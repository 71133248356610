import "grapesjs/dist/css/grapes.min.css";
import _ from "lodash";
import Container from "components/Container/Container";
import Axios from "../../../api/Api";
import { useLazyQuery } from "@apollo/client";
import { GET_ADMIN_CSV_USER_IMPORTS_BULK_NOTIFICATION } from "../../../GraphQl/Queries/OnboardingUsers";
import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import Input from "components/InputGroup/Input";
import Select from "components/InputGroup/Select";
import { CSVLink } from "react-csv";
import { emailDeliveryStatus } from "../../../api/Consts";

function GetNotificationStatus() {
  const [searchCSVData, setSearchCSVData] = useState([]);
  const [userSearch, setUserSearch] = useState({
    csvId: "",
    status: "",
    email: "",
    startDate: "",
    endDate: "",
  });

  const searchFunc = async (e) => {
    const response = await Axios().post("/email_delivery_status", { userSearch });
    setSearchCSVData(response.data.data);
  };

  console.log("search CSV", searchCSVData);
  const [
    runQuery,
    { data: adminCsvUserImportsRes, loading: csvUserImportsLoading },
  ] = useLazyQuery(GET_ADMIN_CSV_USER_IMPORTS_BULK_NOTIFICATION, {
    fetchPolicy: "network-only", // Doesn't check cache before making a network request
  });
  const importsColumns = [
    {
      name: "Created At",
      selector: "createdAt",
      sortable: true,
    },
    {
      name: "CSV Import ID",
      selector: "csv_import_id",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
  ];
  useEffect(() => {
    runQuery();
  }, []);

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Container title={"Get Bulk Notification Status"}>
      <div className="mt-5">
        <div className="flex flex-row grid grid-cols-2">
          <div className="flex flex-col w-full mb-4 space-y-2">
            <Select
              label={"Search By Batch Name"}
              options={adminCsvUserImportsRes?.courses_bulk_notification_csvs}
              valueField={"admin_csv_user_imports_id"}
              displayField={"batch_name"}
              onChange={(e) => {
                setUserSearch({ ...userSearch, csvId: e.target.value });
              }}
            />
          </div>
          <div className="flex flex-col w-full mb-4 space-y-2">
            <Select
              label={"Search By Status"}
              options={emailDeliveryStatus.map((status) => ({
                value: status,
                label: status,
              }))}
              valueField="value"
              displayField="label"
              onChange={(e) => {
                setUserSearch({
                  ...userSearch,
                  status: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex flex-col w-full mb-4 space-y-2">
            <Input
              type={"text"}
              placeholder={"Email"}
              label={"Search By Email"}
              value={userSearch.email}
              onChange={(e) => {
                setUserSearch({ ...userSearch, email: e.target.value });
              }}
            />
          </div>
          <div className="flex flex-col w-full mb-4 space-y-2">
            <Input
              type={"date"}
              placeholder={"Start Date"}
              label={"Search By Start Date"}
              value={userSearch.startDate}
              onChange={(e) => {
                setUserSearch({
                  ...userSearch,
                  startDate: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex flex-col w-full mb-4 space-y-2">
            <Input
              type={"date"}
              placeholder={"End Date"}
              label={"Search By End Date"}
              value={userSearch.endDate}
              onChange={(e) => {
                setUserSearch({
                  ...userSearch,
                  endDate: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex flex-col w-full mb-4 space-y-2">
            <button
              onClick={(e) => searchFunc(e)}
              className="btn-primary block mt-4 w-1/6"
            >
              Search
            </button>
          </div>
        </div>
        <CSVLink
          data={searchCSVData}
          filename={"data.csv"}
          className="btn btn-primary"
        >
          Download CSV
        </CSVLink>

        <DataTable
          className="min-w-max"
          columns={importsColumns}
          data={searchCSVData}
          pagination
          paginationPerPage={10} // Number of rows per page
          paginationRowsPerPageOptions={[10, 20, 30]} // Options for rows per page dropdown
          paginationComponentOptions={paginationOptions}
        />
      </div>
    </Container>
  );
}

export default GetNotificationStatus;
