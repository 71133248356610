import { gql } from "@apollo/client";

export const GET_CERTIFICATE_TEMPLATES = gql`
  query getCertificateTemplates {
    courses_cert_templates {
      id
      name
      html_data
      partner_id
      template_data
    }
  }
`;

export const GET_ALL_PARTNERS = gql`
  query getPartnersData {
    courses_partner {
      name
      id
    }
  }
`;

export const GET_CERTIFICATES = gql`
  query getCertificates {
    courses_certificates {
      id
      certificate_pdf_url
      created_at
      course {
        full_name
      }
      user {
        name
        mobile_number
        email
      }
      partner {
        name
      }
    }
  }
`;

export const GET_TOTAL_CERTIFICATES = gql`
  query ($where: courses_certificates_bool_exp = {}) {
    courses_certificates_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const CERTIFICATES_SEARCH_PAGINATION = gql`
  query MyQuery(
    $searchCriteria: courses_certificates_bool_exp = {}
    $limit: Int
    $offset: Int
  ) {
    courses_certificates(
      where: $searchCriteria
      limit: $limit
      offset: $offset
    ) {
      id
      certificate_pdf_url
      created_at
      course {
        full_name
        id
      }
      user {
        id
        name
        mobile_number
        email
        partner_users {
          partner_id
          partner {
            name
          }
        }
      }
    }
  }
`;

export const GET_FILTER_DATA = gql`
  query getPartnerAndCourses {
    courses_partner {
      name
      id
      projects {
        id
        name
      }
    }
    courses_course {
      full_name
      id
      partner_revenue_split_directives {
        partner_id
        course_id
      }
    }
    courses_st_coupons_configuration {
      code
      id
    }
    courses_partner_projects {
      id
      name
    }
  }
`;

export const GET_PARTNER_FILTER_DATA = gql`
  query getPartnerAndCourses($partnerIds: [bigint!]!) {
    courses_partner(where: { id: { _in: $partnerIds } }) {
      name
      id
      projects {
        id
        name
      }
    }
    courses_course(
      where: {
        partner_revenue_split_directives: { partner_id: { _in: $partnerIds } }
      }
    ) {
      full_name
      id
      partner_revenue_split_directives {
        partner_id
        course_id
      }
    }
    courses_st_coupons_configuration(
      where: { partner_id: { _in: $partnerIds } }
    ) {
      code
      id
    }
    courses_partner_projects(where: { partner_id: { _in: $partnerIds } }) {
      id
      name
    }
  }
`;

export const GET_CERTIFICATES_FOR_BULK_DOWNLOAD = gql`
  query userCertificates($course_id: bigint, $user_array: [bigint!]) {
    courses_certificates(
      where: {
        _and: [
          { course_id: { _eq: $course_id } }
          { user_id: { _in: $user_array } }
        ]
      }
    ) {
      id
      certificate_pdf_url
    }
  }
`;
