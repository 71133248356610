export const qualificationTypeSchema = [
  // {
  //   value: "pre_12",
  //   name: "Pre_12",
  // },
  // {
  //   value: "12",
  //   name: "12",
  // },
  {
    value: "nsqf",
    name: "NSQF",
  },
  {
    value: "bachelors",
    name: "Bachelors",
  },
  {
    value: "iti",
    name: "ITI",
  },
  {
    value: "diploma",
    name: "Diploma",
  },
  {
    value: "masters",
    name: "Masters",
  },
];
