import { useRecoilState } from "recoil";
import { gqlUserRecoilVar } from "State/User";
import { publishChanges } from "utils/hooks/webhooks";
import Report from "components/Report";

export default function HomePage(props) {
  const [user] = useRecoilState(gqlUserRecoilVar);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center pt-20 mt-8">
      <div className="flex flex-col justify-center items-center">
        <div className="text-5xl font-semibold text-gray-300">
          Welcome to <span className="text-orange">SkillsTrainer</span>{" "}
          <span className="text-gray-800">Admin Panel!</span>
        </div>
        {user?.is_skillstrainer_admin && (
          <div className="mt-4">
            <button className="btn-primary" onClick={publishChanges}>
              Publish Marketing website
            </button>
          </div>
        )}
      </div>
      {user.is_skillstrainer_admin && (
        <Report type="home" hidePartners={true} />
      )}
    </div>
  );
}
