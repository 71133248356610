const moment = require("moment");

const day = 24 * 60 * 60 * 1000;

export const getSlotDates = (startDate, endDate, slotDays) => {
  const allSlots = [];

  startDate = moment(startDate).toDate().getTime();
  endDate = moment(endDate).toDate().getTime() + day; // Adding another day to include end date

  const startTimeSlotDay = new Date(startDate).getDay();

  for (const slotDay of slotDays) {
    // Find the first occurrence of the slot day after the start day
    let daysFromStartDate = slotDay - startTimeSlotDay;
    if (daysFromStartDate < 0) daysFromStartDate += 7;

    let slotDayOccurrence = startDate + daysFromStartDate * day;

    // Keep adding a week to the occurence and add to allSlots till the end date is reached
    while (slotDayOccurrence < endDate) {
      allSlots.push(slotDayOccurrence);
      slotDayOccurrence += 7 * day;
    }
  }

  allSlots.sort();

  allSlots.forEach((slotDate, index) => {
    allSlots[index] = new Date(slotDate);
  });

  return allSlots;
};

export const addTimeToDate = (date, time) => {
  date = new Date(date);
  const timeToAdd = time.split(":").reduce((total, cur, index) => {
    // Hours
    if (index === 0) total += Number(cur) * 60 * 60 * 1000;
    // Minutes
    else if (index === 1) total += Number(cur) * 60 * 1000;

    return total;
  }, 0);

  return new Date(date.getTime() + timeToAdd);
};

export const toIndianISOString = (date) => {
  date = new Date(new Date(date).getTime() + 5.5 * 60 * 60 * 1000);
  return date.toISOString().split("Z")[0] + "+05:30";
};

export const setDayTimeToZero = (date) => {
  date = date.split("T");
  const first = date[0];

  const isUTC = date[1].indexOf("Z") > -1;
  const second = isUTC ? date[1].split("Z")[0] : date[1].split("+")[0];
  const ext = isUTC ? "" : date[1].split("+")[1];

  return first + "T" + "00:00:00" + (isUTC ? "Z" : "+") + ext;
};
