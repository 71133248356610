import React, { useEffect, useState } from "react";
import { useQuery, useMutation, empty } from "@apollo/client";
import { useLocation } from "react-router";
import queryString from "query-string";
import { Container } from "react-bootstrap";
import QuizForm from "./QuizForm";
import CourseQuiz from "./CourseQuiz";
import QuizAction from "./QuizAction";
import CreateQuestion from "Pages/Dashboard/Questions/CreateQuestion";
import courseBuilderService from "services/coursebuild/course";
import { Route, Switch, Redirect, matchPath } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { successHandler, errorHandler } from "utils/toast";
import {
  GET_MODULE_DETAILS_BY_ID,
  GET_MODULE_RESTRICTION,
} from "GraphQl/Queries/CourseBuilder/section";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";

export default function QuizEdit() {
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const { data: QuizFormData } = useQuery(GET_MODULE_DETAILS_BY_ID, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  });

  const { data: ModuleRestriction } = useQuery(GET_MODULE_RESTRICTION, {
    variables: { mapping_id: params.id },
    fetchPolicy: "network-only",
  });

  const [topic, setTopics] = useState([]);
  const [quizData, setQuizData] = useState([]);
  const [message, setMessage] = useState();
  const [quizmap, setQuizMap] = useState({
    mapping_id: null,
    course_id: null,
  });

  const [addQuestionMode, setQuestionMode] = useState("");
  const [restriction, setRestriction] = useState(false);

  const [optionsEnable, setoptions] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [Activity, SetActivity] = useState(false);
  const [timer, SetTimer] = useState(false);
  const [defaultRestriction, setDefaultRestriction] = useState("");
  const [lessonName, setLessonName] = useState(null);

  useEffect(() => {
    getQuizData();
  }, [QuizFormData, ModuleRestriction]);

  const getQuizData = async () => {
    QuizFormData &&
      QuizFormData.courses_course_module_mapping.map(async (item) => {
        setLessonName(item.section?.name);

        setQuizData((quizData) => ({ ...quizData, name: item.name }));
        setQuizData((quizData) => ({
          ...quizData,
          enable_user_attempt: item.enable_user_attempt,
        }));
        setQuizData((quizData) => ({
          ...quizData,
          description: item.description,
        }));
        setQuizData((quizData) => ({
          ...quizData,
          maxattempt: item.maxattempt,
        }));
        setQuizData((quizData) => ({
          ...quizData,
          attemtsgrade: item.attemtsgrade,
        }));
        setQuizData((quizData) => ({ ...quizData, course_id: item.course_id }));
        setQuizData((quizData) => ({
          ...quizData,
          require_webcam: item.require_webcam,
        }));

        setQuizData((quizData) => ({
          ...quizData,
          completion_criteria: item.completion_criteria,
        }));

        if (item.completion_criteria == 1) {
          SetActivity(true);

          setQuizData((quizData) => ({
            ...quizData,
            completion_percentage: item.completion_percentage,
          }));
        }
        if (item.enable_timer) {
          setQuizData((quizData) => ({ ...quizData, enable_timer: true }));
          SetTimer(true);
          var h = Math.floor(item.timelimit_number / 3600);
          var m = Math.floor((item.timelimit_number % 3600) / 60);
          var s = Math.floor((item.timelimit_number % 3600) % 60);

          if (m > 0) {
            setQuizData((quizData) => ({ ...quizData, timelimit_unit: 60 }));
          }
          if (h > 0) {
            setQuizData((quizData) => ({ ...quizData, timelimit_unit: 3600 }));
          }
          setQuizData((quizData) => ({
            ...quizData,
            timelimit: m > 0 ? m : h > 0 ? h : s > 0 ? s : "",
          }));
        }

        const res = await courseBuilderService.GetCourseTopics(item.course_id);

        const Options =
          res.length > 0 &&
          res.map((topic, i) => {
            return { value: topic.id, label: topic.name };
          });

        setTopics(Options);

        const restriction_ids =
          ModuleRestriction &&
          ModuleRestriction.courses_module_completion_resrtriction[0];

        if (restriction_ids) {
          const split_string =
            restriction_ids.restriction_ids &&
            restriction_ids.restriction_ids.split(",");

          const result =
            Options &&
            Options.filter((o1) => split_string.some((o2) => o1.value == o2));

          if (result) {
            setDefaultRestriction(result);
            setSelectedValue(
              Array.isArray(result) ? result.map((x) => x.value) : []
            );
          }
          setQuizData((quizData) => ({
            ...quizData,
            enable_restriction: true,
          }));
          setQuizData((quizData) => ({
            ...quizData,
            restriction_previous_set: true,
          }));

          setRestriction(true);
        } else {
          setQuizData((quizData) => ({
            ...quizData,
            restriction_previous_set: false,
          }));
        }
      });
  };

  const updateCheckbox = (e) => {
    const key = e.target.getAttribute("data-key");
    const value = e.target.checked;

    if (key == "enable_timer") {
      SetTimer(value);
    }

    if (key == "enable_restriction") {
      setRestriction(value);
    }
    setQuizData({ ...quizData, [key]: value });
  };

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const updateValue = (e) => {
    const key = e.target.getAttribute("data-key");

    const value = e.target.value;

    console.log("key ", value);
    if (key == "completion_criteria" && value == 1) {
      SetActivity(true);
    } else if (key == "completion_criteria" && value == 0) {
      SetActivity(false);
    }

    if (key == "completion_percentage") {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        setQuizData({ ...quizData, [key]: value });
      }
    } else {
      setQuizData({ ...quizData, [key]: value });
    }
  };

  const criteriaOption = [
    { value: "view", label: "On View" },
    { value: "failed", label: "Failed" },
    { value: "passed", label: "Passed" },
  ];

  const submitQuiz = async (status) => {
    const formData = { ...quizData };

    console.log("formData", formData);
    if (
      formData.name === "" ||
      formData.name === null ||
      formData.name === undefined
    ) {
      errorHandler("Quiz name is required");
      setMessage("Quiz name is required");
      return;
    }

    formData["enable_restriction"] = !formData.enable_restriction
      ? false
      : formData.enable_restriction;

    formData["restriction_ids"] = selectedValue.toString();
    formData["timelimit_number"] = formData["enable_timer"]
      ? formData["timelimit"] * formData["timelimit_unit"]
      : 0;
    formData["id"] = params.id;
    formData["completion_criteria"] = parseInt(formData.completion_criteria);

    const UpdateData = await courseBuilderService.UpdateQuizForm(formData);

    if (!UpdateData) {
      errorHandler("Something will b wrong");
    }

    if (status.return && UpdateData) {
      successHandler("Quiz Successfully Updated!");

      let path =
        getModulePathStringById(ModuleNameIdMap.viewCourse) +
        `?id=${formData.course_id}`;
      history.push(path);
    }

    if (!status.return && UpdateData) {
      successHandler("Quiz Successfully Updated!");
      let path =
        getModulePathStringById(ModuleNameIdMap.editQuestion) +
        `/?id=${formData.course_id}&&quizid=${params.id}`;
      history.push(path);
    }
  };

  const setmode = (mode) => {
    setQuestionMode(mode);
  };

  return (
    <Container>
      {!optionsEnable && !addQuestionMode ? (
        <QuizForm
          submitQuiz={submitQuiz}
          criteriaOption={criteriaOption}
          updateValue={updateValue}
          message={message}
          quizData={quizData}
          updateCheckbox={updateCheckbox}
          handleChange={handleChange}
          topic={topic}
          restriction={restriction}
          Activity={Activity}
          timer={timer}
          action="update"
          defaultRestriction={defaultRestriction}
          selectedValue={selectedValue}
          lessonName={lessonName}
        />
      ) : optionsEnable && !addQuestionMode ? (
        <QuizAction setmode={setmode} />
      ) : optionsEnable && addQuestionMode == "add_new" ? (
        <CreateQuestion quizmap={quizmap} />
      ) : optionsEnable && addQuestionMode == "question_bank" ? (
        <CourseQuiz quizmap={quizmap} />
      ) : (
        ""
      )}
    </Container>
  );
}
