import React, { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { useLazyQuery } from "@apollo/client";

import { GET_SLOTS_BY_BATCH_ID } from "../../../../GraphQl/Queries/BatchesQueries";
import Modal from "react-bootstrap/Modal";
import BatchTableModal from "./BatchTableModal";
import moment from "moment";
import courseSvc from "services/course";
import BatchAttendance from "./BatchesData/BatchAttendance";
import _ from "lodash";
import ShowBatchMedia from "./BatchesData/ShowBatchMedia";

function BatchesData(props) {
  const [search, { data }] = useLazyQuery(GET_SLOTS_BY_BATCH_ID);

  const manageBatch = (e) => {
    e.preventDefault();
    const id = e.target.getAttribute("id");

    search({
      variables: {
        batch_id: id,
      },
      onError: console.error,
      onCompleted: () => setLgShow(true),
    });
  };

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const columns = [
    {
      name: "ID",
      selector: (row, index) => `${row.id}`,
      sortable: true,
      width: "5rem",
    },

    {
      name: "Name",
      selector: (row, index) => `${row.batch_name}`,
      sortable: true,
      width: "12rem",
      wrap: true,
    },
    {
      name: "Course Name",
      selector: (row, index) => `${row.course.full_name}`,
      sortable: true,
      wrap: true,
      width: "10rem",
    },
    {
      name: "Trainer Name",
      selector: (row, index) => `${row.instructor?.name}`,
      sortable: true,
    },

    {
      name: "Start Date",
      selector: (row, index) => moment(row.from_date).format("YYYY-MM-DD"),
      sortable: true,
      width: "7rem",
    },
    {
      name: "End Date",
      selector: (row, index) => moment(row.to_date).format("YYYY-MM-DD"),
      sortable: true,
      width: "7rem",
    },
    {
      name: "Time slot",
      selector: (row, index) => row.slot_start_time + " - " + row.slot_end_time,
      width: "8rem",
    },

    {
      name: "Type",
      selector: (row, index) => (row.type == "offline" ? "Offline" : "Online"),
      width: "6rem",
    },

    {
      name: "Slots Days",
      selector: (row, index) => `${row.slots_days}`,
      sortable: true,
      width: "8rem",
    },

    {
      name: "Is Demo",
      width: "6rem",
      selector: (row) =>
        row.is_demo ? (
          <span className="text-green-600 font-semibold">Yes</span>
        ) : (
          <span className="text-gray-500 font-semibold">No</span>
        ),
    },

    {
      name: "Action",
      width: "20rem",
      cell: (row) => (
        <div className="flex w-full my-1">
          <button className="secondary-cta" id={row.id} onClick={manageBatch}>
            View slots
          </button>

          <button
            className="secondary-cta mx-2 "
            onClick={() => setSelectedBatchForAttendance(row)}
          >
            View attendance
          </button>

          <button
            className="secondary-cta"
            onClick={() => setShowMediaModal(row)}
          >
            View Media
          </button>
        </div>
      ),
    },
  ];

  const [selectedBatchForAttendance, _setSelectedBatchForAttendance] =
    useState();
  const setSelectedBatchForAttendance = async (batch) => {
    if (batch) {
      batch = _.cloneDeep(batch);
      batch.batch_slots = await courseSvc.getBatchSlotsByBatchId(batch.id);
      batch.enrolled_users = await courseSvc.getBatchUsers(batch.id);
    }
    _setSelectedBatchForAttendance(batch);
  };
  const [selectedBatchAttendance, setSelectedBatchAttendance] = useState();
  const fetchBatchAttendance = async (batchId) => {
    const batch = selectedBatchForAttendance;
    const attendance = await courseSvc.getBatchAttendance(batchId);

    let attendanceByUser;

    if (!batch.is_demo) {
      // For non demo batches
      attendanceByUser = batch.enrolled_users.reduce((users, { user }) => {
        users[user.id] = {
          user,
          attendance: attendance
            .filter((a) => a.user_id === user.id)
            .map((e) => ({ ...e, status: e.attendance_status })),
        };
        return users;
      }, {});
    } else {
      // For demo batches
      attendanceByUser = attendance.reduce((users, record) => {
        if (!users[record.user_id])
          users[record.user_id] = { attendance: [], user: record.user };
        users[record.user_id].attendance.push(record);

        return users;
      }, {});
    }

    setSelectedBatchAttendance(attendanceByUser);
  };
  useEffect(() => {
    setSelectedBatchAttendance();
    if (selectedBatchForAttendance)
      fetchBatchAttendance(selectedBatchForAttendance.id);
  }, [selectedBatchForAttendance]);

  const [lgShow, setLgShow] = useState(false);
  const [showMediaModal, setShowMediaModal] = useState(false);

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  return (
    <div>
      <DataTable
        columns={columns}
        pagination
        data-key={columns}
        data={props.batchesData.data?.courses_course_batches}
        selectableRows={false}
        selectableRowsHighlight={false}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />

      {/* Batch slots */}
      {lgShow ? (
        <Modal
          size="lg"
          show={lgShow}
          fullscreen
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Batch Slots
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BatchTableModal batch_slots={data?.courses_batch_slots || []} />
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}

      {/* Attendance */}
      <Modal
        show={!!selectedBatchForAttendance}
        onHide={() => setSelectedBatchForAttendance()}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Attendance for {selectedBatchForAttendance?.batch_name}
          </Modal.Title>
        </Modal.Header>
        {selectedBatchForAttendance && (
          <BatchAttendance
            batch={selectedBatchForAttendance}
            attendance={selectedBatchAttendance}
          />
        )}
      </Modal>

      {/* Batch Media Modal */}
      <Modal
        show={!!showMediaModal}
        onHide={() => setShowMediaModal(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Media for {showMediaModal?.batch_name}
          </Modal.Title>
        </Modal.Header>
        <ShowBatchMedia batch={showMediaModal} />
      </Modal>
    </div>
  );
}

export default BatchesData;
