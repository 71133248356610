import { gql } from "@apollo/client";

export const GET_EMAIL_TEMPLATES_NAMES = gql`
  query getEmailTemplateNames {
    courses_notification_event_types {
      id
      name
    }
  }
`;

export const GET_EMAIL_TEMPLATE_BY_ID = gql`
  query getEmailTemplate($id: Int) {
    courses_notification_event_types(where: { id: { _eq: $id } }) {
      id
      email_template
    }
  }
`;
