import { useState } from "react";
import DataTable from "react-data-table-component";
import ViewApplicantModal from "./ViewApplicantModal";

function ApplicantTable(props) {
  const [modalState, setModalState] = useState(false);
  const [applicant, setApplicant] = useState({
    id: "",
    name: "",
  });
  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });
  const viewApplicantDetailsButton = (row) => {
    return (
      <button
        className="btn-secondary px-2 py-2"
        onClick={() => viewApplicantDetails(row)}
      >
        View Details
      </button>
    );
  };
  const columns = [
    {
      name: "ID",
      selector: (row) => `${row.user.id}`,
      sortable: true,
      maxWidth: "50px",
    },
    {
      name: "Name",
      selector: (row) => `${row.user.name}`,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => `${row.user.email}`,
      minWidth: "210px",
    },
    {
      name: "Mobile Number",
      selector: (row) => `${row.user.mobile_number}`,
    },
    {
      name: "Date of Birth",
      selector: (row) => `${row.user.date_of_birth}`,
      sortable: true,
      maxWidth: "125px",
    },
    {
      name: "Score",
      selector: (row) => `${row.score}`,
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      selector: (row) => `${row.status}`,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.status === "rejected",
          style: {
            backgroundColor: "rgba(245, 39, 39, 0.35)",
          },
        },
        {
          when: (row) => row.status === "accepted",
          style: {
            backgroundColor: "rgba(39, 245, 74, 0.35)",
          },
        },
      ],
    },
    {
      name: "Stage",
      selector: (row) => `${row.stage ? row.stage : 0}`,
      sortable: true,
      width: "85px",
    },
    {
      cell: viewApplicantDetailsButton,
    },
  ];

  const viewApplicantDetails = (row) => {
    setApplicant({
      id: row.user.id,
      name: row.user.name,
      status: row.status,
      stage: row.stage,
    });
    setModalState(true);
  };

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  return (
    <div id="applicants_table">
      <DataTable
        columns={columns}
        pagination
        data={props.data}
        highlightOnHover={true}
        selectableRows={false}
        selectableRowsHighlight={false}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
      <ViewApplicantModal
        show={modalState}
        applicant={applicant}
        setApplicant={setApplicant}
        jobId={props.jobId}
        handleClose={() => {
          setModalState(false);
          props.refetch();
        }}
      />
    </div>
  );
}

export default ApplicantTable;
