import { useState } from "react";
import DataTable from "react-data-table-component";

function JobsTable(props) {
  const { data, handleEdit, handleViewApplicants } = props;
  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const viewApplicantButton = (row) => {
    return (
      <button
        className="btn-secondary px-2 py-2 "
        onClick={(e) => handleViewApplicants(e, row)}
      >
        View Applicants
      </button>
    );
  };

  const editButton = (row) => {
    return (
      <button
        className="btn-secondary px-2 py-2 "
        onClick={(e) => handleEdit(e, row)}
      >
        Edit
      </button>
    );
  };
  const columns = [
    {
      name: "Title",
      selector: (row) => `${row.title}`,
      sortable: true,
      maxWidth: "400px",
    },
    {
      name: "Sector",
      selector: (row) => `${row.sector?.name}`,
      sortable: true,
    },
    {
      name: "Partner Name",
      selector: (row) => `${row.partner.name}`,
    },
    {
      name: "No. of Positions",
      selector: (row) => `${row.no_of_positions}`,
      sortable: true,
      wrap: true,
      maxWidth: "150px",
    },
    {
      name: "No. of Applications",
      selector: (row) => `${row?.job_applications_aggregate.aggregate.count}`,
    },
    {
      name: "Salary",
      selector: (row) => `${row.salary_ctc}`,
      sortable: true,
    },
    {
      cell: viewApplicantButton,
    },
    {
      cell: editButton,
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  return (
    <div id="manage_jobs_table">
      <DataTable
        columns={columns}
        pagination
        data={data}
        selectableRows={false}
        selectableRowsHighlight={false}
        highlightOnHover={true}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </div>
  );
}

export default JobsTable;
