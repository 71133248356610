import { gql } from "@apollo/client";

export const GET_INSTITUTE_BY_ID = gql`
  query getInstituteById($id: bigint) {
    courses_institutes(where: { id: { _eq: $id } }) {
      id
      name
      pincode
      location
      house_number
      district
      state
      city_town
      country
      category
    }
  }
`;

export const GET_INSTITUTES = gql`
  query getInstitutes($name: String) {
    courses_institutes(where: { name: { _ilike: $name } }) {
      id
      name
      pincode
      district
      state
      city_town
      country
      category
    }
  }
`;
export const GET_ALL_INSTITUTES = gql`
  query getAllInstitutes {
    courses_institutes {
      id
      name
      pincode
    }
  }
`;
