import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import base64 from "base-64";
import Parse from "papaparse";
import { uploadFile, getFile } from "../../../../api/UploadFile";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { GET_ADMIN_CSV_USER_IMPORTS } from "../../../../GraphQl/Queries/OnboardingUsers";
import { CREATE_COURSES_ADMIN_CSV_USER_IMPORTS } from "../../../../GraphQl/Mutations/OnboardingUsers";
import { GET_PARTNERS } from "../../../../GraphQl/Queries/Partner";
import { FiRefreshCw } from "react-icons/fi";
import Container from "components/Container/Container";
import { useRecoilState } from "recoil";
import { gqlUserRecoilVar } from "State/User";
import { wireEventValue } from "utils/func";
import { currentModuleAccessRecoilVar } from "State/Navigation";
import { GET_ALL_PARTNERS } from "GraphQl/Queries/CertificateTemplateMaker";
import { errorHandler } from "utils/toast";
import usePromise from "utils/hooks/usePromise";
import Axios from "../../../../api/Api";
import { parse } from "json2csv";
const OnboardingUsers = () => {
  const [acting_user] = useRecoilState(gqlUserRecoilVar);

  const [user, setUsers] = useState([]);
  const [columns, setColumns] = useState([]);
  const [csvData, setCsvData] = useState({});
  const [uploadSuccess, setUploadSucess] = useState(false);
  const [selectedPartnerId, setSelectedPartnerId] = useState();

  /*
   *
   *
   * Fetching required data
   *
   *
   */

  // Partner
  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);

  const { data: allPartnersRes } = useQuery(GET_ALL_PARTNERS);
  const allPartners = allPartnersRes?.courses_partner;

  const accessiblePartners = useMemo(() => {
    if (acting_user && allPartners && acting_user.is_skillstrainer_admin)
      return allPartners.map((p) => p.id);
    else if (currentModuleAccess)
      return Object.keys(currentModuleAccess.allowedPartners).map(Number);
  }, [acting_user, currentModuleAccess, allPartners]);

  const accessiblePartnersOptions = useMemo(
    () =>
      allPartners &&
      accessiblePartners &&
      accessiblePartners.map((pId) => allPartners.find((p) => p.id === pId)),
    [accessiblePartners, allPartners]
  );

  // Fetching CSVs
  const [
    runQuery,
    { data: adminCsvUserImportsRes, loading: csvUserImportsLoading },
  ] = useLazyQuery(GET_ADMIN_CSV_USER_IMPORTS, {
    fetchPolicy: "network-only", // Doesn't check cache before making a network request
  });

  // Filtering CSVs based on admin
  const data = useMemo(() => {
    if (acting_user && adminCsvUserImportsRes) {
      return adminCsvUserImportsRes.courses_admin_csv_user_imports.filter(
        (e) =>
          acting_user.is_skillstrainer_admin || e.uploader_id === acting_user.id
      );
    }
  }, [acting_user, adminCsvUserImportsRes]);

  /*
   *
   *
   * CSV Handling actions
   *
   *
   */

  const [createuserImports, { loading, error }] = useMutation(
    CREATE_COURSES_ADMIN_CSV_USER_IMPORTS,
    { refetchQueries: [GET_ADMIN_CSV_USER_IMPORTS] }
  );

  function processCsvData({ data = [] }) {
    let new_data = [...data];
    const labels = new_data[0];
    new_data = new_data.slice(1, 5);
    changeTable(labels, new_data);
  }
  const changeTable = (labels = [], data = []) => {
    const new_columns = [];
    labels.map((label, index) => {
      new_columns.push({
        name: label,
        cell: (row) => row[index],
        grow: 4,
      });
    });
    setColumns(new_columns);
    setUsers(data);
  };

  const changeFile = async (e) => {
    setUploadSucess(false);
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = function (output) {
      //console.log(output);
      //encoding info removed

      let data = output.target.result.split(",")[1];
      //decode into csv

      //set csv data
      setCsvData({
        csv_data: output.target.result,
        csv_name: file.name,
      });

      const decodedString = base64.decode(data);

      Parse.parse(decodedString, {
        complete: processCsvData,
      });
    };
    reader.readAsDataURL(file);
  };

  const { run: createUsers, loading: creatingUsers } = usePromise(async () => {
    try {
      if (!user.is_skillstrainer_admin && !selectedPartnerId)
        throw new Error("Please choose a partner");

      const response = await uploadFile(
        csvData.csv_data,
        csvData.csv_name,
        "usercsv"
      );
      const userImportRecord = {
        status: "pending",
        partner_id: selectedPartnerId || undefined,
        file_url: response.data_url,
        uploader_id: acting_user.id,
      };
      createuserImports({
        variables: {
          record: userImportRecord,
        },
      });
      if (!loading && !error) setUploadSucess(true);
    } catch (err) {
      errorHandler(err.message || "An error occurred");
    }
  });

  useEffect(() => {
    runQuery();
  }, []);

  console.log(selectedPartnerId);

  /*
   *
   *
   * UI
   *
   *
   */

  return (
    <Container title="Bulk Onboard Trainees">
      <div>
        <p className="mt-5 text-2xl font-medium">
          Use the CSV format given below to bulk onboard users
        </p>
        <a
          href="https://skillstrainer-api.s3.amazonaws.com/user_csv_sheet.csv"
          target={"_blank"}
          rel="noreferrer"
        >
          <button className="btn-primary">Download</button>
        </a>
      </div>
      <div className=" mt-5">
        <p className="text-2xl font-medium">Upload CSV</p>
        <div className="flex">
          <div>
            <div className="font-semibold">Choose a file</div>
            <input
              type={"file"}
              onInput={changeFile}
              accept=".csv"
              multiple={false}
              className="mt-1"
            />
          </div>
          <div>
            <span className="font-semibold">Choose a partner</span>
            <select
              onChange={wireEventValue(setSelectedPartnerId)}
              className="input-primary"
              value={selectedPartnerId}
            >
              <option>Choose an option</option>
              {accessiblePartnersOptions?.map((p) => (
                <option value={p.id} key={p.id}>
                  {p.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {user.length > 0 && (
        <div className="flex-row mt-10 w-auto">
          <p className="text-2xl mb-3 font-semibold">Preview</p>
          <DataTable columns={columns} data={user} />
          <button onClick={createUsers} className="btn-primary mt-3">
            Confirm and Create Users
          </button>
        </div>
      )}

      {uploadSuccess && (
        <div className="mt-7 text-green-700 text-lg">
          CSV successfully uploaded
        </div>
      )}
      <div className="mt-5">
        <div className="flex flex-row">
          <p className="text-2xl font-medium">Status</p>
          <button
            onClick={() => runQuery()}
            className="p-2 text-gray-500 w-5 h-5"
          >
            <FiRefreshCw />
          </button>
        </div>
        <DataTable
          className="min-w-max"
          columns={importsColumns}
          progressPending={csvUserImportsLoading}
          data={!csvUserImportsLoading ? data : []}
        />
      </div>
    </Container>
  );
};

export default OnboardingUsers;

const importsColumns = [
  {
    name: "Id",
    cell: (row, index) => `${row.id}`,
  },
  {
    name: "Created",
    cell: (row, index) => `${row.created}`,
  },
  {
    name: "Duplicate",
    cell: (row, index) => `${row.duplicate}`,
  },
  {
    name: "File url",
    grow: 5,
    cell: (row, index) => row.file_url,
  },
  {
    name: "Partner Id",

    cell: (row, index) => `${row.partner_id}`,
  },
  {
    name: "Status",
    grow: 5,
    cell: (row, index) => <RowStatus row={row} index={index} />,
  },
  {
    name: "Created At",
    grow: 5,
    // style: {wordBreak: "break-all"},
    cell: (row, index) => row.created_at,
  },
  {
    name: "Failed",
    grow: 4,
    cell: (row, index) => `${row.failed}`,
  },
  {
    name: "Updated",
    cell: (row, index) => `${row.updated}`,
  },
  {
    name: "",
    grow: 2.5,
    cell: (row, index) => <RowActions row={row} index={index} />,
  },

  {
    name: "",
    grow: 2.5,
    cell: (row, index) => <FetchUsersCreatedData row={row} index={index} />,
  },
];

const importColumnsStatusColors = {
  pending: "bg-gray-400",
  running: "bg-orange",
  processed: "bg-green-500",
  failed: "bg-red-500",
};

const RowStatus = ({ row, index }) => (
  <p
    className={`${
      importColumnsStatusColors[row.status] != undefined && "text-white"
    } ${importColumnsStatusColors[row.status]}  rounded p-2 m-1`}
  >
    {row.status}
  </p>
);

const RowActions = ({ row, index }) => {
  const handleDownload = async (e, row) => {
    const url = await getFile(row.file_url);
    window.open(url, "_blank");
  };

  return (
    <button
      onClick={(e) => handleDownload(e, row)}
      className="bg-blue-500 text-white p-2 rounded"
    >
      Download
    </button>
  );
};

const FetchUsersCreatedData = ({ row, index }) => {
  const [csvData, setCsvData] = useState([]);

  const handleDownload = async (e) => {
    const csv_import_id = row.id;
    console.log("row===", row);
    const response = await Axios().post("/user_bulk_onboard_reports", {
      csv_import_id,
    });

    // Convert the object to a CSV-formatted string using json2csv
    const csvData = parse(response.data.data);

    // Create a Blob containing the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "data.csv";

    // Trigger a click event on the link to initiate the download
    link.click();

    // Clean up the temporary link
    window.URL.revokeObjectURL(link.href);
  };

  return (
    <>
      <button
        onClick={(e) => handleDownload(e)}
        className="bg-blue-500 text-white p-2 rounded"
      >
        Download Reports
      </button>
    </>
  );
};
