import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_ADMIN_ROLE_MODULE_ACCESS,
  UPSERT_ADMIN_ROLES_INFO,
  UPSERT_ADMIN_ROLE_MODULE_ACCESS,
} from "GraphQl/Mutations/AdminUserRole";
import { GET_ALL_MODULES } from "GraphQl/Queries/AdminUsers";
import { GET_ALL_PARTNERS } from "GraphQl/Queries/CertificateTemplateMaker";
import { GET_PROJECTS_BY_PARTNER } from "GraphQl/Queries/Projects";
import _ from "lodash";
import { useMemo, useState } from "react";
import { arrayDelta } from "utils/func";
import { getModuleTreeFromModuleList } from "utils/resources";
import { errorHandler, successHandler } from "utils/toast";

function CreateRole(props) {
  // Fields: name, partner_id, project_id and active
  const { selectedRole, onClear, afterSubmit } = props;

  /*
   *
   *
   * State variables
   *
   *
   */
  const [role, setRole] = useState(
    selectedRole &&
      _.cloneDeep({
        ...selectedRole,
        module_relations: undefined,
        modules:
          selectedRole?.module_relations.reduce((acc, cur) => {
            acc[cur.module_id] = true;
            return acc;
          }, {}) || {},
      })
  );

  const updateField = (fieldName) => (e) => {
    const newRole = role || {};
    newRole[fieldName] = e.target.value;
    setRole({ ...newRole });
  };

  const getIsModuleSelected = (moduleId) =>
    console.log(role) || !!(role?.modules && role.modules[moduleId]);

  const handleModuleSelect = (moduleId, isSelected) => {
    const newRole = role || {};
    if (!newRole.modules) newRole.modules = {};
    newRole.modules[moduleId] = isSelected;
    setRole({ ...newRole });
  };

  /*
   *
   *
   * GQL
   *
   *
   */
  const { data: partnersRes } = useQuery(GET_ALL_PARTNERS);
  const partners = partnersRes?.courses_partner;

  const { data: projectsRes } = useQuery(GET_PROJECTS_BY_PARTNER, {
    variables: { partner_id: role?.partner_id },
  });
  const projects = projectsRes?.courses_partner_projects;

  const { data: modulesRes } = useQuery(GET_ALL_MODULES);
  const modules = modulesRes?.courses_admin_modules.filter(
    (e) => e.key !== "empty"
  );
  const moduleTree = useMemo(
    () =>
      getModuleTreeFromModuleList(
        modules.map((m) => m.id),
        _.cloneDeep(modules)
      ),
    [modules]
  );

  const [runUpsertAdminRoleInfoMutation] = useMutation(UPSERT_ADMIN_ROLES_INFO);
  const [runUpsertAdminRoleModuleAccess] = useMutation(
    UPSERT_ADMIN_ROLE_MODULE_ACCESS
  );
  const [runDeleteAdminRoleModuleAccess] = useMutation(
    DELETE_ADMIN_ROLE_MODULE_ACCESS
  );

  /*
   *
   *
   * Submit
   *
   *
   */
  const submit = async () => {
    try {
      // Handling role
      const upsertedRole = await runUpsertAdminRoleInfoMutation({
        variables: {
          adminRoleInfo: _.pick(role, [
            "id",
            "name",
            "partner_id",
            "project_id",
            "active",
          ]),
        },
      }).then(
        ({ data }) => data.insert_courses_admin_user_roles_info.returning[0]
      );

      // Handling modules
      const oldModulesList = selectedRole?.module_relations || [];
      const newModulesList = Object.keys(role.modules)
        .filter((k) => role.modules[k])
        .map(Number)
        .map((module_id) => ({ module_id }));

      const { created, deleted, persistant } = arrayDelta(
        oldModulesList,
        newModulesList,
        ["module_id"]
      );

      const roleModulesUpsertionResponse = await runUpsertAdminRoleModuleAccess(
        {
          variables: {
            objects: created
              .concat(persistant)
              .map((c) => ({ ...c, role_id: upsertedRole.id, name: "." })),
          },
        }
      ).then(
        ({ data }) =>
          data.insert_courses_admin_user_role_module_access.affected_rows
      );

      const roleModulesDeletionResponse = await runDeleteAdminRoleModuleAccess({
        variables: {
          ids: deleted.map((m) => m.id),
        },
      }).then(
        ({ data }) =>
          data.delete_courses_admin_user_role_module_access.affected_rows
      );

      console.log({
        roleModulesUpsertionResponse,
        roleModulesDeletionResponse,
      });

      if (typeof afterSubmit === "function") afterSubmit();
      successHandler(`Successfully ${role.id ? "updated" : "created"} role`);
    } catch (err) {
      console.log(err);
      errorHandler(`Couldn't ${role.id ? "update" : "create"} role`);
    }
  };

  /*
   *
   *
   * JSX
   *
   *
   */
  return (
    <div>
      <div className="grid grid-cols-3 gap-2 w-full">
        {/* Name */}
        <div className="flex flex-col">
          <span>Name</span>
          <input
            type="text"
            className="input-primary"
            onChange={updateField("name")}
            value={role?.name}
          />
        </div>

        {/* Partner */}
        <div className="flex flex-col">
          <span>Partner</span>
          <select
            className="input-primary"
            onChange={updateField("partner_id")}
            value={role?.partner_id}
          >
            <option>Choose an option</option>
            {partners &&
              partners.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
          </select>
        </div>

        {/* Project */}
        <div className="flex flex-col">
          <span>Project</span>
          <select
            className="input-primary"
            onChange={updateField("project_id")}
            value={role?.project_id}
          >
            <option>Choose an option</option>
            {projects &&
              projects.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
          </select>
        </div>

        {/* Is Active */}
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            className="input-checkbox"
            onChange={updateField("active")}
            checked={role?.active}
          />
          <span>Is Active</span>
        </div>
      </div>

      {/* Modules */}
      <div className="flex flex-col mt-3">
        <span>Modules allowed:</span>
        <div className="w-full grid grid-cols-2 p-3 gap-3 h-80 overflow-y-auto bg-gray-300">
          {moduleTree &&
            moduleTree.map((mod) => (
              <ModuleNode
                key={mod.id}
                module={mod}
                handleSelect={handleModuleSelect}
                getIsModuleSelected={getIsModuleSelected}
                className="font-semibold"
              />
            ))}
        </div>
      </div>

      {/* Submit */}
      <button className="btn-primary mt-3" onClick={submit}>
        Submit
      </button>

      <button className="btn-secondary ml-3 mt-3" onClick={onClear}>
        Create New
      </button>
    </div>
  );
}

const ModuleNode = ({
  module: mod,
  depth = 0,
  handleSelect,
  getIsModuleSelected,
  className,
}) => {
  return (
    <div className="w-full" style={{ paddingLeft: depth * 1.5 + "rem" }}>
      <div className="flex items-center">
        <input
          type="checkbox"
          onChange={(e) => handleSelect(mod.id, e.target.checked)}
          checked={getIsModuleSelected(mod.id)}
          className="mr-2"
        />
        <div className={className}>{mod.name}</div>
      </div>
      <div className="w-full">
        {mod.submodules.length > 0 &&
          mod.submodules.map((submod) => (
            <ModuleNode
              key={submod.id}
              module={submod}
              depth={depth + 1}
              handleSelect={handleSelect}
              getIsModuleSelected={getIsModuleSelected}
            />
          ))}
      </div>
    </div>
  );
};

export default CreateRole;
