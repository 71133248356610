import { gql } from "@apollo/client";

export const GET_ALL_ADMIN_ROLES = gql`
  query myQuery {
    courses_admin_user_roles_info(order_by: { id: desc }) {
      id
      module_relations {
        id
        role_id
        module_id
      }
      partner_id
      project_id
      name
      is_skillstrainer_admin_role
      active
    }
  }
`;

export const GET_ADMIN_USERS_ROLE_BY_ID = gql`
  query getUserRole($role_id: bigint = "") {
    courses_admin_user_roles_info(where: { id: { _eq: $role_id } }) {
      id
      name
      partner_id
      project_id
      active
      is_skillstrainer_admin_role
      module {
        id
        name
        partner_id
        project_id
        role_id
        read
        write
        submodules {
          id
          module_id
          name
          partner_id
          project_id
          read
          write
          role_id
        }
      }
    }
  }
`;

export const GET_TOTAL_ADMIN_USER_ROLES = gql`
  query getTotaAdminUsers {
    courses_admin_user_roles_info_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PROJECTS_BY_PARTNER_ID = gql`
  query getProjectsByPartnerId($partner_id: bigint) {
    courses_partner_projects(where: { partner_id: { _eq: $partner_id } }) {
      id
      name
      created_at
    }
  }
`;

export const GET_PARTNER_PROJECTS = gql`
  query getPartnerProjects {
    courses_partner {
      id
      name
    }
    courses_partner_projects {
      id
      name
      partner_id
    }
  }
`;
