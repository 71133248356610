import api from "api/Api";
import { client } from "App";
import {
  DELETE_CERTIFICATION_PARTNER_MUTATION,
  INSERT_CERTIFICATION_PARTNER_MUTATION,
} from "GraphQl/Mutations/Courses";
import {
  GET_BATCH_ENROLLMENTS,
  GET_SLOTS_BY_BATCH_ID,
} from "GraphQl/Queries/BatchesQueries";
import {
  GET_BATCH_ATTENDANCE,
  GET_COURSE_BY_ID,
} from "GraphQl/Queries/Courses";

class CourseService {
  getCourseById(courseId) {
    return client
      .mutate({
        mutation: GET_COURSE_BY_ID,
        variables: {
          id: courseId,
        },
      })
      .then(({ data }) => data.courses_course[0]);
  }

  addCertificationPartners(courseId, partnerIds) {
    if (!Array.isArray(partnerIds)) partnerIds = [partnerIds];

    const relations = partnerIds.map((pid) => ({
      course_id: courseId,
      partner_id: pid,
    }));

    return client
      .mutate({
        mutation: INSERT_CERTIFICATION_PARTNER_MUTATION,
        variables: { relations },
      })
      .then(
        ({ data }) => data.insert_courses_course_partner_certification.returning
      );
  }

  deleteCertificationPartners(courseId, partnerIds) {
    return client
      .mutate({
        mutation: DELETE_CERTIFICATION_PARTNER_MUTATION,
        variables: { partner_ids: partnerIds, courseId },
      })
      .then(
        ({ data }) =>
          data.delete_courses_course_partner_certification.affected_rows
      );
  }

  async getTraineeProgress(traineeId, useDummyData) {
    const { data } = useDummyData
      ? {
          data: {
            success: true,
            data: {
              courseData: [
                {
                  fullname: "English course",
                  id: 34,
                  progress: 57,
                },
                {
                  fullname: "Mathematics",
                  id: 34,
                  progress: 57,
                },
              ],
            },
          },
        }
      : await api().post("/get_user_courses", {
          user_ids: traineeId,
        });

    if (data.success)
      return data.data.courseData.map((c) => ({
        name: c.fullname,
        id: c.id,
        progress: c.progress,
      }));
    else throw data.error;
  }

  getBatchSlotsByBatchId(batch_id) {
    return client
      .query({
        query: GET_SLOTS_BY_BATCH_ID,
        variables: { batch_id },
      })
      .then(({ data }) => data.courses_batch_slots);
  }

  getBatchUsers(batch_id) {
    return client
      .query({
        query: GET_BATCH_ENROLLMENTS,
        variables: { batch_id },
      })
      .then(({ data }) => data.courses_user_batch_enrollment);
  }

  getBatchAttendance(batchId) {
    return client
      .query({
        query: GET_BATCH_ATTENDANCE,
        variables: { batchId },
      })
      .then(({ data }) => data.courses_batch_trainee_attendences);
  }
}

const courseSvc = new CourseService();

export default courseSvc;
