import axios from "./Api";

export const getReport = async (payload) => {
  const endpoint = "get_reports";

  const data = await axios().post("/" + endpoint, { data: payload });

  return data.data;
};

export const GetOrderDetailsById = async (data) => {
  const result = await axios().post("/order-detail", data);

  return result.data;
};
