import { useState, useEffect } from "react";
import * as yup from "yup";
export default function QuestionTranslationsFormBuilder(values) {
  const [schema, setSchema] = useState({});

  useEffect(() => {
    const schema = {
      question_text: {
        type: "text",
        label: "Question",
        required: true,
        placeholder: "",
        schema: yup.string(),
      },

      question_answers: {
        label: "Options",
        type: "object",
        required: true,
        repeat: true,
        fields: {
          id: {
            type: "text",
            hidden: true,
          },
          answer_text: {
            type: "text",
            schema: yup.string(),
          },
        },
      },
    };

    setSchema(schema);
  }, [values]);

  return schema;
}
