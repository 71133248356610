import { useQuery } from "@apollo/client";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { GET_INSTITUTES } from "GraphQl/Queries/Repositories";
import { useHistory } from "react-router";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";
import Input from "components/InputGroup/Input";
import Container from "components/Container/Container";
import { successHandler } from "utils/toast";
import jsonToCsv from "utils/jsontocsv";
import { downloadCsv } from "utils/func";

export default function InstitutesTable() {
  const { loading, data, refetch } = useQuery(GET_INSTITUTES, {
    variables: {
      name: "%%",
    },
  });
  const [search, setSearch] = useState("");
  const history = useHistory();

  const columns = [
    {
      name: "ID",
      selector: (row) => `${row.id}`,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Institute Name",
      selector: (row) => `${row.name}`,
      sortable: true,
      width: "19rem",
    },
    {
      name: "Category",
      selector: (row) => `${row.category}`,
      sortable: true,
      width: "12rem",
    },
    {
      name: "District",
      selector: (row) => `${row.district}`,
      sortable: true,
      width: "14rem",
    },
    {
      name: "State",
      selector: (row) => `${row.state}`,
      sortable: true,
      width: "8rem",
    },
    {
      name: "City",
      selector: (row) => `${row.city_town}`,
      sortable: true,
      omit: true,
    },
    {
      name: "Pincode",
      selector: (row) => `${row.pincode}`,
      sortable: true,
      omit: true,
    },
  ];

  const tableActions = [
    {
      cell: (row) => (
        <button className="btn-secondary" onClick={() => editInstitute(row)}>
          Edit
        </button>
      ),
      width: "7rem",
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
    },
  ];

  const editInstitute = (row) => {
    history.push(
      getModulePathStringById(ModuleNameIdMap.createUpdateInstitute) +
        "/?instituteId=" +
        row.id
    );
  };

  const searchInstitute = () => {
    refetch({
      name: `%${search}%`,
    });
  };

  function getCSVData() {
    const csvData = data?.courses_institutes || [];
    const csv = jsonToCsv(csvData, columns);
    successHandler("Downloading...", 1000);
    downloadCsv(csv, "Institutes");
  }

  return (
    <Container title={"Institutes Table"} className="my-4 mx-4">
      <div className="flex flex-row space-x-4">
        <Input
          id="search"
          type="text"
          placeholder={"Enter Name"}
          aria-label="Search Input"
          onBlur={(e) => setSearch(e.target.value)}
        />
        <button onClick={searchInstitute} className="btn-primary">
          Search
        </button>
        <button className="btn-secondary" onClick={getCSVData}>
          Download CSV
        </button>
      </div>
      <div className="flex-row w-full">
        <DataTable
          columns={columns.concat(tableActions)}
          data={data?.courses_institutes}
          progressPending={loading}
          pagination
          persistTableHead
        />
      </div>
    </Container>
  );
}
