import { client } from "App";
import { GET_PARTNERS } from "GraphQl/Queries/Partner";

class PartnerService {
  getAllPartners() {
    return client
      .query({
        query: GET_PARTNERS,
      })
      .then(({ data }) => data.courses_partner);
  }
}

const partnerSvc = new PartnerService();

export default partnerSvc;