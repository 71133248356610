import { gql } from "@apollo/client";

export const UPSERT_INSTITUTES = gql`
  mutation upsertInstitute($objects: [courses_institutes_insert_input!]!) {
    insert_courses_institutes(
      objects: $objects
      on_conflict: {
        constraint: institutes_pkey
        update_columns: [
          name
          city_town
          location
          house_number
          district
          country
          state
          pincode
          category
        ]
      }
    ) {
      returning {
        id
        name
      }
      affected_rows
    }
  }
`;
