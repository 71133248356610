import { gql } from "@apollo/client";

export const GET_JOB_FORM_OPTIONS = gql`
  query jobFormOptions {
    courses_partner {
      about_partner
      activation_end_date
      active
      activation_start_date
      contact_person_email
      created_at
      id
      contact_person_name
      name
      logo
      identifier
      organization_name
      pin_code
      address_city_town
      address_district
      address_state
      address_country
      projects {
        id
        name
      }
    }
    courses_skillstrainer_skills {
      label
      id
    }
    courses_skillstrainer_languages {
      name
      id
    }
    courses_course_categories {
      name
      id
    }
    courses_qualifications_lookup(order_by: { name: asc }) {
      name
      id
      type
      qp_code
      code
    }
    courses_social_categories_lookup {
      name
      id
    }
    courses_genders_lookup {
      name
      id
    }
    courses_document_types_lookup {
      name
      id
    }
  }
`;

export const GET_SKILLS = gql`
  query getSkills {
    courses_skillstrainer_skills(order_by: { updated_at: desc }) {
      id
      name
      label
    }
  }
`;

export const GET_SECTOR = gql`
  query getSector {
    courses_course_categories(order_by: { updated_at: desc }) {
      id
      name
    }
  }
`;

export const GET_JOB_ROLE = gql`
  query MyQuery {
    courses_qualifications_lookup(order_by: { name: asc }) {
      id
      name
      type
      qp_code
      code
    }
  }
`;

export const GET_OTHER_PARTNER = gql`
  query MyQuery {
    courses_partner {
      id
      name
    }
  }
`;

export const GET_QUALIFICATION = gql`
  query getqualifications {
    courses_qualifications_lookup(order_by: { id: desc }) {
      id
      name
      type
      sector_id
      nsqf_level
      qp_code
    }
  }
`;

export const GET_COURSE_CATEGORY = gql`
  query getCourseCategory {
    courses_course_categories(order_by: { updated_at: desc }) {
      id
      name
    }
  }
`;

export const GET_JOBS = gql`
  query getJobs($where: courses_skillstrainer_jobs_bool_exp = {}) {
    courses_skillstrainer_jobs(where: $where, order_by: { created_at: desc }) {
      id
      title
      salary_ctc
      sector_id
      sector {
        name
      }
      job_applications_aggregate {
        aggregate {
          count
        }
      }
      is_active
      associated_job_role_id
      otherpartner_id
      no_of_positions
      partner {
        name
      }
    }
  }
`;

export const GET_QUALIFICATION_SEARCH = gql`
  query getQualification($where: courses_qualifications_lookup_bool_exp = {}) {
    courses_qualifications_lookup(where: $where, order_by: { id: desc }) {
      id
      name
      type
      sector_id
      nsqf_level
      qp_code
    }
  }
`;

export const GET_SKILL_SEARCH = gql`
  query getskills($where: courses_skillstrainer_skills_bool_exp = {}) {
    courses_skillstrainer_skills(where: $where, order_by: { id: desc }) {
      id
      name
      label
    }
  }
`;

export const GET_TOTAL_JOBS = gql`
  query getTotalJobs {
    courses_skillstrainer_jobs_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_SKILL_BY_ID = gql`
  query getSkills($id: bigint) {
    courses_skillstrainer_skills(where: { id: { _eq: $id } }) {
      id
      name
      label
    }
  }
`;

export const GET_QUALIFICATION_BY_ID = gql`
  query getQualifications($id: Int) {
    courses_qualifications_lookup(where: { id: { _eq: $id } }) {
      id
      name
      type
      sector_id
      nsqf_level
      qp_code
    }
  }
`;

export const GET_JOB_DATA_BY_ID = gql`
  query getJobDataById($jobId: uuid) {
    courses_skillstrainer_jobs(where: { id: { _eq: $jobId } }) {
      id
      partner_id
      is_active
      sector_id
      title
      no_of_positions
      is_remote
      shift_end_timing
      shift_start_timing
      salary_ctc
      social_categories
      description
      min_experience
      max_experience
      gender
      marital_status
      project_id
      min_age
      max_age
      family_income
      associated_job_role_id
    }

    courses_job_location(where: { job_id: { _eq: $jobId } }) {
      id
      job_id
      num_of_positions
      pin_code
      city
      district
      country
    }

    courses_job_required_skills_criteria(where: { job_id: { _eq: $jobId } }) {
      id
      job_id
      st_skill_id
      required
      skillstrainer_skill_id {
        name
        label
      }
    }

    courses_job_languages_required(where: { job_id: { _eq: $jobId } }) {
      id
      job_id
      language_id
      required
      can_read
      can_speak
      can_write
      language {
        name
      }
    }

    courses_job_qualifications(where: { job_id: { _eq: $jobId } }) {
      id
      job_id
      qualification_id
      required
      qualification {
        type
      }
    }

    courses_job_documents(where: { job_id: { _eq: $jobId } }) {
      id
      document_id
      required
      document {
        name
        id
      }
    }
  }
`;

export const GET_JOB_APPLICANTS_BY_ID = gql`
  query getJobApplicantData($where: courses_job_applications_bool_exp = {}) {
    courses_job_applications(where: $where) {
      status
      stage
      score
      user {
        id
        name
        email
        date_of_birth
        mobile_number
      }
      job {
        title
        partner_id
        is_active
        id
      }
    }
  }
`;

export const GET_JOB_APPLICATION_SCORE_AND_NOTES = gql`
  query getJobApplicationScoreAndNotes($jobId: uuid, $userId: bigint) {
    courses_job_applications(
      where: {
        _and: [{ job_id: { _eq: $jobId } }, { user_id: { _eq: $userId } }]
      }
    ) {
      score
      notes
    }
  }
`;

export const GET_APPLICANT_DETAILS_BY_ID = gql`
  query getApplicantDetailsById($id: bigint) {
    courses_users(where: { id: { _eq: $id } }) {
      id
      name
      mobile_number
      email
      gender
      date_of_birth
      address {
        id
        city_town
        district
        country
        state
        house_number
        pincode
      }
      user_work_details {
        id
        title
        start_date
        end_date
        place
        company_name
      }
      user_academic_qualifications {
        id
        institution_name
        qualification_name
        marks
        marking_type
        qualifications_lookup {
          name
          type
        }
      }
    }
  }
`;
