import { gql, useQuery } from "@apollo/client";
import { DATE_FORMATS } from "api/Consts";
import Container from "components/Container/Container";
import { GET_PROJECTS_BY_PARTNER_ID } from "GraphQl/Queries/AdminUserRole";
import { GET_ALL_PARTNERS } from "GraphQl/Queries/CertificateTemplateMaker";
import {
  GET_PARTNERS_BY_ID,
  GET_PARTNER_DIRECTIVES_TRANSACTION,
  GET_PARTNER_DIRECTIVES_TRANSACTION_PAGINATION,
  GET_PARTNER_SETTLEMENT,
  GET_PARTNER_WEBSITE_CONFIG_BY_PARTNER_ID,
} from "GraphQl/Queries/Partner";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";

export default function PartnerDashboard(props) {
  const { data: allPartnersRes } = useQuery(GET_ALL_PARTNERS);
  const allPartners = allPartnersRes?.courses_partner;
  const allPartnersOptions = useMemo(
    () => (allPartners || []).map((p) => ({ label: p.name, value: p.id })),
    [allPartners]
  );

  const [selectedPartnerId, setSelectedPartnerId] = useState();
  const { data: selectedPartnerRes } = useQuery(GET_PARTNERS_BY_ID, {
    variables: { id: selectedPartnerId },
  });
  const selectedPartner = selectedPartnerRes?.courses_partner[0];

  const userAccess = useUserPartnerAccessDetails();

  useEffect(() => {
    if (userAccess && userAccess.primaryPartnerId)
      setSelectedPartnerId(userAccess.primaryPartnerId);
  }, [userAccess]);

  /*
  *
  *
  * 
    Showing the follow data:
      - Projects
      - Co branding website
        - URL
        - Courses
      - Revenue
        - Summary
        - Transaction records
        - Settlement records
  * 
  * 
  * 
  */

  /*
   *
   *
   * Projects
   *
   *
   */

  const { data: projectsRes } = useQuery(GET_PROJECTS_BY_PARTNER_ID, {
    variables: { partner_id: selectedPartnerId },
  });
  const projects = projectsRes?.courses_partner_projects;

  /*
   *
   *
   * Co branding website data
   *
   *
   */
  const { data: partnerWebsiteConfigRes } = useQuery(
    GET_PARTNER_WEBSITE_CONFIG_BY_PARTNER_ID,
    { variables: { partnerId: selectedPartnerId } }
  );
  const partnerWebsiteConfig = useMemo(() => {
    if (
      partnerWebsiteConfigRes &&
      partnerWebsiteConfigRes.courses_partner_website_configuration[0]
    ) {
      const allConfig =
        partnerWebsiteConfigRes?.courses_partner_website_configuration[0];
      const config = _.pick(allConfig, [
        "subdomain",
        "website_url",
        "status",
        "edited_at",
      ]);
      allConfig.partner_website_configuration_tuples.forEach((c) => {
        config[c.partner_data_key] = c.partner_data_value;
      });
      console.log(config);
      return config;
    }
  }, [partnerWebsiteConfigRes]);

  /*
   *
   *
   * Revenue
   *
   *
   */

  // Course purchase Transactions
  const { data: coursePurchaseTransactionsRes } = useQuery(
    GET_PARTNER_DIRECTIVES_TRANSACTION,
    { variables: { where: { partner_id: { _eq: selectedPartnerId || -1 } } } }
  );
  const coursePurchaseTransactions =
    coursePurchaseTransactionsRes?.courses_partner_revenue_split_transactions;

  // Settlement transactions
  const { data: partnerSettlementTransactionsRes } = useQuery(
    GET_PARTNER_SETTLEMENT,
    {
      variables: { where: { partner_id: { _eq: selectedPartnerId || -1 } } },
    }
  );
  const partnerSettlementTransactions =
    partnerSettlementTransactionsRes?.courses_partner_settlement_transactions;

  // Calculations
  const summary =
    (coursePurchaseTransactions || []).reduce(
      (acc, t) => acc + ((t.order && t.order.amount) || 0),
      0
    ) -
    (partnerSettlementTransactions || []).reduce(
      (acc, t) => acc + (t.amount || 0),
      0
    );
  const borrower = summary > 0 ? "SkillsTrainer" : selectedPartner?.name;
  const lender = summary > 0 ? selectedPartner?.name : "SkillsTrainer";

  const TABLE = { course_purchase: "0", settlement: "1" };
  const [currentTable, setCurrentTable] = useState(TABLE.course_purchase);

  /*
   *
   *
   * =================== VIEW ====================
   *
   *
   */

  return (
    <Container title={"Partner Dasboard"} className="px-4 my-4 mx-1">
      {userAccess && userAccess.isAdmin && (
        <select
          onChange={(e) => setSelectedPartnerId(e.target.value)}
          className="input-primary"
          value={selectedPartnerId}
        >
          <option>Choose a partner</option>
          {allPartnersOptions.map((opt) => (
            <option value={opt.value}>{opt.label}</option>
          ))}
        </select>
      )}

      <div className="mt-2">
        <h4 className="mb-4 mt-5 font-semibold">Details</h4>
        <div className="flex gap-5">
          {selectedPartner &&
            getObjectDetailComponents(selectedPartner, {
              id: "ID",
              name: "Name",
              contact_person_name: "Contact Person's Name",
              contact_person_email: "Contact Person's Email",
              pin_code: "Pin code",
            })}
        </div>
      </div>

      <div className="mt-2">
        <h4 className="mb-4 mt-5 font-semibold">Website configuration</h4>
        <div className="flex gap-5">
          {partnerWebsiteConfig &&
            getObjectDetailComponents(partnerWebsiteConfig, {
              subdomain: "Subdomain",
              website_url: {
                label: "Website link",
                format: (link) => (
                  <a href={link} rel="noreferrer" target="_blank">
                    {link}
                  </a>
                ),
              },
              status: "Publish Status",
              edited_at: {
                label: "Last edited at",
                format: (date) =>
                  moment(date).format(DATE_FORMATS.BATCH_SLOT_DATE_TIME),
              },
            })}
        </div>
      </div>

      <div className="mt-2">
        <h4 className="mb-4 mt-5 font-semibold">Revenue settlement</h4>
        <div className="text-lg">
          {lender} is owed{" "}
          <span className="font-bold">Rs. {Math.abs(summary)}</span> by{" "}
          {borrower}
        </div>
        <div className="flex gap-2 mt-3">
          <button
            onClick={() => setCurrentTable(TABLE.course_purchase)}
            className={
              currentTable === TABLE.course_purchase
                ? "btn-primary"
                : "font-semibold bg-gray-300 px-3 py-2 text-sm rounded"
            }
          >
            Course purchase transactions
          </button>

          <button
            onClick={() => setCurrentTable(TABLE.settlement)}
            className={
              currentTable === TABLE.settlement
                ? "btn-primary"
                : "font-semibold bg-gray-300 px-3 py-2 text-sm rounded"
            }
          >
            Settlement transactions
          </button>
        </div>
        <div className="mt-4">
          {currentTable === TABLE.course_purchase ? (
            <PaginationTable
              tableName={"courses_partner_revenue_split_transactions"}
              whereClause={{
                partner_id: { _eq: selectedPartnerId },
                order: {
                  id: {
                    _is_null: false,
                  },
                },
              }}
              columns={[
                {
                  name: "Course",
                  selector: (row) => row.order.ordercourse.full_name,
                },
                {
                  name: "Paid Amount (Rs.)",
                  selector: (row) => row.order.amount,
                },
                {
                  name: "Commission",
                  selector: (row) =>
                    `${
                      row.order.amount *
                      ((row.directive && row.directive.commission) || 0) *
                      0.01
                    } (${(row.directive && row.directive.commission) || 0}%)`,
                },
                {
                  name: "User email",
                  selector: (row) => row.order.user.email,
                },
              ]}
              gqlQuery={GET_PARTNER_DIRECTIVES_TRANSACTION_PAGINATION}
              itemsPerPage={1}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </Container>
  );
}

const getObjectDetailComponents = (obj, keyLabelMap) => {
  return Object.keys(keyLabelMap).map((key) => {
    if (typeof obj[key] !== "object") {
      const keyLabelInstance = keyLabelMap[key];
      let label = keyLabelInstance,
        value = obj[key];

      if (keyLabelInstance && typeof keyLabelInstance === "object") {
        label = keyLabelInstance.label;
        if (typeof keyLabelInstance.format === "function")
          value = keyLabelInstance.format(value);
      }

      return (
        <div className="flex flex-col">
          <div className="mb-2 font-semibold">{label}</div>
          <div className="mb-2">{value}</div>
        </div>
      );
    }
    return null;
  });
};

const PaginationTable = ({
  tableName,
  whereClause,
  gqlQuery,
  columns,
  itemsPerPage = 10,
}) => {
  const { data: aggregationRes } = useQuery(
    gql`
    query aggregation ($whereClause: ${tableName}_bool_exp) {
      ${tableName}_aggregate (
        where: $whereClause,
      ) {
        aggregate {
          count
        }
      }
    }
  `,
    { variables: { whereClause } }
  );
  const totalCount = useMemo(
    () =>
      aggregationRes &&
      aggregationRes[`${tableName}_aggregate`].aggregate.count,
    [aggregationRes, tableName]
  );

  const [pageIndex, setPageIndex] = useState(0);
  const { data: recordsRes } = useQuery(gqlQuery, {
    variables: {
      whereClause,
      offset: pageIndex * itemsPerPage,
      limit: (pageIndex + 1) * itemsPerPage,
    },
  });
  const records = recordsRes && recordsRes[tableName];
  const handlePageChange = (targetPageIndex) => setPageIndex(targetPageIndex);

  return (
    <DataTable
      columns={columns}
      data={records}
      pagination
      paginationServer
      paginationTotalRows={totalCount}
      paginationRowsPerPageOptions={[itemsPerPage]}
      onChangeRowsPerPage={console.log}
      onChangePage={handlePageChange}
    />
  );
};
