import { useQuery } from "@apollo/client";
import { GET_APPLICANT_DETAILS_BY_ID } from "GraphQl/Queries/Jobs";

function ApplicantDetails(props) {
  const { userId } = props;
  const { data: applicantData } = useQuery(GET_APPLICANT_DETAILS_BY_ID, {
    variables: {
      id: userId,
    },
  });

  function convertDate(dateString) {
    let date = new Date(dateString);
    let month = date.toLocaleString("default", { month: "long" });
    let year = date.getFullYear();
    return `${month} ${year}`;
  }

  return (
    <div className="border-2 border-gray-200 p-2">
      {applicantData ? (
        <div className="space-y-4 mx-2">
          <table className="w-full font-poppins text-md text-left space-y-2">
            <span className="font-semibold text-xl px-2 cursor-pointer border-2 border-gray-200">
              Basic Details
            </span>
            <tr className="border-2 border-gray-200  rounded-lg">
              <td className="p-2">
                <span className="font-semibold p-1">Name:</span>
                {applicantData?.courses_users[0].name}
                <br />
                <span className="font-semibold p-1">Date of Birth:</span>
                {applicantData?.courses_users[0].date_of_birth}
                <br />
              </td>
              <td className="p-2">
                <span className="font-semibold p-1">Email:</span>
                {applicantData?.courses_users[0].email}
                <br />
                <span className="font-semibold p-1">Mobile Number:</span>
                {applicantData?.courses_users[0].mobile_number}
                <br />
              </td>
            </tr>
          </table>
          <table className="w-full font-poppins text-md text-left ">
            <span className="font-semibold text-xl px-2 cursor-pointer border-2 border-gray-200">
              Address
            </span>
            {applicantData?.courses_users[0].address?.pincode ||
            applicantData?.courses_users[0].address?.state ||
            applicantData?.courses_users[0].address?.district ||
            applicantData?.courses_users[0].address?.city_town ? (
              <tr className="border-2 border-gray-200 rounded-full">
                <td className="p-2">
                  <span className="font-semibold p-1">State:</span>
                  {applicantData?.courses_users[0].address.state || (
                    <span className="text-gray-400">Not Found</span>
                  )}
                  <br />
                  <span className="font-semibold p-1">District:</span>
                  {applicantData?.courses_users[0].address.district || (
                    <span className="text-gray-400">Not Found</span>
                  )}
                  <br />
                </td>
                <td className="p-2">
                  <span className="font-semibold p-1">Town:</span>
                  {applicantData?.courses_users[0].address.city_town || (
                    <span className="text-gray-400">Not Found</span>
                  )}
                  <br />
                  <span className="font-semibold p-1">Pincode:</span>
                  {applicantData?.courses_users[0].address.pincode || (
                    <span className="text-gray-400">Not Found</span>
                  )}
                  <br />
                </td>
              </tr>
            ) : (
              <tr className="text-gray-400 border-2 border-gray-200">
                <td className="p-2">No address to show</td>
              </tr>
            )}
          </table>
          <table className="w-full font-poppins text-md text-left">
            <span className="font-semibold text-xl px-2 cursor-pointer border-2 border-gray-200">
              Work Experience
            </span>

            {applicantData?.courses_users[0].user_work_details.length === 0 ? (
              <tr className="text-gray-400 border-2 border-gray-200  ">
                <td className="p-2">No work experience to show</td>
              </tr>
            ) : (
              applicantData?.courses_users[0].user_work_details.map((work) => {
                return (
                  <tr className="" key={work.id}>
                    <td className=" border-2 border-gray-200 rounded-lg p-2">
                      <span className="font-bold text-md">
                        {work.title || ""}
                      </span>
                      <br />
                      <span className="font-medium">
                        {work.company_name || ""}
                      </span>
                      <br />
                      <span>{work.place || ""}</span> <br />
                      <span>
                        {convertDate(work.start_date)} -{" "}
                        {convertDate(work.end_date)}
                      </span>
                      <br />
                    </td>
                  </tr>
                );
              })
            )}
          </table>
          <table className="w-full font-poppins text-md text-left">
            <span className="font-semibold text-xl px-2 cursor-pointer border-2 border-gray-200">
              Academic Qualifications
            </span>

            {applicantData?.courses_users[0].user_academic_qualifications
              .length === 0 ||
            applicantData?.courses_users[0].user_academic_qualifications.filter(
              (q) => q.marks && q.institution_name
            ).length === 0 ? (
              <tr className="text-gray-400 border-2 border-gray-200  ">
                <td className="p-2">No academic qualifications to show</td>
              </tr>
            ) : (
              applicantData?.courses_users[0].user_academic_qualifications
                .filter(
                  (q) =>
                    (q.qualifications_lookup?.type === "12" ||
                      q.qualifications_lookup?.type === "pre_12" ||
                      q.qualifications_lookup?.type === "bachelors" ||
                      q.qualifications_lookup?.type === "masters") &&
                    q.marks &&
                    q.institution_name
                )
                .map((q) => {
                  return (
                    <tr className="" key={q.id}>
                      <td className=" border-2 border-gray-200 rounded-lg p-2">
                        <span className="font-bold text-md">
                          {q.qualification_name || ""}
                        </span>
                        <br />
                        <span className="font-medium">
                          {q.institution_name || ""}
                        </span>
                        <br />
                        <span className="font-semibold ">Marks: </span>
                        {q.marks || ""}%
                        <br />
                      </td>
                    </tr>
                  );
                })
            )}
          </table>
          <table className="w-full font-poppins text-md text-left">
            <span className="font-semibold text-xl px-2 cursor-pointer border-2 border-gray-200">
              Technical Qualifications
            </span>
            {applicantData?.courses_users[0].user_academic_qualifications
              .length === 0 ||
            applicantData?.courses_users[0].user_academic_qualifications.filter(
              (q) =>
                (q.qualifications_lookup?.type === "iti" ||
                  q.qualifications_lookup?.type === "diploma" ||
                  q.qualifications_lookup?.type === "nsqf") &&
                q.marks &&
                q.qualifications_lookup?.name
            ).length === 0 ? (
              <tr className="text-gray-400 border-2 border-gray-200  ">
                <td className="p-2">No technical qualifications to show</td>
              </tr>
            ) : (
              applicantData?.courses_users[0].user_academic_qualifications
                .filter(
                  (q) =>
                    (q.qualifications_lookup?.type === "iti" ||
                      q.qualifications_lookup?.type === "diploma" ||
                      q.qualifications_lookup?.type === "nsqf") &&
                    q.marks &&
                    q.qualifications_lookup?.name
                )
                .map((q) => {
                  return (
                    <tr className="" key={q.id}>
                      <td className=" border-2 border-gray-200 rounded-lg p-2">
                        <span className="font-bold text-md">
                          {q.qualifications_lookup?.name || ""}
                        </span>
                        <br />
                        <span className="font-medium">
                          {q.institution_name || ""}
                        </span>
                        {q.institution_name && <br />}
                        <span className="font-semibold ">Marks: </span>
                        {q.marks || ""}%
                        <br />
                      </td>
                    </tr>
                  );
                })
            )}
          </table>
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
}

export default ApplicantDetails;
