import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router";
import queryString from "query-string";

export default function UnEnrolledUserTable(props) {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const assignCourseId =
    props.users &&
    props.users.map((obj) => {
      return {
        ...obj,
        course_id: parseInt(params.id),
      };
    });

  const filterUnenrolledUsers =
    assignCourseId &&
    assignCourseId.filter(
      (elem) =>
        !props.enrolledUsers?.find(
          ({ user_id, course_id }) =>
            elem.id === user_id && elem.course_id === course_id
        )
    );

  // console.log(filterUnenrolledUsers);
  const columns = [
    {
      name: "User Id",
      selector: (row, index) => `${row.id}`,
      sortable: true,
    },
    {
      name: "User Name",
      selector: (row, index) => `${row.name}`,
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row, index) => `${row.email}`,
      sortable: true,
    },

    {
      cell: (row) => (
        <button
          className="bg-gray-700 p-2 text-base text-white"
          onClick={() => props.userManualEnrollmentFun(row.id)}
        >
          Enroll User
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 100,
  });

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });
  return (
    <DataTable
      columns={columns}
      data={filterUnenrolledUsers ? filterUnenrolledUsers : []}
      pagination
      paginationTotalRows={1000}
      selectableRows={false}
      selectableRowsHighlight={false}
      onChangeRowsPerPage={handlePerRowsChange}
      paginationRowsPerPageOptions={[20, 100, 200, 250]}
      onChangePage={handlePageChange}
    />
  );
}
