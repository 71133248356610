import React, { useEffect, useState } from "react";
import { useQuery, useMutation, empty } from "@apollo/client";
import { useLocation } from "react-router";
import queryString from "query-string";

import { CREATE_COURSE_SECTION_TOPICS } from "GraphQl/Mutations/coursebuildemutation/CourseModule";
import { Container } from "react-bootstrap";
import QuizForm from "./QuizForm";
import McqQuiz from "../Quiz/McqQuiz";
import CourseQuiz from "./CourseQuiz";
import QuizAction from "./QuizAction";
import CreateQuestion from "Pages/Dashboard/Questions/CreateQuestion";
import courseBuilderService from "services/coursebuild/course";
import { useHistory } from "react-router-dom";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";

export default function QuizActivity(props) {
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [quizData, setQuizData] = useState([]);
  const [message, setMessage] = useState();
  const [quizmap, setQuizMap] = useState({
    mapping_id: null,
    course_id: null,
  });

  const [addQuestionMode, setQuestionMode] = useState("");
  const [restriction, setRestriction] = useState(false);

  const [optionsEnable, setoptions] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [Activity, SetActivity] = useState(false);
  const [timer, SetTimer] = useState(false);

  const [insert_course_module_section, { insert_error, data }] = useMutation(
    CREATE_COURSE_SECTION_TOPICS
  );

  const updateCheckbox = (e) => {
    const key = e.target.getAttribute("data-key");
    const value = e.target.checked;

    if (key == "enable_timer") {
      SetTimer(value);
    }

    if (key == "enable_restriction") {
      setRestriction(value);
    }
    setQuizData({ ...quizData, [key]: value });
  };

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const updateValue = (e) => {
    const key = e.target.getAttribute("data-key");
    const value = e.target.value;
    if (key == "completion_criteria" && value == 1) {
      SetActivity(true);
    } else if (key == "completion_criteria" && value == 0) {
      SetActivity(false);
    }

    if (key == "completion_percentage") {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        setQuizData({ ...quizData, [key]: value });
      }
    } else {
      setQuizData({ ...quizData, [key]: value });
    }
  };

  const criteriaOption = [
    { value: "view", label: "On View" },
    { value: "failed", label: "Failed" },
    { value: "passed", label: "Passed" },
  ];

  const submitQuiz = async (status) => {
    console.log("data", status);

    var formData = { ...quizData };
    if (
      formData.name === "" ||
      formData.name === null ||
      formData.name === undefined
    ) {
      setMessage("Quiz name is required");
      return;
    }

    formData["course_id"] = params.id;
    formData["mod_id"] = params.modid;
    formData["section_id"] = params.secid;
    formData["created_date"] = new Date();
    formData["restriction_ids"] = selectedValue.toString();
    formData["timelimit_number"] = formData["enable_timer"]
      ? formData["timelimit"] * formData["timelimit_unit"]
      : 0;
    formData["enable_user_attempt"] = formData?.enable_user_attempt
      ? formData?.enable_user_attempt
      : false;

    const insertData = await courseBuilderService.CreateCourseTopics(formData);
    console.log("insertData", insertData);
    if (status.return) {
      let path =
        getModulePathStringById(ModuleNameIdMap.viewCourse) +
        `?id=${params.id}`;
      history.push(path);
    } else {
      if (insertData) {
        const quizid =
          formData.enable_restriction && formData.restriction_ids.length > 0
            ? insertData.mapping_id
            : insertData.id;

        let path =
          getModulePathStringById(ModuleNameIdMap.editQuestion) +
          `/?id=${params.id}&&quizid=${quizid}`;
        history.push(path);
      }
    }
  };

  useEffect(() => {
    if (data && data.insert_courses_course_module_mapping) {
      setQuizMap({
        mapping_id: data.insert_courses_course_module_mapping.returning[0].id,
        course_id: params.id,
      });

      setoptions(true);
      setQuestionMode("");
    }
  }, [data]);

  console.log("addQuestion", addQuestionMode);
  const setmode = (mode) => {
    setQuestionMode(mode);
  };

  return (
    <Container>
      {!optionsEnable && !addQuestionMode ? (
        <QuizForm
          submitQuiz={submitQuiz}
          criteriaOption={criteriaOption}
          updateValue={updateValue}
          message={message}
          quizData={quizData}
          updateCheckbox={updateCheckbox}
          handleChange={handleChange}
          topic={props}
          restriction={restriction}
          Activity={Activity}
          timer={timer}
          action="new"
          selectedValue={selectedValue}
        />
      ) : optionsEnable && !addQuestionMode ? (
        <QuizAction setmode={setmode} />
      ) : optionsEnable && addQuestionMode == "add_new" ? (
        <CreateQuestion quizmap={quizmap} />
      ) : optionsEnable && addQuestionMode == "question_bank" ? (
        <CourseQuiz quizmap={quizmap} />
      ) : (
        ""
      )}
    </Container>
  );
}
