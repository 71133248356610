import "grapesjs/dist/css/grapes.min.css";
import { useEffect, useState } from "react";
import grapesjs from "grapesjs";
import gjsWebpagePreset from "grapesjs-preset-webpage";
import gjsBlocksBasic from "grapesjs-blocks-basic";
import gjsPluginForms from "grapesjs-plugin-forms";
import { uploadFile as uploadFileApi } from "api/GrapesUploadApi";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_CERTIFICATE_TEMPLATE,
  UPDATE_CERTIFICATE_TEMPLATE,
} from "GraphQl/Mutations/CertificateTemplateMaker";
import {
  GET_CERTIFICATE_TEMPLATES,
  GET_ALL_PARTNERS,
} from "GraphQl/Queries/CertificateTemplateMaker";
import { successHandler, errorHandler } from "utils/toast";
import Select from "components/InputGroup/Select";
import Container from "components/Container/Container";

function NewCertificateTemplateMaker() {
  const [loadedTemplateName, setLoadedTemplateName] = useState();
  const [certificateTemplateData, setCertificateTemplateData] = useState({});
  const [chosenCertTemplate, setChosenCertTemplate] = useState(-1);
  const [editor, setEditor] = useState();
  const qrCodeLogo =
    '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="64" height="53.426" viewBox="0 0 58 36"><path d="M8.4 11.661V18.834h14.295V4.487H8.4v7.174zm11.426 0V15.965H11.243l0.01 -4.06c0 -2.228 0.01 -4.163 0.01 -4.304l0.005 -0.245h8.557v4.304z"/><path d="M14.087 11.661V13.096h2.87v-2.87h-2.87v1.435zm11.479 0V18.834h14.295V4.487H25.565v7.174zm11.426 0V15.965H28.434V7.356h8.557v4.304z"/><path d="M31.252 11.661V13.096h2.87v-2.87h-2.87v1.435zM8.385 21.689c-0.005 0.005 -0.005 3.224 0.005 7.159L8.4 36h14.295V21.705l-7.153 -0.01c-3.934 -0.01 -7.153 -0.01 -7.159 -0.005zM19.826 28.842V33.13l-4.289 -0.01 -4.284 -0.016 -0.005 -4.262 -0.005 -4.262 0.26 -0.016c0.146 -0.01 2.076 -0.016 4.289 -0.016H19.826v4.294z"/><path d="M14.087 28.826V30.261h2.87v-2.87h-2.87v1.435zm11.479 0.01V36h2.87V27.391h2.817v2.87h8.609V21.678l-1.435 0.016 -1.435 0.016V24.521h-2.87v-2.817l-4.278 -0.01 -4.278 -0.016v7.159z"/><path d="M31.252 34.565V36h2.87v-2.87h-2.87v1.435zm5.739 0V36h2.87v-2.87h-2.87v1.435z"/></svg>';
  const {
    loading: certiTempLoading,
    data: certiTempData,
    refetch: refetchCertificatetemplates,
  } = useQuery(GET_CERTIFICATE_TEMPLATES);

  const { data: partnersData } = useQuery(GET_ALL_PARTNERS);
  const [insertCertificateTemplate] = useMutation(CREATE_CERTIFICATE_TEMPLATE);
  const [updateCertificateTemplate] = useMutation(UPDATE_CERTIFICATE_TEMPLATE);

  useEffect(() => {
    const editor = grapesjs.init({
      container: "#gjs",
      height: "600px",
      width: "auto",
      dragMode: "absolute",
      plugins: [gjsWebpagePreset, gjsBlocksBasic, gjsPluginForms],
      pluginsOpts: {
        [gjsWebpagePreset]: {},
        [gjsBlocksBasic]: {},
        [gjsPluginForms]: {},
      },
      blockManager: {},
      domComponents: {},
      assetManager: {
        assets: [],
        uploadFile: async (fileData) => {
          await uploadFileApi(
            fileData.target.files[0],
            fileData.target.files[0].name,
            "public-assets",
            true
          )
            .then((res) => {
              editor.AssetManager.add({
                src: res.s3_url,
                "data-key": res.data_url,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        },
      },
    });

    //Event Listner to add data-key attribute to an image when uploaded
    editor.on("asset:add", (asset) => {
      const dataKey = asset.attributes["data-key"];
      editor.on("component:update:src", (component) => {
        component.is("image") &&
          component.addAttributes({ "data-key": dataKey });
      });
    });

    //cleans out editor
    editor.on("load", () => {
      editor.DomComponents.clear();
      editor.CssComposer.clear();
      editor.UndoManager.clear();
    });

    editor.BlockManager.add("qr_code", {
      label: "QR Code",
      category: "Basic",
      media: qrCodeLogo,
      content: `
      <img height="120px" width="120px" data-qr-code="true" src="https://adminskillstrainerprod.s3.ap-south-1.amazonaws.com/public-assets/qrcode.png">
      `,
    });

    setEditor(editor);
  }, []);

  const loadCertificateTemplate = (id) => {
    if (id == -1) {
      editor.DomComponents.clear();
      editor.CssComposer.clear();
      editor.UndoManager.clear();
      certificateTemplateData.name = "";
      certificateTemplateData.id = undefined;
      certificateTemplateData.partner_id = "";
      setCertificateTemplateData({ ...certificateTemplateData });
    } else {
      const template = certiTempData?.courses_cert_templates.find(
        (certi_template) => {
          if (certi_template.id == id) return certi_template;
        }
      );

      editor.loadProjectData(template.template_data);
      certificateTemplateData.name = template.name;
      certificateTemplateData.id = template.id;
      certificateTemplateData.partner_id = template.partner_id;
      setLoadedTemplateName(template.name);
      setCertificateTemplateData({ ...certificateTemplateData });
    }
  };

  const saveAsNewTemplate = () => {
    if (
      certificateTemplateData.name.trim() === loadedTemplateName.trim() ||
      certificateTemplateData.name.trim() === ""
    ) {
      errorHandler("Enter a different name for new template");
      setCertificateTemplateData({
        ...certificateTemplateData,
        name: "",
        id: undefined,
      });
      setChosenCertTemplate(-1);
    } else {
      certificateTemplateData.id = undefined;
      setCertificateTemplateData({ ...certificateTemplateData });
      submitCertificateTemplate();
    }
  };

  const submitCertificateTemplate = async () => {
    const certiData = editor.getProjectData();
    //getting html and css
    const html = editor.getHtml();
    const css = editor.getCss();
    const fontCssString = `  @font-face {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2)
        format("woff2");
      unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9,
        U+25CC, U+A830-A839, U+A8E0-A8FF;
    }
    /* latin-ext */
    @font-face {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2)
        format("woff2");
      unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
        U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2)
        format("woff2");
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    body {
      font-family: "Poppins", Arial, Helvetica, sans-serif;
    }`;

    const linkTag = `<link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Poppins"
    />`;
    const templateHtml =
      `<html><head><meta charset="utf-8"><style>` +
      linkTag +
      fontCssString +
      css +
      "</style></head>" +
      html +
      "</html>";

    if (certificateTemplateData.id) {
      if (certificateTemplateData.name === "")
        errorHandler("Enter Template Name");
      else
        await updateCertificateTemplate({
          variables: {
            id: certificateTemplateData.id,
            name: certificateTemplateData.name,
            template_data: certiData,
            html_data: templateHtml,
            partner_id: certificateTemplateData.partner_id,
          },
        })
          .then(() => {
            successHandler("Template Updated Succesfully");
            editor.DomComponents.clear();
            editor.CssComposer.clear();
            editor.UndoManager.clear();
            certificateTemplateData.name = "";
            certificateTemplateData.id = undefined;
            certificateTemplateData.partner_id = "";
            setCertificateTemplateData({ ...certificateTemplateData });
            setChosenCertTemplate(-1);
            refetchCertificatetemplates();
          })
          .catch((err) => {
            console.log(err);
            errorHandler(
              "There was an error while updating Certificate Template"
            );
          });
    } else {
      if (!certificateTemplateData.name) errorHandler("Enter Template Name");
      else
        await insertCertificateTemplate({
          variables: {
            name: certificateTemplateData.name,
            template_data: certiData,
            html_data: templateHtml,
            partner_id: certificateTemplateData.partner_id,
          },
        })
          .then(() => {
            successHandler("Certificate Template Created Successfully");
            editor.DomComponents.clear();
            editor.CssComposer.clear();
            editor.UndoManager.clear();
            certificateTemplateData.name = "";
            certificateTemplateData.id = undefined;
            certificateTemplateData.partner_id = "";
            setCertificateTemplateData({ ...certificateTemplateData });
            setChosenCertTemplate(-1);
            refetchCertificatetemplates();
          })
          .catch((err) => {
            console.log("This is an Error", err);
            errorHandler(
              "There was an error while creating Certificate Template"
            );
          });
    }
  };

  return (
    <Container className="my-4 mx-1" title={"Certificate Template Maker"}>
      {!certiTempLoading && (
        <Select
          label="Certificate Template"
          className="my-4 mx-4 w-1/2"
          options={certiTempData?.courses_cert_templates}
          value={chosenCertTemplate}
          valueField={"id"}
          displayField={"name"}
          onChange={(e) => {
            setChosenCertTemplate(e.target.value);
            loadCertificateTemplate(e.target.value);
          }}
        />
      )}

      <div
        id="gjs"
        className=" ml-4 border-4 rounded-md border-black w-full h-full"
      ></div>
      <div className="grid grid-cols-3 gap-4">
        <div className="">
          <h6 className="mt-4 ml-4">Template Name </h6>

          <input
            type="text"
            value={certificateTemplateData.name}
            onChange={(e) => {
              certificateTemplateData.name = e.target.value;
              setCertificateTemplateData({ ...certificateTemplateData });
            }}
            label="Certificate Template Name"
            className="w-full ml-4"
          ></input>
        </div>
        <div className="">
          <Select
            label="Partner"
            className="w-full mt-4 ml-4"
            value={certificateTemplateData.partner_id}
            options={partnersData?.courses_partner}
            valueField={"id"}
            displayField={"name"}
            onChange={(e) => {
              certificateTemplateData.partner_id = e.target.value;
              setCertificateTemplateData({ ...certificateTemplateData });
            }}
          />
        </div>
        <div className="mt-4">
          <button
            className="btn-primary mt-4 ml-4 hover:opacity-70"
            onClick={submitCertificateTemplate}
          >
            Save
          </button>
          <button
            className={`btn-secondary ml-10 mt-2 ${
              certificateTemplateData.id ? "" : "hidden"
            }`}
            onClick={saveAsNewTemplate}
          >
            Save As New Template
          </button>
        </div>
      </div>
    </Container>
  );
}

export default NewCertificateTemplateMaker;
