import React, { useEffect } from "react";
import { api } from "../../../api/User";
import Container from "../../../components/Container/Container";
import { Form } from "skillstrainer-resource-library";
import InstructorFormBuilder from "formBuilders/InstructorFormBuilder";
import { errorHandler, successHandler } from "utils/toast";
import {
  CREATE_USER_MUTATION,
  GET_USER_BY_EMAIL,
} from "GraphQl/Mutations/CreateUser";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import usePromise from "utils/hooks/usePromise";
import Axios from "../../../api/Api";
import Toast from "wrappers/Toast";

function CreateInstructor() {
  const [insertUser] = useMutation(CREATE_USER_MUTATION, {});

  const { run: handleSubmit, loading: submittingForm } = usePromise(
    async (instructor, e) => {
      const mobile_number = "";

      try {
        let loader = Toast.load("Please wait...");
        const email = instructor.email;
        const mobile_number = instructor.mobile_number;
        const traineeSearchResponse = await Axios()
          .post("/search_trainees", {
            email,
            mobile_number,
          })
          .then(({ data }) => data);
        console.log("traineeSearchResponse==", traineeSearchResponse);

        const { data: duplicateTrainees } = traineeSearchResponse;
        Toast.endLoader(loader);

        if (duplicateTrainees?.length) {
          const forceCreate = await Toast.confirm(
            <DuplicateTraineesMessage duplicateTrainees={duplicateTrainees} />
          );
          console.log("forceCreate==", forceCreate);
          if (!forceCreate) return;
        }
        const res = await api("create_instructor", instructor).then((data) => {
          console.log("data", data);
          if (data?.success === false) {
            errorHandler(data?.message);
          }
          const idp_user_id =
            data?.response?.data?.insert_courses_instructor_one?.idp_user_id;
          const idp_user_name =
            data?.response.data?.insert_courses_instructor_one?.idp_user_name;

          if (instructor.mobile_number === undefined) {
            instructor.mobile_number = mobile_number;
          }

          const user = {
            name: instructor.name,
            email: instructor.email,
            partner_id: instructor.partner_id,
            mobile_number: instructor.mobile_number,
            activation_start_date: instructor.activation_start_date,
            active: instructor.active,
            idp_user_id: idp_user_id,
            idp_user_name: idp_user_name,
          };
          insertUser({ variables: { user } });
          successHandler("New Trainer has been created");
        });
      } catch (err) {
        console.log("i am err", err);
      }
    }
  );
  return (
    <Container title={"Create / Update Trainer"}>
      <Form
        formBuilder={InstructorFormBuilder}
        className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
        submitButton={{
          text: submittingForm ? "submitting..." : "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
          disabled: submittingForm,
        }}
        onSubmit={(instructor) => handleSubmit(instructor)}
      />
    </Container>
  );
}

export default CreateInstructor;
const DuplicateTraineesMessage = ({ duplicateTrainees }) => (
  <div>
    <p>We have found trainers with the same email / phone number:</p>
    <ul>
      {duplicateTrainees.map((dt) => (
        <li key={dt.username}>
          <span className="font-semibold">{dt.name.familyName}</span> (
          {dt.username})
        </li>
      ))}
    </ul>

    <p>Force create a new user?</p>
  </div>
);
