import Container from "components/Container/Container";
import { useEffect, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";
import Papa from "papaparse";
import DataTable from "react-data-table-component";
import { useMutation, useLazyQuery } from "@apollo/client";
import { uploadFile, getFile } from "api/UploadFile";
import { CREATE_COURSES_ADMIN_CSV_USER_IMPORTS } from "GraphQl/Mutations/OnboardingUsers";
import { GET_ADMIN_CSV_USER_IMPORTS_QUIZ } from "GraphQl/Queries/OnboardingUsers";
import { useRecoilState } from "recoil";
import { gqlUserRecoilVar } from "State/User";

export default function BulkQuizResponseHandler() {
  const [user] = useRecoilState(gqlUserRecoilVar);

  const [file, setFile] = useState();
  const [csvData, setCsvData] = useState();
  const [users, setUsers] = useState();
  const [columns, setColumns] = useState();
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const [createBulkQuiz, { error: bulkError, loading: bulkLoading }] =
    useMutation(CREATE_COURSES_ADMIN_CSV_USER_IMPORTS, {
      refetchQueries: [GET_ADMIN_CSV_USER_IMPORTS_QUIZ],
    });

  const [runQuery, tableData] = useLazyQuery(GET_ADMIN_CSV_USER_IMPORTS_QUIZ, {
    fetchPolicy: "network-only",
  });

  const importsColumns = [
    {
      name: "Id",
      cell: (row) => `${row.id}`,
    },
    {
      name: "Created",
      cell: (row) => `${row.created}`,
    },
    {
      name: "Failed",
      cell: (row) => `${row.failed}`,
    },
    {
      name: "File url",
      grow: 5,
      cell: (row) => row.file_url,
    },
    {
      name: "Trigger Status",
      grow: 5,
      cell: (row) => (
        <p
          className={`${
            importColumnsStatusColors[row.status] !== undefined && "text-white"
          } ${importColumnsStatusColors[row.status]}  rounded p-2 m-1`}
        >
          {row.status}
        </p>
      ),
    },
    {
      name: "Created At",
      grow: 5,
      cell: (row) => row.created_at,
    },

    {
      name: "",
      grow: 2.5,
      cell: (row) => (
        <button
          onClick={(e) => handleDownload(row)}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Download
        </button>
      ),
    },
  ];

  const importColumnsStatusColors = {
    pending: "bg-gray-400",
    running: "bg-orange",
    processed: "bg-green-500",
    failed: "bg-red-500",
  };

  const handleDownload = async (row) => {
    const url = await getFile(row.file_url);
    window.open(url, "_blank");
  };

  const handleCsvUpload = (fileData) => {
    setFile(fileData);
    Papa.parse(fileData, {
      header: true,
      transformHeader: (header) => {
        return header.toLowerCase().replace(" ", "_");
      },
      delimiter: ",",
      complete: (res) => {
        formatTableData(res.data);
      },
      skipEmptyLines: true,
    });

    let reader = new FileReader();
    reader.onload = function (output) {
      setCsvData({
        csv_data: output.target.result,
        csv_name: fileData.name,
      });
    };
    reader.readAsDataURL(fileData);
  };

  useEffect(() => {
    runQuery();
  }, []);

  const refreshUser = () => {
    runQuery();
  };

  const generateBulkQuizResponse = async () => {
    const response = await uploadFile(
      csvData.csv_data,
      csvData.csv_name,
      "usercsv"
    );

    const userImportRecord = {
      status: "pending",
      file_url: response.data_url,
      purpose: "quiz_responses",
      uploader_id: user.id,
    };

    createBulkQuiz({
      variables: {
        record: userImportRecord,
      },
    });
    if (!bulkLoading && !bulkError) setUploadSuccess(true);
  };

  const formatTableData = (csvData) => {
    let columns = Object.keys(csvData[0]);
    setColumns(
      columns.map((column, index) => {
        return {
          name: column,
          cell: (row) => row[index],
          grow: 4,
        };
      })
    );
    setUsers(
      csvData.map((item) => {
        return Object.values(item);
      })
    );
  };

  return (
    <Container title={"Bulk Quiz Response Generator"} className="my-4 mx-2">
      <div className="flex flex-col ">
        <h5 className="ml-4 mt-2">Download Sample CSV Format</h5>
        <a
          href="https://adminskillstrainer.s3.ap-south-1.amazonaws.com/sample-quiz-response-format.csv"
          className=" ml-4 btn-primary no-underline hover:text-white hover:opacity-90 w-24 content-center "
        >
          Download
        </a>
        <div className="flex flex-row space-x-2">
          <div className="flex flex-col">
            <h5 className="ml-4 mt-4">Upload CSV</h5>
            <input
              type={"file"}
              accept=".csv"
              onChange={(e) => handleCsvUpload(e.target.files[0])}
              multiple={false}
              className="mt-2 ml-4"
            ></input>
          </div>
        </div>
        {file && columns && (
          <div className="flex-row mt-10 ml-4 w-auto">
            <p className="text-2xl mb-3 font-semibold">Preview</p>
            <DataTable columns={columns} data={users} />
            <button
              onClick={generateBulkQuizResponse}
              className="btn-primary mt-4"
            >
              Confirm and Create Quiz Response
            </button>
          </div>
        )}

        {uploadSuccess && (
          <div className="mt-7 text-green-700 text-lg ml-4">
            CSV successfully uploaded
          </div>
        )}
        <div className="mt-5 ml-4">
          <div className="flex flex-row">
            <p className="text-2xl font-medium">Status</p>
            <button onClick={refreshUser} className="p-2 text-gray-500 w-5 h-5">
              <FiRefreshCw />
            </button>
          </div>

          <DataTable
            className="min-w-max"
            columns={importsColumns}
            progressPending={tableData.loading}
            data={
              !tableData.loading
                ? tableData.data?.courses_admin_csv_user_imports
                : []
            }
          />
        </div>
      </div>
    </Container>
  );
}
