export default async function CourseUserQuizTopicReport(courseTopicProgress) {
  const quiz_attempt_data =
    courseTopicProgress.data?.courses_course_module_mapping[0].quiz_attempt_data.reduce(
      (prev, curr) => {
        if (!prev[curr.user_id]) prev[curr.user_id] = null;

        if (
          !prev[curr.user_id] ||
          curr.total_score > prev[curr.user_id].total_score
        ) {
          prev[curr.user_id] = curr;
        }

        return prev;
      },
      {}
    );

  return await Promise.all(
    Object.keys(quiz_attempt_data).map((e) => {
      const objectData = quiz_attempt_data[e];

      const father_name = objectData.user.user_family_details?.find(
        (e) => e.member_type === 0
      )?.name;

      const father_occupation = objectData.user.user_family_details?.find(
        (e) => e.member_type === 0
      )?.occupation;

      const mother_name = objectData.user.user_family_details?.find(
        (e) => e.member_type === 1
      )?.name;

      const mother_occupation = objectData.user.user_family_details?.find(
        (e) => e.member_type === 1
      )?.occupation;

      return {
        // user_id: e.user.id,
        email: objectData.user.email,
        name: objectData.user.name,
        mobile_number: objectData.user?.mobile_number,
        d_o_b: objectData.user?.date_of_birth,
        gender: objectData.user?.gender,
        social_category: objectData.user?.social_category,
        house_number: objectData.user?.address?.house_number,
        location: objectData.user?.address?.location,
        city_town_village: objectData.user?.address?.city_town,
        district: objectData.user?.address?.district,
        state: objectData.user?.address?.state,
        pincode: objectData.user?.address?.pincode,
        country: objectData.user?.address?.country,
        country_iso_code: objectData.user?.address?.country_iso_code,
        father_name: father_name,
        father_occupation: father_occupation,
        mother_name: mother_name,
        mother_occupation: mother_occupation,
        qualification_name:
          objectData.user.user_academic_qualifications[0]?.qualification_name,
        qualification_type:
          objectData.user.user_academic_qualifications[0]?.qualification_type,
        school:
          objectData.user.user_academic_qualifications[0]?.institution_name,
        class:
          objectData.user.user_academic_qualifications[0]?.qualifications_lookup
            ?.name,
        attempt_number: objectData?.attempt_number,
        attempt_completed: objectData?.attempt_completed,
        attempt_start: objectData.attempt_start,
        attempt_end: objectData.attempt_end,
        attempt_score: objectData.total_score,
        attempt_result: objectData.result,
        ...objectData.questionattemptArray.reduce((acc, obj, index) => {
          return {
            ...acc,
            [`question_${index + 1}-question_type`]:
              obj.quiestionsobject?.question_type === 1
                ? "Answer Question"
                : obj.quiestionsobject?.mode,
            [`question_${index + 1}-question_name`]:
              obj.quiestionsobject?.question_text,
            [`question_${index + 1}-correct_answers`]:
              obj.quiestionsobject?.question_type === 1
                ? obj.question_correct_answer
                : "",
            [`question_${index + 1}-user_response`]:
              obj.quiestionsobject?.question_type === 1 ? obj.response : "",
            [`question_${index + 1}-user_picture_response`]:
              obj.quiestionsobject?.question_type === 2 &&
              obj.quiestionsobject?.mode === "picture"
                ? obj?.user_answer_text
                : "",
            [`question_${index + 1}-user_text_response`]:
              obj.quiestionsobject?.question_type === 2 &&
              obj.quiestionsobject?.mode === "text"
                ? obj?.user_answer_text
                : "",
            // [`question_${index + 1}-result`]: obj.result,
            // marks: obj?.marks,
          };
        }, {}),
      };
    })
  );
}
