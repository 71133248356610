import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import * as yup from "yup";
import { GET_CERTIFICATE_TEMPLATES } from "GraphQl/Queries/CertificateTemplateMaker";
import { GET_PARTNERS } from "GraphQl/Queries/Partner";

function CertificateTemplateSelectorFormBuilder(values) {
  const [schema, setSchema] = useState({});
  const { data: templateData } = useQuery(GET_CERTIFICATE_TEMPLATES);
  const { data: partnerData } = useQuery(GET_PARTNERS);

  const getPreviewData = (template_id) => {
    if (template_id + "" === -1 + "") return "";
    else {
      const html = templateData?.courses_cert_templates.find((template) => {
        if (template_id + "" === template.id + "") return template;
      });

      return html?.html_data || undefined;
    }
  };

  useEffect(() => {
    const schema = {
      certificate_template: {
        label: "Add Template to Course",
        type: "object",
        insertable: true,
        repeat: true,
        schema: yup.object(),
        fields: ({ value }) => ({
          certificate_template_id: {
            label: "Certificate Template",
            type: "select",
            required: true,
            options: (templateData?.courses_cert_templates || []).map(
              (template) => {
                return {
                  label: template.name,
                  value: template.id,
                };
              }
            ),
          },
          partner_id: {
            label: "Partner",
            type: "select",
            options: (partnerData?.courses_partner || []).map((partner) => {
              return {
                label: partner.name,
                value: partner.id,
              };
            }),
          },
          preview_button: {
            type: "certificateTemplatePreview",
            template_data: value?.certificate_template_id
              ? getPreviewData(value?.certificate_template_id)
              : undefined,
          },
        }),
      },
    };
    setSchema(schema);
  }, [values, templateData, partnerData]);

  return schema;
}

export default CertificateTemplateSelectorFormBuilder;
