import { gql } from "@apollo/client";

export const CREATE_CERTIFICATE_TEMPLATE = gql`
  mutation insertCertificateTemplate(
    $name: String
    $template_data: jsonb
    $html_data: String
    $partner_id: bigint
  ) {
    insert_courses_cert_templates(
      objects: {
        name: $name
        template_data: $template_data
        html_data: $html_data
        partner_id: $partner_id
      }
    ) {
      returning {
        id
        name
      }
    }
  }
`;

export const UPDATE_CERTIFICATE_TEMPLATE = gql`
  mutation updateCertificateTemplate(
    $id: bigint
    $template_data: jsonb
    $name: String
    $html_data: String
    $partner_id: bigint
  ) {
    update_courses_cert_templates(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        template_data: $template_data
        html_data: $html_data
        partner_id: $partner_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;
