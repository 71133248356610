import React from "react";
import Modal from "react-bootstrap/Modal";
import CertificateTemplateSelector from "./CertificateTemplateSelector";

function CertificateTemplateSelectorModal({
  show,
  handleClose,
  id,
  certificateTemplateData,
}) {
  return (
    <>
      <Modal
        size="lg"
        show={show}
        scrollable={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Certificate Template</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray">
          <CertificateTemplateSelector
            id={id}
            close={handleClose}
            certificateTemplateData={certificateTemplateData}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-secondary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CertificateTemplateSelectorModal;
