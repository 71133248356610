import React from "react";
import { useMutation } from "@apollo/client";
import { CREATE_SCHOLARSHIP } from "../../../GraphQl/Mutations/Scholarships";
import Container from "../../../components/Container/Container";
import { Form } from "skillstrainer-resource-library";
import ScholarshipFormBuilder from "formBuilders/ScholarshipFormBuilder";
import { successHandler, errorHandler } from "utils/toast";

function AddScholarship() {
  const [insertScholarship, insertedScholarship] =
    useMutation(CREATE_SCHOLARSHIP);

  const submitScholarship = async (scholarship) => {
    await insertScholarship({
      variables: { object: scholarship },
    })
      .then(() => successHandler("Scholarship Created Succesfully"))
      .catch((err) => {
        console.log(err);
        errorHandler("There was an error while creating Scholarship ");
      });
  };

  return (
    <Container title={"Add New Scholarship"}>
      <Form
        formBuilder={ScholarshipFormBuilder}
        initValues={{ is_active: false }}
        className="mt-2 grid grid-cols-2 gap-x-2 mt-4"
        submitButton={{
          text: "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6 py-2",
        }}
        onSubmit={(scholarship) => submitScholarship(scholarship)}
      />
    </Container>
  );
}

export default AddScholarship;
