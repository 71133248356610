import { successHandler, errorHandler } from "utils/toast";
import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Form } from "skillstrainer-resource-library";
import certificateTemplatePreview from "utils/CertificateTemplateSelectorFormPlugin";
import {
  DELETE_CERTIFICATE_TEMPLATE_COURSE_PARTNER_RELATION,
  UPSERT_CERTIFICATE_TEMPLATE_COURSE_PARTNER_RELATION,
} from "GraphQl/Mutations/coursebuildemutation/cousresection";
import CertificateTemplateSelectorFormBuilder from "formBuilders/CertificateTemplateSelectorFormbuilder";
import { arrayDelta } from "utils/func";

function CertificateTemplateSelector({
  id: course_id,
  certificateTemplateData,
}) {
  const [saveTemplateData] = useMutation(
    UPSERT_CERTIFICATE_TEMPLATE_COURSE_PARTNER_RELATION
  );
  const [deleteTemplateRelation] = useMutation(
    DELETE_CERTIFICATE_TEMPLATE_COURSE_PARTNER_RELATION
  );

  const [templateRelationData, setTemplateRelationData] = useState({
    isUpdate: certificateTemplateData.length === 0 ? false : true,
    data: certificateTemplateData,
  });
  const [initValues, setInitValues] = useState({
    id: 1,
    data: {
      certificate_template: [],
    },
  });

  useEffect(() => {
    const formObjectArray = (templateRelationData.data || []).map((temp) => {
      return {
        certificate_template_id: temp.certificate_template_id,
        partner_id: temp.partner_id,
      };
    });
    setInitValues({
      id: initValues.id + 1,
      data: {
        certificate_template: [...formObjectArray],
      },
    });
  }, []);

  const submitTemplate = async (templateRelation) => {
    if (!templateRelationData.isUpdate) {
      //Insert Mode
      const objects = templateRelation.certificate_template.map((temp) => {
        return {
          course_id: course_id,
          ...temp,
          partner_id: temp.partner_id || undefined,
        };
      });

      await saveTemplateData({
        variables: {
          objects: objects,
        },
      })
        .then(() => {
          successHandler("Certificate Templates Associated Successfully");
          setTemplateRelationData({ data: templateRelation, isUpdate: true });
        })
        .catch((err) => {
          console.log(err);
          errorHandler(
            "There was an Error while submitting certificate templates"
          );
        });
    } else {
      //Update Mode
      const delta = arrayDelta(
        templateRelationData.data,
        templateRelation?.certificate_template || [],
        ["partner_id", "certificate_template_id"]
      );

      const objects = delta.created.map((temp) => {
        return {
          course_id: course_id,
          ...temp,
          partner_id: temp.partner_id || undefined,
        };
      });
      await saveTemplateData({
        variables: {
          objects: objects,
        },
      })
        .then(() => {
          deleteTemplateRelation({
            variables: {
              ids: delta.deleted.map((temp) => temp.id),
            },
          }).then(() => {
            successHandler("Certificate Template Successfully");
          });
        })
        .catch((err) => {
          console.log(err);
          errorHandler("There was an Error while submitting certificate");
        });
    }
  };

  return (
    <div className="w-full">
      <Form
        formBuilder={CertificateTemplateSelectorFormBuilder}
        className={"mt-2 grid gap-x-6 w-full pl-2"}
        submitButton={{
          text: "Save",
          className: "btn-primary  ml-2 px-6",
        }}
        plugins={{
          certificateTemplatePreview: certificateTemplatePreview,
        }}
        onSubmit={submitTemplate}
        key={initValues.id}
        initValues={initValues.data}
      />
    </div>
  );
}

export default CertificateTemplateSelector;
