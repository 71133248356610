import React from "react";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import Select from "../../../../components/InputGroup/Select";
import { GET_INSTRUCTOR_ID_NAME } from "../../../../GraphQl/Queries/Courses";
import { useQuery, useMutation } from "@apollo/client";
import dateFormat from "dateformat";
import { UPDATE_BATCH_SLOT } from "../../../../GraphQl/Mutations/BatchesSlots";
import { GET_SLOTS_BY_BATCH_ID } from "../../../../GraphQl/Queries/BatchesQueries";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DELETE_BATCH_SLOTS_BY_ROW_ID } from "../../../../GraphQl/Mutations/BatchesSlots";

import { deleteZoom } from "../../../../api/Zoom";

import {
  signInToGoogle,
  checkSignInStatus,
  deleteTheCalenderEvent,
} from "services/LiveClass/GoogleApi";
import moment from "moment";

import { DISABLE_BATCH_SLOTS } from "../../../../GraphQl/Mutations/BatchesSlots";
import TextArea from "components/InputGroup/TextArea";
import { addTimeToDate, setDayTimeToZero, toIndianISOString } from "utils/date";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { gqlUserRecoilVar } from "State/User";

const classTypeOptions = [
  { value: "offline", label: "Offline" },
  { value: "online", label: "Online" },
];

const dayNames = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

function BatchTableModal(props) {
  const [user] = useRecoilState(gqlUserRecoilVar);

  const batchSlots = props.batch_slots;

  const instructorIdName = useQuery(GET_INSTRUCTOR_ID_NAME);
  const [instructorOptions, setInstructors] = useState([]);
  useEffect(() => {
    if (instructorIdName.data && instructorIdName.data.courses_instructor) {
      const instructorOptions = instructorIdName.data.courses_instructor.map(
        (inst, i) => {
          return { value: inst.id, label: inst.name + " - " + inst.email };
        }
      );
      setInstructors(instructorOptions);
    }
  }, [instructorIdName.data]);

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });

  const [deleteRow, setDeleteRow] = useMutation(DELETE_BATCH_SLOTS_BY_ROW_ID, {
    refetchQueries: [GET_SLOTS_BY_BATCH_ID],
  });

  const [update_batch_slot] = useMutation(UPDATE_BATCH_SLOT, {
    refetchQueries: [GET_SLOTS_BY_BATCH_ID],
  });

  const [disable_batch_slots] = useMutation(DISABLE_BATCH_SLOTS, {
    refetchQueries: [GET_SLOTS_BY_BATCH_ID],
  });

  const [course, setCourse] = useState({});
  const setSelectedCourse = (batchSlotData) => {
    batchSlotData = _.cloneDeep(batchSlotData);
    if (!batchSlotData) setCourse();
    else {
      const slot_start_time = moment(batchSlotData.slot_date).format("HH:mm");
      const slot_end_time = moment(batchSlotData.endto_date).format("HH:mm");

      console.log(slot_start_time, slot_end_time);

      batchSlotData.slot_start_time = slot_start_time;
      batchSlotData.slot_end_time = slot_end_time;
      setCourse({ ...batchSlotData });
    }
  };

  const onDelete = async ({ id, event_id, platform }) => {
    ////console.log("Delete Data: id:", id, " Event_id: ", event_id , "Platform:", platform)
    if (event_id) {
      if (platform == "hangoutsMeet") {
        var googleResponse = "";
        let stat = await checkSignInStatus();
        if (stat) {
          googleResponse = await deleteTheCalenderEvent(event_id);
        } else {
          let signInGoogle = await signInToGoogle();
          if (signInGoogle) {
            googleResponse = await deleteTheCalenderEvent(event_id);
          }
        }

        //console.log("resourcee", googleResponse);

        if (googleResponse.status == 204 || googleResponse.status == 410) {
          disable_batch_slots({
            variables: {
              id: id,
              enable_slots: false,
            },
          });
        }
      } else {
        const zoomDel = async () => {
          const deleteResult = await deleteZoom({ event_id });

          if (deleteResult.data == 204) {
            //console.log("Zoom meet deleted");
            deleteRow({
              variables: {
                id: id,
              },
            });
          }
          return deleteResult;
        };
        zoomDel();
      }
    } else {
      disable_batch_slots({
        variables: {
          id: id,
          enable_slots: false,
        },
      });
    }
  };

  const onEdit = ({ id }) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
  };

  const updateValue = (e) => {
    const key = e.target.getAttribute("data-key");

    const value = e.target.value;
    const update = {};
    update[key] = value;

    setCourse((prevState) => {
      const courseObj = {
        ...prevState,
        update,
      };

      courseObj[key] = value;

      return courseObj;
    });

    e.preventDefault();
  };

  const updateBatchSlotField = (key, value) => {
    course[key] = value;
    setCourse({ ...course });
  };

  const updateBatchSlot = async () => {
    const oldBatchSlot = batchSlots.find((e) => e.id === inEditMode.rowKey);

    course.slot_date = setDayTimeToZero(toIndianISOString(course.slot_date));
    const start_date = addTimeToDate(course.slot_date, course.slot_start_time);
    const end_date = addTimeToDate(course.slot_date, course.slot_end_time);

    if (course.instructor_id !== oldBatchSlot.instructor_id) {
      // Instructor changed
    }

    update_batch_slot({
      variables: {
        id: course.id,
        slot_date: start_date.toISOString(),
        endto_date: end_date.toISOString(),
        type: course.type,
        location: course.location,
        meeting_link: course.meeting_link,
      },
    });

    // Remove batch slot from focus
    setCourse();
    setInEditMode({
      status: false,
      rowKey: null,
    });
  };

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const columns = [
    {
      name: "Batch Name",
      selector: (row) => `${row.batch.batch_name}`,
      sortable: true,
    },

    {
      name: "Course Name",
      selector: (row) => `${row.batch.course.full_name}`,
      sortable: true,
    },

    {
      name: "Slot Date",
      selector: (row, index) =>
        inEditMode.status && inEditMode.rowKey === row.id ? (
          <DatePicker
            selected={new Date(course.slot_date)}
            placeholderText="MM-dd-yyyy"
            dateFormat="yyyy-MM-dd"
            name="meeting_date"
            onChange={(date) =>
              updateBatchSlotField("slot_date", date.toISOString())
            }
          />
        ) : (
          `${
            dateFormat(row.slot_date, "yyyy-mm-dd ") +
            " (" +
            dayNames[new Date(row.slot_date).getDay()] +
            ")"
          }`
        ),
      sortable: true,
    },

    {
      name: "Slot Time",
      selector: (row, index) =>
        inEditMode.status && inEditMode.rowKey === row.id ? (
          <>
            {/* {console.log(
              "  new Date(row.slot_date).getHours())",
              moment(row.slot_date).format("HH:mm")
            )} */}
            <input
              value={course.slot_start_time}
              type="time"
              min="06:00"
              data-row={row.id}
              data-key="slot_start_time"
              className="bg-gray-100 text-base text-black w-100 rounded-md"
              onChange={updateValue}
            />{" "}
            <p> to </p>
            <input
              value={course.slot_end_time}
              type="time"
              name="to_time"
              data-row={row.id}
              data-key="slot_end_time"
              className="bg-gray-100 text-base text-black w-100 rounded-md"
              onChange={updateValue}
            />
          </>
        ) : (
          `${
            new Date(row.slot_date).toLocaleString("en-US", {
              timeZone: "Asia/Kolkata",
              hour: "numeric",
              minute: "numeric",
            }) +
            " to " +
            new Date(row.endto_date).toLocaleString("en-US", {
              timeZone: "Asia/Kolkata",
              hour: "numeric",
              minute: "numeric",
            })
          }`
        ),
      sortable: true,
    },

    {
      name: "Instructor",
      sortable: false,

      cell: (row) => {
        if (inEditMode.status && inEditMode.rowKey == row.id) {
          return (
            <Select
              options={instructorOptions}
              valueField={"value"}
              date-refer={row.id}
              displayField={"label"}
              data-key={"instructor_id"}
              onChange={(e) => updateValue(e)}
              value={
                course.instructor_id ? course.instructor_id : row.instructor_id
              }
            />
          );
        } else {
          const instructor = instructorOptions.find(
            (o) => o.value == row.instructor_id
          );
          return instructor ? instructor.label : "----";
        }
      },
    },

    {
      name: "Type",
      cell: (row) =>
        inEditMode.status && inEditMode.rowKey == row.id ? (
          <Select
            valueField="value"
            displayField="label"
            value={course.type}
            options={classTypeOptions}
            data-key="type"
            onChange={updateValue}
          />
        ) : (
          row.type
        ),
    },

    {
      name: "Directions",
      cell: (row) => {
        const editing = inEditMode.status && inEditMode.rowKey === row.id;
        if (editing) {
          if (course.type === "offline")
            return (
              <TextArea
                name="location"
                className="bg-gray-100 text-base text-black w-100 rounded-md"
                style={{ margin: "0 !important" }}
                data-key="location"
                value={course.location}
                onChange={updateValue}
              />
            );
          else
            return (
              (
                <a href={row.meeting_link} target="_blank">
                  {row.meeting_link}
                </a>
              ) || <i className="text-gray-400">Meeting link not setup</i>
            );
        } else {
          return row.type === "online" ? (
            row.meeting_link ? (
              <a href={row.meeting_link} target="_blank">
                {row.meeting_link}
              </a>
            ) : (
              <i className="text-gray-400">Meeting link not setup</i>
            )
          ) : (
            row.location
          );
        }
      },
      ignoreRowClick: true,
      allowOverflow: true,
    },

    ...(user?.is_skillstrainer_admin
      ? [
          {
            name: " Action ",
            cell: (row) =>
              inEditMode.status && inEditMode.rowKey === row.id ? (
                <button
                  className="bg-gray-700 p-2 text-base text-white"
                  attr={
                    course[row.id] ? course[row.id] : row.batch.instructor.id
                  }
                  id={row.id}
                  onClick={updateBatchSlot}
                >
                  Update
                </button>
              ) : (
                <button
                  className="secondary-cta px-2"
                  id={row.id}
                  onClick={() => {
                    setSelectedCourse(row);
                    onEdit({ id: row.id });
                  }}
                >
                  Edit
                </button>
              ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },

          {
            cell: (row) =>
              inEditMode.status && inEditMode.rowKey === row.id ? (
                <button
                  className="bg-gray-700 p-2 text-base text-white"
                  onClick={() => {
                    setSelectedCourse();
                    setInEditMode({
                      status: false,
                      rowKey: null,
                    });
                  }}
                >
                  Cancel
                </button>
              ) : (
                <button
                  className="bg-gray-700 p-2 text-base text-white"
                  onClick={() => {
                    const confirmBox = window.confirm(
                      "Do you really want to delete this slot?"
                    );
                    if (confirmBox === true) {
                      onDelete({
                        id: row.id,
                        event_id: row.event_id,
                        platform: row.platform,
                      });
                    }
                  }}
                >
                  Delete
                </button>
              ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
        ]
      : []),
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  return (
    <div>
      <DataTable
        columns={columns}
        pagination
        data-key={columns}
        data={batchSlots}
        paginationTotalRows={20}
        selectableRows={false}
        selectableRowsHighlight={false}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </div>
  );
}

export default BatchTableModal;
