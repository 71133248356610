import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { CERTIFICATE_DOWNLOAD_URL } from "api/Consts";
import axios from "axios";
import Loader from "assets/animated-images/loader";

function SampleCertificateGeneratorModal({
  show,
  handleClose,
  certificateTemplateData,
  course: courseName,
}) {
  const [loaderKey, setLoaderKey] = useState({});

  useEffect(() => {
    const obj = {};
    (certificateTemplateData || []).forEach((item, index) => {
      obj[index] = false;
    });
    setLoaderKey(obj);
  }, [certificateTemplateData]);

  const downloadCertificate = async (templateId, index) => {
    const params = JSON.stringify({
      sample_user_details: {
        name: "Shahrukh Khan",
        user_id: 999999,
        course_name: courseName,
        short_course_name: "",
        nsqf_level: "",
        percentage: "",
        completed_on: moment(new Date()).format("DD MMMM YYYY"),
        partner_enrollment_id: 45454,
        certificate_id: "e40ee0c8-06bc-4b4e-8270-474eb3064c87",
        certificate_code: "00000000",
        school_name: "Harvard Academy",
        g1m: "1/8",
        g2m: "4/8",
        g3m: "2/8",
        g4m: "1/8",
        g1_word: "Likely",
        g2_word: "Unlikey",
        g3_word: "Preferred",
        g4_word: "Most Preferred",
        digital_marks: "7/8",
      },
      certificate_template_id: templateId,
    });
    const response = await axios.post(CERTIFICATE_DOWNLOAD_URL, params, {});

    loaderKey[index] = false;
    setLoaderKey({ ...loaderKey });
    window.open(response.data.certificate_link, "_blank");
  };

  return (
    <>
      <Modal
        size="md"
        show={show}
        scrollable={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Sample Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray">
          <div className="flex flex-col space-y-4 content-center">
            {(certificateTemplateData || []).map((item, index) => {
              if (item.partner_id === -1) {
                return (
                  <button
                    className="btn-primary justify-center"
                    key={index}
                    onClick={() => {
                      loaderKey[index] = true;
                      setLoaderKey({ ...loaderKey });
                      downloadCertificate(item.certificate_template_id, index);
                    }}
                  >
                    {loaderKey[index] ? (
                      <Loader color={"white"} className={" h-7 mx-auto"} />
                    ) : (
                      "Default Certificate"
                    )}
                  </button>
                );
              } else {
                return (
                  <button
                    className="btn-secondary"
                    key={index}
                    onClick={() => {
                      loaderKey[index] = true;
                      setLoaderKey({ ...loaderKey });
                      downloadCertificate(item.certificate_template_id, index);
                    }}
                  >
                    {loaderKey[index] ? (
                      <Loader color={"white"} className={" h-7 mx-auto"} />
                    ) : (
                      `Sample Certificate For ${item.partner.name}`
                    )}
                  </button>
                );
              }
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-secondary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SampleCertificateGeneratorModal;
