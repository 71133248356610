import { gql } from "@apollo/client";

export const GET_REFERRAL_SCHEME = gql`
  query myQuery {
    courses_referral_scheme(order_by: { updated_at: desc }) {
      id
      name
      admin_user_id
      referrer_reward_type
      referrer_reward
      referee_reward_type
      referee_reward
      action
      constraints
      created_at
      updated_at
      is_active
    }
  }
`;
