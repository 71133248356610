import React, {useState, useEffect} from 'react'
import { getReport } from '../../../api/reports'
import { TbLoader } from 'react-icons/tb';

function PartnerReports() {


  const [iframeUrl, setIframeUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if(iframeUrl.length === 0) {
        setIsLoading(true);
        const payload = {
            "type": 'partner'
        }
        const data = await getReport(payload);
        setIframeUrl(data.report.url);
        setIsLoading(false);
    }
  }, [])
  
  
  return (

    <div>
       {isLoading === true && <div style={{display: "flex", justifyContent: "center"}} className='text-center w-full self-center items-center content-center justify-items-center place-items-center'>
            <TbLoader size={'60px'} className="self-center justify-self-stretch ml-150"
            />
          </div>}
        {iframeUrl.length > 0 && 
            <iframe
              src={`${iframeUrl}`}
              frameBorder="0"
              width="1200px"
              height={`${2500}px`}
              allowTransparency
              scrolling="no"
        ></iframe>
        }


    </div>
  )
}

export default PartnerReports