import EnterpriseQueriesTable from "./components/WorkPartnerQueriesTable";

export default function WorkPartnerQueries() {
  return (
    <>
      <h1 className="px-4 text-3xl font-bold mb-5">Work Partner Queries</h1>
      <EnterpriseQueriesTable />
    </>
  );
}
