import { PieChart, Pie, Cell } from "recharts";

export default function PieCharts(props) {
  const { data, cumulationKey, chartWidth } = props;
  const responseArray = data;
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#ff7701",
    "#ff3b60",
    "#ff3b60",
    "#007fbd",
  ];
  const RADIAN = Math.PI / 180;

  let processedData = [];

  const getResponseDistribution = (responses, key) => {
    const dist = {};

    responses.forEach((r) => {
      if (!dist[r[key]]) dist[r[key]] = 0;
      dist[r[key]]++;
    });

    return dist;
  };
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        className=" font-semibold"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const distributionData = getResponseDistribution(
    responseArray,
    cumulationKey
  );

  for (const key in distributionData) {
    processedData.push({
      label: key,
      value: distributionData[key],
    });
  }

  processedData.sort((a, b) => {
    const labelA = a.label;
    const labelB = b.label;

    return labelA.localeCompare(labelB);
  });

  return (
    <div className="mx-10 flex flex-row">
      <PieChart className="py-4" width={chartWidth || 400} height={200}>
        <Pie
          animationBegin={600}
          data={processedData}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={30}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {processedData.map((entry, index) => {
            return (
              <Cell
                key={entry.label}
                fill={COLORS[index % COLORS.length]}
                className=""
              />
            );
          })}
        </Pie>
      </PieChart>
      <div className="px-4 mt-4 ">
        {processedData.map((ques, index) => {
          const colour = COLORS[index % COLORS.length];

          return (
            <div
              key={ques.label}
              className="flex flex-row space-y-2 justify-items-center"
            >
              <div
                style={{ "background-color": colour }}
                className="h-3 w-3 p-1 m-3"
              ></div>
              <span className="" style={{ color: colour }}>
                {ques.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
