import { asdmCourseReportConfig } from "api/Consts";
import QuizReport, { QUIZ_TYPES } from "./QuizReport";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { useMemo, useState } from "react";
import { GET_BATCH_ENROLLMENTS_WITH_FITLER } from "GraphQl/Queries/Courses";
import CumulativeReports from "./CumlativeReports";
import { Form } from "skillstrainer-resource-library";
import _ from "lodash";
import ASDMReportFilterFormBuilder from "formBuilders/ASDMReportFilterFormBuilder";
import Loader from "assets/animated-images/loader";

export default function ASDMSkillYatraReport() {
  // 205 is course id for skills yatra test in assamese
  const [reportFilter, setReportFilter] = useState({
    batch: {
      course_id: { _in: [asdmCourseReportConfig.courseId, 205] },
    },
  });
  const [filterKey, setFilterKey] = useState({ reports: true, filter: true });
  const batchEnrollmentSearchExp = useMemo(() => {
    const exp = reportFilter;
    return exp;
  }, [reportFilter]);

  const {
    data: enrolledUsersRes,
    refetch,
    loading,
  } = useQuery(GET_BATCH_ENROLLMENTS_WITH_FITLER, {
    variables: { batchEnrollmentSearchExp },
    fetchPolicy: "network-only",
  });
  const enrolledUsers = useMemo(
    () => enrolledUsersRes?.courses_user_batch_enrollment || [],
    [enrolledUsersRes]
  );
  const enrolledUsersIds = useMemo(
    () => (enrolledUsers && enrolledUsers.map((be) => be.user_id)) || [],
    [enrolledUsers]
  );

  const filterReports = (filterObject) => {
    const { gender, class_, district, date } = filterObject;
    let filter = { ...reportFilter };
    if (gender === "0") {
      filter.user = {
        ...filter.user,
        _or: [
          {
            gender: {
              _eq: "male",
            },
          },
          {
            gender: {
              _eq: "0",
            },
          },
        ],
      };
    }
    if (gender === "1") {
      filter.user = {
        ...filter.user,
        _or: [
          {
            gender: {
              _eq: "female",
            },
          },
          {
            gender: {
              _eq: "1",
            },
          },
        ],
      };
    }
    if (gender === "2") {
      filter.user = {
        ...filter.user,
        gender: {
          _eq: "2",
        },
      };
    }
    if (class_) {
      filter.user = {
        ...filter.user,
        user_academic_qualifications: {
          qualification_id: {
            _eq: class_,
          },
        },
      };
    }
    if (district) {
      filter.batch = {
        ...filter.batch,
        institute: {
          district: {
            _ilike: `%${district}%`,
          },
        },
      };
    }
    if (date) {
      const dateGte = date.split("T")[0] + "T00:00:00.000Z";
      const dateLt = moment(dateGte).add(1, "days").format();
      filter.course_enrolment = {
        ...filter.course_enrolment,
        course_complete_obj: {
          _and: [
            {
              completed_on: {
                _gte: dateGte,
              },
            },
            {
              completed_on: {
                _lt: dateLt,
              },
            },
          ],
        },
      };
    }
    setReportFilter({ ...filter });
    refetch({ ...filter }).then(() => {
      setFilterKey({ ...filterKey, reports: !filterKey.reports });
    });
  };

  const resetFilters = () => {
    let filter = {
      batch: {
        course_id: { _eq: asdmCourseReportConfig.courseId },
      },
    };
    setReportFilter({ ...filter });
    refetch({ ...filter }).then(() => {
      setFilterKey({ ...filterKey, filter: !filterKey.filter });
    });
  };

  return (
    <div>
      <div className="text-2xl font-semibold text-orange mb-3">
        Course report for ASDM Skill Yatra Psychometric test
      </div>
      <div className="my-8">
        <Form
          key={String(filterKey.filter) + "filter"}
          formBuilder={ASDMReportFilterFormBuilder}
          className="mt-2 grid grid-cols-4 gap-x-6 mx-2"
          submitButton={{
            text: "Filter",
            className:
              "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
          }}
          onSubmit={(filter) => filterReports(filter)}
        />
        <button
          className="btn-secondary -mt-9 ml-20 float-left"
          onClick={resetFilters}
        >
          Reset Filters
        </button>
      </div>
      {loading && <Loader color={"#999"} className={"h-20"} />}
      {enrolledUsers && (
        <div className="space-y-12">
          <CumulativeReports
            key={String(filterKey.reports) + "cr"}
            type={QUIZ_TYPES.SCORM_QUIZ}
            users={enrolledUsers}
            userIds={enrolledUsersIds}
            activityIds={asdmCourseReportConfig.activityIds}
          />
          <QuizReport
            key={String(filterKey.reports) + "qr"}
            type={QUIZ_TYPES.SCORM_QUIZ}
            activityIds={asdmCourseReportConfig.activityIds}
            userIds={enrolledUsersIds}
            transformResponses={asdmTransformResponsesFn}
          />
        </div>
      )}
    </div>
  );
}

const asdmTransformResponsesFn = (responses) => {
  /*
    Attempt structure
    {
      "id": 95856,
      "user_id": 593399,
      "course_id": 178,
      "mapping_id": 1335,
      "attempt_id": 3560,
      "question_text": "slide: Do you do Online Banking/ Digital Fund Transfer?",
      "user_response": "A._Yes",
      "correct_answers": "No correct answer",
      "question_id": "Scene1_QuestionDraw11_Slide14_FreeFormPickOne_0_0",
      "result": "wrong",
      "created_at": "2023-04-26T07:52:23.285073+00:00",
      "updated_at": "2023-04-26T07:52:23.285073+00:00"
    }
  */
  const responseClone = _.cloneDeep(responses);

  return responseClone.map((res) => {
    if (res.user_response.includes("._")) {
      // Is a digital literacy question
      res.user_response = res.user_response.split("._")[1];
    }
    res.user_response = res.user_response.split("_").join(" ");
    return res;
  });
};
