import { useQuery, useMutation } from "@apollo/client";
import React, { useMemo } from "react";
import { useLocation } from "react-router";
import { Form } from "skillstrainer-resource-library";

import Container from "components/Container/Container";
import { GET_USER_BY_ID } from "GraphQl/Queries/User";
import { UPDATE_USER_BY_ID } from "GraphQl/Mutations/User";
import UserFormBuilder from "formBuilders/UserFormBuilder";
import { successHandler } from "utils/toast";

const EditUsers = (props) => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  let userId = "";
  if (params.get("id")) {
    userId = params.get("id");
  } else {
    userId = props.userId;
  }
  const { data, loading, error, networkStatus } = useQuery(GET_USER_BY_ID, {
    variables: { id: parseInt(userId) },
  });
  const [update_user, update_user_data] = useMutation(UPDATE_USER_BY_ID);

  const submitUser = (user) => {
    update_user({ variables: { id: userId, ...user } }).then(() => {
      props.close();
      successHandler("Trainee Updated Succesfully");
    });
  };

  return (
    <Container>
      {data?.courses_users && (
        <Form
          formBuilder={UserFormBuilder}
          initValues={data.courses_users[0]}
          className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className:
              "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
          }}
          onSubmit={(user) => submitUser(user)}
        />
      )}
    </Container>
  );
};

export default EditUsers;
