import { gql } from "@apollo/client";

export const GET_SCHOLARSHIPS_BY_PARTNER = gql`
  query MyQuery($_partner_id: [bigint!] = "") {
    courses_scholarships(where: { partner_id: { _in: $_partner_id } }) {
      amount
      created_at
      description
      gender
      id
      max_age
      is_active
      min_age
      min_edu_qualification
      no_of_positions
      social_categories
      title
    }
  }
`;

export const GET_SCHOLARSHIPS = gql`
  query MyQuery{
    courses_scholarships{
      amount
      created_at
      description
      gender
      id
      max_age
      is_active
      min_age
      min_edu_qualification
      no_of_positions
      social_categories
      title
    }
  }
`;

export const GET_SCHOLARSHIP_BY_ID = gql`
  query getScholarshipById($_id: uuid = "") {
    courses_scholarships(where: { id: { _eq: $_id } }) {
      partner_id
      project_id
      amount
      created_at
      description
      gender
      id
      max_age
      is_active
      min_age
      min_edu_qualification
      no_of_positions
      social_categories
      title
      partner {
        name
      }
      project {
        name
      }
    }
  }
`;

export const GET_APPLICANTS_BY_SCHOLARSHIP_ID = gql`
  query MyQuery($_scholarshipid: uuid = "") {
    courses_scholarship_partner_user(
      where: { scholarship_id: { _eq: $_scholarshipid } }
    ) {
      user {
        email
        gender
        mobile_number
        name
        social_category
        user_family_details_aggregate {
          aggregate {
            sum {
              annual_income
            }
          }
        }
      }
      id
      accept
    }
  }
`;

export const GET_ALL_APPLICANTS = gql`
  query getAllScholarshipApplicant(
    $where: courses_scholarship_partner_user_bool_exp
  ) {
    courses_scholarship_partner_user(where: $where) {
      id
      accept
      user_id
      contribution
      scholarship_id
      contribution_percentage
      created_at
      user {
        name
        id
        gender
        email
        social_category
        mobile_number
        address {
          city_town
          location
          district
          state
          pincode
        }
        user_family_details {
          name
          member_type
          mobile_number
          occupation
        }
        user_academic_qualifications {
          qualification_type
          institution_name
          qualification_name
        }
      }
      scholarship {
        id
        title
      }
    }
  }
`;

export const GET_ALL_SCHOLARSHIP_DATA_BY_SCHOLARSHIP_ID = gql`
  query getAllScholarshipApplicant($scholarshipId: [uuid!]) {
    courses_scholarship_partner_user(
      where: { scholarship_id: { _in: $scholarshipId } }
    ) {
      contribution
      scholarship_id
      selected_at
      user {
        name
        gender
        address {
          district
        }
        user_academic_qualifications {
          qualification_type
          institution_name
          qualification_name
        }
      }
    }
  }
`;
