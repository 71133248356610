import Container from "components/Container/Container";
import React, { useState } from "react";
import Papa from "papaparse";
import DataTable from "react-data-table-component";
import { useMutation } from "@apollo/client";
import { INSERT_INSTITUTES_MUTATION } from "GraphQl/Queries/OnboardingUsers";
import { errorHandler, successHandler } from "utils/toast";

export default function BulkInstituteUpload() {
  const [csvData, setCsvData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showUploadedData, setShowUploadedData] = useState(false);
  const [insertInstitutes] = useMutation(INSERT_INSTITUTES_MUTATION);

  const handleCsvUpload = async (fileData) => {
    const result = await new Promise((resolve) => {
      Papa.parse(fileData, {
        header: true,
        transformHeader: (header) =>
          header.toLowerCase().replace(/[\/_]/g, " ").trim(),
        delimiter: ",",
        complete: (res) => {
          resolve(res);
        },
        skipEmptyLines: true,
      });
    });
    const categoryMapping = {
      "Private ITI": "private_ITI",
      "Public School": "public_school",
      College: "college",
      "Public Polytechnic": "public_polytechnic",
    };

    const formattedData = result.data.map((item) => {
      const normalizedCategory =
        categoryMapping[item["institute category"]] ||
        item["institute category"];

      return {
        name: item["institute name"],
        category: normalizedCategory,
        city_town: item["city town"],
        country: item["country"],
        district: item["district"],
        house_number: item["house number"],
        location: item["location"],
        pincode: item["pincode"],
        state: item["state"],
      };
    });

    setCsvData(formattedData);
    formatTableData(formattedData);
  };

  const formatTableData = (data) => {
    if (data.length > 0) {
      const sampleRow = data[0];
      const tableColumns = Object.keys(sampleRow).map((key) => ({
        name: key,
        cell: (row) => row[key],
        sortable: true,
      }));
      setColumns(tableColumns);
      setShowUploadedData(true);
    }
  };

  const generateBulkInstitute = async () => {
    if (csvData.length > 0) {
      try {
        await insertInstitutes({
          variables: {
            institutes: csvData,
          },
        });

        successHandler("File uploaded successfully!");
      } catch (error) {
        console.error("Error inserting institutes:", error);
        errorHandler("Error uploading file!");
      }
    }
  };

  return (
    <Container title={"Bulk Institute Upload"} className="my-4 mx-2">
      <div className="flex flex-col">
        <h5 className="ml-4 mt-2">Download Sample CSV Format</h5>
        <a
          href="https://adminskillstrainer.s3.ap-south-1.amazonaws.com/InstituteResponseSample.csv"
          className="ml-4 btn-primary no-underline hover:text-white hover:opacity-90 w-24 content-center"
        >
          Download
        </a>
        <div className="flex flex-row space-x-2">
          <div className="flex flex-col">
            <h5 className="ml-4 mt-4">Upload CSV</h5>
            <input
              type="file"
              accept=".csv"
              onChange={(e) => handleCsvUpload(e.target.files[0])}
              multiple={false}
              className="mt-2 ml-4"
            />
          </div>
        </div>
        {showUploadedData && (
          <div className="flex-row mt-10 ml-4 w-auto">
            <p className="text-2xl mb-3 font-semibold">Uploaded Data</p>
            <DataTable columns={columns} data={csvData} />
            <button
              onClick={generateBulkInstitute}
              className="btn-primary mt-4"
            >
              Confirm and Upload
            </button>
          </div>
        )}
      </div>
    </Container>
  );
}
