import api from "api/Api";

// const jbfLocations = [
//   "Kapasan",
//   "Nanjangud",
//   "Nira",
//   "Savli",
//   "Gajraula",
//   "Bharuch",
//   "Bangalore",
// ];

export async function jbfDailyStats(date, district) {
  // const currentDate = new Date();
  // const year = currentDate.getFullYear();
  // const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  // const day = String(currentDate.getDate()).padStart(2, "0");
  // const formattedDate = `${year}-${month}-${day}`;
  const response = await api().post("/jbf_daily_data", {
    date: date,
    district: district,
  });

  return response.data.data;
}
