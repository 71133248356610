import Container from "components/Container/Container";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ResourceDbSchema } from "./ActivityDbSchema";
import Input from "components/InputGroup/Input";
import TextArea from "components/InputGroup/TextArea";
import Select from "components/InputGroup/Select";
import Checkbox from "components/InputGroup/Checkbox";
import { FileUploader } from "react-drag-drop-files";
import { AiFillCloseSquare } from "react-icons/ai";
import { errorHandler, successHandler } from "utils/toast";

import { default as ReactSelect } from "react-select";

const ResourcesActivity = ({
  action,
  updateValue,
  updateCheckbox,
  handleFileReaderResource,
  section,
  restriction,
  topic,
  handleChange,
  resourceFile,
  deleteFile,
  selectedValue,
  submitResources,
  newresourceFile,
  deleteNewFile,
  defaultRestriction,
  lessonName,
  fileLoadingFun,
  fileLoading,
}) => {
  let history = useHistory();

  const [errorField, seterrorField] = useState({});

  const requireFields = {
    name: true,
    description: false,
    upload_url: true,
    completion_criteria: true,
    restriction_modules: false,
  };

  useEffect(() => {
    checkValidation();
  }, [section, resourceFile, selectedValue, newresourceFile]);

  const checkValidation = (e) => {
    let checkUplaodAttachement = [];

    if (Array.isArray(resourceFile) && action == "new")
      checkUplaodAttachement = [...resourceFile];

    if (
      Array.isArray(resourceFile) &&
      Array.isArray(newresourceFile) &&
      action == "update"
    )
      checkUplaodAttachement = [...resourceFile, ...newresourceFile];

    Object.keys(requireFields).map((fieldName) => {
      if (section[fieldName] !== 0) {
        seterrorField((prevState) => ({
          ...prevState,
          [fieldName]:
            !section[fieldName] || section[fieldName] < 0 ? true : false,
          upload_url: !checkUplaodAttachement.length ? true : false,
          restriction_modules:
            section["enable_restriction"] && !selectedValue.length > 0
              ? true
              : false,
          description: false,
        }));
      }
      if (section[fieldName] === 0) {
        seterrorField((prevState) => ({
          ...prevState,
          [fieldName]: false,
        }));
      }
    });

    return true;
  };

  const submitScorm = (e) => {
    e.preventDefault();

    if (checkValidation()) {
      const checkRequireFields = Object.keys(errorField).find(
        (key) => errorField[key] === true
      );

      if (checkRequireFields) {
        errorHandler("Please fill all required fields");
      } else {
        fileLoadingFun();
        submitResources();
      }
    }
  };

  return (
    <Container>
      {" "}
      <button
        className="inline-flex justify-center py-2 px-4 
  border border-transparent shadow-sm text-sm font-medium 
  rounded-md text-white bg-orange"
        onClick={() => history.goBack()}
      >
        Go To Back
      </button>
      <div class="">
        <h1 class="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          {lessonName}
        </h1>
      </div>
      <form onSubmit={submitScorm}>
        <div class="p-2">
          <h4 class="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
            {action == "update" ? "Update" : ""} Resource Activity
          </h4>
        </div>
        <div className="shadow sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="flex flex-wrap">
              {/* General */}
              {Object.keys(ResourceDbSchema).map((course_key) => {
                if (course_key == 1) {
                  return (
                    <div className="mb-2 w-full" key={course_key}>
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>General</div>
                        </div>
                        <div className="accordion-content p-3 w-full flex flex-row">
                          {ResourceDbSchema[course_key].map(
                            ({ label, column_name, type }) => (
                              <div className="mt-4 w-6/12">
                                {type === "text" && (
                                  <Input
                                    label={label}
                                    type={type}
                                    data-key={column_name}
                                    onChange={updateValue}
                                    value={section[column_name]}
                                    requiredField={
                                      requireFields[column_name] ? "yes" : "no"
                                    }
                                    name={column_name}
                                    error={
                                      errorField[column_name] ? "yes" : "no"
                                    }
                                  />
                                )}

                                {type === "text_area" && (
                                  <TextArea
                                    label={"Description"}
                                    data-key={column_name}
                                    onChange={updateValue}
                                    value={section[column_name]}
                                    requiredField={
                                      requireFields[column_name] ? "yes" : "no"
                                    }
                                    error={
                                      errorField[column_name] ? "yes" : "no"
                                    }
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {/* Files Attachments */}

              {Object.keys(ResourceDbSchema).map((course_key) => {
                if (course_key == 2) {
                  return (
                    <div key={course_key} className="mb-2 mt-4 w-full">
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>Files Attachments</div>
                        </div>
                        <div className="accordion-content p-3 w-full flex flex-row">
                          {ResourceDbSchema[course_key].map(
                            ({ label, column_name, type, options }) => (
                              <div className="mt-4 w-6/12">
                                {type === "file" && (
                                  <>
                                    <label>
                                      {label}
                                      {requireFields[column_name] ? (
                                        <span className="text-red-600 text-lg	">
                                          *
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </label>

                                    <FileUploader
                                      handleChange={handleFileReaderResource}
                                      name="file"
                                      classes={"drop_area drop_zone"}
                                      multiple={true}
                                    />
                                    {errorField[column_name] ? (
                                      <span className="border-red-600 text-md text-red-600">
                                        Please Upload Resources
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    <ul>
                                      {(resourceFile || []).map((E) => {
                                        return (
                                          <li
                                            className="inline-flex gap-2"
                                            key={
                                              action == "update"
                                                ? E?.file_name
                                                : E?.name
                                            }
                                          >
                                            <p>
                                              {action == "update"
                                                ? E?.actual_file_name
                                                : E?.name}
                                            </p>
                                            <AiFillCloseSquare
                                              className="cursor-pointer"
                                              size={22}
                                              color="red"
                                              onClick={() =>
                                                deleteFile(
                                                  action == "update"
                                                    ? E?.file_name
                                                    : E?.name
                                                )
                                              }
                                            />
                                          </li>
                                        );
                                      })}

                                      {/* {(newresourceFile || []).map(
                                        (newfile) => {
                                          return (
                                            <li
                                              className="inline-flex gap-2"
                                              key={newfile?.name}
                                            >
                                              <p>{newfile?.name}</p>
                                              <AiFillCloseSquare
                                                className="cursor-pointer"
                                                size={22}
                                                color="red"
                                                onClick={() =>
                                                  deleteNewFile(newfile?.name)
                                                }
                                              />
                                            </li>
                                          );
                                        }
                                      )} */}
                                    </ul>
                                  </>
                                )}
                                {type == "select" && (
                                  <Select
                                    label={label}
                                    options={options}
                                    valueField={"value"}
                                    displayField={"label"}
                                    data-key={column_name}
                                    onChange={(e) => updateValue(e)}
                                    value={section[column_name]}
                                    requiredField={
                                      requireFields[column_name] ? "yes" : "no"
                                    }
                                    name={column_name}
                                    // error={
                                    //   errorField[column_name] ? "yes" : "no"
                                    // }
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {/* Access Restriction */}
              {Object.keys(ResourceDbSchema).map((course_key) => {
                if (course_key == 3) {
                  return (
                    <div key={course_key} className="mb-2 mt-4 w-full">
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>Access Restriction</div>
                        </div>
                        <div className="accordion-content p-3 w-full">
                          {ResourceDbSchema[course_key].map(
                            ({ label, column_name, type, label_text }) => (
                              <div className="mt-4">
                                {type === "checkbox" && (
                                  <Checkbox
                                    label={label}
                                    data-key={column_name}
                                    onChange={updateCheckbox}
                                    checked={section[column_name]}
                                  />
                                )}

                                {restriction && type == "multipleselection" && (
                                  <div>
                                    {label_text && (
                                      <p className="pl-5 label_text">
                                        {label_text}

                                        <span className="text-red-600 text-lg	">
                                          *
                                        </span>
                                      </p>
                                    )}
                                    <ReactSelect
                                      placeholder={label}
                                      options={topic ? topic : []}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      allowSelectAll={true}
                                      onChange={handleChange}
                                      defaultValue={
                                        action == "update" &&
                                        defaultRestriction.length > 0
                                          ? defaultRestriction
                                          : []
                                      }
                                    />
                                    {errorField[column_name] ? (
                                      <span className="border-red-600 text-md text-red-600">
                                        Please select atleast one option
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          {fileLoading ? (
            <div class="flex justify-end items-center">
              <div
                class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange"
            >
              {action == "update" ? "Update Resource" : "Save Resource"}
            </button>
          )}
        </div>
      </form>
    </Container>
  );
};

export default ResourcesActivity;
