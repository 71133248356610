import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import CourseActivityForm from "./CourseActivityForm";
import Container from "components/Container/Container";
import { useLocation } from "react-router";
import queryString from "query-string";
import axios from "axios";
import { INSERT_COURSE_RESOURCES } from "GraphQl/Mutations/coursebuildemutation/CourseModule";
import { useHistory } from "react-router-dom";
import courseBuilderService from "services/coursebuild/course";
import QuizActivity from "./QuizActivity";
import { toast } from "react-toastify";
import { GET_COURSE_TOPICS } from "GraphQl/Queries/CourseBuilder/section";
import api from "api/Api";
import { error } from "utils/error";
import ResourcesActivity from "./ResourcesActivity";
import { uploadResourceLargeFile } from "api/UploadFile";
import { errorHandler, successHandler } from "utils/toast";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";

export default function CourseActivityAdd() {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const history = useHistory();

  const [insert_course_resources] = useMutation(INSERT_COURSE_RESOURCES);

  const { data: CourseTopics } = useQuery(GET_COURSE_TOPICS, {
    variables: { course_id: params.id },
    fetchPolicy: "network-only",
  });

  // Contains activity details and completion criteria
  const [criteria, setCriteria] = useState([]);

  const [message, setMessage] = useState();

  const [topic, setTopics] = useState([]);

  const [restriction, setRestriction] = useState(false);

  const [selectedValue, setSelectedValue] = useState([]);

  const [CompleteStatus, setCompleteStatus] = useState(false);

  const [minScore, setMinScore] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  const [resourceFile, setResourceFile] = useState([]);

  // handle onChange event of the dropdown
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  console.log("topic", topic);

  const updateValue = (e) => {
    const key = e.target.getAttribute("data-key");
    const value = e.target.value;

    // Handles module completion criteria
    if (key == "completion_criteria" && value == 1) {
      setCompleteStatus(true);
    }
    if (key == "completion_criteria" && value != 1) {
      setCompleteStatus(false);
    }

    // Handles module
    setCriteria({ ...criteria, [key]: value });
  };

  const updateCheckbox = (e) => {
    const key = e.target.getAttribute("data-key");
    const value = e.target.checked;

    if (key === "enable_restriction") {
      setRestriction(value);
    }

    if (key === "enable_min_score") {
      setMinScore(value);
    }

    setCriteria({ ...criteria, [key]: value });
  };

  const [fileName, SetFileName] = useState({
    file_name: null,
    upload_url: null,
    system_filename: null,
  });
  const [modType, setModType] = useState("");

  const criteriaOption = [
    {
      value: 0,
      label: "Students can manually mark the activity as completed",
    },
    { value: 1, label: "Show activity as complete when condition are meet" },
    { value: 2, label: "Mark activity complete on view" },
  ];

  const ScormVerison = [
    { value: "1.2", label: "1.2" },
    { value: "2004", label: "2004" },
  ];

  const submitSection = async () => {
    // e.preventDefault();

    const formData = { ...criteria };
    if (
      formData.name === "" ||
      formData.name === null ||
      formData.name === undefined
    ) {
      setMessage("Section name is required");
      return;
    }

    formData["course_id"] = params.id;
    formData["mod_id"] = params.modid;
    formData["section_id"] = params.secid;
    formData["created_date"] = new Date();
    formData["file_name"] = fileName.file_name;
    formData["upload_url"] = fileName.upload_url;
    formData["publish_url"] = "";
    formData["system_filename"] = fileName.system_filename;
    formData["restriction_ids"] = selectedValue.toString();
    formData["scorm_version"] = formData.scorm_version.toString();
    formData["attemtsgrade"] = parseInt(formData.attemtsgrade);
    formData["maxattempt"] = parseInt(formData.maxattempt);
    formData["completion_criteria"] = parseInt(formData.completion_criteria);
    formData["enable_user_attempt"] = formData?.enable_user_attempt
      ? formData?.enable_user_attempt
      : false;

    console.log("Form Data", JSON.stringify(formData, null, 2));
    const insertData = await courseBuilderService.CreateCourseTopics(formData);

    if (insertData) {
      setMessage("Topic successfully added to course.");
      setCriteria([]);
    }

    if (insertData) {
      let path =
        getModulePathStringById(ModuleNameIdMap.viewCourse) +
        `?id=${formData.course_id}`;
      history.push(path);

      toast.success("Scorm successfully Added!");
    } else {
      toast.error("Something will be wrong!");
    }
  };

  const getModType = async () => {
    const type = await courseBuilderService.checkModType(params.modid);

    setModType(type);
  };

  useEffect(() => {
    getModType();
    setCriteria({
      ...criteria,
      scorm_version: 1.2,
      completion_criteria: 2,
      maxattempt: 0,
      attemtsgrade: 0,
    });
  }, []);

  /**** Scorm Uplaoder function */
  const handleFileReader = async (event) => {
    setFileLoading(true);
    const file = event;

    const isPublic = false;

    const rep = async () => {
      const data = {
        file_name: event.name,
        is_public: isPublic,
        content_type: file.type,
      };

      try {
        const result = await api()
          .post("/get_file_post_url", data)
          .then(({ data }) => data);

        console.log("response", result);

        const s3UploadUrl = result.image_post_url;

        const axiosResponse = await axios
          .put(s3UploadUrl, file, {
            headers: {
              "content-type": file.type,
            },
          })
          .then((data) => data);

        SetFileName({
          file_name: result.file_name,
          upload_url: axiosResponse.config.url,
          system_filename: event.name,
        });

        return result.file_name;
      } catch (err) {
        throw error("Couldn't upload file", err);
      }
    };

    await rep();

    /*
     Please store this file name in the backend with activity
    */
  };

  useEffect(() => {
    if (CourseTopics) {
      const Options =
        CourseTopics &&
        CourseTopics.courses_course_module_mapping.map((topic, i) => {
          return { value: topic.id, label: topic.name };
        });

      setTopics(Options);
    }
  }, [CourseTopics]);

  const fileTypes = ["ZIP"];

  useEffect(() => {
    if (fileName?.file_name) {
      setFileLoading(false);
    }
  }, [fileName]);

  /**** Resource Uplaoder function */
  const handleFileReaderResource = async (fupload) => {
    setResourceFile((oldarr) => [...oldarr, fupload[0]]);
  };

  // Remove Upload Resources
  const deleteFile = (name) =>
    setResourceFile(resourceFile.filter((item) => item.name !== name));

  // SubmitResources

  const submitResources = async () => {
    const formData = { ...criteria };
    if (
      formData.name === "" ||
      formData.name === null ||
      formData.name === undefined
    ) {
      setMessage("Section name is required");
      return;
    }

    formData["course_id"] = params.id;
    formData["mod_id"] = params.modid;
    formData["section_id"] = params.secid;
    formData["created_date"] = new Date();
    formData["restriction_ids"] = selectedValue.toString();
    formData["scorm_version"] = "";
    formData["completion_criteria"] = parseInt(formData.completion_criteria);
    formData["enable_user_attempt"] = formData?.enable_user_attempt || false;

    const insertData = await courseBuilderService.CreateCourseTopics(formData);

    //Upload and link attached files to created topic
    await Promise.all(
      (resourceFile || []).map(async (file) => {
        const resp = await resourecUploadFun(file);

        insert_course_resources({
          variables: {
            actual_file_name: file.name,
            file_name: resp,
            mapping_id: insertData.id,
          },
        });
      })
    );

    setFileLoading(!fileLoading);

    if (insertData) {
      successHandler("Topic SuccessFully Added");
      setCriteria([]);
      let path =
        getModulePathStringById(ModuleNameIdMap.viewCourse) +
        `?id=${formData.course_id}`;
      history.push(path);
    } else {
      toast.error("Something will be wrong!");
    }
  };

  //Resource Uploading
  const resourecUploadFun = async (resourceFile) => {
    return await uploadResourceLargeFile(
      resourceFile,
      resourceFile.name,
      true,
      "course-resources"
    )
      .then((res) => res.data_url)
      .catch((e) => {
        errorHandler("An error occured while uploading");
        throw error("Couldn't upload file", e.data || e);
      });
  };

  const fileLoadingFun = () => setFileLoading(!fileLoading);
  return (
    <Container>
      {modType && modType === "files-attachment" ? (
        <ResourcesActivity
          action="new"
          updateCheckbox={updateCheckbox}
          updateValue={updateValue}
          handleFileReaderResource={handleFileReaderResource}
          handleChange={handleChange}
          section={criteria}
          topic={topic}
          restriction={restriction}
          resourceFile={resourceFile}
          deleteFile={deleteFile}
          selectedValue={selectedValue}
          submitResources={submitResources}
          fileLoading={fileLoading}
          fileLoadingFun={fileLoadingFun}
        />
      ) : modType && modType === "scorm-package" ? (
        <CourseActivityForm
          CompleteStatus={CompleteStatus}
          updateCheckbox={updateCheckbox}
          updateValue={updateValue}
          submitSection={submitSection}
          section={criteria}
          message={message}
          minScore={minScore}
          // paramValue={paramValue}
          handleFileReader={handleFileReader}
          criteriaOption={criteriaOption}
          ScormVerison={ScormVerison}
          handleChange={handleChange}
          topic={topic}
          restriction={restriction}
          fileTypes={fileTypes}
          action="new"
          fileName={fileName}
          selectedValue={selectedValue}
          fileLoading={fileLoading}
        />
      ) : modType && modType === "quiz" ? (
        <QuizActivity topic={topic} />
      ) : (
        ""
      )}
    </Container>
  );
}
