import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { GET_ORDERS_DETAILS } from "GraphQl/Queries/Orders";
import jsonToCsv from "utils/jsontocsv";
import { convertTimeStamp, downloadCsv } from "utils/func";
import { successHandler } from "utils/toast";
import Container from "components/Container/Container";
import { Form } from "skillstrainer-resource-library";
import InvoicesTable from "./InvoiceTable";
import InvoiceFilterFormBuilder from "formBuilders/InvoiceFilterFormBuilder";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";

const invoicesSchema = [
  {
    name: "ID",
    selector: (row) => row.invoice_id,
    sortable: true,
    maxWidth: "10px",
  },
  {
    name: "Course Name",
    selector: (row) => row.ordercourse?.full_name || "No Course",
    sortable: true,
    maxWidth: "550px",
  },
  {
    name: "Date",
    selector: (row) => convertTimeStamp(row.created_at),
    sortable: true,
    maxWidth: "100px",
  },
  {
    name: "Amount",
    selector: (row) => row.amount || 0,
    maxWidth: "100px",
  },
  {
    name: "Cost",
    selector: (row) => row.cost || 0,
    maxWidth: "100px",
  },
  {
    name: "Tax",
    selector: (row) => row.tax || 0,
    maxWidth: "100px",
  },
  {
    name: "User ID",
    selector: (row) => row.user?.id || "",
    maxWidth: "100px",
  },
  {
    name: "User Name",
    selector: (row) => row.user?.name,
    maxWidth: "200px",
  },
  {
    name: "Payment ID",
    selector: (row) => row.rajorpay_payment_id,
    sortable: true,
    omit: true,
    maxWidth: "210px",
  },
  {
    name: "Invoice URL",
    omit: true,
    selector: (row) => row.invoice || "NA",
  },
];

export default function Invoices() {
  const partnerAccess = useUserPartnerAccessDetails();
  const [runQuery, { data: invoiceData, loading }] = useLazyQuery(
    GET_ORDERS_DETAILS,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    filterSearch({});
  }, [partnerAccess]);

  function resetFilters() {
    runQuery({
      variables: {
        where: {
          _and: [
            {
              type: {
                _neq: "free",
              },
            },
          ],
        },
      },
    });
  }

  function filterSearch(filter) {
    const { from_date, to_date, course, partner_id } = filter;

    let variables = partnerAccess.isAdmin
      ? {
          where: {
            _and: [
              {
                type: {
                  _neq: "free",
                },
              },
            ],
          },
        }
      : {
          where: {
            _and: [
              {
                partner_id: {
                  _in: partnerAccess.partnerIds,
                },
              },
              {
                type: {
                  _neq: "free",
                },
              },
            ],
          },
        };
    if (from_date) {
      const dateGte = from_date.split("T")[0] + "T00:00:00.000Z";
      variables.where._and = [
        ...variables.where._and,
        {
          created_at: {
            _gte: dateGte,
          },
        },
      ];
    }
    if (to_date) {
      const dateLt = moment(to_date.split("T")[0] + "T00:00:00.000Z")
        .add(1, "days")
        .format();
      variables.where._and = [
        ...variables.where._and,
        {
          created_at: {
            _lt: dateLt,
          },
        },
      ];
    }

    if (partner_id) {
      variables.where._and = [
        ...variables.where._and,
        {
          partner_id: {
            _eq: partner_id,
          },
        },
      ];
    }

    if (course) {
      variables.where._and = [
        ...variables.where._and,
        {
          course_id: {
            _eq: course,
          },
        },
      ];
    }

    runQuery({ variables: variables });
  }

  function getCSVData() {
    const data = invoiceData?.courses_user_course_order || [];
    const csv = jsonToCsv(data, invoicesSchema);
    successHandler("Downloading...", 1000);
    downloadCsv(csv, "Invoices");
  }

  return (
    <Container title={"Invoices"}>
      <div className="flex flex-col mb-4">
        <Form
          formBuilder={InvoiceFilterFormBuilder}
          className="mt-2 grid grid-cols-4 gap-x-4 mx-2 w-full"
          submitButton={{
            text: "Filter",
            className:
              "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
          }}
          onSubmit={(filter) => filterSearch(filter)}
        />
        <div className="flex flex-row space-x-2  ml-24 -mt-9">
          <button onClick={resetFilters} className="btn-secondary ">
            <span className="whitespace-nowrap"> Reset Filters</span>
          </button>
          <button className="btn-primary " onClick={getCSVData}>
            Download CSV
          </button>
        </div>
      </div>

      <InvoicesTable
        data={invoiceData}
        loading={loading}
        schema={invoicesSchema}
      />
    </Container>
  );
}
