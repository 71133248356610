import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  GET_ALL_ADMIN_ROLES,
  GET_TOTAL_ADMIN_USER_ROLES,
} from "../../../GraphQl/Queries/AdminUserRole";
import CreateRole from "./components/CreateRole";
import RolesTable from "./components/RolesTable";
import Container from "components/Container/Container";

function ManageUserRoles() {
  const [role, setRole] = useState();
  const allRoles = useQuery(GET_ALL_ADMIN_ROLES);

  const totalAdminUsers = useQuery(GET_TOTAL_ADMIN_USER_ROLES);

  return (
    <Container>
      <h2 className="text-3xl font-bold">Manage User Roles</h2>
      <div className="my-4">
        <CreateRole
          key={role?.id || "new"}
          selectedRole={role}
          onClear={() => setRole()}
          afterSubmit={() => {
            allRoles.refetch();
            totalAdminUsers.refetch();
          }}
        />
      </div>

      <RolesTable
        allRoles={allRoles}
        updateRole={(role) => setRole({ ...role })}
        totalAdminUsers={totalAdminUsers}
      />
    </Container>
  );
}

export default ManageUserRoles;
