import axios from "./Api";

export const getUserMoodleCourses = async (user_id) => {
  const response = await axios().post("/get_moodle_courses_for_user", {
    user_id: user_id,
  });

  return response.data.data || [];
};

export const getCourseCertificate = async (user_id, course_id) => {
  const response = await axios().post("/get_moodle_certificate", {
    user_id: user_id,
    course_id: course_id,
  });

  return response.data.download_link || false;
};

export const getQuizScoresFromMoodle = async (user_id, course_id) => {
  const response = await axios().get("/get_moodle_quiz_scores", {
    user_id: user_id,
    course_id: course_id,
  });

  if (response.data.success) return response.data?.quiz_scores;
  else return false;
};
