import React from "react";
import DataTable from "react-data-table-component";
import { GET_WORK_PARTNER_SIGNUP_QUERIES } from "../../../../GraphQl/Queries/Partner";
import { useQuery } from "@apollo/client";
import FilterComponent from "./UserFilterComponent";
import { useState, useEffect } from "react";

export default function WorkPartnerQueriesTable() {
  const columns = [
    {
      name: "Contact Name",
      selector: (row, index) => `${row.contact_name}`,
      sortable: true,
    },
    {
      name: "Contact Number",
      selector: (row, index) => `${row.contact_number}`,
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (row, index) => `${row.email_id}`,
      sortable: true,
    },
    {
      name: "Organization Name",
      selector: (row, index) => `${row.organisation_name}`,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row, index) => `${row.address}`,
      sortable: true,
    },
    {
      name: "Purpose",
      selector: (row, index) => `${row.purpose}`,
      sortable: true,
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const partnerQueries = useQuery(GET_WORK_PARTNER_SIGNUP_QUERIES);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        placeholder="Search Name, Org. Name"
      />
    );
  }, [filterText, resetPaginationToggle]);

  const filteredItems =
    partnerQueries?.data?.courses_signup_enterprises?.filter(
      (item) =>
        item.contact_name &&
        (item.organisation_name
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
          item.contact_name.toLowerCase().includes(filterText.toLowerCase()))
    );

  useEffect(() => {
    //console.log("Setting child refresh functions");
    // props.setRefreshFunction(() => getUsers);
  }, []);

  return (
    <>
      <div className="flex-row w-full">
        <DataTable
          columns={columns}
          data={
            filterText
              ? filteredItems
              : partnerQueries?.data?.courses_signup_enterprises
          }
          progressPending={partnerQueries.loading}
          pagination
          paginationServer
          paginationPerPage={100}
          paginationRowsPerPageOptions={[100, 150, 200, 250]}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
        />
      </div>
    </>
  );
}
