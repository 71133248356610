import { DATE_FORMATS } from "api/Consts";
import moment from "moment";
import React from "react";

export default function BatchAttendance(props) {
  const { batch, attendance } = props;

  return (
    <div className="w-full p-4 overflow-x-auto">
      {!batch.is_demo ? (
        <table className="text-sm border-separate border-spacing-2">
          <tr>
            <th className="font-semibold w-40 whitespace-nowrap p-1">User</th>
            {batch.batch_slots.map((bs) => (
              <td className="font-semibold w-20 whitespace-nowrap p-1">
                {moment(bs.slot_date).format(DATE_FORMATS.BATCH_SLOT_DATE)}
                <br />
                {moment(bs.slot_date).format(DATE_FORMATS.BATCH_SLOT_TIME)}
              </td>
            ))}
          </tr>
          {attendance &&
            Object.values(attendance).map((item) => {
              const { user, attendance: userAttendance } = item;

              return (
                <tr>
                  <td className="font-semibold p-1">
                    {user.name} ({user.email})
                  </td>
                  {batch.batch_slots.map((bs) => {
                    const attData = userAttendance.find(
                      (ua) => ua.slot_id === bs.id
                    );
                    const { attendance_status: status } = attData || {};

                    return (
                      <td className="p-1">
                        {status === true ? (
                          <span className="font-semibold text-green-500">
                            P
                          </span>
                        ) : status === false ? (
                          <span className="font-semibold text-red-500">A</span>
                        ) : (
                          "NA"
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </table>
      ) : (
        attendance && (
          <ul>
            {batch.batch_slots.map((bs) => {
              const users = Object.values(attendance)
                .map((record) => {
                  if (record.attendance.find((a) => a.slot_id === bs.id))
                    return record.user;
                })
                .filter((e) => e);

              return (
                <li className="mb-3">
                  <div className="font-semibold mb-1">
                    {moment(bs.slot_date).format(
                      DATE_FORMATS.BATCH_SLOT_DATE_TIME
                    )}
                  </div>
                  {users.length > 0 ? (
                    <ul>
                      {users.map((user) => (
                        <li>
                          <span>{user.email}</span>{" "}
                          <span className="text-gray-500">({user.name})</span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <span className="font-semibold text-gray-400 text-sm">
                      No users attended
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        )
      )}
    </div>
  );
}
