export const ERROR_TYPES = {
  internal: "internal",
  hasura: "hasura",
  api: "api",
};

export const error = (
  data,
  message = "An error occurred",
  type = ERROR_TYPES.internal
) => {
  const err = new Error(message);
  err.data = data;
  err.type = type;
  return err;
};

export const api_error = (...args) => {
  const err = error(...args);
  err.type = ERROR_TYPES.api;
  return err;
};

export const hasura_error = (...args) => {
  const err = error(...args);
  err.type = ERROR_TYPES.hasura;
  return err;
};
