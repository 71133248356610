import * as yup from "yup";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_PARTNERS } from "GraphQl/Queries/Partner";

function InstructorFormBuilder(values) {
  const { error, loading, data } = useQuery(GET_PARTNERS);

  const [schema, setSchema] = useState({});

  useEffect(() => {
    const schema = {
      name: {
        label: "Name",
        type: "text",
        required: true,
        schema: yup.string().min(5, "Name should have atleast 5 characters"),
      },
      email: {
        label: "Email",
        required: true,
        type: "text",
        schema: yup.string().email(),
      },
      partner_id: {
        label: "Select Partner",
        type: "select",
        options: (data?.courses_partner || []).map((partner) => ({
          value: partner.id,
          label: partner.name,
        })),
      },
      mobile_number: {
        label: "Mobile Number",
        type: "text",
        required: true,
        schema: yup.number().min(10, "Mobile Number should have 10 digits"),
      },
      activation_start_date: {
        label: "Activation start date",
        type: "date",
      },
      activation_end_date: {
        label: "Activation end date",
        type: "date",
      },
      password: {
        label: "Enter password",
        type: "password",
        required: true,
        schema: yup.string(),
      },
      confirm_password: {
        label: "Confirm password",
        type: "password",
        required: true,
        schema: yup
          .string()
          .equals(
            [values.password],
            "Confirm password and password don't match"
          ),
      },

      active: {
        label: "Active",
        type: "boolean",
        required: true,
      },
      id: {
        type: "hidden",
      },
      trainee_flag: {
        label: "Is Traninee",
        type: "boolean",
      },
      is_email_valid: {
        label: "Is email valid",
        type: "boolean",
      },
    };

    if (values.id) {
      schema.password.required = false;
      schema.confirm_password.required = false;
    }

    setSchema(schema);
  }, [data, values]);

  return schema;
}

export default InstructorFormBuilder;
