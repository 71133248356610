import { gql } from "@apollo/client";

export const CREATE_SCHOLARSHIP = gql`
  mutation MyMutation($object: courses_scholarships_insert_input = {}) {
    insert_courses_scholarships_one(object: $object) {
      id
      created_at
    }
  }
`;

export const UPDATE_SCHOLARSHIP_BY_ID = gql`
  mutation MyMutation(
    $_id: uuid = ""
    $_set: courses_scholarships_set_input = {}
  ) {
    update_courses_scholarships(where: { id: { _eq: $_id } }, _set: $_set) {
      affected_rows
      returning {
        id
        updated_at
      }
    }
  }
`;

export const ACCEPT_APPLICATION = gql`
  mutation acceptApplication($_applicationid: uuid = "") {
    update_courses_scholarship_partner_user(
      where: { id: { _eq: $_applicationid } }
      _set: { accept: true }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const REJECT_APPLICATION = gql`
  mutation acceptApplication($_applicationid: uuid = "") {
    update_courses_scholarship_partner_user(
      where: { id: { _eq: $_applicationid } }
      _set: { accept: false }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
