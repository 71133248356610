import React from "react";
import Container from "../../../components/Container/Container";
import { errorHandler, successHandler } from "utils/toast";
import courseBuilderService from "services/coursebuild/course";
import { useHistory } from "react-router-dom";
import { Form } from "skillstrainer-resource-library";
import CourseFormBuilder from "formBuilders/CourseFormBuilder";
import { CREATE_COURSE_CERTIFICATION_PARTNERS } from "GraphQl/Mutations/Courses";
import { useMutation } from "@apollo/client";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";
import { create_subscription_plan_api } from "api/Zoom";

function CreateCoursePage() {
  const history = useHistory();
  const [createCourseCertificationPartners] = useMutation(
    CREATE_COURSE_CERTIFICATION_PARTNERS
  );

  const createCourse = async (courseData) => {
    const createCourse_object = {
      ...courseData,
      image_url: courseData?.image_url[0]?.url,
      certificate_image_url: courseData?.certificate_image_url[0]?.url,
    };

    const certificationPartners = createCourse_object.partners;
    createCourse_object.partners = undefined;

    createCourse_object.identifier = createCourse_object.full_name
      .toLowerCase()
      .replaceAll(" ", "-");

    const insertData = await courseBuilderService.createCourse(
      createCourse_object
    );

    try {
      if (insertData && courseData["is_subscription"]) {
        const result = await create_subscription_plan_api(courseData);
        const plans = {
          course_id: insertData.id,
          interval: courseData["subscription_interval"],
          period: result.data.period,
          razorpay_plan_id: result.data.id,
          razorpay_plan_creation_date: result.data.created_at,
          subscription_cost: courseData["subscription_cost"],
        };

        await courseBuilderService.CreateCoursePlan(plans);
      }
    } catch (e) {
      console.log("There was an error while creating course plan!");
    }

    if (insertData) {
      const certificationPartnersInputObject = (
        certificationPartners || []
      ).map((partner_id) => {
        return {
          partner_id: partner_id,
          course_id: insertData.id,
        };
      });
      createCourseCertificationPartners({
        variables: {
          certificationPartners: certificationPartnersInputObject,
        },
      }).then(successHandler("Course Created Successfully!"));
      let path =
        getModulePathStringById(ModuleNameIdMap.viewCourse) +
        `?id=${insertData.id}`;

      history.push(path);
    } else {
      errorHandler("There was an error while creating course!");
    }
  };

  return (
    <Container title={"Create / Update Course"}>
      {/* <h2 className="text-3xl font-bold px-4">Create a New Course</h2> */}
      <Form
        formBuilder={CourseFormBuilder}
        className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
        submitButton={{
          text: "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
        }}
        initValues={{
          publish: false,
          is_moodle_course: false,
          is_subscription: false,
          is_live_course: false,
          has_certificate: false,
        }}
        onSubmit={createCourse}
      />
    </Container>
  );
}

export default CreateCoursePage;
