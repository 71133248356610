import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "skillstrainer-resource-library/css/styles.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css"; //bootstrap
import "boxicons"; //boxicons
import { Provider } from "react-redux";
import store from "./redux/store";
import { FileUploaderPlugin } from "skillstrainer-resource-library/components/form-ext/plugin-list";
import { getFile, uploadFile } from "api/UploadFile";
import { errorHandler } from "utils/toast";

setInterval(() => {
  const iframes = document.getElementsByTagName("iframe");
  for (const iframe of iframes)
    if (iframe.style.zIndex === "2147483647") iframe.remove();
}, 1000);

// Settings file uploader config
FileUploaderPlugin.services = {
  uploadFn: (fileData) =>
    uploadFile(fileData, fileData.name, "public-assets", true)
      .then((res) => res.data_url)
      .catch(
        (e) =>
          console.error(e) || errorHandler("An error occured while uploading")
      ),
  getUrl: (args) => getFile(args).then((res) => console.log(res) || res),
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
