import * as yup from "yup";
import { useState, useEffect } from "react";

function CourseCompletionForm(){
  const [schema, setSchema] = useState({});

  useEffect(() => {
    const schema = {
      course_completion_desc: {
        type: "rich-text",
        label: "Description",
        schema: yup.string(),
      }, 
    }

  setSchema(schema);

},[])

return schema;
  
}

export default CourseCompletionForm;
