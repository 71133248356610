import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs";
import gjsWebpagePreset from "grapesjs-preset-webpage";
import gjsBlocksBasic from "grapesjs-blocks-basic";
import gjsPluginForms from "grapesjs-plugin-forms";
import { uploadFile as uploadFileApi } from "api/GrapesUploadApi";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  GET_EMAIL_TEMPLATE_BY_ID,
  GET_EMAIL_TEMPLATES_NAMES,
} from "GraphQl/Queries/EmailTemplate";
import Select from "components/InputGroup/Select";
import { useState, useEffect } from "react";
import { UPDATE_EMAIL_TEMPLATE } from "GraphQl/Mutations/EmailTemplateMaker";
import { errorHandler, successHandler } from "utils/toast";
import Input from "components/InputGroup/Input";
import _ from "lodash";
import Container from "components/Container/Container";

function EmailTemplate() {
  const [emailTemplateData, setEmailTemplateData] = useState({
    subject: "",
    template_data: {},
  });

  const [editor, setEditor] = useState();

  const { data: fetchEmailTempNames } = useQuery(GET_EMAIL_TEMPLATES_NAMES);

  const [runQuery] = useLazyQuery(GET_EMAIL_TEMPLATE_BY_ID);

  useEffect(() => {
    const editor = grapesjs.init({
      container: "#gjs",
      height: "600px",
      width: "auto",
      dragMode: "absolute",
      plugins: [gjsWebpagePreset, gjsBlocksBasic, gjsPluginForms],
      assetManager: {
        assets: [],
        uploadFile: async (fileData) => {
          await uploadFileApi(
            fileData.target.files[0],
            fileData.target.files[0].name,
            "public-assets",
            true
          )
            .then((res) => {
              editor.AssetManager.add({
                src: res.s3_url,
                "data-key": res.data_url,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        },
      },
    });

    editor.on("load", () => {
      editor.DomComponents.clear();
      addHtmlToEditor(editor);
    });

    setEditor(editor);
  }, []);

  const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE);

  const submitEmailTemplate = async () => {
    const { template_data, html } = getEditorData(editor);
    console.log(getEditorData(editor));

    if (emailTemplateData.id) {
      await updateEmailTemplate({
        variables: {
          id: emailTemplateData.id,
          email_template: {
            subject: emailTemplateData.email_template.subject,
            template_data: template_data,
            html: html,
          },
        },
      })
        .then(() => {
          successHandler("Template Updated Succesfully");
        })
        .catch((error) => {
          console.log(error);
          errorHandler("There was an error while updating Email Template");
        });
    } else return errorHandler("Please Select a Notification Event  ");
  };

  const onChangeHandler = async (id) => {
    if (id == -1) {
      console.log("i am here==");
      editor.DomComponents.clear();
      emailTemplateData.email_template.subject = "";
      setEmailTemplateData({ ...emailTemplateData });
    } else {
      runQuery({
        variables: {
          id: id,
        },
      }).then((res) => {
        console.log(res);
        console.log(
          res?.data?.courses_notification_event_types[0]?.email_template
            .template_data
        );
        const data = _.cloneDeep(res.data);
        editor.loadProjectData(
          data.courses_notification_event_types[0].email_template.template_data
        );
        setEmailTemplateData(data?.courses_notification_event_types[0]);
      });
    }
  };

  window.getEditorData = () => getEditorData(editor);
  return (
    <Container title="Email Templates">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Select
            label="Email Template Name"
            className="my-4"
            options={fetchEmailTempNames?.courses_notification_event_types}
            // value={chosenEmailTemp}
            valueField={"id"}
            displayField={"name"}
            onChange={(e) => {
              onChangeHandler(e.target.value);
            }}
          />
        </div>

        <div>
          <Input
            label="Email Subject"
            className="my-4"
            type="text"
            value={emailTemplateData?.email_template?.subject}
            onChange={(e) => {
              emailTemplateData.email_template.subject = e.target.value;
              setEmailTemplateData({ ...emailTemplateData });
            }}
          />
        </div>
      </div>
      <div
        id="gjs"
        className=" border-4 rounded-md border-black w-full h-full"
      ></div>

      <div className="mt-4">
        <button
          className="btn-primary hover:oppdacity-70"
          onClick={submitEmailTemplate}
        >
          Save
        </button>
      </div>
    </Container>
  );
}

export default EmailTemplate;

const addHtmlToEditor = (
  editor,
  html = `
`
) => {
  editor.setComponents(html);
};

const getEditorData = (editor) => {
  const template_data = editor.getProjectData();
  var html = editor.getHtml();

  console.log("html=====", html);

  var css = editor.getCss();
  var html =
    `<html><head><meta charset="utf-8"><style>` +
    css +
    "</style></head>" +
    html +
    "</html>";

  return {
    template_data,
    html,
  };
};
