import {
  DATE_FORMATS,
  PARTNER_WEBSITE_STATUS,
  PARTNER_WEBSITE_STATUS_LABEL,
} from "api/Consts";
import { GET_PARTNER_WEBSITE_CONFIG_BY_PARTNER_ID_SUB } from "GraphQl/Subscriptions/PartnerWebsiteConfiguration";
import moment from "moment";
import React, { useMemo } from "react";
import { useSubscription } from "utils/subscription";

export default function PartnerMarketingWebsiteStatus(props) {
  const { partnerId } = props;

  const {
    data: partnerMarketingWebsiteConfigRes,
    error: partnerMarketingWebsiteConfigError,
  } = useSubscription(GET_PARTNER_WEBSITE_CONFIG_BY_PARTNER_ID_SUB, {
    variables: { partnerId },
  });

  console.log(
    partnerMarketingWebsiteConfigRes,
    partnerMarketingWebsiteConfigError
  );

  const partnerMarketingWebsiteConfig = useMemo(
    () =>
      partnerMarketingWebsiteConfigRes
        ?.courses_partner_website_configuration[0],
    [partnerMarketingWebsiteConfigRes]
  );
  const partnerMarketingWebsiteConfigLoaded =
    partnerMarketingWebsiteConfigRes &&
    partnerMarketingWebsiteConfigRes.courses_partner_website_configuration;

  const { status, website_url, last_published_at, edited_at } =
    partnerMarketingWebsiteConfig || {};

  return (
    <>
      {partnerMarketingWebsiteConfig ? (
        <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {detail(
            "Status",
            PARTNER_WEBSITE_STATUS_LABEL[status],
            status === PARTNER_WEBSITE_STATUS.failed
              ? "text-red-500"
              : status === PARTNER_WEBSITE_STATUS.unpublished
              ? "text-orange"
              : [
                  PARTNER_WEBSITE_STATUS.publishing,
                  PARTNER_WEBSITE_STATUS.beginningPublish,
                ].includes(status)
              ? "text-blue-500"
              : "text-green-600"
          )}
          {detail(
            "Website URL",
            website_url ? (
              <a href={website_url} target="_blank">
                {website_url}
              </a>
            ) : (
              <span className="text-gray-400 font-italic">Not available</span>
            )
          )}
          {detail(
            "Last published at",
            (last_published_at &&
              moment(last_published_at).format(
                DATE_FORMATS.BATCH_SLOT_DATE_TIME
              )) || (
              <span className="text-gray-400 font-italic">Not available</span>
            )
          )}
          {detail(
            "Last edited at",
            moment(edited_at).format(DATE_FORMATS.BATCH_SLOT_DATE_TIME)
          )}
        </div>
      ) : !partnerMarketingWebsiteConfigLoaded ? (
        <div className="w-full text-center font-semibold text-gray-300">
          Loading...
        </div>
      ) : (
        <div className="w-full text-center font-semibold text-gray-300 font-italic">
          No config created
        </div>
      )}
    </>
  );
}

const detail = (key, value, valueClass) => {
  return (
    <div className="flex flex-col items-start">
      <span className="font-semibold">{key}</span>
      <span className={valueClass}>{value}</span>
    </div>
  );
};
