import {gql} from "@apollo/client";

export const GET_COURSE_TAX=gql`
  query GetCourseTax {
    courses_course_tax {
      tax_name
      id
      tax_percentage
      tax_enable
    }
  }

`

export const GET_COURSE_ENABLE_TAX=gql`

query GetCourseTax {
  courses_course_tax(where: {tax_enable: {_eq: true}}) {
    tax_name
    id
    tax_percentage
    tax_enable
  }
}
`