import React, { useRef, useMemo, useEffect } from 'react';
import { Form } from 'skillstrainer-resource-library';
import Container from "components/Container/Container";
import ReferralSchemeFormBuilder from './ReferralSchemeFormBuilder';
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { errorHandler, successHandler } from "utils/toast";
import { INSERT_REFERRAL_SCHEME } from 'GraphQl/Mutations/Referral';
import { gqlUserRecoilVar } from "State/User";
import { useRecoilState } from "recoil";
import { GET_REFERRAL_SCHEME } from 'GraphQl/Queries/Referral';
import { FiRefreshCw } from "react-icons/fi";
import DataTable from "react-data-table-component";


export default function ReferralScheme() {
  const formRef = useRef({});
  const user_id = useRecoilState(gqlUserRecoilVar)?.[0]?.id;

  const [
    runQuery,
    { data: schemesRes, loading: schemesLoading },
  ] = useLazyQuery(GET_REFERRAL_SCHEME, {
    fetchPolicy: "network-only", // Doesn't check cache before making a network request
  });

  useEffect(() => {
    runQuery()
  }, [])
  

  const data = useMemo(() => {
    if (user_id && schemesRes) {
      return schemesRes.courses_referral_scheme
    }
  }, [user_id, schemesRes]);
  
  const [insertScheme] = useMutation(INSERT_REFERRAL_SCHEME);

  const submit = async (res) => {
    try {
      res.admin_user_id = user_id
      insertScheme({
        variables: { referral_scheme: res },
      })
        .then(() => {
          successHandler("Scheme created successfully!");
        })
        .catch((err) => {
          errorHandler("An error occured while creating the scheme");
          console.log(err);
        });
    } catch (err) {
      errorHandler("Couldn't submit form");
      console.error(err);
    }
  };

  return (
    <Container title={"Referral Scheme"} className="mx-4 my-4">
      <Form
        // key={partnerConfigInitValues?.id || "new-form"}
        formBuilder={ReferralSchemeFormBuilder}
        className=" ml-6 mt-2 mb-4 grid grid-cols-2 gap-x-2"
        hideSubmit={true}
        onSubmit={submit}
        // initValues={partnerConfigInitValues}
        ref={(e) => (formRef.current = e)}
      />
      <div className="ml-6 mt-4">
        <button
          onClick={() => formRef.current.submit()}
          className="btn-primary mr-3"
        >
          Add Scheme
        </button>
      </div>
      <div className="mt-5">
        <div className="flex flex-row">
          <p className="text-2xl font-medium">Schemes</p>
          <button
            onClick={() => runQuery()}
            className="p-2 text-gray-500 w-5 h-5"
          >
            <FiRefreshCw />
          </button>
        </div>
        <div className='overflow-x-scroll	'>
          <DataTable
            className="min-w-max"
            columns={importsColumns}
            progressPending={schemesLoading}
            data={!schemesLoading ? data : []}
          />
        </div>
      </div>
    </Container>
  )
}

const importsColumns = [
  {
    name: "Id",
    cell: (row, index) => `${row.id}`,
  },
  {
    name: "Referral Scheme Name",
    width:'200px',
    cell: (row, index) => `${row.name}`,
  },
  {
    name: "User Id",
    cell: (row, index) => `${row.admin_user_id}`,
  },
  {
    name: "Applicable On",
    width:'200px',
    cell: (row, index) => `${row.action}`,
  },
  {
    name: "Referrer Commission Type",
    width:'200px',
    cell: (row, index) => row.referrer_reward.type,
  },
  {
    name: "Referrer Commission Value",
    width:'200px',
    cell: (row, index) => row.referrer_reward.value,
  },
  {
    name: "Referee Commission Type",
    width:'200px',
    cell: (row, index) => row.referee_reward.type,
  },
  {
    name: "Referree Commission Value",
    width:'200px',
    cell: (row, index) => row.referee_reward.value,
  },
  {
    name: "Applicable Courses Ids",
    width:'200px',
    cell: (row, index) => row.constraints.course_ids?row.constraints.course_ids.toString():"All",
  },
];

