import React from "react";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../../components/InputGroup/FilterComponent";
import { useState } from "react";

function AdminUsersTable(props) {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const handleButtonClick = (e, row) => {
    props.updateAdminUser(row);
  };

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const columns = [
    {
      name: "id",
      selector: (row, index) => `${row.id}`,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row, index) => `${row.name}`,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row, index) => `${row.email || "NA"}`,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row, index) => `${row.phone_number || "NA"}`,
      sortable: true,
    },
    {
      name: "Role Id",
      selector: (row, index) => `${row.role_id}`,
      sortable: true,
    },
    {
      name: "Partner Id",
      selector: (row, index) => `${row.partner_id || "NA"}`,
      sortable: true,
    },
    {
      name: "Project Id",
      selector: (row, index) => `${row.project_id || "NA"}`,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row, index) => `${row.active || true}`,
      sortable: true,
    },
    {
      cell: (row) => (
        <button
          className="bg-gray-700 p-2 text-base text-white"
          onClick={(e) => handleButtonClick(e, row)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const filteredItems = props.allUsers.data?.courses_admin_users.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  const getTotalCourses = () => {
    if (props.totalUsers.data) {
      return props.totalUsers.data.courses_admin_users_aggregate.aggregate
        .count;
    }
  };

  return (
    <div>
      <DataTable
        columns={columns}
        pagination
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        data={filteredItems}
        progressPending={props.allUsers.loading}
        paginationTotalRows={getTotalCourses()}
        selectableRows={false}
        selectableRowsHighlight={false}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </div>
  );
}

export default AdminUsersTable;
