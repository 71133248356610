import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  COURSES_QUERY,
  GET_INSTRUCTOR_ID_NAME,
} from "../GraphQl/Queries/Courses";
import { GET_PARTNERS } from "../GraphQl/Queries/Partner";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "../GraphQl/Queries/Projects";
import { GET_ALL_INSTITUTES } from "../GraphQl/Queries/Repositories";
import { multiSelectSchema } from "utils/schemas";
import { daysOptions } from "../Pages/Dashboard/Courses/BatchData";

function CreateUpdateBatchFormBuilder(values) {
  //Get Institutes
  const [instituteOptions, setInstituteOptions] = useState([]);
  useQuery(GET_ALL_INSTITUTES, {
    onCompleted: (data) => {
      const instituteOption = data.courses_institutes.map((ins) => {
        return { value: ins.id, label: ins.name, pincode: ins.pincode };
      });

      setInstituteOptions(instituteOption);
    },
  });

  // Get Instructors
  const [instructorOptions, setInstructors] = useState([]);
  const instructorIdName = useQuery(GET_INSTRUCTOR_ID_NAME, {
    onCompleted: (data) => {
      const instructorOptions = data.courses_instructor.map((inst, i) => {
        return { value: inst.id, label: inst.name + " - " + inst.email };
      });

      setInstructors(instructorOptions);
    },
  });

  //Get Partners
  const [partnerOption, setPartners] = useState([]);
  useQuery(GET_PARTNERS, {
    onCompleted: (data) => {
      const partnerOption = data.courses_partner.map((part, i) => {
        return { value: part.id, label: part.name };
      });

      setPartners(partnerOption);
    },
  });

  //Get Courses
  const [courseOptions, setCourses] = useState([]);
  useQuery(COURSES_QUERY, {
    onCompleted: (data) => {
      const courseOptions = data.courses_course.map((course, i) => {
        return { value: course.id, label: course.full_name };
      });

      setCourses(courseOptions);
    },
  });

  // Get partner projects
  const [partnerProjectOption, setPartnerProject] = useState([]);
  useQuery(GET_PROJECTS, {
    fetchPolicy: "network-only", // Doesn't check cache before making a network request
    onCompleted: (data) => {
      const partnerProjectOption = data.courses_partner_projects.map((part) => {
        return { value: part.id, label: part.name };
      });

      setPartnerProject(partnerProjectOption);
    },
  });

  const [schema, setSchema] = useState({});
  useEffect(() => {
    const schema = {
      batch_name: {
        type: "text",
        label: "Batch Name",
        required: true,
        schema: yup.string(),
      },
      course_id: {
        type: "select",
        required: true,
        label: "Course Name",
        options: courseOptions.map((course) => ({
          value: course.value,
          label: course.label,
        })),
      },
      from_date: {
        type: "date",
        label: "Event start date",
        required: true,
      },
      to_date: {
        type: "date",
        label: "Event end Date",
      },
      slot_start_time: {
        type: "time",
        label: "Slot start time",
      },
      slot_end_time: {
        type: "time",
        label: "Slot end time",
      },

      slot_days: {
        type: "multi-select",
        label: "Select Repeat Days",
        options: daysOptions,
        schema: multiSelectSchema,
      },
      instructor_id: {
        type: "select",
        label: "Trainer Name",
        required: true,
        options: instructorOptions.map((instructor) => ({
          value: instructor.value,
          label: instructor.label,
        })),
      },
      min_learners: {
        type: "text",
        label: "Min. No. of Learners",
        schema: yup.number(),
      },
      max_learners: {
        type: "text",
        label: "Max.No. of Learners",
        schema: yup.number(),
      },
      type: {
        type: "select",
        label: "Class type",
        options: [
          { value: "offline", label: "Offline" },
          { value: "online", label: "Online" },
        ],
      },
      location: {
        type: "hidden",
        label: "Location",
      },
      pincode: {
        type: "hidden",
        label: "Pin Code",
      },
      institute_id: {
        type: "hidden",
        label: "Institute",
      },
      partner_id: {
        type: "select",
        label: "Partner",
        options: partnerOption.map((partner) => ({
          value: partner.value,
          label: partner.label,
        })),
      },
      project_id: {
        type: "select",
        label: "Partner Project",
        options: partnerProjectOption.map((projects) => ({
          value: projects.value,
          label: projects.label,
        })),
      },
      is_demo: {
        type: "boolean",
        label: "Is Demo Batch",
      },
    };

    const institute_options = values.pincode
      ? instituteOptions.filter((data) => {
          if (values.pincode === data.pincode) {
            return {
              value: data.value,
              label: data.label,
            };
          }
          return false;
        })
      : instituteOptions;

    if (values.type === "offline") {
      schema.location = { type: "textarea", label: "Location" };
      schema.pincode = {
        type: "text",
        label: "Pin Code",
      };
      schema.institute_id = {
        type: "select",
        label: "Institute",
        options: institute_options,
      };
    }
    setSchema(schema);
  }, [values, courseOptions]);

  return schema;
}

export default CreateUpdateBatchFormBuilder;
