import React, { useEffect, Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../../components/InputGroup/FilterComponent";

import { Link } from "react-router-dom";
import Select from "components/InputGroup/Select";
import { useQuery } from "@apollo/client";
import { COURSE_CATEGORY_QUERY } from "GraphQl/Queries/Courses";
import { Menu, Transition } from "@headlessui/react";
import { FaRegSun } from "react-icons/fa";
import Checkbox from "components/InputGroup/Checkbox";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";
function CoursesTable(props) {
  const catData = useQuery(COURSE_CATEGORY_QUERY);

  const isAdmin = props.partnerAccess.isAdmin;

  const [filterText, setFilterText] = useState("");
  const [category, setCategory] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleButtonClick = (e, row) => {
    ////console.log('clicked : ', row);
    props.performAction(row);
  };

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const hideCourseProps = (e, id, status) => {
    e.preventDefault();
    props.hideCourseFun(id, status);
  };

  const filteredItems = isAdmin
    ? props.courses.data?.courses_course.filter(
        (item) =>
          item.full_name &&
          item.full_name.toLowerCase().includes(filterText.toLowerCase())
      )
    : props.courses.data?.courses_partner_revenue_split_directives;

  const copy = (id) => {
    const filter = filteredItems.find((e) => e.id === id);

    props.copyFun(filter);
  };

  const columns = [
    {
      name: "Course Id",
      selector: (row, index) => `${isAdmin ? row.id : row.course.id}`,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Name",
      selector: (row, index) =>
        `${isAdmin ? row.full_name : row.course.full_name}`,
      sortable: true,
      wrap: true,
      width: "25rem",
    },
    {
      name: "Category",
      selector: (row, index) =>
        `${
          isAdmin ? row.course_category.name : row.course.course_category.name
        }`,
      sortable: true,
      width: "20rem",
    },
    {
      name: "Description",
      selector: (row, index) => (
        <div
          className="list-wrapper"
          dangerouslySetInnerHTML={{
            __html: isAdmin ? row.description : row.course.description,
          }}
        ></div>
      ),
      sortable: true,
      width: "30rem",
    },

    {
      cell: (row) => (
        <Menu as="div" className="float-right relative inline-block text-left">
          <Menu.Button
            className="inline-flex justify-center w-full  
  px-4 py-2  text-sm 
  font-medium text-black-700 "
          >
            <FaRegSun size={24} />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md z-50 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1 z-50	">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={
                        "bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
                      }
                      to={props.editRoute + "?id=" + row["id"]}
                    >
                      Edit Course
                    </Link>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={
                        "bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
                      }
                      to={props.viewRoute + "?id=" + row["id"]}
                    >
                      View Course
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={
                        "bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
                      }
                      to={
                        getModulePathStringById(
                          ModuleNameIdMap.courseUserReports
                        ) + `/?id=${row["id"]}`
                      }
                    >
                      Enrolled Users
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={
                        "bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
                      }
                      onClick={(e) => hideCourseProps(e, row.id, row.publish)}
                    >
                      {row.publish ? "Hide" : "Show"}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={
                        "bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
                      }
                      onClick={(e) => copy(row.id)}
                    >
                      Copy
                    </Link>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={
                        "bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
                      }
                      onClick={(e) => props.delete(row.id)}
                    >
                      Delete
                    </Link>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  ////console.log('Course criteris ', props.courseCriterias);
  // const getTotalCourses = () => {
  //   if (props.totalCourses.data) {
  //     return isAdmin
  //       ? props.totalCourses.data.courses_course_aggregate.aggregate.count
  //       : props.partnerTotalCourses.data
  //           .courses_partner_revenue_split_directives_aggregate.aggregate.count;
  //   }
  // };

  const catChange = (e) => {
    const catId = e.target.value;
    props.catFilterFun(catId);
  };

  useEffect(() => {
    const catOptions = catData.data?.courses_course_categories.map(
      (inst, i) => {
        return { value: inst.id, label: inst.name };
      }
    );

    setCategory(catOptions);
  }, [catData]);

  const conditionalRowStyles = [
    {
      when: (row) => (isAdmin ? !row.publish : !row.course.publish),
      style: {
        backgroundColor: "gray",
        userSelect: "none",
      },
    },
  ];

  // Update checkbox
  const updateCheckbox = (e) => {
    const value = e.target.checked;

    props.courseHideFilter(value);
  };

  return (
    <div id="manage_course_table">
      <div className={`w-6/12 ${isAdmin ? "mb-5" : ""} inline-flex`}>
        {isAdmin && (
          <Select
            label={"Filter By Category"}
            options={category}
            valueField={"value"}
            displayField={"label"}
            data-key={"category"}
            onChange={catChange}
          />
        )}
        {isAdmin && (
          <Checkbox
            className={"mt-8"}
            label={"Enabled Courses"}
            type="checkbox"
            data-key={"hide-show"}
            onChange={(e) => updateCheckbox(e)}
            defaultChecked={props.hideFilter}
          />
        )}
      </div>
      <DataTable
        columns={columns}
        pagination
        subHeader
        subHeaderComponent={isAdmin && subHeaderComponentMemo}
        data={filteredItems}
        progressPending={props.courses.loading}
        // paginationTotalRows={getTotalCourses()}
        selectableRows={false}
        selectableRowsHighlight={false}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        conditionalRowStyles={conditionalRowStyles}

        // onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
        //     handleRowSelection(allSelected, selectedRows);
        // }}
      />
    </div>
  );
}

export default CoursesTable;
