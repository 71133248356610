import * as yup from "yup";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { multiSelectSchema } from "utils/schemas";
import { GET_JOB_FORM_OPTIONS } from "GraphQl/Queries/Jobs";

function JobFormBuilder(values) {
  const { data: formOptionsData } = useQuery(GET_JOB_FORM_OPTIONS);
  const [schema, setSchema] = useState({});
  const [selectedPartner, setSelectedPartner] = useState();

  useEffect(() => {
    const partnerSelect = (formOptionsData?.courses_partner || []).find(
      (partner) => {
        if (partner.id === Number(values.partner_id)) return partner;
      }
    );
    setSelectedPartner(partnerSelect);
  }, [values.partner_id]);

  useEffect(() => {
    const schema = {
      partner_id: {
        label: "Employer",
        type: "select",
        options: formOptionsData?.courses_partner.map((partner) => ({
          label: partner.name,
          value: partner.id,
        })),
        required: true,
        schema: yup.string(),
      },
      otherpartner_id: {
        label: "Other Project Partner",
        type: "multi-select",
        options: formOptionsData?.courses_partner.map((partner) => ({
          label: partner.name,
          value: partner.id,
        })),
        schema: multiSelectSchema,
      },

      project_id: {
        label: "Project Name",
        type: "select",
        options: ((selectedPartner && selectedPartner.projects) || []).map(
          (project) => ({
            label: project.name,
            value: project.id,
          })
        ),
        schema: yup.string(),
      },
      title: {
        label: "Company Job Title",
        type: "text",
        required: true,
      },
      associated_job_role_id: {
        label: "Associated Job Role",
        type: "select",
        options: formOptionsData?.courses_qualifications_lookup
          .filter((q) => q.type === "nsqf")
          .map((jobRole) => {
            return {
              label: jobRole.name + "(" + jobRole.qp_code + ")",
              value: jobRole.id,
            };
          }),
      },
      salary_ctc: {
        label: "Salary (Monthly)",
        type: "text",
        required: true,
      },
      description: {
        label: "Job Description",
        type: "textarea",
      },
      min_age: {
        type: "text",
        label: "Minimum Age",
        schema: yup.number(),
      },
      max_age: {
        type: "text",
        label: "Maximum Age",
        schema: yup
          .number()
          .test(
            "max-age-greater-than-min-age",
            "Maximum Age should be greater than Minimum Age",
            function (maxAge) {
              return values.min_age < maxAge;
            }
          ),
      },

      is_experienced: {
        type: "boolean",
        label: "Experience level",
        options: [
          { label: "Fresher", value: "fresher" },
          { label: "Experienced", value: "experienced" },
        ],
        trueLabel: "Experienced",
        falseLabel: "Fresher",
      },

      min_experience: {
        type: "hidden",
        label: "Minimum Experience",
      },
      max_experience: {
        type: "hidden",
        label: "Maximum Experience",
        schema: yup.number(),
        // .test(
        //   "max-exp-greater-than-min-exp",
        //   "Maximum Experience should be greater than Minimum Experience",
        //   function (max_experience) {
        //     return values.min_experience < max_experience;
        //   }
        // ),
      },
      no_of_positions: {
        label: "Number of Positions",
        type: "text",
        required: true,
        schema: yup.number(),
      },
      sector: {
        type: "select",
        label: "Sector",
        options: formOptionsData?.courses_course_categories.map((sector) => {
          return {
            label: sector.name,
            value: sector.id,
          };
        }),
      },
      family_income: {
        label: "Family Income",
        type: "select",
        options: [
          { label: "Less than 1L", value: 1 },
          { label: "1L - 2.5L", value: 2 },
          { label: "2.5L - 5L", value: 3 },
          { label: "5L and above", value: 4 },
        ],
      },
      social_categories: {
        type: "multi-select",
        schema: multiSelectSchema,
        label: "Social Categories",
        options: formOptionsData?.courses_social_categories_lookup.map((sc) => {
          return {
            label: sc.name,
            value: sc.id,
          };
        }),
      },
      shift_start_timing: {
        label: "Shift Starting Time",
        type: "time",
      },
      shift_end_timing: {
        label: "Shift Ending Time",
        type: "time",
      },
      gender: {
        label: "Gender",
        schema: multiSelectSchema,
        type: "multi-select",
        options: formOptionsData?.courses_genders_lookup.map((gen) => {
          if (gen.name.toLowerCase() === "any") {
            return {
              label: gen.name,
              value: null,
            };
          } else {
            return {
              label: gen.name,
              value: gen.id,
            };
          }
        }),
      },

      marital_status: {
        label: "Marital Status",
        type: "select",
        options: [
          {
            label: "Married",
            value: "married",
          },
          {
            label: "Unmarried",
            value: "unmarried",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
      },
      academic_qualifications: {
        type: "object",
        schema: yup.object(),
        label: " Highest Required Academic Qualification",
        fields: {
          school: {
            type: "select",
            label: "School",
            options: formOptionsData?.courses_qualifications_lookup
              .filter(
                (q) =>
                  q.code === "5" ||
                  q.code === "0" ||
                  q.code === "1" ||
                  q.code === "2" ||
                  q.code === "3"
              )

              .map((qualification) => {
                return {
                  label: qualification.name,
                  value: qualification.id,
                };
              }),
          },
          bachelors: {
            type: "select",
            label: "Bachelors",
            options: formOptionsData?.courses_qualifications_lookup
              .filter((q) => q.type === "bachelors")
              .map((qualification) => {
                return {
                  label: qualification.name,
                  value: qualification.id,
                };
              }),
          },
          masters: {
            type: "select",
            label: "Masters",
            options: formOptionsData?.courses_qualifications_lookup
              .filter((q) => q.type === "masters")
              .map((qualification) => {
                return {
                  label: qualification.name,
                  value: qualification.id,
                };
              }),
          },
        },
      },
      technical_qualifications: {
        type: "object",
        schema: yup.object(),
        label: " Required Technical Qualification",
        fields: {
          iti: {
            label: "ITI",
            type: "select",
            options: [
              {
                label: "Major ITI",
                value: "Major ITI",
              },
              {
                label: "Any",
                value: "Any",
              },
            ],
          },
          diploma: {
            type: "select",
            label: "Diploma",
            options: (formOptionsData?.courses_qualifications_lookup || [])
              .filter((q) => q.type === "diploma")
              .map((qualification) => {
                return {
                  label: qualification.name,
                  value: qualification.id,
                };
              }),
          },
          nsqf: {
            type: "select",
            label: "NSQF",
            options: (formOptionsData?.courses_qualifications_lookup || [])
              .filter((q) => q.type === "nsqf")
              .map((qualification) => {
                return {
                  label: qualification.name + "(" + qualification.qp_code + ")",
                  value: qualification.id,
                };
              }),
          },
        },
      },
      preferred_academic_qualifications: {
        type: "object",
        schema: yup.object(),
        label: " Preferred Academic Qualification",
        fields: {
          school: {
            type: "select",
            label: "School",
            options: formOptionsData?.courses_qualifications_lookup
              .filter((q) => q.type === "pre_12" || q.type === "12")
              .map((qualification) => {
                return {
                  label: qualification.name,
                  value: qualification.id,
                };
              }),
          },
          bachelors: {
            type: "select",
            label: "Bachelors",
            options: formOptionsData?.courses_qualifications_lookup
              .filter((q) => q.type === "bachelors")
              .map((qualification) => {
                return {
                  label: qualification.name,
                  value: qualification.id,
                };
              }),
          },
          masters: {
            type: "select",
            label: "Masters",
            options: formOptionsData?.courses_qualifications_lookup
              .filter((q) => q.type === "masters")
              .map((qualification) => {
                return {
                  label: qualification.name,
                  value: qualification.id,
                };
              }),
          },
        },
      },
      preferred_technical_qualifications: {
        type: "object",
        schema: yup.object(),
        label: " Preferred Technical Qualification",
        fields: {
          iti: {
            type: "select",
            label: "ITI",
            options: (formOptionsData?.courses_qualifications_lookup || [])
              .filter((q) => q.type === "iti")
              .map((qualification) => {
                return {
                  label: qualification.name,
                  value: qualification.id,
                };
              }),
          },
          diploma: {
            type: "select",
            label: "Diploma",
            options: (formOptionsData?.courses_qualifications_lookup || [])
              .filter((q) => q.type === "diploma")
              .map((qualification) => {
                return {
                  label: qualification.name,
                  value: qualification.id,
                };
              }),
          },
          nsqf: {
            type: "select",
            label: "NSQF",
            options: (formOptionsData?.courses_qualifications_lookup || [])
              .filter((q) => q.type === "nsqf")
              .map((qualification) => {
                return {
                  label: qualification.name + "(" + qualification.qp_code + ")",
                  value: qualification.id,
                };
              }),
          },
        },
      },
      skills: {
        label: "Skills",
        type: "object",
        insertable: true,
        repeat: true,
        schema: yup.object(),
        fields: {
          skill: {
            type: "select",
            label: "Skill",
            options: formOptionsData?.courses_skillstrainer_skills.map(
              (skill) => {
                return {
                  label: skill.label,
                  value: skill.id,
                };
              }
            ),
          },
          required: {
            type: "boolean",
            label: "Required",
          },
        },
      },
      languages: {
        type: "object",
        label: "Languages",
        repeat: true,
        insertable: true,
        fields: {
          language: {
            label: "Language",
            type: "select",
            options: formOptionsData?.courses_skillstrainer_languages.map(
              (language) => {
                return {
                  label: language.name,
                  value: language.id,
                };
              }
            ),
          },
          proficiency: {
            schema: multiSelectSchema,
            label: "Profciency",
            type: "multi-select",
            options: [
              {
                label: "Can Read",
                value: "can_read",
              },

              {
                label: "Can Write",
                value: "can_write",
              },

              {
                label: "Can Speak",
                value: "can_speak",
              },
            ],
          },
          required: {
            label: "Required",
            type: "boolean",
          },
        },
      },
      locations: {
        type: "object",
        label: "Location",
        repeat: true,
        insertable: true,
        schema: yup.object(),
        fields: {
          city: {
            schema: yup.object(),
            label: "City",
            type: "address",
            keys: {
              action: "include",
              keys: ["country", "city_town", "district"],
            },
          },
          no_of_positions: {
            label: "No. of Positions",
            type: "text",
            schema: yup.number(),
          },
        },
      },
      documents: {
        type: "object",
        label: "Documents",
        schema: yup.object(),
        insertable: true,
        repeat: true,
        fields: {
          document: {
            type: "select",
            label: "Document",
            options: formOptionsData?.courses_document_types_lookup.map(
              (document) => {
                return {
                  label: document.name,
                  value: document.id,
                };
              }
            ),
          },
          required: {
            type: "boolean",
            label: "Required",
          },
        },
      },

      is_active: {
        label: "Is Active",
        type: "boolean",
      },
      is_remote: {
        label: "Is Remote",
        type: "boolean",
      },
    };

    if (values.is_experienced === true) {
      console.log("hey=");
      schema.max_experience = { type: "text", label: "Minimum Experience" };
      schema.min_experience = {
        type: "text",
        label: "Maximum Experience",
        schema: yup.number(),
        // .test(
        //   "max-exp-greater-than-min-exp",
        //   "Maximum Experience should be greater than Minimum Experience",
        //   function (max_experience) {
        //     return values.min_experience < max_experience;
        //   }
        // ),
      };
    }

    setSchema(schema);
  }, [selectedPartner, values, formOptionsData]);

  return schema;
}

export default JobFormBuilder;
