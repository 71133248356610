import { gql } from "@apollo/client";

export const COURSES_QUERY = gql`
  query fetchCourses($where: courses_course_bool_exp = {}) {
    courses_course(order_by: { id: desc }, where: $where) {
      course_category_id
      created_at
      description
      end_date
      full_name
      id
      identifier
      is_moodle_course
      moodle_config_id
      moodle_course_url
      publish
      start_date
      updated_at

      moodle_config {
        id
        moodle_sp_name
      }
      course_category {
        id
        name
      }
      partners: certification_partners {
        partner {
          id
          name
          logo
        }
      }
      certificate_image_url
      certificate_template_id
      cost
      course_type
      discount
      duration
      image_url
      is_live_course
      is_subscription
      is_taxable
      live_class_duration
      nsqf_level
      short_name
      video_url
      moodle_course_id
      discontinued_from_moodle_at
    }
  }
`;

export const GET_COURSES_PAGINATION = gql`
  query fetchCourses($per_page: Int, $offset: Int, $searchTerm: String) {
    courses_course(
      where: { full_name: { _ilike: $searchTerm } }
      limit: $per_page
      offset: $offset
      order_by: { full_name: asc }
    ) {
      course_category_id
      created_at
      description
      end_date
      full_name
      id
      identifier
      is_moodle_course
      moodle_config_id
      moodle_course_url
      publish
      start_date
      updated_at
      moodle_config {
        id
        moodle_sp_name
      }
      course_category {
        id
        name
      }
      partners: certification_partners {
        partner {
          id
          name
          logo
        }
      }
      discontinued_from_moodle_at
    }
  }
`;

export const GET_TOTAL_COURSES = gql`
  query fetchTotalCourses {
    courses_course_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PARTNER_TOTAL_COURSES = gql`
  query partnerTotalCourses($partnerIds: [bigint!]!) {
    courses_partner_revenue_split_directives_aggregate(
      where: { partner_id: { _in: $partnerIds } }
      distinct_on: course_id
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_COURSE_BY_ID = gql`
  query fetchCourseById($id: bigint) {
    courses_course(where: { id: { _eq: $id } }) {
      cost
      course_category {
        id
        name
      }
      course_instructions
      course_category_id
      created_at
      description
      discount
      duration
      end_date
      full_name
      id
      identifier
      image_url
      is_live_course
      is_moodle_course
      has_certificate
      moodle_config {
        id
        moodle_sp_name
      }
      moodle_config_id
      moodle_course_id
      moodle_course_url
      nsqf_level
      publish
      short_name
      start_date
      updated_at
      partners: certification_partners {
        id
        partner_id
        course_id
        partner {
          id
          name
          logo
        }
      }
      video_url
      certificate_image_url
      is_subscription
      is_taxable
      course_type
      course_subscription_relation_array(
        where: { current_plan: { _eq: true } }
      ) {
        interval
        period
        razorpay_plan_id
        subscription_cost
      }
      discontinued_from_moodle_at
    }
  }
`;

// export const GET_COURSE_BY_ID = gql`
//   query fetchCourseById {
//     courses_course {
//       cost
//       course_category {
//         id
//         name
//       }
//       course_category_id
//       created_at
//       description
//       discount
//       duration
//       end_date
//       full_name
//       id
//       identifier
//       image_url
//       is_live_course
//       is_moodle_course
//       moodle_config {
//         id
//         moodle_sp_name
//       }
//       moodle_config_id
//       moodle_course_id
//       moodle_course_url
//       nsqf_level
//       publish
//       short_name
//       start_date
//       updated_at
//       partners: certification_partners {
//         partner {
//           id
//           name
//           logo
//         }
//       }
//       video_url
//       certificate_image_url
//       is_subscription
//       is_taxable
//       course_type
//       course_subscription_relation_array(
//         where: { current_plan: { _eq: true } }
//       ) {
//         interval
//         period
//         razorpay_plan_id
//         subscription_cost
//       }
//       discontinued_from_moodle_at
//     }
//   }
// `;

export const COURSE_CATEGORY_QUERY = gql`
  query fetchCourseCategories {
    courses_course_categories {
      created_at
      description
      id
      name
      slug
      image_url
      updated_at
      visible
    }
  }
`;

export const MOODLE_CONFIG_QUERY = gql`
  query fetchMoodleConfig {
    courses_moodle_config {
      moodle_server_acs_url
      moodle_sp_client_id
      moodle_sp_name
      moodle_sp_redirect_url
      updated_at
      id
    }
  }
`;
export const GET_MOODLE_CONFIG_BY_ID = gql`
  query fetchMoodleConfigById($id: bigint) {
    courses_moodle_config(where: { id: { _eq: $id } }) {
      moodle_server_acs_url
      moodle_sp_client_id
      moodle_sp_name
      moodle_sp_redirect_url
      moodle_sp_secret_key
    }
  }
`;
export const CATEGORY_MOODLE_CONFIG_QUERY = gql`
  query fetchMoodleAndCourseCategories {
    courses_course_categories {
      id
      name
    }
    courses_moodle_config {
      moodle_sp_name
      id
    }
  }
`;

export const GET_INSTRUCTOR_ID_NAME = gql`
  query fetchInstructor {
    courses_instructor {
      id
      name
      email
    }
  }
`;

export const GET_BATCH_ATTENDANCE = gql`
  query fetchBatchAttendance($batchId: Int) {
    courses_batch_trainee_attendences(where: { batch_id: { _eq: $batchId } }) {
      id
      batch_id
      slot_id
      user_id
      attendance_status
      created_at
      updated_at
      user {
        id
        name
        email
      }
      slot {
        id
        slot_date
      }
    }
  }
`;

export const GET_PARTNER_COURSES = gql`
  query getPartnerCourses($partnerIds: [bigint!]!) {
    courses_partner_revenue_split_directives(
      where: { partner_id: { _in: $partnerIds } }
    ) {
      course {
        course_category_id
        created_at
        description
        end_date
        full_name
        id
        identifier
        is_moodle_course
        moodle_config_id
        moodle_course_url
        publish
        start_date
        updated_at
        moodle_config {
          id
          moodle_sp_name
        }
        course_category {
          id
          name
        }
        partners: certification_partners {
          partner {
            id
            name
            logo
          }
        }
        certificate_image_url
        certificate_template_id
        cost
        course_type
        discount
        duration
        image_url
        is_live_course
        is_subscription
        is_taxable
        live_class_duration
        nsqf_level
        short_name
        video_url
        moodle_course_id
        discontinued_from_moodle_at
      }
    }
  }
`;

export const GET_COURSES_WITH_FILTER = gql`
  query ($courseSearchExp: courses_course_bool_exp!) {
    courses_course(where: $courseSearchExp) {
      id
      full_name
    }
  }
`;

export const GET_BATCH_ENROLLMENTS_WITH_FITLER = gql`
  query ($batchEnrollmentSearchExp: courses_user_batch_enrollment_bool_exp!) {
    courses_user_batch_enrollment(where: $batchEnrollmentSearchExp) {
      id
      user_id
      batch_id
      batch {
        course_id
        institute {
          id
          name
          district
        }
      }
      course_enrolment {
        course_complete_obj {
          completed_on
        }
      }
      user {
        gender
        user_academic_qualifications(order_by: { qualification_id: desc }) {
          qualification_id
        }
      }
    }
  }
`;

export const GET_TRANSLATIONS = gql`
  query getTranslations($key_array: [String!]!) {
    courses_multi_lang_contents(where: { key: { _in: $key_array } }) {
      id
      key
      content
    }
  }
`;

export const GET_SCORM_QUIZ_ATTEMPT_WITH_FILTER = gql`
  query (
    $scormQuizAttemptSearchExp: courses_course_scorm_quiz_attempt_data_bool_exp!
  ) {
    courses_course_scorm_quiz_attempt_data(where: $scormQuizAttemptSearchExp) {
      id
      user_id
      attempt_id
      question_text
      user_response
      correct_answers
      question_id
      result
      created_at
      updated_at
    }
  }
`;
