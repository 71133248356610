import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import ProjectsTable from "./components/ProjectsTable";
import { GET_PROJECTS } from "../../../GraphQl/Queries/Projects";
import {
  CREATE_PROJECT,
  UPDATE_PROJECT,
  UPDATE_PROJECT_CORUSES,
} from "../../../GraphQl/Mutations/Projects";
import { useUserPartnerAccessDetails } from "../../../Hooks/UserAcccess";
import { errorHandler, successHandler } from "utils/toast";
import { Form } from "skillstrainer-resource-library";
import ProjectFormBuilder from "formBuilders/ProjectFormBuilder";

function ManageProjects() {
  const allProjects = useQuery(GET_PROJECTS);

  const [isUpdate, setIsUpdate] = useState(false);
  const partnerAccess = useUserPartnerAccessDetails();
  const [insertProject] = useMutation(CREATE_PROJECT, {
    refetchQueries: [GET_PROJECTS],
  });
  const [updateProject] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [GET_PROJECTS],
  });
  const [updateProjectCourses] = useMutation(UPDATE_PROJECT_CORUSES, {
    refetchQueries: [GET_PROJECTS],
  });
  const [projectState, setProjectState] = useState({});
  const [formKey, setFormKey] = useState(false);

  const createProject = async (project) => {
    const insertProjectObject = {
      ...project,
      city: project.address?.city_town,
      country: project.address?.country,
      house_number: project.address?.house_number,
      location: project.address?.location,
      state: project.address?.state,
      pincode: project.address?.pincode,
      address: undefined,
    };
    insertProjectObject.project_courses = (project.courses || []).map(
      (course_id) => {
        return {
          course_id,
          partner_id: project.partner_id,
        };
      }
    );
    insertProject({ variables: insertProjectObject })
      .then(() => {
        successHandler("New Project Created Successfully");
        setFormKey(!formKey);
      })
      .catch((err) => {
        console.log(err);
        errorHandler("There was an error while creating project");
      });
  };

  const update_Project = async (project) => {
    const updateProjectObject = {
      id: projectState.id,
      ...project,
      city: project.address?.city_town,
      location: project.address?.location,
      house_number: project.address?.house_number,
      country: project.address?.country,
      state: project.address?.state,
      pincode: project.address?.pincode,
      address: undefined,
      courses: undefined,
    };

    updateProject({
      variables: {
        id: { _eq: updateProjectObject.id },
        _set: updateProjectObject,
      },
    })
      .then(() => {
        successHandler("Project Updated Successfully");
      })
      .catch((err) => {
        console.log(err);
        errorHandler("There was an error while updating course");
      });

    const updateProjectCoursesObject = (project.courses || []).map(
      (course_id) => {
        return {
          course_id,
          partner_id: project.partner_id,
          project_id: projectState.id,
        };
      }
    );

    updateProjectCourses({
      variables: {
        project_id: projectState.id,
        objects: updateProjectCoursesObject,
      },
    })
      .then(() => {
        setIsUpdate(false);
        setProjectState({});
        setFormKey(!formKey);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSelectedProject = (selectedProject) => {
    setIsUpdate(true);
    console.log("selectedProject===", selectedProject);
    setProjectState(() => {
      const courseObj = {
        ...selectedProject,
        courses: selectedProject.project_courses.map((projectCourse) => {
          return projectCourse.course_id;
        }),
        address: {
          country: selectedProject.country,
          house_number: selectedProject.house_number,
          location: selectedProject.location,
          city_town: selectedProject.city,
          state: selectedProject.state,
          pincode: selectedProject.pincode,
        },
      };
      setFormKey(!formKey);
      return courseObj;
    });
  };

  const filterProjects = (data) => {
    if (partnerAccess.allPartnerAccess === true) {
      return data;
    } else if (partnerAccess.primaryPartnerId) {
      const partnerIds = partnerAccess.partnerIds;
      partnerIds.push(partnerAccess.primaryPartnerId);
      if (data) {
        return data.filter(
          ({ partner_id }) => partnerIds.indexOf(partner_id) > -1
        );
      }
    }
    return [];
  };

  return (
    <div>
      <h2 className="text-3xl font-bold px-4 ml-6 py-4">Manage Projects</h2>
      <div className="px-4 m-3">
        <div className="m-3">
          <h5>{isUpdate ? "Update project" : "Create Project"}</h5>
          <Form
            key={formKey}
            formBuilder={ProjectFormBuilder}
            className="  mt-2 mb-12 grid grid-cols-2 gap-x-2 "
            submitButton={{
              text: "Save",
              className:
                "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
            }}
            onSubmit={
              isUpdate
                ? (project) => update_Project(project)
                : (project) => createProject(project)
            }
            initValues={projectState}
          />
        </div>
      </div>
      {allProjects.data ? (
        <ProjectsTable
          allProjects={filterProjects(
            allProjects?.data?.courses_partner_projects
          )}
          updateSelectedProject={(selectedProject) => {
            updateSelectedProject(selectedProject);
          }}
        />
      ) : (
        <>
          <p>Please wait, fetching projects...</p>
        </>
      )}
    </div>
  );
}

export default ManageProjects;
