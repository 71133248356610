//TO DO: Filter on the basis of projects
//TO DO: Number of applicants in ScholarshipTable
//TO DO: Edit scholarship ( refetch query for view) Update API done
//TO DO: 2 modals opening together fix

import React, { useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import { GET_SCHOLARSHIPS_BY_PARTNER } from "GraphQl/Queries/Scholarships";
import moment from "moment";
import { FiEdit } from "react-icons/fi";
import { CSVLink } from "react-csv";
import { DATE_FORMATS, SOCIAL_CATEGORY_NAMES } from "api/Consts";
import { client } from "App";
import { GET_APPLICANTS_BY_SCHOLARSHIP_ID } from "GraphQl/Queries/Scholarships";
import DownloadAllScholarshipData from "../AllScholarshipData";

function ScholarshipsTable(props) {
  const [getScholarships, searchedScholarships] = useLazyQuery(
    GET_SCHOLARSHIPS_BY_PARTNER
  );

  const handleEditButtonClick = (e, row) => {
    props.editPartners(row);
    e.preventDefault();
  };
  const handleViewApplicants = (e, row) => {
    props.viewApplicants(row);
    e.preventDefault();
  };

  const columns = [
    {
      cell: (row) => (
        <button
          id="editButton"
          className="p-2"
          onClick={(e) => handleEditButtonClick(e, row)}
        >
          <FiEdit />
        </button>
      ),
      width: "3rem",
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
    },
    {
      name: "Scholarship Name",
      width: "19rem",
      selector: (row, index) => `${row.title}`,
      sortable: true,
    },
    {
      name: "Date of Creation",
      width: "10rem",
      selector: (row, index) =>
        moment(row.created_at).format("YYYY-MM-DD, hh:mm"),
      sortable: true,
    },
    {
      name: "Amount",
      width: "7rem",
      selector: (row, index) => `${row.amount}`,
      sortable: true,
    },
    {
      name: "Number of positions",
      width: "11rem",
      selector: (row, index) => `${row.no_of_positions}`,
      sortable: true,
    },
    {
      name: "Number of applicants",
      width: "12rem",
      selector: (row, index) => `${row.no_of_positions}`,
      sortable: true,
    },
    {
      name: "Active",
      width: "6rem",
      selector: (row, index) => {
        return row.is_active ? (
          <span className="text-green-500 font-semibold">Yes</span>
        ) : (
          <span className="text-red-500 font-semibold">No</span>
        );
      },
      sortable: true,
    },

    {
      cell: (row) => (
        <div className="flex space-x-2 p-2">
          <button
            id="viewButton"
            className="secondary-cta whitespace-normal break-normal"
            onClick={(e) => handleViewApplicants(e, row)}
          >
            View Applicants
          </button>
          <DownloadAllScholarshipData
            className={"secondary-cta whitespace-normal break-normal"}
            buttonText={"Download Applicants CSV"}
            params={{
              scholarship_id: { _eq: row.id },
              accept: { _eq: true },
            }}
            loaderColor={"#000"}
          />
          {/* <button
            id="viewButton"
            className="secondary-cta whitespace-normal break-normal"
            onClick={() =>
              getScholarshipApplicantsData(row.id).then((applicants) =>
                setScholarshipApplicantsData({
                  scholarshipTitle: row.title,
                  applicants,
                })
              )
            }
          >
            Download Applicants CSV
          </button> */}
        </div>
      ),
      width: "10rem",
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
    },
  ];

  const scholarshipApplicantsDownloadButton = useRef();

  const [scholarshipApplicantsData, setScholarshipApplicantsData] = useState();
  useEffect(() => {
    if (scholarshipApplicantsData && !scholarshipApplicantsData.downloaded) {
      scholarshipApplicantsDownloadButton.current.link.click();
      scholarshipApplicantsData.downloaded = true;
      setScholarshipApplicantsData({ ...scholarshipApplicantsData });
    }
  }, [scholarshipApplicantsData]);

  const getScholarshipApplicantsData = async (scholarshipId) => {
    return client
      .query({
        query: GET_APPLICANTS_BY_SCHOLARSHIP_ID,
        variables: {
          _scholarshipid: scholarshipId,
        },
      })
      .then(({ data }) =>
        data.courses_scholarship_partner_user.map(({ user, accept, id }) => ({
          name: user.name,
          email: user.email,
          gender: user.gender,
          mobile_number: user.mobile_number,
          social_category: SOCIAL_CATEGORY_NAMES[user.social_category],
          application_id: id,
          accept,
          family_income:
            user.user_family_details_aggregate.aggregate.sum.annual_income,
        }))
      );
  };

  useEffect(() => {
    getScholarships({
      variables: { _partner_id: props.searchObj.partner_id },
    });
  }, [props.searchObj]);

  return (
    <div>
      <div className="my-4">
        {searchedScholarships.data?.courses_scholarships && (
          <CSVLink
            filename="All scholarships"
            data={searchedScholarships.data?.courses_scholarships}
            headers={[
              { key: "id", label: "ID" },
              { key: "title", label: "Title" },
              { key: "description", label: "Description" },
              { key: "no_of_positions", label: "No of positions" },
              { key: "created_at", label: "Created At" },
              { key: "amount", label: "Amount" },
              { key: "gender", label: "Gender" },
              { key: "max_age", label: "Maximum Age" },
              { key: "min_age", label: "Minimum Age" },
              {
                key: "min_edu_qualification",
                label: "Minimum educational qualification",
              },
              { key: "social_categories", label: "Social categories" },
              { key: "is_active", label: "Is Active" },
            ]}
          >
            <button className="btn-primary">Download CSV</button>
          </CSVLink>
        )}
        <DownloadAllScholarshipData
          className={"mx-4 btn-secondary"}
          partnerId={props.searchObj?.partner_id}
          buttonText={"Download All Scholarship Data"}
          params={{
            partner_id: { _eq: props.searchObj?.partner_id },
            accept: { _eq: true },
          }}
        />
        <div className="flex-row w-full">
          <div>
            <DataTable
              columns={columns}
              data={searchedScholarships.data?.courses_scholarships}
              pagination
              persistTableHead
            />
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <CSVLink
          data={scholarshipApplicantsData?.applicants || []}
          filename={scholarshipApplicantsData?.scholarshipTitle + " Applicants"}
          headers={[
            { key: "application_id", label: "Application ID" },
            { key: "name", label: "Name" },
            { key: "email", label: "Email" },
            { key: "mobile_number", label: "Mobile number" },
            { key: "accept", label: "Is Accepted" },
            { key: "gender", label: "Gender" },
            { key: "social_category", label: "Social category" },
            { key: "family_income", label: "Family income" },
          ]}
          ref={scholarshipApplicantsDownloadButton}
        >
          <button className="btn-primary">Download Applicants CSV</button>
        </CSVLink>
      </div>
    </div>
  );
}

export default ScholarshipsTable;