import _ from "lodash";

// Conditional Object: returns the object if condition is true, else an empty object (or falseObject)
export const condObj = (condition, trueObject, falseObject = {}) =>
  condition ? trueObject : falseObject;

// Returns an empty string for falsy values
export const _es = (s) => s || "";

// Returns that value if not a function, else returns the output of the function
export const resolveValue = (val) => {
  if (typeof val === "function") return val();
  else return val;
};

// extracts value from input event
export const wireEventValue = (func) => (e) => func(e.target.value);

// updates the values of the first object with the values of the second object
export const updateObject = (target, ref) => {
  Object.keys(ref).map((refKey) => {
    target[refKey] = ref[refKey];
  });
};

export const arrayDelta = (oldArr, newArr, idKeyPaths = ["id"]) => {
  // Utilities Start

  const genKeyString = (item, keyPaths) =>
    keyPaths.map((keyPath) => _.get(item, keyPath) || "").join(".");

  const isMatched = (e, f, keyPaths) => {
    const eKeyString = genKeyString(e, keyPaths);
    const fKeyString = genKeyString(f, keyPaths);
    return eKeyString === fKeyString;
  };

  // Utilities End

  const created = newArr.filter((e) => {
    if (!idKeyPaths) return oldArr.indexOf(e) === -1;
    else return !oldArr.find((f) => isMatched(e, f, idKeyPaths));
  });
  const deleted = oldArr.filter((e) => {
    if (!idKeyPaths) return newArr.indexOf(e) === -1;
    else return !newArr.find((f) => isMatched(e, f, idKeyPaths));
  });
  const persistant = newArr.filter((e) => {
    if (!idKeyPaths) return newArr.indexOf(e) > -1;
    else return oldArr.find((f) => isMatched(e, f, idKeyPaths));
  });

  return { created, deleted, persistant };
};

export const changeNameOnDownload = async (url, filename) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
};

export const convertTimeStamp = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    timeZone: "Asia/Kolkata",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const formatter = new Intl.DateTimeFormat("en-IN", options);
  const formattedDate = formatter.format(date);

  return formattedDate;
};

export function downloadCsv(csvString, fileName) {
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
