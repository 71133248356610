import { gql } from "@apollo/client";

export const CREATE_JOB = gql`
  mutation createJob($objects: [courses_skillstrainer_jobs_insert_input!]!) {
    insert_courses_skillstrainer_jobs(
      objects: $objects
      on_conflict: {
        constraint: skillstrainer_jobs_pkey
        update_columns: [
          partner_id
          is_active
          sector_id
          title
          no_of_positions
          family_income
          is_remote
          shift_end_timing
          shift_start_timing
          salary_ctc
          description
          min_age
          max_age
          min_experience
          max_experience
          marital_status
          project_id
          social_categories
          associated_job_role_id
          gender
          otherpartner_id
        ]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_SKILLS = gql`
  mutation insertSkills($name: String, $label: String) {
    insert_courses_skillstrainer_skills_one(
      object: { name: $name, label: $label }
    ) {
      id
      name
    }
  }
`;

export const CREATE_QUALIFICATIONS = gql`
  mutation insertQualification(
    $name: String
    $type: String
    $sector_id: bigint
    $nsqf_level: Int
    $qp_code: String
    $code: String
  ) {
    insert_courses_qualifications_lookup_one(
      object: {
        name: $name
        type: $type
        sector_id: $sector_id
        nsqf_level: $nsqf_level
        qp_code: $qp_code
        code: $code
      }
    ) {
      id
      name
    }
  }
`;

export const DELETE_JOB = gql`
  mutation deleteJob($job_id: uuid) {
    delete_courses_skillstrainer_jobs(where: { id: { _eq: $job_id } }) {
      affected_rows
    }
  }
`;

export const INSERT_JOB_LOCATIONS_SKILLS_LANGUAGES_QUALIFICATIONS_DOCUMENTS = gql`
  mutation insertJobLocationsSkillsLanguagesQualifications(
    $locations: [courses_job_location_insert_input!]!
    $skills: [courses_job_required_skills_criteria_insert_input!]!
    $languages: [courses_job_languages_required_insert_input!]!
    $qualifications: [courses_job_qualifications_insert_input!]!
    $documents: [courses_job_documents_insert_input!]!
  ) {
    insert_courses_job_location(objects: $locations) {
      affected_rows
    }

    insert_courses_job_required_skills_criteria(objects: $skills) {
      affected_rows
    }

    insert_courses_job_languages_required(objects: $languages) {
      affected_rows
    }

    insert_courses_job_qualifications(objects: $qualifications) {
      affected_rows
    }

    insert_courses_job_documents(objects: $documents) {
      affected_rows
    }
  }
`;

export const UPSERT_JOB_LOCATIONS_SKILLS_LANGUAGES_QUALIFICATIONS_DOCUMENTS = gql`
  mutation upsertJobLocationsSkillsLanguagesQualifications(
    $jobData: [courses_skillstrainer_jobs_insert_input!]!
    $locations: [courses_job_location_insert_input!]!
    $skills: [courses_job_required_skills_criteria_insert_input!]!
    $languages: [courses_job_languages_required_insert_input!]!
    $qualifications: [courses_job_qualifications_insert_input!]!
    $documents: [courses_job_documents_insert_input!]!
  ) {
    insert_courses_skillstrainer_jobs(
      objects: $jobData
      on_conflict: {
        constraint: skillstrainer_jobs_pkey
        update_columns: [
          partner_id
          associated_job_role_id
          family_income
          sector_id
          title
          no_of_positions
          is_active
          is_remote
          shift_end_timing
          shift_start_timing
          salary_ctc
          description
          social_categories
          min_experience
          max_experience
          min_age
          max_age
          marital_status
          gender
          project_id
          otherpartner_id
        ]
      }
    ) {
      returning {
        id
      }
    }

    insert_courses_job_location(
      objects: $locations
      on_conflict: {
        constraint: job_location_pkey
        update_columns: [num_of_positions, city, country, pin_code, district]
      }
    ) {
      returning {
        id
        job_id
        num_of_positions
        pin_code
        city
        district
        country
      }
    }

    insert_courses_job_required_skills_criteria(
      objects: $skills
      on_conflict: {
        constraint: job_required_skills_criteria_pkey
        update_columns: [st_skill_id, required]
      }
    ) {
      returning {
        id
        job_id
        st_skill_id
        required
        skillstrainer_skill_id {
          name
          label
        }
      }
    }

    insert_courses_job_languages_required(
      objects: $languages
      on_conflict: {
        constraint: job_languages_required_pkey
        update_columns: [language_id, can_read, can_speak, can_write, required]
      }
    ) {
      returning {
        id
        language {
          name
        }
        can_speak
        can_read
        can_write
        required
      }
    }

    insert_courses_job_qualifications(
      objects: $qualifications
      on_conflict: {
        constraint: job_qualifications_pkey
        update_columns: [qualification_type, required]
      }
    ) {
      returning {
        id
        job_id
        qualification_id
        required
        qualification {
          type
        }
      }
    }

    insert_courses_job_documents(
      objects: $documents
      on_conflict: {
        constraint: job_documents_pkey
        update_columns: [document_id, required]
      }
    ) {
      returning {
        id
        document_id
        required
        document {
          name
          id
        }
      }
    }
  }
`;

export const DELETE_JOB_LOCATIONS_QUALIFICATIONS_LANGUAGES_SKILLS_DOCUMENTS = gql`
  mutation deleteJobLocationsQualificationsLanguagesSkills(
    $locationIds: [uuid!]!
    $languageIds: [uuid!]!
    $qualificationIds: [uuid!]!
    $skillsIds: [uuid!]!
    $documentsIds: [uuid!]!
  ) {
    delete_courses_job_location(where: { id: { _in: $locationIds } }) {
      affected_rows
    }

    delete_courses_job_languages_required(
      where: { id: { _in: $languageIds } }
    ) {
      affected_rows
    }

    delete_courses_job_qualifications(
      where: { id: { _in: $qualificationIds } }
    ) {
      affected_rows
    }

    delete_courses_job_required_skills_criteria(
      where: { id: { _in: $skillsIds } }
    ) {
      affected_rows
    }

    delete_courses_job_documents(where: { id: { _in: $documentsIds } }) {
      affected_rows
    }
  }
`;

export const UPDATE_SKILL_MUTATION = gql`
  mutation update_course_skillstrainer_skills(
    $id: bigint
    $name: String
    $label: String
  ) {
    update_courses_skillstrainer_skills(
      where: { id: { _eq: $id } }
      _set: { name: $name, label: $label }
    ) {
      returning {
        name
        label
      }
    }
  }
`;

export const UPDATE_JOB_APPLICATION_SCORE_AND_NOTES = gql`
  mutation upsertJobApplicationNotesAndScore(
    $jobId: uuid
    $userId: bigint
    $score: numeric
    $notes: jsonb
  ) {
    update_courses_job_applications(
      where: {
        _and: [{ job_id: { _eq: $jobId } }, { user_id: { _eq: $userId } }]
      }
      _set: { score: $score, notes: $notes }
    ) {
      returning {
        score
        notes
        id
        job_id
        user_id
      }
    }
  }
`;

export const UPDATE_QUALIFICATION = gql`
  mutation update_qualifications_lookup(
    $id: Int
    $name: String
    $type: String
    $sector_id: bigint
    $nsqf_level: Int
    $qp_code: String
    $code: String
  ) {
    update_courses_qualifications_lookup(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        type: $type
        sector_id: $sector_id
        nsqf_level: $nsqf_level
        qp_code: $qp_code
      }
    ) {
      returning {
        id
        name
        type
      }
    }
  }
`;
export const UPDATE_JOB_STATUS = gql`
  mutation updateJobStatus($jobId: uuid, $userId: bigint, $status: String) {
    update_courses_job_applications(
      where: {
        _and: [{ job_id: { _eq: $jobId } }, { user_id: { _eq: $userId } }]
      }
      _set: { status: $status }
    ) {
      returning {
        id
        status
      }
    }
  }
`;

export const UPDATE_JOB_STAGE = gql`
  mutation updateJobStage(
    $jobId: uuid
    $userId: bigint
    $stage: Int
    $status: String
  ) {
    update_courses_job_applications(
      where: {
        _and: [{ job_id: { _eq: $jobId } }, { user_id: { _eq: $userId } }]
      }
      _set: { stage: $stage, status: $status }
    ) {
      returning {
        id
        stage
      }
    }
  }
`;
