import React from "react";
import Routes from "./Routes/Routes";

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { graphqlUri } from "./api/Consts";
import { getJwtToken } from "./utils/Auth";

import { RecoilRoot } from "recoil";
import AuthContext from "Contexts/AuthContext";
import ResourceContext from "Contexts/ResourceContext";
import NavigationContext from "Contexts/NavigationContext";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastProvider } from "wrappers/Toast";

import { ToastContainer } from "react-toastify";

const httpLink = createHttpLink({
  uri: graphqlUri,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getJwtToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: authLink.concat(httpLink),
});

const App = () => {
  return (
    <RecoilRoot>
      <ApolloProvider client={client}>
        <ToastProvider>
          <Router>
            <AuthContext />
            <ResourceContext />
            <NavigationContext />
            <Routes />
          </Router>
        </ToastProvider>
        <ToastContainer />
      </ApolloProvider>
    </RecoilRoot>
  );
};

export default App;
