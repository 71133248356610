const { atom } = require("recoil");

export const currentModuleRecoilVar = atom({
  key: "currentModuleRecoilVar",
  default: null,
});

export const currentModuleAccessRecoilVar = atom({
  key: "currentModuleAccessRecoilVar",
  default: null,
});

export const currentModulePathRecoilVar = atom({
  key: "currentModulePathRecoilVar",
  default: [],
});
