import React, { useEffect, useState } from "react";
import { useQuery, useMutation, empty } from "@apollo/client";

import courseBuilderService from "services/coursebuild/course";

import { useLocation } from "react-router";
import queryString from "query-string";
import TopicListTable from "./TopicListTable";
import { useHistory } from "react-router-dom";
import { GET_COURSE_COMPLETION_MODULE } from "GraphQl/Queries/CourseBuilder/section";
import { successHandler, errorHandler } from "utils/toast";
import { DELETE_COURSE_COMPLETION_CRITERIA } from "GraphQl/Mutations/coursebuildemutation/cousresection";
import { INSERT_COURSE_COMPLETION_TOPICS } from "GraphQl/Mutations/coursebuildemutation/cousresection";
import Modal from "react-bootstrap/Modal";
import { UPDATE_COURSE_COMPLETION_DESCRIPTION } from "GraphQl/Mutations/coursebuildemutation/CourseModule";
import { GET_COURSE_COMPLETION_DESC } from "GraphQl/Queries/CourseBuilder/Course";
import { Form } from "skillstrainer-resource-library";
import CourseCompletionForm from "formBuilders/CourseCompletionForm";


export default function CourseCompletionSetting() {
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [topics, setTopics] = useState([]);

  const [selectedTopics, setSelectedTopics] = useState([]);
  const [preSelectedRows, setPreSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState([]);

  const {   
    data: moduleLists,
  } = useQuery(GET_COURSE_COMPLETION_MODULE, {
    variables: { course_id: params.id },
    fetchPolicy: "network-only",
  });

  const {   
    data: completionDesc,
  } = useQuery(GET_COURSE_COMPLETION_DESC, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  });

  //Add lessons
  const [updateCompletionDesc] = useMutation(
    UPDATE_COURSE_COMPLETION_DESCRIPTION,
    {
      refetchQueries: () => [
        {
          query: GET_COURSE_COMPLETION_DESC,
          variables: {
            id: params.id,
          },
        },
      ],
      onCompleted: (data) => {
        successHandler("Completion Description Added Successfully!");
        setShowModal(!showModal);
      },
      onError: () => errorHandler("An error occurred"),
    }
  );

  const [deleteCompletionCriteria] = useMutation(
    DELETE_COURSE_COMPLETION_CRITERIA,
    {
      refetchQueries: () => [
        {
          query: GET_COURSE_COMPLETION_MODULE,
          variables: {
            course_id: params.id,
          },
          fetchPolicy: "network-only",
        },
      ],
      onCompleted: () => successHandler("Success!"),
      onError: () => errorHandler("An error occurred"),
    }
  );

  const [InsertCourseComletionTopics] = useMutation(
    INSERT_COURSE_COMPLETION_TOPICS,
    {
      refetchQueries: () => [
        {
          query: GET_COURSE_COMPLETION_MODULE,
          variables: {
            course_id: params.id,
          },
          fetchPolicy: "network-only",
        },
      ],
      onCompleted: () => successHandler("Update Successfully!"),
      onError: () => errorHandler("An error occurred"),
    }
  );

  useEffect(() => {
    console.log("moduleLists", moduleLists);

    if (moduleLists) {
      setPreSelectedRows(moduleLists.courses_course_completion_criteria);
    }
  }, [moduleLists]);

  useEffect(() => {
    if (params.id) {
      getCourseSectionList();
    }
  }, [params]);

  const getCourseSectionList = async () => {
    const res = await courseBuilderService.getCourseTopicLists(params.id);
    setTopics(res);
  };

  const updateCourseSelection = (topicids, checked) => {
    setSelectedTopics(topicids);
  };

  const handleSubmit = async () => {
    const preSelectedRowsFilter =
      preSelectedRows && preSelectedRows.map((r) => r.mapping_id);

    // Topics that have been added
    const removeExistingRows = selectedTopics.filter(function (obj) {
      return preSelectedRowsFilter.indexOf(obj) == -1;
    });

    console.log("removeExistingRows", removeExistingRows);

    // Topics that have been deleted
    const previousUnselect = preSelectedRowsFilter.filter(function (n) {
      return !this.has(n);
    }, new Set(selectedTopics));

    console.log("previousUnselect", previousUnselect);

    /*****Insert Query ***/

    removeExistingRows &&
      removeExistingRows.map(async (mapping_id) => {
        InsertCourseComletionTopics({
          variables: {
            course_id: params.id,
            mapping_id: mapping_id,
          },
        });
      });

    /*******Delete Unselect previous selected mapping */
    previousUnselect &&
      previousUnselect.map((mapping_id) => {
        deleteCompletionCriteria({
          variables: {
            mapping_id: mapping_id,
          },
        });
      });
  };

  const updateValue = (e) => {
    const key = e.target.getAttribute("data-key");
    const value = e.target.value;

    setDescription({ ...description, [key]: value });
  };

  // Add completion setting desc
  const submitForm = (data) => {   
    updateCompletionDesc({
      variables: {
        course_completion_desc: data?.course_completion_desc,
        id: params.id,
      },
    });
  };

  useEffect(() => {
    setDescription({
      ...description,
      ["course_completion_desc"]:
        completionDesc?.courses_course[0]?.course_completion_desc,
    });
  }, [completionDesc]);

 

  return (
    <div>
      <Modal
        size="lg"
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Course Completion Instruction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form
        formBuilder={CourseCompletionForm}
        className=" ml-6 mt-2 h-100"
        submitButton={{
          text: "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
        }}
        
        onSubmit={submitForm}
        key={params.id?.id}
        initValues={completionDesc?.courses_course[0] || {}}
      />
          
        </Modal.Body>
      </Modal>

      <div className="flex justify-between mt-3">
        <button
          className="inline-flex justify-center py-2 px-4 
		border border-transparent shadow-sm text-sm font-medium 
		rounded-md text-white bg-orange"
          onClick={() => history.goBack()}
        >
          Go To Back
        </button>

        <button
          className="inline-flex justify-center py-2 px-4 
		border border-transparent shadow-sm text-sm font-medium 
		rounded-md text-white bg-orange"
          onClick={() => setShowModal(!showModal)}
        >
          {`${
            description?.course_completion_desc ? "Update" : "Add"
          } Completion Setting Instruction`}
        </button>
      </div>

      <h2 className="text-2xt font-bold px-4 text-center">
        Course Completion Topics
      </h2>

      <TopicListTable
        topics={topics}
        selectedTopics={selectedTopics}
        preSelectedRows={preSelectedRows}
        updateCourseSelection={(topicids, checked) => {
          updateCourseSelection(topicids, checked);
        }}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}
