import { useLazyQuery } from "@apollo/client";
import { GET_ALL_APPLICANTS } from "GraphQl/Queries/Scholarships";
import { downloadCsv } from "utils/func";
import arrayToCsv from "utils/arraytocsv";
import { useState } from "react";
import Loader from "assets/animated-images/loader";

const DownloadAllScholarshipData = (props) => {
  const { className, buttonText, params, loaderColor = "#FFF" } = props;

  const [loaderState, setLoaderState] = useState(false);
  const [getAllPartnerScholarshipData] = useLazyQuery(GET_ALL_APPLICANTS);

  function formatHeaders(header) {
    if (header === "location") return "Address";

    const words = header.split("_");
    const formattedHeader = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedHeader;
  }

  const downloadAllScholarshipData = async () => {
    const allScholarshipData = await getAllPartnerScholarshipData({
      variables: {
        where: params,
      },
    }).then((res) => {
      const data = res.data?.courses_scholarship_partner_user || [];

      const result = {};
      data?.forEach((item) => {
        const scholarshipId = item.scholarship_id;
        const userId = item.user_id;

        if (!result[scholarshipId]) {
          result[scholarshipId] = {};
        }
        if (!result[scholarshipId][userId]) {
          result[scholarshipId][userId] = [];
        }
        result[scholarshipId][userId].push(item);
      });

      return result;
    });

    for (let key in allScholarshipData) {
      let scholarshipCsv = [];
      let scholarshipName = "SCHOLARSIHP";

      for (let user in allScholarshipData[key]) {
        let contribution = [];
        let firstEnt = allScholarshipData[key][user][0];
        scholarshipName = firstEnt.scholarship.title;

        let userFamilyDetails = {};
        let userAddress = {
          city_town: firstEnt.user.address?.city_town,
          district: firstEnt.user.address?.district,
          pincode: firstEnt.user.address?.pincode,
          location: firstEnt.user.address?.location,
        };

        firstEnt.user.user_family_details.map((fam) => {
          if (fam?.member_type === "0") {
            userFamilyDetails.father_name = fam?.name;
            userFamilyDetails.father_occupation = fam?.occupation;
            userFamilyDetails.father_mobile_number = fam?.mobile_number;
          } else if (fam?.member_type === "1") {
            userFamilyDetails.mother_name = fam?.name;
            userFamilyDetails.mother_occupation = fam?.occupation;
            userFamilyDetails.mother_mobile_number = fam?.mobile_number;
          }
        });

        for (let userEntry in allScholarshipData[key][user]) {
          contribution.push({
            contribution: allScholarshipData[key][user][userEntry].contribution,
            created_at: allScholarshipData[key][user][userEntry].created_at,
          });
        }

        // let contribution_obj = { "2022-23": null, "2023-24": null };
        // contribution.map((cont) => {
        //   if (cont.created_at.includes("2022"))
        //     contribution_obj["2022-23"] = cont.contribution;
        //   else if (cont.created_at.includes("2023"))
        //     contribution_obj["2023-24"] = cont.contribution;
        // });

        scholarshipCsv.push({
          name: firstEnt.user.name,
          mobile_number: firstEnt.user.mobile_number,
          email: firstEnt.user.email,
          gender: firstEnt.user.gender,
          ...userFamilyDetails,
          ...userAddress,
          scholarship_name: firstEnt.scholarship.title,
          institution_name:
            firstEnt.user.user_academic_qualifications.length === 0
              ? null
              : firstEnt.user.user_academic_qualifications[0]
                  .institution_name || null,
          qualification:
            firstEnt.user?.user_academic_qualifications.length === 0
              ? null
              : firstEnt.user?.user_academic_qualifications[0]
                  ?.qualification_name || null,
          // ...contribution_obj,
        });
      }
      const csvData = scholarshipCsv || [];
      const csv = arrayToCsv(csvData, formatHeaders);
      downloadCsv(csv, "DATA-" + scholarshipName);
    }
    setLoaderState(false);
  };

  return (
    <button
      className={className}
      onClick={() => {
        setLoaderState(true);
        downloadAllScholarshipData();
      }}
    >
      {loaderState ? (
        <Loader color={loaderColor} className={"h-4 w-16"} />
      ) : (
        buttonText
      )}
    </button>
  );
};

export default DownloadAllScholarshipData;
