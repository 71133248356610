import React, { useEffect, useState } from "react";

export default function MediaPreview(props) {
  const { data } = props;

  return (
    <iframe
      src={data}
      style={{ height: "calc(100vh - 15rem)", width: "80vw" }}
    />
  );
}
