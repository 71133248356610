import { gql } from "@apollo/client";
export const GET_COURSE_CERTIFICATE_TEMPLATES = gql`
  query GetCertificateTemplates {
    courses_course_certificate_templates {
      certificate_name
      certificate_url
      director_name
      id
      partnerlogo1
      partnerlogo2
      partnerlogo3
    }
  }
`;

export const GET_COURSE_CERTIFICATE_TEMPLATES_BY_ID = gql`
  query GetCertificateTemplatesByID($id: Int) {
    courses_course_certificate_templates(where: { id: { _eq: $id } }) {
      certificate_name
      certificate_url
      director_name
      id
      partnerlogo1
      partnerlogo2
      partnerlogo3
    }
  }
`;
export const GET_COURSE_PARTNER_PROJECT_COURSES = gql`
  query getcourseprojectPartner($course_id: bigint!) {
    courses_partner_project_courses(where: { course_id: { _eq: $course_id } }) {
      id
      partner_id
      project_id
      partner {
        id
        name
        organization_name
        logo
        active
        about_partner
      }
    }
  }
`;
export const GET_CERTIFICATES_DETAILS = gql`
  query getcertificateDetails($course_id: Int!) {
    courses_course_module_mapping(where: { course_id: { _eq: $course_id } }) {
      id
      mod_id
      name
      description
      completion_percentage
      attemtsgrade
      completion_criteria
      course_module {
        full_name
        duration
        description
        image_url
        nsqf_level
        created_at
        updated_at
      }
    }
  }
`;

export const GET_CERTIFICATE_TEMPLATE_COURSE_PARTNER_RELATION = gql`
  query getCertificateTemplateCoursePartnerRelation {
    courses_certificate_template_course_partner_relation {
      id
      course_id
      certificate_template_id
      partner_id
    }
  }
`;
