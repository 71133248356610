import { gql } from "@apollo/client";

export const UPSERT_PARTNER_ACCESS = gql`
  mutation upsertPartnerAccess(
    $partnerAccessObjects: [courses_admin_user_partner_access_insert_input!]!
  ) {
    insert_courses_admin_user_partner_access(
      objects: $partnerAccessObjects
      on_conflict: {
        constraint: admin_user_partner_access_admin_user_id_partner_id_role_id_key
        update_columns: []
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_PARTNER_ACCESS = gql`
  mutation deletePartnerAccess($partnerAccessIds: [uuid!]!) {
    delete_courses_admin_user_partner_access(
      where: { id: { _in: $partnerAccessIds } }
    ) {
      affected_rows
    }
  }
`;
