import { useState, useEffect } from "react";
import * as yup from "yup";
import { GET_PARTNER_AND_INSTRUCTOR } from "GraphQl/Queries/User";
import { useQuery } from "@apollo/client";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";

function UserFormBuilder(values) {
  const { error, loading, data } = useQuery(GET_PARTNER_AND_INSTRUCTOR);
  const [schema, setSchema] = useState({});
  const partnerAccess = useUserPartnerAccessDetails();

  const filterPartner = (partners) => {
    if (partners) {
      if (partnerAccess && partnerAccess.allPartnerAccess) {
        return partners;
      } else if (partnerAccess.partnerIds) {
        const filteredPartners = partners.filter(
          ({ id }) => partnerAccess.partnerIds.indexOf(id) > -1
        );
        return filteredPartners;
      }
    }
    return [];
  };

  const [selectedPartner, setSelectedPartner] = useState();

  //Effect to see which partner is selected and get that partner's projects
  useEffect(() => {
    const partnerSelect = (data?.courses_partner || []).find((partner) => {
      if (partner.id == values.partner_id) return partner;
    });
    setSelectedPartner(partnerSelect);
  }, [values.partner_id]);

  useEffect(() => {
    const schema = {
      name: {
        label: "Name",
        type: "text",
        schema: yup.string().min(6, "Name must have atleast 5 characters"),
      },
      email: {
        // schema: yup.string().when("mobile_number", {
        //   is: (value) => value !== undefined,
        //   then: yup
        //     .string()
        //     .email()
        //     .required("Please Enter Email or Mobile Number"),
        //   otherwise: yup.string().email(),
        // }),
        schema: !values.mobile_number
          ? yup.string().email().required("Enter Email or Mobile number")
          : yup.string().email(),
        label: "Email",
        type: "email",
        required_or: [4],
      },
      partner_id: {
        label: "Select Partner",
        type: "select",
        options: filterPartner(data?.courses_partner).map((partner) => ({
          value: partner.id,
          label: partner.name,
        })),
        placeholder: "Choose a Partner",
      },
      project_id: {
        label: "Select project",
        type: "select",
        options: ((selectedPartner && selectedPartner.projects) || []).map(
          (project) => ({ value: project.id, label: project.name })
        ),
        placeholder: "Choose a Project",
      },

      mobile_number: {
        label: "Mobile Number",
        type: "text",
        required_or: [1],
        schema: !values.email
          ? yup
              .number()
              .min(10, "Mobile Number should have 10 digits")
              .required("Please Enter Email or Mobile Number")
          : yup.number().min(10, "Mobile Number should have 10 digits"),
        // schema: yup.number().when("email", {
        //   is: (value) => value !== null,
        //   then: yup
        //     .number()
        //     .min(10, "Mobile Number should have 10 digits")
        //     .max(11, "Mobile Number should have 10 digits")
        //     .required("Please Enter Email or Mobile Number"),
        //   otherwise: yup
        //     .number()
        //     .min(10, "Mobile Number should have 10 digits")
        //     .max(11, "Mobile Number should have 10 digits"),
        // }),
      },
      activation_start_date: {
        label: "Activation start date",
        type: "date",
      },

      password: {
        label: "Enter password",
        type: "password",
        required: true,
        schema: yup.string(),
      },
      confirm_password: {
        label: "Confirm password",
        type: "password",
        required: true,
        schema: yup.string(),
      },
      active: {
        label: "Active",
        type: "boolean",
      },
      is_email_valid: {
        label: "Is email valid",
        type: "boolean",
      },
    };

    if (values.id) {
      schema.password = { type: "hidden", required: false };
      schema.confirm_password = { type: "hidden", required: false };
    }

    setSchema(schema);
  }, [
    data,
    selectedPartner,
    partnerAccess,
    values.mobile_number,
    values.email,
  ]);

  return schema;
}

export default UserFormBuilder;
