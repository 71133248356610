import courseBuilderService from "services/coursebuild/course";
export const CREATE_SECTION= "CREATE_SECTION";
export const SET_SECTION= "SET_SECTION";




export const GetSection = (course_id) => {
    return async (dispatch) => {
        const section = await courseBuilderService.getCourseSection(course_id);
        dispatch(SetSection(section));
    };
  };

  export const CreateSection = (data,hooks) =>{
    const { onEnd } = hooks || {};

    return async (dispatch)=>{    
        let rep;
        try{
            rep= await courseBuilderService.insercoursesection(data);
        }
        catch (err) {
           console.log(err.msg);
          } 
          
          if (rep) dispatch(SetSection(rep));
      
          if (onEnd && typeof onEnd === "function") onEnd();

      


    };
}

  export const SetSection = (section) => {
    return (dispatch) => dispatch({ type: SET_SECTION, payload: section });
  };


  