import { useQuery } from "@apollo/client";
import { GET_ALL_PARTNERS } from "GraphQl/Queries/CertificateTemplateMaker";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { gqlUserRecoilVar, selectedPartnerIdRecoilVar } from "State/User";
import { getReport } from "api/reports";
import Container from "./Container/Container";
import MultiSelectDropdown from "./InputGroup/MultiselectDropdown";
import Loader from "assets/animated-images/loader";
import { currentModuleAccessRecoilVar } from "State/Navigation";
import { errorHandler } from "utils/toast";

export default function Report(props) {
  /*
   *
   *
   * Context
   *
   *
   */
  const { type, params, hidePartners = false } = props;

  const [user] = useRecoilState(gqlUserRecoilVar);
  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);
  const [selectedPartnerId] = useRecoilState(selectedPartnerIdRecoilVar);

  const { data: allPartnersRes } = useQuery(GET_ALL_PARTNERS);
  const allPartners = allPartnersRes?.courses_partner;

  const accessiblePartners = useMemo(() => {
    if (user && allPartners && user.is_skillstrainer_admin)
      return allPartners.map((p) => p.id);
    else if (currentModuleAccess)
      return Object.keys(currentModuleAccess.allowedPartners).map(Number);
  }, [user, currentModuleAccess, allPartners]);

  /*
   *
   *
   * Input
   *
   *
   */
  const [partnerIdList, setPartnerIdList] = useState();
  useEffect(() => {
    if (accessiblePartners) {
      if (user.is_skillstrainer_admin)
        setPartnerIdList([...accessiblePartners]);
      else setPartnerIdList([accessiblePartners[0]]);
    }
  }, [user, accessiblePartners, selectedPartnerId]);

  /*
   *
   *
   * Iframe URL
   *
   *
   */
  const [iframeUrl, setIframeUrl] = useState();
  const [fetchingIframeUrl, setFetchingIframeUrl] = useState();

  const fetchReportUrl = async (type, params) => {
    setFetchingIframeUrl(true);
    await getReport({ type, params })
      .then((response) => {
        setIframeUrl(response.report?.url);
      })
      .catch((err) => {
        errorHandler(err.response.data.message);
      });
    setFetchingIframeUrl(false);
  };

  /*
   *
   *
   * Trigger
   *
   *
   */
  useEffect(() => {
    if (partnerIdList) {
      fetchReportUrl(type, { ...(params || {}), partner_id: partnerIdList });
    }
  }, [params, !!partnerIdList]);

  /*
   *
   *
   * Misc
   *
   *
   */
  const [isChoosePartnerOpen, setIsChoosePartnerOpen] = useState();

  return (
    <Container>
      {accessiblePartners && !hidePartners && (
        <div className="mb-4">
          <button
            onClick={() => setIsChoosePartnerOpen(!isChoosePartnerOpen)}
            className="btn-primary"
          >
            {isChoosePartnerOpen ? "Done" : "Choose partners"}
          </button>
          {isChoosePartnerOpen && (
            <div className="mt-3">
              <MultiSelectDropdown
                options={allPartners
                  ?.filter((p) => accessiblePartners.includes(p.id))
                  .map((p) => ({
                    label: p.name,
                    value: p.id,
                  }))}
                setSelectedValue={(e) => setPartnerIdList(e)}
                selectedValue={partnerIdList || []}
              />
              <button
                onClick={() =>
                  fetchReportUrl(type, {
                    ...(params || {}),
                    partner_id: partnerIdList,
                  })
                }
                className="btn-primary w-full mt-2"
              >
                Get reports
              </button>
            </div>
          )}
        </div>
      )}

      {fetchingIframeUrl ? (
        <div className="flex w-full justify-center">
          <Loader color="#999" style={{ height: "50px" }} />
        </div>
      ) : (
        iframeUrl && (
          <iframe
            title="Reports"
            src={`${iframeUrl}`}
            width="100%"
            height={`${2500}px`}
            allowTransparency
          ></iframe>
        )
      )}
    </Container>
  );
}
