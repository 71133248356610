import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { TaxDbSchema } from "./TaxDbSchema";
import Input from "components/InputGroup/Input";
import Checkbox from "components/InputGroup/Checkbox";
import Container from "components/Container/Container";
import { INSERT_COURSE_TAX } from "GraphQl/Mutations/coursebuildemutation/CourseTax";
import { GET_COURSE_TAX } from "GraphQl/Queries/CourseBuilder/CoursesTax";
import { UPDATE_COURSE_TAX } from "GraphQl/Mutations/coursebuildemutation/CourseTax";
import TaxDataTable from "./TaxDataTable";

export default function ManageTaxPage() {
  const courseTax = useQuery(GET_COURSE_TAX);
  const [insert_course_tax, insert_error] = useMutation(INSERT_COURSE_TAX, {
    refetchQueries: [GET_COURSE_TAX],
  });

  const [update_course_tax, update_insert_error] = useMutation(
    UPDATE_COURSE_TAX,
    {
      refetchQueries: [GET_COURSE_TAX],
    }
  );

  const [taxdata, setTaxData] = useState({});
  const [taxUpdate, setTaxUpdate] = useState(false);

  const updateValue = (e) => {
    const key = e.target.getAttribute("data-key");

    e.preventDefault();
    const value = e.target.value;
    taxdata[key] = value;
    setTaxData({ ...taxdata, [key]: value });
  };

  const updateCheckbox = (e) => {
    const key = e.target.getAttribute("data-key");
    const value = e.target.checked;
    setTaxData({ ...taxdata, [key]: value });
  };

  const submitTax = (e) => {
    e.preventDefault();
    console.log("Tax Data : ", taxdata);
    if (taxUpdate) {
      update_course_tax({ variables: taxdata });
    } else {
      insert_course_tax({ variables: taxdata });
    }
    resetCategory();
  };

  const resetCategory = () => {
    var default_category = {
      tax_name: "",
      tax_percentage: "",
      tax_enable: false,
    };
    setTaxData(default_category);
    setTaxUpdate(false);
  };

  const updateCourseTaxes = (row) => {
    //resetCategory();
    setTaxUpdate(true);
    setTaxData({ ...row });
  };
  return (
    <div>
      <Container title={"Manage Taxes"}>
        {/* <div className="px-4 m-3 w-3/4">
          <div className="m-3">
            <h5>Create Taxes</h5>
          </div>
        </div> */}
        <form className="flex flex-wrap" onSubmit={submitTax}>
          {TaxDbSchema.map((course) => {
            return (
              <div className="w-1/2">
                {["text", "date", "password"].includes(course.type) && (
                  <Input
                    label={course.label}
                    className={"px-2 py-1"}
                    type={course.type}
                    data-key={course.column_name}
                    onChange={(e) => updateValue(e)}
                    value={taxdata[course.column_name]}
                  />
                )}

                {course.type === "checkbox" && (
                  <Checkbox
                    label={"Visible"}
                    className={"py-1"}
                    data-key={course.column_name}
                    onChange={updateCheckbox}
                    checked={taxdata[course.column_name]}
                  />
                )}
              </div>
            );
          })}
          <div className="px-4 py-3 text-right sm:px-6 space-x-2">
            <button
              onClick={resetCategory}
              type="reset"
              className="btn-secondary"
            >
              Reset
            </button>
            <button type="submit" className="btn-primary">
              Save
            </button>
          </div>
        </form>

        <TaxDataTable
          courseTax={courseTax}
          className={"shadow overflow-hidden sm:rounded-md mt-10"}
          updateCourseTaxes={updateCourseTaxes}
        />
      </Container>
    </div>
  );
}
