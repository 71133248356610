import { Draggable } from "react-beautiful-dnd";
import { FaGripVertical } from "react-icons/fa";

export const Drag = ({ id, index, ...props }) => {
  return (
    <Draggable draggableId={id.toString()} index={index}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
            <div className="drag-handle" {...provided.dragHandleProps}>
              <FaGripVertical className="AiOutlineHolder" size={25} />
            </div>
            <div>{props.children}</div>
          </div>
        );
      }}
    </Draggable>
  );
};
