import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GET_INSTRUCTORS_BY_ID } from "../../../GraphQl/Queries/Instructor";
import { errorHandler, successHandler } from "utils/toast";
import { Form } from "skillstrainer-resource-library";
import InstructorFormBuilder from "formBuilders/InstructorFormBuilder";
import api from "api/Api";
import Container from "components/Container/Container";

const UpdateInstructor = ({ id, close }) => {
  const { data: querydata } = useQuery(GET_INSTRUCTORS_BY_ID, {
    variables: { id: id },
  });
  const instructor = useMemo(
    () => querydata && querydata.courses_instructor[0],
    [querydata]
  );

  const submitInstructor = (trainer_changes) => {
    if (
      trainer_changes.password &&
      trainer_changes.password !== trainer_changes.confirm_password
    ) {
      errorHandler("Password and confirm password don't match");
      return;
    }

    delete trainer_changes.confirm_password;

    api()
      .post("/update_trainer", { trainer_id: instructor.id, trainer_changes })
      .then((res) => {
        console.log(res);
        close();
        successHandler("Trainer Updated Successfully");
      })
      .catch((err) => {
        console.error(err.response.data);
        errorHandler(err.response.data.message || "An unknown error occurred");
      });
  };

  return (
    <Container title={"Update Instructor"}>
      {querydata && (
        <Form
          formBuilder={InstructorFormBuilder}
          initValues={querydata.courses_instructor[0]}
          className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className:
              "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
          }}
          onSubmit={(instructor) => submitInstructor(instructor)}
        />
      )}
    </Container>
  );
};

export default UpdateInstructor;
