import { useLazyQuery } from "@apollo/client";
import { GET_JOB_APPLICANTS_BY_ID } from "GraphQl/Queries/Jobs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import ApplicantsTable from "./ApplicantTable";
import Input from "components/InputGroup/Input";

function ViewJobApplicants() {
  const [search, setSearch] = useState({
    name: "",
    email: "",
  });
  const [runQuery, { data: applicantsData, loading: applicantsLoading }] =
    useLazyQuery(GET_JOB_APPLICANTS_BY_ID, {
      fetchPolicy: "network-only",
    });

  const location = useLocation();
  const params = queryString.parse(location.search);
  const jobId = params.id;

  const getApplicantsData = () => {
    runQuery({
      variables: {
        where: {
          job_id: {
            _eq: jobId,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (params.id) {
      getApplicantsData();
    }
  }, [params.id]);

  const searchApplicants = (e) => {
    e.preventDefault();
    const searchVariables = {
      where: {
        _and: [
          {
            job_id: { _eq: jobId },
          },
          {
            user: {
              name: { _ilike: `%${search.name}%` },
            },
          },
          {
            user: {
              email: { _ilike: `%${search.email}%` },
            },
          },
        ],
      },
    };

    runQuery({ variables: searchVariables });
  };

  return (
    <div className="ml-4 mt-6 space-y-4">
      {applicantsData ? (
        <h2>
          Job Applicants For
          <span className="text-orange">
            {" "}
            {applicantsData?.courses_job_applications[0]?.job.title}
          </span>
        </h2>
      ) : (
        <h2>Job Applicants</h2>
      )}
      <div className="flex flex-row space-x-4 ">
        <Input
          label={"Search By Name"}
          value={search.name}
          onChange={(e) => setSearch({ ...search, name: e.target.value })}
          type={"text"}
          placeholder={"Enter Name"}
          className={"w-1/3"}
        />
        <Input
          label={"Search By Email"}
          value={search.email}
          onChange={(e) => setSearch({ ...search, email: e.target.value })}
          type={"text"}
          placeholder={"Enter Email"}
          className={"w-1/3"}
        />
        <button
          className="btn-primary h-9 mt-4 py-2 px-3"
          onClick={searchApplicants}
        >
          Search
        </button>
      </div>

      {!applicantsLoading && applicantsData && (
        <div>
          <ApplicantsTable
            data={applicantsData?.courses_job_applications}
            jobId={jobId}
            refetch={getApplicantsData}
          />
        </div>
      )}
    </div>
  );
}

export default ViewJobApplicants;
