import { gql } from "@apollo/client";

export const UPSERT_ADMIN_ROLES_INFO = gql`
  mutation upsertAdminRolesInfo(
    $adminRoleInfo: [courses_admin_user_roles_info_insert_input!]!
  ) {
    insert_courses_admin_user_roles_info(
      objects: $adminRoleInfo
      on_conflict: {
        constraint: admin_user_roles_info_id_key
        update_columns: [id, name, partner_id, active, project_id]
      }
    ) {
      affected_rows
      returning {
        id
        created_at
        updated_at
      }
    }
  }
`;

export const UPSERT_ADMIN_ROLE_MODULE_ACCESS = gql`
  mutation upsertAdminRoleModuleAccess(
    $objects: [courses_admin_user_role_module_access_insert_input!]!
  ) {
    insert_courses_admin_user_role_module_access(
      objects: $objects
      on_conflict: {
        constraint: admin_user_role_module_access_role_id_module_id_key
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_ADMIN_ROLE_MODULE_ACCESS = gql`
  mutation deleteAdminRoleModuleAccess($ids: [bigint!]!) {
    delete_courses_admin_user_role_module_access(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;
