export const TaxDbSchema = [
    {
      column_name: "tax_name",
      type: "text",
      label: "Enter tax name",
    },
    {
      column_name: "tax_percentage",
      type: "text",
      label: "Enter tax percentage",
    },

    {
        column_name: "tax_enable",
        type: "checkbox",
        label: "Publish",
      },
    
  ];
  