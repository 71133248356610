import Loader from "assets/animated-images/loader";
import Container from "components/Container/Container";
import { useState } from "react";
import { jbfDailyStats } from "utils/jbfDailyReports";
import Select from "components/InputGroup/Select";
export default function JBFDailyReports() {
  const [loaderState, setLoaderState] = useState(false);
  const [apiParam, setApiParam] = useState({
    date: "",
    district: "",
  });
  const [text, setText] = useState("");

  const getJBFData = async ({ date, district }) => {
    const response = await jbfDailyStats(date, district);
    setText(JSON.stringify(response, null, 4));
    setLoaderState(false);
  };
  return (
    <Container className="">
      <div className="w-full flex flex-row space-x-4">
        <Select
          className="my-2 w-1/2"
          value={apiParam.district}
          onChange={(e) =>
            setApiParam({
              ...apiParam,
              district: e.target.value,
            })
          }
          options={[
            "Kapasan",
            "Nanjangud",
            "Nira",
            "Savli",
            "Gajraula",
            "Bharuch",
            "Bangalore",
          ].map((item) => {
            return {
              label: item,
              value: item,
            };
          })}
        />
        <input
          type="date"
          onChange={(e) => setApiParam({ ...apiParam, date: e.target.value })}
        />
        <button
          className="btn-primary my-2"
          onClick={() => {
            setLoaderState(true);
            getJBFData(apiParam);
          }}
          disabled={loaderState}
        >
          {loaderState ? (
            <Loader style={{ width: "40px" }} />
          ) : (
            "JBF Daily Reports"
          )}
        </button>
      </div>

      <p>{text}</p>
    </Container>
  );
}
