import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ApplicantDetails from "./ApplicantDetails";
import ApplicantEvaluation from "./ApplicantEvaluation";
import { useMutation } from "@apollo/client";
import { UPDATE_JOB_STAGE, UPDATE_JOB_STATUS } from "GraphQl/Mutations/Jobs";
import { errorHandler, successHandler } from "utils/toast";

function ViewApplicantModal(props) {
  const { applicant, setApplicant, show, handleClose, jobId } = props;
  const [showEvaluation, setShowEvaluation] = useState(false);
  const [updateJobApplicationStatus] = useMutation(UPDATE_JOB_STATUS);
  const [updateJobApplicationStage] = useMutation(UPDATE_JOB_STAGE);
  const showEvaluationTab = () => {
    if (showEvaluation) setShowEvaluation(false);
    else setShowEvaluation(true);
  };

  const updateApplicationStatus = async (status) => {
    await updateJobApplicationStatus({
      variables: {
        jobId: jobId,
        userId: applicant.id,
        status: status,
      },
    })
      .then(() => {
        successHandler("Application Status Updated");
        setApplicant({
          id: applicant.id,
          name: applicant.name,
          status: status,
          stage: applicant.stage,
        });
      })
      .catch((err) => {
        console.log(err);
        errorHandler("Error while updating status");
      });
  };

  const updateApplicationStage = async (stage) => {
    let status = undefined;
    if (applicant.status === "accepted" || applicant.status === "rejected") {
      status = "active";
    }
    if (stage < 0) successHandler("Stage can't go below 0");
    else
      await updateJobApplicationStage({
        variables: {
          jobId: jobId,
          userId: applicant.id,
          stage: stage >= 0 ? stage : 0,
          status: status ? status : applicant.status,
        },
      })
        .then(() => {
          successHandler("Stage Updated Successfully");
          setApplicant({
            id: applicant.id,
            name: applicant.name,
            status: status ? status : applicant.status,
            stage: stage,
          });
        })
        .catch((err) => {
          console.log(err);
          errorHandler("Error while updating stage");
        });
  };

  return (
    <div>
      <Modal
        size="lg"
        show={show}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        className=""
      >
        <Modal.Header closeButton key={applicant}>
          <div className="flex flex-row">
            <h4 className=" bold mt-2 p-1">{applicant.name}</h4>
            <button
              className="btn-secondary p-2 my-2 ml-4"
              onClick={showEvaluationTab}
            >
              {showEvaluation ? "Show Applicant Details" : "Show Evaluation"}
            </button>
            {applicant.status === "active" ? (
              <h4 className=" text-orange p-1 font-bold border-4 border-orange rounded-md ml-4">{`Stage ${
                applicant.stage ? applicant.stage : "0"
              }`}</h4>
            ) : (
              <h4
                className={`${
                  applicant.status === "accepted"
                    ? "text-green-500 border-green-500 ml-4"
                    : "text-red-500 border-red-500 ml-4"
                } font-bold p-1 border-4 rounded-md`}
              >
                {applicant.status === "accepted" ? "ACCEPTED" : "REJECTED"}
              </h4>
            )}
          </div>
        </Modal.Header>
        <Modal.Body className="">
          {showEvaluation ? (
            <ApplicantEvaluation userId={applicant.id} jobId={jobId} />
          ) : (
            <ApplicantDetails userId={applicant.id} close={handleClose} />
          )}
        </Modal.Body>
        <Modal.Footer className={`w-full`}>
          <button
            onClick={() => {
              updateApplicationStatus("accepted");
            }}
            className="text-sm text-white font-semibold rounded-lg px-2 w-1/6 py-2 bg-green-600"
          >
            Accept
          </button>
          <button
            className="text-sm text-white font-semibold rounded-lg px-2  w-1/6 py-2 bg-black"
            onClick={() => {
              updateApplicationStage(Number(applicant.stage) - 1);
            }}
          >
            Stage Decrease
          </button>
          <button
            className="text-sm text-white font-semibold rounded-lg px-2  w-1/6 py-2 bg-black"
            onClick={() => {
              updateApplicationStage(Number(applicant.stage + 1));
            }}
          >
            Stage Increase
          </button>
          <button
            onClick={() => {
              updateApplicationStatus("rejected");
            }}
            className="text-sm text-white font-semibold rounded-lg px-2  w-1/6 py-2 bg-red-600 "
          >
            Reject
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewApplicantModal;
