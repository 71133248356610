import React from "react";
import { condObj } from "utils/func";

const Input = ({ label, className, value, ...inputProps }) => {
  return (
    <div key={label} className={`${className}`}>
      <label className="block text-sm font-medium text-gray-700">
        {label}
        {inputProps.requiredField == "yes" ? (
          <span className="text-red-600 text-lg	">*</span>
        ) : (
          ""
        )}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          key={label}
          className={`focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 text-sm ${
            inputProps.error == "yes" ? "border-red-600" : "border-gray-300"
          } rounded-md`}
          {...condObj(value, { value })}
          {...inputProps}
        />
      </div>
      {inputProps.error == "yes" ? (
        <span className="border-red-600 text-md text-red-600">
          This field can't be blank
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Input;
