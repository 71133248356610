export const ModuleNameIdMap = {
  traineeReports: 4,
  trainerReports: 23,
  createUpdateTrainer: 24,
  listTrainers: 25,
  partnerReports: 27,
  partnerDashboard: 28,
  managePartners: 29,
  manageProjects: 30,
  workPartnerQueries: 31,
  partnerWebsiteConfiguration: 32,
  team: 36,
  manageUsers: 37,
  manageRoles: 38,
  reports: 40,
  commissionTransactions: 41,
  settlementTransactions: 42,
  editMoodleConfiguration: 14,
  editPartner: 53,
  updateTrainee: 2,
  createJob: 55,
  manageJobs: 56,
  partnerCommission: 57,
  onboardBulkTrainee: 7,
  jobs: 54,
  createTrainee: 6,
  listTrainees: 5,
  createScholarships: 34,
  traineeCourseEnrol: 58,
  updateJob: 59,
  projectReports: 61,
  listScholarships: 35,
  viewJobApplicants: 63,
  scormEdit: 64,
  quizEdit: 65,
  trainee: 3,
  courses: 9,
  scholarshipReports: 62,
  resourceEdit: 66,
  partner: 26,
  projects: 60,
  scholarship: 33,
  trainer: 22,
  financials: 39,
  createJobSkills: 67,
  courseUserReports: 46,
  traineeProjectAssociation: 70,
  createJobQualifications: 68,
  traineePartnerAssociation: 69,
  applyCoupon: 71,
  enrolInBatch: 72,
  createUpdateCouponCode: 17,
  createBatch: 73,
  listBatches: 15,
  courseReports: 10,
  listCourses: 11,
  createUpdateCourse: 12,
  manageCourseCategories: 13,
  listCouponCodes: 16,
  manageTaxes: 18,
  certificateTemplateMaker: 19,
  csvBulkCertificateGenerator: 20,
  certificates: 21,
  updateCourse: 43,
  viewCourse: 44,
  completionSettings: 45,
  editQuestion: 47,
  viewModuleQuestions: 48,
  addCourseActivity: 49,
  courseModule: 50,
  courseModuleView: 51,
  notifications: 74,
  smsTemplates: 75,
  emailTemplates: 76,
  bulkNotification: 91,
  questionBank: 77,
  questionCreate: 78,
  generateCallingList: 80,
  listCallingLists: 81,
  callingLists: 79,
  viewCallingList: 82,
  createUpdateInstitute: 86,
  institutes: 85,
  partnerWebsiteTable: 87,
  referralScheme: 90,
  invoices: 89,
  bulkQuizResponseHandler: 92,
  bulkinstituteupload: 93,
  asdmStats: 97,
  analatics: 98
};
