import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_QUESTION } from "GraphQl/Mutations/coursebuildemutation/Questions";
import { GET_QUESTION_BY_ID } from "GraphQl/Queries/CourseBuilder/Quiz";
import QuestionFormBuilder from "formBuilders/QuestionFormBuilder";
import { Container } from "react-bootstrap";
import { Form } from "skillstrainer-resource-library";
import { arrayDelta } from "utils/func";
import { errorHandler, successHandler } from "utils/toast";

export default function EditQuestion(props) {
  const { question, refresh } = props;
  const questionId = question?.id || null;
  const [questionData, setQuestionData] = useState({ key: -1, data: {} });

  const { data, refetch } = useQuery(GET_QUESTION_BY_ID, {
    variables: {
      id: questionId,
    },
  });
  const [updateQuestion] = useMutation(UPDATE_QUESTION);

  async function handleSubmit(question) {
    const coursesQuestionData = {
      id: questionId,
      marks: question.marks,
      questionText: question.question_text,
      questionCategory: question.question_category,
      visible: question.visible,
      mode: question.mode || null,
      questionType: question?.mode === "picture" ? 2 : 1,
    };

    const questionOldTags = (
      data?.courses_questions[0]?.qusetion_tag || []
    ).map((tag) => {
      return {
        id: tag.id,
        question_id: questionId,
        tag_id: tag,
      };
    });

    const questionNewTags = (question?.question_tags || []).map((tag) => {
      return {
        question_id: questionId,
        tag_id: tag,
      };
    });

    const { created, deleted } = arrayDelta(questionOldTags, questionNewTags, [
      "tag_id",
    ]);

    const correctAnswer = question.answer;

    const newAnswers = question?.question_answers.map((ans) => {
      return {
        ...ans,
        question_id: questionId,
        answer_img: ans.answer_img ? ans.answer_img[0].url : null,
        system_filename: ans.answer_img ? ans.answer_img[0].name : null,
        isAnswer: ans.answer_text === correctAnswer ? true : false,
      };
    });

    const oldAnswers =
      questionData.data?.question_answers.map((ans) => {
        return {
          ...ans,
          answer_img: ans.answer_img ? ans.answer_img[0].url : null,
          system_filename: ans.answer_img ? ans.answer_img[0].name : null,
          isAnswer: ans.answer_text === correctAnswer ? true : false,
        };
      }) || [];

    const {
      created: createdAnswers,
      persistant: persistentAnswers,
      deleted: deletedAnswers,
    } = arrayDelta(oldAnswers, newAnswers, ["id"]);

    await updateQuestion({
      variables: {
        questionTagRelations: created,
        ids: deleted.map((tag) => tag.id),
        answerIds: deletedAnswers.map((ans) => ans.id),
        questionAnswers: [...createdAnswers, ...persistentAnswers],
        ...coursesQuestionData,
      },
    })
      .then(() => {
        refetch();
        refresh();
        successHandler("Question was updated successfully");
      })
      .catch((err) => {
        console.log(err);
        errorHandler("There was an error while updating question");
      });
  }

  //effect for init values
  useEffect(() => {
    if (Object.keys(data || {}).length !== 0) {
      const editQuestionData = data.courses_questions[0];
      const question_tags = (editQuestionData?.qusetion_tag || []).map(
        (tag) => {
          return tag.tag_id;
        }
      );
      let answer;
      const question_answers = (editQuestionData?.quest_answer || []).map(
        (ans) => {
          if (ans.isAnswer) answer = ans.answer_text;
          return {
            id: ans.id,
            question_id: ans.question_id,
            answer_text: ans.answer_text,
            category: ans.category,
            answer_img: ans?.answer_img
              ? [
                  {
                    id: 1,
                    name: ans?.system_filename || null,
                    url: ans?.answer_img || null,
                  },
                ]
              : undefined,
          };
        }
      );

      setQuestionData({
        key: editQuestionData.id,
        data: {
          question_tags: question_tags,
          question_category: editQuestionData?.question_category || null,
          question_text: editQuestionData?.question_text || null,
          question_answers: question_answers,
          marks: editQuestionData?.marks || null,
          visible: editQuestionData?.visible,
          answer: answer,
          mode: editQuestionData?.mode || null,
        },
      });
    }
  }, [data]);

  return (
    <Container>
      {data && (
        <Form
          key={questionData.key}
          formBuilder={QuestionFormBuilder}
          className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className:
              "btn-primary text-sm font-semibold text-white rounded-md px-6",
          }}
          initValues={questionData.data}
          onSubmit={handleSubmit}
        />
      )}
    </Container>
  );
}
