import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Container from "components/Container/Container";
import { useLocation } from "react-router";
import queryString from "query-string";
import axios from "axios";
import { useHistory } from "react-router-dom";
import courseBuilderService from "services/coursebuild/course";
import { toast } from "react-toastify";
import {
  GET_MODULE_DETAILS_BY_ID,
  GET_MODULE_RESTRICTION,
} from "GraphQl/Queries/CourseBuilder/section";

import api from "api/Api";
import { error } from "utils/error";
import ResourcesActivity from "./ResourcesActivity";
import { errorHandler, successHandler } from "utils/toast";

import { uploadResourceLargeFile } from "api/UploadFile";

import { INSERT_COURSE_RESOURCES } from "GraphQl/Mutations/coursebuildemutation/CourseModule";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleIdComponentMap } from "Contexts/Navigation/ModuleIdComponentMap";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";

export default function ResourceActivityEdit() {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const history = useHistory();

  const { data: ResourceFormData } = useQuery(GET_MODULE_DETAILS_BY_ID, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  });

  const { data: ModuleRestriction } = useQuery(GET_MODULE_RESTRICTION, {
    variables: { mapping_id: params.id },
    fetchPolicy: "network-only",
  });

  const [insert_course_resources] = useMutation(INSERT_COURSE_RESOURCES);

  const [formData, setFormData] = useState([]);

  const [resourceFile, setResourceFile] = useState([]);

  const [newresourceFile, setNewResourceFile] = useState([]);

  const [restriction, setRestriction] = useState(false);

  const [defaultRestriction, setDefaultRestriction] = useState("");

  const [selectedValue, setSelectedValue] = useState([]);

  const [topic, setTopics] = useState([]);
  const [lessonName, setLessonName] = useState(null);

  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    const getResourecData = async () => {
      const formData = {
        ...(ResourceFormData &&
          ResourceFormData.courses_course_module_mapping[0]),
      };
      const { module_resources } = formData;

      setResourceFile(module_resources);
      setLessonName(formData.section?.name);

      if (formData.course_id) {
        const res = await courseBuilderService.GetCourseTopics(
          formData.course_id
        );

        const Options =
          res.length > 0 &&
          res.map((topic, i) => {
            return { value: topic.id, label: topic.name };
          });

        setTopics(Options);

        const restriction_ids =
          ModuleRestriction &&
          ModuleRestriction.courses_module_completion_resrtriction[0];

        if (restriction_ids) {
          const split_string =
            restriction_ids.restriction_ids &&
            restriction_ids.restriction_ids.split(",");

          if (split_string) {
            const result =
              Options &&
              Options.filter((o1) => split_string.some((o2) => o1.value == o2));

            if (result) {
              setDefaultRestriction(result);
              setSelectedValue(
                Array.isArray(result) ? result.map((x) => x.value) : []
              );
            }
          }

          formData.enable_restriction = true;
          formData.restriction_previous_set = true;
          formData.batch_id = restriction_ids.batch_id;

          setRestriction(true);
        } else {
          formData.restriction_previous_set = false;
        }
      }

      setFormData(formData);
    };

    getResourecData();

    console.log("module_resources");
  }, [ResourceFormData, ModuleRestriction]);

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const updateValue = (e) => {
    const key = e.target.getAttribute("data-key");
    const value = e.target.value;

    setFormData({ ...formData, [key]: value });
  };

  const updateCheckbox = (e) => {
    const key = e.target.getAttribute("data-key");

    const value = e.target.checked;

    if (key === "enable_restriction") {
      setRestriction(value);
    }

    setFormData({ ...formData, [key]: value });
  };

  /**** Resource Uplaoder function */
  const handleFileReaderResource = async (fupload) => {
    setNewResourceFile((oldarr) => [...oldarr, fupload[0]]);
  };

  // Remove Uploaded Resources
  const deleteFile = (name) =>
    setResourceFile(resourceFile.filter((item) => item.file_name !== name));

  // Remove New Upload Resources
  const deleteNewFile = (name) =>
    setNewResourceFile(newresourceFile.filter((item) => item.name !== name));

  // SubmitResources
  const submitResources = async () => {
    const checkExistingAttachemt = formData.module_resources.filter((ad) =>
      resourceFile.every((fd) => fd.id !== ad.id)
    );

    formData["restriction_ids"] = selectedValue.toString();
    formData["id"] = params.id;
    formData["completion_criteria"] = parseInt(formData.completion_criteria);

    const update = await courseBuilderService.UpdateScormForm(formData);

    if (checkExistingAttachemt.length > 0)
      await courseBuilderService.deleteResourceTopicAttachments(
        checkExistingAttachemt.map((e) => e.id)
      );

    //Upload and link attached files to created topic
    await Promise.all(
      (newresourceFile || []).map(async (file) => {
        const resp = await resourecUploadFun(file);

        insert_course_resources({
          variables: {
            actual_file_name: file.name,
            file_name: resp,
            mapping_id: params.id,
          },
        });
      })
    );

    if (update) {
      let path =
        getModulePathStringById(ModuleNameIdMap.viewCourse) +
        `?id=${formData.course_id}`;
      history.push(path, { upadtedModuleId: formData["section_id"] });

      toast.success("Resource successfully updated!");
    } else {
      toast.error("Something will be wrong!");
    }
  };

  //Resource Uploading
  const resourecUploadFun = async (resourceFile) => {
    return await uploadResourceLargeFile(
      resourceFile,
      resourceFile.name,
      true,
      "course-resources"
    )
      .then((res) => res.data_url)
      .catch((e) => {
        errorHandler("An error occured while uploading");
        throw error("Couldn't upload file", e.data || e);
      });
  };

  const fileLoadingFun = () => setFileLoading(!fileLoading);

  return (
    <Container>
      <ResourcesActivity
        action="update"
        updateCheckbox={updateCheckbox}
        updateValue={updateValue}
        handleFileReaderResource={handleFileReaderResource}
        handleChange={handleChange}
        section={formData}
        topic={topic}
        restriction={restriction}
        resourceFile={resourceFile}
        deleteFile={deleteFile}
        selectedValue={selectedValue}
        submitResources={submitResources}
        newresourceFile={newresourceFile}
        deleteNewFile={deleteNewFile}
        defaultRestriction={defaultRestriction}
        lessonName={lessonName}
        fileLoading={fileLoading}
        fileLoadingFun={fileLoadingFun}
      />
    </Container>
  );
}
