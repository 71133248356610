import * as yup from "yup";
import { COURSES_QUERY } from "GraphQl/Queries/Courses";
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";

function ReferralSchemeFormBuilder(values) {
  const [schema, setSchema] = useState();
  // const [selectedDataType, setSelectedDataType] = useState("text");
  const { data: courseData } = useQuery(COURSES_QUERY);

  useEffect(() => {
    const schema = {
      name: {
        required: true,
        label: "Scheme Name",
        type: "text",
        schema: yup.string(),
      },
      referrer_reward_type: {
        label: "Referrer Reward Type",
        type: "select",
        required: true,
        options: [
          {
            label: "Commission",
            value: "commission",
          },
          // {
          //   label: "Course",
          //   value: "course",
          // },
        ],
      },
      referrer_reward: {
        type: "object",
        required: true,
        label: "Referrer Reward",
        insertable: true,
        fields: {
          type: {
            label: "Commission Type",
            type: "select",
            required: true,
            options: [
              {
                label: "Percentage",
                value: "percentage",
              },
              {
                label: "Absolute",
                value: "absolute",
              },
            ],
            schema: yup.string(),
          },
          value: {
            required: true,
            label: "Commission Value",
            type: "number",
            schema: yup.number(),
          },
        },
      },
      referee_reward_type: {
        label: "Referee Reward Type",
        type: "select",
        required: true,
        options: [
          {
            label: "Discount",
            value: "discount",
          },
          // {
          //   label: "Course",
          //   value: "course",
          // },
        ],
      },
      referee_reward: {
        type: "object",
        required: true,
        label: "Referee Reward",
        insertable: true,
        fields: {
          type: {
            label: "Discount Type",
            type: "select",
            required: true,
            options: [
              {
                label: "Percentage",
                value: "percentage",
              },
              {
                label: "Absolute",
                value: "absolute",
              },
            ],
            schema: yup.string(),
          },
          value: {
            required: true,
            label: "Discount Value",
            type: "number",
            schema: yup.number(),
          },
        },
      },
      action: {
        label: "Applicable On",
        type: "select",
        required: true,
        options: [
          {
            label: "Trainee Registration",
            value: "trainee registration",
          },
          {
            label: "Trainee Course Enrolment",
            value: "trainee course enrolment",
          },
        ],
      },
      constraints: {
        type: "object",
        required: false,
        label: "Constraints",
        // insertable: true,
        // repeat: true,
        fields: {
          course_ids: {
            label: "Courses",
            type: "multi-select",
            // required: true,
            // repeat: true,
            options: courseData?.courses_course
              .map((course) => ({
                value: course.id,
                label: course.full_name,
              }))
              .sort((a, b) => {
                return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
              }),
            schema: yup.number(),
          },
        },
      },
      // id: {
      //   type: "hidden",
      //   schema: yup.string(),
      // },
      admin_user_id: {
        type: "hidden",
        schema: yup.string(),
      },
      // is_active: {
      //   type: "hidden",
      //   schema: yup.boolean(),
      // },
    };
    setSchema(schema);
  }, [values, courseData]);

  return schema;
}

export default ReferralSchemeFormBuilder;
