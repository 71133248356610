import { gql } from "@apollo/client";

export const GET_PROJECTS = gql`
  query getPartnterProjects {
    courses_partner_projects(order_by: { updated_at: desc }) {
      id
      name
      partner_id
      pincode
      state
      house_number
      location
      type
      created_at
      country
      city
      partner {
        id
        name
      }
      project_courses {
        id
        course_id
        course {
          id
          full_name
        }
      }
    }
  }
`;

export const GET_COURSES = gql`
  query GetCourses {
    courses_course {
      id
      full_name
    }
  }
`;

export const GET_PARTNER_COURSES = gql`
  query getPartnerCourses($partnerIds: [bigint!]!) {
    courses_course(
      where: {
        _or: [
          {
            partner_revenue_split_directives: {
              partner_id: { _in: $partnerIds }
            }
          }
          {
            criteria_courses: {
              criteria: { coupon: { partner_id: { _in: $partnerIds } } }
            }
          }
        ]
      }
    ) {
      id
      full_name
    }
  }
`;

export const GET_PROJECTS_BY_PARTNER = gql`
  query MyQuery($partner_id: bigint = "") {
    courses_partner_projects(where: { partner_id: { _eq: $partner_id } }) {
      id
      name
    }
  }
`;

export const GET_PROJECTS_WITH_FILTER = gql`
  query MyQuery($filterClause: courses_partner_projects_bool_exp!) {
    courses_partner_projects(where: $filterClause) {
      id
      name
      partner_id
    }
  }
`;
