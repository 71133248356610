import "grapesjs/dist/css/grapes.min.css";
import _ from "lodash";
import { Form } from "skillstrainer-resource-library";
import Container from "components/Container/Container";
import BulkNotificationsFormBuilder from "formBuilders/BulkNotificationsFormBuilder";
import Axios from "../../../api/Api";
import React from "react";
import { secretForBulkNotification } from "../../../api/Consts";

function CreateNotificationBatches() {
  const handleBulkNotification = async (data) => {
    data["secret"] = secretForBulkNotification;
    try {
      const response = await Axios().post("/bulk_notifications", { data });
    } catch (err){
      console.log('error', err);
    }
  };

  return (
    <Container title={"Create Bulk Notification"}>
      <Form
        className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
        submitButton={{
          text: "Create batch",
          className: `btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6`,
        }}
        formBuilder={BulkNotificationsFormBuilder}
        onSubmit={handleBulkNotification}
      />
    </Container>
  );
}

export default CreateNotificationBatches;