import React, { useEffect, useState } from "react";
import Checkbox from "components/InputGroup/Checkbox";
import Select from "components/InputGroup/Select";
import TextArea from "components/InputGroup/TextArea";
import Container from "components/Container/Container";

import Input from "components/InputGroup/Input";
import { QuizFormDbSchema } from "./ActivityDbSchema";
import { default as ReactSelect } from "react-select";
import { useHistory } from "react-router-dom";
import { errorHandler } from "utils/toast";

const QuizForm = ({
  submitQuiz,
  criteriaOption,
  updateValue,
  message,
  quizData,
  updateCheckbox,
  handleChange,
  topic,
  restriction,
  Activity,
  timer,
  action,
  defaultRestriction,
  selectedValue,
  lessonName,
}) => {
  let history = useHistory();

  console.log("quizData", quizData);
  const [requireFields, setRequireFields] = useState({
    name: true,
    description: true,
    completion_criteria: true,
    completion_percentage: false,
    restriction_modules: false,
    maxattempt: true,
    attemtsgrade: true,
    timelimit: false,
    timelimit_unit: false,
  });

  const [errorField, seterrorField] = useState({});

  useEffect(() => {
    if (quizData) {
      checkValidation();
    }
  }, [quizData, selectedValue]);

  //Check Validation

  const checkValidation = (e) => {
    Object.keys(requireFields).map((fieldName) => {
      if (quizData[fieldName] !== 0) {
        seterrorField((prevState) => ({
          ...prevState,
          [fieldName]:
            !quizData[fieldName] || quizData[fieldName] < 0 ? true : false,

          completion_percentage:
            quizData["completion_criteria"] == 1 &&
            !quizData["completion_percentage"]
              ? true
              : false,
          restriction_modules:
            quizData["enable_restriction"] && !selectedValue.length > 0
              ? true
              : false,
          timelimit:
            quizData["enable_timer"] && !quizData["timelimit"] ? true : false,
          timelimit_unit:
            (quizData["enable_timer"] && !quizData["timelimit_unit"]) ||
            quizData["timelimit_unit"] == -1
              ? true
              : false,
          description: false,
        }));
      }

      if (quizData[fieldName] === 0) {
        seterrorField((prevState) => ({
          ...prevState,
          [fieldName]: false,
        }));
      }
    });

    return true;
  };

  const submit = (e, status) => {
    e.preventDefault();

    if (checkValidation()) {
      const hh = Object.keys(errorField).find(
        (key) => errorField[key] === true
      );

      if (hh) {
        errorHandler("Please fill all required fields");
      } else {
        submitQuiz(status);
      }
    }
  };

  console.log("errorField", errorField);
  return (
    <Container>
      <button
        className="inline-flex justify-center py-2 px-4 
		border border-transparent shadow-sm text-sm font-medium 
		rounded-md text-white bg-orange"
        onClick={() => history.goBack()}
      >
        Go To Back
      </button>

      <div class="">
        <h1 class="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          {lessonName}
        </h1>
      </div>

      <form>
        <div className="p-2">
          <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
            {action == "update" ? "Update" : ""} Quiz Activity
          </h1>
        </div>

        <div className="shadow sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="flex flex-wrap">
              {Object.keys(QuizFormDbSchema).map((course_key) => {
                if (course_key == 1) {
                  return (
                    <div className="mb-2 w-full">
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>General</div>
                        </div>
                        <div className="accordion-content p-3 w-full flex flex-row">
                          {QuizFormDbSchema[course_key].map(
                            ({ label, column_name, type }) => (
                              <div className="mt-4 w-6/12">
                                {type === "text" && (
                                  <Input
                                    label={label}
                                    type={type}
                                    data-key={column_name}
                                    onChange={updateValue}
                                    value={quizData[column_name]}
                                    requiredField={
                                      requireFields[column_name] ? "yes" : "no"
                                    }
                                    name={column_name}
                                    error={
                                      errorField[column_name] ? "yes" : "no"
                                    }
                                  />
                                )}

                                {type === "textarea" && (
                                  <TextArea
                                    label={"Description"}
                                    data-key={column_name}
                                    onChange={updateValue}
                                    value={quizData[column_name]}
                                    requiredField={"no"}
                                    name={column_name}
                                    error={
                                      errorField[column_name] ? "yes" : "no"
                                    }
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {Object.keys(QuizFormDbSchema).map((course_key) => {
                if (course_key == 2) {
                  return (
                    <div className="mb-2 w-full">
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>Activity completion</div>
                        </div>
                        <div className="accordion-content w-full flex flex-row p-3 ">
                          <div className="w-6/12">
                            {QuizFormDbSchema[course_key].map(
                              ({ label, column_name, type, options }) => (
                                <div className="mt-4">
                                  {type == "select" && (
                                    <Select
                                      label={label}
                                      options={options}
                                      valueField={"value"}
                                      displayField={"label"}
                                      data-key={column_name}
                                      onChange={(e) => updateValue(e)}
                                      value={quizData[column_name]}
                                      requiredField={
                                        requireFields[column_name]
                                          ? "yes"
                                          : "no"
                                      }
                                      name={column_name}
                                      error={
                                        errorField[column_name] ? "yes" : "no"
                                      }
                                    />
                                  )}

                                  {Activity && type === "text" && (
                                    <Input
                                      label={label}
                                      type={type}
                                      data-key={column_name}
                                      onChange={updateValue}
                                      value={quizData[column_name]}
                                      requiredField={
                                        requireFields[column_name]
                                          ? "yes"
                                          : "no"
                                      }
                                      name={column_name}
                                      error={
                                        errorField[column_name] ? "yes" : "no"
                                      }
                                    />
                                  )}
                                </div>
                              )
                            )}
                          </div>
                          <div className="w-6/12">
                            {QuizFormDbSchema[course_key].map(
                              ({ label, column_name, type, label_text }) => (
                                <div className="mt-16 pl-10 " key={course_key}>
                                  {type == "checkbox" &&
                                    column_name === "enable_user_attempt" && (
                                      <Checkbox
                                        label={label}
                                        data-key={column_name}
                                        onChange={updateCheckbox}
                                        checked={quizData[column_name]}
                                        value={column_name}
                                        requiredField={
                                          requireFields[column_name]
                                            ? "yes"
                                            : "no"
                                        }
                                        name={column_name}
                                        error={
                                          errorField[column_name] ? "yes" : "no"
                                        }
                                      />
                                    )}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {Object.keys(QuizFormDbSchema).map((course_key) => {
                if (course_key == 3) {
                  return (
                    <div className="mb-2 w-full">
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>Restrict access </div>
                        </div>
                        <div className="accordion-content p-3 w-full">
                          {QuizFormDbSchema[course_key].map(
                            ({ label, column_name, type, options }) => (
                              <div className="mt-4">
                                {type === "checkbox" && (
                                  <Checkbox
                                    label={label}
                                    data-key={column_name}
                                    onChange={updateCheckbox}
                                    checked={quizData[column_name]}
                                  />
                                )}

                                {restriction && type == "multipleselection" && (
                                  <div>
                                    <p className="pl-5 label_text">
                                      {label}

                                      <span className="text-red-600 text-lg	">
                                        *
                                      </span>
                                    </p>

                                    <ReactSelect
                                      placeholder={label}
                                      options={
                                        action == "update" && topic
                                          ? topic
                                          : action == "new" && topic.topic
                                          ? topic.topic
                                          : []
                                      }
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      allowSelectAll={true}
                                      onChange={handleChange}
                                      defaultValue={
                                        action == "update" &&
                                        defaultRestriction.length > 0
                                          ? defaultRestriction
                                          : []
                                      }
                                    />
                                    {errorField[column_name] ? (
                                      <span className="border-red-600 text-md text-red-600">
                                        Please select atleast one option
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {Object.keys(QuizFormDbSchema).map((course_key) => {
                if (course_key == 4) {
                  return (
                    <div className="mb-2 mt-4 w-full">
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>Attemts Management Section</div>
                        </div>
                        <div className="accordion-content p-3 w-full">
                          {QuizFormDbSchema[course_key].map(
                            ({ label, column_name, type, options }) => (
                              <div className="mt-4">
                                {type == "select" && (
                                  <Select
                                    label={label}
                                    options={options}
                                    valueField={"value"}
                                    displayField={"label"}
                                    data-key={column_name}
                                    onChange={(e) => updateValue(e)}
                                    value={quizData[column_name]}
                                    requiredField={
                                      requireFields[column_name] ? "yes" : "no"
                                    }
                                    name={column_name}
                                    error={
                                      errorField[column_name] ? "yes" : "no"
                                    }
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {Object.keys(QuizFormDbSchema).map((course_key) => {
                if (course_key == 5) {
                  return (
                    <div className="mb-2 mt-4 w-full">
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>Time Management</div>
                        </div>
                        <div className="accordion-content p-3 w-full flex">
                          {QuizFormDbSchema[course_key].map(
                            ({ label, column_name, type, options }) => (
                              <div className="mt-4">
                                {type === "checkbox" && (
                                  <Checkbox
                                    label={label}
                                    data-key={column_name}
                                    onChange={updateCheckbox}
                                    checked={quizData[column_name]}
                                  />
                                )}

                                {timer && type === "text" && (
                                  <Input
                                    label={label}
                                    type={type}
                                    data-key={column_name}
                                    onChange={updateValue}
                                    value={quizData[column_name]}
                                    placeholder="Enter Time Number"
                                    requiredField={
                                      requireFields[column_name] ? "yes" : "no"
                                    }
                                    name={column_name}
                                    error={
                                      errorField[column_name] ? "yes" : "no"
                                    }
                                  />
                                )}

                                {timer && type == "select" && (
                                  <Select
                                    label={label}
                                    options={options}
                                    valueField={"value"}
                                    displayField={"label"}
                                    data-key={column_name}
                                    onChange={(e) => updateValue(e)}
                                    value={quizData[column_name]}
                                    requiredField={
                                      requireFields[column_name] ? "yes" : "no"
                                    }
                                    name={column_name}
                                    error={
                                      errorField[column_name] ? "yes" : "no"
                                    }
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            {message && (
              <div className="text-green-600 text-base mt-2">{message}</div>
            )}
          </div>

          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange"
              //   onClick={(e) => submitQuiz(e, { return: true })}
              onClick={(e) => submit(e, { return: true })}
            >
              {action == "update" ? "Update & Return" : "Save & Return"}
            </button>

            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange"
              //   onClick={(e) => submitQuiz(e, { return: false })}
              onClick={(e) => submit(e, { return: false })}
            >
              {action == "update"
                ? "Update & Manage Questions"
                : "Save & Add Questions"}
            </button>
          </div>
        </div>
      </form>
    </Container>
  );
};

export default QuizForm;
