import { gql } from "@apollo/client";
export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation updateEmailTemplate($id: Int, $email_template: jsonb) {
    update_courses_notification_event_types(
      where: { id: { _eq: $id } }
      _set: { email_template: $email_template }
    ) {
      returning {
        id
      }
    }
  }
`;
