import React from "react";

const Checkbox = ({ label, className, ...inputProps }) => {
  return (
    <div className={`mx-4 ${className}`}>
      <div className="flex items-center">
        <input
          {...inputProps}
          type="checkbox"
          className="h-7 w-7 text-orange focus:ring-0 focus:ring-offset-0 focus:border-0 rounded"
        ></input>
        <label
          htmlFor="comments"
          className="ml-3 text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      </div>
      {inputProps.error == "yes" ? (
        <span className="border-red-600 text-md text-red-600">
          Please check one or more
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Checkbox;
