import { gql } from "@apollo/client";

export const CREATE_COURSE_PLANS = gql`
  mutation CreateSubscriptionPlan(
    $course_id: Int
    $interval: Int
    $period: String
    $razorpay_plan_creation_date: Int
    $razorpay_plan_id: String
    $subscription_cost: Int
    $current_plan: Boolean
  ) {
    insert_courses_course_subscription_plans(
      objects: {
        course_id: $course_id
        interval: $interval
        period: $period
        razorpay_plan_creation_date: $razorpay_plan_creation_date
        razorpay_plan_id: $razorpay_plan_id
        subscription_cost: $subscription_cost
        current_plan: $current_plan
      }
    ) {
      returning {
        razorpay_plan_id
        razorpay_plan_creation_date
        course_id
        id
      }
    }
  }
`;

export const UPDATE_COURSE_SUBSCRIPTION = gql`
  mutation MyMutation($course_id: Int, $current_plan: Boolean) {
    update_courses_course_subscription_plans(
      where: { course_id: { _eq: $course_id } }
      _set: { current_plan: $current_plan }
    ) {
      returning {
        course_id
        period
      }
    }
  }
`;
