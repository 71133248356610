import { Form } from "skillstrainer-resource-library";
import QualificationFormBuilder from "formBuilders/QualificationFormBuilder";
import { useMutation, useLazyQuery } from "@apollo/client";
import QualificationsTable from "./QualificationsTable";
import { useState, useEffect } from "react";
import uuid from "react-uuid";
import {
  CREATE_QUALIFICATIONS,
  UPDATE_QUALIFICATION,
} from "../../../GraphQl/Mutations/Jobs";
import {
  GET_QUALIFICATION_BY_ID,
  GET_QUALIFICATION_SEARCH,
} from "../../../GraphQl/Queries/Jobs";
import { errorHandler, successHandler } from "utils/toast";
import { client } from "App";
import { qualificationTypeSchema } from "./JobSchema";
import Input from "components/InputGroup/Input";
import Select from "components/InputGroup/Select";
import Container from "components/Container/Container";

function CreateQualification() {
  const [insertQualification] = useMutation(CREATE_QUALIFICATIONS, {
    refetchQueries: [GET_QUALIFICATION_SEARCH],
  });

  const [selectedQualification, setSelectedQualification] = useState({});

  const [isUpdate, setIsUpdate] = useState(false);
  const [insertId, setInsertId] = useState();

  const queryParametersId = new URLSearchParams(window.location.search);

  let currentQualificationId = queryParametersId.get("id");

  if (insertId) {
    currentQualificationId = insertId;
  }

  const [search, setSearch] = useState({ title: "", type: "" });

  const [runQuery, { loading: qualificationLoading, data: qualificationData }] =
    useLazyQuery(GET_QUALIFICATION_SEARCH, { fetchPolicy: "network-only" });

  useEffect(() => {
    client
      .query({
        query: GET_QUALIFICATION_BY_ID,
        variables: {
          id: currentQualificationId,
        },
      })
      .then((res) => {
        setSelectedQualification(res.data.courses_qualifications_lookup[0]);
      });
    if (currentQualificationId) {
      setIsUpdate(true);
    }
  }, [currentQualificationId]);

  let qualificationCombineUuidCode = "";

  const createQualification = async (qualification) => {
    qualificationTypeSchema.map((res) => {
      if (qualification.type === res.value) {
        qualificationCombineUuidCode = res.value + "/" + uuid();
      }
    });

    const insertQualificationObject = {
      ...qualification,
      code: qualificationCombineUuidCode,
    };

    insertQualification({ variables: insertQualificationObject })
      .then((res) => {
        console.log("res===", res);
        setInsertId(res?.data?.insert_courses_qualifications_lookup_one.id);
        successHandler("New Qualification Created Successfully");
      })
      .catch((err) => {
        errorHandler("There was an error while creating a New Qualification");
      });
  };

  const [update_qualification, mutatioData] = useMutation(
    UPDATE_QUALIFICATION,
    {
      refetchQueries: [GET_QUALIFICATION_SEARCH],

      onCompleted: () => successHandler("Qualification Successfully Updated"),
      onError: (err) =>
        errorHandler("An error occured while updating a Qualification") ||
        console.error(err),
    }
  );

  const updateQualification = async (qualification) => {
    const updateQualificationObj = {
      ...qualification,
      id: currentQualificationId,
    };

    update_qualification({
      variables: updateQualificationObj,
    });
  };

  const updateSelectedQualification = (selectedQualification) => {
    setIsUpdate(true);

    const qualificationObj = {
      ...selectedQualification,
      name: selectedQualification.name,
      label: selectedQualification.label,
    };

    setSelectedQualification(qualificationObj);
  };
  useEffect(() => {
    SearchUsers();
  }, []);

  const SearchUsers = (e) => {
    let variables = {
      where: {
        _and: [
          {
            name: { _ilike: `%${search.title}%` },
          },
          {
            type: { _ilike: `%${search.type}%` },
          },
        ],
      },
    };
    runQuery({ variables: variables });
  };

  return (
    <Container
      title={"Create/Update Qualifications"}
      className="ml-6 mr-12 mt-4 mb-8"
    >
      <Form
        formBuilder={QualificationFormBuilder}
        className="mt-2 grid grid-cols-2 gap-x-6"
        submitButton={{
          text: "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
        }}
        onSubmit={
          !isUpdate
            ? (qualification) => createQualification(qualification)
            : (qualification) => updateQualification(qualification)
        }
        key={selectedQualification?.id}
        initValues={selectedQualification || {}}
      />
      <div className="d-flex mt-5 pt-3">
        <Input
          label={"Search By Name"}
          value={search.title}
          onChange={(e) => {
            setSearch({ ...search, title: e.target.value });
          }}
          type={"text"}
          placeholder={"Enter Qualification Name"}
        />
        <Select
          label="Choose Qualification Type"
          className="pl-5"
          options={qualificationTypeSchema}
          displayField={"name"}
          onChange={(e) => {
            setSearch({ ...search, type: e.target.value });
          }}
        />
        <button className="btn-primary my-4 ml-5" onClick={SearchUsers}>
          Search
        </button>
      </div>

      {!qualificationLoading && qualificationData ? (
        <QualificationsTable
          qualificationData={qualificationData.courses_qualifications_lookup}
          updateSelectedSkills={(selectedQualifications) => {
            updateSelectedQualification(selectedQualifications);
          }}
        />
      ) : (
        <>
          <p>Please wait, fetching projects...</p>
        </>
      )}
    </Container>
  );
}

export default CreateQualification;
