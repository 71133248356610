import * as yup from "yup";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_COURSE_CATEGORY } from "GraphQl/Queries/Jobs";
import { qualificationTypeSchema } from "../Pages/Dashboard/Jobs/JobSchema";

function QualificationFormBuilder(values) {
  const { data: sectorData } = useQuery(GET_COURSE_CATEGORY);

  const [schema, setSchema] = useState({});

  useEffect(() => {
    const schema = {
      name: {
        label: "Qualification Name",
        type: "text",
        required: true,
      },
      type: {
        label: "Qualification Type",
        type: "select",
        required: true,
        options: qualificationTypeSchema?.map((type) => ({
          label: type.name,
          value: type.value,
        })),
        schema: yup.string(),
      },
      nsqf_level: {
        type: "hidden",
      },
      qp_code: {
        type: "hidden",
      },
      sector_id: {
        label: "Sector",
        type: "select",
        options: sectorData?.courses_course_categories.map((sector) => ({
          label: sector.name,
          value: sector.id,
        })),
        schema: yup.string(),
      },
    };

    if (values.type === "nsqf") {
      schema.qp_code = { label: "QP Code", type: "text" };
      schema.nsqf_level = { label: "NSQF Level", type: "text" };
    }
    if (values.type === "iti") {
      schema.nsqf_level = { label: "NSQF Level", type: "text" };
    }

    setSchema(schema);
  }, [values, sectorData]);

  return schema;
}

export default QualificationFormBuilder;
