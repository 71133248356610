import React, { useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { GET_ALL_SCHOLARSHIP_DATA_BY_SCHOLARSHIP_ID, GET_SCHOLARSHIPS_BY_PARTNER } from "GraphQl/Queries/Scholarships";
import Select from "react-select";
import ScholarshipsFilterTableComponent from "./ScholarshipsFilterTableComponent";
import scholarshipData from "./sampleScholarshipData.json";
import MultiSelectDropdown from "components/InputGroup/MultiselectDropdown";
import Loader from "assets/animated-images/loader";
import { errorHandler } from "utils/toast";
import { CSVLink } from "react-csv";
import { GET_PARTNERS } from "GraphQl/Queries/Partner";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";
import { Container } from "react-bootstrap";

function ScholarShipTableComponent(props) {
  const partnerAccess = useUserPartnerAccessDetails();
  const partners = useQuery(GET_PARTNERS);
  console.log("paetners", partners);
  const [getScholarships, searchedScholarships] = useLazyQuery(
    GET_SCHOLARSHIPS_BY_PARTNER
  );
  const [loader, setLoader] = useState(false);
  const [filtersOfGroupedData, setFiltersOfGroupedData] = useState();

  console.log("filtersOfGroupedData", filtersOfGroupedData);
  const [selections, setSelections] = useState({
    selectedScholarship: [],
    selectedCourses: [],
    selectedDistricts: [],
    selectedYears: [],
    selectedInstitutions: [],
    selectedGroupBy: [],
    selectedGender: [],
  });

  const [allFilters, setAllFilters] = useState({
    course_id: [],
    year: [],
    school: [],
    district: [],
    selected_years: [],
    genders: [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
    ],
    group_by: [
      { value: "Student Wise(Count)", label: "Student Wise(Count)" },
      { value: "Course(Count)", label: "Course(Count)" },
      { value: "Year(Count)", label: "Year(Count)" },
      { value: "School Wise(Count)", label: "School Wise(Count)" },
      { value: "Gender Wise(Count)", label: "Gender Wise(Count)" },
      { value: "District Wise(Count)", label: "District Wise(Count)" },
    ],
  });

  function filterData(selections, data) {
    let filteredData = data;
    try {
      // Filter by year if selectedGender is not empty
      if (selections.selectedYears.length > 0) {
        filteredData = filteredData.filter((user) => {
          // Extract the year from 'selected_at' if it's not null
          const selectedYear = user.selected_at
            ? new Date(user.selected_at).getFullYear()
            : null;
          return String(selections.selectedYears).includes(
            selectedYear?.toString()
          );
        });
      }

      // Convert selectedGender to lowercase for case-insensitive comparison
      const lowerCaseSelectedGender = selections.selectedGender.map((gender) =>
        gender
      );

      // Filter by gender if selectedGender is not empty
      if (lowerCaseSelectedGender.length > 0) {
        filteredData = filteredData.filter((user) =>
          lowerCaseSelectedGender.includes(user?.user?.gender)
        );
      }

      // Filter by courses (qualification_name) if selectedCourses is not empty
      if (selections.selectedCourses.length > 0) {
        filteredData = filteredData.filter((user) =>
          user.user.user_academic_qualifications.some((qualification) =>
            selections.selectedCourses.includes(
              qualification.qualification_name
            )
          )
        );
      }

      // Filter by districts if selectedDistricts is not empty
      if (selections.selectedDistricts.length > 0) {
        filteredData = filteredData.filter((user) =>
          selections.selectedDistricts.includes(user.user.address.district)
        );
      }

      // You would filter based on the 'institution_name'
      if (selections.selectedInstitutions.length > 0) {
        filteredData = filteredData.filter((user) =>
          user.user.user_academic_qualifications.some((qualification) =>
            selections.selectedInstitutions.includes(
              qualification.institution_name
            )
          )
        );

        console.log("inside institution", filteredData);
      }

      // Return the filtered data
      return filteredData;
    } catch (err) {
      console.log("i am err", err);
    }
  }
  function aggregateDataFunc(selectedGroupBy, data) {
    let result = [];
    let totalContribution = 0;
    let totalCount = 0;

    try {
      switch (selectedGroupBy) {
        case "School Wise(Count)":
          // Aggregate by institution name within user_academic_qualifications
          const schoolMap = {};
          data.forEach((item) => {
            item.user.user_academic_qualifications.forEach((qualification) => {
              const schoolName = qualification.institution_name || "NA";
              if (!schoolMap[schoolName]) {
                schoolMap[schoolName] = { count: 0, contribution: 0 };
              }
              schoolMap[schoolName].count++;
              schoolMap[schoolName].contribution += item.contribution || 0;
              totalContribution += item.contribution || 0;
            });
          });
          result = Object.entries(schoolMap).map(
            ([schoolName, { count, contribution }]) => ({
              schoolName,
              count,
              contribution,
            })
          );
          totalCount = data.length;
          result.push({
            schoolName: "Total",
            count: totalCount,
            contribution: totalContribution,
          });
          break;

        case "Gender Wise(Count)":
          // Aggregate by gender
          const genderMap = {};
          data.forEach((item) => {
            const gender = item.user.gender || "NA";
            if (!genderMap[gender]) {
              genderMap[gender] = { count: 0, contribution: 0 };
            }
            genderMap[gender].count++;
            genderMap[gender].contribution += item.contribution || 0;
            totalContribution += item.contribution || 0;
          });
          result = Object.entries(genderMap).map(
            ([gender, { count, contribution }]) => ({
              gender,
              count,
              contribution,
            })
          );
          totalCount = data.length;
          result.push({
            gender: "Total",
            count: totalCount,
            contribution: totalContribution,
          });
          break;

        case "District Wise(Count)":
          // Aggregate by district
          const districtMap = {};
          data.forEach((item) => {
            const district = item?.user?.address?.district || "NA";
            if (!districtMap[district]) {
              districtMap[district] = { count: 0, contribution: 0 };
            }
            districtMap[district].count++;
            districtMap[district].contribution += item.contribution || 0;
            totalContribution += item.contribution || 0;
          });
          result = Object.entries(districtMap).map(
            ([district, { count, contribution }]) => ({
              district,
              count,
              contribution,
            })
          );
          totalCount = data.length;
          result.push({
            district: "Total",
            count: totalCount,
            contribution: totalContribution,
          });
          break;
        case "Student Wise(Count)":
          // Aggregate by student name
          data.forEach((item) => {
            result.push({
              name: item.user?.name,
              selected_at: item.selected_at,
              gender: item.user?.gender,
              contribution: item.contribution || 0,
            });
            totalContribution += item.contribution || 0;
          });
          result.push({ name: "Total", contribution: totalContribution });
          break;

        case "Course(Count)":
          // Aggregate by course (qualification_name)
          const courseMap = {};
          data.forEach((item) => {
            item.user.user_academic_qualifications.forEach((qualification) => {
              if (!courseMap[qualification.qualification_name]) {
                courseMap[qualification.qualification_name] = {
                  count: 0,
                  contribution: 0,
                };
              }
              courseMap[qualification.qualification_name].count++;
              courseMap[qualification.qualification_name].contribution +=
                item.contribution || 0;
              totalContribution += item.contribution || 0;
            });
          });
          result = Object.entries(courseMap).map(
            ([courseName, { count, contribution }]) => ({
              courseName,
              count,
              contribution,
            })
          );
          totalCount = data.length;
          result.push({
            courseName: "Total",
            count: totalCount,
            contribution: totalContribution,
          });
          break;

        case "Year(Count)":
          // Aggregate by year extracted from selected_at
          const yearMap = {};
          console.log("result");

          data.forEach((item) => {
            const year = item.selected_at
              ? new Date(item.selected_at).getFullYear()
              : "NA";
            if (!yearMap[year]) {
              yearMap[year] = { count: 0, contribution: 0 };
            }
            yearMap[year].count++;
            yearMap[year].contribution += item.contribution || 0;
            totalContribution += item.contribution || 0;
          });
          result = Object.entries(yearMap).map(
            ([year, { count, contribution }]) => ({
              year,
              count,
              contribution,
            })
          );
          totalCount = data.length;
          result.push({
            year: "Total",
            count: totalCount,
            contribution: totalContribution,
          });

          console.log("result", result);
          break;
      }

      return result;
    } catch (err) {
      console.log("i a err", err);
    }
  }

  const [getScholarshipsByIds, searchedScholarshipsByIds] = useLazyQuery(
    GET_ALL_SCHOLARSHIP_DATA_BY_SCHOLARSHIP_ID
  );

  const filterStats = async () => {
    try {
      console.log(selections);
      if (selections.selectedGroupBy.length <= 0) {
        return errorHandler("Please select group by");
      }
      const selectedGroupByValue = selections?.selectedGroupBy?.value;
      setLoader(true);
      const getScholarshipData = await getScholarshipsByIds({
        variables: { scholarshipId: selections.selectedScholarship },
      });
      const filteredData = await filterData(
        selections,
        getScholarshipData.data.courses_scholarship_partner_user
      );

      const aggregateData = await aggregateDataFunc(
        selectedGroupByValue,
        filteredData
      );
      setLoader(false);
      console.log("filteredData", filteredData);
      console.log("aggregateData", aggregateData);
      setFiltersOfGroupedData(aggregateData);
      // } else {
      //   console.log("Data not fetched");
      // }
    } catch (err) {
      console.log("i am err", err);
    }
  };

  useEffect(() => {
    const partnerIdArray = partners?.data?.courses_partner?.map(data => data.id)
    if (partnerAccess && partnerAccess.allPartnerAccess !== true) {
      getScholarships({
        variables: { _partner_id: partnerAccess.partnerIds },
      });
    } else {
      console.log("partnerIdArray", partnerIdArray);
      getScholarships({
        variables: { _partner_id: partnerIdArray },
      });
    }
  }, [partnerAccess, partners?.data])


  useEffect(() => {
    const courseIdentifierSet = new Set();
    const districtSet = new Set();
    const institutionSet = new Set();
    const uniqueYearsSet = new Set();
    const scholarshipFilteredData = scholarshipData;
    scholarshipFilteredData?.forEach((data) => {
      data?.user?.user_academic_qualifications?.forEach((qualification) => {
        const {
          qualification_id: courseId,
          qualification_name: courseName,
          institution_name,
        } = qualification;

        // Courses
        if (courseId && courseName) {
          const identifier = `${courseId}-${courseName}`;
          courseIdentifierSet.add(identifier);
        }

        // Institutions
        if (institution_name && institution_name.trim() !== "") {
          institutionSet.add(institution_name);
        }
      });

      // Districts
      const district = data?.user?.address?.district;
      if (district) districtSet.add(district);

      // Years
      const selectedAt = data?.selected_at;
      if (selectedAt) {
        const year = new Date(selectedAt).getFullYear();
        uniqueYearsSet.add(year);
      }
    });

    // Convert Sets to desired format
    const uniqueCourses = Array.from(courseIdentifierSet).map((identifier) => {
      const [value, label] = identifier.split("-");
      return {
        value: label,
        label: label,
      };
      // return { value, label };
    });

    const uniqueDistricts = Array.from(districtSet).map((district) => ({
      value: district,
      label: district,
    }));
    const uniqueInstitutions = Array.from(institutionSet).map((name) => ({
      value: name,
      label: name,
    }));
    const uniqueYears = Array.from(uniqueYearsSet).map((year) => ({
      value: year,
      label: year.toString(),
    }));

    setAllFilters({
      ...allFilters,
      course_id: uniqueCourses,
      district: uniqueDistricts,
      institution_name: uniqueInstitutions,
      selected_years: uniqueYears,
    });
  }, [scholarshipData]);
  const handleSelectionChange = (key, value) => {
    console.log(key, value);
    setSelections((prevSelections) => ({
      ...prevSelections,
      [key]: value,
    }));
  };
  return (
    <Container>
      <div className="my-4">
        {searchedScholarships.data?.courses_scholarships && (
          <div>
            <div className="grid grid-cols-2 gap-x-2">
              <MultiSelectDropdown
                label={"Select Scholarship"}
                options={searchedScholarships.data?.courses_scholarships.map(
                  (k) => ({
                    value: k.id,
                    label: k.title,
                  })
                )}
                valueField="value"
                displayField="label"
                selectedValue={selections.selectedScholarship}
                setSelectedValue={(value) =>
                  handleSelectionChange("selectedScholarship", value)
                }
              />
            </div>
            {selections.selectedScholarship.length > 0 && (
              <div>
                <div className="grid grid-cols-2 gap-x-2 mt-3">
                  {/* Selected Years Dropdown */}
                  <MultiSelectDropdown
                    label="Select Year"
                    options={allFilters.selected_years}
                    valueField="value"
                    displayField="label"
                    selectedValue={selections.selectedYears}
                    setSelectedValue={(value) =>
                      handleSelectionChange("selectedYears", value)
                    }
                  />
                  {/* Institution Name Dropdown */}
                  <MultiSelectDropdown
                    label="Select Institution"
                    options={allFilters.institution_name}
                    valueField="value"
                    displayField="label"
                    selectedValue={selections.selectedInstitutions}
                    setSelectedValue={(value) =>
                      handleSelectionChange("selectedInstitutions", value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2 gap-x-2 mt-3">
                  {/* District Dropdown */}
                  <MultiSelectDropdown
                    label="Select District"
                    options={allFilters.district}
                    valueField="value"
                    displayField="label"
                    selectedValue={selections.selectedDistricts}
                    setSelectedValue={(value) =>
                      handleSelectionChange("selectedDistricts", value)
                    }
                  />
                  {/* Course ID Dropdown */}
                  <MultiSelectDropdown
                    label="Select Course"
                    options={allFilters.course_id}
                    valueField="value"
                    displayField="label"
                    selectedValue={selections.selectedCourses}
                    setSelectedValue={(value) =>
                      handleSelectionChange("selectedCourses", value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2 gap-x-2 mt-3">
                  {/* Genders Dropdown */}
                  <MultiSelectDropdown
                    label="Select Genders"
                    options={allFilters.genders}
                    valueField="value"
                    displayField="label"
                    selectedValue={selections.selectedGender}
                    setSelectedValue={(value) =>
                      handleSelectionChange("selectedGender", value)
                    }
                  />
                  <div>
                    <label>Select Group By</label>
                    <Select
                      label={""}
                      required={true}
                      options={allFilters.group_by}
                      value={selections.selectedGroupBy}
                      valueField="value"
                      displayField="label"
                      selectedValue={selections.selectedGroupBy}
                      onChange={(e) => {
                        handleSelectionChange("selectedGroupBy", e);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <button
              className="btn-primary mt-4 ml-4 hover:opacity-70 mr-3"
              onClick={filterStats}
            >
              Filter
            </button>
            {
              filtersOfGroupedData && filtersOfGroupedData.length > 0 && (
                <CSVLink data={filtersOfGroupedData} filename={"scholarship_data.csv"}>
                  <button className="btn-primary">Download CSV</button>
                </CSVLink>
              )
            }

            {loader && (
              <div className="flex w-full justify-center">
                <Loader color="#999" style={{ height: "50px" }} />
              </div>
            )}

            {filtersOfGroupedData && (
              <ScholarshipsFilterTableComponent
                scholarshipData={filtersOfGroupedData}
              />
            )}

            <br />
          </div>
        )}
      </div>
    </Container>
  );
}

export default ScholarShipTableComponent;
