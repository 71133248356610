import React, {useState} from 'react'
import Modal from "react-bootstrap/Modal";

import Input from '../../components/InputGroup/Input';
import {api} from '../../api/User';



function ResetPassword(props) {
    const [emailId, setEmailId] = useState('');

    const [hasError, setHasError] = useState(false);
    const [message, setMessage] = useState('');

    const sendResetPasswordLink = async () => {
       const response = await api('send_password_reset_link', {
        'emailId': emailId
       })

       setHasError(!response.success)
       setMessage(response.data.message);
    }

    return (
    <div>
        <Modal
            size="md"
            show={props.showModal}
            onHide={() => props.closeModal()}
            aria-labelledby="example-modal-sizes-title-lg"

        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Reset Password
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="">
                    <div className="w-full">
                        <Input
                            label="Enter Email-Id"
                            type={"text"}
                            data-key="email_id"
                            className={``}
                            onChange={(e) => setEmailId(e.target.value)}
                            value={emailId}
                        ></Input>
                    </div>
                    <div className="flex w-full justify-center">
                        <button
                            onClick={(e) => sendResetPasswordLink(e)}
                            className="w-80 self-center  h-10 border-r-4 bg-blue-600 text-1xl text-white mt-1"
                        >
                            Get password reset link
                        </button>
                    </div>
                    {message.length > 0 && <div 
                        className={`${hasError === true ? 'text-red-600': 'text-green-900'} mt-2`}>
                            {message}
                    </div>}
                </div>
            </Modal.Body>    
        </Modal>
    </div>
    )
}

export default ResetPassword