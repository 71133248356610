import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ViewCourseModal(props) {
    return (
        <div>
            <Modal
                size="lg"
                show={props.show}
                onHide={() => props.handleClose()}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Courses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {props.courses.map((course) => {
                            return (
                                <div>
                                    {course.courses && course.courses.full_name}
                                </div>
                            )
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ViewCourseModal
