import { gql } from "@apollo/client";

export const UPDATE_USER_BY_ID = gql`
  mutation updateUserById(
    $id: bigint
    $name: String
    $active: Boolean
    $email: String
    $partner_id: bigint
    $instructor_id: bigint
    $mobile_number: String
    $activation_end_date: timestamptz
    $activation_start_date: timestamptz
  ) {
    update_courses_users(
      where: { id: { _eq: $id } }
      _set: {
        active: $active
        email: $email
        name: $name
        partner_id: $partner_id
        instructor_id: $instructor_id
        mobile_number: $mobile_number
        activation_end_date: $activation_end_date
        activation_start_date: $activation_start_date
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const ATTACH_USERS_TO_PARTNER = gql`
  mutation attachUsersToPartner(
    $objects: [courses_partner_users_insert_input!]!
  ) {
    insert_courses_partner_users(
      objects: $objects
      on_conflict: { constraint: partner_users_partner_id_user_id_key }
    ) {
      returning {
        id
        user_id
        partner_id
      }
    }
  }
`;

export const DETACH_USERS_FROM_PARTNER = gql`
  mutation attachUsersToPartner($partnerIds: [bigint!]!, $userIds: [bigint!]!) {
    delete_courses_partner_users(
      where: { partner_id: { _in: $partnerIds }, user_id: { _in: $userIds } }
    ) {
      affected_rows
    }
  }
`;

export const ATTACH_USER_TO_PARTNER_PROJECT = gql`
  mutation attachUsersToPartner(
    $partnerProjectUserObjects: [courses_partner_project_users_insert_input!]!
    $partnerUserObjects: [courses_partner_users_insert_input!]!
  ) {
    insert_courses_partner_project_users(
      objects: $partnerProjectUserObjects
      on_conflict: { constraint: partner_project_users_project_id_user_id_key }
    ) {
      returning {
        id
        user_id
        partner_id
        project_id
      }
    }

    insert_courses_partner_users(
      objects: $partnerUserObjects
      on_conflict: { constraint: partner_users_partner_id_user_id_key }
    ) {
      returning {
        id
        user_id
        partner_id
      }
    }
  }
`;

export const DETACH_USER_FROM_PARTNER_PROJECT = gql`
  mutation detachUsersToProject($userIds: [bigint!]!, $projectIds: [bigint!]!) {
    delete_courses_partner_project_users(
      where: { user_id: { _in: $userIds }, project_id: { _in: $projectIds } }
    ) {
      affected_rows
    }
  }
`;
