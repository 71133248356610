import { gql } from "@apollo/client";

export const GET_ALL_ADMIN_USERS = gql`
  query getAllAdminUsers {
    courses_admin_users {
      id
      name
      email
      idp_user_id
      idp_user_name
      is_skillstrainer_admin
      partner_id
      project_id
      mobile_number
      role_id
      active
      mobile_number
      is_email_valid
      partner_access {
        id
        partner_id
        partner {
          organization_name
        }
        role_id
      }
    }
  }
`;

export const GET_TOTAL_ADMIN_USERS = gql`
  query getTotalAdminUsers {
    courses_admin_users_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PARTNER_PROJECTS_ROlES = gql`
  query getData {
    courses_partner {
      id
      name
      projects {
        id
        name
      }
    }
    courses_partner_projects {
      id
      name
      partner_id
    }
    courses_admin_user_roles_info {
      id
      name
    }
  }
`;

export const GET_ADMIN_USER_BY_ID = gql`
  query getAdminUserById($userId: bigint = "") {
    courses_admin_users(where: { id: { _eq: $userId } }) {
      role_id
      project_id
      partner_id
      name
      mobile_number
      idp_user_name
      is_skillstrainer_admin
      idp_user_id
      id
      is_email_valid
      email
      updated_at
      created_at
      partner_access {
        partner_id
        id
      }
    }
  }
`;

export const GET_ADMIN_PARTNER_ACCESS = gql`
  query getAdminPartnerAccess($userId: bigint) {
    courses_admin_user_partner_access(
      where: { admin_user_id: { _eq: $userId } }
    ) {
      partner_id
      role_id
      partner {
        id
        name
      }
      role {
        id
        module_relations {
          module_id
        }
      }
    }
  }
`;

export const GET_EXISTING_PARTNER_ACCESS_IDS = gql`
  query getExistingPartnerAccessIds($admin_user_id: bigint = "") {
    courses_admin_user_partner_access(
      where: { admin_user_id: { _eq: $admin_user_id } }
    ) {
      partner_id
      partner {
        organization_name
      }
    }
  }
`;

export const GET_MODULES_BY_ROLE_IDS = gql`
  query getModulesByRoleIds($roleIds: [bigint!]!) {
    courses_admin_user_role_module_access(
      where: { role_id: { _in: $roleIds } }
    ) {
      module_id
    }
  }
`;

export const GET_ALL_MODULES = gql`
  query getAllModules {
    courses_admin_modules {
      id
      name
      key
      slug
      order
      parent_module_id
      hide
    }
  }
`;

export const GET_PARTNER_LOGO_BY_ID = gql`
  query getPartnerLogoById($userId: bigint = "") {
    courses_admin_users(where: { id: { _eq: $userId } }) {
      partner {
        logo
        id
      }
    }
  }
`;
