import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PARTNERS } from "../../../GraphQl/Queries/Partner";
import { CREATE_PARTNER } from "../../../GraphQl/Mutations/Partner";
import { PARTNER_SCHEMA } from "./DbSchema";
import ScrollIntoView from "react-scroll-into-view";
import Container from "../../../components/Container/Container";
import PartnerTable from "./components/PartnerTable";
import { UPDATE_PARTNER_BY_ID } from "../../../GraphQl/Mutations/Partner";
import { errorHandler, successHandler } from "utils/toast";
import { Form } from "skillstrainer-resource-library";
import PartnerFormBuilder from "formBuilders/PartnerFormBuilder";

function ManagePartner() {
  const { error, loading, data } = useQuery(GET_PARTNERS);
  const [isUpdate, setIsUpdate] = useState(false);
  const [partnerState, setPartnerState] = useState({ active: false });

  const [partnerTableUpdate, setPartnerTableUpdate] = useState(false);
  const [reset, setReset] = useState(false);
  const [childRefreshFunction, setChildRefreshFunction] = useState(null);

  const [insertPartner] = useMutation(CREATE_PARTNER, {
    onCompleted: () =>
      typeof childRefreshFunction === "function" && childRefreshFunction(),
  });

  const [update_partner] = useMutation(UPDATE_PARTNER_BY_ID, {
    onCompleted: () =>
      typeof childRefreshFunction === "function" && childRefreshFunction(),
  });

  const createPartner = async (partner) => {
    const partner_submit = {
      ...partner,

      address_country: partner?.address?.country,
      address_district: partner?.address?.district,
      address_state: partner?.address?.state,
      address_location: partner?.address?.location,
      address_house_number: partner?.address?.house_number,
      address_city_town: partner?.address.city_town,
      pin_code: partner?.address?.pincode,
      logo: partner?.logo[0]?.url,
      address: undefined,
    };

    partner_submit.identifier = partner_submit.name
      .toLowerCase()
      .replaceAll(" ", "-");
    insertPartner({
      variables: { object: partner_submit, update_columns: [] },
    })
      .then(() => {
        successHandler("Partner created successfully!");
        setPartnerTableUpdate(!partnerTableUpdate);
        setReset(!reset);
      })
      .catch((err) => {
        errorHandler("An error occured while creating the partner");
        console.log(err);
      });
  };

  const updatePartner = async (partner) => {
    const partner_submit = {
      ...partner,
      id: partnerState.id,
      address_country: partner?.address?.country,
      address_district: partner?.address?.district,
      address_state: partner?.address?.state,
      address_location: partner?.address?.location,
      address_house_number: partner?.address?.house_number,
      address_city_town: partner?.address.city_town,
      pin_code: partner?.address?.pincode,
      logo: partner?.logo[0]?.url,
      address: undefined,
    };

    update_partner({
      variables: {
        partner: partner_submit,
        partner_id: partner_submit.id,
      },
    })
      .then(() => {
        successHandler("Partner updated successfully!");
        setPartnerTableUpdate(!partnerTableUpdate);
        setPartnerState({ active: false });
        setIsUpdate(false);
      })
      .catch((err) => {
        errorHandler("An error occured while updating the partner");
        console.log(err);
      });
  };

  const editPartnerFormFill = (partner) => {
    const temp_partner = JSON.parse(JSON.stringify(partner));
    const edit_partner = {
      ...temp_partner,
      address: {
        country: temp_partner.address_country,
        district: temp_partner.address_district,
        state: temp_partner.address_state,
        pincode: temp_partner.pin_code,
        city_town: temp_partner.address_city_town,
        location: temp_partner.address_location,
        house_number: temp_partner.address_house_number,
      },
      logo: [
        {
          id: "1",
          name: `${partner?.identifier + "-logo"}`,
          url: `${partner?.logo}`,
        },
      ],
    };
    setPartnerState(edit_partner);
    setIsUpdate(true);
  };

  return (
    <Container title={"Manage Partners - Create / Edit"}>
      <Form
        key={isUpdate || reset}
        formBuilder={PartnerFormBuilder}
        className=" ml-6 mt-2 mb-4 grid grid-cols-2 gap-x-2"
        submitButton={{
          text: "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
        }}
        onSubmit={
          isUpdate
            ? (partner) => updatePartner(partner)
            : (partner) => createPartner(partner)
        }
        initValues={partnerState}
      />

      <ScrollIntoView selector="#createPartner">
        <PartnerTable
          key={partnerTableUpdate}
          editRoute={"/partner/update_partner"}
          editPartners={(partner) => editPartnerFormFill(partner)}
          setRefreshFunction={(f) => {
            setChildRefreshFunction(f);
          }}
        />
      </ScrollIntoView>
    </Container>
  );
}

export default ManagePartner;
