import { useState, useEffect, useMemo } from "react";
import Container from "components/Container/Container";
import CertificatesTable from "./CertificatesTable";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  CERTIFICATES_SEARCH_PAGINATION,
  GET_FILTER_DATA,
  GET_PARTNER_FILTER_DATA,
  GET_TOTAL_CERTIFICATES,
} from "GraphQl/Queries/CertificateTemplateMaker";
import Input from "components/InputGroup/Input";
import Select from "components/InputGroup/Select";
import JsZip from "jszip";
import FileSaver from "file-saver";
import { Promise as BluePromise } from "bluebird";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";

function ViewCertificates() {
  const [userSearch, setUserSearch] = useState({
    name: "",
    mobile_number: "",
    email: "",
  });
  const [paginationInfo, setPaginationInfo] = useState({
    page: 0,
    per_page: 20,
  });
  const partnerAccess = useUserPartnerAccessDetails();
  const [partnerFilter, setPartnerFilter] = useState();
  const [courseFilter, setCourseFilter] = useState();
  const [projectFilter, setProjectFilter] = useState();
  const [couponFilter, setCouponFilter] = useState();
  const searchCriteria = useMemo(() => {
    let variables = partnerAccess.isAdmin
      ? { user: {}, course: {} }
      : {
          user: {
            partner_users: {
              partner_id: {
                _in: partnerAccess.partnerIds,
              },
            },
          },
          course: {
            partner_revenue_split_directives: {
              partner_id: {
                _in: partnerAccess.partnerIds,
              },
            },
          },
        };

    if (userSearch.name || userSearch.email || userSearch.mobile_number) {
      variables.user = {
        ...variables.user,
        name: { _ilike: `%${userSearch.name}%` },
        email: { _ilike: `%${userSearch.email}%` },
        mobile_number: { _ilike: `%${userSearch.mobile_number}%` },
      };
    }

    if (courseFilter) {
      variables.course = {
        ...variables.course,
        id: { _eq: `${courseFilter}` },
      };
    }

    if (partnerFilter) {
      variables.user = {
        ...variables.user,
        partner_users: {
          partner_id: {
            _eq: partnerFilter,
          },
        },
      };
    }
    if (couponFilter) {
      variables.user = {
        st_user_coupons: {
          coupon_id: { _eq: `${couponFilter}` },
        },
      };
    }
    if (projectFilter) {
      variables.partner = {
        projects: {
          id: { _eq: `${projectFilter}` },
        },
      };
    }
    return variables;
  }, [
    partnerAccess,
    userSearch,
    couponFilter,
    courseFilter,
    projectFilter,
    partnerFilter,
  ]);

  const { loading, data } = useQuery(
    partnerAccess.isAdmin ? GET_FILTER_DATA : GET_PARTNER_FILTER_DATA,
    {
      variables: partnerAccess.isAdmin
        ? {}
        : { partnerIds: partnerAccess.partnerIds },
    }
  );
  const [runQuery, { data: certiData }] = useLazyQuery(
    CERTIFICATES_SEARCH_PAGINATION,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    setPaginationInfo({
      ...paginationInfo,
      page: 0,
    });
  }, [searchCriteria]);
  useEffect(() => {
    searchFunc();
  }, [partnerAccess, paginationInfo]);

  const totalCertificatesHook = useQuery(GET_TOTAL_CERTIFICATES, {
    variables: {
      searchCriteria,
    },
    fetchPolicy: "network-only",
  });

  const totalCertificates = useMemo(() => {
    if (
      totalCertificatesHook.data &&
      totalCertificatesHook.data.courses_certificates_aggregate
    )
      return totalCertificatesHook.data.courses_certificates_aggregate.aggregate
        .count;
    else return 0;
  }, [totalCertificatesHook.data, searchCriteria]);

  const downloadAllInCurrentResult = () => {
    console.log("Download All in current result invoked");
    const download = (certificateDataElement) => {
      const url = certificateDataElement.certificate_pdf_url;
      const certificateFileBlob = fetch(url).then((resp) => {
        return resp.blob();
      });
      certificateDataElement.blob = certificateFileBlob;
      return certificateDataElement;
    };

    const downloadByGroup = (certificateDataArray, files_per_group = 30) => {
      return BluePromise.map(
        certificateDataArray,
        async (certificateDataElement) => {
          return await download(certificateDataElement);
        },
        { concurrency: files_per_group }
      );
    };

    const exportZip = (certificateDataArray) => {
      const zip = JsZip();
      certificateDataArray.forEach((certificateDataElement) => {
        zip.file(
          `${certificateDataElement.user.name}-${certificateDataElement.user.id}.pdf`,
          certificateDataElement.blob
        );
      });
      zip.generateAsync({ type: "blob" }).then((zipFile) => {
        const currentDate = new Date().getTime();
        const fileName = `certificates-${currentDate}.zip`;
        return FileSaver.saveAs(zipFile, fileName);
      });
    };

    const downloadAndZip = (certificateDataArray) => {
      return downloadByGroup(certificateDataArray, 100).then(exportZip);
    };

    downloadAndZip(certiData?.courses_certificates);
  };

  const searchFunc = (e) => {
    if (e) e.preventDefault();

    runQuery({
      variables: {
        searchCriteria: searchCriteria,
        limit: paginationInfo.per_page,
        offset: paginationInfo.page * paginationInfo.per_page,
      },
    });
  };

  const filterProjects = (selectedPartner) => {
    if (selectedPartner && selectedPartner + "" !== -1 + "") {
      const partnerData = data?.courses_partner.find((partner) => {
        if (selectedPartner + "" === partner.id + "") return partner;
        return false;
      });

      return partnerData.projects;
    } else {
      return data?.courses_partner_projects || [];
    }
  };

  return (
    <Container title={"Certificates Table"}>
      <div className="flex flex-row grid grid-cols-2 space-x-4">
        <div className="flex flex-col w-full mb-4 space-y-2 ">
          <Select
            label={"Filter by Partner"}
            options={
              data?.courses_partner.map((partner) => {
                return {
                  value: partner.id,
                  label: partner.name,
                };
              }) || []
            }
            valueField={"value"}
            displayField={"label"}
            onChange={(e) => setPartnerFilter(e.target.value)}
          />
          <Select
            label={"Filter by Project"}
            options={
              filterProjects(partnerFilter).map((project) => {
                return {
                  value: project.id,
                  label: project.name,
                };
              }) || []
            }
            valueField={"value"}
            displayField={"label"}
            onChange={(e) => setProjectFilter(e.target.value)}
          />
          <Select
            label={"Filter By Course"}
            options={
              data?.courses_course.map((course) => {
                return {
                  value: course.id,
                  label: course.full_name,
                };
              }) || []
            }
            valueField={"value"}
            displayField={"label"}
            onChange={(e) => setCourseFilter(e.target.value)}
          />
          <Select
            label={"Filter By Coupon"}
            options={
              data?.courses_st_coupons_configuration.map((coupon) => {
                return {
                  value: coupon.id,
                  label: coupon.code,
                };
              }) || []
            }
            valueField={"value"}
            displayField={"label"}
            onChange={(e) => setCouponFilter(e.target.value)}
          />
        </div>
        <div className="flex flex-col w-full mb-4 space-y-2">
          <Input
            type={"text"}
            placeholder={"Name"}
            label={"Search By Name"}
            value={userSearch.name}
            onChange={(e) => {
              setUserSearch({ ...userSearch, name: e.target.value });
            }}
          />
          <Input
            type={"text"}
            placeholder={"Email"}
            label={"Search By Email"}
            value={userSearch.email}
            onChange={(e) => {
              setUserSearch({ ...userSearch, email: e.target.value });
            }}
          />
          <Input
            type={"text"}
            placeholder={"Mobile Number"}
            label={"Search By Mobile Number"}
            value={userSearch.mobile_number}
            onChange={(e) => {
              setUserSearch({ ...userSearch, mobile_number: e.target.value });
            }}
          />

          <button
            onClick={(e) => searchFunc(e)}
            className="btn-primary block mt-4 w-1/6 ml-6"
          >
            Search
          </button>
        </div>
      </div>
      <button
        onClick={() => downloadAllInCurrentResult()}
        className="btn-secondary float-right hover:opacity-80 mr-2 "
      >
        Download All in Current Result
      </button>
      <CertificatesTable
        paginationInfo={paginationInfo}
        setPaginationInfo={setPaginationInfo}
        data={certiData}
        total={totalCertificates}
        loading={loading}
      />
    </Container>
  );
}

export default ViewCertificates;
