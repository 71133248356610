import Container from "components/Container/Container";
import { useEffect, useState } from "react";
import { Form } from "skillstrainer-resource-library";
import QuestionTranslationsFormBuilder from "formBuilders/QuestionTranslationsFormBuilder";
import Select from "components/InputGroup/Select";
import { GET_SKILLSTRAINER_LANGUAGES } from "GraphQl/Queries/CourseBuilder/Quiz";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_TRANSLATIONS } from "GraphQl/Queries/Courses";
import { UPSERT_TRANSLATIONS } from "GraphQl/Mutations/Courses";
import { errorHandler, successHandler } from "utils/toast";

export function Translations({ question }) {
  const { data: languagesData } = useQuery(GET_SKILLSTRAINER_LANGUAGES);
  const [getTranslations] = useLazyQuery(GET_TRANSLATIONS, {
    fetchPolicy: "network-only",
  });
  const englishQuestionData = {
    key: -1,
    data: {
      question_text: question?.question_text || "",
      question_answers: (question?.quest_answer || []).map((ans) => {
        return {
          id: ans.id,
          answer_text: ans.answer_text,
        };
      }),
    },
  };
  const [upsertTranslations] = useMutation(UPSERT_TRANSLATIONS);
  const [language, setLanguage] = useState();
  const [questionData, setQuestionData] = useState(englishQuestionData);

  useEffect(() => {
    if (language && language !== "English") {
      const quesitonKey =
        "question." + question?.id + "." + language.toLowerCase();

      const answerKeys = (question?.quest_answer || []).map((ans) => {
        return "answer." + ans.id + "." + language.toLowerCase();
      });

      getTranslations({
        variables: {
          key_array: [quesitonKey, ...answerKeys],
        },
      }).then(({ data }) => {
        const responseArray = data?.courses_multi_lang_contents || [];
        const questionDataObject = {
          question_text: "",
          question_answers: [],
        };
        const transIdArray = [];

        if (responseArray.length !== 0) {
          responseArray.forEach((trans) => {
            if (trans.key.includes("question"))
              questionDataObject.question_text = trans.content.question;
            else {
              const transAnsId = trans.key.split(".")[1];
              transIdArray.push(parseInt(transAnsId));

              questionDataObject.question_answers.push({
                id: transAnsId,
                answer_text: trans.content.answer,
              });
            }
          });

          //handling new non translated answer options
          englishQuestionData.data.question_answers.forEach((ans) => {
            const ansId = parseInt(ans.id);
            let nonTransAnsFlag = false;
            for (const transId of transIdArray) {
              if (transId === ansId) {
                nonTransAnsFlag = true;
              }
            }
            if (!nonTransAnsFlag) {
              questionDataObject.question_answers.push({
                id: ansId,
                answer_text: ans.answer_text,
              });
            }
          });

          setQuestionData({
            key: questionData.key + 1,
            data: questionDataObject,
          });
        }
      });
    } else if (language === "English") {
      setQuestionData(englishQuestionData);
    }
  }, [language]);

  function handleSubmit(formObject) {
    if (language !== "English") {
      const translationInput = [
        {
          key: "question." + question?.id + "." + language.toLowerCase(),
          content: {
            question: formObject.question_text,
          },
        },
      ];

      formObject.question_answers.forEach((ans) => {
        translationInput.push({
          key: "answer." + ans.id + "." + language.toLowerCase(),
          content: {
            answer: ans.answer_text,
          },
        });
      });

      if (
        translationInput.length !==
        englishQuestionData.data.question_answers.length + 1
      ) {
        errorHandler(
          "Do not delete any translated options, please refresh and try again"
        );
        return null;
      }

      upsertTranslations({
        variables: {
          translationsArray: translationInput,
        },
      })
        .then(() => {
          successHandler("Translation Saved Successfully");
        })
        .catch((err) => {
          console.log(err);
          errorHandler("There was an error while saving translation");
        });
    } else {
      errorHandler("Please Select Another Language");
    }
  }

  return (
    <Container>
      {languagesData && (
        <Select
          label={"Language"}
          className={"px-4"}
          defaultValue={"English"}
          options={
            languagesData?.courses_skillstrainer_languages.map((partner) => {
              return {
                value: partner.id,
                label: partner.name,
              };
            }) || []
          }
          valueField={"label"}
          displayField={"label"}
          onChange={(e) => setLanguage(e.target.value)}
        />
      )}
      <Form
        key={questionData.key}
        formBuilder={QuestionTranslationsFormBuilder}
        className=" ml-6 mt-2 grid grid-cols-1 gap-y-2 pr-2"
        submitButton={{
          text: "Save",
          className:
            "btn-primary text-sm font-semibold text-white rounded-md px-6",
        }}
        initValues={questionData.data || {}}
        onSubmit={handleSubmit}
      />
    </Container>
  );
}
