import * as yup from "yup";
import { useState, useEffect } from "react";

function SkillFormBuilder(values) {
  const [schema, setSchema] = useState({});

  useEffect(() => {
    const schema = {
      name: {
        label: "Name",
        type: "text",
        required: true,
      },
      label: {
        label: "Label",
        type: "text",
        required: true,
      },
    };

    setSchema(schema);
  }, []);

  return schema;
}

export default SkillFormBuilder;
