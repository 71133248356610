import { gql } from "@apollo/client";

export const UPSERT_COUPON_BATCH_RELATIONS = gql`
  mutation (
    $couponBatchRelations: [courses_coupon_batch_relations_insert_input!]!
  ) {
    insert_courses_coupon_batch_relations(
      objects: $couponBatchRelations
      on_conflict: { constraint: coupon_batch_relations_coupon_id_batch_id_key }
    ) {
      affected_rows
      returning {
        id
        coupon_id
        batch_id
      }
    }
  }
`;

export const DELETE_COUPON_BATCH_RELATIONS = gql`
  mutation ($couponBatchRelationIds: [bigint!]!) {
    delete_courses_coupon_batch_relations(
      where: { id: { _in: $couponBatchRelationIds } }
    ) {
      affected_rows
    }
  }
`;
