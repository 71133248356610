import { useEffect, useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { uploadFile, getFile } from "api/UploadFile";
import { FiRefreshCw } from "react-icons/fi";
import { errorHandler, successHandler } from "utils/toast";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import { Promise as BluePromise } from "bluebird";
import JsZip from "jszip";
import FileSaver from "file-saver";
import { GET_USER_IDS_BY_EMAILS } from "GraphQl/Queries/User";
import { GET_CERTIFICATES_FOR_BULK_DOWNLOAD } from "GraphQl/Queries/CertificateTemplateMaker";
import { CREATE_COURSES_ADMIN_CSV_USER_IMPORTS } from "GraphQl/Mutations/OnboardingUsers";
import { GET_ADMIN_CSV_USER_IMPORTS_CERTIFICATE } from "GraphQl/Queries/OnboardingUsers";
import { useRecoilState } from "recoil";
import { gqlUserRecoilVar } from "State/User";
import Container from "components/Container/Container";

const funcRef = {};

function CSVBulkCertificateGenerator() {
  const [user] = useRecoilState(gqlUserRecoilVar);

  const [certificateCourse, setCertificateCourse] = useState();
  const [csvData, setCsvData] = useState();
  const [users, setUsers] = useState();
  const [columns, setColumns] = useState();
  const [file, setFile] = useState();
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [userIds, setUserIds] = useState([]);

  const importsColumns = [
    {
      name: "Id",
      cell: (row, index) => `${row.id}`,
    },
    {
      name: "File url",
      grow: 5,
      cell: (row, index) => row.file_url,
    },
    {
      name: "Trigger Status",
      grow: 5,
      cell: (row, index) => (
        <p
          className={`${
            importColumnsStatusColors[row.status] !== undefined && "text-white"
          } ${importColumnsStatusColors[row.status]}  rounded p-2 m-1`}
        >
          {row.status}
        </p>
      ),
    },
    {
      name: "Created At",
      grow: 5,
      // style: {wordBreak: "break-all"},
      cell: (row, index) => row.created_at,
    },

    {
      name: "",
      grow: 2.5,
      cell: (row, index) => (
        <button
          onClick={(e) => funcRef.checkStatus(row)}
          className="bg-red-500 text-white py-2 px-1 rounded"
        >
          Certificates
        </button>
      ),
    },
    {
      name: "",
      grow: 2.5,
      cell: (row, index) => (
        <button
          onClick={(e) => handleDownload(row)}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Download
        </button>
      ),
    },
  ];

  const importColumnsStatusColors = {
    pending: "bg-gray-400",
    running: "bg-orange",
    processed: "bg-green-500",
    failed: "bg-red-500",
  };

  const handleDownload = async (row) => {
    const url = await getFile(row.file_url);
    window.open(url, "_blank");
  };

  const [createBulkCertificates, { error: bulkError, loading: bulkLoading }] =
    useMutation(CREATE_COURSES_ADMIN_CSV_USER_IMPORTS, {
      refetchQueries: [GET_ADMIN_CSV_USER_IMPORTS_CERTIFICATE],
    });

  const [runQuery, tableData] = useLazyQuery(
    GET_ADMIN_CSV_USER_IMPORTS_CERTIFICATE,
    {
      fetchPolicy: "network-only",
    }
  );
  const { refetch: getUserIdsByEmails } = useQuery(GET_USER_IDS_BY_EMAILS, {
    emails: [],
  });

  const { refetch: getCertificatesForBulkDownload } = useQuery(
    GET_CERTIFICATES_FOR_BULK_DOWNLOAD,
    {
      course_id: 56, //doesnt matter, for intial fetch
      user_array: [],
    }
  );

  useEffect(() => {
    runQuery();
  }, []);

  const refreshUser = () => {
    runQuery();
  };

  const formatTableData = (csvData) => {
    let columns = Object.keys(csvData[0]);
    setColumns(
      columns.map((column, index) => {
        return {
          name: column,
          cell: (row) => row[index],
          grow: 4,
        };
      })
    );
    setUsers(
      csvData.map((item) => {
        return Object.values(item);
      })
    );
  };

  const handleCsvUpload = (fileData) => {
    setFile(fileData);
    Papa.parse(fileData, {
      header: true,
      transformHeader: (header) => {
        return header.toLowerCase().replace(" ", "_");
      },
      delimiter: ",",
      complete: (res) => {
        formatTableData(res.data);
      },
      skipEmptyLines: true,
    });

    let reader = new FileReader();
    reader.onload = function (output) {
      setCsvData({
        csv_data: output.target.result,
        csv_name: fileData.name,
      });
    };
    reader.readAsDataURL(fileData);
  };

  const generateBulkCertificate = async () => {
    const response = await uploadFile(
      csvData.csv_data,
      csvData.csv_name,
      "usercsv"
    );

    const userImportRecord = {
      status: "pending",
      file_url: response.data_url,
      purpose: "bulk_certificate",
      uploader_id: user.id,
    };

    createBulkCertificates({
      variables: {
        record: userImportRecord,
      },
    });
    if (!bulkLoading && !bulkError) setUploadSuccess(true);
  };

  funcRef.getUserIds = async (data) => {
    const emailArray = data.map((user) => {
      return user.email;
    });

    setCertificateCourse(data[0].certificate_course_id);

    await getUserIdsByEmails({
      emails: [...emailArray],
    }).then((res) => {
      const { courses_users } = res.data;
      userIds.splice(
        0,
        userIds.length,
        ...courses_users.map((item) => item.id)
      );
      setUserIds([...userIds]);
    });
  };

  funcRef.getCertificateLinks = async () => {
    const certificates = await getCertificatesForBulkDownload({
      course_id: certificateCourse,
      user_array: userIds,
    }).then((res) => {
      return res.data?.courses_certificates;
    });

    return certificates;
  };

  const downloadBulkCertificates = async (certificates) => {
    const certificateLinks = certificates.map((certificate) => {
      return certificate.certificate_pdf_url;
    });

    const download = (url) => {
      return fetch(url).then((resp) => resp.blob());
    };

    const downloadByGroup = (urls, files_per_group = 30) => {
      return BluePromise.map(
        urls,
        async (url) => {
          return await download(url);
        },
        { concurrency: files_per_group }
      );
    };

    const exportZip = (blobs) => {
      const zip = JsZip();
      blobs.forEach((blob, i) => {
        zip.file(`certificate-${i}.pdf`, blob);
      });
      zip.generateAsync({ type: "blob" }).then((zipFile) => {
        const currentDate = new Date().getTime();
        const fileName = `certificates-${currentDate}.zip`;
        return FileSaver.saveAs(zipFile, fileName);
      });
    };

    const downloadAndZip = (urls) => {
      return downloadByGroup(urls, 30).then(exportZip);
    };

    downloadAndZip(certificateLinks);
  };

  funcRef.checkStatus = async (row) => {
    successHandler("Checking certificate availablity, please wait...");
    if (row.status !== "processed") {
      errorHandler("Certificates can't be downloaded, please check status");
    } else {
      const fileUrl = await getFile(row.file_url);
      await new Promise((res, rej) =>
        Papa.parse(fileUrl, {
          download: true,
          header: true,
          transformHeader: (header) => {
            return header.toLowerCase().replace(" ", "_");
          },
          delimiter: ",",
          error: rej,
          complete: res,
          skipEmptyLines: true,
        })
      )
        .then((res) => {
          return funcRef.getUserIds(res.data);
        })
        .catch(console.error);
    }

    const certificates = await funcRef.getCertificateLinks();

    if (certificates.length === userIds.length) {
      successHandler("Please wait while certificates are being downloaded...");
      downloadBulkCertificates(certificates);
    } else errorHandler("Please wait while certificates are being generated");
  };

  return (
    <Container title={"Bulk Certificate Generator"} className="my-4 mx-2">
      <div className="flex flex-col ">
        <h5 className="ml-4 mt-2">Download Sample CSV Format</h5>
        <a
          href="https://adminskillstrainer.s3.ap-south-1.amazonaws.com/bulk_certificate_csv_format.csv"
          className=" ml-4 btn-primary no-underline hover:text-white hover:opacity-90 w-24 content-center "
        >
          Download
        </a>

        <div className="flex flex-row space-x-2">
          <div className="flex flex-col">
            <h5 className="ml-4 mt-4">Upload CSV</h5>
            <input
              type={"file"}
              accept=".csv"
              onChange={(e) => handleCsvUpload(e.target.files[0])}
              multiple={false}
              className="mt-2 ml-4"
            ></input>
          </div>
        </div>
        {file && columns && (
          <div className="flex-row mt-10 ml-4 w-auto">
            <p className="text-2xl mb-3 font-semibold">Preview</p>
            <DataTable columns={columns} data={users} />
            <button
              onClick={generateBulkCertificate}
              className="btn-primary mt-4"
            >
              Confirm and Create Certificates
            </button>
          </div>
        )}

        {uploadSuccess && (
          <div className="mt-7 text-green-700 text-lg ml-4">
            CSV successfully uploaded
          </div>
        )}
        <div className="mt-5 ml-4">
          <div className="flex flex-row">
            <p className="text-2xl font-medium">Status</p>
            <button onClick={refreshUser} className="p-2 text-gray-500 w-5 h-5">
              <FiRefreshCw />
            </button>
          </div>
          <DataTable
            className="min-w-max"
            columns={importsColumns}
            progressPending={tableData.loading}
            data={
              !tableData.loading
                ? tableData.data?.courses_admin_csv_user_imports
                : []
            }
          />
        </div>
      </div>
    </Container>
  );
}

export default CSVBulkCertificateGenerator;
