import _ from "lodash";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  userAccessibleModulesRecoilVar as userRole,
  gqlUserRecoilVar as gqlUser,
} from "../State/User";

export function useViewAccessCheck(module, subModule) {
  const { role } = useRecoilState(userRole);
  // console.log(role);
}

export function useEditAccessCheck(module, subModule) {
  const { role } = useRecoilState(userRole);
}

export function useMainNavigationAccessCheck(navs) {
  const [newNavs, setNewNavs] = useState([]);

  const [data, setUserRole] = useRecoilState(userRole);

  useEffect(() => {
    if (data.module) {
      const newNavs = _.cloneDeep(navs);
      console.log("data.module", newNavs);

      newNavs.items = navs.items.filter((nav) => {
        const moduleAccess = data.module.filter(({ name, read }) => {
          if (name === nav.name.toLowerCase()) {
            return read;
          }
          return false;
        });

        if (moduleAccess.length > 0) {
          return true;
        }

        return false;
      });
      setNewNavs(newNavs);
    }
  }, [data]);

  return newNavs;
}

export function useSideBarNavigationCheck(moduleName, sideBarNavs) {
  // console.log("Module : ", moduleName, sideBarNavs);
  const [navs, setNavs] = useState([]);
  const [data, setUserRole] = useRecoilState(userRole);

  // console.log("===================, data", data);

  useEffect(() => {
    const hasModuleAccess = () => {
      const moduleAccess = data.module.filter(({ name }) => {
        return moduleName.toLowerCase() == name.toLowerCase();
      });
      return moduleAccess.length > 0;
    };
    if (data.module && hasModuleAccess()) {
      // console.log("Data : ", data);
      const module = data.module.filter(({ name }) => {
        return moduleName.toLowerCase() == name.toLowerCase();
      })[0];

      const filteredSideBars = sideBarNavs.filter((sideBarNav) => {
        const submodule = module.submodules.filter(
          ({ name }) => name === sideBarNav.key
        );
        if (submodule.length > 0) {
          // console.log("Name", submodule[0].name, sideBarNav.key);
          return submodule[0].read;
        }
        return false;
      });

      // console.log("Fitlered side bar", filteredSideBars);
      setNavs(filteredSideBars);
    } else {
      setNavs([]);
    }
  }, [data]);

  // console.log("Navs : ", navs);

  return navs;
}

export function useUserPartnerAccessDetails() {
  const [data, setGqlUser] = useRecoilState(gqlUser);
  // console.log("User gql : ", data);
  const [partnerAccess, setPartnerAccess] = useState({});

  useEffect(() => {
    // console.log("VAilable data ", data);

    if (data && data.is_skillstrainer_admin == true) {
      const access = {
        partnerIds: [],
        allPartnerAccess: true,
        isAdmin: true,
        primaryPartnerId: data.partner_id,
      };
      setPartnerAccess(access);
    } else if (data.partner_access) {
      const parnterIds = data.partner_access.map(
        ({ partner_id }) => partner_id
      );
      parnterIds.push(data.partner_id);

      const access = {
        partnerIds: parnterIds,
        allPartnerAccess: false,
        primaryPartnerId: data.partner_id,
      };
      console.log(access);
      setPartnerAccess(access);
    }
  }, [data]);

  return partnerAccess;
}
