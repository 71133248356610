import { gql } from "@apollo/client";
export const INSERT_CERTIFICATES_DETAILS = gql`
  mutation certificatedata(
    $bg_image_url: String
    $course_name: String
    $course_name_size: Int
    $course_x_axis: Int
    $course_y_axis: Int
    $date_size: Int
    $date_x_axis: Int
    $date_y_axis: Int
    $enroll_size: Int
    $enroll_x_axis: Int
    $enroll_y_axis: Int
    $enrollment_id: Int
    $issued_date: String
    $mapping_id: Int
    $nsqf: String
    $nsqf_size: Int
    $nsqf_x_axis: Int
    $nsqf_y_axis: Int
    $partner_name: String
    $partner_size: Int
    $partner_x_axis: Int
    $partner_y_axis: Int
    $per_size: Int
    $per_x_axis: Int
    $per_y_axis: Int
    $percentage_grade: String
    $project_name: String
    $project_size: Int
    $project_x_axis: Int
    $project_y_axis: Int
    $user_name: String
    $user_text_size: Int
    $user_x_axis: Int
    $user_y_axis: Int
    $qr_size: Int
    $qr_x_axis: Int
    $qr_y_axis: Int
    $grade: String
    $grade_x_axis: Int
    $grade_y_axis: Int
    $grade_size: Int
    $course_name_element: Boolean
    $enrollment_id_element: Boolean
    $grade_element: Boolean
    $issued_date_element: Boolean
    $nsqf_element: Boolean
    $partner_name_element: Boolean
    $percentage_element: Boolean
    $project_element: Boolean
    $qr_element: Boolean
    $user_name_element: Boolean
  ) {
    insert_courses_course_template_certificates_data(
      objects: {
        bg_image_url: $bg_image_url
        course_name: $course_name
        course_name_size: $course_name_size
        course_x_axis: $course_x_axis
        course_y_axis: $course_y_axis
        date_size: $date_size
        date_x_axis: $date_x_axis
        date_y_axis: $date_y_axis
        enroll_size: $enroll_size
        enroll_x_axis: $enroll_x_axis
        enroll_y_axis: $enroll_y_axis
        enrollment_id: $enrollment_id
        issued_date: $issued_date
        mapping_id: $mapping_id
        nsqf: $nsqf
        nsqf_size: $nsqf_size
        nsqf_x_axis: $nsqf_x_axis
        nsqf_y_axis: $nsqf_y_axis
        partner_name: $partner_name
        partner_size: $partner_size
        partner_x_axis: $partner_x_axis
        partner_y_axis: $partner_y_axis
        per_size: $per_size
        per_x_axis: $per_x_axis
        per_y_axis: $per_y_axis
        percentage_grade: $percentage_grade
        project_name: $project_name
        project_size: $project_size
        project_x_axis: $project_x_axis
        project_y_axis: $project_y_axis
        user_name: $user_name
        user_text_size: $user_text_size
        user_x_axis: $user_x_axis
        user_y_axis: $user_y_axis
        qr_size: $qr_size
        qr_x_axis: $qr_x_axis
        qr_y_axis: $qr_y_axis
        grade: $grade
        grade_x_axis: $grade_x_axis
        grade_y_axis: $grade_y_axis
        grade_size: $grade_size
        course_name_element: $course_name_element
        enrollment_id_element: $enrollment_id_element
        grade_element: $grade_element
        issued_date_element: $issued_date_element
        nsqf_element: $nsqf_element
        partner_name_element: $partner_name_element
        percentage_element: $percentage_element
        project_element: $project_element
        qr_element: $qr_element
        user_name_element: $user_name_element
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export const PREVIEW_CERT_DATA = gql`
  query getPreviewCertData($mapping_id: Int) {
    courses_course_template_certificates_data(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      bg_image_url
      course_name
      course_name_size
      course_x_axis
      course_y_axis
      date_size
      date_x_axis
      date_y_axis
      enroll_size
      enroll_x_axis
      enroll_y_axis
      enrollment_id
      id
      issued_date
      mapping_id
      nsqf
      nsqf_size
      nsqf_x_axis
      nsqf_y_axis
      partner_name
      partner_size
      partner_x_axis
      partner_y_axis
      per_size
      per_x_axis
      per_y_axis
      percentage_grade
      project_name
      project_size
      project_x_axis
      project_y_axis
      qr_size
      qr_x_axis
      qr_y_axis
      user_name
      user_text_size
      user_x_axis
      user_y_axis
      grade
      grade_x_axis
      grade_y_axis
      grade_size
      enrollment_id_element
      course_name_element
      grade_element
      issued_date_element
      nsqf_element
      partner_name_element
      percentage_element
      project_element
      qr_element
      user_name_element
    }
  }
`;
export const UPDATE_CERT_DATA = gql`
  mutation MyMutation(
    $bg_image_url: String
    $course_name: String
    $course_name_size: Int
    $course_x_axis: Int
    $course_y_axis: Int
    $created_at: timestamptz
    $date_size: Int
    $date_x_axis: Int
    $date_y_axis: Int
    $enroll_size: Int
    $enroll_x_axis: Int
    $enroll_y_axis: Int
    $enrollment_id: Int
    $id: Int
    $issued_date: String
    $mapping_id: Int
    $nsqf: String
    $nsqf_size: Int
    $nsqf_x_axis: Int
    $nsqf_y_axis: Int
    $partner_name: String
    $partner_size: Int
    $partner_x_axis: Int
    $partner_y_axis: Int
    $per_size: Int
    $per_x_axis: Int
    $per_y_axis: Int
    $percentage_grade: String
    $project_name: String
    $project_size: Int
    $project_x_axis: Int
    $project_y_axis: Int
    $qr_size: Int
    $qr_x_axis: Int
    $qr_y_axis: Int
    $updated_at: timestamptz
    $user_name: String
    $user_text_size: Int
    $user_x_axis: Int
    $user_y_axis: Int
    $grade: String
    $grade_x_axis: Int
    $grade_y_axis: Int
    $grade_size: Int
    $course_name_element: Boolean
    $enrollment_id_element: Boolean
    $grade_element: Boolean
    $issued_date_element: Boolean
    $nsqf_element: Boolean
    $partner_name_element: Boolean
    $percentage_element: Boolean
    $project_element: Boolean
    $qr_element: Boolean
    $user_name_element: Boolean
  ) {
    update_courses_course_template_certificates_data(
      where: { mapping_id: { _eq: $mapping_id }, id: { _eq: $id } }
      _set: {
        bg_image_url: $bg_image_url
        course_name: $course_name
        course_name_size: $course_name_size
        course_x_axis: $course_x_axis
        course_y_axis: $course_y_axis
        created_at: $created_at
        date_size: $date_size
        date_x_axis: $date_x_axis
        date_y_axis: $date_y_axis
        enroll_size: $enroll_size
        enroll_x_axis: $enroll_x_axis
        enroll_y_axis: $enroll_y_axis
        enrollment_id: $enrollment_id
        id: $id
        issued_date: $issued_date
        mapping_id: $mapping_id
        nsqf: $nsqf
        nsqf_size: $nsqf_size
        nsqf_x_axis: $nsqf_x_axis
        nsqf_y_axis: $nsqf_y_axis
        partner_name: $partner_name
        partner_size: $partner_size
        partner_x_axis: $partner_x_axis
        partner_y_axis: $partner_y_axis
        per_size: $per_size
        per_x_axis: $per_x_axis
        per_y_axis: $per_y_axis
        percentage_grade: $percentage_grade
        project_name: $project_name
        project_size: $project_size
        project_x_axis: $project_x_axis
        project_y_axis: $project_y_axis
        qr_size: $qr_size
        qr_x_axis: $qr_x_axis
        qr_y_axis: $qr_y_axis
        updated_at: $updated_at
        user_name: $user_name
        user_text_size: $user_text_size
        user_x_axis: $user_x_axis
        user_y_axis: $user_y_axis
        grade: $grade
        grade_x_axis: $grade_x_axis
        grade_y_axis: $grade_y_axis
        grade_size: $grade_size
        course_name_element: $course_name_element
        enrollment_id_element: $enrollment_id_element
        grade_element: $grade_element
        issued_date_element: $issued_date_element
        nsqf_element: $nsqf_element
        partner_name_element: $partner_name_element
        percentage_element: $percentage_element
        project_element: $project_element
        qr_element: $qr_element
        user_name_element: $user_name_element
      }
    ) {
      affected_rows
      returning {
        id
        mapping_id
      }
    }
  }
`;

export const INSERT_BULKDATA_CERTIFICATES = gql`
  mutation insertDataforBulkCertificate(
    $course_name: String
    $course_size: Int
    $course_x_axis: Int
    $course_y_axis: Int
    $email_size: Int
    $email_x_axis: Int
    $email_y_axis: Int
    $enroll_size: Int
    $enroll_x_axis: Int
    $enroll_y_axis: Int
    $enrollment_id: Int
    $nsqf: String
    $nsqf_size: Int
    $nsqf_x_axis: Int
    $nsqf_y_axis: Int
    $percentage_grade: String
    $percentage_size: Int
    $percentage_x_axis: Int
    $percentage_y_axis: Int
    $phone_size: Int
    $phone_x_axis: Int
    $phone_y_axis: Int
    $project_name: String
    $project_name_size: Int
    $project_x_axis: Int
    $project_y_axis: Int
    $user_email: String
    $user_id: Int
    $user_name: String
    $user_name_size: Int
    $user_phone: Int
    $user_x_axis: Int
    $user_y_axis: Int
    $bg_image_url: String
    $template_name: String
  ) {
    insert_courses_bulk_certificates_details(
      objects: {
        course_name: $course_name
        course_size: $course_size
        course_x_axis: $course_x_axis
        course_y_axis: $course_y_axis
        email_size: $email_size
        email_x_axis: $email_x_axis
        email_y_axis: $email_y_axis
        enroll_size: $enroll_size
        enroll_x_axis: $enroll_x_axis
        enroll_y_axis: $enroll_y_axis
        enrollment_id: $enrollment_id
        nsqf: $nsqf
        nsqf_size: $nsqf_size
        nsqf_x_axis: $nsqf_x_axis
        nsqf_y_axis: $nsqf_y_axis
        percentage_grade: $percentage_grade
        percentage_size: $percentage_size
        percentage_x_axis: $percentage_x_axis
        percentage_y_axis: $percentage_y_axis
        phone_size: $phone_size
        phone_x_axis: $phone_x_axis
        phone_y_axis: $phone_y_axis
        project_name: $project_name
        project_x_axis: $project_x_axis
        project_name_size: $project_name_size
        project_y_axis: $project_y_axis
        user_email: $user_email
        user_id: $user_id
        user_name: $user_name
        user_name_size: $user_name_size
        user_phone: $user_phone
        user_x_axis: $user_x_axis
        user_y_axis: $user_y_axis
        bg_image_url: $bg_image_url
        template_name: $template_name
      }
    ) {
      affected_rows
      returning {
        id
        enrollment_id
        user_id
        user_name
      }
    }
  }
`;
export const GET_ALL_CERT_TEMPLATES = gql`
  query getAllTemplate {
    courses_bulk_certificates_details {
      course_name
      course_size
      course_x_axis
      course_y_axis
      email_size
      email_x_axis
      email_y_axis
      enroll_size
      enroll_x_axis
      enroll_y_axis
      enrollment_id
      id
      nsqf
      nsqf_size
      nsqf_x_axis
      nsqf_y_axis
      percentage_grade
      percentage_size
      percentage_x_axis
      percentage_y_axis
      phone_size
      phone_x_axis
      phone_y_axis
      project_name
      project_name_size
      project_x_axis
      project_y_axis
      template_name
      user_email
      user_id
      user_name
      user_name_size
      user_phone
      user_x_axis
      user_y_axis
      bg_image_url
      template_name
    }
  }
`;
