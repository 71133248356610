import { useState, useEffect } from "react";
import { fileSchema } from "utils/schemas";
import * as yup from "yup";

function PartnerFormBuilder(values) {
  const [schema, setSchema] = useState();

  useEffect(() => {
    const schema = {
      name: {
        label: "Organization Name",
        type: "text",
        required: true,
        schema: yup.string(),
      },
      organization_category: {
        label: "Select organization category",
        type: "select",
        options: [
          { label: "Sector Skill Council", value: "sector_skill_council" },
          { label: "Private College", value: "college" },
          { label: "Private Polytechnic", value: "private_polytechnic" },
          { label: "Private ITI", value: "private_ITI" },
          { label: "Private University", value: "private_university" },
          { label: "Private School", value: "private_school" },
          { label: "Public College", value: "private_college" },
          { label: "Public Polytechnic", value: "public_polytechnic" },
          { label: "Public ITI", value: "public_ITI" },
          { label: "Public University", value: "public_university" },
          { label: "Public School", value: "public_school" },
          {
            label: "Training/ Coaching Center",
            value: "training_coaching_center",
          },
          {
            label: "Social Organisation/NGO",
            value: "social_organisation_ngo",
          },
          { label: "Govt. Department", value: "govt._department" },
          {
            label: "Multi-national Corporation",
            value: "multi_national_corporation",
          },
          { label: "Indian Large Corporate", value: "indian_large_corporate" },
          { label: "Indian MSME", value: "MSME" },
          { label: "Other", value: "other" },
        ],
      },

      address: {
        label: "Address",
        type: "address",
        keys: {
          action: "include",
          keys: ["house_number", "location", "state", "district", "city_town"],
        },
        schema: yup.object(),
      },
      about_partner: {
        label: "About Partner",
        type: "textarea",
        schema: yup.string(),
      },

      activation_start_date: {
        label: "Activation Start Date",
        type: "date",
        required: true,
      },
      activation_end_date: {
        label: "Activation End Date",
        type: "date",
      },

      contact_person_name: {
        label: "Contact Name",
        type: "text",
        required: true,
        schema: yup.string(),
      },
      contact_person_email: {
        label: "Contact Email",
        type: "text",
        required: true,
        schema: yup.string().email(),
      },
      contact_person_mobile_number: {
        label: "Contact Mobile Number",
        type: "text",
        schema: yup.number().nullable(),
      },

      organisation_head_contact_person_name: {
        label: "Organisation Head Name",
        type: "text",
        required: true,
        schema: yup.string(),
      },
      organisation_head_contact_person_email: {
        label: "Organisation Head Contact Email",
        type: "text",
        required: true,
        schema: yup.string().email(),
      },
      organisation_head_contact_person_mobile_number: {
        label: "Organisation Head Contact Mobile Number",
        type: "text",
        required: true,
        schema: yup.number().nullable(),
      },

      operational_head_contact_person_name: {
        label: "SPOC / Operational Head Name",
        type: "text",
        required: true,
        schema: yup.string(),
      },
      operational_head_contact_person_email: {
        label: "Operational Head Contact Email",
        type: "text",
        required: true,
        schema: yup.string().email(),
      },
      operational_head_contact_person_mobile_number: {
        label: "Operational Head Contact Mobile Number",
        type: "text",
        required: true,
        schema: yup.number().nullable(),
      },

      logo: {
        label: "Partner Logo",
        type: "file",
        schema: fileSchema,
      },
      active: {
        label: "Active",
        type: "boolean",
      },
    };
    setSchema(schema);
  }, [values]);

  return schema;
}

export default PartnerFormBuilder;
