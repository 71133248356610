import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import CouponCodesTable from "./components/CouponCodesTable";
import { currentModuleAccessRecoilVar } from "State/Navigation";
import {
  GET_ALL_COUPONS,
  GET_PARTNER_COUPONS,
  GET_TOTAL_PARTNER_COUPON_CODES,
  GET_TOTAL_COUPON_CODES,
} from "../../../GraphQl/Queries/CourseCoupons";
import {
  gqlUserRecoilVar,
  userAccessibleModulesRecoilVar as userRole,
} from "State/User";
import { useRecoilState } from "recoil";
import { userRoles } from "api/Consts";
import Container from "components/Container/Container";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";

function ManageCouponCodes() {
  const history = useHistory();

  const [user] = useRecoilState(gqlUserRecoilVar);
  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);
  const allowedPartnerIds = useMemo(
    () => Object.keys(currentModuleAccess?.allowedPartners || {}),
    [currentModuleAccess]
  );

  const fetchCouponsQuery = user.is_skillstrainer_admin
    ? GET_ALL_COUPONS
    : GET_PARTNER_COUPONS;
  const fetchCouponsCountQuery = user.is_skillstrainer_admin
    ? GET_TOTAL_COUPON_CODES
    : GET_TOTAL_PARTNER_COUPON_CODES;

  // Fetching all coupons
  const allCoupons = useQuery(fetchCouponsQuery, {
    variables: {},
  });

  // Get number of coupon codes
  const totalCouponCodes = useQuery(fetchCouponsCountQuery, {
    variables: {},
  });

  if (!user.is_skillstrainer_admin) {
    allCoupons.refetch({
      partner_ids: allowedPartnerIds,
    });
    totalCouponCodes.refetch({
      partner_id: allowedPartnerIds,
    });
  }

  // Going to coupon editing page
  const updateCourseCouponCode = (selectedCouponCode) =>
    history.push(
      getModulePathStringById(ModuleNameIdMap.createUpdateCouponCode) +
        "?id=" +
        selectedCouponCode.id
    );

  return (
    <Container>
      <h2 className="text-3xl font-bold">Manage coupon codes</h2>

      <CouponCodesTable
        canEdit={user.is_skillstrainer_admin}
        allCoupons={allCoupons}
        totalCouponCodes={totalCouponCodes}
        updateCourseCouponCode={updateCourseCouponCode}
      />
    </Container>
  );
}

export default ManageCouponCodes;
