import { gql } from "@apollo/client";
export const GET_COURSE_SECTION = gql`
  query getcoursesection($course_id: Int, $course_id_big: bigint) {
    courses_course_section(
      where: { course_id: { _eq: $course_id } }
      order_by: { order: asc }
    ) {
      name
      id
      course_id
      visible
      description

      coursesec(order_by: { order: asc }) {
        name
        description
        mod_id
        id
        publish_url
        section_id
        completion_percentage
        completion_criteria
        visible
        enable_timer
        require_webcam
        enable_user_attempt
        Module {
          name
          type
        }
        course_id
        scorm_version
        upload_url
        file_name
        maxattempt
        attemtsgrade
        moodle_published
        timelimit_number
        scorm_completion_criteria {
          completed
          enable_min_score
          mapping_id
          min_score
          passed
          scorm_version
        }
        restriction {
          batch_id
          mapping_id
          restriction_ids
          course_id
        }
        course_question_link {
          course_id
          module_mapping_id
          question_id
        }
        resources {
          id
          file_name
          mapping_id
          name
          created_at
        }
        module_resources {
          actual_file_name
          file_name
          id
          mapping_id
        }
      }
    }
    courses_course(where: { id: { _eq: $course_id_big } }) {
      full_name
    }
    courses_certificate_template_course_partner_relation(
      where: { course_id: { _eq: $course_id_big } }
      order_by: { partner_id: asc }
    ) {
      certificate_template_id
      course_id
      partner_id
      id
      partner {
        name
      }
    }
  }
`;

export const GET_COURSE_MODULE = gql`
  query getCourseModule {
    courses_course_module(
      order_by: { id: desc }
      where: { visible: { _eq: true } }
    ) {
      id
      name
      type
      visible
      discription
    }
  }
`;

export const GET_COURSE_SECTION_MODULE = gql`
  query getSectionModule($course_id: Int, $section_id: Int) {
    courses_course_module_mapping(
      where: {
        course_id: { _eq: $course_id }
        section_id: { _eq: $section_id }
      }
    ) {
      id
      name
      description
      section_id
      Module {
        name
        id
      }
      section {
        name
        id
      }
    }
  }
`;
export const GET_COURSE_SCORM_PUBLISH_URL = gql`
  query getScormPublishUrl($id: Int) {
    courses_course_module_mapping(where: { id: { _eq: $id } }) {
      publish_url
    }
  }
`;

export const CHECK_MOD_TYPE = gql`
  query checkModType($id: Int) {
    courses_course_module(where: { id: { _eq: $id } }) {
      type
    }
  }
`;

export const GET_COURSE_TOPICS = gql`
  query getCourseTopics($course_id: Int) {
    courses_course_module_mapping(
      where: { course_id: { _eq: $course_id } }
      order_by: { order: asc }
    ) {
      name
      id
      course_id
      section {
        name
      }
      Module {
        type
      }
    }
  }
`;

export const INSERT_TOPIC_COMPLETION_RESTRICTION = gql`
  mutation InsertTopicCompletionRestriction(
    $course_id: Int
    $mapping_id: Int
    $restriction_ids: String
    $batch_id: bigint
  ) {
    insert_courses_module_completion_resrtriction(
      objects: {
        restriction_ids: $restriction_ids
        mapping_id: $mapping_id
        course_id: $course_id
        batch_id: $batch_id
      }
    ) {
      returning {
        id
        mapping_id
        course_id
      }
    }
  }
`;

export const GET_COURSE_LESSON_MODULE = gql`
  query MyQuery($course_id: Int, $id: Int) {
    courses_course_module_mapping(
      where: { course_id: { _eq: $course_id }, id: { _eq: $id } }
    ) {
      name
      description
      id
      course_question_link {
        question_id
      }
    }
  }
`;

export const GET_COURSE_MODULE_DATA = gql`
  query MyQuery($id: Int) {
    courses_course_module_mapping(where: { id: { _eq: $id } }) {
      course_module {
        full_name
        nsqf_level
        course_partner {
          partner {
            id
            name
          }
        }
      }
    }
  }
`;
export const COURSE_TOPIC_LISTS = gql`
  query CourseTopicLists($course_id: Int) {
    courses_course_module_mapping(where: { course_id: { _eq: $course_id } }) {
      description
      name
      id
      section_id
    }
  }
`;

export const GET_MODULE_DETAILS_BY_ID = gql`
  query GetModuleDetailsById($id: Int) {
    courses_course_module_mapping(where: { id: { _eq: $id } }) {
      attemtsgrade
      completion_criteria
      completion_percentage
      course_id
      description
      enable_timer
      file_name
      maxattempt
      mod_id
      moodle_published
      name
      publish_url
      scorm_version
      section_id
      timelimit_number
      upload_url
      visible
      require_webcam
      system_filename
      enable_user_attempt
      scorm_completion_criteria {
        completed
        enable_min_score
        mapping_id
        min_score
        passed
        scorm_version
      }
      module_resources {
        actual_file_name
        file_name
        mapping_id
        id
      }
      section {
        name
      }
    }
  }
`;

export const GET_MODULE_RESTRICTION = gql`
  query ModuleRestriction($mapping_id: Int) {
    courses_module_completion_resrtriction(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      restriction_ids
      batch_id
    }
  }
`;

export const GET_SCORM_COMPLETION_CRITERIA_BY_ID = gql`
  query MyQuery($mapping_id: Int) {
    courses_course_scorm_completion_criteria(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      passed
      scorm_version
      mapping_id
      completed
      min_score
      enable_min_score
    }
  }
`;

export const GET_COURSE_COMPLETION_MODULE = gql`
  query GetCourseCompletionModule($course_id: Int) {
    courses_course_completion_criteria(
      where: { course_id: { _eq: $course_id } }
    ) {
      mapping_id
    }
  }
`;

export const GET_USERS_SCORM_PROGRESS_BY_TOPIC_ID = gql`
  query MyQuery($id: Int) {
    courses_course_module_mapping(where: { id: { _eq: $id } }) {
      scorm_track_data {
        attempt_id
        scorm_attempt {
          attempt_completed
          attempt_end
          attempt_number
          attempt_start
          course_id
        }
        scorm_attempt_array(order_by: { id: asc }) {
          correct_answers
          question_id
          question_text
          result
          user_response
          user_id
        }
        score_min
        score_raw
        lesson_status
        user {
          email
          id
          name
          mobile_number
          address {
            adress_type
            city_town
            country
            country_iso_code
            district
            house_number
            location
            pincode
            state
            user_academic_qualifications {
              institution_name
              qualification_id
              qualification_name
              qualification_type
            }
          }
          date_of_birth
          gender
          user_family_details {
            member_type
            name
            occupation
          }
          user_academic_qualifications {
            qualification_name
            qualification_type
            qualification_id
            institution_name
            qualifications_lookup {
              name
            }
          }
          social_category
        }
      }
    }
  }
`;

export const GET_USERS_QUIZ_PROGRESS_BY_TOPIC_ID = gql`
  query MyQuery($id: Int) {
    courses_course_module_mapping(where: { id: { _eq: $id } }) {
      quiz_attempt_data(where: { mapping_id: { _eq: $id } }) {
        attempt_start
        attempt_percentage
        attempt_number
        attempt_end
        attempt_completed
        mapping_id
        total_score
        result
        user_id
        questionattemptArray {
          attempt_quiz_id
          mapping_id
          marks
          question_correct_answer
          question_id
          response
          user_answer_text
          user_id
          quiestionsobject {
            question_text
            question_type
            mode
          }
        }
        user {
          email
          id
          name
          mobile_number
          address {
            adress_type
            city_town
            country
            country_iso_code
            district
            house_number
            location
            pincode
            state
            user_academic_qualifications {
              institution_name
              qualification_id
              qualification_name
              qualification_type
            }
          }
          date_of_birth
          gender
          user_family_details {
            member_type
            name
            occupation
          }
          user_academic_qualifications {
            qualification_name
            qualification_type
            qualification_id
            institution_name
            qualifications_lookup {
              name
            }
          }
          social_category
        }
      }
    }
  }
`;
