import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_BATCH_MEDIA_BY_ID } from "GraphQl/Queries/BatchesQueries";
import { Modal } from "skillstrainer-resource-library";
import MediaPreview from "./MediaPreview";
import DataTable from "react-data-table-component";
import { getFile } from "api/UploadFile";

export default function ShowBatchMedia(props) {
  const [batchMedia, setBatchMedia] = useState([]);
  const [previewData, setPreviewData] = useState();
  const [getBatchMediaById] = useLazyQuery(GET_BATCH_MEDIA_BY_ID);

  const batchId = props.batch.id;
  const getMediaFile = (args) =>
    getFile(args, true, "assets").then((res) => console.log(res) || res);
  useEffect(() => {
    getBatchMediaById({
      variables: {
        batchId: batchId,
      },
    }).then((res) => {
      const response = res.data.courses_batch_media_relation || [];
      const tableValues = response.map((item) => {
        return {
          id: item.id,
          remark: item.remark || undefined,
          name: item.media?.file_name || "",
          url: item.media?.file_url,
          type: item.media?.file_type || "",
        };
      });

      setBatchMedia(tableValues);
    });
  }, [batchId]);

  const columns = [
    {
      name: "ID",
      selector: (row) => `${row.id}`,
      sortable: true,
      width: "5rem",
    },

    {
      name: "File Name",
      selector: (row) => `${row.name}`,
      sortable: true,
      width: "12rem",
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => `${row.type}`,
      width: "10rem",
    },
    {
      name: "Remark",
      selector: (row) => `${row.remark}`,
      sortable: true,
      wrap: true,
      width: "16rem",
    },
    {
      name: "View",
      width: "20rem",
      cell: (row) => (
        <div className="flex w-full my-1">
          <button
            className="secondary-cta mx-2 "
            onClick={async () => {
              const data = await getMediaFile(row.url);
              setPreviewData({ data });
            }}
          >
            View File
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <DataTable columns={columns} pagination data={batchMedia} />
      <Modal isOpen={previewData} onClose={() => setPreviewData(null)}>
        <MediaPreview {...previewData} />
      </Modal>
    </div>
  );
}
