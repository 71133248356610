import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import CurrentPage from "./CurrentPage";

const DashboardRoutes = () => {
  const navbarHeight = 55;

  return (
    <div className="w-screen h-screen flex flex-col">
      <div style={{ height: navbarHeight + "px" }}>
        <Navbar />
      </div>
      <div
        style={{ height: `calc(100vh - ${navbarHeight}px)` }}
        className="w-full flex"
      >
        <div className="xl:w-1/5 w-1/4 h-full">
          <Sidebar />
        </div>
        <div className="xl:w-4/5 w-3/4 h-full overflow-y-auto">
          <CurrentPage />
        </div>
      </div>
    </div>
  );
};

export default DashboardRoutes;
