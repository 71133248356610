import { useMemo, useRef, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router";
import {
  DELETE_PARTNER_REVENUE_SPLIT_DIRECTIVES,
  UPSERT_PARTNER_REVENUE_SPLIT_DIRECTIVES,
  UPSERT_PARTNER_WEBSITE_CONFIGURATION,
  UPSERT_PARTNER_WEBSITE_CONFIGURATION_TUPLES,
} from "GraphQl/Mutations/Partner";
import {
  GET_PARTNERS,
  GET_PARTNER_WEBSITE_CONFIG_BY_PARTNER_ID,
} from "GraphQl/Queries/Partner";
import PartnerWebsiteConfigFormBuilder from "formBuilders/PartnerWebsiteConfigFormBuilder";
import { Form } from "skillstrainer-resource-library";
import { errorHandler, successHandler } from "utils/toast";
import Select from "components/InputGroup/Select";
import queryString from "query-string";
import _ from "lodash";
import { arrayDelta } from "utils/func";
import PartnerMarketingWebsiteStatus from "./components/PartnerMarketingWebsiteStatus";
import api from "api/Api";
import { getFile, uploadLargeFile } from "api/UploadFile";
import { error } from "utils/error";
import { UPLOAD_COLLECTIONS } from "api/Consts";
import Container from "components/Container/Container";

const partnerConfigKeyToTypeMap = {
  partner_website_link: "text",
  hero_section_headline: "text",
  partner_display_name: "text",
  hero_section_font_size: "text",
  partner_display_logo: "media",
  associated_partners: "json",
  sliders: "json",
};

function PartnerWebsiteConfig() {
  // Fetch all partners' data
  const { data: partnerData } = useQuery(GET_PARTNERS);

  const [selectedPartnerId, setSelectedPartnerId] = useState();
  const { data: partnerConfigRes, refetch: refetchPartnerConfig } = useQuery(
    GET_PARTNER_WEBSITE_CONFIG_BY_PARTNER_ID,
    { variables: { partnerId: selectedPartnerId } }
  );

  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (params?.partnerId) {
      setSelectedPartnerId(params.partnerId);
    }
  }, [params?.partnerId]);

  const partnerConfig =
    partnerConfigRes?.courses_partner_website_configuration[0];

  const partnerConfigInitValues = useMemo(() => {
    const config = _.cloneDeep(partnerConfig);
    if (config) {
      // Config tuples
      const ignoreTuples = [];
      config.partner_website_configuration_tuples.reduce((acc, cur) => {
        if (!ignoreTuples.includes(cur.partner_data_key)) {
          cur = _.cloneDeep(cur);
          acc[cur.partner_data_key] = formatConfigTupleValue(
            cur.partner_data_key,
            cur.partner_data_value
          );
        }

        return acc;
      }, config);

      // Partner courses
      config.courses = config.partner_revenue_split_directives.map((d) =>
        _.pick(d, ["id", "course_id", "commission", "partner_id"])
      );

      return _.pick(config, [
        "id",
        "subdomain",
        ...Object.keys(partnerConfigKeyToTypeMap),
        "courses",
      ]);
    }
    return {};
  }, [partnerConfig]);

  const formRef = useRef({});

  const [upsertPartnerWebsiteConfig] = useMutation(
    UPSERT_PARTNER_WEBSITE_CONFIGURATION
  );
  const [upsertPartnerWebsiteConfigTuples] = useMutation(
    UPSERT_PARTNER_WEBSITE_CONFIGURATION_TUPLES
  );
  const [upsertPartnerRevenueSplitDirectives] = useMutation(
    UPSERT_PARTNER_REVENUE_SPLIT_DIRECTIVES
  );
  const [deletePartnerRevenueSplitDirectives] = useMutation(
    DELETE_PARTNER_REVENUE_SPLIT_DIRECTIVES
  );

  const submit = async (res) => {
    try {
      const isUpdating = !!partnerConfig;

      /*
       *
       *
       * Handling config
       *
       *
       */
      const config = _.pick(res, ["id", "subdomain"]);
      config.partner_id = selectedPartnerId;
      config.edited_at = new Date().toISOString();
      config.status = "unpublished";

      const configUpsertionResponse = await upsertPartnerWebsiteConfig({
        variables: { config },
      }).then(
        (res) => res.data.insert_courses_partner_website_configuration_one
      );
      console.log("Config Upsertion Response", configUpsertionResponse);
      Object.assign(config, configUpsertionResponse || {});

      /*
       *
       *
       * Handling config tuples
       *
       *
       */
      const tuples = [];
      for (const tupleKey of Object.keys(partnerConfigKeyToTypeMap)) {
        const tuple = {
          config_id: config.id,
          partner_id: selectedPartnerId,
          partner_data_key: tupleKey,
          partner_data_value: unformatConfigTupleValue(tupleKey, res[tupleKey]),
        };

        if (isUpdating) {
          const oldTuple =
            partnerConfig &&
            partnerConfig.partner_website_configuration_tuples.find(
              (t) => t.partner_data_key === tupleKey
            );
          tuple.id = oldTuple && oldTuple.id;
        }

        tuples.push(tuple);
      }
      const configTuplesUpsertionResponse =
        await upsertPartnerWebsiteConfigTuples({
          variables: { objects: tuples },
        }).then(
          (res) => res.data.insert_courses_partner_website_configuration_tuples
        );
      console.log(
        "Config tuples upsertion response",
        configTuplesUpsertionResponse
      );

      /*
       *
       *
       * Revenue split directives
       *
       *
       */
      let upsertableDirectives = [];

      if (isUpdating) {
        const {
          created: createdDirectives,
          deleted: deletedDirectives,
          persistant: persistantDirectives,
        } = arrayDelta(
          partnerConfig.partner_revenue_split_directives,
          res.courses,
          ["course_id", "partner_id"]
        );

        upsertableDirectives = createdDirectives
          .map((e) => ({
            ...e,
            partner_id: selectedPartnerId,
            commission: e.commission || 0,
          }))
          .concat(persistantDirectives);

        // Deleting removed directives
        const directivesDeletionResponse =
          await deletePartnerRevenueSplitDirectives({
            variables: { directiveIds: deletedDirectives.map((e) => e.id) },
          }).then(
            (res) => res.data.delete_courses_partner_revenue_split_directives
          );
        console.log(
          "Partner revenue split directives deletion response",
          directivesDeletionResponse
        );
      } else
        upsertableDirectives = res.courses.map((d) => ({
          ...d,
          partner_id: selectedPartnerId,
          commission: d.commission || 0,
        }));

      const upsertDirectivesResponse =
        await upsertPartnerRevenueSplitDirectives({
          variables: {
            directives: upsertableDirectives,
          },
        }).then((res) => res.data);
      console.log(
        "Handle Partner Revenue split directives response",
        upsertDirectivesResponse
      );

      /*
       *
       *
       * Finish
       *
       *
       */
      successHandler("Successfully updated partner website config!");
      await refetchPartnerConfig();
    } catch (err) {
      errorHandler("Couldn't submit form");
      console.error(err);
    }
  };

  const [disablePublish, setDisablePublish] = useState();
  const publishWebsite = () => {
    setDisablePublish(true);
    api()
      .post("/publish-partner-website", { partner_id: selectedPartnerId })
      .then(({ data }) => {
        if (data.success) successHandler("Publish started");
        else throw data;
      })
      .catch((err) => {
        console.log(err);
        errorHandler("An error occurred while starting publish");
      })
      .finally(() => setDisablePublish());
  };

  window.logFormRef = () => console.log(formRef);

  return (
    <Container title={"Partner Website Configuration"} className="mx-4 my-4">
      <Select
        label={"Partner"}
        className={"w-1/2 mt-4"}
        key={selectedPartnerId}
        options={partnerData?.courses_partner}
        valueField={"id"}
        value={selectedPartnerId}
        displayField={"name"}
        onChange={(e) => {
          setSelectedPartnerId(e.target.value);
          history.push("?partnerId=" + e.target.value);
        }}
      />
      {selectedPartnerId && (
        <>
          <div className="ml-6 my-5">
            <PartnerMarketingWebsiteStatus
              key={selectedPartnerId}
              partnerId={selectedPartnerId}
            />
          </div>
          <Form
            key={partnerConfigInitValues?.id || "new-form"}
            formBuilder={PartnerWebsiteConfigFormBuilder}
            className=" ml-6 mt-2 mb-4 grid grid-cols-2 gap-x-2"
            hideSubmit={true}
            onSubmit={submit}
            initValues={partnerConfigInitValues}
            ref={(e) => (formRef.current = e)}
            plugins={{
              file: {
                services: {
                  uploadFn: (fileData) =>
                    console.log(fileData) ||
                    uploadLargeFile(fileData, fileData.name, true)
                      .then((res) => res.data_url)
                      .catch((e) => {
                        errorHandler("An error occured while uploading");
                        throw error("Couldn't upload file", e.data || e);
                      }),
                  getUrl: (args) =>
                    getFile(args, true, UPLOAD_COLLECTIONS.assets).then(
                      (res) => console.log(res) || res
                    ),
                },
              },
            }}
          />
          <div className="ml-6 mt-4">
            <button
              onClick={() => formRef.current.submit()}
              className="btn-primary mr-3"
            >
              Save changes
            </button>
            <button
              onClick={publishWebsite}
              disabled={disablePublish}
              className="btn-primary bg-green-500"
            >
              Publish
            </button>
          </div>
        </>
      )}
    </Container>
  );
}

export default PartnerWebsiteConfig;

const formatConfigTupleValue = (key, value) => {
  const type = partnerConfigKeyToTypeMap[key];

  if (type === "media")
    value = value && [
      { id: new Date().getTime() + "", name: "partner-logo", url: value },
    ];
  else if (type === "json") value = value && JSON.parse(value);

  return value;
};

const unformatConfigTupleValue = (key, value) => {
  const type = partnerConfigKeyToTypeMap[key];

  if (type === "media") value = value && value[0] && value[0].url;
  else if (type === "json") value = JSON.stringify(value);

  return value;
};
