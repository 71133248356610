import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import Select from "components/InputGroup/Select";
import ReactSelect from "react-select";
import { QuizFilterDbSchema } from "./ActivityDbSchema";
import { GET_QUESTION_CATEGORY } from "GraphQl/Queries/CourseBuilder/QuestionsCategory";
import { GET_QUESTION_TAGS } from "GraphQl/Queries/CourseBuilder/QuestionsCategory";

const QuizFilterForm = ({ handleChange, updateValue, onSubmit, onreset }) => {
  const questionCategories = useQuery(GET_QUESTION_CATEGORY);
  const questionTagsQuery = useQuery(GET_QUESTION_TAGS);

  const [category, setCategory] = useState([]);
  const [questionTags, setQuestionTags] = useState([]);

  useEffect(() => {
    if (questionCategories.data) {
      console.log(questionCategories.data);
      const categoryOptions =
        questionCategories.data?.courses_question_category.map((inst, i) => {
          return { value: inst.id, label: inst.name };
        });
      setCategory(categoryOptions);
    }
  }, [questionCategories]);

  useEffect(() => {
    if (questionTagsQuery.data) {
      const tagOptions = questionTagsQuery.data?.courses_question_tags.map(
        (inst, i) => {
          return { value: inst.id, label: inst.name };
        }
      );

      setQuestionTags(tagOptions);
    }
  }, [questionTagsQuery]);

  const qeustionType = [
    { value: "1", label: "Multiple Choice Question" },
    { value: "2", label: "Multiple Select option" },
    { value: "3", label: "Drag and Drop" },
  ];

  return (
    <form onSubmit={onSubmit}>
      <div className="shadow overflow-hidden sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="flex flex-wrap ">
            {QuizFilterDbSchema.map(({ label, column_name, type }) => (
              <div className={`${type === "select" ? "w-1/2" : ""} mt-4`}>
                {type === "select" && (
                  <Select
                    className={"px-2"}
                    label={label}
                    options={
                      column_name == "question_category"
                        ? category
                        : column_name == "question_type"
                        ? qeustionType
                        : []
                    }
                    valueField={"value"}
                    displayField={"label"}
                    data-key={column_name}
                    onChange={(e) => updateValue(e)}
                  />
                )}
                {/* {type === "multiselect" && (
                  <label className="block text-sm font-medium text-gray-700 px-2">
                    Select Tags
                    <ReactSelect
                      className="pt-1"
                      options={questionTags ? questionTags : []}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      allowSelectAll={true}
                      onChange={handleChange}
                    />
                  </label>
                )} */}
              </div>
            ))}
          </div>
          {/* {message && (
          <div className="text-green-600 text-base mt-2">{message}</div>
        )} */}
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 space-x-4">
          {
            <button onClick={onreset} type="reset" className="btn-secondary">
              Reset
            </button>
          }
          <button type="submit" className="btn-primary">
            Search
          </button>
        </div>
      </div>
    </form>
  );
};
export default QuizFilterForm;
