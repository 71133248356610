import Container from "components/Container/Container";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import Input from "components/InputGroup/Input";

export default function CourseResources(props) {
  return (
    <div>
      <div className={`col-span-6 sm:col-span-3 mt-4`}>
        <Input
          label={"Add Resource Name(*)"}
          type={"text"}
          data-key={"name"}
          onChange={(e) => props.updateValue(e)}
          value={props.resource["name"]}
        />
      </div>
      <div className={`col-span-6 sm:col-span-3 mx-4 mt-4`}>
        <label className="block text-sm font-medium text-japanese_indigo">
          Upload Resource File Here(*)
        </label>
        <FileUploader
          handleChange={(file) => props.resourceFilehandleChange(file)}
          name="file"
          classes={"drop_area drop_zone"}
        />
        <p>
          {props.uploadedFile
            ? `Uploaded File Name: ${props.uploadedFile}`
            : ""}
          {props.resourceFile ? `File name: ${props.resourceFile.name}` : ""}
        </p>
      </div>
      <div className="px-4 py-3 text-right sm:px-6">
        <button
          type="submit"
          className="primary-cta btn-primary hover:bg-opacity-90"
          onClick={() =>
            props.resource["id"]
              ? props.updateResource(props.resource["id"])
              : props.addResource()
          }
        >
          Save
        </button>
      </div>
    </div>
  );
}
