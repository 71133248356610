import React, { useState } from "react";
import DataTable from "react-data-table-component";

export default function PartnerCommissionTransaction(props) {
  const { partnerDirectiveFilter } = props;

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });
  const columns = [
    {
      name: "Partner Id",
      selector: (row, index) => `${row.partner_id}`,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Partner Name",
      selector: (row, index) => `${row.partner.name}`,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Course Name",
      selector: (row, index) => `${row.directive?.course.full_name}`,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Order Id",
      selector: (row, index) => `${row.order_id}`,
      sortable: true,
      width: "15rem",
    },

    {
      name: "Amount",
      selector: (row, index) => `${row.order ? row.order.amount : null}`,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Commission",
      selector: (row, index) => `${row.directive?.commission}%`,
      sortable: true,
      width: "15rem",
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  return (
    <DataTable
      columns={columns}
      pagination
      data-key={columns}
      data={partnerDirectiveFilter ? partnerDirectiveFilter : []}
      paginationTotalRows={20}
      selectableRows={false}
      selectableRowsHighlight={false}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
}
