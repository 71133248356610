import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { INSTRUCTOR_DISPLAY_SCHEMA } from "./DbSchema";
import { useRecoilState } from "recoil";
import { gqlUserRecoilVar } from "State/User";

const InstructorTable = ({ handleEdit, data, loading, className }) => {
  const [user] = useRecoilState(gqlUserRecoilVar);

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 100,
  });
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    var table_columns = [];
    INSTRUCTOR_DISPLAY_SCHEMA.map(({ column_name, label }) =>
      table_columns.push({
        name: label,
        selector: (row, index) => `${row[column_name]}`,
      })
    );

    if (user?.is_skillstrainer_admin)
      table_columns.push({
        cell: (row) => (
          <button
            className="bg-gray-700 p-2 text-base text-white"
            onClick={(e) => handleEdit(e, row)}
          >
            Edit
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      });

    setColumns(table_columns);
  }, [user]);

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  return (
    <DataTable
      className={className}
      columns={columns}
      data={data ? data.courses_instructor : []}
      progressPending={loading}
      pagination
      paginationTotalRows={data ? data.courses_instructor.length : 0}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

export default InstructorTable;
