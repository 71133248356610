import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useMutation, useLazyQuery } from "@apollo/client";
import queryString from "query-string";
import { Form } from "skillstrainer-resource-library";
import { errorHandler, successHandler } from "utils/toast";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";
import InstituteFormBuilder from "formBuilders/InstituteFormBuilder";
import { UPSERT_INSTITUTES } from "GraphQl/Mutations/Repositories";
import { GET_INSTITUTE_BY_ID } from "GraphQl/Queries/Repositories";
import Container from "components/Container/Container";

export default function CreateUpdateInstitute() {
  const [institute, setInstitute] = useState({ id: null });

  const history = useHistory();
  const params = queryString.parse(history?.location?.search);

  const [upsertInstituteMutation] = useMutation(UPSERT_INSTITUTES);
  const [getInstituteById] = useLazyQuery(GET_INSTITUTE_BY_ID);

  useEffect(() => {
    if (params.instituteId) {
      getInstituteData(params.instituteId);
    }
  }, [params.instituteId]);

  const getInstituteData = (instituteId) => {
    getInstituteById({ variables: { id: instituteId } }).then((res) => {
      const data = res?.data?.courses_institutes[0];
      const instituteAddress = {
        country: data.country,
        city_town: data.city_town,
        district: data.district,
        pincode: data.pincode,
        location: data.location,
        house_number: data.house_number,
        state: data.state,
      };
      const instituteObject = {
        name: data.name,
        category: data.category,
        address: instituteAddress,
      };
      setInstitute(instituteObject);
    });
  };

  const upsertInstitute = async (institute) => {
    const instituteAddress = institute.address;
    const insertObject = {
      ...institute,
      ...instituteAddress,
      address: undefined,
    };
    if (params.instituteId) insertObject.id = params.instituteId;
    await upsertInstituteMutation({
      variables: {
        objects: insertObject,
      },
    })
      .then((res) => {
        if (!params.instituteId) {
          successHandler("Institute created successfully");
          history.push(
            getModulePathStringById(ModuleNameIdMap.createUpdateInstitute) +
              "/?instituteId=" +
              res.data.insert_courses_institutes.returning[0]?.id
          );
        } else successHandler("Institute updated successfully");
      })
      .catch((err) => {
        console.log(err);
        if (params.instituteId)
          errorHandler("there was an error while updating institute");
        else errorHandler("there was an error while creating institute");
      });
  };

  return (
    <Container title={"Create / Update Institutes"} className="my-4 mx-4">
      <Form
        key={institute.id}
        formBuilder={InstituteFormBuilder}
        className="mt-2 mb-4 grid grid-cols-2 gap-x-2"
        submitButton={{
          text: "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
        }}
        onSubmit={(institute) => upsertInstitute(institute)}
        initValues={institute}
      />
    </Container>
  );
}
