import { Form } from "skillstrainer-resource-library";
import SkillsFormBuilder from "formBuilders/SkillsFormBuilder";
import { useMutation, useLazyQuery } from "@apollo/client";
import { CREATE_SKILLS } from "GraphQl/Mutations/Jobs";
import SkillsTable from "./SkillsTable";
import { useState, useEffect } from "react";
import { UPDATE_SKILL_MUTATION } from "../../../GraphQl/Mutations/Jobs";
import {
  GET_SKILLS,
  GET_SKILL_BY_ID,
  GET_SKILL_SEARCH,
} from "../../../GraphQl/Queries/Jobs";
import Input from "components/InputGroup/Input";
import { errorHandler, successHandler } from "utils/toast";
import { client } from "App";
import Container from "components/Container/Container";

function CreateSkills() {
  const [insertSkills] = useMutation(CREATE_SKILLS, {
    refetchQueries: [GET_SKILLS],
  });
  const [search, setSearch] = useState({ title: "" });

  const [selectedSkill, setSelectedSkill] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  const [insertId, setInsertId] = useState();

  const queryParametersId = new URLSearchParams(window.location.search);
  let currentSkillId = queryParametersId.get("id");

  if (insertId) {
    currentSkillId = insertId;
  }

  const [runQuery, { loading: skillLoading, data: skillData }] = useLazyQuery(
    GET_SKILL_SEARCH,
    { fetchPolicy: "network-only" }
  );

  // Write param code
  useEffect(() => {
    client
      .query({
        query: GET_SKILL_BY_ID,
        variables: {
          id: currentSkillId,
        },
      })
      .then((res) => {
        setSelectedSkill(res.data.courses_skillstrainer_skills[0]);
      });
    if (currentSkillId) {
      setIsUpdate(true);
    }
  }, [currentSkillId]);

  const createSkills = async (skills) => {
    const insertSkillsObject = {
      ...skills,
    };

    insertSkills({ variables: insertSkillsObject })
      .then((res) => {
        setInsertId(res?.data?.insert_courses_skillstrainer_skills_one.id);
        successHandler("New Skill Created Successfully");
      })
      .catch((err) => {
        errorHandler("There was an error while creating a New Skill");
      });
  };

  const [update_skill, mutatioData] = useMutation(UPDATE_SKILL_MUTATION, {
    onCompleted: () => successHandler("Skill Successfully Updated"),
    onError: (err) =>
      errorHandler("An error occured while updating Skill") ||
      console.error(err),
  });

  const updateSkill = async (skillsData) => {
    const updateSkillObj = {
      ...skillsData,
      id: currentSkillId,
    };

    update_skill({
      variables: updateSkillObj,
    });
  };
  const updateSelectedSkills = (selectedSkills) => {
    setIsUpdate(true);

    const courseObj = {
      ...selectedSkills,
      name: selectedSkills.name,
      label: selectedSkills.label,
    };

    setSelectedSkill(courseObj);
  };

  useEffect(() => {
    SearchUsers();
  }, []);

  const SearchUsers = (e) => {
    let variables = { where: {} };
    if (search.name) variables.where.name = { _ilike: `%${search.name}%` };
    runQuery({ variables: variables });
  };

  return (
    <Container
      title={"Create / Update Skills"}
      className="ml-6 mr-12 mt-4 mb-8"
    >
      <Form
        formBuilder={SkillsFormBuilder}
        className="mt-2 grid grid-cols-2 gap-x-6"
        submitButton={{
          text: "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
        }}
        onSubmit={
          !isUpdate
            ? (skills) => createSkills(skills)
            : (skills) => updateSkill(skills)
        }
        key={selectedSkill?.id}
        initValues={selectedSkill || {}}
      />
      <div className="d-flex mt-5 pt-3">
        <Input
          label={"Search By Name"}
          value={search.title}
          onChange={(e) => {
            setSearch({ ...search, name: e.target.value });
          }}
          type={"text"}
          placeholder={"Enter Skill Name"}
        />
        <button className="btn-primary my-4 ml-5" onClick={SearchUsers}>
          Search
        </button>
      </div>
      {!skillLoading && skillData ? (
        <SkillsTable
          skillsData={skillData.courses_skillstrainer_skills}
          updateSelectedSkills={(selectedSkills) => {
            updateSelectedSkills(selectedSkills);
          }}
        />
      ) : (
        <>
          <p>Please wait, fetching projects...</p>
        </>
      )}
    </Container>
  );
}

export default CreateSkills;
