import React, { useEffect, useState } from "react";
import { default as ReactSelect } from "react-select";
import {
  GET_PARTNERS,
  GET_PARTNER_DIRECTIVES_TRANSACTION,
  GET_PARTNER_SETTLEMENT,
} from "GraphQl/Queries/Partner";
import { COURSES_QUERY, GET_PARTNER_COURSES } from "GraphQl/Queries/Courses";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import PartnerCommissionTransaction from "./PartnerCommissionTransaction";
import PartnerSettlement from "./PartnerSettlement";
import { INSERT_PARTNER_SETTLEMENT } from "GraphQl/Mutations/Partner";
import { successHandler, errorHandler } from "utils/toast";
import DateTime from "components/InputGroup/DateTime";
import moment from "moment";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";
import Container from "components/Container/Container";

export default function PartnerCommission() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [partnerDirectiveFilter, setPartnerDirective] = useState();
  const [settlementValue, setSettlementValue] = useState({});
  const [fromDate, setFromDate] = useState();
  const [fromTo, setToDate] = useState();
  const [dateFilter, setDateFilter] = useState({});
  const [partnerValue, setPartnerValue] = useState([]);
  const [courseValue, setCourseValue] = useState([]);
  const [owed, setOwed] = useState(0);

  //Intial Render
  useEffect(() => {
    const graphql_exp = {};
    runQuery({
      variables: graphql_exp,
    });

    runSettlementQuery({
      variables: graphql_exp,
    });
  }, []);

  const partnerAccess = useUserPartnerAccessDetails();

  const accessablePartners = (partnerAccess.partnerIds || []).filter(
    (item, index) => partnerAccess.partnerIds.indexOf(item) === index
  );

  // From and to date validation
  const filterPassedTime = (time, name) => {
    const currentDate =
      name == "to_time" && fromDate ? new Date(fromDate) : new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  // Handle onchange from and to date
  const updateStateValue = (key, value) => {
    dateFilter[key] = value;
    setDateFilter({ ...dateFilter });
  };

  // Get partners directive transaction
  const [runQuery, partnerDirective] = useLazyQuery(
    GET_PARTNER_DIRECTIVES_TRANSACTION,
    {
      fetchPolicy: "network-only",
      variables: partnerAccess.isAdmin
        ? {}
        : {
            where: {
              partner_id: {
                _in: accessablePartners,
              },
            },
          },
    }
  );

  // Get partners settlement transaction
  const [runSettlementQuery, partnerSettlementData] = useLazyQuery(
    GET_PARTNER_SETTLEMENT,
    {
      fetchPolicy: "network-only",
      variables: partnerAccess.isAdmin
        ? {}
        : {
            where: {
              partner_id: {
                _in: accessablePartners,
              },
            },
          },
    }
  );

  // Insert Partner Settlement
  const [insertSettlement] = useMutation(INSERT_PARTNER_SETTLEMENT, {
    refetchQueries: () => [
      {
        query: GET_PARTNER_SETTLEMENT,
        variables: {},
      },
      {
        query: GET_PARTNER_DIRECTIVES_TRANSACTION,
        variables: {},
      },
    ],
    onCompleted: () => {
      successHandler("Partner settlement added successfully!");
    },
    onError: () => errorHandler("An error occurred"),
  });

  // Partner directive dependency
  useEffect(() => {
    if (partnerDirective.data?.courses_partner_revenue_split_transactions) {
      const owedFilter =
        partnerDirective.data.courses_partner_revenue_split_transactions
          .filter((a) => a.order !== null)
          .reduce(function (accumulator, curValue) {
            return (
              accumulator +
              (curValue.order.amount * curValue.directive?.commission) / 100
            );
          }, 0);

      setPartnerDirective(
        partnerDirective.data.courses_partner_revenue_split_transactions
      );

      const paidCommission =
        partnerSettlementData.data?.courses_partner_settlement_transactions.reduce(
          function (accumulator, curValue) {
            return accumulator + curValue.amount;
          },
          0
        );

      setOwed(owedFilter - paidCommission);
    }
  }, [partnerDirective, partnerSettlementData]);

  // Get partners
  const [partnerOption, setPartners] = useState([]);
  useQuery(GET_PARTNERS, {
    onCompleted: (data) => {
      if (partnerAccess.isAdmin) {
        const partnerOption = data.courses_partner.map((part, i) => {
          return { value: part.id, label: part.name };
        });

        setPartners(partnerOption);
      } else {
        const partnerOption = data.courses_partner
          .filter((part) => accessablePartners.includes(part.id))
          .map((part) => {
            return { value: part.id, label: part.name };
          });

        setPartners(partnerOption);
      }
    },
  });

  // Get courses
  const [courseOptions, setCourses] = useState([]);
  useQuery(partnerAccess.isAdmin ? COURSES_QUERY : GET_PARTNER_COURSES, {
    variables: partnerAccess.isAdmin
      ? {}
      : {
          partnerIds: accessablePartners,
        },
    onCompleted: (data) => {
      if (partnerAccess.isAdmin) {
        const courseOptions = data.courses_course.map((course, i) => {
          return { value: course.id, label: course.full_name };
        });
        setCourses(courseOptions);
      } else {
        const courseOptions = data.courses_partner_revenue_split_directives.map(
          (course) => {
            return { value: course.course.id, label: course.course.full_name };
          }
        );
        setCourses(courseOptions);
      }
    },
  });

  //Update settlement component form value
  const updateValue = (e) => {
    const key = e.target.getAttribute("data-key");
    const value = e.target.value;
    e.preventDefault();

    settlementValue[key] = value;
    setSettlementValue({ ...settlementValue });
  };

  // Submit settlement component
  const submitSettlement = (e) => {
    insertSettlement({
      variables: settlementValue,
    });
  };

  // Filter Commission
  const filterCommission = (e) => {
    const variables = {};
    const settlementvariables = {};

    const graphql_exp = {};
    const graphql_exp_settlement = {};

    const from_date = moment(dateFilter.from_date, "MM/DD/YYYY HH:mm").unix();
    const to_date = moment(
      dateFilter.to_date ? dateFilter.to_date : new Date(),
      "MM/DD/YYYY HH:mm"
    ).unix();

    // Date
    if (from_date) {
      graphql_exp.order = { order_create_at: { _gte: from_date } };

      graphql_exp._and = { order: { order_create_at: { _lte: to_date } } };
    }

    // Partner
    if (partnerValue.length > 0) {
      graphql_exp.partner_id = { _in: partnerValue };
      graphql_exp_settlement.partner_id = { _in: partnerValue };
    }

    // Course
    if (courseValue.length > 0) {
      graphql_exp.directive = { course_id: { _in: courseValue } };
    }

    variables.where = graphql_exp;
    settlementvariables.where = graphql_exp_settlement;

    runQuery({
      variables: variables,
    });

    runSettlementQuery({
      variables: settlementvariables,
    });
  };

  return (
    <Container title={"Partner Commission"}>
      <form className="grid grid-cols-2 gap-3">
        <div>
          <div>
            <label className="block text-sm font-medium text-gray-700 my-2">
              Select Partner(s)
            </label>
            <div className="mt-1 relative rounded-md">
              <ReactSelect
                placeholder="Select Partners"
                options={partnerOption}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                onChange={(e) => {
                  setPartnerValue(
                    Array.isArray(e) ? e.map((x) => x.value) : []
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <label className="block text-sm font-medium text-gray-700 my-2">
              Select Courses(s)
            </label>
            <div className="mt-1 relative rounded-md">
              <ReactSelect
                placeholder="Select Courses"
                options={courseOptions}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                onChange={(e) => {
                  setCourseValue(Array.isArray(e) ? e.map((x) => x.value) : []);
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <label className="block text-sm font-medium text-gray-700 my-2">
              Start Date
            </label>
            <div className="mt-1 relative rounded-md">
              <DateTime
                label={""}
                selected={fromDate}
                placeholderText="MM-DD-YYYY"
                dateFormat="MMMM d, yyyy"
                filterTime={(time) => filterPassedTime(time, "from_date")}
                minDate={{}}
                name={"from_date"}
                onChange={(date) => {
                  setFromDate(date);
                  updateStateValue("from_date", date);

                  if (!fromTo || date.getTime() > fromTo.getTime()) {
                    setToDate(date);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <label className="block text-sm font-medium text-gray-700 my-2">
              End Date
            </label>
            <div className="mt-1 relative rounded-md">
              <DateTime
                label={""}
                selected={fromTo}
                placeholderText="MM-DD-YYYY"
                dateFormat="MMMM d, yyyy"
                filterTime={(time) => filterPassedTime(time, "from_date")}
                minDate={{}}
                name={"from_date"}
                onChange={(date) => {
                  if (fromDate && date.getTime() < fromDate.getTime())
                    errorHandler(
                      "Please choose a day later than event start date"
                    );
                  else {
                    setToDate(date);
                    updateStateValue("to_date", date);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </form>
      <div className="mb-5 mt-3 text-right ">
        <button
          className="inline-flex justify-center py-2 
          px-4 border border-transparent shadow-sm 
          text-sm font-medium rounded-md text-white bg-orange"
          onClick={(e) => filterCommission(e)}
        >
          Filter
        </button>
      </div>

      <div className="flex-row text-center">
        <h3 className="font-bold">Total Amount Owed</h3>
        <p className="text-japanese_indigo text-lg font-bold">Rs. {owed}</p>
      </div>

      <div className="Tabs">
        {/* Tab nav */}
        <ul className="nav">
          <li
            className={`${activeTab === "tab1" ? "active" : ""}`}
            onClick={() => setActiveTab("tab1")}
          >
            Commission Transactions
          </li>
          <li
            className={activeTab === "tab2" ? "active" : ""}
            onClick={() => setActiveTab("tab2")}
          >
            Settlement Transactions
          </li>
        </ul>
        <div className="outlet">
          {/* content will be shown here */}
          {activeTab === "tab1" ? (
            <div className="FirstTab">
              <PartnerCommissionTransaction
                partnerDirectiveFilter={partnerDirectiveFilter}
              />
            </div>
          ) : (
            <div className="SecondTab">
              <PartnerSettlement
                partnerSettlementData={
                  partnerSettlementData.data
                    ?.courses_partner_settlement_transactions
                }
                partnerOption={partnerOption}
                updateValue={updateValue}
                submitSettlement={submitSettlement}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}
