import { useState, useEffect } from "react";
import { GET_PARTNERS } from "GraphQl/Queries/Partner";
import { useQuery, useMutation } from "@apollo/client";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";
import * as yup from "yup";
import { multiSelectSchema } from "utils/schemas";

function ScholarshipFormBuilder(values) {
  const { error, loading, data } = useQuery(GET_PARTNERS);
  const partnerAccess = useUserPartnerAccessDetails();
  const [selectedPartner, setSelectedPartner] = useState();

  const [schema, setSchema] = useState({});

  //get partners based on who is logged in, an admin or a partner

  const filterPartner = (partners) => {
    if (partners) {
      if (partnerAccess && partnerAccess.allPartnerAccess) {
        return partners;
      } else if (partnerAccess.partnerIds) {
        const filteredPartners = partners.filter(
          ({ id }) => partnerAccess.partnerIds.indexOf(id) > -1
        );
        return filteredPartners;
      }
    }
    return [];
  };

  //Effect to detect which partner is selected and get partner's projects

  useEffect(() => {
    const partnerSelect = (data?.courses_partner || []).find((partner) => {
      if (partner.id == values.partner_id) return partner;
    });
    setSelectedPartner(partnerSelect);
  }, [values.partner_id]);

  useEffect(() => {
    const schema = {
      partner_id: {
        label: "Partner Name",
        type: "select",
        options: filterPartner(data?.courses_partner).map((partner) => ({
          label: partner.name,
          value: partner.id,
        })),
        required: true,
        schema: yup.string(),
      },
      project_id: {
        label: "Project Name",
        type: "select",
        options: ((selectedPartner && selectedPartner.projects) || []).map(
          (project) => ({
            label: project.name,
            value: project.id,
          })
        ),
        required: true,
        schema: yup.string(),
      },
      title: {
        label: "Scholarship Name",
        type: "text",
        required: true,
        schema: yup.string(),
      },
      no_of_positions: {
        label: "Number of Scholarships",
        type: "text",
        required: true,
        schema: yup.number(),
      },
      amount: {
        label: "Amount",
        type: "text",
        schema: yup.number(),
      },
      description: {
        label: "Scholarship Description",
        type: "textarea",
      },
      min_age: {
        label: "Minimum Age",
        type: "select",
        options: [{ label: "6-30 years ", value: "0" }],
      },
      max_age: {
        label: "Maximum Age",
        type: "select",
        options: [{ label: "12-35 years ", value: "0" }],
      },

      gender: {
        label: "Gender",
        type: "select",
        options: [
          { label: "Male", value: "0" },
          { label: "Female", value: "1" },
          { label: "Other", value: "2" },
        ],
      },
      social_categories: {
        label: "Social Category",
        type: "multi-select",
        options: [
          { label: "General", value: "0" },
          { label: "SC", value: "1" },
          { label: "ST", value: "2" },
          { label: "OBC", value: "3" },
          { label: "Physically Challenged", value: "4" },
          { label: "Orphan", value: "5" },
        ],
        schema: multiSelectSchema,
      },
      min_edu_qualification: {
        label: "Minimum Education Qualification",
        type: "select",
        options: [
          { label: "Class V", value: "0" },
          { label: "Class VIII", value: "1" },
          { label: "Class IX", value: "2" },
          { label: "Class X", value: "3" },
          { label: "Class XI", value: "4" },
          { label: "Class XII", value: "5" },
          { label: "Graduate", value: "6" },
        ],
      },
      is_active: {
        label: "Active",
        type: "boolean",
      },
    };

    //To see if a partner has accessed the scholarship form, then automatically fill the partner_id field
    if (
      partnerAccess &&
      partnerAccess.allPartnerAccess !== true &&
      partnerAccess.primaryPartnerId &&
      !values.partner_id
    ) {
      values.partner_id = partnerAccess.primaryPartnerId;
    }

    setSchema(schema);
  }, [data, selectedPartner, partnerAccess, values]);

  return schema;
}

export default ScholarshipFormBuilder;
