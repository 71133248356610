import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_MODULE_TAGS } from "GraphQl/Mutations/Courses";
import { GET_MODULE_TAGS } from "GraphQl/Queries/CourseBuilder/Quiz";
import Container from "components/Container/Container";
import QuizDetailsFormBuilder from "formBuilders/QuizDetailsFormBuilder";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "skillstrainer-resource-library";
import { errorHandler, successHandler } from "utils/toast";

export const QuizDetails = ({ show, handleClose, quizId }) => {
  const { data: tagsData } = useQuery(GET_MODULE_TAGS, {
    variables: {
      moduleId: quizId,
    },
  });
  const [updateModuleTags] = useMutation(UPDATE_MODULE_TAGS);

  const [initValues, setInitValues] = useState({
    key: 0,
    value: { blockNext: false },
  });

  useEffect(() => {
    if (tagsData) {
      setInitValues(
        tagsData?.courses_course_module_mapping
          ? {
              key: initValues.key + 1,
              value: tagsData?.courses_course_module_mapping[0].tags,
            }
          : {
              key: 0,
              value: { blockNext: false },
            }
      );
    }
  }, [tagsData]);

  const submitQuizDetails = async (details) => {
    await updateModuleTags({
      variables: {
        moduleId: quizId,
        tags: details,
      },
    })
      .then(() => {
        successHandler("Quiz Details Updated Successfully");
        setInitValues({
          key: initValues.key + 1,
          value: details,
        });
      })
      .catch((err) => {
        console.log(err);
        errorHandler("There was an error while updating quiz details");
      });
  };
  return (
    <Container className={"p-8"}>
      <Modal
        size="lg"
        show={show}
        scrollable={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Quiz Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray">
          <Form
            key={initValues.key}
            formBuilder={QuizDetailsFormBuilder}
            className=" ml-6 mt-2 mb-4 grid grid-cols-2 gap-x-2"
            submitButton={{
              text: "Save",
              className:
                "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
            }}
            onSubmit={submitQuizDetails}
            initValues={initValues.value}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-secondary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
