import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import * as yup from "yup";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";
import { GET_PARTNERS } from "GraphQl/Queries/Partner";
import { GET_COURSES } from "GraphQl/Queries/Projects";
import { multiSelectSchema } from "utils/schemas";

function ProjectFormBuilder(values) {
  const [schema, setSchema] = useState();
  const partnerAccess = useUserPartnerAccessDetails();
  const { error, loading, data: partnerData } = useQuery(GET_PARTNERS);
  const {
    error: courseError,
    loading: courseLoading,
    data: courseData,
  } = useQuery(GET_COURSES);

  //get partners based on who is logged in, an admin or a partner

  const filterPartner = (partners) => {
    if (partners) {
      if (partnerAccess && partnerAccess.allPartnerAccess) {
        return partners;
      } else if (partnerAccess.partnerIds) {
        const filteredPartners = partners.filter(
          ({ id }) => partnerAccess.partnerIds.indexOf(id) > -1
        );
        console.log("filteredPartners==", filteredPartners);
        return filteredPartners;
      }
    }
    return [];
  };

  useEffect(() => {
    const schema = {
      name: {
        type: "text",
        label: "Project Name",
        required: true,
      },
      partner_id: {
        type: "select",
        label: "Partner Name",
        required: true,
        options: filterPartner(partnerData?.courses_partner || []).map(
          (partner) => ({
            value: partner.id,
            label: partner.name,
          })
        ),
      },
      address: {
        type: "address",
        label: "Address (Optional)",
        keys: {
          action: "include",
          keys: ["state", "city_town", "house_number", "location"],
        },
        schema: yup.object(),
      },
      type: {
        type: "select",
        label: "Project Type",
        required: true,
        options: [
          { value: "Scholarship", label: "Scholarship" },
          { value: "Course Provision", label: "Course Provision" },
          { value: "Placement / Jobs", label: "Placement / Jobs" },
        ],
      },

      courses: {
        type: "multi-select",
        label: "Courses",
        options: (courseData?.courses_course || []).map((course) => ({
          value: course.id,
          label: course.full_name,
        })),
        schema: multiSelectSchema,
      },
    };

    if (values.type != "Course Provision") {
      schema.courses = { type: "hidden" };
    }

    setSchema(schema);
  }, [values, courseData, partnerData]);

  return schema;
}

export default ProjectFormBuilder;
