export const ADD_SCHOLARSHIP_SCHEMA = [
  {
    column_name: "partner_id",
    label: "Partner Name",
    type: "select",
    required: true,
  },
  {
    column_name: "project_id",
    label: "Project Name",
    type: "select",
    required: true,
  },
  {
    column_name: "title",
    label: "Scholarship Name",
    type: "text",
    required: true,
  },
  {
    column_name: "no_of_positions",
    label: "Number of Positions (Optional)",
    type: "number",
  },
  {
    column_name: "amount",
    label: "Amount",
    type: "number",
    required: true,
  },
  {
    column_name: "description",
    label: "Description (Optional)",
    type: "text_area",
  },
  {
    column_name: "min_age",
    label: "Minimum Age (Optional)",
    type: "number",
  },
  {
    column_name: "max_age",
    label: "Maximum Age (Optional)",
    type: "number",
  },

  {
    column_name: "gender",
    label: "Gender (Optional)",
    type: "select",
    options: [
      { label: "Male", value: "0" },
      { label: "Female", value: "1" },
      { label: "Other", value: "2" },
    ],
  },
  {
    column_name: "social_categories",
    label: "Social Category (Optional)",
    type: "select",
    options: [
      { label: "General", value: "0" },
      { label: "SC", value: "1" },
      { label: "ST", value: "2" },
      { label: "OBC", value: "3" },
      { label: "Physically Challenged", value: "4" },
      { label: "Orphan", value: "5" },
    ],
  },
  {
    column_name: "min_edu_qualification",
    label: "Minimum Education Qualification (Optional)",
    type: "select",
    options: [
      { label: "Class V", value: "0" },
      { label: "Class VIII", value: "1" },
      { label: "Class IX", value: "2" },
      { label: "Class X", value: "3" },
      { label: "Class XI", value: "4" },
      { label: "Class XII", value: "5" },
      { label: "Graduate", value: "6" },
      { label: "Post-graduate", value: "7" },
      { label: "Doctorate", value: "8" },
    ],
  },
  {
    column_name: "is_active",
    label: "Active",
    type: "checkbox",
  },
];

export const VIEW_SCHOLARSHIP_SCHEMA = [
  {
    column_name: "partner_id",
    label: "Partner Name",
    type: "select",
    required: true,
  },
];
