import Input from "../../../../components/InputGroup/Input";

const FilterComponent = ({ filterText, onFilter, onClear, placeholder }) => (
  <>
    <Input
      id="search"
      type="text"
      placeholder={placeholder ? placeholder : "Search..."}
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <button
      className="px-3 py-2 rounded-lg bg-japanese_indigo text-base text-white"
      onClick={() => onClear()}
    >
      Clear Filter
    </button>
  </>
);

export default FilterComponent;
