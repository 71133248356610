import { useState, useEffect, useMemo } from "react";
import { GET_PARTNERS } from "GraphQl/Queries/Partner";
import { COURSES_QUERY } from "GraphQl/Queries/Courses";
import { useQuery } from "@apollo/client";
import * as yup from "yup";
import { multiSelectSchema } from "utils/schemas";
import { GET_BATCHES_WITH_FILTER } from "GraphQl/Queries/CourseCoupons";

function CouponCodeFormBuilder(values) {
  const { data: partnerData } = useQuery(GET_PARTNERS);
  const { data: courseData } = useQuery(COURSES_QUERY);
  const { data: batchesRes } = useQuery(GET_BATCHES_WITH_FILTER, {
    variables: { courseBatchesCriteria: {} },
  });
  const batches = useMemo(
    () => batchesRes && batchesRes.courses_course_batches,
    [batchesRes]
  );

  const [schema, setSchema] = useState({});
  const [selectedPartner, setSelectedPartner] = useState();

  useEffect(() => {
    const partnerSelect = partnerData?.courses_partner.find((partner) => {
      if (partner.id == values.partner_id) return partner;
    });
    setSelectedPartner(partnerSelect);
  }, [values.partner_id]);

  useEffect(() => {
    const schema = {
      code: {
        type: "text",
        label: "Code Name",
        required: true,
        schema: yup.string(),
      },
      user_limit: {
        type: "text",
        label: "User Limit",
        required: true,
        schema: yup.number(),
      },

      start_date: {
        type: "date",
        label: "Start Date",
        required: true,
      },
      end_date: {
        type: "date",
        label: "End Date",
      },
      description: {
        type: "textarea",
        label: "Description",
        schema: yup.string(),
      },
      courses: {
        type: "multi-select",
        required: true,
        label: "Courses",
        options: courseData?.courses_course
          .map((course) => ({
            value: course.id,
            label: course.full_name,
          }))
          .sort((a, b) => {
            return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
          }),
        schema: multiSelectSchema,
      },
      batches: {
        type: "multi-select",
        label: "Batches",
        options:
          batches &&
          batches.map((batch) => ({
            value: batch.id,
            label: batch.batch_name,
          })),
        schema: multiSelectSchema,
      },
      partner_id: {
        type: "select",
        label: "Select partner",
        options: partnerData?.courses_partner.map((partner) => ({
          value: partner.id,
          label: partner.name,
        })),
      },
      project_id: {
        type: "select",
        label: "Select project",
        options: ((selectedPartner && selectedPartner?.projects) || []).map(
          (project) => ({
            value: project.id,
            label: project.name,
          })
        ),
      },

      discount: {
        type: "text",
        label: "Discount (%)",
        schema:
          values.is_discount && !values.abs_discount
            ? yup.number().required("Enter the discount percentage")
            : yup.number().nullable(),
      },
      abs_discount: {
        type: "text",
        label: "Discount (Number)",
        schema:
          values.is_discount && !values.discount
            ? yup.number().required("Enter the discount")
            : yup.number().nullable(),
      },

      minimum_cart_amount: {
        type: "text",
        label: "Minimum cart amount",
        scheam: yup.number().nullable(),
      },
      max_usage_per_user: {
        type: "text",
        label: "Maximum Usage Per User",
        schema: yup.number().nullable(),
      },

      active: {
        type: "boolean",
        label: "Active",
        required: true,
      },

      is_discount: {
        type: "boolean",
        label: "Is Discount Code",
      },
    };

    setSchema(schema);
  }, [partnerData, courseData, values, selectedPartner]);

  return schema;
}

export default CouponCodeFormBuilder;
