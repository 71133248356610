import React, { useEffect, useState } from "react";
import { ScormApi } from './ScormApi';
import courseBuilderService from "services/coursebuild/course";
import { useLocation } from 'react-router';
import queryString from 'query-string';

export default function CourseModView() {


  return (
    <div>
             
           
        </div>
  );
}
