import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import { INSERT_QUIZ_QUESTION } from "GraphQl/Mutations/coursebuildemutation/Questions";
import { useMutation } from "@apollo/client";
import { INSERT_QUESTION_TAGS } from "GraphQl/Mutations/coursebuildemutation/Questions";
import { LINK_QUESTION_TO_COURSE } from "GraphQl/Mutations/coursebuildemutation/Questions";
import { successHandler, errorHandler } from "utils/toast";
import { FileUploader } from "react-drag-drop-files";
import { uploadResourceLargeFile } from "api/UploadFile";
import { s3QuizImgBaseUrl } from "api/Consts";

const PictureMcqQuiz = ({ selectedTags, questionData, propsData }) => {
  const [link_questions_to_course] = useMutation(LINK_QUESTION_TO_COURSE);
  const [insert_quiz_question] = useMutation(INSERT_QUIZ_QUESTION);
  const [insert_question_tags] = useMutation(INSERT_QUESTION_TAGS);
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);

  const [fileLoading, setFileLoading] = useState(false);
  const [fileName, SetFileName] = useState({
    file_name: null,
    upload_url: null,
    system_filename: null,
  });

  const [mode, setMode] = useState("picture");
  const [arrayData, setArrayData] = useState([]);

  const addOption = (e) => {
    if (!fileName?.file_name && mode === "picture") {
      errorHandler("Please Upload Picture First");
      return false;
    }

    if (e.key === "Enter") {
      // Options can't be empty or more than 5.
      if (arrayData.length <= 6 && e.target.value) {
        // setOptionsList([...optionsList, e.target.value]);

        let updatedData;

        if (mode === "picture") {
          updatedData = arrayData?.map((item) => {
            if (item.answer_img === fileName?.file_name) {
              return {
                ...item,
                answer_text: e.target.value,
                isAnswer: false,
                answer_description: null,
              };
            }
            return item;
          });
          setArrayData(updatedData);
        } else {
          setArrayData([
            ...arrayData,
            {
              answer_text: e.target.value,
              isAnswer: false,
              answer_description: null,
            },
          ]);
        }

        SetFileName({});
        setOptions("");
      } else {
        errorHandler("Only 6 options allowed");
      }
    }
  };

  const addQuestion = async () => {
    var flag = true;

    if (question == "") {
      errorHandler("Enter qeustion details");
      flag = false;
    }

    if (question !== "" && arrayData.length < 2) {
      errorHandler("Add atleast four Options");
      flag = false;
    }

    if (flag) {
      const response = await insert_quiz_question({
        variables: {
          marks: null,
          question_text: question,
          question_type: 2,
          visible: true,
          question_category: questionData.question_category,
          question_level: questionData.question_level,
          data: arrayData,
          mode: mode,
        },
      });
      if (response) {
        const quest_id =
          response.data.insert_courses_questions?.returning[0].id;
        selectedTags &&
          selectedTags.map((id) => {
            insert_question_tags({
              variables: {
                question_id: quest_id,
                tag_id: id,
              },
            });
          });

        if (propsData && propsData.quizmap) {
          link_questions_to_course({
            variables: {
              course_id: propsData.quizmap.course_id,
              question_id: quest_id,
              module_mapping_id: propsData.quizmap.mapping_id,
            },
          });
        }
        setQuestion("");
        setArrayData([]);
        successHandler("Question Added Successfully");
      }
    }
  };

  //Resource Uploading
  const resourecUploadFun = async (resourceFile) => {
    return await uploadResourceLargeFile(
      resourceFile,
      resourceFile.name,
      true,
      "course-resources"
    )
      .then((res) => res.data_url)
      .catch((e) => {
        errorHandler("An error occured while uploading");
        throw errorHandler("Couldn't upload file", e.data || e);
      });
  };

  const handleFileReader = async (event) => {
    setFileLoading(true);

    const awsFileName = await resourecUploadFun(event);

    SetFileName({
      file_name: awsFileName,
      system_filename: event.name,
    });

    setArrayData((arrayData) => [
      ...arrayData,
      {
        answer_img: awsFileName,
        system_filename: event.name,
      },
    ]);
    setFileLoading(false);
  };

  return (
    <div className="App">
      {/* {questionList.length > 1 && alert('Questions Added successfully')} */}

      <div className="flex justify-center items-center content-center h-screen align-middle">
        <div className="bg-white w-full p-6 text-gray-500 rounded-lg">
          <h1>Please Add Your Picture Question Below:</h1>
          <div className="relative m-4 flex flex-row gap-3">
            <div className="mt-4 w-6/12">
              <label
                htmlFor="question"
                className="text-left leading-7 text-sm text-gray-600"
              >
                Question:
              </label>
              <input
                type="text"
                id="question"
                name="question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Enter your question here"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            <div className="mt-4 w-6/12">
              <label htmlFor="mode">Choose Mode:</label>
              <select
                name="mode"
                id="mode"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                onChange={(e) => setMode(e.target.value)}
              >
                <option value={"picture"}>Picture</option>
                <option value={"text"}>Text</option>
              </select>
            </div>
          </div>

          <div className="relative m-4 flex flex-row gap-3">
            {mode === "picture" && (
              <div className="mt-4 w-6/12">
                <label
                  htmlFor="options"
                  className="text-left leading-7 text-sm text-gray-600"
                >
                  Upload Picture Here:
                </label>
                <FileUploader
                  handleChange={handleFileReader}
                  name="file"
                  // types={['jpeg']}
                  classes={"drop_area drop_zone"}
                />
                {fileName?.system_filename}
              </div>
            )}
            <div className={`mt-4 ${mode === "picture" ? "w-6/12" : "w-full"}`}>
              <label
                htmlFor="options"
                className="text-left leading-7 text-sm text-gray-600"
              >
                {`${
                  mode === "picture"
                    ? "Enter Picture value:"
                    : "Enter Text value:"
                }`}
              </label>
              <input
                type="text"
                id="options"
                name="options"
                value={options}
                onChange={(e) => setOptions(e.target.value)}
                onKeyDown={addOption}
                placeholder={`Press enter after each ${
                  mode === "picture" ? "picture" : "text"
                } value`}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mb-1"
              />
            </div>
          </div>

          {(arrayData || []).map((option) => {
            return (
              <div className="relative m-4 w-full">
                <div className="flex text-xs p-1 px-2 text-white border m-1">
                  {mode === "picture" && (
                    <img
                      src={`${s3QuizImgBaseUrl}/${option?.answer_img}`}
                      width="250"
                      height="100"
                      alt="Example dd"
                    />
                  )}
                  <p
                    className={`text-left leading-7 text-lg p-${
                      mode === "picture" ? 5 : 2
                    } text-gray-600`}
                  >
                    {" "}
                    {option?.answer_text ? option?.answer_text : ""}{" "}
                  </p>
                  <button
                    onClick={() => {
                      if (mode === "picture")
                        setArrayData(
                          arrayData.filter(
                            (e) => e?.answer_img !== option?.answer_img
                          )
                        );
                      else
                        setArrayData(
                          arrayData.filter(
                            (e) => e?.answer_text !== option?.answer_text
                          )
                        );
                    }}
                    className="ml-auto focus:outline-none text-red-600 font-semibold text-lg"
                  >
                    x
                  </button>
                </div>
              </div>
            );
          })}

          <button
            onClick={() => addQuestion()}
            className="border py-2 px-4 text-white bg-orange"
          >
            Add Question
          </button>
          {/* <button
              onClick={() => toggleShowTakeQuiz(showTakeQuiz)}
              className="w-full mt-4 border border-blue-500 py-2 px-4 text-blue-500 bg-white"
            >
              Preview
            </button> */}
        </div>
      </div>
    </div>
  );
};

export default PictureMcqQuiz;
