import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";

import { GET_TOTAL_USERS, GET_USERS_PAGINATION } from "GraphQl/Queries/User";
import UsersTable from "components/TraineeList/UsersTable";
import UsersFilter from "components/TraineeList/UsersFilter";
import userSvc from "services/user";
import { useRecoilState } from "recoil";
import { gqlUserRecoilVar } from "State/User";
import { currentModuleAccessRecoilVar } from "State/Navigation";

import { GET_COURSE_TOPICS } from "GraphQl/Queries/CourseBuilder/section";
import { GET_USERS_COURSE_PROGRESS } from "GraphQl/Queries/CourseBuilder/Course";

export default function TraineeList(props) {
  const {
    initialSelectedUsers,
    selectable,
    showCourses,
    showCoupons,
    onComplete,
  } = props;

  const [user] = useRecoilState(gqlUserRecoilVar);
  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);

  const [listingCriteria, setListingCriteria] = useState({});

  const runSearch = useCallback(() => {
    totalUsersHook?.refetch();
    availableUsersHook?.refetch();
  }, [listingCriteria]);

  // Get Course Modules Topics
  const [runQueryCourseTopics, courseTopics] = useLazyQuery(GET_COURSE_TOPICS, {
    fetchPolicy: "network-only", // Doesn't check cache before making a network request
  });

  //GET ENROLLED USERS PROGRESS
  const [runUsersProgress, usersProgress] = useLazyQuery(
    GET_USERS_COURSE_PROGRESS,
    {
      fetchPolicy: "network-only", // Doesn't check cache before making a network request
    }
  );

  // Run search on mount
  useEffect(() => runSearch(), [runSearch]);

  /*
   *
   *
   * Data fetching
   *
   *
   */
  const [paginationInfo, setPaginationInfo] = useState({
    page: 0,
    per_page: 100,
  });

  const {
    partnerId,
    projectId,
    couponId,
    courseId,
    name,
    email,
    mobileNumber,
    keywords,
  } = listingCriteria;

  const userSearchCriteria = useMemo(() => {
    const obj = {
      name: { _ilike: `%${name || ""}%` },
      email: { _ilike: `%${email || ""}%` },
      mobile_number: { _ilike: `%${mobileNumber || ""}%` },
    };

    if (courseId) {
      Object.assign(obj, {
        enrollments: {
          course_id: {
            _eq: courseId,
          },
        },
      });
    }
    if (couponId)
      Object.assign(obj, {
        st_user_coupons: {
          st_coupons_configuration: {
            id: { _eq: couponId },
          },
        },
      });

    if (partnerId)
      Object.assign(obj, {
        partner_users: {
          partner_id: { _eq: partnerId },
        },
      });
    else {
      // @to-revert
      if (!user || (!user.is_skillstrainer_admin && !currentModuleAccess))
        Object.assign(obj, {
          partner_users: {
            partner_id: { _eq: -1 },
          },
        });
      else if (!user.is_skillstrainer_admin)
        Object.assign(obj, {
          partner_users: {
            partner_id: {
              _in: Object.keys(currentModuleAccess.allowedPartners),
            },
          },
        });
    }

    if (projectId)
      Object.assign(obj, {
        partner_projects: {
          project_id: { _eq: projectId },
        },
      });

    console.log(keywords);

    if (keywords && keywords.length) {
      Object.assign(obj, {
        tags: {
          name: { _in: keywords },
          value: { _eq: "true" },
        },
      });
    }

    if (courseId) {
      runQueryCourseTopics({
        variables: {
          course_id: courseId || null,
        },
      });

      runUsersProgress({
        variables: {
          course_id: courseId,
          id: courseId,
        },
      });
    }

    return obj;
  }, [
    name,
    email,
    mobileNumber,
    couponId,
    courseId,
    partnerId,
    projectId,
    user,
    currentModuleAccess,
    keywords,
    runQueryCourseTopics,
    runUsersProgress,
  ]);

  // User count
  const totalUsersHook = useQuery(GET_TOTAL_USERS, {
    variables: { userSearchCriteria },
    fetchPolicy: "network-only",
  });
  const totalUsers = useMemo(() => {
    const list =
      totalUsersHook.data && totalUsersHook.data.courses_users_aggregate;
    if (list) return list.aggregate.count;
    return 0;
  }, [totalUsersHook.data]);

  // User data
  const availableUsersHook = useQuery(GET_USERS_PAGINATION, {
    variables: {
      offset: paginationInfo.page * paginationInfo.per_page,
      limit: paginationInfo.per_page,
      userSearchCriteria,
    },
    fetchPolicy: "network-only",
  });
  const availableUsers = useMemo(
    () => availableUsersHook.data?.courses_users,
    [availableUsersHook]
  );

  /*
   *
   *
   * User selection
   *
   *
   */
  const [selectedUsers, setSelectedUsers] = useState(
    (initialSelectedUsers &&
      initialSelectedUsers.reduce((acc, userId) => {
        acc[userId] = true;
        return acc;
      }, {})) ||
      {}
  );
  const handleUsersSelection = ({
    deselectedUsers,
    selectedUsers: selectedUserRecords,
  }) => {
    let changed = !!deselectedUsers.length;
    selectedUserRecords.forEach((u) => {
      if (!selectedUsers[u.id]) {
        changed = true;
        selectedUsers[u.id] = true;
      }
    });
    deselectedUsers.forEach((u) => {
      selectedUsers[u.id] = false;
    });

    if (changed) setSelectedUsers({ ...selectedUsers });
  };

  const getUserIdsInCurrentResults = useCallback(() => {
    return userSvc
      .getUsersWithFilter(userSearchCriteria)
      .then((users) => users.map((u) => u.id));
  }, [userSearchCriteria]);

  const selectAllUsersInResult = useCallback(async () => {
    const userIds = await getUserIdsInCurrentResults();
    userIds.forEach((userId) => {
      selectedUsers[userId] = true;
    });
    setSelectedUsers({ ...selectedUsers });
  }, [getUserIdsInCurrentResults]);

  const deselectAllUsersInResult = useCallback(async () => {
    const userIds = await getUserIdsInCurrentResults();
    userIds.forEach((userId) => {
      selectedUsers[userId] = false;
    });
    setSelectedUsers({ ...selectedUsers });
  }, [getUserIdsInCurrentResults]);

  return (
    <div>
      <div className="flex w-full justify-end gap-x-3 mb-3">
        {selectable && (
          <>
            <button
              onClick={() => setSelectedUsers({})}
              className="btn-secondary px-5"
            >
              Clear selection
            </button>

            <button
              onClick={() =>
                onComplete(
                  Object.keys(selectedUsers)
                    .filter((e) => selectedUsers[e])
                    .map(Number)
                )
              }
              className="btn-primary px-5"
            >
              Done
            </button>
          </>
        )}
      </div>
      <UsersFilter updateListingCriteria={setListingCriteria} />
      <div className="flex-1">
        <button onClick={runSearch} className="primary-cta">
          Search
        </button>
      </div>

      <div className="flex-row w-full mt-3">
        {selectable && (
          <div className="flex-1 mb-2 flex gap-2">
            <button onClick={selectAllUsersInResult} className="primary-cta">
              Select all in current result
            </button>
            <button onClick={deselectAllUsersInResult} className="primary-cta">
              Deselect all in current result
            </button>
          </div>
        )}
        <UsersTable
          searchObj={listingCriteria}
          paginationInfo={paginationInfo}
          setPaginationInfo={setPaginationInfo}
          users={availableUsers}
          fieldsConfig={{ showCourses, showCoupons }}
          totalUsers={totalUsers}
          loadingUsers={availableUsersHook.loading}
          selectable={selectable}
          selectedUsers={selectedUsers}
          handleUsersSelection={handleUsersSelection}
          courseTopics={courseTopics?.data?.courses_course_module_mapping}
          usersProgress={usersProgress?.data}
        />
      </div>
    </div>
  );
}
