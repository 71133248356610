import React from "react";

const Container = ({ title, children }) => {
  return (
    <div className="w-full min-h-full p-4">
      {title && <h2 className="text-3xl font-bold mb-8">{title}</h2>}
      {children}
    </div>
  );
};

export default Container;
