import {gql} from "@apollo/client";

export const INSERT_COURSE_TAX=gql`
mutation InsertTax($tax_name: String, $tax_percentage: Int, $tax_enable: Boolean) {
    insert_courses_course_tax(objects: {tax_name: $tax_name, tax_percentage: $tax_percentage, tax_enable: $tax_enable}) {
      returning {
        id
      }
    }
  }
  
`

export const UPDATE_COURSE_TAX=gql`
mutation UpdateCourseTax($id: Int, $tax_enable: Boolean, $tax_name: String, $tax_percentage: Int) {
  update_courses_course_tax(where: {id: {_eq: $id}}, _set: {tax_enable: $tax_enable, tax_name: $tax_name, tax_percentage: $tax_percentage}) {
    returning {
      id
    }
  }
}

`

export const INSERT_COURSE_TAX_RELATION=gql`
mutation InsertTagRelation($course_id: Int, $tax_id: Int ) {
  insert_courses_course_tax_relation(objects: {course_id: $course_id, tax_id: $tax_id}) {
    returning {
      id
    }
  }
}

`

