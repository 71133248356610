import CoureseReports from "Pages/Dashboard/Courses/CoureseReports";
import ManageTax from "Pages/Dashboard/Courses/components/coursetax/ManageTax";
import CourseCategoriesPage from "Pages/Dashboard/Courses/CourseCategories/CourseCategoriesPage";
import CreateBatches from "Pages/Dashboard/Courses/CreateBatches";
import CreateCoursePage from "Pages/Dashboard/Courses/CreateCoursePage";
import CreateUpdateCouponCodePage from "Pages/Dashboard/Courses/CreateUpdateCouponCode";
import ManageCouponCodes from "Pages/Dashboard/Courses/ManageCouponCodes";
import ManageCourses from "Pages/Dashboard/Courses/ManageCourses";
import MoodleConfig from "Pages/Dashboard/Courses/MoodleConfig";
import HomePage from "Pages/Dashboard/Home";
import CreateUsers from "Pages/Dashboard/Users/CreateUsers";
import EditUsers from "components/TraineeList/EditUsers";
import ListUsers from "Pages/Dashboard/Users/ListUsers";
import OnboardingUsers from "Pages/Dashboard/Users/OnboardingUsers/OnboardingUsers";
import NewCertificateTemplateMaker from "Pages/Dashboard/Courses/NewCertificateTemplateMaker";
import CSVBulkCertificateGenerator from "Pages/Dashboard/Courses/CSVBulkCertificateGenerator";
import InstructorReports from "Pages/Dashboard/Instructor/InstructorReports";
import CreateInstructor from "Pages/Dashboard/Instructor/CreateInstructor";
import ManageInstructor from "Pages/Dashboard/Instructor/ManageInstructor";
import PartnerReports from "Pages/Dashboard/Partner/PartnerReports";
import PartnerDashboard from "Pages/Dashboard/Partner/PartnerDashboard";
import ManagePartner from "Pages/Dashboard/Partner/ManagePartner";
import ManageProjects from "Pages/Dashboard/Partner/ManageProject";
import WorkPartnerQueries from "Pages/Dashboard/Partner/WorkPartnerQueries";
import PartnerWebsiteConfig from "Pages/Dashboard/Partner/PartnerWebsiteConfig";
import AddScholarship from "Pages/Dashboard/Scholarships/AddScholarship";
import ViewEditScholarship from "Pages/Dashboard/Scholarships/ViewEditScholarship";
import TeamManageUsers from "Pages/Dashboard/Team/ManageUsers";
import ManageUserRoles from "Pages/Dashboard/Team/ManageUserRoles";
import UpdateCoursePage from "Pages/Dashboard/Courses/UpdateCoursePage";
import CourseCompletionSetting from "Pages/Dashboard/Courses/CourseCompletionSetting";
import CourseEnrolledUsers from "Pages/Dashboard/Courses/CourseEnrolledUsers";
import QuizAction from "Pages/Dashboard/Courses/components/courseactivity/QuizAction";
import ModuleQuestionView from "Pages/Dashboard/Courses/components/courseactivity/ModuleQuestionView";
import CourseActivityAdd from "Pages/Dashboard/Courses/components/courseactivity/CourseActivityAdd";
import CourseModule from "Pages/Dashboard/Courses/components/coursemodule/CourseModule";
import CourseModView from "Pages/Dashboard/Courses/components/coursemodule/CourseModView";
import EditPartner from "Pages/Dashboard/Partner/EditPartner";
import ViewCoursePage from "Pages/Dashboard/Courses/ViewCoursePage";
import CreateJob from "Pages/Dashboard/Jobs/CreateJob";
import ManageJobs from "Pages/Dashboard/Jobs/ManageJobs";
import UpdateJob from "Pages/Dashboard/Jobs/UpdateJob";
import ViewJobApplicants from "Pages/Dashboard/Jobs/ViewJobApplicants";
import TraineeReports from "Pages/Dashboard/Users/TraineeReports";
import PartnerCommission from "Pages/Dashboard/Courses/components/PartnerCommission";
import ScholarshipReports from "Pages/Dashboard/Scholarships/SCholarshipReports";
import ProjectReports from "Pages/Dashboard/Projects/ProjectReports";
import ScormEdit from "Pages/Dashboard/Courses/components/courseactivity/ScormEdit";
import QuizEdit from "Pages/Dashboard/Courses/components/courseactivity/QuizEdit";
import ViewCertificates from "Pages/Dashboard/Courses/ViewCertificates";
import CreateUpdateSkills from "Pages/Dashboard/Jobs/CreateUpdateSkills";
import CreateUpdateQualification from "Pages/Dashboard/Jobs/CreateUpdateQualifications";
import ResourceActivityEdit from "Pages/Dashboard/Courses/components/courseactivity/ResourceActivityEdit";
import HandleBatchUser from "Pages/Dashboard/Users/ManageUsers/HandleBatchUser";
import HandlePartnerUser from "Pages/Dashboard/Users/ManageUsers/HandlePartnerUser";
import HandleProjectUser from "Pages/Dashboard/Users/ManageUsers/HandleProjectUser";
import HandleCouponUser from "Pages/Dashboard/Users/ManageUsers/HandleCouponUser";
import EmailTemplate from "Pages/Dashboard/Notifications/EmailTemplate";
import BulkNotification from "Pages/Dashboard/Notifications/BulkNotifications";
import CreateNotificationBatches from "Pages/Dashboard/Notifications/CreateNotificationBatches";
import ManageNotificationBatches from "Pages/Dashboard/Notifications/ManageNotificationBatches";
import GetNotificationStatus from "Pages/Dashboard/Notifications/GetNotificationStatus";
import SMSTemplate from "Pages/Dashboard/Notifications/SMSTemplate";
import ListBatches from "Pages/Dashboard/Courses/ListBatches";
import InstituteTable from "Pages/Dashboard/Repositories/InstituteTable";
import CreateUpdateInstitute from "Pages/Dashboard/Repositories/CreateUpdateInstitute";
import { PartnerWebsiteTable } from "Pages/Dashboard/Partner/PartnerWebsiteTable";
import ReferralScheme from "Pages/Dashboard/Financials/ReferralScheme";
import Invoices from "Pages/Dashboard/Financials/Invoices";
import BulkInstituteUpload from "Pages/Dashboard/Courses/BulkInstituteUpload";
import BulkQuizResponseHandler from "Pages/Dashboard/Courses/BulkQuizResponseHandler";
import ASDMStats from "Pages/Dashboard/Courses/AsdmStats";
import Analatics from "Pages/Dashboard/Scholarships/Analatics";

export const ModuleIdComponentMap = {
  // empty
  home: HomePage,
  // trainee
  2: EditUsers,
  3: null,
  // trainee-reports
  4: TraineeReports,
  // list-trainees
  5: ListUsers,
  // create-update-trainee
  6: CreateUsers,
  // onboard-bulk-trainee
  7: OnboardingUsers,
  // manage-trainees
  // 58: ManageUsers,
  // handle-partner-association
  // handle-project-association
  // apply-coupon
  // enrol-in-batch
  69: HandlePartnerUser,
  70: HandleProjectUser,
  71: HandleCouponUser,
  72: HandleBatchUser,
  // courses
  9: null,
  // course-reports
  10: CoureseReports,
  // list-courses
  11: ManageCourses,
  // create-update-course
  12: CreateCoursePage,
  // manage-course-categories
  13: CourseCategoriesPage,
  // edit-moodle-configuration
  14: MoodleConfig,
  // list-batches
  15: ListBatches,
  // create-batch
  73: CreateBatches,
  // list-coupon-codes
  16: ManageCouponCodes,
  // create-update-coupon-code
  17: CreateUpdateCouponCodePage,
  // manage-taxes
  18: ManageTax,
  // certificate-template-maker
  19: NewCertificateTemplateMaker,
  // csv-bulk-certificate-generator
  20: CSVBulkCertificateGenerator,
  // certificates
  21: ViewCertificates,
  // trainer
  22: null,
  // trainer-reports
  23: InstructorReports,
  // create-update-trainer
  24: CreateInstructor,
  // list-trainers
  25: ManageInstructor,
  // partner
  26: PartnerReports,
  // partner-reports
  27: PartnerReports,
  // partner-dashboard
  28: PartnerDashboard,
  // manage-partners
  29: ManagePartner,
  // manage-projects
  30: ManageProjects,
  // work-partner-queries
  31: WorkPartnerQueries,
  // partner-website-configuration
  32: PartnerWebsiteConfig,
  // scholarship
  33: null,
  // create-update-scholarships
  34: AddScholarship,
  // list-scholarships
  35: ViewEditScholarship,
  // scholarship-reports
  62: ScholarshipReports,
  // team
  36: null,
  // manage-users
  37: TeamManageUsers,
  // manage-roles
  38: ManageUserRoles,
  // financials
  39: null,
  // reports
  40: null,
  // commission-transactions
  41: null,
  // settlement-transactions
  42: null,
  43: UpdateCoursePage,
  44: ViewCoursePage,
  45: CourseCompletionSetting,
  // course-user-reports
  46: CourseEnrolledUsers,
  47: QuizAction,
  48: ModuleQuestionView,
  49: CourseActivityAdd,
  50: CourseModule,
  51: CourseModView,
  52: MoodleConfig,
  53: EditPartner,
  54: null,
  55: CreateJob,
  67: CreateUpdateSkills,
  68: CreateUpdateQualification,
  56: ManageJobs,
  59: UpdateJob,
  63: ViewJobApplicants,
  57: PartnerCommission,
  // project
  60: null,
  // project-reports
  61: ProjectReports,
  //course builder routes (parent course id: 9)
  64: ScormEdit,
  65: QuizEdit,
  66: ResourceActivityEdit,
  76: EmailTemplate,
  91: BulkNotification,
  95: CreateNotificationBatches,
  94: ManageNotificationBatches,
  96: GetNotificationStatus,
  75: SMSTemplate,
  85: InstituteTable,
  86: CreateUpdateInstitute,
  87: PartnerWebsiteTable,
  90: ReferralScheme,
  89: Invoices,
  90: ReferralScheme,
  92: BulkQuizResponseHandler,
  93: BulkInstituteUpload,
  97: ASDMStats,
  98: Analatics
};
