import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import * as yup from "yup";
import { fileSchema, multiSelectSchema } from "utils/schemas";
import { GET_QUESTION_TAGS_AND_CATEGORY } from "GraphQl/Queries/CourseBuilder/Quiz";

export default function QuestionFormBuilder(values) {
  const [schema, setSchema] = useState({});
  const { data: questionOptionData } = useQuery(GET_QUESTION_TAGS_AND_CATEGORY);

  useEffect(() => {
    const schema = {
      question_tags: {
        type: "multi-select",
        label: "Tags",
        options: (questionOptionData?.courses_question_tags || []).map(
          (tag) => {
            return {
              label: tag.name,
              value: tag.id,
            };
          }
        ),
        schema: multiSelectSchema,
      },
      mode: {
        type: "select",
        label: "Mode",
        options: [
          {
            label: "Picture",
            value: "picture",
          },
        ],
      },
      question_category: {
        label: "Category",
        type: "select",
        options: (questionOptionData?.courses_question_category || []).map(
          (cat) => {
            return {
              label: cat.name,
              value: cat.id,
            };
          }
        ),
      },
      question_text: {
        type: "text",
        label: "Question",
        required: true,
        schema: yup.string(),
      },
      marks: {
        type: "text",
        label: "Marks",
        schema: yup.number().max(4, "Marks must not be greater than 5"),
      },
      answer: {
        label: "Answer",
        type: "select",
        required: true,
        options: (values.question_answers || []).map((ans) => {
          return {
            label: ans.answer_text,
            value: ans.answer_text,
          };
        }),
      },
      visible: {
        label: "Visible",
        type: "boolean",
        trueLabel: "Yes",
        falseLabel: "No",
      },
      question_answers: {
        label: "Options",
        type: "object",
        required: true,
        repeat: true,
        insertable: true,
        fields: {
          id: {
            type: "text",
            hidden: true,
          },
          answer_text: {
            label: "Answer Text",
            type: "text",
            schema: yup.string(),
          },
          answer_img: {
            label: "Answer Image",
            type: "file",
            hide: values?.mode === "picture" ? false : true,
            schema: fileSchema,
          },
          category: {
            label: "Category",
            type: "text",
            schema: yup.string(),
          },
        },
      },
    };

    setSchema(schema);
  }, [values, questionOptionData]);

  return schema;
}
