import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router";
import queryString from "query-string";
import { Form } from "skillstrainer-resource-library";
import JobFormBuilder from "formBuilders/JobFormBuilder";
import { JobHelper } from "./JobHelper";
import { GET_JOB_DATA_BY_ID } from "GraphQl/Queries/Jobs";
import {
  UPSERT_JOB_LOCATIONS_SKILLS_LANGUAGES_QUALIFICATIONS_DOCUMENTS,
  DELETE_JOB_LOCATIONS_QUALIFICATIONS_LANGUAGES_SKILLS_DOCUMENTS,
} from "GraphQl/Mutations/Jobs";
import { errorHandler, successHandler } from "utils/toast";
import Container from "components/Container/Container";

function UpdateJob() {
  const [updateJob] = useMutation(
    UPSERT_JOB_LOCATIONS_SKILLS_LANGUAGES_QUALIFICATIONS_DOCUMENTS
  );
  const [deleteJobDetails] = useMutation(
    DELETE_JOB_LOCATIONS_QUALIFICATIONS_LANGUAGES_SKILLS_DOCUMENTS
  );
  const [jobInitValues, setJobInitValues] = useState({});
  const [jobData, setJobData] = useState();
  const [runQuery] = useLazyQuery(GET_JOB_DATA_BY_ID, {
    fetchPolicy: "network-only",
  });

  // Target course is identified by query parameters
  const location = useLocation();
  const params = queryString.parse(location.search);

  const getJobData = () => {
    runQuery({
      variables: {
        jobId: params.id,
      },
    }).then((res) => {
      formatUpdateJobData(res);
      setJobData(res.data);
    });
  };

  useEffect(() => {
    if (params.id) {
      getJobData();
    }
  }, [params.id]);

  const formatUpdateJobData = async (jobData) => {
    const jobHelper = new JobHelper();
    const jobInitObject = jobHelper.reformatQueryValues(jobData);
    setJobInitValues(jobInitObject);
  };

  const submitJob = async (jobFormData) => {
    const jobId = jobData?.courses_skillstrainer_jobs[0].id;
    const updateJobHelper = new JobHelper(jobFormData);
    const oldJobHelper = new JobHelper(jobInitValues);
    updateJobHelper.setJobId(jobId);
    oldJobHelper.setJobId(jobId);

    //handling skillstrainer job object
    const skillstrainerJobsObject = updateJobHelper.reformatJobObject();
    skillstrainerJobsObject.id = jobId;

    //handling qualifications
    const newJobQualificationsArray = updateJobHelper.reformatQualifications();
    const oldJobQualificationsArray = oldJobHelper.reformatQualifications();
    const {
      upsertableArray: upsertableQualificationsArray,
      deletableArray: deletableQualificationsArray,
    } = updateJobHelper.getUpsertableAndDeletableArray(
      oldJobQualificationsArray,
      newJobQualificationsArray,
      "qualification_id",
      jobData?.courses_job_qualifications
    );

    //handling skills
    const newSkillsArray = updateJobHelper.reformatSkills();
    const oldSkillsArray = oldJobHelper.reformatSkills();
    const {
      upsertableArray: upsertableSkillsArray,
      deletableArray: deletableSkillsArray,
    } = updateJobHelper.getUpsertableAndDeletableArray(
      oldSkillsArray,
      newSkillsArray,
      "st_skill_id",
      jobData?.courses_job_required_skills_criteria
    );

    //handling languages
    const newLanguagesArray = updateJobHelper.reformatLanguages();
    const oldLanguagesArray = oldJobHelper.reformatLanguages();
    const {
      upsertableArray: upsertableLanguagesArray,
      deletableArray: deletableLanguagesArray,
    } = updateJobHelper.getUpsertableAndDeletableArray(
      oldLanguagesArray,
      newLanguagesArray,
      "language_id",
      jobData?.courses_job_languages_required
    );

    //handling locations
    const newLocationsArray = updateJobHelper.reformatLocations();
    const oldLocationsArray = oldJobHelper.reformatLocations();
    const {
      upsertableArray: upsertableLocationsArray,
      deletableArray: deletableLocationsArray,
    } = updateJobHelper.getUpsertableAndDeletableArray(
      oldLocationsArray,
      newLocationsArray,
      "pin_code",
      jobData?.courses_job_location
    );

    //handling documents
    const newDocumentsArray = updateJobHelper.reformatDocuments();
    const oldDocumentsArray = oldJobHelper.reformatDocuments();
    const {
      upsertableArray: upsertableDocumentsArray,
      deletableArray: deletableDocumentsArray,
    } = updateJobHelper.getUpsertableAndDeletableArray(
      oldDocumentsArray,
      newDocumentsArray,
      "document_id",
      jobData?.courses_job_documents
    );

    //doing upsertions
    await updateJob({
      variables: {
        jobData: skillstrainerJobsObject,
        locations: upsertableLocationsArray,
        skills: upsertableSkillsArray,
        languages: upsertableLanguagesArray,
        qualifications: upsertableQualificationsArray,
        documents: upsertableDocumentsArray,
      },
    })
      .then(async () => {
        await deleteJobDetails({
          variables: {
            locationIds: deletableLocationsArray,
            languageIds: deletableLanguagesArray,
            qualificationIds: deletableQualificationsArray,
            skillsIds: deletableSkillsArray,
            documentsIds: deletableDocumentsArray,
          },
        }).then(() => {
          successHandler("Job Updated Successfully");
        });
      })
      .catch((err) => {
        console.log(err);
        errorHandler("There was an error while updating job");
      });
  };

  return (
    <Container title={"Update Job"} className="ml-6 mt-4 mb-8 mr-8">
      <Form
        formBuilder={JobFormBuilder}
        key={jobInitValues.partner_id}
        className="mt-2 grid grid-cols-2 gap-x-6"
        submitButton={{
          text: "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
        }}
        onSubmit={(job) => submitJob(job)}
        initValues={jobInitValues}
      />
    </Container>
  );
}

export default UpdateJob;
