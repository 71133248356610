export default async function CourseUserSCORMTopicReport(courseTopicProgress) {
  const filter_scorm_user_data =
    courseTopicProgress.data?.courses_course_module_mapping
      .map((E) => E.scorm_track_data)
      .reduce((prev, curr) => {
        if (!prev[curr?.user.id]) prev[curr?.user.id] = [];
        const filterHighestAttempt =
          prev?.score_raw > curr?.score_raw ? prev : curr;
        prev[curr?.user.id].push(filterHighestAttempt);
        return prev;
      }, []);

  return await Promise.all(
    Object.keys(filter_scorm_user_data)
      .map((E) => {
        const final_filter = (filter_scorm_user_data[E] || []).reduce(function (
          prev,
          current
        ) {
          return prev?.score_raw > current?.score_raw ? prev : current;
        },
        false);

        return final_filter;
      })
      .map((e) => {
        const father_name = e.user.user_family_details?.find(
          (e) => e.member_type == 0
        )?.name;

        const father_occupation = e.user.user_family_details?.find(
          (e) => e.member_type == 0
        )?.occupation;

        const mother_name = e.user.user_family_details?.find(
          (e) => e.member_type == 1
        )?.name;

        const mother_occupation = e.user.user_family_details?.find(
          (e) => e.member_type == 1
        )?.occupation;

        return {
          // user_id: e.user.id,
          email: e.user.email,
          name: e.user.name,
          mobile_number: e.user?.mobile_number,
          d_o_b: e.user?.date_of_birth,
          gender: e.user?.gender,
          social_category: e.user?.social_category,
          house_number: e.user?.address?.house_number,
          location: e.user?.address?.location,
          city_town_village: e.user?.address?.city_town,
          district: e.user?.address?.district,
          state: e.user?.address?.state,
          pincode: e.user?.address?.pincode,
          country: e.user?.address?.country,
          country_iso_code: e.user?.address?.country_iso_code,
          father_name: father_name,
          father_occupation: father_occupation,
          mother_name: mother_name,
          mother_occupation: mother_occupation,
          qualification_name:
            e.user.user_academic_qualifications[0]?.qualification_name,
          qualification_type:
            e.user.user_academic_qualifications[0]?.qualification_type,
          school: e.user.user_academic_qualifications[0]?.institution_name,
          class:
            e.user.user_academic_qualifications[0]?.qualifications_lookup?.name,
          attempt_number: e.scorm_attempt?.attempt_number,
          attempt_completed: e.scorm_attempt?.attempt_completed,
          attempt_start: e.scorm_attempt?.attempt_start,
          attempt_end: e.scorm_attempt?.attempt_end,
          attempt_score: e.score_raw,
          attempt_status: e.lesson_status,
          ...e.scorm_attempt_array.reduce((acc, obj, index) => {
            return {
              ...acc,
              [`question_${index + 1}-question_name`]: obj.question_text,
              // [`question_${index + 1}-correct_answers`]: obj.correct_answers,
              [`question_${index + 1}-user_response`]: obj.user_response,
              //[`question_${index + 1}-result`]: obj.result,
            };
          }, {}),
          digital_literacy_marks: (e.scorm_attempt_array || []).filter(
            (r) => r.user_response === "A._Yes"
          ).length,
        };
      })
  );
}
