import "grapesjs/dist/css/grapes.min.css";
import _ from "lodash";
import { Form } from "skillstrainer-resource-library";
import Container from "components/Container/Container";
import BulkNotificationsFormBuilder from "formBuilders/BulkNotificationsFormBuilder";
import Axios from "../../../api/Api";
import { useLazyQuery } from "@apollo/client";
import { GET_ADMIN_CSV_USER_IMPORTS_BULK_NOTIFICATION } from "../../../GraphQl/Queries/OnboardingUsers";
import { FiRefreshCw } from "react-icons/fi";
import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import Input from "components/InputGroup/Input";
import Select from "components/InputGroup/Select";
import { CSVLink } from "react-csv";
import { emailDeliveryStatus, secretForBulkNotification } from "../../../api/Consts";

function BulkNotifications() {
  const [searchCSVData, setSearchCSVData] = useState([]);
  const [userSearch, setUserSearch] = useState({
    csvId: "",
    status: "",
    email: "",
    startDate: "",
    endDate: "",
  });

  const searchFunc = async (e) => {
    const response = await Axios().post("/email_delivery_status", { userSearch });
    setSearchCSVData(response.data.data);
  };


  const handleBulkNotification = async (data) => {
    data["secret"] = secretForBulkNotification;
    const response = await Axios().post("/bulk_notifications", { data });
  };
  const [
    runQuery,
    { data: adminCsvUserImportsRes, loading: csvUserImportsLoading },
  ] = useLazyQuery(GET_ADMIN_CSV_USER_IMPORTS_BULK_NOTIFICATION, {
    fetchPolicy: "network-only", // Doesn't check cache before making a network request
  });

  const importsColumns = [
    {
      name: "Id",
      cell: (row, index) => {
        return `${row.id}`;
      },
    },
    {
      name: "CSV import id",
      grow: 5,
      cell: (row, index) => row.admin_csv_user_imports_id,
    },
    {
      name: "Notes",
      grow: 5,
      cell: (row, index) => row.notes,
    },
    {
      name: "Created At",
      grow: 5,
      cell: (row, index) => row.created_at,
    },
    {
      name: "Batch Name",
      grow: 5,
      cell: (row, index) => row.batch_name,
    },
  ];
  useEffect(() => {
    runQuery();
  }, []);

  return (
    <Container title={"Bulk Notifications"}>
      <Form
        className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
        submitButton={{
          text: "Create batch",
          className: `btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6`,
        }}
        formBuilder={BulkNotificationsFormBuilder}
        onSubmit={handleBulkNotification}
      />

      <div className="mt-5">
        <div className="flex flex-row">
          <p className="text-2xl font-medium">Status</p>
          <button
            onClick={() => runQuery()}
            className="p-2 text-gray-500 w-5 h-5"
          >
            <FiRefreshCw />
          </button>
        </div>

        <div className="flex flex-row grid grid-cols-2">
          <div className="flex flex-col w-full mb-4 space-y-2">
            <Select
              label={"Search By Batch Name"}
              options={adminCsvUserImportsRes?.courses_bulk_notification_csvs}
              valueField={"admin_csv_user_imports_id"}
              displayField={"batch_name"}
              onChange={(e) => {
                setUserSearch({ ...userSearch, csvId: e.target.value });
              }}
            />
          </div>
          <div className="flex flex-col w-full mb-4 space-y-2">
            <Select
              label={"Search By Status"}
              options={emailDeliveryStatus.map((status) => ({
                value: status,
                label: status,
              }))}
              valueField="value"
              displayField="label"
              onChange={(e) => {
                setUserSearch({
                  ...userSearch,
                  status: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex flex-col w-full mb-4 space-y-2">
            <Input
              type={"text"}
              placeholder={"Email"}
              label={"Search By Email"}
              value={userSearch.email}
              onChange={(e) => {
                setUserSearch({ ...userSearch, email: e.target.value });
              }}
            />
          </div>
          <div className="flex flex-col w-full mb-4 space-y-2">
            <Input
              type={"date"}
              placeholder={"Start Date"}
              label={"Search By Start Date"}
              value={userSearch.startDate}
              onChange={(e) => {
                setUserSearch({
                  ...userSearch,
                  startDate: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex flex-col w-full mb-4 space-y-2">
            <Input
              type={"date"}
              placeholder={"End Date"}
              label={"Search By End Date"}
              value={userSearch.endDate}
              onChange={(e) => {
                setUserSearch({
                  ...userSearch,
                  endDate: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex flex-col w-full mb-4 space-y-2">
            <button
              onClick={(e) => searchFunc(e)}
              className="btn-primary block mt-4 w-1/6"
            >
              Search
            </button>
          </div>
        </div>
        <CSVLink
          data={searchCSVData}
          filename={"data.csv"}
          className="btn btn-primary"
        >
          Download CSV
        </CSVLink>

        <DataTable
          className="min-w-max"
          columns={importsColumns}
          progressPending={csvUserImportsLoading}
          data={adminCsvUserImportsRes?.courses_bulk_notification_csvs}
        />
      </div>
    </Container>
  );
}

export default BulkNotifications;