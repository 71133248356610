
import { gql } from "@apollo/client";

export const GET_QUESTION_CATEGORY=gql`
query QuestionCategory {
    courses_question_category(order_by: {id: desc}) {
      name
      description
      id
      updated_at
      visible
      created_at
    }
  }
  
  `

  export const GET_QUESTION_TAGS=gql`
  query QuestionTags {
    courses_question_tags {
      id
      name
    }
  }
  
  
  `
  