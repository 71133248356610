import React, { useState, Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import Modal from "react-bootstrap/Modal";
import {
  GET_COURSE_MODULE,
  GET_USERS_SCORM_PROGRESS_BY_TOPIC_ID,
  GET_USERS_QUIZ_PROGRESS_BY_TOPIC_ID,
} from "../../../GraphQl/Queries/CourseBuilder/section";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import queryString from "query-string";
import { Drag, Drop } from "components/drag-and-drop";
import { errorHandler } from "utils/toast";
import { AiOutlineDownload } from "react-icons/ai";

import { CSVLink } from "react-csv";

import {
  FaListUl,
  FaDiceD6,
  FaAward,
  FaTrashAlt,
  FaEye,
  FaEyeSlash,
  FaCopy,
  FaCogs,
  FaPencilAlt,
} from "react-icons/fa";

import { checkUserAuthentication } from "api/Auth";
import { SCORM_DOMAIN } from "api/Consts";
import Input from "components/InputGroup/Input";
import TextArea from "components/InputGroup/TextArea";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";
import CourseUserSCORMTopicReport from "services/coursebuild/CourseUserSCORMTopicReport";
import CourseUserQuizTopicReport from "services/coursebuild/CourseUserQuizTopicReport";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Accordions(props) {
  const coursemodule = useQuery(GET_COURSE_MODULE);
  const [userScormReport, setUserScormReport] = useState([]);

  const [loadingTopicReport, setLoadingTopicReport] = useState(false);

  // Get SCORM TOPIC PROGRESS
  const [runQuerySCORMTopicProgress, courseSCORMTopicProgress] = useLazyQuery(
    GET_USERS_SCORM_PROGRESS_BY_TOPIC_ID,
    {
      fetchPolicy: "network-only", // Doesn't check cache before making a network request
    }
  );

  // Get QUIZ TOPIC PROGRESS
  const [runQueryQuizTopicProgress, courseQuizTopicProgress] = useLazyQuery(
    GET_USERS_QUIZ_PROGRESS_BY_TOPIC_ID,
    {
      fetchPolicy: "network-only", // Doesn't check cache before making a network request
    }
  );

  // SCORM Topic Attempt Function
  useEffect(() => {
    if (courseSCORMTopicProgress.data?.courses_course_module_mapping) {
      const getReportFun = async () => {
        return await CourseUserSCORMTopicReport(courseSCORMTopicProgress).then(
          (response) => {
            setUserScormReport(response);
            setLoadingTopicReport(false);
          }
        );
      };
      getReportFun();
    }
  }, [courseSCORMTopicProgress]);

  // Quiz Topic Progress

  useEffect(() => {
    if (courseQuizTopicProgress.data?.courses_course_module_mapping) {
      const getReportFun = async () => {
        return await CourseUserQuizTopicReport(courseQuizTopicProgress).then(
          (response) => {
            setUserScormReport(response);
            setLoadingTopicReport(false);
          }
        );
      };
      getReportFun();
    }
  }, [courseQuizTopicProgress]);

  const [lgShow, setLgShow] = useState(false);
  const [editLesson, setEditLesson] = useState({
    lessonid: null,
    status: false,
  });

  const [editLessonTopic, setEditLessonTopic] = useState({
    lessonTopicid: null,
    status: false,
  });

  const [lessonTitle, setlessonTitle] = useState({
    title: props.content.name,
    sectionid: null,
  });

  const [lessonTopicTitle, setlessonTopicTitle] = useState({
    title: null,
    topicid: null,
  });

  const [showScormModal, setShowScormModal] = useState(false);

  const [auth, setAuth] = useState({});

  const [authLoad, setAuthLoad] = useState(false);

  const [scormPreview, setScormPreview] = useState({
    mapping_id: null,
    name: null,
  });

  const [resourcePreview, setResourcePreview] = useState([]);
  const [userTopicReportShow, setuserTopicReportShow] = useState(false);
  const [resourcePreviewModal, setResourcePreviewModal] = useState(false);

  const [lessonModal, setLessonModal] = useState(false);

  const [lessonData, setLessonData] = useState({});

  const location = useLocation();
  const params = queryString.parse(location.search);

  const addActivity = (sectionid) => {
    setLgShow(true);
  };

  //Copy topic filter
  const copyTopicFilter = (id) => {
    const filterSelectedTopic = props.content.coursesec.find(
      (e) => e.id === id
    );
    props.copyTopicFun(filterSelectedTopic);
  };

  //copy lesson Filter
  const copyLessonFilter = (lessonid) => {
    props.copyLessonFun(props.content);
  };

  //lesson input
  const updateInput = (e, sectionid) => {
    setlessonTitle({
      title: e.target.value,
      sectionid: sectionid,
    });
  };

  //lesson name update
  const lessonUpdate = () => {
    props.updateLessonFun(lessonTitle);
    setEditLesson({
      lessonid: null,
      status: false,
    });
  };

  //topic name update
  const lessonTopicUpdate = () => {
    props.updateLessonTopicFun(lessonTopicTitle);
    setEditLessonTopic({
      lessonTopicid: null,
      status: false,
    });
  };

  //lesson Topic input
  const updateTopicInput = (e, topicid) => {
    setlessonTopicTitle({
      title: e.target.value,
      topicid: topicid,
    });
  };

  // find Resources
  const findResources = (id) => {
    const filterResources = props.content.coursesec.find(
      (e) => e.id === id
    ).resources;
    props.openResourceModal(id, filterResources);
  };

  //Preview Topic Resources
  const previewResource = (id) => {
    const filterModuleResources = props.content.coursesec.find(
      (e) => e.id === id
    ).module_resources;

    setResourcePreviewModal(!resourcePreviewModal);
    setResourcePreview(filterModuleResources);
  };
  useEffect(() => {
    const element = document.getElementById(location.state?.upadtedModuleId);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }

    checkAuth();
  }, []);

  const checkAuth = async () => {
    const user = await checkUserAuthentication();
    setAuth(user?.db_user);
    setAuthLoad(!authLoad);
  };

  const previewScorm = (name, id) => {
    setScormPreview({
      mapping_id: id,
      name: name,
    });

    setShowScormModal(!showScormModal);
  };

  const filterLessonFun = (lessonid) => {
    const filter = Array(props.content).find((e) => e.id === lessonid);
    setLessonData({
      name: filter?.name,
      description: filter?.description,
      id: filter?.id,
    });
    setLessonModal(!lessonModal);
  };

  const submitLessonFun = (e) => {
    e.preventDefault();

    props.updateLessonDataFun(lessonData);
  };

  return (
    <div>
      {/* User Topic Progress */}
      {/* Lesson Edit Modal */}
      <Modal
        size="lg"
        show={userTopicReportShow}
        onHide={() => {
          setuserTopicReportShow(!userTopicReportShow);
        }}
        aria-labelledby=""
      >
        <Modal.Header closeButton>
          <Modal.Title id=""></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CSVLink
            className="no-underline bg-orange p-2 rounded-md text-white my-5 mx-4"
            filename="Users-Topic-Attempt-Report"
            data={userScormReport}
            target="_blank"
          >
            {loadingTopicReport
              ? "Please Wait..."
              : "Export Topic Attempt Report as CSV"}
          </CSVLink>
        </Modal.Body>
      </Modal>

      {/* Lesson Edit Modal */}
      <Modal
        size="lg"
        show={lessonModal}
        onHide={() => {
          setLessonModal(!lessonModal);
        }}
        aria-labelledby=""
      >
        <Modal.Header closeButton>
          <Modal.Title id=""></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={submitLessonFun} className="">
              <div class="col-span-6 sm:col-span-3 mx-4 mt-4">
                <div class="undefined">
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <Input
                      label={"Lesson Name"}
                      type={"text"}
                      data-key={"name"}
                      onChange={(e) =>
                        setLessonData({
                          ...lessonData,
                          name: e.target.value,
                        })
                      }
                      value={lessonData["name"]}
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-3 mt-4">
                <TextArea
                  label={"Lesson Description"}
                  data-key={"description"}
                  onChange={(e) =>
                    setLessonData({
                      ...lessonData,
                      description: e.target.value,
                    })
                  }
                  rows="5"
                  cols="30"
                  type="text"
                  value={lessonData["description"]}
                />{" "}
              </div>
              <div class="px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  class="primary-cta btn-primary hover:bg-opacity-90"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      {/* Scorm Preview */}
      <Modal
        size="lg"
        show={showScormModal}
        onHide={() => {
          setShowScormModal(false);
          setScormPreview({
            mapping_id: null,
            name: null,
          });
        }}
        aria-labelledby=""
      >
        <Modal.Header closeButton>
          <Modal.Title id="">{scormPreview.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!authLoad ? (
            <div class="flex justify-center items-center">
              <div
                class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <iframe
              title="adminscorm"
              src={`${SCORM_DOMAIN}/api/admin_renderscorm?module_mapping_id=${scormPreview.mapping_id}&user_id=${auth.id}`}
              height="700"
              width="100%"
              allowfullscreen="true"
            ></iframe>
          )}
        </Modal.Body>
      </Modal>

      {/* Resourec activity preview */}

      <Modal
        size="lg"
        show={resourcePreviewModal}
        onHide={() => {
          setResourcePreviewModal(!resourcePreviewModal);
          setResourcePreview([]);
        }}
        aria-labelledby=""
      >
        <Modal.Header closeButton>
          <Modal.Title id=""></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
              Resource Lists
            </h1>
          </div>
          <div className="w-full bg-white rounded-lg shadow-lg">
            <ul className="divide-y-2 divide-gray-400">
              {(resourcePreview || []).map((e, i) => {
                return (
                  <li
                    key={e.id}
                    className="flex justify-between p-3 hover:bg-orange hover:text-white"
                  >
                    {e.actual_file_name}
                    <div className="flex justify-end gap-3 p-3 hover:bg-orange hover:text-white">
                      {props.fileLoading?.resourceId === e.id &&
                      props.fileLoading.status ? (
                        <div class="flex justify-end items-center">
                          <div
                            class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <AiOutlineDownload
                          className="cursor-pointer mr-0"
                          size={22}
                          onClick={() => {
                            props.fileLoadingFun(e.id);

                            props.handleDownload(e.file_name);
                          }}
                        />
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      {/*Module Activity */}
      {lgShow ? (
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Add activity
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex gap-3">
              {coursemodule &&
                coursemodule.data.courses_course_module.map((data) => {
                  return (
                    <div
                      key={data.id}
                      className=" box-border h-28 w-28 p-2 border-2 border-black rounded-md flex justify-center relative bg-orange"
                    >
                      {data.name == "Quiz" ? (
                        <FaListUl size={25} className=" text-white" />
                      ) : data.name == "SCORM Package" ? (
                        <FaDiceD6 size={25} className="top-5 text-white " />
                      ) : (
                        <FaAward size={25} className="top-5 text-white" />
                      )}

                      <Link
                        className="absolute top-12 text-center inline text-white"
                        to={
                          getModulePathStringById(
                            ModuleNameIdMap.addCourseActivity
                          ) +
                          "?id=" +
                          params.id +
                          "&modid=" +
                          data.id +
                          "&secid=" +
                          props.content.id
                        }
                      >
                        {data.name}
                      </Link>
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}

      <div key={props.content.id} id={props.content.id} className="mb-2 mt-2">
        <div
          className="accordion-item"
          style={{
            border: "4px solid #D97341",
          }}
        >
          <div className="accordion-title text-black text-md bg-gray p-2 flex flex-row">
            <div className="w-full">
              <div className="w-full text-black text-md font-semibold inline-flex">
                {editLesson.lessonid === props.content.id &&
                editLesson.status ? (
                  <form
                    className="w-full max-w-sm"
                    onSubmit={() => lessonUpdate()}
                  >
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-2/3">
                        <input
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                          type="text"
                          value={lessonTitle.title}
                          placeholder="Update Lesson Name"
                          onChange={(e) => updateInput(e, props.content.id)}
                        />
                      </div>
                      <button
                        className="shadow bg-orange hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                        type="button"
                        onClick={() => {
                          if (lessonTitle.title) {
                            lessonUpdate();
                          } else {
                            errorHandler("Please enter lesson title");
                          }
                        }}
                      >
                        update
                      </button>
                      <button
                        className="shadow bg-orange hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded ml-3"
                        type="button"
                        onClick={() =>
                          setEditLesson({
                            lessonid: null,
                            status: false,
                          })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                ) : (
                  <>
                    <span className="pr-4">{props.content.name} </span>
                    <FaPencilAlt
                      className="cursor-pointer"
                      onClick={() =>
                        setEditLesson({
                          lessonid: props.content.id,
                          status: true,
                        })
                      }
                    />
                  </>
                )}
              </div>

              {props.content?.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.content?.description?.replace(
                      /\n/g,
                      "<br />"
                    ),
                  }}
                />
              )}
            </div>

            <div className="pr-2">
              {" "}
              <FaPencilAlt
                className="cursor-pointer"
                onClick={() => filterLessonFun(props.content.id)}
              />
            </div>
            <div className="pr-2">
              <FaCopy
                className="cursor-pointer"
                onClick={() => copyLessonFilter(props.content.id)}
              />
            </div>
            <div className="pr-2">
              {props.content.visible ? (
                <FaEye
                  className="cursor-pointer"
                  onClick={() =>
                    props.hideLessonFun(props.content.id, props.content.visible)
                  }
                />
              ) : (
                <FaEyeSlash
                  className="cursor-pointer"
                  onClick={() =>
                    props.hideLessonFun(props.content.id, props.content.visible)
                  }
                />
              )}
            </div>
            <div>
              <FaTrashAlt
                className="cursor-pointer"
                onClick={() => props.deleteLessonFun(props.content.id)}
              />
            </div>
          </div>
          <div className="accordion-content p-3">
            <div className="p-0">
              <Drop
                key={props.content.id}
                id={props.content.id}
                type="droppable-item"
              >
                {props.content.coursesec.map((data, index) => {
                  return (
                    <Drag
                      className="draggable"
                      key={data.id}
                      id={data.id}
                      index={index}
                    >
                      <div
                        key={data.id}
                        id={data.id}
                        className="list p-2 m-3 flex flex-row"
                        style={{
                          backgroundColor: "beige",
                          border: "3px solid #D97341",
                        }}
                      >
                        {editLessonTopic.lessonTopicid === data.id &&
                        editLessonTopic.status ? (
                          <form
                            className="w-full  ml-4 p-2 inline-flex gap-2"
                            onSubmit={() => lessonTopicUpdate()}
                          >
                            <div className="md:flex md:items-center mb-6">
                              <div className="md:w-2/3">
                                <input
                                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                  type="text"
                                  value={lessonTopicTitle.title}
                                  placeholder="Update Topic Name"
                                  onChange={(e) => updateTopicInput(e, data.id)}
                                />
                              </div>
                              <button
                                className="shadow bg-orange hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                                type="button"
                                onClick={() => {
                                  if (lessonTopicTitle.title) {
                                    lessonTopicUpdate();
                                  } else {
                                    errorHandler("Please enter topic title");
                                  }
                                }}
                              >
                                update
                              </button>
                              <button
                                className="shadow bg-orange hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded ml-3"
                                type="button"
                                onClick={() =>
                                  setEditLessonTopic({
                                    lessonTopicid: false,
                                    status: true,
                                  })
                                }
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        ) : (
                          <div className="w-full ml-4 p-2  ">
                            <div className="inline-flex gap-2 cursor-pointer font-semibold">
                              <p
                                className="underline"
                                onClick={() =>
                                  data.Module.type === "scorm-package"
                                    ? previewScorm(data.name, data.id)
                                    : data.Module.type === "files-attachment"
                                    ? previewResource(data.id)
                                    : ""
                                }
                              >
                                {data.name}{" "}
                              </p>
                              <FaPencilAlt
                                className="cursor-pointer"
                                onClick={() => {
                                  setEditLessonTopic({
                                    lessonTopicid: data.id,
                                    status: true,
                                  });

                                  setlessonTopicTitle({
                                    title: data.name,
                                    topicid: data.id,
                                  });
                                }}
                              />
                            </div>
                            {data.description && (
                              <div
                                className="break-normal text-base"
                                dangerouslySetInnerHTML={{
                                  __html: data.description?.replace(
                                    /\n/g,
                                    "<br />"
                                  ),
                                }}
                              />
                            )}
                          </div>
                        )}

                        {data.Module.type !== "files-attachment" && (
                          <div
                            className="pt-1 pr-2 font-semibold cursor-pointer"
                            onClick={() => findResources(data.id)}
                          >
                            Resource({data.resources.length})
                          </div>
                        )}

                        <div className="pt-2 pr-2">
                          <FaCopy
                            className="cursor-pointer"
                            onClick={() => copyTopicFilter(data.id)}
                          />
                        </div>

                        <div className="pt-2">
                          {data.visible ? (
                            <FaEye
                              className="cursor-pointer"
                              onClick={() =>
                                props.hideTopicFun(data.id, data.visible)
                              }
                            />
                          ) : (
                            <FaEyeSlash
                              className="cursor-pointer"
                              onClick={() =>
                                props.hideTopicFun(data.id, data.visible)
                              }
                            />
                          )}
                        </div>

                        <Menu as="div" className="relative">
                          <div>
                            <Menu.Button
                              className="inline-flex justify-center w-full  
                              px-4 py-2  text-sm 
                              font-medium text-black-700 "
                            >
                              <FaCogs size={20} />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md z-50 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1 z-50	">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700 text-sm text-japanese_indigo font-bold pr-2",
                                        "block px-4 py-2 text-sm text-japanese_indigo font-bold pr-2"
                                      )}
                                      to={`${
                                        data.Module.type == "quiz"
                                          ? getModulePathStringById(
                                              ModuleNameIdMap.quizEdit
                                            ) +
                                            "?id=" +
                                            data.id
                                          : data.Module.type ==
                                            "files-attachment"
                                          ? getModulePathStringById(
                                              ModuleNameIdMap.resourceEdit
                                            ) +
                                            "/?id=" +
                                            data.id
                                          : data.Module.type == "scorm-package"
                                          ? getModulePathStringById(
                                              ModuleNameIdMap.scormEdit
                                            ) +
                                            "/?id=" +
                                            data.id
                                          : ""
                                      }`}
                                    >
                                      Edit Settings
                                    </Link>
                                  )}
                                </Menu.Item>

                                <Menu.Item>
                                  <p
                                    className="text-sm text-japanese_indigo 
                                font-bold block px-4 py-0 cursor-pointer"
                                    onClick={() => props.deleteMapping(data.id)}
                                  >
                                    Delete
                                  </p>
                                </Menu.Item>
                                {data.Module.type === "scorm-package" &&
                                  data.enable_user_attempt && (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <div
                                          className="px-4 py-2 font-semibold cursor-pointer"
                                          onClick={() => {
                                            setuserTopicReportShow(
                                              !userTopicReportShow
                                            );
                                            runQuerySCORMTopicProgress({
                                              variables: {
                                                id: data.id,
                                              },
                                            });
                                            setLoadingTopicReport(true);
                                          }}
                                        >
                                          Users Attempt Report
                                        </div>
                                      )}
                                    </Menu.Item>
                                  )}

                                {data.Module.type === "quiz" && (
                                  <>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "block px-4 py-3 text-sm text-japanese_indigo font-bold"
                                          )}
                                          to={
                                            getModulePathStringById(
                                              ModuleNameIdMap.editQuestion
                                            ) +
                                            `/?id=${params.id}&&quizid=${data.id}`
                                          }
                                        >
                                          Add Questions
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "block px-4 py-3 text-sm text-japanese_indigo font-bold"
                                          )}
                                          to={
                                            getModulePathStringById(
                                              ModuleNameIdMap.viewModuleQuestions
                                            ) +
                                            `/?id=${params.id}&&quizid=${data.id}`
                                          }
                                        >
                                          View Questions
                                        </Link>
                                      )}
                                    </Menu.Item>

                                    {data.Module.type === "quiz" &&
                                      data.enable_user_attempt && (
                                        <Menu.Item>
                                          {({ active }) => (
                                            <div
                                              className="px-4 py-2 font-semibold cursor-pointer"
                                              onClick={() => {
                                                setuserTopicReportShow(
                                                  !userTopicReportShow
                                                );
                                                runQueryQuizTopicProgress({
                                                  variables: {
                                                    id: data.id,
                                                  },
                                                });
                                                setLoadingTopicReport(true);
                                              }}
                                            >
                                              Users Attempt Report
                                            </div>
                                          )}
                                        </Menu.Item>
                                      )}
                                  </>
                                )}

                                {data.Module.type !== "files-attachment" && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <p
                                        className="text-gray-700 text-sm text-japanese_indigo 
                                 font-bold block px-4 cursor-pointer"
                                        onClick={() => findResources(data.id)}
                                      >
                                        Resource
                                      </p>
                                    )}
                                  </Menu.Item>
                                )}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </Drag>
                  );
                })}
              </Drop>
            </div>

            <div className="px-4 py-3 text-right sm:px-6">
              <button
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange"
                type="button"
                onClick={() => addActivity(props.content.id)}
              >
                +Add activity
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
