import { gql } from "@apollo/client";

export const GET_PARTNERS = gql`
  query getPartners {
    courses_partner {
      about_partner
      activation_end_date
      active
      activation_start_date
      contact_person_email
      created_at
      id
      contact_person_name
      name
      logo
      identifier
      organization_name
      pin_code
      address_city_town
      address_district
      address_state
      address_country
      address_house_number
      address_location
      projects {
        id
        name
      }
    }
  }
`;

export const GETdATA = gql`
  query getPartners {
    courses_partner {
      about_partner
      activation_end_date
      active
      activation_start_date
      contact_person_email
      created_at
      id
      contact_person_name
      name
      logo
      identifier
      organization_name
      pin_code
      address_city_town
      address_district
      address_state
      address_country
      address_house_number
      address_location
      projects {
        id
        name
      }
    }
  }
`;
export const GET_PARTNERS_BY_ID = gql`
  query getPartnersById($id: bigint) {
    courses_partner(where: { id: { _eq: $id } }) {
      id
      name
      organization_name
      created_at
      updated_at
      active
      activation_start_date
      activation_end_date
      about_partner
      contact_person_email
      contact_person_name
      organization_name
      organization_category
      organization_type
      pin_code
      address_city_town
      address_district
      address_state
      address_country
    }
  }
`;

export const GET_PARTNERS_WITH_FILTER = gql`
  query getPartnersWithFilter($filterClause: courses_partner_bool_exp!) {
    courses_partner(where: $filterClause) {
      id
      name
      organization_name
    }
  }
`;

export const GET_PARTNERS_PAGINATION = gql`
  query getCoursePartner($page: Int, $per_page: Int) {
    courses_partner(limit: $per_page, offset: $page) {
      about_partner
      activation_end_date
      active
      activation_start_date
      contact_person_email
      created_at
      id
      contact_person_name
      name
      logo
      identifier
      organization_name
      organization_category
      organization_type
      pin_code
      contact_person_mobile_number
      address_city_town
      address_district
      address_state
      address_country
      address_house_number
      address_location
      organisation_head_contact_person_name
      organisation_head_contact_person_email
      organisation_head_contact_person_mobile_number
      operational_head_contact_person_name
      operational_head_contact_person_email
      operational_head_contact_person_mobile_number
    }
  }
`;
export const GET_TOTAL_PARTNRES = gql`
  query getTotalCourses {
    courses_partner_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PARTNER_PROJECTS_BY_PARTNER_ID = gql`
  query getPartnerProjects($partner_id: bigint = "") {
    courses_partner_projects(where: { partner_id: { _eq: $partner_id } }) {
      id
      name
      created_at
      country
      city
      pincode
      state
      type
      updated_at
    }
  }
`;

export const GET_USER_BY_PARTNER_PROJECT_ID = gql`
  query getUserByPartnerAndProject($project_id: bigint = "") {
    courses_partner_project_users(where: { project_id: { _eq: $project_id } }) {
      id
      updated_at
      user {
        name
        mobile_number
        email
        date_of_birth
        created_at
      }
    }
  }
`;

export const GET_WORK_PARTNER_SIGNUP_QUERIES = gql`
  query getWorkPartnerSignupQueries {
    courses_signup_enterprises {
      address
      contact_name
      contact_number
      created_at
      email_id
      id
      organisation_name
      organisation_type
      pincode
      purpose
      updated_at
    }
  }
`;

export const GET_PARTNER_WEBSITE_CONFIGURATION_BY_ID = gql`
  query getPartnerWebsiteConfiguration($partner_id: bigint) {
    courses_partner_website_configuration(
      where: { partner_id: { _eq: $partner_id } }
    ) {
      id
      partner_id
      partner_data_key
      partner_data_value
    }

    courses_partner_revenue_split_directives(
      where: { partner_id: { _eq: $partner_id } }
    ) {
      id
      course_id
      partner_id
      commission
    }
  }
`;

export const GET_PARTNER_DIRECTIVES_TRANSACTION = gql`
  query MyQuery(
    $where: courses_partner_revenue_split_transactions_bool_exp = {}
  ) {
    courses_partner_revenue_split_transactions(where: $where) {
      directive {
        commission
        id
        course_id
        course {
          full_name
        }
      }
      partner_id
      partner {
        name
      }
      order {
        amount
        user {
          email
        }
        ordercourse {
          full_name
        }
      }
      order_id
    }
  }
`;

export const GET_PARTNER_DIRECTIVES_TRANSACTION_PAGINATION = gql`
  query MyQuery(
    $whereClause: courses_partner_revenue_split_transactions_bool_exp = {}
    $limit: Int
    $offset: Int
  ) {
    courses_partner_revenue_split_transactions(
      where: $whereClause
      limit: $limit
      offset: $offset
    ) {
      directive {
        commission
        id
        course_id
        course {
          full_name
        }
      }
      partner_id
      partner {
        name
      }
      order {
        amount
        user {
          email
        }
        ordercourse {
          full_name
        }
      }
      order_id
    }
  }
`;

export const GET_PARTNER_SETTLEMENT = gql`
  query MyQuery($where: courses_partner_settlement_transactions_bool_exp = {}) {
    courses_partner_settlement_transactions(where: $where) {
      amount
      partner_id
      created_at
      partner {
        name
      }
    }
  }
`;

export const GET_PARTNER_WEBSITE_CONFIG_BY_PARTNER_ID = gql`
  query ($partnerId: bigint) {
    courses_partner_website_configuration(
      where: { partner_id: { _eq: $partnerId } }
    ) {
      id
      partner_id
      subdomain
      status
      edited_at
      website_url
      created_at
      updated_at
      partner_website_configuration_tuples {
        id
        partner_id
        partner_data_key
        partner_data_value
        data_type
      }
      partner_revenue_split_directives {
        id
        course_id
        partner_id
        commission
      }
    }
  }
`;

export const GET_PARTNER_WEBSITE_CONFIGS = gql`
  query getPartnerConfig(
    $where: courses_partner_website_configuration_bool_exp = {}
  ) {
    courses_partner_website_configuration(
      where: $where
      order_by: { id: desc }
    ) {
      id
      partner_id
      subdomain
      status
      edited_at
      website_url
      created_at
      last_published_at
      partner {
        name
      }
    }
  }
`;
