import { useQuery } from "@apollo/client";
import { GET_FILTER_DATA } from "GraphQl/Queries/CertificateTemplateMaker";
import { GET_PARTNERS } from "GraphQl/Queries/Partner";
import { useState, useEffect, useMemo } from "react";
import * as yup from "yup";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";

function FilterFormBuilder(values) {
  const [schema, setSchema] = useState();
  const { data } = useQuery(GET_PARTNERS);
  const partnerAccess = useUserPartnerAccessDetails();
  const accessiblePartners = useMemo(
    () => partnerAccess.partnerIds,
    [partnerAccess]
  );
  const { data: filterData } = useQuery(GET_FILTER_DATA);

  useEffect(() => {
    const schema = {
      course: {
        label: "Course",
        type: "select",
        options: partnerAccess.isAdmin
          ? (filterData?.courses_course || []).map((course) => {
              return {
                label: course.full_name,
                value: course.id,
              };
            })
          : filterData
          ? filterData.courses_course
              .map((course) => {
                const coursePartners =
                  course.partner_revenue_split_directives.map(
                    (item) => item.partner_id
                  );
                // let flag = false;
                // for (let partner of accessiblePartners || []) {
                //   for (let coursePartner of coursePartners) {
                //     if (coursePartner == partner) flag = true;
                //   }
                // }
                // if (flag) {
                //   return {
                //     label: course.full_name,
                //     value: course.id,
                //   };
                // } else return false;

                if (accessiblePartners) {
                  for (let partner of accessiblePartners) {
                    if (coursePartners.includes(partner)) {
                      return {
                        label: course.full_name,
                        value: course.id,
                      };
                    }
                  }
                }
                return null;
              })
              .filter((item) => item)
          : [],
      },
      partner_id: {
        label: "Partner",
        type: "select",
        options: (data?.courses_partner || [])
          .map((partner) => ({
            label: partner.name,
            value: partner.id,
          }))
          .filter((part) => {
            if (partnerAccess.isAdmin) return true;
            else {
              if (accessiblePartners?.includes(part.value)) return true;
              else return false;
            }
          }),
        schema: yup.string(),
      },
      name: {
        label: "Name",
        type: "text",
        schema: yup.string(),
      },
      // from_date: {
      //   label: "From Date",
      //   type: "date",
      //   schema: yup.string(),
      // },
      // to_date: {
      //   label: "To Date",
      //   type: "date",
      //   schema: yup.string(),
      // },
    };
    setSchema(schema);
  }, [values, filterData, data, partnerAccess]);

  return schema;
}

export default FilterFormBuilder;
