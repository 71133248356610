import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { CSVLink } from "react-csv";
import React, { useState, useEffect } from "react";
import _ from "lodash";
export default function ExportReportModal(props) {
  const [courseReport, setCourseReport] = useState([]);

  const [topicList, setTopiclist] = useState([]);

  const [isCheckAll, setIsCheckAll] = useState(true);
  const [isCheckAllGeneral, setIsCheckAllGeneral] = useState(true);

  const [removedKey, setRemovedKey] = useState([
    "name",
    "id",
    "course_id",
    "section",
    "Module",
  ]);

  useEffect(() => {
    const { reports, courseTopics } = props;

    //Remove Unwanted key form array of objects
    const newReportArr = _.map(reports, function (row) {
      return _.omit(row, removedKey);
    });
    setCourseReport(newReportArr);

    //Lesson topics
    const topics = courseTopics?.courses_course_module_mapping.map((e) => {
      return {
        id: e.id,
        name: e.name,
      };
    });
    setTopiclist(topics);
  }, [props]);

  // Add/Remove checked item from list
  const handleCheck = (event) => {
    let updatedList = [...props?.ischecked];
    if (event.target.checked) {
      updatedList = [...props?.ischecked, parseInt(event.target.value)];
    } else {
      updatedList.splice(
        props?.ischecked.indexOf(parseInt(event.target.value)),
        1
      );
    }

    props.handleChecked(updatedList);
  };

  // Set Enrolled Users Report Csv Header
  const headers = [
    { label: "Student Id", key: "user_id" },
    { label: "Student Name", key: "user.name" },
    { label: "Email", key: "user.email" },
    { label: "Phone", key: "user.mobile_number" },
    { label: "Course Name", key: "course_obj_relation.full_name" },
    { label: "Enroll Status", key: "enroll_status" },
    { label: "Enroll Date", key: "created_at" },
  ];

  // Set course progress Report General Columns or Header

  const GeneralHeaders = [
    { label: "skillstrainer student id", key: "skillstrainer_student_id" },
    { label: "Course Name", key: "course_name" },
    { label: "Nsqf Level", key: "nsqf_level" },
    { label: "Salutation", key: "salutation" },
    { label: "Username", key: "username" },
    { label: "Email", key: "useremail" },
    { label: "Father Name", key: "father_name" },
    { label: "Mobile", key: "mobile_number" },
    { label: "D.O.B", key: "date_of_birth" },
    { label: "Gender", key: "gender" },
    { label: "Permanent Address Line", key: "permanent_address_line" },
    { label: "Country", key: "country" },
    { label: "City Town", key: "city_town" },
    { label: "District", key: "district" },
    { label: "State", key: "state" },
    { label: "Pincode", key: "pincode" },

    { label: "Client Student ID", key: "client_student_id" },
    { label: "Client Batch ID", key: "client_batch_id" },
    { label: "Partner User Slot Number", key: "partner_user_slot_number" },
    { label: "Is Currently Working", key: "is_currently_working" },
    { label: "Job Requirement", key: "job_requirement" },

    { label: "Partner Id", key: "partner_id" },
    { label: "SkillsTrainer Batch ID", key: "skillstrainer_batch_id" },
    { label: "Partner Name", key: "partner_name" },
    { label: "Course Enrollment Date", key: "course_enrollment_date" },
    { label: "Course Start Date", key: "course_start_date" },
    { label: "Course Completion Date", key: "course_completion_date" },
  ];

  //All select topics handler
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    props.topicSelectAll(!isCheckAll);
  };

  // All select general
  const handleSelectAllGeneral = (event) => {
    setIsCheckAllGeneral(!isCheckAllGeneral);
    if (event.target.checked) {
      setRemovedKey(["name", "id", "course_id", "section", "Module"]);
    } else {
      setRemovedKey((oldarray) => [
        ...oldarray,
        "skillstrainer_student_id",
        "course_name",
        "salutation",
        "username",
        "useremail",
        "nsqf_level",
        "father_name",
        "mobile_number",
        "date_of_birth",
        "gender",
        "permanent_address_line",
        "country",
        "city_town",
        "district",
        "state",
        "pincode",
        "is_currently_working",
        "job_requirement",
        "client_student_id",
        "client_batch_id",
        "partner_user_slot_number",
        "partner_id",
        "skillstrainer_batch_id",
        "partner_name",
        "course_enrollment_date",
        "course_start_date",
        "course_completion_date",
      ]);
    }
  };

  const handleCheckGeneral = (event) => {
    let updatedList = [...removedKey];
    if (event.target.checked) {
      updatedList.splice(removedKey.indexOf(event.target.value), 1);
    } else {
      updatedList = [...removedKey, event.target.value];
    }
    setRemovedKey(updatedList);
  };

  useEffect(() => {
    const filterReport = _.map(props?.reports, function (row) {
      return _.omit(row, removedKey);
    });
    setCourseReport(filterReport);
  }, [removedKey]);

  return (
    <Tabs
      defaultActiveKey="enrolled_users"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="enrolled_users" title="Enrolled Users">
        {props.enrolledUsers ? (
          <CSVLink
            className="no-underline bg-orange p-2 rounded-md text-white my-5 mx-4"
            filename="Enrolled Users"
            data={props.enrolledUsers}
            headers={headers}
            target="_blank"
          >
            Export Enrolled List as CSV
          </CSVLink>
        ) : (
          "<h2>Sorry, No Enrolled Users Found In This Course</h2>"
        )}
      </Tab>
      <Tab eventKey="user_course_report" title="User Course Reports">
        <div className="flex flex-row pl-40">
          <div className="w-full">
            <h3 className="text-center font-semibold">Select Course Topics</h3>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="h-7 w-7 focus:ring-0 
          focus:ring-offset-0 focus:border-0 rounded"
                onChange={handleSelectAll}
                checked={isCheckAll}
              ></input>
              <label
                htmlFor="comments"
                className="ml-3 text-sm font-medium text-gray-700"
              >
                Select All
              </label>
            </div>
            <ul className="toppings-list overflow-y-scroll	h-screen w-full	">
              {topicList?.map(({ id, name }) => {
                return (
                  <li key={id}>
                    <div className="toppings-list-item">
                      <div className="left-section">
                        <input
                          type="checkbox"
                          id={`custom-checkbox-${id}`}
                          name={name}
                          value={id}
                          onChange={handleCheck}
                          // defaultChecked={true}
                          checked={props?.ischecked.includes(id)}
                        />
                        <label htmlFor={`custom-checkbox-${id}`}>{name}</label>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="w-full">
            <h3 className="text-center font-semibold">Select General Topics</h3>
            <div className="flex items-center justify-between">
              <div className="mx-2">
                <input
                  type="checkbox"
                  className="h-7 w-7 focus:ring-0 
          focus:ring-offset-0 focus:border-0 rounded"
                  onChange={handleSelectAllGeneral}
                  checked={isCheckAllGeneral}
                ></input>

                <label
                  htmlFor="comments"
                  className="ml-3 text-sm font-medium text-gray-700"
                >
                  Select All
                </label>
              </div>

              {topicList && topicList.length > 0 ? (
                <CSVLink
                  className="no-underline bg-orange p-2 rounded-md text-white my-5 mx-2  items-center "
                  filename="Users Progress Report"
                  data={courseReport}
                  target="_top"
                >
                  Export User Topics Progress as CSV
                </CSVLink>
              ) : (
                "Sorry, No Modules Added In This Course"
              )}
            </div>
            <ul className="toppings-list overflow-y-scroll	h-screen w-full	">
              {GeneralHeaders?.map(({ label, key }) => {
                return (
                  <li key={key}>
                    <div className="toppings-list-item">
                      <div className="left-section">
                        <input
                          type="checkbox"
                          id={`custom-checkbox-${key}`}
                          name={label}
                          value={key}
                          onChange={handleCheckGeneral}
                          checked={!removedKey.includes(key)}
                        />
                        <label htmlFor={`custom-checkbox-${key}`}>
                          {label}
                        </label>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Tab>
    </Tabs>
  );
}
