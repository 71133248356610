import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_JOB_APPLICATION_SCORE_AND_NOTES } from "GraphQl/Queries/Jobs";
import { UPDATE_JOB_APPLICATION_SCORE_AND_NOTES } from "GraphQl/Mutations/Jobs";
import { errorHandler, successHandler } from "utils/toast";

function ApplicantEvaluation(props) {
  const { jobId, userId } = props;
  const [formData, setFormData] = useState({
    score: "",
    notes: "",
  });
  const [updateEvaluation] = useMutation(
    UPDATE_JOB_APPLICATION_SCORE_AND_NOTES
  );
  const { data: evaluationData, refetch } = useQuery(
    GET_JOB_APPLICATION_SCORE_AND_NOTES,
    {
      variables: {
        jobId: jobId,
        userId: userId,
      },
    }
  );
  const saveEvaluation = async () => {
    await updateEvaluation({
      variables: {
        jobId: jobId,
        userId: userId,
        score: formData.score || 0,
        notes: formData.notes,
      },
    })
      .then(() => {
        successHandler("Evaluation Saved");
        refetch();
      })
      .catch((err) => {
        console.log(err);
        errorHandler("Error while saving Evaluation");
      });
  };

  useEffect(() => {
    const evaluationObject = evaluationData?.courses_job_applications[0] || {
      score: 0,
      notes: "",
    };
    setFormData({
      score: evaluationObject.score,
      notes: evaluationObject.notes,
    });
  }, [evaluationData]);

  return (
    <div className="flex flex-col space-y-2 w-full">
      <label className="block text-sm font-medium text-gray-900 dark:text-white">
        Notes
      </label>
      <textarea
        onBlur={(e) => {
          setFormData({
            ...formData,
            notes: e.target.value,
          });
        }}
        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        defaultValue={formData.notes}
      ></textarea>
      <div className="flex flex-row space-x-2 mt-2">
        {" "}
        <label className="block text-sm font-medium text-gray-900">
          Score:
        </label>
        <span className="block text-sm font-medium text-gray-900">
          {formData.score}
        </span>
      </div>

      <input
        type="range"
        min={"0"}
        max={"100"}
        step={"1"}
        value={formData.score}
        onChange={(e) => {
          setFormData({
            ...formData,
            score: e.target.value,
          });
        }}
      ></input>
      <button className="btn-primary w-1/4" onClick={saveEvaluation}>
        Save Evaluation
      </button>
    </div>
  );
}

export default ApplicantEvaluation;
