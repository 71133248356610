import { Form } from "skillstrainer-resource-library";
import JobFormBuilder from "formBuilders/JobFormBuilder";
import { useMutation } from "@apollo/client";
import {
  CREATE_JOB,
  DELETE_JOB,
  INSERT_JOB_LOCATIONS_SKILLS_LANGUAGES_QUALIFICATIONS_DOCUMENTS,
} from "GraphQl/Mutations/Jobs";
import { errorHandler, successHandler } from "utils/toast";
import { JobHelper } from "./JobHelper";
import Container from "components/Container/Container";

function CreateJob() {
  const [createNewJob] = useMutation(CREATE_JOB);
  const [deleteJob] = useMutation(DELETE_JOB);
  const [insertJobQualificationsLanguagesLocationsSkills] = useMutation(
    INSERT_JOB_LOCATIONS_SKILLS_LANGUAGES_QUALIFICATIONS_DOCUMENTS
  );

  async function submitJob(formJobObject) {
    const jobHelper = new JobHelper(formJobObject);
    console.log("jobhelper", jobHelper);

    const skillstrainerJobsObject = jobHelper.reformatJobObject();
    console.log("skills", skillstrainerJobsObject);
    const jobId = await createNewJob({
      variables: { objects: skillstrainerJobsObject },
    })
      .then((res) => {
        successHandler("Job Created Successfully");
        console.log(res, "response");
        return res?.data?.insert_courses_skillstrainer_jobs.returning[0].id;
      })
      .catch((err) => {
        console.log(err);
        errorHandler("There was an error while creating the job");
        return false;
      });

    if (jobId) {
      jobHelper.setJobId(jobId);

      const jobQualificationsArray = jobHelper.reformatQualifications();
      const jobLanguagesArray = jobHelper.reformatLanguages();
      const jobRequiredSkillsCriteriaArray = jobHelper.reformatSkills();
      const jobLocationsArray = jobHelper.reformatLocations();
      const jobDocumentsArray = jobHelper.reformatDocuments();

      await insertJobQualificationsLanguagesLocationsSkills({
        variables: {
          locations: jobLocationsArray,
          skills: jobRequiredSkillsCriteriaArray,
          languages: jobLanguagesArray,
          qualifications: jobQualificationsArray,
          documents: jobDocumentsArray,
        },
      })
        .then(() => successHandler("Job Created Successfully"))
        .catch((err) => {
          console.log(err);
          errorHandler("There was an error while creating the job");
          deleteJob({
            variables: {
              job_id: jobId,
            },
          });
        });
    }
  }

  return (
    <Container title={"Create Job"} className="ml-6 mr-12 mt-4 mb-8">
      <Form
        formBuilder={JobFormBuilder}
        className="mt-2 grid grid-cols-2 gap-x-6"
        submitButton={{
          text: "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
        }}
        onSubmit={submitJob}
      />
    </Container>
  );
}

export default CreateJob;
