import DataTable from "react-data-table-component";
import { certificatesSchema } from "./DbScchemas";
import { changeNameOnDownload } from "utils/func";
import GenerateCertificateButton from "./CertificateGenerateButton";
import Loader from "assets/animated-images/loader";

const CertificatesTable = ({
  data,
  loading,
  total,
  paginationInfo,
  setPaginationInfo,
}) => {
  const columns = certificatesSchema.concat([
    {
      cell: (row) => (
        <a
          href={row.certificate_pdf_url}
          target={"_blank"}
          className="btn-secondary no-underline hover:text-white hover:bg-opacity-90 cursor-pointer"
        >
          View
        </a>
      ),
      width: "150px",
      ignoreRowClick: true,
      button: true,
    },
    {
      cell: (row) => (
        <a
          onClick={() =>
            changeNameOnDownload(
              row.certificate_pdf_url,
              `${row.user.name.replace(" ", "-")}-${row.user.id}`
            )
          }
          target={"_blank"}
          className="btn-primary no-underline hover:text-white hover:bg-opacity-90 cursor-pointer"
        >
          Download
        </a>
      ),
      width: "150px",
      ignoreRowClick: true,
      button: true,
    },
    {
      cell: (row) =>
        row?.certificate_pdf_url === "" ? (
          <GenerateCertificateButton
            user_id={row?.user?.id}
            course_id={row?.course?.id}
          />
        ) : (
          ""
        ),
      width: "20px",
      ignoreRowClick: true,
      button: true,
    },
  ]);

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  return loading ? (
    <Loader color={"#999"} style={{ height: "100px" }} />
  ) : (
    <DataTable
      columns={columns}
      keyField={"id"}
      data={data ? data.courses_certificates : []}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={total}
      paginationPerPage={20}
      paginationRowsPerPageOptions={[10, 20, 30, 50]}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

export default CertificatesTable;
