import {
  CREATE_COURSE_SECTION,
  INSERT_COURSE_COMPLETION_TOPICS,
} from "GraphQl/Mutations/coursebuildemutation/cousresection";
import { client } from "App";
import { CREATE_COURSE_MUTATION } from "GraphQl/Mutations/Courses";
import { INSERT_COURSE_TAX_RELATION } from "GraphQl/Mutations/coursebuildemutation/CourseTax";
import {
  CREATE_COURSE_PLANS,
  UPDATE_COURSE_SUBSCRIPTION,
} from "GraphQl/Mutations/coursebuildemutation/RazorpayPlans";
import { GET_COURSE_BY_ID } from "GraphQl/Queries/Courses";
import { GET_COURSE_PARTNER_PROJECT_COURSES } from "GraphQl/Queries/CourseBuilder/Certificate";
import { GET_PARTNER_PROJECTS_BY_PARTNER_ID } from "GraphQl/Queries/Partner";
import {
  INSERT_BULKDATA_CERTIFICATES,
  INSERT_CERTIFICATES_DETAILS,
  PREVIEW_CERT_DATA,
  UPDATE_CERT_DATA,
  GET_ALL_CERT_TEMPLATES,
} from "GraphQl/Mutations/coursebuildemutation/CertificateData";

import {
  GET_MODULE_DETAILS_BY_ID,
  COURSE_TOPIC_LISTS,
  GET_COURSE_SECTION,
  GET_COURSE_SECTION_MODULE,
  GET_COURSE_SCORM_PUBLISH_URL,
  CHECK_MOD_TYPE,
  GET_COURSE_TOPICS,
  INSERT_TOPIC_COMPLETION_RESTRICTION,
  GET_COURSE_LESSON_MODULE,
  GET_COURSE_MODULE_DATA,
  GET_MODULE_RESTRICTION,
  GET_SCORM_COMPLETION_CRITERIA_BY_ID,
} from "GraphQl/Queries/CourseBuilder/section";

import {
  UPDATE_QUIZ_MODULE_FORM_DATA,
  UPDATE_MODULE_RESTRICTION,
  CREATE_COURSE_SECTION_TOPICS,
  INSERT_SCORM_COMPLETION_CRITERIA,
  UPDATE_CERTIFICATE_MODULE_FORM_DATA,
  UPDATE_SCORM_MODULE_FORM_DATA,
  UPDATE_SCORM_COMPLETION_CRITERIA,
  DELETE_SCORM_COMPLETION_CRITERIA,
  DELETE_MODULE_COMPLETION_RESTRICTION,
  DELETE_RESOURCE_TOPIC_ATTACHMENTS,
} from "GraphQl/Mutations/coursebuildemutation/CourseModule";

import {
  CHECK_COURSE_COMPLETION_CRITERIA_TOPIC,
  GET_USER_SCORM_PROGRESS,
  GET_USER_QUIZ_PROGRESS,
  GET_USER_COURSE_CERTIFICATE,
} from "GraphQl/Queries/CourseBuilder/Course";

class courseBuilder {
  async getCourseSection(courseid) {
    const result = await client
      .query({
        query: GET_COURSE_SECTION,
        variables: { course_id: courseid, course_id_big: courseid },
      })
      .then((res) => res.data.courses_course_section);

    return result;
  }

  getCourseSectionModule(courseid, sectionid) {
    return client
      .query({
        query: GET_COURSE_SECTION_MODULE,
        variables: {
          course_id: courseid,
          section_id: sectionid,
        },
      })
      .then((res) => {
        return res.data.courses_course_module_mapping;
      });
  }

  async insercoursesection(data) {
    const result = await client
      .mutate({
        mutation: CREATE_COURSE_SECTION,
        variables: { course_id: data.course_id, name: data.title },
      })
      .then(
        ({ data }) => data.insert_courses_course_section?.returning[0].course_id
      )
      .catch(console.error);

    const finalResult = await this.getCourseSection(result);
    return finalResult;
  }

  getScormPublishUrl(id) {
    return client
      .mutate({
        mutation: GET_COURSE_SCORM_PUBLISH_URL,
        variables: { id: id },
      })
      .then((res) => {
        return res.data.courses_course_module;
      })
      .catch(console.error);
  }

  async checkModType(mod_id) {
    const result = await client
      .query({
        query: CHECK_MOD_TYPE,
        variables: {
          id: mod_id,
        },
      })
      .then((res) => res.data.courses_course_module[0].type);

    return result;
  }

  async createCourse(course) {
    console.log("course", course);
    return await client
      .mutate({
        mutation: CREATE_COURSE_MUTATION,
        variables: course,
      })
      .then(({ data }) => data.insert_courses_course_one)
      .catch(console.error);
  }

  async InsertCourseTaxRelation(course) {
    console.log("course", course);
    return await client
      .mutate({
        mutation: INSERT_COURSE_TAX_RELATION,
        variables: course,
      })
      .then(({ data }) => data.insert_courses_course_tax_relation)
      .catch(console.error);
  }

  async CreateCoursePlan(plans) {
    return await client
      .mutate({
        mutation: CREATE_COURSE_PLANS,
        variables: {
          course_id: plans.course_id,
          interval: plans.interval,
          period: plans.period,
          razorpay_plan_creation_date: plans.razorpay_plan_creation_date,
          razorpay_plan_id: plans.razorpay_plan_id,
          subscription_cost: plans.subscription_cost,
          current_plan: true,
        },
      })
      .then(({ data }) => data.insert_courses_course_subscription_plans)
      .catch(console.error);
  }

  async GetCourseTopics(course_id) {
    const result = await client
      .query({
        query: GET_COURSE_TOPICS,
        variables: {
          course_id: course_id,
        },
      })
      .then(({ data }) => data.courses_course_module_mapping);

    return result;
  }

  async InsertScormCompletionCriteria(mapping_id, data) {
    return await client
      .mutate({
        mutation: INSERT_SCORM_COMPLETION_CRITERIA,
        variables: {
          mapping_id: mapping_id,
          completed: data.completed,
          passed: data.passed,
          scorm_version: data.scorm_version,
          min_score: data.min_score,
          enable_min_score: data.enable_min_score,
        },
      })
      .then(
        ({ data }) =>
          data.insert_courses_course_scorm_completion_criteria?.returning[0]
      )
      .catch(console.error);
  }

  async InsertTopicCompletionRestriction(
    course_id,
    mapping_id,
    restriction_ids,
    batch_id
  ) {
    return await client
      .mutate({
        mutation: INSERT_TOPIC_COMPLETION_RESTRICTION,
        variables: {
          course_id: course_id,
          mapping_id: mapping_id,
          restriction_ids: restriction_ids ? restriction_ids : null,
          batch_id: batch_id,
        },
      })
      .then(
        ({ data }) =>
          data.insert_courses_module_completion_resrtriction?.returning[0]
      )
      .catch(console.error);
  }

  // Handles module mapping creation AND scorm metadata creation
  async CreateCourseTopics(formData) {
    const topic = await client
      .mutate({
        mutation: CREATE_COURSE_SECTION_TOPICS,
        variables: formData,
      })
      .then(
        ({ data }) => data.insert_courses_course_module_mapping?.returning[0]
      )
      .catch(console.error);

    //  GET MODULE TYPE
    const modType = await this.checkModType(formData.mod_id);

    if (formData.completion_criteria === 1 && modType == "scorm-package") {
      await this.InsertScormCompletionCriteria(topic.id, formData);
    }

    if (formData.enable_restriction) {
      const result = await this.InsertTopicCompletionRestriction(
        topic.course_id,
        topic.id,
        formData.restriction_ids,
        formData.batch_id
      );

      return result;
    } else {
      return topic;
    }
  }

  async GetCourseLessonModule(course_id, id) {
    const result = await client
      .query({
        query: GET_COURSE_LESSON_MODULE,
        variables: {
          course_id: course_id,
          id: id,
        },
      })
      .then(({ data }) => data.courses_course_module_mapping[0]);

    return result;
  }

  async getCourseById(courseid) {
    const result = await client
      .query({
        query: GET_COURSE_BY_ID,
        variables: { id: courseid },
      })
      .then(({ data }) => data.courses_course[0]);

    return result;
  }

  async getCoursePartner(courseid) {
    const result = await client
      .query({
        query: GET_COURSE_PARTNER_PROJECT_COURSES,
        variables: { course_id: courseid },
      })
      .then(({ data }) => data.courses_partner_project_courses);

    return result;
  }

  async getCourseModuleData(mapping_id) {
    const result = await client
      .query({
        query: GET_COURSE_MODULE_DATA,
        variables: { id: mapping_id },
      })
      .then(({ data }) => data.courses_course_module_mapping);

    return result;
  }

  async getPartnerProject(partner_id) {
    const result = await client
      .query({
        query: GET_PARTNER_PROJECTS_BY_PARTNER_ID,
        variables: { partner_id: partner_id },
      })
      .then(({ data }) => data.courses_partner_projects);

    return result;
  }

  async insertCertificateData(resizedata) {
    console.log("bb", resizedata);
    const result = await client
      .mutate({
        mutation: INSERT_CERTIFICATES_DETAILS,
        variables: resizedata,
      })
      .then(
        ({ data }) =>
          data.insert_courses_course_template_certificates_data?.returning[0]
      )
      .catch(console.error);

    return result;
  }

  async previewCertificateData(mapping_id) {
    const result = await client
      .query({
        query: PREVIEW_CERT_DATA,
        variables: { mapping_id: mapping_id },
      })
      .then(({ data }) => data);

    return result;
  }
  async updateCertData(resize) {
    console.log("service", resize);
    const result = await client.mutate({
      mutation: UPDATE_CERT_DATA,
      variables: resize,
    });
  }
  async handLeBulkData(data) {
    const result = await client
      .mutate({
        mutation: INSERT_BULKDATA_CERTIFICATES,
        variables: data,
      })
      .then(({ data }) => data);

    return result;
  }
  async getAllTemplate() {
    const result = await client
      .query({ query: GET_ALL_CERT_TEMPLATES })
      .then(({ data }) => data.courses_bulk_certificates_details);
    return result;
  }

  async getCourseTopicLists(courseid) {
    const result = await client
      .query({
        query: COURSE_TOPIC_LISTS,
        variables: { course_id: courseid },
      })
      .then((res) => res.data.courses_course_module_mapping);

    return result;
  }

  async InsertCourseComletionTopics(course_id, mapping_id) {
    const result = await client
      .mutate({
        mutation: INSERT_COURSE_COMPLETION_TOPICS,
        variables: {
          course_id: course_id,
          mapping_id: mapping_id,
        },
      })
      .then(({ data }) => data);

    return result;
  }

  async GetModuleDetailsById(mapping_id) {
    const result = await client
      .query({
        query: GET_MODULE_DETAILS_BY_ID,
        variables: { id: mapping_id },
      })
      .then(({ data }) => data.courses_course_module_mapping);

    return result;
  }
  async getModuleRestriction(mapping_id) {
    const result = await client
      .query({
        query: GET_MODULE_RESTRICTION,
        variables: { mapping_id: mapping_id },
      })
      .then(({ data }) =>
        data.courses_module_completion_resrtriction
          ? data.courses_module_completion_resrtriction[0]
          : []
      );

    return result;
  }

  async UpdateQuizForm(formData) {
    const topic = await client
      .mutate({
        mutation: UPDATE_QUIZ_MODULE_FORM_DATA,
        variables: formData,
      })
      .then(({ data }) => data.update_courses_course_module_mapping)
      .catch(console.error);

    if (
      formData.enable_restriction &&
      formData.restriction_ids.length > 0 &&
      formData.restriction_previous_set == true
    ) {
      await this.UpdateModuleRestriction(formData);
    }

    if (
      formData.enable_restriction &&
      formData.restriction_ids.length > 0 &&
      formData.restriction_previous_set == false
    ) {
      await this.InsertTopicCompletionRestriction(
        formData.course_id,
        formData.id,
        formData.restriction_ids
      );
    }

    if (
      formData.enable_restriction == false &&
      formData.restriction_previous_set == true
    ) {
      await this.DeleteModuleRestriction(formData);
    }

    return topic;
  }

  async UpdateScormForm(formData) {
    const topic = await client
      .mutate({
        mutation: UPDATE_SCORM_MODULE_FORM_DATA,
        variables: formData,
      })
      .then(({ data }) => data.update_courses_course_module_mapping)
      .catch(console.error);

    //  GET MODULE TYPE
    const modType = await this.checkModType(formData.mod_id);

    /****Check scorm already set completion critria and module restriction */

    if (
      formData.completion_criteria === 1 &&
      modType == "scorm-package" &&
      formData.completion_criteria_previous_set === true
    ) {
      await this.UpdateScormCompletionCriteria(formData);
    }

    if (
      formData.completion_criteria === 1 &&
      modType == "scorm-package" &&
      formData.completion_criteria_previous_set === false
    ) {
      await this.InsertScormCompletionCriteria(formData.id, formData);
    }

    if (
      formData.completion_criteria !== 1 &&
      modType == "scorm-package" &&
      formData.completion_criteria_previous_set == true
    ) {
      await this.DeleteScormCompletionCritera(formData);
    }

    /********/

    if (
      formData.enable_restriction &&
      // formData.restriction_ids.length > 0 &&
      formData.restriction_previous_set == true
    ) {
      await this.UpdateModuleRestriction(formData);
    }

    if (
      formData.enable_restriction &&
      // formData.restriction_ids.length > 0 &&
      formData.restriction_previous_set == false
    ) {
      await this.InsertTopicCompletionRestriction(
        formData.course_id,
        formData.id,
        formData.restriction_ids,
        formData.batch_id
      );
    }

    if (
      formData.enable_restriction == false &&
      formData.restriction_previous_set == true
    ) {
      await this.DeleteModuleRestriction(formData);
    }

    return topic;
  }

  async UpdateScormCompletionCriteria(formData) {
    const result = await client
      .mutate({
        mutation: UPDATE_SCORM_COMPLETION_CRITERIA,
        variables: {
          mapping_id: formData.id,
          completed: formData.completed,
          passed: formData.passed,
          scorm_version: formData.scorm_version,
          min_score: formData.min_score,
          enable_min_score: formData.enable_min_score,
        },
      })
      .then(({ data }) => data.update_courses_course_scorm_completion_criteria);

    return result;
  }

  async DeleteScormCompletionCritera(formData) {
    const result = await client
      .mutate({
        mutation: DELETE_SCORM_COMPLETION_CRITERIA,
        variables: {
          mapping_id: formData.id,
        },
      })
      .then(({ data }) => data.delete_courses_course_scorm_completion_criteria);

    return result;
  }

  async DeleteModuleRestriction(formData) {
    const result = await client
      .mutate({
        mutation: DELETE_MODULE_COMPLETION_RESTRICTION,
        variables: {
          mapping_id: formData.id,
        },
      })
      .then(({ data }) => data.delete_courses_module_completion_resrtriction);

    return result;
  }

  async UpdateCertificateForm(formData) {
    const topic = await client
      .mutate({
        mutation: UPDATE_CERTIFICATE_MODULE_FORM_DATA,
        variables: formData,
      })
      .then(({ data }) => data.update_courses_course_module_mapping)
      .catch(console.error);

    if (
      formData.enable_restriction &&
      formData.restriction_ids.length > 0 &&
      formData.restriction_previous_set == true
    ) {
      await this.UpdateModuleRestriction(formData);
    }

    if (
      formData.enable_restriction &&
      formData.restriction_ids.length > 0 &&
      formData.restriction_previous_set == false
    ) {
      await this.InsertTopicCompletionRestriction(
        formData.course_id,
        formData.id,
        formData.restriction_ids
      );
    }

    if (
      formData.enable_restriction == false &&
      formData.restriction_previous_set == true
    ) {
      await this.DeleteModuleRestriction(formData);
    }

    return topic;
  }

  async UpdateModuleRestriction(formData) {
    const result = await client
      .mutate({
        mutation: UPDATE_MODULE_RESTRICTION,
        variables: {
          restriction_ids: formData.restriction_ids
            ? formData.restriction_ids
            : null,
          mapping_id: formData.id,
          batch_id: formData.batch_id,
        },
      })
      .then(({ data }) => data);

    return result;
  }

  async GetScormCompletionCriteria(mapping_id) {
    const result = await client
      .query({
        query: GET_SCORM_COMPLETION_CRITERIA_BY_ID,
        variables: { mapping_id: mapping_id },
      })
      .then(({ data }) => data.courses_course_scorm_completion_criteria[0]);

    return result;
  }

  async updateCourseSubscription(course_id) {
    const result = await client
      .mutate({
        mutation: UPDATE_COURSE_SUBSCRIPTION,
        variables: {
          course_id: course_id,
          current_plan: false,
        },
      })
      .then(({ data }) => data);

    return result;
  }

  async copyTopicRestriction(topicid, data) {
    const result = await client
      .mutate({
        mutation: INSERT_TOPIC_COMPLETION_RESTRICTION,
        variables: {
          course_id: data.course_id,
          mapping_id: topicid,
          restriction_ids: data.restriction.restriction_ids,
          batch_id: null,
        },
      })
      .then(({ data }) => data);

    return result;
  }

  async checkCourseCompletionTopic(course_id, mapping_id) {
    const result = await client
      .query({
        query: CHECK_COURSE_COMPLETION_CRITERIA_TOPIC,
        variables: {
          course_id: course_id,
          mapping_id: mapping_id,
        },
      })
      .then(({ data }) => data.courses_course_completion_criteria[0]);

    return result;
  }

  async getUserCourseProgress(data, user_id) {
    if (data.Module.type == "scorm-package") {
      const result = await client
        .query({
          query: GET_USER_SCORM_PROGRESS,
          variables: { user_id: user_id, mapping_id: data.id },
        })
        .then((res) =>
          res.data?.courses_course_scorm_track_data.length > 0
            ? res.data?.courses_course_scorm_track_data.reduce(function (
                prev,
                current
              ) {
                return prev.score_raw > current.score_raw ? prev : current;
              }) //returns object
            : false
        );

      return result;
    }

    if (data.Module.type == "quiz") {
      const result = await client
        .query({
          query: GET_USER_QUIZ_PROGRESS,
          variables: { user_id: user_id, mapping_id: data.id },
        })

        // .then((res) => res.data?.courses_user_course_quiz_attempt);

        .then((res) =>
          res.data?.courses_user_course_quiz_attempt.length > 0
            ? res.data?.courses_user_course_quiz_attempt.reduce(function (
                prev,
                current
              ) {
                return prev.total_score > current.total_score ? prev : current;
              }) //returns object
            : false
        );

      return result;
    }
  }

  async getUserCourseCertificate(course_id, user_id) {
    return client
      .query({
        query: GET_USER_COURSE_CERTIFICATE,
        variables: {
          course_id: course_id,
          user_id: user_id,
        },
      })
      .then(({ data }) => data.courses_certificates[0])
      .catch(console.error);
  }

  async deleteResourceTopicAttachments(ids) {
    const result = await client
      .mutate({
        mutation: DELETE_RESOURCE_TOPIC_ATTACHMENTS,
        variables: {
          id: ids,
        },
      })
      .then(({ data }) => data.delete_courses_course_module_files_attachements);

    return result;
  }
}

const courseBuilderService = new courseBuilder();
export default courseBuilderService;
