import React from "react";
import { useMutation, useLazyQuery } from "@apollo/client";

import { CREATE_COURSE_BATCHES_MUTATION } from "../../../GraphQl/Mutations/Courses";
import { CREATE_COURSE_BATCHES_SLOTS_MUTATION } from "../../../GraphQl/Mutations/BatchesSlots";
import Container from "../../../components/Container/Container";
import { GET_BATCH_DETAILS } from "../../../GraphQl/Queries/BatchesQueries";
import { GET_BATCH_BY_NAME } from "../../../GraphQl/Queries/Instructor";
import { errorHandler, successHandler } from "utils/toast";
import { addTimeToDate, getSlotDates } from "utils/date";
import { Form } from "skillstrainer-resource-library";
import CreateUpdateBatchFormBuilder from "formBuilders/CreateUpdateBatchFormBuilder";
import { daysOptions } from "./BatchData";

function CreateBatches() {
  const createBatch = async (batchData) => {
    await getBatchesByName(batchData.batch_name.trim()).then((res) => {
      if (res.length) {
        errorHandler(
          `A batch with this name already exists: ${res[0].batch_name}`
        );
        throw {};
      }
    });

    batchData["slots_days"] = daysOptions
      .filter((day) =>
        batchData.slot_days.find((selectedDay) => day.value == selectedDay)
      )
      .map((e) => e.shortLabel)
      .join();

    if (parseInt(batchData.max_learners) <= parseInt(batchData.min_learners))
      errorHandler("Max Learners should be greater than Min Learners");
    else {
      // Inserting in DB
      insert_course_batches({
        variables: batchData,
        onCompleted: (response) => {
          successHandler("Batch created successfully!");
          insertBatchSlots(
            response.insert_courses_course_batches_one,
            batchData
          );
        },
      });
    }
  };

  // GQL: Check batch_name uniquenes
  const [runCheckBatchNameQuery] = useLazyQuery(GET_BATCH_BY_NAME, {
    fetchPolicy: "network-only",
  });
  const getBatchesByName = (batchName) =>
    runCheckBatchNameQuery({
      variables: { batch_name: `${batchName}` },
    }).then(({ data }) => data.courses_course_batches);

  // GQL: Insert batches
  const [insert_course_batches] = useMutation(CREATE_COURSE_BATCHES_MUTATION, {
    refetchQueries: [GET_BATCH_DETAILS],
    onError: (errors) => {
      console.log("errors====", errors);
      errorHandler("Error creating batch");
    },
  });

  // GQL: Insert batch slots
  const [insert_course_batches_slots] = useMutation(
    CREATE_COURSE_BATCHES_SLOTS_MUTATION
  );

  // Batch slots insertion

  const insertBatchSlots = (insertedBatch, batch) => {
    // Creating batch slots
    const batchSlots = getSlotDates(
      batch.from_date,
      batch.to_date,
      batch.slot_days.map(Number)
    ).map((date) => {
      //removing current time from date
      let dateWithoutTime = new Date(date);

      dateWithoutTime.setHours(0);
      dateWithoutTime.setMinutes(0);
      dateWithoutTime.setSeconds(0);
      dateWithoutTime.setMilliseconds(0);

      const slot_date = addTimeToDate(dateWithoutTime, batch.slot_start_time);
      const endto_date = addTimeToDate(dateWithoutTime, batch.slot_end_time);

      console.log("slot==", slot_date, "end===", endto_date);

      return { slot_date, endto_date };
    });

    console.log("batchSlots==", batchSlots);

    const obj = {};
    // Inserting batch slots
    batchSlots.map((bs) => {
      obj["slot_date"] = bs.slot_date;
      obj["endto_date"] = bs.endto_date;
      obj["type"] = insertedBatch.type;
      obj["location"] = insertedBatch.location;
      obj["platform"] = insertedBatch.platform;
      obj["slots_days"] = insertedBatch.slots_days;
      obj["instructor_id"] = insertedBatch.instructor_id;
      obj["batch_id"] = insertedBatch.id;

      insert_course_batches_slots({ variables: obj });
    });
  };

  return (
    <Container title={"Create Batches"}>
      <Form
        className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
        submitButton={{
          text: "Create batch",
          className: `btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6`,
        }}
        formBuilder={CreateUpdateBatchFormBuilder}
        onSubmit={createBatch}
      />
    </Container>
  );
}

export default CreateBatches;
