import { gql } from "@apollo/client";

export const GET_ORDERS_DETAILS = gql`
  query GetOrdersDetails($where: courses_user_course_order_bool_exp = {}) {
    courses_user_course_order(order_by: { id: desc }, where: $where) {
      amount
      user_id
      subscription_order
      subscription_id
      rajorpay_signature
      rajorpay_payment_id
      type
      rajorpay_order_id
      order_status
      order_email
      order_create_at
      order_contact
      invoice
      invoice_id
      created_at
      enrollment_id
      customer_id
      currency
      course_id
      id
      cost
      tax
      discount
      ordercourse {
        id
        full_name
        cost
      }
      user {
        id
        name
        email
        mobile_number
      }
    }
  }
`;

export const GET_SUBSCRIPTION_ORDERS = gql`
  query MyQuery($where: courses_user_course_subscription_bool_exp = {}) {
    courses_user_course_subscription(order_by: { id: desc }, where: $where) {
      total_count
      subscription_created_at
      status
      start_at
      source
      remaining_count
      plan_id
      payment_method
      paused_at
      paid_count
      id
      current_start
      current_end
      auth_attempts
      course_id
      charge_at
      created_at
      end_at
      expire_by
      pause_initiated_by
      rajorpay_customer_id
      razorpay_payment_id
      razorpay_signature
      subscription_id
      arraysubscriptionorder {
        amount
        customer_id
        id
        order_contact
        order_create_at
        order_email
        order_status
        subscription_id
        subscription_order
        user {
          email
          name
          mobile_number
        }
        rajorpay_order_id
      }
      course_relation {
        full_name
      }
      plans {
        interval
        period
        subscription_cost
      }
    }
  }
`;
