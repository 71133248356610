/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import ScrollIntoView from "react-scroll-into-view";
import {
  GET_ALL_ADMIN_USERS,
  GET_TOTAL_ADMIN_USERS,
} from "../../../GraphQl/Queries/AdminUsers";
import {
  DELETE_PARTNER_ACCESS,
  UPSERT_PARTNER_ACCESS,
} from "GraphQl/Mutations/AdminUsers";
import AdminUsersTable from "./components/AdminUsersTable";
import { api } from "../../../api/User";
import { errorHandler, successHandler } from "utils/toast";
import { Form } from "skillstrainer-resource-library";
import TeamMemberFormBuilder from "formBuilders/TeamMemberFormBuilder";
import Container from "components/Container/Container";

import { arrayDelta } from "utils/func";
import Axios from "../../../api/Api";
import Toast from "wrappers/Toast";
function ManageUsers() {
  const allUsers = useQuery(GET_ALL_ADMIN_USERS);
  const totalUsers = useQuery(GET_TOTAL_ADMIN_USERS);

  /*
   *
   *
   * Handle user submission
   *
   *
   */

  const createUser = async (user) => {
    try {
      let loader = Toast.load("Please wait...");
      const email = user.email;
      const mobile_number = user.mobile_number;
      const traineeSearchResponse = await Axios()
        .post("/search_trainees", {
          email,
          mobile_number,
        })
        .then(({ data }) => data);
      console.log("traineeSearchResponse==", traineeSearchResponse);

      const { data: duplicateTrainees } = traineeSearchResponse;
      Toast.endLoader(loader);

      if (duplicateTrainees?.length) {
        const forceCreate = await Toast.confirm(
          <DuplicateTraineesMessage duplicateTrainees={duplicateTrainees} />
        );
        console.log("forceCreate==", forceCreate);
        if (!forceCreate) return;
      }

      const data = await api("create_admin_user", user);

      console.log("data", data);
      if (data?.success === false) {
        return errorHandler(data?.message);
      }

      console.log(JSON.stringify(data, null, 2));

      await handlePartnerAccess(
        data?.response?.data?.insert_courses_admin_users_one?.id,
        user.partnerAccess
      ).then(() => {
        setReset(!reset);
      });

      successHandler("New User created successfully!");
      await allUsers.refetch();
    } catch (err) {
      console.log(err);
      errorHandler("There was an Error while creating new member");
    }
  };

  const updateUser = async (user) => {
    try {
      const update_user = {
        id: isUpdateMember.id,
        idp_user_id: isUpdateMember.idp_user_id,
        idp_user_name: isUpdateMember.idp_user_name,
        ...user,
      };

      await api("update_admin_user", update_user);

      await handlePartnerAccess(update_user.id, user.partnerAccess).then(() => {
        successHandler("User updated successfully!");
        setIsUpdateMember({});
      });

      await allUsers.refetch();
    } catch (err) {
      console.log(err);
      errorHandler("There was an error while updating member");
    }
  };

  /*
   *
   *
   * Handle partner submission
   *
   *
   */

  const [runUpsertPartnerAccessMutation] = useMutation(UPSERT_PARTNER_ACCESS);
  const [runDeletePartnerAccessMutation] = useMutation(DELETE_PARTNER_ACCESS);

  const handlePartnerAccess = async (userId, partnerAccess) => {
    if (userId && partnerAccess) {
      const selectedUser =
        allUsers.data.courses_admin_users.find((e) => e.id === userId) || {};

      const { created, deleted, persistant } = arrayDelta(
        selectedUser.partner_access || [],
        partnerAccess.reduce((acc, cur) => {
          cur.role_ids.forEach((r) => {
            acc.push({
              partner_id: cur.partner_id,
              role_id: r,
              admin_user_id: userId,
            });
          });

          return acc;
        }, []),
        ["partner_id", "role_id", "user_id"]
      );

      const upsertionResponse = await runUpsertPartnerAccessMutation({
        variables: { partnerAccessObjects: created.concat(persistant) },
      }).then(
        ({ data }) =>
          data.insert_courses_admin_user_partner_access.affected_rows
      );

      const deletionResponse = await runDeletePartnerAccessMutation({
        variables: { partnerAccessIds: deleted.map((r) => r.id) },
      }).then(
        ({ data }) =>
          data.delete_courses_admin_user_partner_access.affected_rows
      );

      console.log({ upsertionResponse, deletionResponse });
    }
  };

  /*
   *
   *
   * Handle form state
   *
   *
   */

  const [isUpdateMember, setIsUpdateMember] = useState({});
  const [reset, setReset] = useState(false);

  const formRef = useRef({});

  return (
    <Container>
      <h2 id="createUser" className="text-3xl font-bold">
        Manage Partner Team Member
      </h2>

      <div className="w-full mt-2">
        {isUpdateMember?.id ? <h5>Update User</h5> : <h5>Create User</h5>}
        <Form
          key={isUpdateMember?.id || reset}
          formBuilder={TeamMemberFormBuilder}
          className="mt-4 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className:
              "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6 py-2 mb-4",
          }}
          hideSubmit={true}
          ref={(e) => (formRef.current = e)}
          onSubmit={(user) =>
            console.log(user) ||
            (isUpdateMember?.id ? updateUser(user) : createUser(user))
          }
          initValues={isUpdateMember}
        />
        <button
          className="btn-primary mt-4"
          onClick={() => formRef.current.submit()}
        >
          Submit
        </button>
      </div>

      <h5 className="mt-5">Admin user list</h5>
      <ScrollIntoView selector="#createUser">
        <AdminUsersTable
          allUsers={allUsers}
          totalUsers={totalUsers}
          updateAdminUser={(userPayload) => {
            console.log(userPayload);
            setIsUpdateMember({
              ...userPayload,
              partnerAccess: Object.values(
                userPayload.partner_access.reduce((acc, cur) => {
                  if (!acc[cur.partner_id])
                    acc[cur.partner_id] = {
                      partner_id: cur.partner_id,
                      role_ids: [],
                    };
                  acc[cur.partner_id].role_ids.push(cur.role_id);
                  return acc;
                }, {})
              ),
            });
          }}
        />
      </ScrollIntoView>
    </Container>
  );
}

export default ManageUsers;

const DuplicateTraineesMessage = ({ duplicateTrainees }) => (
  <div>
    <p>We have found trainers with the same email / phone number:</p>
    <ul>
      {duplicateTrainees.map((dt) => (
        <li key={dt.username}>
          <span className="font-semibold">{dt.name.familyName}</span> (
          {dt.username})
        </li>
      ))}
    </ul>

    <p>Force create a new user?</p>
  </div>
);
