import React from "react";
import DataTable from "react-data-table-component";

function CourseCategoriesTable({
  updateCourseCategories,
  courseCategories,
  className,
}) {
  const handleButtonClick = (e, row) => {
    updateCourseCategories(row);
  };

  const columns = [
    {
      name: "Name",
      selector: (row, index) => `${row.name}`,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row, index) => `${row.description}`,
      sortable: true,
    },
    {
      name: "Visible",
      selector: (row, index) => `${row.visible}`,
      sortable: true,
    },
    {
      name: "Image URL",
      selector: (row, index) => `${row.image_url || ""}`,
      sortable: true,
    },
    {
      cell: (row) => (
        <button
          className="bg-gray-700 p-2 text-base text-white"
          onClick={(e) => handleButtonClick(e, row)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className={className}>
      <DataTable
        columns={columns}
        pagination
        data={courseCategories.data?.courses_course_categories}
        progressPending={courseCategories.loading}
        selectableRows={false}
        selectableRowsHighlight={false}
      />
    </div>
  );
}

export default CourseCategoriesTable;
