export const GET_PARTNER_WEBSITE_CONFIG_BY_PARTNER_ID_SUB = `
  subscription ($partnerId: bigint) {
    courses_partner_website_configuration (
      where: {
        partner_id: { _eq: $partnerId }
      }
    ) {
      id
      status
      website_url
      last_published_at
      edited_at
    }
  }
`;
