import { useState, useEffect } from "react";
import { CATEGORY_MOODLE_CONFIG_QUERY } from "GraphQl/Queries/Courses";
import { useQuery } from "@apollo/client";
import partnerSvc from "services/partner";
import { fileSchema } from "utils/schemas";
import { multiSelectSchema } from "utils/schemas";

import * as yup from "yup";

function CreateUpdateFormBuilder(values) {
  const { data: courseCategoryData } = useQuery(CATEGORY_MOODLE_CONFIG_QUERY);
  const [schema, setSchema] = useState({});
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    partnerSvc.getAllPartners().then(setPartners);
  }, []);

  useEffect(() => {
    const schema = {
      full_name: {
        type: "text",
        label: "Full Name",
        required: true,
        schema: yup.string(),
      },
      course_category_id: {
        type: "select",
        label: "Course Category",
        required: true,
        options: courseCategoryData?.courses_course_categories.map(
          (course) => ({
            value: course.id,
            label: course.name,
          })
        ),
      },
      description: {
        type: "rich-text",
        label: "Description",
        schema: yup.string(),
      },
      short_name: {
        type: "text",
        label: "Short Name",
        schema: yup.string(),
      },
      image_url: {
        type: "file",
        label: "Image URL",
        schema: fileSchema,
      },
      video_url: {
        type: "text",
        label: "Video (Optional)",
        schema: yup.string(),
      },
      certificate_image_url: {
        type: "file",
        label: "Certificate Image",
        schema: fileSchema,
      },
      is_moodle_course: {
        type: "boolean",
        label: "is Moodle Course",
      },
      moodle_course_url: {
        type: "hidden",
      },
      moodle_config_id: {
        type: "hidden",
      },

      moodle_course_id: {
        type: "text",
        label: "Moodle Course Id",
        schema: yup.string(),
      },
      discontinued_from_moodle_at: {
        type: "date",
        label: "Discontinued from Moodle at",
      },
      start_date: {
        type: "date",
        label: "Start Date",
        required: true,
      },
      end_date: {
        type: "date",
        label: "End Date",
        required: true,
      },
      cost: {
        type: "text",
        label: "Price",
        schema: yup.number(),
      },
      nsqf_level: {
        type: "text",
        label: "NSQF Level (Optional)",
      },
      discount: {
        type: "number",
        label: "Discount (Optional)",
        schema: yup.number(),
      },
      duration: {
        type: "text",
        label: "Duration (Optional)",
      },
      course_type: {
        type: "select",
        label: "Course Type",
        required: true,
        options: [
          { label: "Digital Content (Self Paced)", value: "1" },
          { value: "2", label: "Live Online Classes + Digital Content" },
          {
            value: "3",
            label: "Physical Classes+Live Online Classes+Digital Content",
          },
          {
            value: "4",
            label: "External course",
          },
        ],
      },
      partners: {
        type: "multi-select",
        label: "Certification Partners",
        options: partners.map((p) => ({
          value: p.id,
          label: p.name,
        })),
        schema: multiSelectSchema,
      },

      publish: {
        type: "boolean",
        label: "Publish to dashboard",
      },
      is_subscription: {
        type: "boolean",
        label: "is Installement",
      },
      subscription_period: {
        type: "select",
        label: "Period",
        required: true,
        options: [
          { value: "daily", label: "Daily" },
          { value: "weekly", label: "Weekly" },
          { value: "monthly", label: "Monthly" },
          { value: "yearly", label: "Yearly" },
        ],
      },
      // is_taxable: {
      //   type: "boolean",
      //   label: "is Taxable",
      // },
      // tax_name: {
      //   type: "select",
      //   label: "Select Tax",
      //   options: courseTax?.courses_course_tax.map((course) => ({
      //     label: course.tax_name,
      //   })),
      // },

      // if  is_installment is true
      subscription_interval: {
        label: "No. of installement",
        type: "select",
        required: true,
        schema: yup.number(),
        options: [
          { value: "1", label: "1 Months" },
          { value: "2", label: "2 Months" },
          { value: "3", label: "3 Months" },
          { value: "4", label: "4 Months" },
          { value: "5", label: "5 Months" },
          { value: "6", label: "6 Months" },
          { value: "7", label: "7 Months" },
          { value: "8", label: "8 Months" },
          { value: "9", label: "9 Months" },
          { value: "10", label: "10 Months" },
          { value: "11", label: "11 Months" },
          { value: "12", label: "12 Months" },
        ],
      },

      subscription_cost: {
        type: "number",
        label: "Installment Price",
        required: true,
        schema: yup.number(),
      },
      course_instructions: {
        type: "rich-text",
        label: "Course Instructions",
      },
      has_certificate: {
        type: "boolean",
        label: "Has Certificate",
      },
    };

    if (values.is_subscription === false) {
      schema.subscription_period = { type: "hidden" };
      schema.subscription_interval = { type: "hidden" };
      schema.subscription_cost = { type: "hidden" };
    }

    if (values.is_moodle_course === true) {
      schema.image_url = { type: "text", label: "Image URL" };
      schema.moodle_course_url = {
        type: "text",
        label: "Moodle Course URL",
        schema: yup.string(),
        required: true,
      };
      schema.moodle_course_id = {
        ...schema.moodle_course_id,
        required: true,
      };
      schema.moodle_config_id = {
        type: "select",
        label: "Moodle Server",
        schema: yup.string(),
        required: true,
        options: courseCategoryData?.courses_moodle_config.map((course) => ({
          value: course.id,
          label: course.moodle_sp_name,
        })),
      };
    }

    setSchema(schema);
  }, [values, courseCategoryData]);

  return schema;
}
export default CreateUpdateFormBuilder;
