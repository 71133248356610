import { useQuery } from "@apollo/client";
import api from "api/Api";
import Select from "components/InputGroup/Select";
import { GET_PARTNER_COUPONS } from "GraphQl/Queries/CourseCoupons";
import { GET_PARTNERS_WITH_FILTER } from "GraphQl/Queries/Partner";
import { useState, useMemo } from "react";
import { useRecoilState } from "recoil";
import { currentModuleAccessRecoilVar } from "State/Navigation";
import { error } from "utils/errors";
import usePromise from "utils/hooks/usePromise";
import MultiSelectDropdown from "components/InputGroup/MultiselectDropdown";
import { errorHandler, successHandler } from "utils/toast";
import { gqlUserRecoilVar } from "State/User";
import { wireEventValue } from "utils/func";

// User management pages common imports
import Container from "components/Container/Container";
import { Modal } from "skillstrainer-resource-library";
import TraineeList from "components/TraineeList/TraineeList";

export default function HandleCouponUser() {
  const [user] = useRecoilState(gqlUserRecoilVar);
  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);
  const accessiblePartners = useMemo(
    () =>
      currentModuleAccess
        ? Object.keys(currentModuleAccess.allowedPartners).map(Number)
        : [],
    [currentModuleAccess]
  );

  const [isTraineeListOpen, setIsTraineeListOpen] = useState();
  const [users, setSelectedUsers] = useState([]);

  const [selectedPartner, setSelectedPartner] = useState();
  const [selectedCoupons, setSelectedCoupons] = useState([]);

  /*
   *
   *
   * Fetching data
   *
   *
   */

  const { data: partnersRes } = useQuery(GET_PARTNERS_WITH_FILTER, {
    variables: { filterClause: {} },
  });
  const partners = useMemo(
    () =>
      partnersRes?.courses_partner.filter(
        (p) => user?.is_skillstrainer_admin || accessiblePartners.includes(p.id)
      ),
    [partnersRes, user, accessiblePartners]
  );

  const { data: couponsRes } = useQuery(GET_PARTNER_COUPONS, {
    variables: { partner_ids: selectedPartner?.id },
  });
  const coupons = couponsRes?.courses_st_coupons_configuration;

  /*
   *
   *
   * Submit function
   *
   *
   */

  const { run: applyCoupons, loading: applyingCoupons } =
    usePromise(async () => {
      try {
        if (!users.length) throw error({}, "No users are selected");
        if (!selectedCoupons.length) throw error({}, "No coupon is selected");

        await api()
          .post("/apply_coupon", {
            user_id: users,
            coupon_code: selectedCoupons,
          })
          .then(({ data: res }) => {
            if (res.success) {
              return "Coupon applied to users successfully";
            }
          })
          .catch((err) => {
            err = err.response.data.message;
            if (err.type === "invalid_coupons")
              throw error(err, "Invalid coupon code");
            else if (err.type === "coupon_already_applied")
              throw error(err, "Coupon already applied");
            else
              throw error(
                err,
                typeof err === "string" ? err : "An error occurred"
              );
          });

        successHandler("Applied coupons successfully!");
      } catch (err) {
        console.log(err);
        errorHandler(err.message);
      }
    }, [users, selectedCoupons]);

  return (
    <Container title="Apply Coupon">
      <div className="flex flex-col gap-y-3 mt-4">
        <div className="mt-4 flex items-center gap-3">
          <button
            onClick={() => setIsTraineeListOpen(true)}
            className="btn-primary"
          >
            Choose trainees
          </button>
          <span>
            {users.length
              ? `${users.length} users selected`
              : "No users selected"}
          </span>
        </div>
        <Modal
          isOpen={isTraineeListOpen}
          onClose={() => setIsTraineeListOpen()}
        >
          <div className="px-4">
            <TraineeList
              selectable
              onComplete={(userIds) => {
                setSelectedUsers([...userIds]);
                setIsTraineeListOpen();
              }}
            />
          </div>
        </Modal>

        <Select
          placeholder="Choose a partner"
          options={partners?.map((p) => ({
            value: p.id,
            label: p.name,
            id: p.id,
          }))}
          onChange={wireEventValue((partnerId) =>
            setSelectedPartner({ id: partnerId })
          )}
          value={selectedPartner?.id}
        />
        <MultiSelectDropdown
          placeholder="Choose coupons"
          options={coupons?.map((c) => ({ value: c.code, label: c.code }))}
          setSelectedValue={setSelectedCoupons}
          selectedValue={selectedCoupons}
        />

        <button
          onClick={applyCoupons}
          className="btn-primary"
          disabled={applyingCoupons}
        >
          {applyingCoupons ? "Applying coupons..." : "Apply coupons"}
        </button>
      </div>
    </Container>
  );
}
