import { homeModule } from "Contexts/NavigationContext";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  currentModulePathRecoilVar,
  currentModuleRecoilVar,
} from "State/Navigation";
import { modulesRecoilVar } from "State/Resources";
import { gqlUserRecoilVar, userAccessibleModulesRecoilVar } from "State/User";
import { getModuleTreeFromModuleList } from "utils/resources";

const Sidebar = () => {
  const [user] = useRecoilState(gqlUserRecoilVar);
  const [allModules] = useRecoilState(modulesRecoilVar);
  const [userAccessibleModules] = useRecoilState(
    userAccessibleModulesRecoilVar
  );

  const sideBarTree = useMemo(() => {
    if (user && allModules) {
      if (user.is_skillstrainer_admin)
        return getModuleTreeFromModuleList(
          allModules.map((m) => m.id),
          _.cloneDeep(allModules)
        );
      else if (userAccessibleModules)
        return getModuleTreeFromModuleList(
          Object.keys(userAccessibleModules).map(Number),
          _.cloneDeep(allModules)
        );
    }
  }, [user, allModules, userAccessibleModules]);

  return sideBarTree ? (
    <div className="w-full bg-gray-800 text-white h-full pt-3 pl-3 overflow-y-auto pb-5">
      <SidebarItem key={homeModule.id} item={homeModule} />
      {orderedList(sideBarTree).map((rootItem) => {
        return <SidebarItem key={rootItem.id} item={rootItem} />;
      })}
    </div>
  ) : null;
};

export const SidebarItem = ({ item: moduleItem, depth = 0, prefix = "" }) => {
  const hasChildren = moduleItem.submodules?.length;

  const [currentModule] = useRecoilState(currentModuleRecoilVar);
  const [currentModulePath] = useRecoilState(currentModulePathRecoilVar);

  const isSelected = currentModule && currentModule.id === moduleItem.id;
  const isChildSelected = currentModulePath.includes(moduleItem.id);

  const [isExpanded, setIsExpanded] = useState();

  useEffect(() => {
    if (isChildSelected && !isExpanded) setIsExpanded(true);
  }, [isChildSelected, isExpanded]);

  if (moduleItem.hide) return null;

  return (
    <div className={`w-full box-border mb-1`}>
      <div style={{ marginLeft: depth * 2 + "rem" }}>
        {/* Header */}
        <div
          className={`flex items-center pl-2 rounded-r-none rounded-l-lg ${
            isSelected ? "bg-orange text-white" : ""
          } ${isChildSelected ? "text-white" : ""}`}
        >
          {hasChildren ? (
            <button
              className={`p-1 pb-0 mr-1 rounded-2xl`}
              onClick={() => setIsExpanded(!isExpanded)}
              disabled={isChildSelected}
            >
              <box-icon
                name={isExpanded ? "chevron-down" : "chevron-right"}
                color="#fff"
              />
            </button>
          ) : (
            <button className="p-1 pb-0 mr-1">
              <box-icon
                name="disc"
                style={{ height: "15px" }}
                color={isSelected ? "#fff" : "rgba(255, 255, 255, 0.2)"}
              />
            </button>
          )}

          {!hasChildren ? (
            <Link className="w-full" to={prefix + "/" + moduleItem.slug}>
              <button
                className={`w-full text-left text-gray-400 pl-0 pr-3 py-2 font-semibold ${
                  isSelected ? "text-white" : "hover:text-white"
                }`}
              >
                {moduleItem.name}
              </button>
            </Link>
          ) : (
            <button
              className={`w-full text-left text-gray-400 pl-0 pr-3 py-2 font-semibold ${
                isSelected ? "text-white" : "hover:text-white"
              }`}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {moduleItem.name}
            </button>
          )}
        </div>

        {/* Sublist */}
        {moduleItem.submodules &&
          moduleItem.submodules.length > 0 &&
          isExpanded && (
            <div className="mt-1">
              {orderedList(moduleItem.submodules).map((item) => (
                <SidebarItem
                  key={item.id}
                  item={item}
                  depth={depth + 1}
                  prefix={prefix + "/" + moduleItem.slug}
                />
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

const n = Number.MAX_VALUE;
const orderedList = (list) =>
  list.sort((a, b) => (a.order || n) - (b.order || n));

export default Sidebar;
