import { gql } from "@apollo/client";

export const GET_ALL_COUPONS = gql`
  query getAllCoupons {
    courses_st_coupons_configuration(order_by: { created_at: desc }) {
      user_limit
      start_date
      description
      partner_id
      num_of_users
      minimum_cart_amount
      max_usage_per_user
      end_date
      id
      discount
      code
      applicable_for_all_users
      active
      abs_discount
      type
      criteria {
        name
        coupon_id
        apply_to_skillstrainer
        apply_to_all_users
        apply_to_all_courses
        id
        instructor_id
        partner_id
        courses {
          id
          course_id
          criteria_id
          courses {
            id
            full_name
          }
        }
      }
      st_user_coupons {
        user_id
      }
      coupon_batch_relations {
        id
        batch_id
        coupon_id
      }
    }
  }
`;

export const GET_PARTNER_COUPONS = gql`
  query ($partner_ids: [bigint!]!) {
    courses_st_coupons_configuration(
      where: { partner_id: { _in: $partner_ids } }
    ) {
      user_limit
      start_date
      partner_id
      description
      num_of_users
      minimum_cart_amount
      max_usage_per_user
      end_date
      id
      discount
      code
      applicable_for_all_users
      active
      abs_discount
      criteria {
        name
        coupon_id
        apply_to_skillstrainer
        apply_to_all_users
        apply_to_all_courses
        id
        instructor_id
        partner_id
        courses {
          id
          course_id
          criteria_id
          courses {
            id
            full_name
          }
        }
      }
      st_user_coupons {
        user_id
      }
      coupon_batch_relations {
        id
        batch_id
        coupon_id
      }
    }
  }
`;

export const GET_BATCHES_WITH_FILTER = gql`
  query getBatchesWithFilter(
    $courseBatchesCriteria: courses_course_batches_bool_exp!
  ) {
    courses_course_batches(where: $courseBatchesCriteria) {
      id
      batch_name
      partner_id
      course_id
      course {
        id
        full_name
      }
    }
  }
`;

export const GET_COUPON_CODE_BY_ID = gql`
  query getCouponCodeById($id: uuid!) {
    courses_st_coupons_configuration_by_pk(id: $id) {
      user_limit
      start_date
      partner_id
      project_id
      num_of_users
      minimum_cart_amount
      max_usage_per_user
      end_date
      id
      discount
      code
      applicable_for_all_users
      active
      type
      abs_discount
      criteria {
        name
        coupon_id
        apply_to_skillstrainer
        apply_to_all_users
        apply_to_all_courses
        id
        instructor_id
        partner_id
        courses {
          id
          course_id
          criteria_id
          courses {
            id
            full_name
          }
        }
      }
      coupon_batch_relations {
        id
        batch_id
        coupon_id
      }
    }
  }
`;

export const CREATE_NEW_COUPON_CODES = gql`
  mutation insertCouponCode(
    $object: courses_st_coupons_configuration_insert_input = {}
  ) {
    insert_courses_st_coupons_configuration_one(object: $object) {
      id
      code
    }
  }
`;

export const UPDATE_NEW_COUPON_COES = gql`
  mutation MyMutation(
    $id: uuid_comparison_exp = {}
    $_set: courses_st_coupons_configuration_set_input = {}
  ) {
    update_courses_st_coupons_configuration(where: { id: $id }, _set: $_set) {
      affected_rows
      returning {
        id
        code
      }
    }
  }
`;

export const GET_TOTAL_COUPON_CODES = gql`
  query GetTotalCouponCodes {
    courses_st_coupons_configuration_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TOTAL_PARTNER_COUPON_CODES = gql`
  query GetTotalPartnerCouponCodes($partner_id: [bigint!]!) {
    courses_st_coupons_configuration_aggregate(
      where: { partner_id: { _in: $partner_id } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
