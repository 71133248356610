import { useState, useEffect } from "react";
import * as yup from "yup";
import { GET_COURSES } from "../GraphQl/Queries/Projects";
import { useQuery } from "@apollo/client";
import { multiSelectSchema } from "utils/schemas";

function ASDMStatsFormBuilder(values) {
  const { data: allCourses } = useQuery(GET_COURSES);
  console.log(allCourses);

  const [schema, setSchema] = useState();

  useEffect(() => {
    const schema = {
      start_date: {
        label: "Start Date",
        type: "date",
        schema: yup.string(),
      },
      end_date: {
        label: "End Date",
        type: "date",
        schema: yup.string(),
      },
      course_id: {
        type: "multi-select",
        required: true,
        label: "Course Name",
        options: allCourses?.courses_course.map((course) => ({
          value: course.id,
          label: course.full_name,
        })),
        schema: multiSelectSchema,
      },
    };
    setSchema(schema);
  }, [values]);

  return schema;
}

export default ASDMStatsFormBuilder;
