import { gql } from "@apollo/client";

export const CREATE_USER_MUTATION = gql`
  mutation createUser($user: courses_users_insert_input!) {
    insert_courses_users_one(object: $user) {
      id
      name
      idp_user_id
    }
  }
`;
