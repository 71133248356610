import React from "react";

const TextArea = ({ label, className, value, style, ...inputProps }) => {
  return (
    <div className={`mx-4 ${className}`} style={style}>
      <label className="block text-sm font-medium text-japanese_indigo">
        {label}
        {inputProps.requiredField == "yes" ? (
          <span className="text-red-600 text-lg	">*</span>
        ) : (
          ""
        )}
      </label>
      <div className="mt-1">
        <textarea
          value={value}
          className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full text-sm  ${
            inputProps.error == "yes" ? "border-red-600" : "border-gray-300"
          } rounded-md p-3`}
          {...inputProps}
        />
      </div>
      {inputProps.error == "yes" ? (
        <span className="border-red-600 text-md text-red-600">
          This field can't be blank
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default TextArea;
