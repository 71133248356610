export default function arrayToCsv(data, headerTransformFunction = undefined) {
  if (!Array.isArray(data) || data.length === 0) {
    return "";
  }

  // Find the object with the most keys
  const maxKeysObject = data.reduce((acc, curr) => {
    return Object.keys(curr).length > Object.keys(acc).length ? curr : acc;
  }, {});

  // Extract headers from the object with the most keys
  const headers = Object.keys(maxKeysObject);

  let csvHeader;

  // Create the CSV header row
  csvHeader = headerTransformFunction
    ? headers.map((header) => headerTransformFunction(header)).join(",")
    : headers.join(",");

  // Create an array to hold the CSV rows
  const csvRows = [];

  // Iterate through each object in the array
  data.forEach((item) => {
    // Create an array of values for the current object
    const values = headers.map((header) => {
      // Ensure the value is properly escaped and quoted
      const escapedValue = String(item[header] || "").replace(/"/g, '""');
      return `"${escapedValue}"`;
    });

    // Join the values to create a CSV row
    const csvRow = values.join(",");

    // Add the CSV row to the array
    csvRows.push(csvRow);
  });

  // Join all rows with line breaks to create the final CSV string
  const csvString = [csvHeader, ...csvRows].join("\n");

  return csvString;
}
