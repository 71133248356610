import React from "react";

const Select = ({
  label,
  options = [],
  valueField = "value",
  displayField = "label",
  className,
  style,
  ...selectProps
}) => {
  return (
    <div className={className} style={style}>
      <label className="block text-sm font-medium text-japanese_indigo">
        {label}
        {selectProps.requiredField == "yes" ? (
          <span className="text-red-600 text-lg	">*</span>
        ) : (
          ""
        )}
      </label>
      <select
        {...selectProps}
        className={`mt-1 block w-full py-2 px-7 ${
          selectProps.error == "yes" ? "border-red-600" : "border-gray-300"
        } bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm`}
      >
        <option value="-1">Select {label}</option>
        {options.map((option) => (
          <option
            className="p-2 font-medium"
            key={option[valueField]}
            value={option[valueField]}
          >
            {option[displayField]}
          </option>
        ))}
      </select>
      {selectProps.error == "yes" ? (
        <span className="border-red-600 text-md text-red-600">
          Please select option
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Select;
