import CertificateTemplatePreviewModal from "Pages/Dashboard/Courses/CertificateTemplatePreviewModal";
import React, { useState } from "react";

const createPlugin = (config) => ({ ...config });

export const CertificateTemplatePreview = ({
  name,
  className,
  template_data,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const handleClose = () => {
    setShowPreviewModal(false);
  };
  return (
    <div
      key={name}
      className={`col-span-6 sm:col-span-3 space-y-4 ${className}`}
    >
      <button
        type="button"
        className="btn-secondary mt-3"
        onClick={() => setShowPreviewModal(true)}
        name={name}
      >
        Preview
      </button>
      <CertificateTemplatePreviewModal
        show={showPreviewModal}
        handleClose={handleClose}
        templateData={
          template_data ||
          "<html><body><h1>Select a Template To Preview</h1></body></html>"
        }
      />
    </div>
  );
};

export default createPlugin({
  Component: CertificateTemplatePreview,
});
