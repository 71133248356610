import Container from "components/Container/Container";
import TraineeList from "components/TraineeList/TraineeList";

function ManageUsers() {
  return (
    <Container>
      <h2 className="text-3xl font-bold">List Trainees</h2>
      <TraineeList showCourses showCoupons />
    </Container>
  );
}

export default ManageUsers;
