import React, { useEffect, useState, Fragment } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router";
import queryString from "query-string";

import {
  CREATE_COURSE_SECTION,
  UPDATE_COURSE_SECTION_ORDER,
  UPDATE_COURSE_LESSON,
  UPDATE_COURSE_TOPIC,
  UPDATE_COURSE_TOPIC_ORDER,
  UPDATE_LESSON_TITLE,
} from "GraphQl/Mutations/coursebuildemutation/cousresection";
import { GET_COURSE_SECTION } from "GraphQl/Queries/CourseBuilder/section";

import Accordions from "../components/Accordions";

import courseBuilderService from "services/coursebuild/course";
import { FaRegSun, FaTrashAlt } from "react-icons/fa";
import { AiFillEdit, AiOutlineDownload } from "react-icons/ai";

import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { errorHandler, successHandler } from "utils/toast";
import {
  DELETE_COURSE_MODULE_MAPPING,
  DELETE_COURSE_SECTION,
  CREATE_COURSE_SECTION_TOPICS,
  CREATE_RESOURCES,
  UPDATE_SECTION_MODULE,
  DELETE_COURSE_MODULE_RESOURCES_BY_ID,
  UPDATE_COURSE_MODULE_RESOURCES,
  UPDATE_LESSON_TOPIC_NAME,
  INSERT_COURSE_RESOURCES,
} from "GraphQl/Mutations/coursebuildemutation/CourseModule";
import CertificateTemplateSelectorModal from "./CertificateTemplateSelectorModal";
import { LINK_QUESTION_TO_COURSE } from "GraphQl/Mutations/coursebuildemutation/Questions";
import { reorder } from "utils/helpers";
import { DragAndDrop, Drag, Drop } from "components/drag-and-drop";
import Modal from "react-bootstrap/Modal";
import { uploadResourceLargeFile, getFile } from "api/UploadFile";
import { error } from "utils/error";
import CourseResources from "./CourseResources";
import SampleCertificateGeneratorModal from "./SampleCertificateGeneratorModal";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function ViewCoursePage() {
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [showSampleCertificateModal, setShowSampleCertificateModal] =
    useState(false);
  const [certificateTemplateData, setCertificateTemplateData] = useState("");
  const [courseName, setCourseName] = useState("");
  const [resourceModal, setResourceModal] = useState(false);
  const [resourceFile, setResourceFile] = useState(null);
  const [activeMapping, setActiveMapping] = useState(null);
  const [resource, setResource] = useState({});
  const [activeResources, setActiveResources] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileLoading, setFileLoading] = useState({
    resourceId: null,
    status: false,
  });

  const [title, setTitle] = useState({
    title: "",
    course_id: null,
  });

  const location = useLocation();
  const params = queryString.parse(location.search);

  // Get Lessons
  const { data: lessons, refetch } = useQuery(GET_COURSE_SECTION, {
    variables: { course_id: params.id, course_id_big: params.id },
    fetchPolicy: "network-only",
  });

  //Link quiz topic questions
  const [link_questions_to_course] = useMutation(LINK_QUESTION_TO_COURSE);

  //Add lessons
  const [addLesson] = useMutation(CREATE_COURSE_SECTION, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],
    onCompleted: (data) => {
      successHandler("Lesson added successfully!");
      setTitle({
        title: "",
        course_id: null,
      });
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Lesson Copy Data
  const [lessonTopicCopy] = useMutation(CREATE_COURSE_SECTION_TOPICS, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],

    onError: () => errorHandler("An error occurred"),
  });

  //Update Lessons
  const [updateLesson] = useMutation(UPDATE_COURSE_LESSON, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],
    onCompleted: () => {
      successHandler("Lesson Updated successfully!");
      setTitle({
        title: "",
        course_id: null,
      });
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Update Topic
  const [updateTopicVisible] = useMutation(UPDATE_COURSE_TOPIC, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],
    onCompleted: (data) => {
      successHandler("Topic Updated successfully!");
      setTitle({
        title: "",
        course_id: null,
      });
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Delete Lessons
  const [deleteLessons] = useMutation(DELETE_COURSE_MODULE_MAPPING, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],
    onCompleted: () => {
      successHandler("Topic Succcessfully Deleted!");
      setTitle({
        title: "",
        course_id: null,
      });
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Delete Section
  const [deleteSection] = useMutation(DELETE_COURSE_SECTION, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],
    onCompleted: () => {
      successHandler("Lesson Succcessfully Deleted!");
      setTitle({
        title: "",
        course_id: null,
      });
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Update Lesson title
  const [updateLessonTitle] = useMutation(UPDATE_LESSON_TITLE, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],
    onCompleted: () => {
      successHandler("Lesson Title Updated");
      setTitle({
        title: "",
        course_id: null,
      });
    },

    onError: () => errorHandler("An error occurred"),
  });

  //Update Lesson order
  const [updateCourseSectionOrder] = useMutation(UPDATE_COURSE_SECTION_ORDER, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],

    onError: () => errorHandler("An error occurred"),
  });

  //Update Topic order
  const [updateCourseTopicOrder] = useMutation(UPDATE_COURSE_TOPIC_ORDER, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],

    onError: () => errorHandler("An error occurred"),
  });

  //Add Resources
  const [addNewResource] = useMutation(CREATE_RESOURCES, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],
    onCompleted: (data) => {
      successHandler("Resource added successfully!");
      setResourceModal(false);
      setResourceFile(null);
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Delete Resource
  const [deleteSelectedResource] = useMutation(
    DELETE_COURSE_MODULE_RESOURCES_BY_ID,
    {
      refetchQueries: () => [
        {
          query: GET_COURSE_SECTION,
          variables: {
            course_id: params.id,
            course_id_big: params.id,
          },
        },
      ],
      onCompleted: (data) => {
        successHandler("Resource deleted successfully!");
        setResourceModal(false);
        setResourceFile(null);
      },
      onError: () => errorHandler("An error occurred"),
    }
  );

  //Update Resource
  const [updateSelectedResource] = useMutation(UPDATE_COURSE_MODULE_RESOURCES, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],
    onCompleted: (data) => {
      successHandler("Resource Updated successfully!");
      setResourceModal(false);
      setResourceFile(null);
      setUploadedFile(null);
      setResource({});
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Move section module to other section
  const [moveSectionModule] = useMutation(UPDATE_SECTION_MODULE, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],

    onError: () => errorHandler("An error occurred"),
  });

  //Update Lessons Topic
  const [updateLessonTopicName] = useMutation(UPDATE_LESSON_TOPIC_NAME, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],
    onCompleted: () => {
      successHandler("Topic Name Updated successfully!");
      setTitle({
        title: "",
        course_id: null,
      });
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Link Copied resource attachement
  const [insert_course_resources] = useMutation(INSERT_COURSE_RESOURCES, {
    refetchQueries: () => [
      {
        query: GET_COURSE_SECTION,
        variables: {
          course_id: params.id,
          course_id_big: params.id,
        },
      },
    ],
    onCompleted: () => {
      successHandler("Resourec Topic Copy successfully!");
      setTitle({
        title: "",
        course_id: null,
      });
    },
    onError: () => errorHandler("An error occurred"),
  });

  const [items, setItems] = useState();

  const [section, setSection] = useState({
    enable: false,
  });

  useEffect(() => {
    if (lessons) {
      setItems(lessons.courses_course_section);
      setCertificateTemplateData(
        lessons.courses_certificate_template_course_partner_relation
      );
      setCourseName(lessons.courses_course[0].full_name);
    }
  }, [lessons]);

  const createSection = () => {
    setSection({
      enable: true,
    });
  };

  const updateInput = (e) => {
    setTitle({
      title: e.target.value,
      course_id: params.id ? params.id : null,
    });
  };

  const submit = (e) => {
    setSection({
      enable: false,
    });

    addLesson({
      variables: {
        course_id: title.course_id,
        name: title.title,
      },
    });
  };

  //Delete Mapping
  const deleteMapping = (id) => {
    if (window.confirm("Are you really want delete this Topic?")) {
      deleteLessons({
        variables: {
          mapping_id: id,
          id: id,
          bigmapping_id: id,
        },
      });
    }
  };

  //Delete Section
  const deleteLessonFun = (id) => {
    if (window.confirm("Are you really want delete this Lesson?")) {
      deleteSection({
        variables: {
          section_id: id,
          id: id,
        },
      });
    }
  };

  //Hide Lesson
  const hideLessonFun = (id, prevStatus) => {
    if (window.confirm("Are you really want update this Lesson?")) {
      updateLesson({
        variables: {
          visible: !prevStatus,
          id: id,
        },
      });
    }
  };

  // Hide Topic
  const hideTopicFun = (id, prevStatus) => {
    if (window.confirm("Are you really want update this Topic?")) {
      updateTopicVisible({
        variables: {
          visible: !prevStatus,
          id: id,
        },
      });
    }
  };

  // Copy Topic
  const copyTopicFun = async (copyData) => {
    if (window.confirm("Are you really want to copy this Topic?")) {
      copyData.name = copyData.name + "--copy";

      const insertTopicRow = await lessonTopicCopy({
        variables: copyData,
      });

      const topicId =
        insertTopicRow.data.insert_courses_course_module_mapping.returning[0]
          .id;

      topicCopyProgress(topicId, copyData);
    }
  };

  //copy Lesson
  const copyLessonFun = async (copyData) => {
    if (window.confirm("Are you really want to copy this Lesson?")) {
      //Create first copy lesson
      const lesson = await addLesson({
        variables: {
          course_id: copyData.course_id,
          name: copyData.name + " --copy",
          description: copyData?.description,
        },
      });

      const section_id =
        lesson.data.insert_courses_course_section.returning[0].id;

      //Replace copied section id with new section id
      const output = copyData.coursesec.map((s) => {
        s.section_id = section_id;
        return s;
      });

      output.map(async (topicData) => {
        topicData.name = topicData.name + "--copy";
        const insertTopicRow = await lessonTopicCopy({
          variables: topicData,
        });

        const topicId =
          insertTopicRow.data.insert_courses_course_module_mapping.returning[0]
            .id;

        topicCopyProgress(topicId, topicData);
      });
    }
  };

  //Topic copy progress fun
  const topicCopyProgress = async (topicId, topicData) => {
    // Copy Topic Restriction
    if (topicData.restriction) {
      await courseBuilderService.copyTopicRestriction(topicId, topicData);
    }

    // copy question
    if (topicData.course_question_link) {
      topicData.course_question_link.map((data) => {
        return link_questions_to_course({
          variables: {
            course_id: data.course_id,
            question_id: data.question_id,
            module_mapping_id: topicId,
          },
        });
      });
    }

    //copy scorm completion criteria
    if (topicData.scorm_completion_criteria && topicData.Module.type) {
      await courseBuilderService.InsertScormCompletionCriteria(
        topicId,
        topicData.scorm_completion_criteria
      );
    }

    //copy resources topics

    if (topicData.module_resources) {
      (topicData.module_resources || []).map((file) => {
        return insert_course_resources({
          variables: {
            actual_file_name: file.actual_file_name,
            file_name: file.file_name,
            mapping_id: topicId,
          },
        });
      });
    }
  };

  //Update Lesson Titile
  const updateLessonFun = (data) => {
    const section = items.find((category) => category.id === data.sectionid);

    updateLessonTitle({
      variables: {
        id: data.sectionid,
        name: data.title,
        description: section?.description,
      },
    });
  };

  //Update Lesson Titile
  const updateLessonDataFun = (data) => {
    updateLessonTitle({
      variables: data,
    });
  };

  //Handle Drag and drop
  const handleDragEnd = (result) => {
    const { type, source, destination, draggableId } = result;
    if (!destination) return;

    const sourceCategoryId = parseInt(source.droppableId);
    const destinationCategoryId = parseInt(destination.droppableId);

    // Reordering items
    if (type === "droppable-item") {
      // If drag and dropping within the same category or lesson
      if (sourceCategoryId === destinationCategoryId) {
        const updatedOrder = reorder(
          items.find((category) => category.id === sourceCategoryId).coursesec,
          source.index,
          destination.index
        );

        const updatedCategories = items.map((category) =>
          category.id !== sourceCategoryId
            ? category
            : { ...category, coursesec: updatedOrder }
        );

        updatedCategories.map((data, index) => {
          return (
            data.coursesec &&
            data.coursesec.map((e, updateIndex) => {
              return updateCourseTopicOrder({
                variables: {
                  id: e.id,
                  order: updateIndex,
                },
              });
            })
          );
        });

        setItems(updatedCategories);
      } else {
        moveSectionModule({
          variables: {
            id: draggableId,
            section_id: destinationCategoryId,
          },
        });
      }
    }

    // Reordering categories
    if (type === "droppable-category") {
      const updatedCategories = reorder(items, source.index, destination.index);

      updatedCategories.map((data, index) => {
        return updateCourseSectionOrder({
          variables: {
            id: data.id,
            order: index,
          },
        });
      });

      setItems(updatedCategories);
    }
  };

  //Resoure

  const openResourceModal = (mapping_id, resources) => {
    setActiveMapping(mapping_id);
    setActiveResources(resources);
    setResourceModal(!resourceModal);
  };

  const resourceFilehandleChange = async (file) => {
    setResourceFile(file);
    setUploadedFile(null);
  };

  const updateValue = (e) => {
    const key = e.target.getAttribute("data-key");
    e.preventDefault();
    const value = e.target.value;
    resource[key] = value;
    setResource((resource) => ({
      ...resource,
      key: value,
    }));
  };

  const addResource = async () => {
    console.log(resource);

    if (!resource.name) {
      return errorHandler("Please Enter Resource Name");
    }

    if (!resourceFile) {
      return errorHandler("Please Upload Resource");
    }

    const awsFileName = await resourecUploadFun();

    addNewResource({
      variables: {
        mapping_id: activeMapping,
        name: resource.name,
        file_name: awsFileName,
      },
    });
  };

  // Delete Resourece

  const deleteResource = (deleteId) => {
    if (window.confirm("Are you really want to delete this Resource?")) {
      deleteSelectedResource({
        variables: {
          id: deleteId,
        },
      });
    }
  };

  // Downlaod Resource
  const handleDownload = async (row) => {
    const url = await getFile(row);
    setFileLoading({ resourceId: null, status: false });
    window.open(url, "_blank");
  };

  //Update Resources
  const updateResource = async (rowId) => {
    if (!resource.name) {
      return errorHandler("Please Enter Resource Name");
    }

    if (resourceFile) {
      const awsFileName = await resourecUploadFun();

      updateSelectedResource({
        variables: {
          id: rowId,
          file_name: awsFileName,
          name: resource.name,
        },
      });
    } else {
      updateSelectedResource({
        variables: {
          id: rowId,
          file_name: uploadedFile,
          name: resource.name,
        },
      });
    }
  };

  //Resource Uploading
  const resourecUploadFun = async () => {
    return await uploadResourceLargeFile(
      resourceFile,
      resourceFile.name,
      true,
      "course-resources"
    )
      .then((res) => res.data_url)
      .catch((e) => {
        errorHandler("An error occured while uploading");
        throw error("Couldn't upload file", e.data || e);
      });
  };

  //Topic name update
  const updateLessonTopicFun = (data) => {
    updateLessonTopicName({
      variables: {
        id: data.topicid,
        name: data.title,
      },
    });
  };

  //Download Certificate

  const fileLoadingFun = (id) =>
    setFileLoading({ resourceId: id, status: true });

  return (
    <div className="mx-4">
      <div className="p-2">
        <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          {courseName}
        </h1>
      </div>

      <div className="flex flex-row">
        <div className="w-full">
          {!section.enable ? (
            <button className="btn-primary " onClick={() => createSection()}>
              Add Lessons
            </button>
          ) : (
            <form className="w-full max-w-sm" onSubmit={submit}>
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-2/3">
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    type="text"
                    value={title.title}
                    placeholder="Enter Lesson Name"
                    onChange={updateInput}
                  />
                </div>
                <button className="btn-primary">Create</button>
                <button
                  className="btn-secondary ml-2"
                  type="button"
                  onClick={() => setSection({ enable: false })}
                >
                  Cancel
                </button>
              </div>
            </form>
          )}{" "}
          <span
            className=" btn-primary ml-4 cursor-pointer"
            onClick={() => setShowCertificateModal(true)}
          >
            {" "}
            {certificateTemplateData?.length !== 0
              ? "View Certificate"
              : "+ Add Certificate"}
          </span>
          {certificateTemplateData && (
            <button
              className="btn-primary ml-4"
              onClick={() => {
                setShowSampleCertificateModal(true);
              }}
            >
              {"Download Sample Certificate"}
            </button>
          )}
        </div>

        <Menu as="div" className="float-right relative inline-block text-left">
          <Menu.Button
            className="inline-flex justify-center w-full  
		px-4 py-2  text-sm 
		font-medium text-black-700 "
          >
            <p className="text-japanese_indigo text-lg font-bold pr-2">
              Settings
            </p>
            <FaRegSun size={24} />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md z-50 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1 z-50	">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                      to={
                        getModulePathStringById(
                          ModuleNameIdMap.courseUserReports
                        ) + `/?id=${params.id}`
                      }
                    >
                      Enrolled Users
                    </Link>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                      to={
                        getModulePathStringById(
                          ModuleNameIdMap.completionSettings
                        ) + `/?id=${params.id}`
                      }
                    >
                      Completion Settings
                    </Link>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      <DragAndDrop onDragEnd={handleDragEnd}>
        <Drop id="droppable" type="droppable-category" items={items}>
          {items &&
            items.map((category, categoryIndex) => {
              return (
                <Drag
                  className="draggable-category"
                  key={category.id}
                  id={category.id}
                  index={categoryIndex}
                >
                  <Accordions
                    content={category}
                    deleteMapping={deleteMapping}
                    deleteLessonFun={deleteLessonFun}
                    hideLessonFun={hideLessonFun}
                    hideTopicFun={hideTopicFun}
                    copyTopicFun={copyTopicFun}
                    copyLessonFun={copyLessonFun}
                    updateLessonFun={updateLessonFun}
                    openResourceModal={openResourceModal}
                    updateLessonTopicFun={updateLessonTopicFun}
                    handleDownload={handleDownload}
                    fileLoading={fileLoading}
                    fileLoadingFun={fileLoadingFun}
                    updateLessonDataFun={updateLessonDataFun}
                  />
                </Drag>
              );
            })}
        </Drop>
      </DragAndDrop>

      {/* Resource Modal */}
      <Modal
        size="lg"
        show={resourceModal}
        onHide={() => {
          setResourceModal(false);
          setActiveMapping(null);
          setActiveResources([]);
          setResourceFile(null);
          setUploadedFile(null);
          setResource({});
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Resource Activity
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="p-2">
            <h1 class="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
              Add Resource
            </h1>
          </div>
          <CourseResources
            resourceFilehandleChange={resourceFilehandleChange}
            resourceFile={resourceFile}
            addResource={addResource}
            updateValue={updateValue}
            resource={resource}
            uploadedFile={uploadedFile}
            updateResource={updateResource}
          />
          <div className="p-2">
            <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
              Resource Lists
            </h1>
          </div>
          <div className="w-full bg-white rounded-lg shadow-lg">
            <ul className="divide-y-2 divide-gray-400">
              {activeResources.length > 0 &&
                activeResources.map((e, i) => {
                  return (
                    <li
                      key={e.id}
                      className="flex justify-between p-3 hover:bg-orange hover:text-white"
                    >
                      {e.name} ({e.file_name})
                      <div className="flex justify-end gap-3 p-3 hover:bg-orange hover:text-white">
                        <FaTrashAlt
                          size={20}
                          className="cursor-pointer mr-0"
                          onClick={() => deleteResource(e.id)}
                        />
                        <AiFillEdit
                          size={22}
                          className="cursor-pointer mr-0"
                          onClick={() => {
                            setResource((resource) => ({
                              ...resource,
                              name: e.name,
                              id: e.id,
                            }));

                            setUploadedFile(e.file_name);
                          }}
                        />
                        <AiOutlineDownload
                          className="cursor-pointer mr-0"
                          size={22}
                          onClick={() => handleDownload(e.file_name)}
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      <CertificateTemplateSelectorModal
        show={showCertificateModal}
        handleClose={() => {
          setShowCertificateModal(false);
          refetch();
        }}
        id={params.id}
        certificateTemplateData={certificateTemplateData}
      />
      <SampleCertificateGeneratorModal
        show={showSampleCertificateModal}
        handleClose={() => setShowSampleCertificateModal(false)}
        certificateTemplateData={certificateTemplateData}
        course={courseName}
      />
    </div>
  );
}
