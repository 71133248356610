import { gql } from "@apollo/client";

export const GET_BATCH_DETAILS = gql`
  query get_batches {
    courses_course_batches(order_by: { id: desc }) {
      batch_name
      from_date
      to_date
      slot_start_time
      course_id
      slot_end_time
      id
      max_learners
      min_learners
      slots_days
      platform
      type
      location
      is_demo
      partner {
        name
      }
      instructor {
        name
      }
      course {
        full_name
      }
    }
  }
`;

export const GET_BATCHES_WITH_FILTER = gql`
  query getBatchesWithFilter($where: courses_course_batches_bool_exp = {}) {
    courses_course_batches(where: $where, order_by: { id: desc }) {
      batch_name
      from_date
      to_date
      slot_start_time
      course_id
      slot_end_time
      id
      max_learners
      min_learners
      slots_days
      platform
      type
      location
      is_demo
      partner {
        name
      }
      instructor {
        name
      }
      course {
        full_name
      }
    }
  }
`;

export const GET_PARTNER_BATCHES = gql`
  query get_partner_batches($partnerIds: [Int!]!) {
    courses_course_batches(
      where: { partner_id: { _in: $partnerIds } }
      order_by: { id: desc }
    ) {
      partner_id
      batch_name
      from_date
      to_date
      slot_start_time
      slot_end_time
      id
      max_learners
      min_learners
      slots_days
      platform
      type
      location
      is_demo
      partner {
        name
      }
      instructor {
        name
      }
      course {
        full_name
      }
    }
  }
`;

export const GET_SLOTS_BY_BATCH_ID = gql`
  query get_slots($batch_id: Int) {
    courses_batch_slots(
      where: { batch_id: { _eq: $batch_id }, enable_slots: { _eq: true } }
      order_by: { slot_date: asc }
    ) {
      id
      slot_date
      slots_days
      endto_date
      platform
      batch_id
      meeting_link
      instructor_id
      event_id
      type
      location
      batch {
        batch_name
        instructor {
          id
          name
          email
        }
        course {
          full_name
        }
      }
    }
  }
`;

export const GET_SLOTS_ROW_DATA_BY_ID = gql`
  query get_slots_row_data($id: Int) {
    courses_batch_slots(where: { id: { _eq: $id } }) {
      id
      platform
      slot_date
      slots_days
      endto_date
      instructor_id
      batch_id
      event_id
      meeting_link
      type
      location

      batch {
        batch_name
      }
    }
  }
`;
export const GET_UPDATED_SLOTS = gql`
  query UpdateQuery($id: Int) {
    courses_batch_slots(where: { id: { _eq: $id } }) {
      batch_id
      endto_date
      event_id
      id
      meeting_link
      platform
      slot_date
      slots_days
      type
      location
    }
  }
`;

export const GET_COURSE_BATCHES = gql`
  query getCourseBatches($course_id: Int) {
    courses_course_batches(where: { course_id: { _eq: $course_id } }) {
      batch_name
      id
    }
  }
`;

export const GET_BATCH_ENROLLMENTS = gql`
  query getBatchUsers($batch_id: Int) {
    courses_user_batch_enrollment(where: { batch_id: { _eq: $batch_id } }) {
      user {
        id
        name
        email
      }
      course {
        id
        full_name
      }
    }
  }
`;

export const GET_BATCH_MEDIA_BY_ID = gql`
  query getBatchMedia($batchId: bigint) {
    courses_batch_media_relation(where: { batch_id: { _eq: $batchId } }) {
      id
      batch_id
      media_id
      remark
      media {
        id
        file_url
        file_name
        file_type
      }
    }
  }
`;
