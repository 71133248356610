import React from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../../components/InputGroup/FilterComponent";
import ViewCourseModal from "./ViewCourseModal";
import { FiEdit } from "react-icons/fi";

function CouponCodesTable(props) {
  const { canEdit } = props;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [courseCriteria, setCourseCriteria] = useState({});
  const [showCourseModal, setShowCourseModal] = useState(false);

  const handleButtonClick = (e, row) => {
    props.updateCourseCouponCode(row);
  };

  const setCourseCriteriaModal = (row) => {
    if (row) {
      setCourseCriteria(row);
      setShowCourseModal(true);
    }
  };

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const getCoursesLength = (row) => {
    if (row.criteria && row.criteria.courses) {
      return row.criteria.courses.length;
    }
    return 0;
  };

  const columns = [
    {
      name: "Coupon Code",
      selector: (row, index) => `${row.code}`,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row, index) => `${row.description ? row.description : "NA"}`,
      sortable: true,
    },
    // {
    //   name: "Start Date",
    //   selector: (row, index) => `${row.start_date}`,
    //   sortable: true,
    // },
    // {
    //   name: "End Date",
    //   selector: (row, index) => `${row.end_date}`,
    //   sortable: true,
    // },
    {
      name: "Limit",
      selector: (row, index) => `${row.user_limit ? row.user_limit : 0}`,
      sortable: true,
    },
    {
      name: "Num of users",
      selector: (row, index) => `${row.num_of_users ? row.num_of_users : 0}`,
      sortable: true,
    },

    {
      name: "Discount",
      selector: (row, index) => `${row.discount ? row.discount : 0}`,
      sortable: true,
    },
    {
      name: "Usage count",
      selector: (row, index) => `${row.st_user_coupons.length || 0}`,
      sortable: true,
    },
    ...(canEdit
      ? [
          {
            cell: (row) => (
              <button
                className="cta"
                onClick={(e) => handleButtonClick(e, row)}
              >
                Edit
              </button>
            ),
            width: "3rem",
            ignoreRowClick: true,
            allowOverflow: false,
            button: true,
          },
        ]
      : []),
    {
      cell: (row) => (
        <button
          className="secondary-cta hover:bg-opacity-90 text-xs my-1"
          onClick={(e) => setCourseCriteriaModal(row.criteria)}
        >
          {`View Courses(${getCoursesLength(row)}) `}
        </button>
      ),
      ignoreRowClick: true,
      // allowOverflow: true,
      button: true,
      width: "30$",
    },
  ];
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const filteredItems =
    props.allCoupons.data?.courses_st_coupons_configuration.filter(
      (item) =>
        item.code && item.code.toLowerCase().includes(filterText.toLowerCase())
    );

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  //console.log('Course criteris ', props.courseCriterias);
  const getTotalCourses = () => {
    if (props.totalCouponCodes.data) {
      return props.totalCouponCodes.data
        .courses_st_coupons_configuration_aggregate.aggregate.count;
    }
  };

  const handleClose = () => {
    setShowCourseModal(false);
  };

  return (
    <div>
      <DataTable
        columns={columns}
        pagination
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        data={filteredItems}
        progressPending={props.allCoupons.loading}
        paginationTotalRows={getTotalCourses()}
        selectableRows={false}
        selectableRowsHighlight={false}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        // onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
        //     handleRowSelection(allSelected, selectedRows);
        // }}
      />
      <ViewCourseModal
        show={showCourseModal}
        courses={courseCriteria.courses ? courseCriteria.courses : []}
        handleClose={() => handleClose()}
      />
    </div>
  );
}

export default CouponCodesTable;
