import { useState, useEffect } from "react";
import * as yup from "yup";

function ASDMReportFilterFormBuilder(values) {
  const [schema, setSchema] = useState();

  useEffect(() => {
    const schema = {
      gender: {
        label: "Gender",
        type: "select",
        options: [
          {
            label: "Male",
            value: 0,
          },
          {
            label: "Female",
            value: 1,
          },
          {
            label: "Other",
            value: 2,
          },
        ],
        schema: yup.string(),
      },

      class_: {
        label: "Class",
        type: "select",
        options: [
          {
            label: "9th",
            value: 3,
          },
          {
            label: "10th",
            value: 4,
          },
          {
            label: "11th",
            value: 5,
          },
          {
            label: "12th",
            value: 6,
          },
        ],
        schema: yup.string(),
      },
      district: {
        label: "District",
        type: "select",
        options: [
          {
            label: "Nalbari",
            value: "Nalbari",
          },
          {
            label: "Kamrup",
            value: "Kamrup",
          },
          {
            label: "Morigaon",
            value: "Morigaon",
          },
          {
            label: "Darrang",
            value: "Darrang",
          },
        ],
        schema: yup.string(),
      },
      date: {
        label: "Date",
        type: "date",
        schema: yup.string(),
      },
    };
    setSchema(schema);
  }, [values]);

  return schema;
}

export default ASDMReportFilterFormBuilder;
