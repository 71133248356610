import { gql } from "@apollo/client";

/*

{
    "organization_name": "Testpartner",
    "name": "test",
    "activation_start_date": "2021-11-28",
    "activation_end_date": "2021-11-30",
    "active": true,
    "contact_person_email": "test",
    "contact_person_name": "test",
    "identifier": "test"
}

*/

export const CREATE_PARTNER = gql`
  mutation insertPartner(
    $object: courses_partner_insert_input = {}
    $update_columns: [courses_partner_update_column!]
  ) {
    insert_courses_partner_one(
      object: $object
      on_conflict: {
        constraint: partner_identifier_key
        update_columns: $update_columns
      }
    ) {
      id
      name
    }
  }
`;

export const UPDATE_PARTNER_BY_ID = gql`
  mutation updatePartner(
    $partner: courses_partner_set_input = {}
    $partner_id: bigint = ""
  ) {
    update_courses_partner(
      _set: $partner
      where: { id: { _eq: $partner_id } }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_PARTNER_SETTLEMENT = gql`
  mutation MyMutation($amount: numeric, $partner_id: bigint) {
    insert_courses_partner_settlement_transactions(
      objects: { amount: $amount, partner_id: $partner_id }
    ) {
      returning {
        id
        amount
        partner_id
      }
    }
  }
`;

export const UPSERT_PARTNER_WEBSITE_CONFIGURATION = gql`
  mutation ($config: courses_partner_website_configuration_insert_input!) {
    insert_courses_partner_website_configuration_one(
      object: $config
      on_conflict: {
        constraint: partner_website_configuration_id_key
        update_columns: [subdomain, status, edited_at]
      }
    ) {
      id
      partner_id
      subdomain
      status
      website_url
      edited_at
      created_at
      updated_at
    }
  }
`;

export const DELETE_PARTNER_WEBSITE_CONFIGURATION = gql`
  mutation ($partner_id: bigint) {
    config: delete_courses_partner_website_configuration(
      where: { partner_id: { _eq: $partner_id } }
    ) {
      affected_rows
    }

    tuples: delete_courses_partner_website_configuration_tuples(
      where: { partner_id: { _eq: $partner_id } }
    ) {
      affected_rows
    }

    revenue_split_directives: delete_courses_partner_revenue_split_directives(
      where: { partner_id: { _eq: $partner_id } }
    ) {
      affected_rows
    }
  }
`;

export const UPSERT_PARTNER_WEBSITE_CONFIGURATION_TUPLES = gql`
  mutation upsertPartnerWebsiteConfigurationTuples(
    $objects: [courses_partner_website_configuration_tuples_insert_input!]!
  ) {
    insert_courses_partner_website_configuration_tuples(
      objects: $objects
      on_conflict: {
        constraint: partner_website_configuration_tuples_id_key
        update_columns: [partner_data_value]
      }
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export const UPSERT_PARTNER_REVENUE_SPLIT_DIRECTIVES = gql`
  mutation (
    $directives: [courses_partner_revenue_split_directives_insert_input!]!
  ) {
    insert_courses_partner_revenue_split_directives(
      objects: $directives
      on_conflict: {
        constraint: partner_revenue_split_directives_course_id_partner_id_key
        update_columns: [commission]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_PARTNER_REVENUE_SPLIT_DIRECTIVES = gql`
  mutation ($directiveIds: [bigint!]!) {
    delete_courses_partner_revenue_split_directives(
      where: { id: { _in: $directiveIds } }
    ) {
      affected_rows
    }
  }
`;
