import { Droppable } from "react-beautiful-dnd";

export const Drop = ({ id, type, items, ...props }) => {
  return (
    <Droppable droppableId={id.toString()} type={type}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver
                ? "#d1d5db"
                : items
                ? "#fed7aa"
                : "",
            }}
            {...provided.droppableProps}
            className="accordion p-2"
          >
            {props.children}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};
