import React, { useEffect, useState } from "react";
import Checkbox from "../../../../../components/InputGroup/Checkbox";
import Input from "../../../../../components/InputGroup/Input";
import TextArea from "../../../../../components/InputGroup/TextArea";
import Select from "../../../../../components/InputGroup/Select";
import { ActivityDbSchema } from "./ActivityDbSchema";
import DropzoneComponent from "../../../../../components/FileUpload/DropzoneComponent";
import { default as ReactSelect } from "react-select";
import { FileUploader } from "react-drag-drop-files";
import Container from "components/Container/Container";
import { useHistory } from "react-router-dom";
import { object } from "yup";
import { errorHandler } from "utils/toast";
import { AiFillCloseSquare } from "react-icons/ai";
import { BsCloudArrowDownFill } from "react-icons/bs";
import api from "api/Api";
const CourseActivityForm = ({
  updateValue,
  updateCheckbox,
  submitSection,
  section,
  message,
  minScore,
  // paramValue,
  handleFileReader,
  criteriaOption,
  handleChange,
  topic,
  restriction,
  fileTypes,
  CompleteStatus,
  ScormVerison,
  defaultRestriction,
  action,
  fileName,
  selectedValue,
  fileLoading,
  removeAttachedFile,
  lessonName,
}) => {
  let history = useHistory();

  const [requireFields, setRequireFields] = useState({
    name: true,
    description: true,
    file_name: true,
    scorm_version: true,
    min_score: false,
    completion_criteria: true,
    passed: false,
    completed: false,
    maxattempt: true,
    attemtsgrade: true,
    restriction_modules: false,
  });

  const [errorField, seterrorField] = useState({});

  useEffect(() => {
    if (section) {
      checkValidation();
    }
  }, [section, fileName, selectedValue]);

  console.log("section", section);

  const submitScorm = (e) => {
    e.preventDefault();

    if (checkValidation()) {
      const hh = Object.keys(errorField).find(
        (key) => errorField[key] === true
      );

      if (hh) {
        errorHandler("Please fill all required fields");
      } else {
        submitSection();
      }
    }
  };

  //Check Validation

  const checkValidation = (e) => {
    Object.keys(requireFields).map((fieldName) => {
      if (section[fieldName] !== 0) {
        seterrorField((prevState) => ({
          ...prevState,
          [fieldName]:
            !section[fieldName] || section[fieldName] < 0 ? true : false,
          file_name: !fileName.file_name ? true : false,
          min_score:
            section["enable_min_score"] && !section["min_score"] ? true : false,
          restriction_modules:
            section["enable_restriction"] && !selectedValue.length > 0
              ? true
              : false,
          description: false,
        }));
      }
      if (section[fieldName] === 0) {
        seterrorField((prevState) => ({
          ...prevState,
          [fieldName]: false,
        }));
      }

      if (section["completion_criteria"] == "1") {
        seterrorField((prevState) => ({
          ...prevState,
          passed: section["completed"] || section["passed"] ? false : true,
          completed: section["completed"] || section["passed"] ? false : true,
        }));
      } else {
        seterrorField((prevState) => ({
          ...prevState,
          passed: false,
          completed: false,
        }));
      }
    });

    return true;
  };

  // Scorm File Downlaod
  const downloadScorm = async (row) => {
    const data = { data_url: row, is_large_file: true };
    const response = await api().post("/get_file", data);

    if (response.data) window.open(response.data, "_top");
    else errorHandler("Something will be wrong not able to download");
  };
  return (
    <Container>
      <button
        className="inline-flex justify-center py-2 px-4 
        border border-transparent shadow-sm text-sm font-medium 
        rounded-md text-white bg-orange"
        onClick={() => history.goBack()}
      >
        Go To Back
      </button>

      <div class="">
        <h1 class="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          {lessonName}
        </h1>
      </div>

      <form onSubmit={(e) => submitScorm(e)}>
        <div class="p-2">
          <h1 class="font-bold text-center my-4 text-xl  text-japanese_indigo">
            {action == "update" ? "Update" : ""} SCORM Activity
          </h1>
        </div>
        <div className="shadow sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="flex flex-wrap">
              {Object.keys(ActivityDbSchema).map((course_key) => {
                if (course_key == 1) {
                  return (
                    <div className="mb-2 w-full" key={course_key}>
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>General</div>
                        </div>
                        <div className="accordion-content p-3 w-full flex flex-row">
                          {ActivityDbSchema[course_key].map(
                            ({ label, column_name, type }) => (
                              <div className="mt-4 w-6/12" key={course_key}>
                                {type === "text" && (
                                  <Input
                                    label={label}
                                    type={type}
                                    data-key={column_name}
                                    onChange={updateValue}
                                    value={section[column_name]}
                                    requiredField={
                                      requireFields[column_name] ? "yes" : "no"
                                    }
                                    name={column_name}
                                    error={
                                      errorField[column_name] ? "yes" : "no"
                                    }
                                  />
                                )}

                                {type === "text_area" && (
                                  <TextArea
                                    label={"Description"}
                                    data-key={column_name}
                                    onChange={updateValue}
                                    value={section[column_name]}
                                    requiredField={"no"}
                                    error={
                                      errorField[column_name] ? "yes" : "no"
                                    }
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {Object.keys(ActivityDbSchema).map((course_key) => {
                if (course_key == 2) {
                  return (
                    <div key={course_key} className="mb-2 mt-4 w-full">
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>Scorm Package</div>
                        </div>
                        <div className="accordion-content p-3 w-full flex flex-row">
                          {ActivityDbSchema[course_key].map(
                            ({ label, column_name, type }) => (
                              <div className="mt-4 w-6/12" key={course_key}>
                                {type === "file" && (
                                  <>
                                    <label>
                                      {label}
                                      {requireFields[column_name] ? (
                                        <span className="text-red-600 text-lg	">
                                          *
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </label>

                                    {fileLoading && (
                                      <div class="flex justify-center items-center">
                                        <div
                                          class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                                          role="status"
                                        >
                                          <span class="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    {!fileLoading && (
                                      <FileUploader
                                        handleChange={handleFileReader}
                                        name="file"
                                        types={["ZIP"]}
                                        classes={"drop_area drop_zone"}
                                      />
                                    )}

                                    {errorField["file_name"] && !fileLoading ? (
                                      <span className="border-red-600 text-md text-red-600">
                                        Please upload scorm pacakge
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    {!fileLoading && (
                                      <div className="flex justify-between p-2 w-3/4">
                                        <div className="inline-flex">
                                          {fileName?.system_filename
                                            ? `File name: ${fileName?.system_filename}`
                                            : fileName?.file_name
                                            ? `File name: ${fileName?.file_name}`
                                            : ""}
                                          {fileName?.file_name && (
                                            <>
                                              <AiFillCloseSquare
                                                className="cursor-pointer "
                                                size={22}
                                                color="red"
                                                onClick={() =>
                                                  removeAttachedFile()
                                                }
                                              />
                                            </>
                                          )}
                                        </div>
                                        <div>
                                          {fileName?.file_name && (
                                            <>
                                              <BsCloudArrowDownFill
                                                className="cursor-pointer float-right"
                                                size={22}
                                                onClick={() =>
                                                  downloadScorm(
                                                    fileName?.file_name
                                                  )
                                                }
                                              />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                                {type == "select" && (
                                  <Select
                                    label={label}
                                    options={ScormVerison}
                                    valueField={"value"}
                                    displayField={"label"}
                                    data-key={column_name}
                                    onChange={(e) => updateValue(e)}
                                    value={section[column_name]}
                                    requiredField={
                                      requireFields[column_name] ? "yes" : "no"
                                    }
                                    name={column_name}
                                    error={
                                      errorField[column_name] ? "yes" : "no"
                                    }
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {Object.keys(ActivityDbSchema).map((course_key) => {
                if (course_key == 3) {
                  return (
                    <div key={course_key} className="mb-2  mt-4 w-full">
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>completion </div>
                        </div>

                        <div className="accordion-content w-full flex flex-row p-3 ">
                          <div className="w-6/12">
                            {ActivityDbSchema[course_key].map(
                              ({ label, column_name, type, label_text }) => (
                                <div className="mt-4" key={course_key}>
                                  {type == "select" && (
                                    <Select
                                      label={label}
                                      options={criteriaOption}
                                      valueField={"value"}
                                      displayField={"label"}
                                      data-key={column_name}
                                      onChange={(e) => updateValue(e)}
                                      value={section[column_name]}
                                      requiredField={
                                        requireFields[column_name]
                                          ? "yes"
                                          : "no"
                                      }
                                      name={column_name}
                                      error={
                                        errorField[column_name] ? "yes" : "no"
                                      }
                                    />
                                  )}

                                  {CompleteStatus && (
                                    <div>
                                      {label_text && (
                                        <p className="pl-5 label_text">
                                          {label_text}
                                          {section["enable_min_score"] ? (
                                            <span className="text-red-600 text-lg	">
                                              *
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      )}

                                      {minScore && type == "number" && (
                                        <Input
                                          label={label}
                                          type={type}
                                          data-key={column_name}
                                          onChange={updateValue}
                                          value={section[column_name]}
                                          requiredField={
                                            section["enable_min_score"]
                                              ? "yes"
                                              : "no"
                                          }
                                          error={
                                            errorField[column_name]
                                              ? "yes"
                                              : "no"
                                          }
                                        />
                                      )}

                                      {type == "checkbox" &&
                                        column_name !==
                                          "enable_user_attempt" && (
                                          <Checkbox
                                            label={label}
                                            data-key={column_name}
                                            onChange={updateCheckbox}
                                            checked={section[column_name]}
                                            value={column_name}
                                            requiredField={
                                              requireFields[column_name]
                                                ? "yes"
                                                : "no"
                                            }
                                            name={column_name}
                                            error={
                                              errorField[column_name]
                                                ? "yes"
                                                : "no"
                                            }
                                          />
                                        )}
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>

                          <div className="w-6/12">
                            {ActivityDbSchema[course_key].map(
                              ({ label, column_name, type, label_text }) => (
                                <div className="mt-16 pl-10 " key={course_key}>
                                  {type == "checkbox" &&
                                    column_name === "enable_user_attempt" && (
                                      <Checkbox
                                        label={label}
                                        data-key={column_name}
                                        onChange={updateCheckbox}
                                        checked={section[column_name]}
                                        value={column_name}
                                        requiredField={
                                          requireFields[column_name]
                                            ? "yes"
                                            : "no"
                                        }
                                        name={column_name}
                                        error={
                                          errorField[column_name] ? "yes" : "no"
                                        }
                                      />
                                    )}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {Object.keys(ActivityDbSchema).map((course_key) => {
                if (course_key == 4) {
                  return (
                    <div key={course_key} className="mb-2 mt-4 w-full">
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>Restrict Access</div>
                        </div>
                        <div className="accordion-content p-3 w-full">
                          {ActivityDbSchema[course_key].map(
                            ({ label, column_name, type, label_text }) => (
                              <div className="mt-4" key={course_key}>
                                {type === "checkbox" && (
                                  <Checkbox
                                    label={label}
                                    data-key={column_name}
                                    onChange={updateCheckbox}
                                    checked={section[column_name]}
                                  />
                                )}

                                {restriction && type == "multipleselection" && (
                                  <div>
                                    {label_text && (
                                      <p className="pl-5 label_text">
                                        {label_text}

                                        <span className="text-red-600 text-lg	">
                                          *
                                        </span>
                                      </p>
                                    )}
                                    <ReactSelect
                                      placeholder={label}
                                      options={topic ? topic : []}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      allowSelectAll={true}
                                      onChange={handleChange}
                                      defaultValue={
                                        action == "update" &&
                                        defaultRestriction.length > 0
                                          ? defaultRestriction
                                          : []
                                      }
                                    />
                                    {errorField[column_name] ? (
                                      <span className="border-red-600 text-md text-red-600">
                                        Please select atleast one option
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                                {restriction && type == "number" && (
                                  <div>
                                    {label_text && (
                                      <p className="pl-5 label_text">
                                        {label_text}
                                      </p>
                                    )}
                                    <Input
                                      label={label}
                                      type={type}
                                      data-key={column_name}
                                      onChange={updateValue}
                                      value={section[column_name]}
                                    />
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {Object.keys(ActivityDbSchema).map((course_key) => {
                if (course_key == 5) {
                  return (
                    <div key={course_key} className="mb-2 mt-4 w-full">
                      <div className="accordion-item">
                        <div className="accordion-title text-black text-md bg-orange p-2">
                          <div>Attempts Management</div>
                        </div>
                        <div className="accordion-content p-3 w-full flex flex-row">
                          {ActivityDbSchema[course_key].map(
                            ({ label, column_name, type, options }) => (
                              <div className="mt-4 w-6/12" key={course_key}>
                                {type == "select" && (
                                  <Select
                                    label={label}
                                    options={options}
                                    valueField={"value"}
                                    displayField={"label"}
                                    data-key={column_name}
                                    onChange={(e) => updateValue(e)}
                                    value={section[column_name]}
                                    requiredField={
                                      requireFields[column_name] ? "yes" : "no"
                                    }
                                    name={column_name}
                                    error={
                                      errorField[column_name] ? "yes" : "no"
                                    }
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          {message && (
            <div className="text-green-600 text-base mt-2">{message}</div>
          )}
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          {fileLoading ? (
            <div class="flex justify-center items-center">
              <div
                class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange"
            >
              {action == "update" ? "Update SCORM" : "Save SCORM"}
            </button>
          )}
        </div>
      </form>
    </Container>
  );
};

export default CourseActivityForm;
