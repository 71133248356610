import { useState, useEffect } from "react";
import PieCharts from "../PieChart";
import _ from "lodash";
import { getQuizAttemptRecords } from "./QuizReport";

export default function CumulativeReports(props) {
  const { users, userIds, activityIds, type } = props;
  const [overallScore, setOverAllScore] = useState();
  const [careerGroupCount, setCareerGroupCount] = useState();

  let maleCount = 0;
  let femaleCount = 0;
  let schools = {};
  const usersCount = users.length;

  const usersGenderArray = users.map((item) => {
    const genderData = item.user?.gender || undefined;
    const gender =
      genderData === "0" || genderData === "male"
        ? "Male"
        : genderData === "1" || genderData === "female"
        ? "Female"
        : null;
    if (gender === "Male") maleCount = maleCount + 1;
    if (gender === "Female") femaleCount = femaleCount + 1;
    return {
      user_id: item.user_id,
      gender: gender,
    };
  });

  const usersDistrictsArray = users.map((item) => {
    const instituteId = item.batch?.institute?.id || null;
    if (instituteId) {
      if (!schools[instituteId]) {
        schools[item.batch.institute.id] = true;
      }
    }
    return {
      user_id: item.user_id,
      district: item.batch?.institute?.district || null,
    };
  });

  async function getScores() {
    const quizAttempts = await getQuizAttemptRecords(
      type,
      activityIds,
      userIds
    );
    const scores = await getUserScoresArray(
      quizAttempts,
      userIds,
      processASDMUsersResponses
    );
    let careerGroup = {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    };
    scores.forEach((group) => {
      careerGroup[group.group] = careerGroup[group.group] + 1;
    });
    setCareerGroupCount(careerGroup);
    setOverAllScore(scores);
  }

  useEffect(() => {
    getScores();
  }, [type, activityIds, userIds]);

  return (
    <div className="mx-2">
      <h3 className="text-xl font-semibold">Cumulative Reports</h3>
      <div className="container my-16 px-4 mx-auto">
        <div className="mb-16 text-gray-800 text-center">
          <div className="grid grid-cols-3 gap-x-12 ">
            <div className="">
              <h2 className="text-3xl font-bold display-6 text-orange ">
                {usersCount}
              </h2>
              <h5 className="text-lg font-medium ">Total Students Covered</h5>
            </div>
            <div className=" ">
              <h2 className="text-3xl font-bold display-6 text-orange">
                {Object.keys(schools).length}
              </h2>
              <h5 className="text-lg font-medium ">Schools</h5>
            </div>
            <div className="">
              <h2 className="text-3xl font-bold display-6">
                <span className=" text-blue-500">{maleCount}</span>/
                <span className="text-pink-500">{femaleCount}</span>
              </h2>
              <h5 className="text-lg font-medium ">Male/Female</h5>
            </div>
          </div>
        </div>
      </div>
      {careerGroupCount && (
        <div className="container my-16 px-4 mx-auto">
          <div className="mb-16 text-gray-800 text-center">
            <div className="grid grid-cols-4 gap-x-12 ">
              <div className="">
                <h2 className="text-3xl font-bold display-6 text-red-500 ">
                  {careerGroupCount.A}
                </h2>
                <h5 className="text-lg font-medium ">Group A</h5>
              </div>
              <div className=" ">
                <h2 className="text-3xl font-bold display-6 text-blue-500">
                  {careerGroupCount.B}
                </h2>
                <h5 className="text-lg font-medium ">Group B</h5>
              </div>
              <div className="">
                <h2 className="text-3xl font-bold display-6 text-green-500 ">
                  {careerGroupCount.C}
                </h2>
                <h5 className="text-lg font-medium ">Group C</h5>
              </div>
              <div className="">
                <h2 className="text-3xl font-bold display-6 text-yellow-500 ">
                  {careerGroupCount.D}
                </h2>
                <h5 className="text-lg font-medium ">Group D</h5>
              </div>
            </div>
          </div>
        </div>
      )}
      {users.length === 0 ? (
        <span className="text-2xl font-semibold text-orange ">
          No Data For These Filters
        </span>
      ) : (
        <div className="flex flex-row">
          <div>
            <span className="text-md font-semibold text-orange ">
              Gender Distribution
            </span>
            <PieCharts
              data={usersGenderArray || []}
              cumulationKey={"gender"}
              chartWidth={290}
            />
          </div>
          {overallScore && (
            <div>
              <span className="text-md font-semibold text-orange ">
                Overall Career Group Distribution
              </span>
              <PieCharts
                data={overallScore}
                cumulationKey={"group"}
                chartWidth={290}
              />
            </div>
          )}
          <div>
            <span className="text-md font-semibold text-orange ">
              District Distribution
            </span>
            <PieCharts
              data={usersDistrictsArray || []}
              cumulationKey={"district"}
              chartWidth={290}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function processASDMUsersResponses(responses) {
  let psychometricResponses = [];
  responses.forEach((res) => {
    const userResponse = res.user_response.toLowerCase();
    if (userResponse[0] === "a" || userResponse[0] === "b") {
      const sliceResponse = userResponse.slice(3);
      if (sliceResponse !== "yes" || sliceResponse !== "no") {
        psychometricResponses.push(res);
      }
    } else {
      psychometricResponses.push(res);
    }
  });

  let g1_marks = 0;
  let g2_marks = 0;
  let g3_marks = 0;
  let g4_marks = 0;
  psychometricResponses.forEach((res) => {
    if (
      res.user_response.trim()[0] === "D" ||
      res.user_response.trim()[res.user_response.length - 1] === "4"
    )
      g4_marks = g4_marks + 1;
    if (
      res.user_response.trim()[0] === "C" ||
      res.user_response.trim()[res.user_response.length - 1] === "3"
    )
      g3_marks = g3_marks + 1;
    if (
      res.user_response.trim()[0] === "B" ||
      res.user_response.trim()[res.user_response.length - 1] === "2"
    )
      g2_marks = g2_marks + 1;
    if (
      res.user_response.trim()[0] === "A" ||
      res.user_response.trim()[res.user_response.length - 1] === "1"
    )
      g1_marks = g1_marks + 1;
  });

  return {
    A: g1_marks,
    B: g2_marks,
    C: g3_marks,
    D: g4_marks,
  };
}

async function getUserScoresArray(responses, userIds, processResponses) {
  const userIdsMemo = {};

  //grouping quiz responses by user_id
  await responses.forEach((res) => {
    const user_id = res.user_id;
    if (!userIdsMemo[user_id]) {
      userIdsMemo[user_id] = [res];
    } else userIdsMemo[user_id] = [...userIdsMemo[user_id], res];
  });

  let userScoresList = [];

  userIds.forEach((id) => {
    //checking for users who are enrolled but have not attempted the quiz
    if (userIdsMemo[id]) {
      const userQuizResponses = userIdsMemo[id];
      const userScores = processResponses(userQuizResponses);

      let largest = null;
      for (const key in userScores) {
        if (userScores[largest] === userScores[key]) {
          userScoresList.push({ group: String(key), user_id: id });
        }
        if (largest === null || userScores[key] > userScores[largest]) {
          largest = key;
        }
      }
      userScoresList.push({ group: String(largest), user_id: id });
    }
  });

  return userScoresList;
}
