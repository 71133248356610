import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { authRecoilVar } from "State/User";
import { logoutUser } from "../../api/Auth";

function LogoutPage() {
  const setAuth = useSetRecoilState(authRecoilVar);

  useEffect(() => {
    logoutUser().then(() => {
      setAuth();
      window.location.replace("/");
    });
  }, []);

  return null;
}

export default LogoutPage;
