import Axios from "./Api";
import { setLoginCookie, getLoginCookie, setJwtToken } from "../utils/Auth";
import { refreshSocket } from "utils/websocket";

export const LoginUser = async (email, password) => {
  const response = await Axios().post("/login", { email, password });
  if (response.data.success) {
    setAuthState({
      jwt: response.data.jwt_token,
      accessToken: response.data.auth.access_token,
    });
  }
  return response.data;
};
export const checkUserAuthentication = async () => {
  if (!getLoginCookie()) return false;
  const response = await Axios().post("/validate_login");
  return response.data;
};

export const logoutUser = async () => {
  const response = await Axios().post("/logout");
  setAuthState();
  return response.data;
};

window.logout = logoutUser;

export const setAuthState = ({ jwt, accessToken } = {}) => {
  setLoginCookie(accessToken || "");
  setJwtToken(jwt || "");

  refreshSocket();
};
