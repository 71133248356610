import Container from "components/Container/Container";
import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";

export default function TopicListTable(props) {
  const [initial, setInitial] = useState(true);

  const arraySort = useMemo(() => {
    return (
      props.topics.length > 0 &&
      props.topics.slice().sort((a, b) => a.section_id - b.section_id)
    );
  }, [props.topics]);

  // const arraySort =
  //   props.topics.length > 0 &&
  //   props.topics.slice().sort((a, b) => a.section_id - b.section_id);

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 100,
  });

  const columns = [
    {
      name: "Topic Id",
      selector: (row, index) => `${row.id}`,
      width: "5rem",
      //   sortable: true,
    },
    {
      name: "Name",
      selector: (row, index) => `${row.name}`,
      sortable: true,
      width: "12rem",
    },
    {
      name: "Description",
      selector: (row, index) => `${row.description}`,
      sortable: true,
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  const handleRowSelection = (allSelected, selectedRows) => {
    const topicids = props.selectedTopics;

    if (topicids.length != selectedRows.length) {
      props.updateCourseSelection(selectedRows.map((e) => e.id));
    }

    selectedRows &&
      selectedRows.filter((e) => {
        if (topicids.indexOf(e.id) == -1) {
          setInitial(false);

          return props.updateCourseSelection(
            selectedRows.map((c) => c.id),
            true
          );
        }
      });
  };

  // console.log(" props.selectedTopics", props.selectedTopics);

  var preSelectedRows =
    props.preSelectedRows && props.preSelectedRows.map((e) => e.mapping_id);

  const rowSelectCritera = (row) => {
    if (props.selectedTopics.length > 0 || !preSelectedRows) {
      return (
        props.selectedTopics &&
        props.selectedTopics.indexOf(parseInt(row.id)) > -1
      );
    }
    if (preSelectedRows.length > 0 && initial) {
      return preSelectedRows && preSelectedRows.indexOf(parseInt(row.id)) > -1;
    }
  };

  return (
    <Container>
      <DataTable
        columns={columns}
        data={arraySort ? arraySort : []}
        pagination
        paginationServer
        paginationTotalRows={100}
        selectableRowSelected={rowSelectCritera}
        selectableRows
        selectableRowsHighlight={true}
        paginationPerPage={100}
        paginationRowsPerPageOptions={[100, 150, 200, 250]}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSelectedRowsChange={({
          allSelected,
          selectedCount,
          selectedRows,
        }) => {
          handleRowSelection(allSelected, selectedRows);
        }}
      />

      <button
        className="float-right w-40 h-11 mt-3 border-r-4 bg-gray-700 text-base text-white mt-1"
        onClick={() => props.handleSubmit()}
      >
        Save
      </button>
    </Container>
  );
}
