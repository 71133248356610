import { gql } from "@apollo/client";

export const CREATE_QUESTION_CATEGORY = gql`
  mutation InsertQuestionCategory(
    $description: String
    $name: String
    $visible: Boolean
  ) {
    insert_courses_question_category(
      objects: { name: $name, description: $description, visible: $visible }
    ) {
      returning {
        name
      }
    }
  }
`;
export const UPDATE_QUESTION_CATEGORY = gql`
  mutation UpdateQuestionCategory(
    $visible: Boolean
    $name: String
    $description: String
    $id: Int
  ) {
    update_courses_question_category(
      where: { id: { _eq: $id } }
      _set: { name: $name, description: $description, visible: $visible }
    ) {
      returning {
        id
        name
      }
    }
  }
`;

export const INSERT_QUESTION_TAG = gql`
  mutation InsertQuestionTag($name: String!) {
    insert_courses_question_tags(objects: { name: $name }) {
      returning {
        id
        name
      }
    }
  }
`;

export const INSERT_QUIZ_QUESTION = gql`
  mutation InsertQuizQuestion(
    $marks: Int
    $question_text: String
    $question_type: Int
    $visible: Boolean
    $question_category: Int
    $question_level: Int
    $mode: String
    $data: [courses_question_answer_insert_input!]!
  ) {
    insert_courses_questions(
      objects: {
        question_text: $question_text
        question_type: $question_type
        marks: $marks
        question_category: $question_category
        question_level: $question_level
        mode: $mode
        quest_answer: { data: $data }
        visible: $visible
      }
    ) {
      affected_rows
      returning {
        id
        quest_answer {
          answer_text
          question_id
        }
      }
    }
  }
`;

export const INSERT_QUESTION_TAGS = gql`
  mutation MyMutation($question_id: Int, $tag_id: Int) {
    insert_courses_question_tag_relation(
      objects: { question_id: $question_id, tag_id: $tag_id }
    ) {
      returning {
        tag_id
        id
        question_id
      }
    }
  }
`;

export const LINK_QUESTION_TO_COURSE = gql`
  mutation LinkQuestionToCourse(
    $course_id: Int
    $module_mapping_id: Int
    $question_id: Int
  ) {
    insert_courses_course_question_link(
      objects: {
        course_id: $course_id
        question_id: $question_id
        module_mapping_id: $module_mapping_id
      }
    ) {
      returning {
        question_id
        id
        module_mapping_id
      }
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation UpdateAndInsertCourseQuestionData(
    $id: Int!
    $marks: Int
    $questionText: String
    $questionCategory: Int
    $questionType: Int
    $visible: Boolean
    $mode: String
    $questionAnswers: [courses_question_answer_insert_input!]!
    $questionTagRelations: [courses_question_tag_relation_insert_input!]!
    $answerIds: [Int!]
    $ids: [Int!]
  ) {
    update_courses_questions(
      where: { id: { _eq: $id } }
      _set: {
        marks: $marks
        visible: $visible
        question_type: $questionType
        question_text: $questionText
        question_category: $questionCategory
        mode: $mode
      }
    ) {
      affected_rows
    }

    insert_courses_question_answer(
      objects: $questionAnswers
      on_conflict: {
        constraint: question_answer_pkey
        update_columns: [
          answer_text
          isAnswer
          category
          system_filename
          answer_img
        ]
      }
    ) {
      affected_rows
    }

    insert_courses_question_tag_relation(objects: $questionTagRelations) {
      affected_rows
      returning {
        id
        question_id
        tag_id
      }
    }
    delete_courses_question_answer(where: { id: { _in: $answerIds } }) {
      affected_rows
    }
    delete_courses_question_tag_relation(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;
