import { convertTimeStamp } from "utils/func";

export const courseCategoriesSchema = [
  {
    column_name: "name",
    type: "text",
    label: "Category Name (Mandatory)",
  },
  {
    column_name: "description",
    type: "text_area",
    label: "Description (Mandatory)",
  },
  {
    column_name: "visible",
    type: "checkbox",
    label: "Visibility on dashboard (Mandatory)",
  },
  {
    column_name: "image_url",
    type: "text",
    label: "Image URL (Optional)",
  },
];

export const courseCategoriesDisplayKyes = [
  {
    column_name: "name",
    label: "Category Name",
  },
  {
    column_name: "description",
    label: "Description",
  },
  {
    column_name: "visible",
    label: "Visibility on dashboard",
  },
  {
    column_name: "image_url",
    label: "Image URL",
  },
  {
    column_name: "created_at",
    label: "Created At",
  },
  {
    column_name: "updated_at",
    label: "Updated At",
  },
];

export const courseFields = {
  1: [
    {
      column_name: "full_name",
      type: "text",
      label: "Full Name (Mandatory)",
    },

    {
      column_name: "course_category_id",
      type: "selector",
      label: "Course Category (Mandatory)",
    },
    {
      column_name: "description",
      type: "text_area",
      label: "Description (Optional)",
    },
    {
      column_name: "short_name",
      type: "text",
      label: "Short Name (Optional)",
    },
    {
      column_name: "image_url",
      type: "text",
      label: "Image URL (Optional)",
    },
    {
      column_name: "video_url",
      type: "text",
      label: "Video (Optional)",
    },
    {
      column_name: "certificate_image_url",
      type: "file",
      label: "Certificate Image (Optional)",
    },
    {
      column_name: "is_moodle_course",
      type: "checkbox",
      label: "is Moodle Course",
    },
  ],
  2: [
    {
      column_name: "moodle_course_url",
      type: "text",
      label: "Moodle Course URL (Mandatory)",
    },
    {
      column_name: "moodle_config_id",
      type: "selector",
      label: "Moodle Server (Mandatory)",
    },
  ],
  3: [
    {
      column_name: "moodle_course_id",
      type: "text",
      label: "Moodle Course Id (Mandatory)",
    },
    {
      column_name: "discontinued_from_moodle_at",
      type: "date",
      label: "Discontinued from Moodle at",
    },
  ],
  4: [
    {
      column_name: "start_date",
      type: "date",
      label: "Start Date (Mandatory)",
    },
    {
      column_name: "end_date",
      type: "date",
      label: "End Date (Mandatory)",
    },
    {
      column_name: "cost",
      type: "number",
      label: "Price",
    },
    {
      column_name: "nsqf_level",
      type: "text",
      label: "NSQF Level (Optional)",
    },
    {
      column_name: "discount",
      type: "number",
      label: "Discount (Optional)",
    },
    {
      column_name: "duration",
      type: "text",
      label: "Duration (Optional)",
    },
    {
      column_name: "course_type",
      type: "selector",
      label: "Course Type (Mandatory)",
      option: [
        { value: "1", label: "Digital Content (Self Paced)" },
        { value: "2", label: "Live Online Classes + Digital Content" },
        {
          value: "3",
          label: "Physical Classes+Live Online Classes+Digital Content",
        },
        {
          value: "4",
          label: "External course",
        },
      ],
    },
    {
      column_name: "partners",
      type: "selector_multiple",
      label: "Certification Partners",
    },
  ],
  5: [
    {
      column_name: "publish",
      type: "checkbox",
      label: "Publish to dashboard",
    },

    // {
    //   column_name: "is_moodle_course",
    //   type: "checkbox",
    //   label: "is Moodle Course",
    // },
    // {
    //   column_name: "is_live_course",
    //   type: "checkbox",
    //   label: "is Course With Live Session",
    // },
  ],
  6: [
    {
      column_name: "is_taxable",
      type: "checkbox",
      label: "is Taxable",
    },
  ],

  7: [
    {
      column_name: "tax_name",
      type: "tax_select",
      label: "Select Tax",
    },
  ],

  8: [
    {
      column_name: "is_subscription",
      type: "checkbox",
      label: "is Installement",
    },
  ],

  9: [
    {
      column_name: "subscription_period",
      type: "select",
      label: "Period (Mandatory)",
    },

    {
      column_name: "subscription_interval",
      type: "select",
      label: "No. of installement (Mandatory)",
      option: [
        { value: "1", label: "1 Months" },
        { value: "2", label: "2 Months" },
        { value: "3", label: "3 Months" },
        { value: "4", label: "4 Months" },
        { value: "5", label: "5 Months" },
        { value: "6", label: "6 Months" },
        { value: "7", label: "7 Months" },
        { value: "8", label: "8 Months" },
        { value: "9", label: "9 Months" },
        { value: "10", label: "10 Months" },
        { value: "11", label: "11 Months" },
        { value: "12", label: "12 Months" },
      ],
    },
    {
      column_name: "subscription_cost",
      type: "number",
      label: "Installment Price (Mandatory)",
    },
  ],
};

export const couponCodeFields = {
  1: [
    {
      column_name: "code",
      type: "text",
      label: "Code (Mandatory)",
    },
    {
      column_name: "description",
      type: "text_area",
      label: "Description (Optional)",
    },

    {
      column_name: "start_date",
      type: "date",
      label: "Start Date (Mandatory)",
    },
    {
      column_name: "end_date",
      type: "date",
      label: "End Date",
    },
    {
      column_name: "partner_id",
      type: "selector",
      label: "Select partner (Optional)",
    },
    {
      column_name: "project_id",
      type: "selector",
      label: "Select project (Optional)",
    },
    {
      column_name: "user_limit",
      type: "number",
      label: "User Limit (Mandatory)",
    },
  ],
  2: [
    {
      column_name: "discount",
      type: "number",
      label: "Discount (%) (Optional)",
    },
    {
      column_name: "abs_discount",
      type: "number",
      label: "Discount (Number) (Optional)",
    },
  ],
  3: [
    {
      column_name: "minimum_cart_amount",
      type: "number",
      label: "Minimum cart amount (Optional)",
    },
    {
      column_name: "max_usage_per_user",
      type: "number",
      label: "Maximum Usage Per User (Optional)",
    },
  ],
  4: [
    // {
    //   column_name: "applicable_for_all_time",
    //   type: "checkbox",
    //   label: "Make it applicable all time (Optional)",
    // },

    {
      column_name: "active",
      type: "checkbox",
      label: "Active (Mandatory)",
    },
  ],
};

export const slotsFields = {
  1: [
    {
      column_name: "instructor_id",
      type: "selector",
      label: "Instructor Name",
    },
  ],
};

export const batchFields = {
  1: [
    {
      column_name: "batch_name",
      type: "text",
      label: "Batch Name (Mandatory)",
    },

    {
      column_name: "course_id",
      type: "selector",
      label: "Course Name (Mandatory)",
    },

    {
      column_name: "from_date",
      type: "datetimepicker",
      label: "Event start date (Mandatory)",
    },

    {
      column_name: "to_date",
      type: "datetimepicker",
      label: "Event end date (Optional)",
    },

    {
      column_name: "slot_start_time",
      type: "timepicker",
      label: "Slot start time",
    },

    {
      column_name: "slot_end_time",
      type: "timepicker",
      label: "Slot end time",
    },
    {
      column_name: "slots_days",
      type: "multiselector",
      label: "Select Repeat Days (Optional)",
    },

    {
      column_name: "instructor_id",
      type: "selector",
      label: "Trainer Name (Mandatory)",
    },

    {
      column_name: "min_learners",
      type: "number",
      label: "Min. No. of Learners (Optional)",
    },

    {
      column_name: "max_learners",
      type: "number",
      label: "Max. No. of Learners (Optional)",
    },

    {
      column_name: "type",
      type: "selector",
      label: "Class type",
    },

    // If class type is Offline
    {
      column_name: "location",
      type: "textarea",
      label: "Location",
    },
    {
      column_name: "pincode",
      type: "text",
      label: "Pin Code",
    },
    {
      column_name: "institute_id",
      type: "selector",
      label: "Institute",
    },
  ],

  2: [
    {
      column_name: "enable_partner",
      type: "checkbox",
      label: "Enable Partner",
    },
  ],
  3: [
    {
      column_name: "partner_id",
      type: "partnerselector",
      label: "Partner",
    },
  ],

  4: [
    {
      column_name: "project_id",
      type: "partnerselector",
      label: "Partner Project",
    },
  ],

  5: [
    {
      column_name: "is_demo",
      type: "checkbox",
      label: "Is Demo Batch",
    },
  ],
};

export const moodleConfigSchema = [
  {
    column_name: "moodle_sp_name",
    type: "text",
    label: "Moodle service provider name WSO2",
  },
  {
    column_name: "moodle_sp_client_id",
    type: "text",
    label: "Moodle client id",
  },
  {
    column_name: "moodle_sp_secret_key",
    type: "text",
    label: "Moodle Secret Key",
  },
  {
    column_name: "moodle_sp_acs_url",
    type: "text",
    label: "Moodle ACS url",
  },
  {
    column_name: "moodle_sp_redirect_url",
    type: "text",
    label: "Moodle Redirect URL",
  },
];

export const certificatesSchema = [
  {
    name: "User Name",
    selector: (row) => row.user?.name,
    sortable: true,
    maxWidth: "300px",
  },
  {
    name: "Course Name",
    selector: (row) => row.course?.full_name || "No Course",
    sortable: true,
    maxWidth: "400px",
  },
  {
    name: "Date",
    selector: (row) => convertTimeStamp(row.created_at),
    sortable: true,
    maxWidth: "150px",
  },
  {
    name: "Partner",
    selector: (row) =>
      String(
        row.user?.partner_users?.map((partner) => {
          return " " + String(partner.partner.name);
        })
      ) || "No Partner",
    sortable: true,
    maxWidth: "400px",
  },
  {
    name: "Email",
    selector: (row) => row.user?.email || "",
    omit: true,
  },
  {
    name: "Mobile Number",
    selector: (row) => row.user?.mobile_number || "",
    omit: true,
  },
  {
    name: "id",
    selector: (row) => row.id,
    omit: true,
  },
];
