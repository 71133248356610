import React, { useCallback, useRef } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { Modal } from "skillstrainer-resource-library";
import TraineeProgress from "components/TraineeList/TraineeProgress";
import EditUserModal from "./EditUserModal";
import { condObj } from "utils/func";
import { CSVLink } from "react-csv";
import _ from "lodash";
import CourseUserProgressReport from "services/coursebuild/CourseUserProgressReport";

function UsersTable(props) {
  const [reports, setReports] = useState([]);

  const {
    listingCriteria,
    users,
    loadingUsers,
    totalUsers,
    paginationInfo,
    setPaginationInfo,

    fieldsConfig,

    selectable,
    selectedUsers,
    handleUsersSelection,
    courseTopics,
    usersProgress,
  } = props;

  const { showCourses, showCoupons } = fieldsConfig;

  // Rreports
  useEffect(() => {
    if (!usersProgress) {
      return false;
    }

    const getReportFun = async () => {
      return await CourseUserProgressReport(usersProgress, courseTopics).then(
        (response) => {
          const newReportArr = response?.map(
            ({ name, id, course_id, section, Module, ...rest }) => {
              return rest;
            }
          );

          setReports(newReportArr);
        }
      );
    };

    getReportFun();

    // getting users progress reports

    //Remove Unwanted key form array of objects
  }, [courseTopics, usersProgress, users]);

  /*
   *
   *
   * User updation
   *
   *
   */
  const [userToUpdate, setUserToUpdate] = useState();

  /*
   *
   *
   * Columns
   *
   *
   */
  const columns = [
    {
      cell: (row) => (
        <button
          className="p-2"
          onClick={() => {
            setUserToUpdate(row);
          }}
        >
          <FiEdit />
        </button>
      ),
      width: "3rem",
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
    },
    {
      name: "User ID",
      selector: (row, index) => `${row.id}`,
      width: "5rem",
    },
    {
      name: "Name",
      selector: (row, index) => `${row.name}`,
      sortable: true,
      width: "13rem",
    },
    {
      name: "Email",
      selector: (row, index) => (row.email ? row.email : "NA"),
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row, index) => (row.mobile_number ? row.mobile_number : "NA"),
      sortable: true,
      width: "10rem",
    },
    ...condObj(
      showCoupons,
      [
        {
          name: "Coupons",
          selector: (row, index) => (
            <button
              onClick={() =>
                setCouponsInUse(row.coupons.map(({ coupon }) => coupon))
              }
            >
              {row.coupons.length} in use
            </button>
          ),
          width: "7rem",
        },
      ],
      []
    ),
    ...condObj(
      showCourses,
      [
        {
          name: "Progress",
          selector: (row, index) => (
            <button
              className="btn-primary"
              onClick={() => setTraineeIdForProgress(row.id)}
            >
              Show course progress
            </button>
          ),
        },
      ],
      []
    ),
    {
      name: "Created At",
      selector: (row, index) =>
        moment(row.created_at).format("YYYY-MM-DD, hh:mm"),
      sortable: true,
    },
  ];

  // Reset pagination whenever search query changes
  useEffect(() => {
    setPaginationInfo({ ...paginationInfo, page: 0 });
  }, [listingCriteria]);

  /*
   *
   *
   * Table resources
   *
   *
   */

  /*
    Issue 1:
      Whenever data table's page change button is clicked, handleSelectedRowsChange function is triggered
      with empty selection without changing the page records and THEN handlePageChange function is triggered. This causes
      the function to assume that all the selection in the current page was deselected.

      Solution:
        To prevent this, I have added a delay in the execution of handleSelectedRowsChange.
        I have created pageEventsRef where, on handlePageChange, it's hasChangedPage property is set to true.
        After the timeout in handleSelectedRowsChange, pageEventsRef.hasChangedPage is read, if it is true,
        then it means that the current invocation was a consequence of page change, in which case the rest of the
        function execution is skipped.
  */

  const pageEventsRef = useRef({}); // See issue 1

  const handlePageChange = (page) => {
    pageEventsRef.current.hasPageChanged = true; // See issue 1
    setPaginationInfo({ ...paginationInfo, page: page - 1 });
  };

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  const isUserSelected = useCallback(
    (user) => selectedUsers[user.id],
    [selectedUsers]
  );
  const handleSelectedRowsChange = useCallback(
    async ({ selectedRows }) => {
      await new Promise((res) => setTimeout(res, 100)); // See issue 1
      if (pageEventsRef.current.hasPageChanged) {
        // See issue 1
        pageEventsRef.current.hasPageChanged = false; // See issue 1
        return; // See issue 1
      } // See issue 1

      if (users && selectedUsers) {
        const selectedUsersInCurrentPage = users.filter(
          (u) => selectedUsers[u.id]
        );
        const deselectedRows = selectedUsersInCurrentPage.filter(
          (u) => !selectedRows.find((r) => r.id === u.id)
        );
        handleUsersSelection({
          deselectedUsers: deselectedRows,
          selectedUsers: selectedRows,
        });
      }
    },
    [users, selectedUsers, handleUsersSelection]
  );

  /*
   *
   *
   * Misc
   *
   *
   */
  const [couponsInUse, setCouponsInUse] = useState();
  const [traineeIdForProgress, setTraineeIdForProgress] = useState();

  return (
    <div>
      {!_.isEmpty(reports) && (
        <CSVLink
          className="no-underline bg-orange p-2 rounded-md text-white my-5 mx-2 float-right	 rounded items-center"
          filename="Users Progress Report"
          data={reports}
          target="_top"
        >
          Export Users Course Progress as CSV
        </CSVLink>
      )}
      <div className="flex-row w-full">
        <DataTable
          columns={columns}
          data={users}
          progressPending={loadingUsers}
          pagination
          paginationServer
          paginationTotalRows={totalUsers}
          paginationPerPage={100}
          paginationRowsPerPageOptions={[100, 150, 200, 250]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationResetDefaultPage={false}
          persistTableHead
          selectableRows={selectable}
          selectableRowSelected={isUserSelected}
          onSelectedRowsChange={handleSelectedRowsChange}
        />
      </div>
      <Modal isOpen={couponsInUse} onClose={() => setCouponsInUse()}>
        <div className="p-2 ">
          <div className="text-2xl font-semibold">Coupon in use</div>
          <div className="font-semibold mt-3 text-orange">
            {couponsInUse && couponsInUse.map((c) => <li>{c.code}</li>)}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={traineeIdForProgress}
        onClose={() => setTraineeIdForProgress()}
      >
        {traineeIdForProgress && (
          <TraineeProgress traineeId={traineeIdForProgress} />
        )}
      </Modal>
      <EditUserModal
        show={userToUpdate && userToUpdate.id}
        user={userToUpdate}
        handleClose={() => setUserToUpdate()}
      />
    </div>
  );
}

export default UsersTable;
