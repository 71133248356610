import { useQuery } from "@apollo/client";
import { GET_SKILLSTRAINER_LANGUAGES } from "GraphQl/Queries/CourseBuilder/Quiz";
import { useState, useEffect } from "react";
import { multiSelectSchema } from "utils/schemas";

export default function QuizDetailsFormBuilder(values) {
  const [schema, setSchema] = useState({});
  const { data: languagesData } = useQuery(GET_SKILLSTRAINER_LANGUAGES);

  useEffect(() => {
    const schema = {
      languages: {
        label: "Languages Available",
        type: "multi-select",
        schema: multiSelectSchema,
        options: (languagesData?.courses_skillstrainer_languages || []).map(
          (language) => {
            return {
              label: language.name,
              value: language.name,
            };
          }
        ),
      },
      blockNext: {
        label: "Block Next button when question is not answered",
        type: "boolean",
      },
    };

    setSchema(schema);
  }, [values, languagesData]);

  return schema;
}
