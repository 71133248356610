import React from "react";
import { api } from "../../../api/User";
import Container from "../../../components/Container/Container";
import { Form } from "skillstrainer-resource-library";
import UserFormBuilder from "formBuilders/UserFormBuilder";
import { errorHandler, successHandler } from "utils/toast";
import { client } from "App";
import { ATTACH_USERS_TO_PARTNER } from "GraphQl/Mutations/User";
import userSvc from "services/user";
import { error } from "utils/error";
import Toast from "wrappers/Toast";
import Axios from "../../../api/Api";
function CreateUsers() {
  const attachUserToPartner = async (userId, partnerId) => {
    const response = await client
      .mutate({
        mutation: ATTACH_USERS_TO_PARTNER,
        variables: {
          objects: [{ user_id: userId, partner_id: partnerId }],
        },
      })
      .then(({ data }) => data.insert_courses_partner_users.returning[0]);

    return response;
  };

  const submitUser = async (user) => {
    console.log("user==", user);
    let loader = Toast.load("Please wait...");

    const email = user.email;
    const mobile_number = user.mobile_number;

    const traineeSearchResponse = await Axios()
      .post("/search_trainees", {
        email,
        mobile_number,
      })
      .then(({ data }) => data);
    console.log("traineeSearchResponse==", traineeSearchResponse);

    const { data: duplicateTrainees } = traineeSearchResponse;
    Toast.endLoader(loader);

    if (duplicateTrainees?.length) {
      const forceCreate = await Toast.confirm(
        <DuplicateTraineesMessage duplicateTrainees={duplicateTrainees} />
      );
      console.log("forceCreate==", forceCreate);
      if (!forceCreate) return;
    }

    return api("create_user", user)
      .then((data) => {
        console.log("data", data);
        if (data?.success === false) {
          errorHandler(data?.message);
        }
        const createdUser = data?.response?.data.insert_courses_users_one;
        console.log(createdUser);
        if (!createdUser) throw error();

        attachUserToPartner(createdUser.id, user.partner_id);
        successHandler("New Trainee has been created");
      })
      .catch(async (err) => {
        console.log(err.response);
        const errorType = err.response.data.data?.type;
        if (errorType === "user-already-exists") {
          // Fetching existing user
          const users = await userSvc.getUsersWithFilter({
            _or: [
              {
                email: { _eq: user.email },
              },
              {
                mobile_number: { _eq: user.mobile_number },
              },
            ],
          });
          console.log(users);
          if (!users.length)
            errorHandler("Can't create a user with these crendentials");
          else if (users.length > 1)
            errorHandler("There are multiple users with the given credentials");
          else {
            const response = attachUserToPartner(users[0].id, user.partner_id);
            console.log(response);
            successHandler("New Trainee has been created");
          }
        } else {
          errorHandler("An error occurred while creating user");
        }
      });
  };

  return (
    <Container title="Create Trainee">
      <Form
        formBuilder={UserFormBuilder}
        className="mt-2 grid grid-cols-2 gap-x-2"
        submitButton={{
          text: "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
        }}
        onSubmit={(user) => submitUser(user)}
      />
    </Container>
  );
}

export default CreateUsers;

const DuplicateTraineesMessage = ({ duplicateTrainees }) => (
  <div>
    <p>We have found trainees with the same email / phone number:</p>
    <ul>
      {duplicateTrainees.map((dt) => (
        <li key={dt.username}>
          <span className="font-semibold">{dt.name.familyName}</span> (
          {dt.username})
        </li>
      ))}
    </ul>

    <p>Force create a new user?</p>
  </div>
);
