import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import Container from "components/Container/Container";
import QuizFilterForm from "./QuizFilterForm";
import QuizFilterTable from "./QuizFilterTable";
import { GET_QUIZ_QUESTIONS } from "GraphQl/Queries/CourseBuilder/Quiz";
import { LINK_QUESTION_TO_COURSE } from "GraphQl/Mutations/coursebuildemutation/Questions";
import { successHandler, errorHandler } from "utils/toast";

export default function CourseQuiz(props) {
  const [runQuery, availableQuestions] = useLazyQuery(GET_QUIZ_QUESTIONS, {
    fetchPolicy: "network-only", // Doesn't check cache before making a network request
  });

  const [link_questions_to_course, { data }] = useMutation(
    LINK_QUESTION_TO_COURSE,
    {
      onCompleted: () => {
        successHandler("Select Questions Added To Quiz Successfully!");
      },

      onError: () => errorHandler("An error occurred"),
    }
  );

  const [selectedValue, setSelectedValue] = useState([]);
  const [question, setQuestion] = useState({});
  const [questionids, setQuestionids] = useState([]);

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const updateValue = (e) => {
    e.preventDefault();
    const key = e.target.getAttribute("data-key");
    const value = e.target.value;

    question[key] = value;

    setQuestion(question);
  };

  useEffect(() => {
    getQuestion();
  }, []);

  const getQuestion = () => {
    const graphql_exp = {};

    runQuery({
      variables: graphql_exp,
    });
  };

  const submitFilter = (e) => {
    e.preventDefault();

    const graphql_exp = {};

    if (question.question_category && question.question_category.length > 0) {
      graphql_exp.question_category = { _eq: question.question_category };
    }
    if (question.question_type && question.question_type.length > 0) {
      graphql_exp.question_type = { _eq: question.question_type };
    }

    runQuery({
      variables: graphql_exp,
    });
  };

  const onreset = () => {
    getQuestion();
    setSelectedValue();
  };

  const handleRowSelection = (allSelected, selectedRows) => {
    const questionids = selectedRows.map((question) => question.id);

    setQuestionids(questionids);
  };

  const linkQuestions = () => {
    questionids &&
      questionids.map((id) => {
        link_questions_to_course({
          variables: {
            course_id: props.quizmap.course_id,
            question_id: id,
            module_mapping_id: props.quizmap.mapping_id,
          },
        });
      });
  };

  return (
    <Container>
      <h2 class="text-4xl text-center font-semibold leading-10 text-japanese_indigo mb-6">
        Questions Bank
      </h2>
      <QuizFilterForm
        handleChange={handleChange}
        updateValue={updateValue}
        onSubmit={submitFilter}
        onreset={onreset}
      />

      <QuizFilterTable
        selectedQuetions={props.selectedQ}
        questions={availableQuestions}
        refresh={() => getQuestion()}
        handleRowSelection={handleRowSelection}
        linkQuestions={linkQuestions}
        propsData={props.quizmap && props.quizmap.course_id ? true : false}
        message={data}
      />
    </Container>
  );
}
