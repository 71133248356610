import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_COURSE_BY_ID } from "../../../GraphQl/Queries/Courses";
import {
  UPDATE_COURSE_MUTATION,
  CREATE_COURSE_CERTIFICATION_PARTNERS,
  DELETE_COURSE_CERTIFICATION_PARTNERS,
} from "../../../GraphQl/Mutations/Courses";
import Container from "../../../components/Container/Container";
import { useLocation } from "react-router";
import queryString from "query-string";
import _ from "lodash";
import { Form } from "skillstrainer-resource-library";
import CourseFormBuilder from "formBuilders/CourseFormBuilder";
import { arrayDelta } from "utils/func";
import { errorHandler, successHandler } from "utils/toast";
import courseBuilderService from "services/coursebuild/course";
import { create_subscription_plan_api } from "api/Zoom";

function UpdateCoursePage() {
  const [subscription, setSubscription] = useState(false);

  const [course, setCourse] = useState({});

  // Fetch course by id
  const [runQuery, availableCourse] = useLazyQuery(GET_COURSE_BY_ID, {
    fetchPolicy: "network-only", // Doesn't check cache before making a network request
  });

  const [createCertificationPartners] = useMutation(
    CREATE_COURSE_CERTIFICATION_PARTNERS
  );
  const [deleteCertificationPartners] = useMutation(
    DELETE_COURSE_CERTIFICATION_PARTNERS
  );

  const [update_course, mutatioData] = useMutation(UPDATE_COURSE_MUTATION, {
    onCompleted: () => successHandler("Course Successfully Updated"),
    onError: (err) =>
      errorHandler("An error occured while updating course") ||
      console.error(err),
  });

  async function createPlan() {
    if (mutatioData.data?.update_courses_course && course["is_subscription"]) {
      const course_id = mutatioData.data?.update_courses_course.returning[0].id;
      console.log("mutatioData", course_id);
      var flage = false;

      if (course.course_subscription_relation_array[0]) {
        flage = true;
      } else {
        flage = true;
      }

      if (flage) {
        const confirmBox = window.confirm(
          "Do you really want to Update a plan?"
        );
        if (confirmBox === true) {
          if (course.course_subscription_relation_array[0]) {
            await courseBuilderService.updateCourseSubscription(course_id);
          }
          const result = await create_subscription_plan_api(course);
          console.log("plan", result);
          const plans = {
            course_id: course_id,
            interval: course["subscription_interval"],
            period: result.data.period,
            razorpay_plan_id: result.data.id,
            razorpay_plan_creation_date: result.data.created_at,
            subscription_cost: course["subscription_cost"],
          };
          await courseBuilderService.CreateCoursePlan(plans);
        }
      }
    }
  }

  useEffect(() => {
    createPlan();
  }, [mutatioData.data?.update_courses_course]);
  // Set current course

  // Target course is identified by query parameters
  const location = useLocation();
  const params = queryString.parse(location.search);

  // Dispatching a request for fetching target course
  useEffect(() => {
    if (params.id) {
      if (Object.keys(course).length === 0) {
        runQuery({
          variables: {
            id: params.id,
          },
        });
      } else {
        console.log("Course is updated");
      }
    }
  }, []);

  // On resolution of request for fetching target course
  useEffect(() => {
    if (availableCourse.data?.courses_course) {
      if (
        availableCourse.data.courses_course.length > 0 &&
        Object.keys(course).length == 0
      ) {
        const course = { ...availableCourse.data.courses_course[0] };

        const {
          video_url,
          is_subscription,
          is_taxable,
          course_subscription_relation_array,
          image_url,
        } = course;

        if (is_subscription) {
          setSubscription(is_subscription);
          course.subscription_interval =
            course_subscription_relation_array[0]?.interval;
          course.subscription_period =
            course_subscription_relation_array[0]?.period;
          course.subscription_cost =
            course_subscription_relation_array[0]?.subscription_cost;
        }

        const certificationPartners = course?.partners.map((partner) => {
          return partner?.partner_id;
        });

        const createCourse_object = {
          ...course,
          image_url: [
            {
              name: course.image_url,
              url: course.image_url,
            },
          ],
          certificate_image_url: [
            {
              name: course.certificate_image_url,
              url: course.certificate_image_url,
            },
          ],
          partners: certificationPartners,
        };
        setCourse(createCourse_object);
      }
    }
  }, [availableCourse]);

  const updateCourse = async (updateData) => {
    const updateCourseObject = {
      ...updateData,
      id: params.id,
      image_url: updateData?.image_url[0]?.url,
      certificate_image_url: updateData?.certificate_image_url[0]?.url,
      course_subscription_relation_array:
        course.course_subscription_relation_array,
    };

    if (updateCourseObject.discount === "") {
      updateCourseObject.discount = null;
    }

    updateCourseObject["identifier"] = updateCourseObject.full_name
      .toLowerCase()
      .replaceAll(" ", "-");

    const certificationPartners = (updateCourseObject.partners || []).map(
      (partner_id) => {
        return {
          partner_id: partner_id,
          course_id: params.id,
        };
      }
    );

    const updatedCertificationPartners = updateCourseObject.partners;

    updateCourseObject.partners = undefined;

    const { created, persistant, deleted } = arrayDelta(
      availableCourse?.data?.courses_course[0].partners,
      certificationPartners,
      ["partner_id"]
    );

    createCertificationPartners({
      variables: {
        certificationPartners: created,
      },
    }).catch((err) => {
      errorHandler("Error while creating certification partners");
      console.log(err);
    });

    deleteCertificationPartners({
      variables: {
        idArray: deleted.map((deletedPartner) => {
          return deletedPartner.id;
        }),
      },
    }).catch((err) => {
      errorHandler("Error while deleting certification partners");
      console.log(err);
    });

    update_course({
      variables: updateCourseObject,
    });
    setCourse({
      ...updateCourseObject,
      partners: updatedCertificationPartners,
    });
  };

  return (
    <Container title={"Create / Update Course"}>
      {/* <h2 className="text-3xl font-bold px-4">Update Course</h2> */}
      <Form
        formBuilder={CourseFormBuilder}
        className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
        submitButton={{
          text: "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
        }}
        onSubmit={updateCourse}
        key={course?.id}
        initValues={course || {}}
      />
    </Container>
  );
}

export default UpdateCoursePage;
