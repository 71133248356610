import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { changeNameOnDownload } from "utils/func";

const InvoicesTable = ({ data, loading, schema }) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let table_columns = schema;

    table_columns.push({
      cell: (row) => (
        <a
          onClick={() =>
            changeNameOnDownload(
              row.invoice,
              `${row.user.name.replace(" ", "-")}-${row.user.id}`
            )
          }
          target={"_blank"}
          className="btn-primary no-underline hover:text-white hover:bg-opacity-90 cursor-pointer"
        >
          Download
        </a>
      ),
      width: "150px",
      ignoreRowClick: true,
      button: true,
    });

    setColumns(table_columns);
  }, []);

  return (
    <DataTable
      columns={columns}
      data={
        data
          ? data.courses_user_course_order
              .map((order) => {
                return {
                  ...order,
                  key: order.id,
                };
              })
              .filter((order) => order.invoice)
          : []
      }
      progressPending={loading}
      pagination
      paginationTotalRows={data ? data.courses_user_course_order.length : 0}
    />
  );
};

export default InvoicesTable;
