export const ActivityDbSchema = {
  1: [
    {
      column_name: "name",
      type: "text",
      label: "Module Name",
      required: "yes",
    },

    {
      column_name: "description",
      type: "text_area",
      label: "Description",
      required: "yes",
    },
  ],

  2: [
    {
      column_name: "scorm_url",
      type: "file",
      label: "Upload Scorm Package",
      required: "yes",
    },

    {
      column_name: "scorm_version",
      type: "select",
      label: "Select Scorm Version",
      required: "yes",
    },
  ],

  3: [
    {
      column_name: "completion_criteria",
      type: "select",
      label: "Completion Criteria",
      required: "yes",
    },
    {
      label_text: "Require minimum score",
      column_name: "enable_min_score",
      type: "checkbox",
      label: "Enable",
      required: "no",
    },
    {
      column_name: "min_score",
      type: "number",
      label: "Enter Min Score",
      required: "yes",
    },

    {
      label_text: "Require status",
      column_name: "passed",
      type: "checkbox",
      label: "Passed",
      required: "yes",
    },
    {
      column_name: "completed",
      type: "checkbox",
      label: "Complete",
      required: "yes",
    },
    {
      column_name: "enable_user_attempt",
      type: "checkbox",
      label: "Enable user track report ?",
      required: "no",
    },
  ],

  4: [
    {
      column_name: "enable_restriction",
      type: "checkbox",
      label: "Enable restriction ?",
    },

    {
      label_text: "Activity Completion",
      column_name: "restriction_modules",
      type: "multipleselection",
      label: "Select topis",
    },

    {
      label_text: "Department or Batch Id",
      column_name: "batch_id",
      type: "number",
      label: "",
    },

    {
      label_text: "Required Webcam?",
      column_name: "require_webcam",
      type: "checkbox",
      label: "Required Webcam?",
    },
  ],

  5: [
    {
      column_name: "maxattempt",
      type: "select",
      label: "Number of attempts",
      options: [
        { value: "0", label: "Unlimited" },
        { value: "1", label: "1 Attempt" },
        { value: "2", label: "2 Attempt" },
        { value: "3", label: "3 Attempt" },
        { value: "4", label: "4 Attempt" },
      ],
      required: "yes",
    },

    {
      column_name: "attemtsgrade",
      type: "select",
      label: "Attempts grading",
      options: [
        { value: "0", label: "Highest Attempt " },
        { value: "1", label: "First Attempt" },
        { value: "2", label: "Last Completed Attempt" },
        { value: "3", label: "Average Attempt" },
      ],
      required: "yes",
    },
  ],
};

export const CertficateActivityDbSchema = {
  1: [
    {
      column_name: "name",
      type: "text",
      label: "Module Name",
    },

    {
      column_name: "description",
      type: "text_area",
      label: "Description",
    },
  ],

  2: [
    {
      column_name: "enable_restriction",
      type: "checkbox",
      label: "Enable restriction ?",
    },

    {
      column_name: "restriction_modules",
      type: "multipleselection",
      label: "Select topis",
    },
  ],
};

export const QuizFilterDbSchema = [
  {
    column_name: "question_category",
    type: "select",
    label: "Question Category",
  },

  {
    column_name: "tag_id",
    type: "multiselect",
    label: "Tags",
  },

  {
    column_name: "question_type",
    type: "select",
    label: "Question Type",
  },
];

export const QuizFormDbSchema = {
  1: [
    {
      column_name: "name",
      type: "text",
      label: "Quiz Name",
    },

    {
      column_name: "description",
      type: "textarea",
      label: "Quiz Description",
    },
  ],

  2: [
    {
      column_name: "completion_criteria",
      type: "select",
      label: "Completion Criteria",
      options: [
        {
          value: "0",
          label: "Students can manually mark the activity as completed",
        },
        {
          value: "1",
          label: "Show activity as complete when condition are meet ",
        },
      ],
    },

    {
      column_name: "completion_percentage",
      type: "text",
      label: "Enter completion Percentage",
    },
    {
      column_name: "enable_user_attempt",
      type: "checkbox",
      label: "Enable user track report?",
      required: "no",
    },
  ],

  3: [
    {
      column_name: "require_webcam",
      type: "checkbox",
      label: "Webcam Required?",
    },
    {
      column_name: "enable_restriction",
      type: "checkbox",
      label: "Enable restriction ?",
    },

    {
      column_name: "restriction_modules",
      type: "multipleselection",
      label: "Select topis",
    },
  ],
  4: [
    {
      column_name: "maxattempt",
      type: "select",
      label: "Number of attempts",
      options: [
        { value: "0", label: "Unlimited" },
        { value: "1", label: "1 Attempt" },
        { value: "2", label: "2 Attempts" },
        { value: "3", label: "3 Attempts" },
        { value: "4", label: "4 Attempts" },
      ],
    },

    {
      column_name: "attemtsgrade",
      type: "select",
      label: "Attempts grading",
      options: [
        { value: "0", label: "Highest Attempt " },
        { value: "1", label: "First Attempt" },
        { value: "2", label: "Last completed Attempt" },
        { value: "3", label: "Average Attempt" },
      ],
    },
  ],

  5: [
    {
      column_name: "enable_timer",
      type: "checkbox",
      label: "Enable Quiz Timer ?",
    },
    {
      column_name: "timelimit",
      type: "text",
      label: "",
    },
    {
      column_name: "timelimit_unit",
      type: "select",
      label: "",
      options: [
        { value: "3600", label: "Hours" },
        { value: "60", label: "Minutes" },
        { value: "1", label: "Seconds" },
      ],
    },
  ],
};
export const ManageCertificateSchema = {
  1: [
    {
      column_name: "Select Partner",
      type: "checkbox",
      label: "Select Partner ?",
    },

    {
      column_name: "partner_name",
      type: "select",
      label: "Select Partner",
    },

    {
      column_name: "project_name",
      type: "select",
      label: "Select Partner Project",
    },
    // {
    // 	column_name: 'available Grades',
    // 	type: 'select',
    // 	label: 'Select Grades',
    // },
  ],
  2: [
    {
      column_name: "User ",
      type: "text",
      label: "User ",
    },
    {
      column_name: "X",
      type: "text",
      label: "x-axis",
    },
    {
      column_name: "Y",
      type: "text",
      label: "y-axis",
    },
  ],
  3: [
    {
      column_name: "Course ",
      type: "text",
      label: "Course ",
    },
    {
      column_name: "X",
      type: "text",
      label: "x-axis",
    },
    {
      column_name: "Y",
      type: "text",
      label: "y-axis",
    },
  ],
};

export const ResourceDbSchema = {
  1: [
    {
      column_name: "name",
      type: "text",
      label: "Module Name",
      required: "yes",
    },

    {
      column_name: "description",
      type: "text_area",
      label: "Description",
      required: "yes",
    },
  ],

  2: [
    {
      column_name: "upload_url",
      type: "file",
      label: "Upload Files",
      required: "yes",
    },

    {
      column_name: "completion_criteria",
      type: "select",
      label: "Completion Criteria",
      options: [
        {
          value: 0,
          label: "Students can manually mark the activity as completed",
        },

        { value: 2, label: "Mark activity complete on view" },
      ],
    },
  ],

  3: [
    {
      column_name: "enable_restriction",
      type: "checkbox",
      label: "Enable restriction ?",
    },

    {
      label_text: "Activity Completion",
      column_name: "restriction_modules",
      type: "multipleselection",
      label: "Select topis",
    },
  ],
};
