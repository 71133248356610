import api from "./Api";

export const uploadFile = async (
    file_data,
    file_name,
    ops_type,
    isNotBase64
  ) => {
    if (isNotBase64) {
      file_data = await new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onloadend = () => res(reader.result);
        reader.onerror = () => rej(reader.error);
        reader.readAsDataURL(file_data);
      });
    }
    console.log("File data", file_data);
    const data = { file_data, file_name, ops_type };
    const response = await api().post("/upload_file", data);
  
    return response.data;
  };
  
  export const getFile = async (file_url) => {
    const data = { data_url: file_url };
    const response = await api().post("/get_file", data);
    return response.data;
  };