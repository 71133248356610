import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_SCHOLARSHIP_BY_ID } from "../../../../GraphQl/Queries/Scholarships";
import { Form } from "skillstrainer-resource-library";
import Container from "../../../../components/Container/Container";
import { UPDATE_SCHOLARSHIP_BY_ID } from "../../../../GraphQl/Mutations/Scholarships";
import ScholarshipFormBuilder from "formBuilders/ScholarshipFormBuilder";
import { successHandler, errorHandler } from "utils/toast";
import CouponCodesTable from "Pages/Dashboard/Courses/components/CouponCodesTable";

const EditScholarship = (props) => {
  const { data, loading } = useQuery(GET_SCHOLARSHIP_BY_ID, {
    variables: { _id: props.scholarshipId },
  });
  const [updateScholarship, updatedScholarshipData] = useMutation(
    UPDATE_SCHOLARSHIP_BY_ID
  );

  const submitScholarship = async (scholarship) => {
    console.log(scholarship);
    await updateScholarship({
      variables: { _id: props.scholarshipId, _set: scholarship },
    })
      .then(() => {
        successHandler("Scholarship Updated Successfully");
        props.close();
      })
      .catch((err) => {
        console.log(err);
        errorHandler("There was a problem while updating Scholarship");
      });
  };
  return (
    <Container title={"Edit Scholarship"}>
      {data && (
        <Form
          formBuilder={ScholarshipFormBuilder}
          initValues={data.courses_scholarships[0]}
          className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className:
              "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6 py-2",
          }}
          onSubmit={(scholarship) => submitScholarship(scholarship)}
        />
      )}
    </Container>
  );
};

export default EditScholarship;
