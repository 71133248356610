import { gql } from "@apollo/client";

export const GET_PARTNER_AND_INSTRUCTOR = gql`
  query fetchCourses {
    courses_instructor {
      id
      name
    }
    courses_partner {
      name
      id
      projects {
        id
        name
      }
      coupons {
        id
        code
      }
    }
    courses_st_coupons_configuration {
      id
      code
    }
  }
`;

export const GET_USERS_QUERY = gql`
  query getUsers {
    courses_users {
      activation_end_date
      activation_start_date
      active
      created_at
      email
      idp_user_id
      instructor_id
      id
      mobile_number
      name
      enrollments {
        course_id
      }
      partner {
        id
        name
      }
      user_instructor {
        id
        name
      }
      partner_id
    }
  }
`;

export const GET_USERS_WITH_FILTER = gql`
  query getUsersWithFilter($filterClause: courses_users_bool_exp!) {
    courses_users(where: $filterClause) {
      activation_end_date
      activation_start_date
      active
      created_at
      email
      idp_user_id
      instructor_id
      id
      mobile_number
      name
      partner_id
    }
  }
`;

export const GET_USERS_QUERY_PAGINATION = gql`
  query getUsers($per_page: Int, $page: Int) {
    courses_users(limit: $per_page, offset: $page) {
      activation_end_date
      activation_start_date
      active
      created_at
      email
      idp_user_id
      instructor_id
      id
      mobile_number
      name
      partner {
        id
        name
      }
      user_instructor {
        id
        name
      }
      partner_id
    }
  }
`;

export const GET_USERS_BY_PARTNER_ID = gql`
  query getUsersByPartnerId($partner_id: bigint!, $per_page: Int, $page: Int) {
    courses_users(
      limit: $per_page
      offset: $page
      where: { partner_id: { _eq: $partner_id } }
    ) {
      name
      id
      email
      partner {
        name
        id
      }
      user_instructor {
        id
        name
      }
    }
  }
`;

export const GET_USERS_BY_INSTRUCTOR_ID = gql`
  query getUsersByPartnerId(
    $per_page: Int
    $page: Int
    $instructor_id: bigint!
  ) {
    courses_users(
      limit: $per_page
      page: $page
      where: { instructor_id: { _eq: $instructor_id } }
    ) {
      name
      id
      email
      partner {
        name
        id
      }
      user_instructor {
        id
        name
      }
    }
  }
`;
export const Get_USRS_BY_PARNTER_AND_INSTRUCTOR = gql`
  query getUsersByPartnerId(
    $per_page: Int
    $page: Int
    $instructor_id: bigint
    $partner_id: bigint!
  ) {
    courses_users(
      limit: $per_page
      offset: $page
      where: {
        instructor_id: { _eq: $instructor_id }
        partner_id: { _eq: $partner_id }
      }
    ) {
      name
      id
      email
      partner {
        name
        id
      }
      user_instructor {
        id
        name
      }
    }
  }
`;
export const GET_USER_BY_ID = gql`
  query getUsersById($id: bigint) {
    courses_users(where: { id: { _eq: $id } }) {
      activation_end_date
      activation_start_date
      active
      email
      idp_user_id
      instructor_id
      id
      mobile_number
      name
      is_email_valid
      partner {
        id
        name
      }
      user_instructor {
        id
        name
      }
      partner_id
    }
  }
`;
export const GET_NUMBER_OF_USERS = gql`
  query getTotalUsers {
    courses_users_aggregate {
      aggregate {
        count(columns: source)
      }
    }
  }
`;

export const GET_TOTAL_NMBER_OF_USERS = gql`
  query MyQuery {
    courses_users_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_NUMBER_OF_USERS_DISTICT_BY_SOURCE = gql`
  query MyQuery {
    users_count_by_source {
      count
      source
    }
  }
`;

export const GET_USERS_PAGINATION = gql`
  query MyQuery(
    $userSearchCriteria: courses_users_bool_exp = {}
    $limit: Int
    $offset: Int
  ) {
    courses_users(
      where: $userSearchCriteria
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      email
      id
      name
      mobile_number
      source
      partner_id
      partner {
        name
      }
      partner_users {
        partner {
          name
          id
        }
      }
      created_at
      coupons: st_user_coupons {
        coupon: st_coupons_configuration {
          id
          code
        }
      }
    }
  }
`;

export const GET_PARTNER_USERS_PAGINATION = gql`
  query GetPartnerUsersPagination(
    $relationsSearchCriteria: courses_partner_users_bool_exp
    $page: Int
    $per_page: Int
  ) {
    courses_partner_users(
      where: $relationsSearchCriteria
      distinct_on: user_id
      offset: $page
      limit: $per_page
      order_by: [{ user_id: desc }, { created_at: desc }]
    ) {
      user {
        email
        id
        name
        mobile_number
        source
        partner {
          name
        }
        created_at
        coupons: st_user_coupons {
          coupon: st_coupons_configuration {
            id
            code
          }
        }
      }
    }
  }
`;

export const GET_TOTAL_USERS = gql`
  query GetTotalUsers($userSearchCriteria: courses_users_bool_exp = {}) {
    courses_users_aggregate(where: $userSearchCriteria) {
      aggregate {
        count
      }
    }
  }
`;

// Make it consistent with partner_pagination query
export const GET_TOTAL_PARTNER_USERS = gql`
  query GetTotalPartnerUsers(
    $relationsSearchCriteria: courses_partner_users_bool_exp = {}
  ) {
    courses_partner_users_aggregate(where: { user: $relationsSearchCriteria }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_MOODLE_USERS_COUNT = gql`
  query getMoodleUserSTats {
    courses_moodle_user_stats {
      id
      moodle_name
      total_users
      created_at
      course_stats {
        course_name
        moodle_course_id
        moodle_name
        moodle_user_stats_id
        total_users
      }
    }
  }
`;

export const GET_USER_IDS_BY_EMAILS = gql`
  query getUserIds($emails: [String]) {
    courses_users(where: { email: { _in: $emails } }) {
      id
    }
  }
`;

export const GET_USER_COURSES = gql`
  query MyQuery($userId: bigint) {
    courses_users(where: { id: { _eq: $userId } }) {
      enrollments {
        course_array {
          full_name
          id
          is_moodle_course
          moodle_course_id
        }
      }
    }
  }
`;

export const GET_USER_COURSE_PROGRESS = gql`
  query getUserCourseProgress(
    $courseIdArrayBig: [bigint!]!
    $userIdBig: bigint
    $courseIdArray: [Int!]!
    $userId: Int
  ) {
    courses_course_completion_criteria(
      where: { course_id: { _in: $courseIdArray } }
    ) {
      course_id
      id
      mapping_id
    }

    courses_user_course_complete(
      where: {
        _and: [
          { course_id: { _in: $courseIdArray } }
          { user_id: { _eq: $userId } }
        ]
      }
    ) {
      id
      course_id
      user_id
      percentage
    }

    courses_course_module_completion(
      where: {
        _and: [
          { course_id: { _in: $courseIdArray } }
          { user_id: { _eq: $userId } }
        ]
      }
    ) {
      course_id
      id
      mapping_id
      section_id
      mod_id
      section_completion_obj {
        name
      }
    }

    courses_user_course_quiz_attempt(
      where: {
        _and: [
          { course_id: { _in: $courseIdArray } }
          { user_id: { _eq: $userId } }
        ]
      }
      order_by: { mapping_id: asc }
    ) {
      id
      mapping_id
      course_id
      user_id
      attempt_percentage
      max_marks
      total_score
    }

    courses_certificates(
      where: {
        _and: [
          { course_id: { _in: $courseIdArrayBig } }
          { user_id: { _eq: $userIdBig } }
        ]
      }
    ) {
      course_id
      user_id
      certificate_pdf_url
    }
  }
`;

export const GET_USERS_COUNT_BY_DATE = gql`
  query q4(
    $startDate: timestamptz!
    $endDate: timestamptz!
    $course_ids: [Int!]
    $project_id: bigint
  ) {
    courses_users(
      where: {
        enrollments: {
          course_id: { _in: $course_ids }
          course_complete_obj: {}
        }
        batch_enrollments: { batch: { project_id: { _eq: $project_id } } }
        quiz_attempts: { updated_at: { _gt: $startDate, _lt: $endDate } }
      }
    ) {
      quiz_attempts {
        created_at
      }
      batch_enrollments(
        where: { batch: { project_id: { _eq: $project_id } } }
      ) {
        batch {
          institute {
            id
            name
            district
          }
        }
      }
    }
  }
`;
