import React from "react";
import { Switch, Route } from "react-router-dom";
import LoginPage from "../Pages/Auth/LoginPage";
import LogoutPage from "Pages/Dashboard/LogoutPage";
import DashboardRoutes from "./Dashboard/DashboardRoutes";
import { authRecoilVar } from "State/User";
import { useRecoilState } from "recoil";

const Routes = () => {
  const [authenticated] = useRecoilState(authRecoilVar);

  return (
    <Switch>
      <Route path="/logout" component={LogoutPage} />
      <Route
        path="/"
        component={authenticated ? DashboardRoutes : () => <LoginPage />}
      />
    </Switch>
  );
};

export default Routes;
