import { useEffect, useState } from "react";
import * as yup from "yup";
import { fileSchema } from "utils/schemas";
import {
  GET_EMAIL_TEMPLATE_BY_ID,
  GET_EMAIL_TEMPLATES_NAMES,
} from "GraphQl/Queries/EmailTemplate";
import { useQuery } from "@apollo/client";

function BulkNotificationsFormBuilder(values) {
  const { data: fetchEmailTempNames } = useQuery(GET_EMAIL_TEMPLATES_NAMES);

  const [schema, setSchema] = useState({});
  useEffect(() => {
    const schema = {
      batch_name: {
        type: "text",
        label: "Batch Name",
        required: true,
      },  
      type: {
        type: "select",
        label: "Notification Type",
        required: true,
        options: [
          {
            value: "email",
            label: "Email",
          },
          {
            value: "sms",
            label: "SMS",
          },
        ],
      },
      file_url: {
        type: "file",
        label: "Upload CSV",
        required: true,
        schema: fileSchema,
      },
      additional_data: {
        type: "select",
        label: "Template Type",
        required: true,
        options: fetchEmailTempNames?.courses_notification_event_types.map(
          (course) => ({
            value: course.name,
            label: course.name,
          })
        ),
      },
      notes: {
        type: "text",
        label: "Notes",
      },
    };

    setSchema(schema);
  }, [values, fetchEmailTempNames]);

  return schema;
}

export default BulkNotificationsFormBuilder;
