import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import Select from "components/InputGroup/Select";
import CourseQuiz from "./CourseQuiz";
import CreateQuestion from "Pages/Dashboard/Questions/CreateQuestion";
import { Container } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GET_COURSE_LESSON_MODULE } from "GraphQl/Queries/CourseBuilder/section";
import { useHistory } from "react-router-dom";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";
import { QuizDetails } from "./QuizDetails";

export default function QuizAction() {
  const location = useLocation();

  const params = queryString.parse(location.search);
  let history = useHistory();

  const {
    loading,
    error,
    data: quizQuestions,
  } = useQuery(GET_COURSE_LESSON_MODULE, {
    variables: { course_id: params.id, id: params.quizid },
    fetchPolicy: "network-only",
  });

  const [quizData, SetQuizData] = useState("");
  const [questionMod, SetQuestionMod] = useState("");
  const [quizDetailsModalShow, setQuizDetailsModalShow] = useState(false);
  const [selectedQuestions, SetSelectedQuestions] = useState([]);

  useEffect(() => {
    if (quizQuestions && quizQuestions.courses_course_module_mapping[0]) {
      const filterArray =
        quizQuestions.courses_course_module_mapping[0]?.course_question_link.map(
          ({ question_id }) => question_id
        );

      SetSelectedQuestions([...filterArray]);

      SetQuizData({
        count:
          quizQuestions.courses_course_module_mapping[0]?.course_question_link
            .length,
        quizname: quizQuestions.courses_course_module_mapping[0]?.name,
        course_id: params.id,
        mapping_id: params.quizid,
      });
    }
  }, [quizQuestions]);

  const updateValue = (e) => {
    const key = e.target.getAttribute("data-key");
    const value = e.target.value;

    SetQuestionMod(value);
  };

  const questionType = [
    { value: "add_new", label: "Add New Questions" },
    { value: "question_bank", label: "Add From Question Bank" },
  ];
  return (
    <Container>
      <div className="flex justify-between">
        <button
          className="inline-flex justify-center py-2 px-4 
border border-transparent shadow-sm text-sm font-medium 
rounded-md text-white bg-orange mb-5"
          onClick={() => history.goBack()}
        >
          Go To Back
        </button>

        <button
          className="inline-flex justify-center py-2 px-4 
border border-transparent shadow-sm text-sm font-medium 
rounded-md text-white bg-orange mb-5"
          onClick={() => {
            let path =
              getModulePathStringById(ModuleNameIdMap.viewModuleQuestions) +
              `/?id=${params.id}&&quizid=${params.quizid}`;
            history.push(path);
          }}
        >
          View Questions
        </button>
      </div>
      <div className="w-full text-center">
        <h2 className="text-4xl text-center font-semibold leading-10 text-japanese_indigo mb-6">
          Topic Name
        </h2>

        <div className="text-3xl mb-6">
          <span className="font-medium mr-3 text-japanese_indigo">
            Quiz Name:{" "}
          </span>
          <span>{quizData.quizname}</span>
        </div>
        <div className="text-3xl mb-6">
          <span className="font-medium mr-3 text-japanese_indigo">
            Number Of Questions Added:
          </span>
          <span>{quizData.count}</span>
        </div>

        <div className="flex items-center justify-center">
          <Select
            label="Add Questions From"
            options={questionType}
            valueField={"value"}
            displayField={"label"}
            data-key="question_type"
            onChange={(e) => updateValue(e)}
          />
          <button
            className="btn-secondary mx-4 mt-4 hover:opacity-80"
            onClick={() => setQuizDetailsModalShow(true)}
          >
            QUIZ DETAILS
          </button>
        </div>
      </div>

      <QuizDetails
        quizId={params?.quizid}
        show={quizDetailsModalShow}
        handleClose={() => setQuizDetailsModalShow(false)}
      />

      {questionMod == "question_bank" ? (
        <CourseQuiz quizmap={quizData} selectedQ={selectedQuestions} />
      ) : questionMod == "add_new" ? (
        <CreateQuestion quizmap={quizData} />
      ) : (
        ""
      )}
    </Container>
  );
}
