import React, { useEffect, useState } from "react";
import Container from "components/Container/Container";
import McqQuiz from "../Courses/components/Quiz/McqQuiz";
import Select from "components/InputGroup/Select";
import ReactSelect, { components } from "react-select";
import { GET_QUESTION_CATEGORY } from "GraphQl/Queries/CourseBuilder/QuestionsCategory";
import { useQuery, useMutation } from "@apollo/client";
import { GET_QUESTION_TAGS } from "GraphQl/Queries/CourseBuilder/QuestionsCategory";
import { INSERT_QUESTION_TAG } from "GraphQl/Mutations/coursebuildemutation/Questions";
import PictureMcqQuiz from "../Courses/components/Quiz/PictureMcqQuiz";
export default function CreateQuestion(props) {
  const questionCategories = useQuery(GET_QUESTION_CATEGORY);
  const questionTagsQuery = useQuery(GET_QUESTION_TAGS);
  const [insert_question_tag] = useMutation(INSERT_QUESTION_TAG, {
    refetchQueries: [GET_QUESTION_TAGS],
  });
  const [category, setCategory] = useState([]);
  const [question, setQuestion] = useState({});
  const [selectedValue, setSelectedValue] = useState([]);
  const [questionTags, setQuestionTags] = useState([]);
  // const filterOption = createFilter({});

  useEffect(() => {
    if (questionCategories.data) {
      const categoryOptions =
        questionCategories.data?.courses_question_category.map((inst, i) => {
          return { value: inst.id, label: inst.name };
        });
      setCategory(categoryOptions);
    }
  }, [questionCategories]);

  useEffect(() => {
    if (questionTagsQuery.data) {
      const tagOptions = questionTagsQuery.data?.courses_question_tags.map(
        (inst, i) => {
          return { value: inst.id, label: inst.name };
        }
      );
      setQuestionTags(tagOptions);
    }
  }, [questionTagsQuery]);

  const updateValue = (e) => {
    e.preventDefault();
    const key = e.target.getAttribute("data-key");
    const value = e.target.value;
    // console.log("key",key, " value",value)
    // if(key==='question_type' && value===1 ){
    //   setMcq(true);
    // }
    //   setUser({ ...user, [e.target.getAttribute("data-key")]: e.target.checked });

    question[key] = value;
    console.log("question", question);

    setQuestion({
      ...question,
      [e.target.getAttribute("data-key")]: e.target.value,
    });
  };

  const qeustionType = [
    { value: "1", label: "Multiple Choice Question" },
    { value: "2", label: "Picture/Text Multiple Choice option" },
    { value: "3", label: "Drag and Drop" },
  ];

  const levelType = [
    { value: 1, label: "Easy" },
    { value: 2, label: "Meduim" },
    { value: 3, label: "Difficult" },
  ];

  const [tag, setTag] = useState("");

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const addnew = (e) => {
    const otherOption = {
      value: tag,
      label: tag,
    };

    setQuestionTags((questionTags) => [...questionTags, otherOption]);

    insert_question_tag({
      variables: { name: tag },
    });
  };

  const SelectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <button onClick={addnew}>Add new tag</button>
      </components.MenuList>
    );
  };

  const filterAllOptions = (rawInput) => {
    setTag(rawInput);
  };

  return (
    <Container>
      <h2 className="text-4xl text-center font-semibold leading-10 text-japanese_indigo mb-6">
        Add New Question
      </h2>
      <div className="flex flex-wrap space-x-6 w-full">
        <Select
          label="Category"
          options={category ? category : []}
          valueField={"value"}
          displayField={"label"}
          data-key="question_category"
          onChange={(e) => updateValue(e)}
        />

        <label className="block text-sm font-medium text-gray-700">
          Select Tags
          <ReactSelect
            options={questionTags ? questionTags : []}
            isMulti
            className="pt-1"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            onChange={handleChange}
            onInputChange={(e) => filterAllOptions(e)}
            components={{ MenuList: SelectMenuButton }}
          />
        </label>

        <Select
          label="Level"
          options={levelType}
          valueField={"value"}
          displayField={"label"}
          data-key="question_level"
          onChange={(e) => updateValue(e)}
        />

        <Select
          label="Question Type"
          options={qeustionType}
          valueField={"value"}
          displayField={"label"}
          data-key="question_type"
          onChange={(e) => updateValue(e)}
        />
      </div>

      {question.question_type == 1 ? (
        <McqQuiz
          selectedTags={selectedValue}
          questionData={question}
          propsData={props}
        />
      ) : question.question_type == 2 ? (
        <PictureMcqQuiz
          selectedTags={selectedValue}
          questionData={question}
          propsData={props}
        />
      ) : (
        ""
      )}
    </Container>
  );
}
