import React, { useEffect, useState } from "react";
import { errorSelector, useSetRecoilState } from "recoil";
import { authRecoilVar } from "State/User";
import { LoginUser } from "../../api/Auth";
import { LogoBlue } from "../../assets";
import { errorHandler, successHandler } from "utils/toast";
import ResetPassword from "./ResetPassword";
import usePromise from "utils/hooks/usePromise";
import Loader from "assets/animated-images/loader";

const LoginPage = () => {
  const [inputData, setInputData] = useState({
    email: " ",
    password: " ",
  });
  const [loading, setLoading] = useState(false);

  const [openResetPassword, setOpenResetPassword] = useState(false);

  const [errors, setErrors] = useState("");
  const handleChange = ({ target: { id, value } }) => {
    setInputData({ ...inputData, [id]: value });
  };

  const setAuth = useSetRecoilState(authRecoilVar);

  const { run: handleSubmit, loading: submittingForm } = usePromise(
    async (e) => {
      e.preventDefault();

      if (!inputData.email || !inputData.password)
        return setErrors("field cannot be empty");

      const LoginResponse = await LoginUser(
        inputData.email,
        inputData.password
      );
      if (!LoginResponse.success)
        return setErrors(LoginResponse.message || "An error occurred");
      else setAuth(LoginResponse);
    }
  );

  // Opening password reset modal
  const openPasswordResetModal = () => {
    setOpenResetPassword(true);
  };

  const closePasswordResetModal = () => {
    setOpenResetPassword(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
        <img className="mx-auto w-48 my-4" alt="SkillsTrainer" src={LogoBlue} />
        <div className="font-semibold self-center text-xl sm:text-2xl uppercase">
          ADMIN PORTAL LOGIN
        </div>

        <div className="mt-10">
          <form
            className="w-full my-auto"
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <div className="flex flex-col mb-6">
              <label
                htmlFor="email"
                className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
              >
                E-Mail Address:
              </label>
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                  </svg>
                </div>
                <input
                  id="email"
                  type="email"
                  name="email"
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  placeholder="E-Mail Address"
                />
              </div>
            </div>

            <div className="flex flex-col mb-6">
              <label
                htmlFor="password"
                className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
              >
                Password:
              </label>
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </span>
                </div>
                <input
                  id="password"
                  type="password"
                  name="password"
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  placeholder="Password"
                />
              </div>
            </div>
            <div className="flex flex-row w-full ">
              <div className="flex-col self-end mb-2 text-right items-end w-full place-items-end">
                <button
                  type="button"
                  className="text-blue-600 underline cursor-pointer"
                  onClick={() => openPasswordResetModal()}
                >
                  Reset Password
                </button>
              </div>
            </div>
            <div className="flex w-full">
              <button
                type="submit"
                className="flex items-center justify-center focus:outline-none text-white text-lg font-semibold bg-orange hover:opacity-90 rounded py-2 w-full transition duration-150 ease-in"
                disabled={submittingForm}
              >
                {submittingForm ? (
                  <Loader style={{ height: "30px" }} />
                ) : (
                  <>
                    <span className="mr-2 uppercase">Login</span>
                    <span>
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span>
                  </>
                )}
              </button>
            </div>
          </form>
          {errors && (
            <div
              className="text-center bg-red-200 text-red-500 font-semibold mt-8 p-1 rounded-md"
              role="alert"
            >
              {errors}
            </div>
          )}
        </div>
        {openResetPassword === true && (
          <ResetPassword
            showModal={openResetPassword}
            closeModal={closePasswordResetModal}
          />
        )}
      </div>
    </div>
  );
};

export default LoginPage;
