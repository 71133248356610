import React from "react";
import { Link } from "react-router-dom";
import { LogoBlue } from "../../assets";

const Navbar = () => {
  return (
    <div className="w-full h-full bg-gray-300 px-3 flex justify-between items-center">
      <div className="flex items-center">
        <Link to="/">
          <img alt="SkillsTrainer" src={LogoBlue} className="w-36 mr-4" />
        </Link>
        <span className="text-sm font-semibold text-gray-500 border-2 border-gray-400 py-1 px-2 rounded">
          Admin Panel
        </span>
      </div>
      <div className="flex items-center">
        <Link to="/logout" className="ml-4">
          <button className="btn-primary text-white">Logout</button>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
