import { useState, useEffect } from "react";
import * as yup from "yup";
import { useQuery } from "@apollo/client";

import { GET_PARTNER_PROJECTS_ROlES } from "GraphQl/Queries/AdminUsers";
import { condObj } from "utils/func";
import { GET_ALL_ADMIN_ROLES } from "GraphQl/Queries/AdminUserRole";

function TeamMemberFormBuilder(values) {
  const [schema, setSchema] = useState({});
  const [selectedPartner, setSelectedPartner] = useState();

  const { error, loading, data } = useQuery(GET_PARTNER_PROJECTS_ROlES);
  const { data: rolesRes } = useQuery(GET_ALL_ADMIN_ROLES);
  const roles = rolesRes?.courses_admin_user_roles_info;

  //Effect to see which partner is selected

  useEffect(() => {
    const partnerSelect = (data?.courses_partner || []).find((partner) => {
      if (partner.id == values.partner_id) return partner;
    });
    setSelectedPartner(partnerSelect);
  }, [values.partner_id]);

  useEffect(() => {
    const schema = {
      name: {
        type: "text",
        label: "Enter Name",
        required: true,
        schema: yup.string(),
      },
      email: {
        type: "text",
        label: "Enter Email",
        required: true,
        schema: yup.string().email(),
      },
      password: {
        type: "password",
        label: "Enter Password",
        required: true,
        schema: yup.string(),
      },
      confirm_password: {
        type: "password",
        label: "Confirm Password",
        required: true,
        schema: yup.string(),
      },
      mobile_number: {
        type: "text",
        label: "Enter Mobile Number",
        schema: yup.number(),
      },
      partner_id: {
        type: "select",
        label: "Select Partner ID",
        options: data?.courses_partner.map((partner) => ({
          value: partner.id,
          label: partner.name,
        })),
      },
      project_id: {
        type: "select",
        label: "Select Project ID",
        options: ((selectedPartner && selectedPartner?.projects) || []).map(
          (project) => ({
            value: project.id,
            label: project.name,
          })
        ),
      },
      is_skillstrainer_admin: {
        type: "boolean",
        label: "This user is a SkillsTrainer Admin",
      },
      is_email_valid: {
        type: "boolean",
        label: "Is email valid",
      },
      ...condObj(!values.is_skillstrainer_admin, {
        partnerAccess: {
          label: "Partner Access Conditions",
          type: "object",
          repeat: true,
          insertable: true,
          fields: {
            partner_id: {
              label: "Partner",
              type: "select",
              required: true,
              options: data?.courses_partner.map((partner) => ({
                value: partner.id,
                label: partner.name,
              })),
            },
            role_ids: {
              label: "Role IDs",
              type: "multi-select",
              required: true,
              options: roles?.map((r) => ({ label: r.name, value: r.id })),
              schema: yup.array().min(1),
            },
            id: {
              type: "hidden",
            },
          },
        },
      }),
      id: {
        type: "hidden",
      },
    };

    if (values.id) {
      schema.password = { type: "hidden", required: false };
      schema.confirm_password = { type: "hidden", required: false };
    }

    setSchema(schema);
    //console.log(selectedPartner);
  }, [data, roles, selectedPartner, values.id, values.is_skillstrainer_admin]);

  return schema;
}

export default TeamMemberFormBuilder;
