import React, { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import { useQuery } from "@apollo/client";
import { GET_QUIZ_QUESTIONS_BY_QID } from "GraphQl/Queries/CourseBuilder/Quiz";
import { Button } from "react-bootstrap";
import { s3QuizImgBaseUrl } from "api/Consts";
import EditQuestion from "./EditQuestion";
import { Translations } from "./Translations";

const QuizFilterTable = ({
  questions,
  refresh,
  handleRowSelection,
  linkQuestions,
  propsData,
  selectedQuetions,
  message,
}) => {
  console.log("selectedQuetions", selectedQuetions);
  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });
  const [isShow, setIsShow] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [showEditQuestion, setShowEditQuestion] = useState({
    show: false,
    question: null,
  });
  const [showTranslationsModal, setShowTranslationsModal] = useState({
    show: false,
    question: null,
  });
  const [currQuest, setCurrQues] = useState();

  const { data } = useQuery(GET_QUIZ_QUESTIONS_BY_QID);
  const handleShow = (id) => {
    let qid = id;
    setIsShow(!isShow);
    var newArray =
      data !== "" &&
      data.courses_questions.filter(function (el) {
        return el.id == qid;
      });
    setCurrQues(newArray);
  };

  const handleView = () => {
    setViewAll(!viewAll);
  };
  console.log("view", viewAll);
  const columns = [
    {
      name: "id",
      selector: (row, index) => `${row.id}`,
      maxWidth: "30px",
      sortable: true,
    },
    {
      name: "Question Name",
      selector: (row, index) => `${row.question_text}`,
      sortable: true,
    },
    {
      name: "Question Category",
      selector: (row, index) => `${row.question_cat[0].name}`,
      sortable: true,
    },
    {
      name: "Question Level",
      selector: (row, index) => {
        return row.question_level == 1
          ? "Easy"
          : row.question_level == 2
          ? "Medium"
          : row.question_level == 3
          ? "Difficult"
          : "N/A";
      },
      sortable: true,
    },
    {
      name: "Question Type",
      selector: (row, index) =>
        `${
          row.question_type == 1
            ? "MCQ"
            : row.question_type == 2
            ? "Picture MCQ"
            : row.question_type == 3
            ? "Drag and Drop"
            : "N/A"
        }`,
      sortable: true,
    },
    {
      name: "Preview",
      cell: (record) => {
        return (
          <button
            className=" btn-primary btn-sm"
            id={record.id}
            onClick={() => handleShow(record.id)}
          >
            Preview
          </button>
        );
      },
    },
    {
      name: "Edit",
      maxWidth: "60px",
      cell: (record) => {
        // console.log('record', record.id);
        return (
          <button
            className="btn-secondary"
            id={record.id}
            // onClick={handleShow()}
            onClick={() =>
              setShowEditQuestion({
                show: true,
                question: record,
              })
            }
          >
            Edit
          </button>
        );
      },
    },
    {
      name: "Translations",
      cell: (record) => {
        // console.log('record', record.id);
        return (
          <button
            className="btn"
            id={record.id}
            // onClick={handleShow()}
            onClick={() =>
              setShowTranslationsModal({
                show: true,
                question: record,
              })
            }
          >
            <span className="text-sm flex-nowrap">Translations</span>
          </button>
        );
      },
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  // const rowSelectCritera = row => selectedQuetions.indexOf(parseInt(row.id)) > -1;

  return (
    <div className="mt-20  ">
      <Button className="mb-2" onClick={() => setViewAll(!viewAll)}>
        View All
      </Button>
      <DataTable
        columns={columns}
        pagination
        data={questions.data?.courses_questions}
        progressPending={questions.loading}
        // paginationTotalRows={1000}
        selectableRows={propsData}
        selectableRowsHighlight={true}
        //selectableRowSelected={rowSelectCritera}

        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSelectedRowsChange={({
          allSelected,
          selectedCount,
          selectedRows,
        }) => {
          handleRowSelection(allSelected, selectedRows);
        }}
      />
      {propsData ? (
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange"
            onClick={linkQuestions}
          >
            Add To Quiz
          </button>
        </div>
      ) : (
        " "
      )}
      {showEditQuestion.show && (
        <Modal
          size="lg"
          show={true}
          onHide={() => {
            setShowEditQuestion({
              ...showEditQuestion,
              show: false,
            });
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Question</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditQuestion
              refresh={refresh}
              question={showEditQuestion.question}
              close={() =>
                setShowEditQuestion({
                  ...showEditQuestion,
                  show: false,
                })
              }
            />
          </Modal.Body>
        </Modal>
      )}
      {showTranslationsModal.show && (
        <Modal
          size="lg"
          show={true}
          onHide={() => {
            setShowTranslationsModal({
              ...showTranslationsModal,
              show: false,
            });
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Translations</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Translations question={showTranslationsModal.question} />
          </Modal.Body>
        </Modal>
      )}
      {message && (
        <div className="text-green-600 text-base mt-2">
          Selected Questions Successfully Added To Quiz{" "}
        </div>
      )}

      {isShow == true && (
        <div>
          <Modal
            size="lg"
            show={true}
            onHide={handleShow}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {currQuest !== "" &&
                currQuest.map((q) => {
                  return (
                    <div className="">
                      <div className="flex justify-between">
                        <p key={q.id}>Question:&nbsp;{q.question_text}</p>
                        {q.question_type === 1 && (
                          <div>
                            Marks:&nbsp;&nbsp;
                            <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-500 rounded-full">
                              {q.marks}
                            </span>
                          </div>
                        )}
                      </div>
                      <p>Options:&nbsp;</p>
                      <div className="">
                        {q.quest_answer.map((e) => {
                          return (
                            <div className="">
                              <ul className="">
                                <li
                                  className={`flex justify-between ${
                                    e.isAnswer
                                      ? "text-green-500 font-bold"
                                      : "text-red-500"
                                  } `}
                                >
                                  {q.question_type === 1 && e.answer_text}
                                  {q.question_type === 2 && (
                                    <img
                                      src={`${s3QuizImgBaseUrl}/${e?.answer_img}`}
                                      width="250"
                                      height="100"
                                      alt="Example dd"
                                    />
                                  )}
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </Modal.Body>
            <Modal.Footer>Questions Details</Modal.Footer>
          </Modal>
        </div>
      )}
      {viewAll == true && (
        <Modal
          size="lg"
          show={true}
          onHide={handleView}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>All Question</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {data.length !== "" &&
              data.courses_questions.map((q) => {
                return (
                  <div>
                    <div>
                      <ul className="flex justify-between">
                        <p className="text-red-500">
                          Question:&nbsp;{q.question_text}
                        </p>
                        {q.question_type === 1 && (
                          <div>
                            Marks:&nbsp;&nbsp;
                            <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-500 rounded-full">
                              {q.marks}
                            </span>
                          </div>
                        )}
                      </ul>
                      Options:&nbsp;
                      {q.quest_answer.length > 0 &&
                        q.quest_answer.map((e) => {
                          return (
                            <div>
                              <li className="ml-8">
                                {q.question_type === 1 && e.answer_text}
                                {q.question_type === 2 && (
                                  <img
                                    src={`${s3QuizImgBaseUrl}/${e?.answer_img}`}
                                    width="250"
                                    height="100"
                                    alt="Example dd"
                                  />
                                )}

                                {q.question_type === 1 && (
                                  <p>
                                    {" "}
                                    {e.isAnswer == true ? "true" : "false"}
                                  </p>
                                )}
                                <br />
                              </li>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </Modal.Body>
          <Modal.Footer>modal footer</Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default QuizFilterTable;
