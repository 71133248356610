import { useQuery } from "@apollo/client";
import Input from "components/InputGroup/Input";
import Select from "react-select";
import { GET_PARTNER_AND_INSTRUCTOR } from "GraphQl/Queries/User";
import { useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { currentModuleAccessRecoilVar } from "State/Navigation";
import { gqlUserRecoilVar } from "State/User";
import { GET_COURSES_WITH_FILTER } from "GraphQl/Queries/Courses";
import MultiSelectDropdown from "components/InputGroup/MultiselectDropdown";

export default function UsersFilter(props) {
  const { updateListingCriteria, selectedCourseId } = props;

  const [user] = useRecoilState(gqlUserRecoilVar);
  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);
  const allowedPartnerIds = useMemo(
    () =>
      currentModuleAccess && Object.keys(currentModuleAccess.allowedPartners),
    [currentModuleAccess]
  );

  const [transientListingCriteria, setTransientListingCriteria] = useState({
    partnerId: null,
    email: null,
    name: null,
    mobileNumber: null,
    couponId: null,
    projectId: null,
    courseId: selectedCourseId ? parseInt(selectedCourseId) : null,
    keywords: [],
  });

  const updateInListingCriteria = (field, bypass) => (value) => {
    console.log({ field, bypass });
    const newListingCriteria = { ...transientListingCriteria, [field]: value };
    setTransientListingCriteria(newListingCriteria);
    if (bypass) updateListingCriteria(newListingCriteria);
  };

  // FETCHING ALL PARTNERS
  const { data: partnersRes } = useQuery(GET_PARTNER_AND_INSTRUCTOR);
  const partners = useMemo(() => {
    const partners = (partnersRes?.courses_partner || []).filter(
      (partner) =>
        user?.is_skillstrainer_admin ||
        (currentModuleAccess?.allowedPartners &&
          currentModuleAccess.allowedPartners[partner.id])
    );
    return partners;
  }, [user, partnersRes, currentModuleAccess]);
  const selectedPartner =
    partners &&
    partners.find((p) => p.id === transientListingCriteria.partnerId);

  const selectedProject =
    selectedPartner &&
    selectedPartner.projects.find(
      (p) => p.id === transientListingCriteria.projectId
    );
  const selectedCoupon =
    selectedPartner &&
    selectedPartner.coupons.find(
      (c) => c.id === transientListingCriteria.couponId
    );

  // FETCHING ALL COURSES
  // @to-revert
  const courseSearchExp = useMemo(() => {
    if (!user) return { course_id: { _eq: -1 } };
    else if (!user.is_skillstrainer_admin)
      return {
        ...(selectedCourseId ? { id: { _eq: selectedCourseId } } : {}),
        _or: [
          {
            partner_revenue_split_directives: {
              partner_id: { _in: allowedPartnerIds },
            },
          },
          {
            criteria_courses: {
              criteria: {
                coupon: {
                  partner_id: { _in: allowedPartnerIds },
                },
              },
            },
          },
        ],
      };
    else return selectedCourseId ? { id: { _eq: selectedCourseId } } : {};
  }, [user, allowedPartnerIds, selectedCourseId]);

  const coursesHook = useQuery(GET_COURSES_WITH_FILTER, {
    variables: { courseSearchExp },
  });
  const courses = coursesHook.data?.courses_course || [];
  const selectedCourse =
    courses && courses.find((c) => c.id === transientListingCriteria.courseId);

  return (
    <div className="flex-col">
      <div className="flex-row mb-3 grid grid-cols-2 gap-x-3 gap-y-2">
        <div className="flex flex-col w-full">
          <div className="flex-1">Select Partner</div>
          <div className="flex-1">
            <Select
              className="my-2"
              value={
                selectedPartner
                  ? {
                      label: selectedPartner.name,
                      value: selectedPartner.id,
                    }
                  : { label: "Select a partner" }
              }
              onChange={(e) =>
                updateInListingCriteria("partnerId", true)(e.value)
              }
              options={[{ label: "Select a partner" }].concat(
                partners.map((p) => ({ value: p.id, label: p.name }))
              )}
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex-1 ">Select Project</div>
          <div className="flex-1">
            <Select
              className="my-2"
              value={
                selectedProject
                  ? {
                      label: selectedProject.name,
                      value: selectedProject.id,
                    }
                  : { label: "Select a project" }
              }
              onChange={(e) =>
                updateInListingCriteria("projectId", true)(e.value)
              }
              options={[{ label: "Select a project" }].concat(
                (selectedPartner &&
                  selectedPartner.projects.map((p) => ({
                    value: p.id,
                    label: p.name,
                  }))) ||
                  []
              )}
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex-1 ">Select Coupon</div>
          <div className="flex-1">
            <Select
              className=" my-2"
              value={
                selectedCoupon
                  ? {
                      label: selectedCoupon.code,
                      value: selectedCoupon.id,
                    }
                  : { label: "Select a coupon" }
              }
              onChange={(e) =>
                updateInListingCriteria("couponId", true)(e.value)
              }
              options={[{ label: "Select a coupon" }].concat(
                (selectedPartner &&
                  selectedPartner.coupons.map((c) => ({
                    value: c.id,
                    label: c.code,
                  }))) ||
                  []
              )}
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex-1 ">Select Course</div>
          <div className="flex-1">
            <Select
              className=" my-2"
              value={
                selectedCourse
                  ? {
                      label: selectedCourse.full_name,
                      value: selectedCourse.id,
                    }
                  : { label: "Select a course" }
              }
              onChange={(e) =>
                updateInListingCriteria("courseId", true)(e.value)
              }
              options={[{ label: "Select a course" }].concat(
                courses.map((c) => ({
                  value: c.id,
                  label: c.full_name,
                })) || []
              )}
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex-1 ">Keywords</div>
          <div className="flex-1">
            <MultiSelectDropdown
              className=" my-2"
              selectedValue={transientListingCriteria.keywords}
              options={transientListingCriteria.keywords.map((k) => ({
                value: k,
                label: k,
              }))}
              setSelectedValue={(values) =>
                updateInListingCriteria("keywords", true)(values)
              }
              creatable
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <p className="mt-2 text-japanese_indigo">User details: </p>
        <div className="grid grid-cols-3 gap-3">
          <div className="flex-1 mb-3">
            <Input
              id="search"
              type="text"
              placeholder="Search by Email"
              aria-label="Search Input"
              value={transientListingCriteria.email}
              onChange={(e) => updateInListingCriteria("email")(e.target.value)}
              onBlur={(e) =>
                updateInListingCriteria("email", true)(e.target.value)
              }
            />
          </div>
          <div className="flex-1 mb-3">
            <Input
              id="name"
              type="text"
              placeholder="Search by Name"
              aria-label="Search Name"
              value={transientListingCriteria.name}
              onChange={(e) => updateInListingCriteria("name")(e.target.value)}
              onBlur={(e) =>
                updateInListingCriteria("name", true)(e.target.value)
              }
            />
          </div>
          <div className="flex-1 mb-3">
            <Input
              id="mobile_number"
              type="text"
              placeholder="Search by Mobile Number"
              aria-label="Search Mobile Number"
              value={transientListingCriteria.mobileNumber}
              onChange={(e) =>
                updateInListingCriteria("mobileNumber")(e.target.value)
              }
              onBlur={(e) =>
                updateInListingCriteria("mobileNumber", true)(e.target.value)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
