export let domain = "";

const useLocalApi = process.env.REACT_APP_USE_LOCAL_API;

export const env = process.env.REACT_APP_BUILD_ENV;
if (env === "production") {
  domain = "https://adminapi.skillstrainer.in/";
  if (useLocalApi === "true") domain = "http://admin.skillstrainer-dev.co/";
} else if (env === "staging") {
  domain = "https://admin.skillsscale.in/";
} else {
  domain = "http://admin.skillstrainer-dev.co/";
}

export let graphqlUri = "";
export let graphqlWsUri = "";

if (env === "production") {
  graphqlUri = "https://graphql.skillstrainer.in/v1/graphql";
  graphqlWsUri = "wss://graphql.skillstrainer.in/v1/graphql";
} else if (env === "staging") {
  graphqlUri = "https://graphql.skillsscale.in/v1/graphql";
  graphqlWsUri = "wss://graphql.skillsscale.in/v1/graphql";
} else {
  graphqlUri = "https://graphql.skillsscale.in/v1/graphql";
  graphqlWsUri = "wss://graphql.skillsscale.in/v1/graphql";
}

export let marketingWebsiteUri;

if (env === "production") {
  marketingWebsiteUri = "https://editable.skillstrainer.in";
} else {
  // graphqlUri = "https://graphql.skillsscale.in/v1/graphql";
  marketingWebsiteUri = "http://localhost:4000";
}

export let reportsUri;

if (env === "production") {
  reportsUri = "https://metabase.skillstrainer.in";
} else {
  // graphqlUri = "https://graphql.skillsscale.in/v1/graphql";
  marketingWebsiteUri = "https://reports.skillscale.in";
}

export const userRoles = {
  production: {
    administrator: 1,
    partner: 2,
  },
  staging: {
    administrator: 3,
    partner: 6,
  },
  dev: {},
}[env || "dev"];

export const DATE_FORMATS = {
  BATCH_SLOT_DATE: "DD MMM YY",
  BATCH_SLOT_TIME: "HH:mm",
  BATCH_SLOT_DATE_TIME: "DD MMM YY, HH:mm",
  HTML_DATE_FORMAT: "YYYY-MM-DD",
};

export const PARTNER_WEBSITE_STATUS = {
  unpublished: "unpublished",
  beginningPublish: "beginning-publish",
  publishing: "publishing",
  published: "published",
  failed: "failed",
};

export const PARTNER_WEBSITE_STATUS_LABEL = {
  [PARTNER_WEBSITE_STATUS.unpublished]: "Unpublished",
  [PARTNER_WEBSITE_STATUS.beginningPublish]: "Beginning publish...",
  [PARTNER_WEBSITE_STATUS.publishing]: "Publishing...",
  [PARTNER_WEBSITE_STATUS.published]: "Published",
  [PARTNER_WEBSITE_STATUS.failed]: "Failed",
};

export const SOCIAL_CATEGORY = {
  GENERAL: "0",
  SC: "1",
  ST: "2",
  OBC: "3",
  DIFFERENTLY_ABLED: "4",
  ORHPAN: "5",
};

export const SOCIAL_CATEGORY_NAMES = {
  [SOCIAL_CATEGORY.GENERAL]: "General",
  [SOCIAL_CATEGORY.SC]: "SC",
  [SOCIAL_CATEGORY.ST]: "ST",
  [SOCIAL_CATEGORY.OBC]: "OBC",
  [SOCIAL_CATEGORY.DIFFERENTLY_ABLED]: "Differently Abled",
  [SOCIAL_CATEGORY.ORHPAN]: "Orphan",
};

export const UPLOAD_COLLECTIONS = {
  assets: "assets",
};

export let SCORM_DOMAIN = "";
if (env === "production") {
  SCORM_DOMAIN = "https://webappapi.skillstrainer.in";
} else if (env === "staging") {
  SCORM_DOMAIN = "https://webapp.skillsscale.in";
} else {
  SCORM_DOMAIN = "http://webapp.skillstrainer-dev.co";
}

export let CERTIFICATE_DOWNLOAD_URL = "";
if (env === "production") {
  CERTIFICATE_DOWNLOAD_URL =
    "https://tkvsnr5yp2.execute-api.ap-south-1.amazonaws.com/prod/";
} else {
  CERTIFICATE_DOWNLOAD_URL =
    "https://vgnl1cncc3.execute-api.ap-south-1.amazonaws.com/prod/";
}

export const asdmCourseReportConfig = {};

if (env === "production")
  Object.assign(asdmCourseReportConfig, {
    partnerId: 51,
    courseId: 178,
    //205 is the courses id for skills yatra test in assamese, which is hard coded in asdm report components
    projectId: 18,
    mappingId: [1335, 2594],
    coursesIds: [205, 178],
    asdmProjectId: 22,
  });
else
  Object.assign(asdmCourseReportConfig, {
    partnerId: 51,
    courseId: 178,
    projectId: 18,
    mappingId: [32462346],
  });

// Quiz Image base url
export let s3QuizImgBaseUrl;

if (env === "production")
  s3QuizImgBaseUrl =
    "https://adminskillstrainerprod.s3.ap-south-1.amazonaws.com";
else if (env === "staging")
  s3QuizImgBaseUrl = "https://adminskillstrainer.s3.ap-south-1.amazonaws.com";
else
  s3QuizImgBaseUrl = "https://adminskillstrainer.s3.ap-south-1.amazonaws.com";

export const emailDeliveryStatus = ["bounce", "complaint", "delivery"];

export const secretForBulkNotification = "HUQQh#=lf==/~D~'b1(.-F0VI#@jj.^F";
