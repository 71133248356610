import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { GET_PARTNERS } from "../../../GraphQl/Queries/Partner";
import { GET_PROJECTS_BY_PARTNER } from "../../../GraphQl/Queries/Projects";
import { CREATE_SCHOLARSHIP } from "../../../GraphQl/Mutations/Scholarships";
import { VIEW_SCHOLARSHIP_SCHEMA } from "./schema";
import Container from "../../../components/Container/Container";
import Select from "react-select";
import ScholarshipsTable from "./components/ScholarshipsTable";
import EditScholarship from "./components/EditModal";
import ApplicantsModal from "./components/ViewApplicantsModal";

import { useUserPartnerAccessDetails } from "../../../Hooks/UserAcccess";

function ViewEditScholarship() {
  const partners = useQuery(GET_PARTNERS);

  const [insertScholarship, insertedScholarship] =
    useMutation(CREATE_SCHOLARSHIP);

  const [scholarship, setScholarship] = useState({});
  const [selectedScholarship, setSelectedScholarship] = useState({});
  const [showScholarshipsTable, setShowScholarshipsTable] = useState(false);
  const [showEditModel, setShowEditModal] = useState(false);
  const [showApplicantsModal, setShowApplicantsModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const partnerAccess = useUserPartnerAccessDetails();

  const filterPartner = (partners) => {
    if (partners) {
      //console.log('Partner Access : ', partnerAccess);
      if (partnerAccess && partnerAccess.allPartnerAccess === true) {
        return partners;
      } else if (partnerAccess.partnerIds) {
        const filteredPartners = partners.filter(
          ({ id }) => partnerAccess.partnerIds.indexOf(id) > -1
        );
        return filteredPartners;
      }
    }

    return [];
  };

  const CheckFields = async () => {
    VIEW_SCHOLARSHIP_SCHEMA.map(async (scholarship_schema) => {
      if (
        scholarship_schema.type === "checkbox" &&
        scholarship[scholarship_schema.column_name] == undefined
      ) {
        var key = scholarship_schema.column_name;
        scholarship[key] = false;
        setScholarship({ ...scholarship });
      }
    });
    return scholarship;
  };

  const getOptions = (scholarship_schema) => {
    if (scholarship_schema.column_name == "partner_id") {
      return filterPartner(partners.data?.courses_partner).map((partner) => {
        return {
          label: partner.name,
          value: partner.id,
        };
      });
    } else {
      return scholarship_schema.options;
    }
  };

  const submitScholarship = async (e) => {
    e.preventDefault();
    setErrors([]);
    CheckFields();
    var submit_errors = [];
    VIEW_SCHOLARSHIP_SCHEMA.map((v) => {
      if (v.required)
        if (!(v.column_name in scholarship)) {
          if (v.required_or) {
            v.required_or.map((j) => {
              if (!(VIEW_SCHOLARSHIP_SCHEMA[j].column_name in scholarship)) {
                submit_errors.push({ message: `${v.label} is required` });
              }
            });
          } else {
            submit_errors.push({ message: `${v.label} is required` });
          }
        }
    });

    if (submit_errors.length) {
      setErrors(submit_errors);
      return;
    }

    setShowScholarshipsTable(true);
  };

  const updateSelectValue = async (value, key) => {
    const obj = {};
    obj[key] = value;
    setScholarship((prevState) => {
      const criteriaObj = {
        ...prevState,
        ...obj,
      };

      return criteriaObj;
    });
  };
  const editScholarship = (scholarship) => {
    setSelectedScholarship(scholarship);
    setShowEditModal(true);
    setShowApplicantsModal(false);
  };

  const viewApplicants = (scholarship) => {
    setSelectedScholarship(scholarship);
    setShowApplicantsModal(true);
    setShowEditModal(false);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleApplicantsModalClose = () => {
    setShowApplicantsModal(false);
  };

  // console.log("MODAL PREVIEW : ", showEditModel, showApplicantsModal);

  return (
    <Container title={"View/Edit Scholarship"}>
      <h2 className="text-3xl font-bold">List Scholarships</h2>

      <form className="flex flex-wrap mt-4" onSubmit={submitScholarship}>
        {VIEW_SCHOLARSHIP_SCHEMA.map((scholarship_schema) => {
          return (
            <div className="w-1/2">
              {scholarship_schema.type == "select" && (
                <div className="mr-3">
                  <label className="block text-sm font-medium text-gray-700">
                    {scholarship_schema.label}
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <Select
                      options={getOptions(scholarship_schema)}
                      onChange={(e) =>
                        updateSelectValue(
                          e.value,
                          scholarship_schema.column_name
                        )
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
        <div className="flex flex-col items-end justify-end text-right">
          <button type="submit" className="btn-primary">
            Show Scholarships
          </button>
        </div>

        {errors && (
          <div className="mt-1">
            {errors.map(({ message }) => {
              return <div className="text-lg text-red-800">{message}</div>;
            })}
          </div>
        )}
      </form>
      {insertedScholarship.data?.insert_courses_scholarships_one.id && (
        <div className="text-green-600 text-base mt-2">
          New scholarship created
        </div>
      )}
      {showScholarshipsTable && (
        <ScholarshipsTable
          //   editRoute={"/partner/update_partner"}
          editPartners={(scholarship) => editScholarship(scholarship)}
          viewApplicants={(scholarship) => viewApplicants(scholarship)}
          searchObj={scholarship}
          // setRefreshFunction={(f) => {
          //   setChildRefreshFunction(f);
          // }}
        />
      )}
      <EditScholarship
        show={showEditModel}
        selectedScholarship={selectedScholarship}
        handleClose={() => handleEditModalClose()}
      />
      <ApplicantsModal
        show={showApplicantsModal}
        selectedScholarship={selectedScholarship}
        handleClose={() => handleApplicantsModalClose()}
      />
    </Container>
  );
}

export default ViewEditScholarship;