import { useQuery } from "@apollo/client";
import { useRecoilState } from "recoil";
import { gqlUserRecoilVar } from "State/User";
import Container from "components/Container/Container";
import { currentModuleAccessRecoilVar } from "State/Navigation";
import { GET_BATCHES_WITH_FILTER } from "GraphQl/Queries/BatchesQueries";
import React, { useMemo } from "react";
import BatchesData from "./components/BatchesData";
import { Form } from "skillstrainer-resource-library";
import FilterFormBuilder from "formBuilders/FilterFormBuilder";

export default function ListBatches(props) {
  const [user] = useRecoilState(gqlUserRecoilVar);
  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);
  const allowedPartnerIds = useMemo(
    () => Object.keys(currentModuleAccess?.allowedPartners || {}),
    [currentModuleAccess]
  );

  const partnerSearchParams = {
    where: { partner_id: { _in: allowedPartnerIds } },
  };

  const batchesDataQuery = useQuery(GET_BATCHES_WITH_FILTER, {
    variables: user.is_skillstrainer_admin ? {} : partnerSearchParams,
  });

  function resetFilters() {
    batchesDataQuery.refetch(
      user.is_skillstrainer_admin ? { where: {} } : partnerSearchParams
    );
  }

  function filterSearch(filter) {
    const { course, partner_id, name } = filter;

    let variables = user.is_skillstrainer_admin
      ? { where: {} }
      : partnerSearchParams;

    if (partner_id) {
      variables.where = {
        ...variables.where,
        partner_id: {
          _eq: partner_id,
        },
      };
    }

    if (course) {
      variables.where = {
        ...variables.where,

        course_id: {
          _eq: course,
        },
      };
    }

    if (name) {
      variables.where = {
        ...variables.where,
        batch_name: {
          _ilike: `%${name}%`,
        },
      };
    }
    batchesDataQuery.refetch(variables);
  }

  return (
    <Container title="List Batches">
      <Form
        formBuilder={FilterFormBuilder}
        className="mt-2 grid grid-cols-3 gap-x-4 mx-2 w-full"
        submitButton={{
          text: "Filter",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
        }}
        onSubmit={(filter) => filterSearch(filter)}
      />
      <div className="flex flex-row space-x-2  ml-24 -mt-9">
        <button onClick={resetFilters} className="btn-secondary ">
          <span className="whitespace-nowrap"> Reset Filters</span>
        </button>
      </div>
      <div className="container w-full mt-4">
        <BatchesData batchesData={batchesDataQuery} />
      </div>
    </Container>
  );
}
