import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_MODULE_QUESTIONS } from "GraphQl/Queries/CourseBuilder/Quiz";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";
import { s3QuizImgBaseUrl } from "api/Consts";
import EditQuestion from "./EditQuestion";
import { Translations } from "./Translations";

export default function ModuleQuestionView() {
  const location = useLocation();
  const [isShow, setIsShow] = useState(false);
  const [currQuest, setCurrQues] = useState();
  const [question, setQuestion] = useState([]);

  const params = queryString.parse(location.search);
  let history = useHistory();
  const [showEditQuestion, setShowEditQuestion] = useState({
    show: false,
    question: null,
  });
  const [showTranslationsModal, setShowTranslationsModal] = useState({
    show: false,
    question: null,
  });

  const { data: subModuleQuestions, refetch } = useQuery(GET_MODULE_QUESTIONS, {
    variables: { module_mapping_id: params.quizid },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (subModuleQuestions && subModuleQuestions.courses_questions[0]) {
      console.log("subModuleQuestions", subModuleQuestions);
      setQuestion(subModuleQuestions.courses_questions);
    }
  }, [subModuleQuestions]);

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  const handleShow = (id) => {
    setIsShow(!isShow);
    var newArray =
      question &&
      question.find(function (el) {
        return el.id == id;
      });
    setCurrQues(newArray);
  };

  const columns = [
    {
      name: "id",
      selector: (row, index) => `${row.id}`,
      sortable: true,
      width: "100px",
    },
    {
      name: "Question Name",
      selector: (row, index) => `${row.question_text}`,
      sortable: false,
      width: "300px",
    },
    {
      name: "Question Level",
      selector: (row, index) => {
        return row.question_level == 1
          ? "Easy"
          : row.question_level == 2
          ? "Medium"
          : row.question_level == 3
          ? "Difficult"
          : "N/A";
      },
      sortable: true,
      width: "140px",
    },
    {
      name: "Type",
      selector: (row, index) =>
        `${
          row.question_type == 1
            ? "MCQ"
            : row.question_type == 2
            ? "Picture MCQ"
            : row.question_type == 3
            ? "Drag and Drop"
            : "N/A"
        }`,
      sortable: true,
      width: "100px",
    },
    {
      name: "Category",
      selector: (row, index) => {
        return row.question_cat.map((e) => e.name).toString();
      },
      sortable: true,
      maxWidth: "180px",
    },
    {
      // name: "Preview",
      sortable: true,
      maxWidth: "160px",
      cell: (row) => {
        // console.log('record', record.id);
        return (
          <button
            className="btn-primary"
            id={row.id}
            // onClick={handleShow()}
            onClick={() => handleShow(row.id)}
          >
            Preview
          </button>
        );
      },
    },
    {
      // name: "Edit",
      maxWidth: "80px",
      cell: (record) => {
        // console.log('record', record.id);
        return (
          <button
            className="btn-secondary"
            id={record.id}
            // onClick={handleShow()}
            onClick={() =>
              setShowEditQuestion({
                show: true,
                question: record,
              })
            }
          >
            Edit
          </button>
        );
      },
    },
    {
      // name: "Translations",
      cell: (record) => {
        // console.log('record', record.id);
        return (
          <button
            className="btn-primary"
            id={record.id}
            // onClick={handleShow()}
            onClick={() =>
              setShowTranslationsModal({
                show: true,
                question: record,
              })
            }
          >
            <span className="text-sm flex-nowrap">Translations</span>
          </button>
        );
      },
    },
  ];
  return (
    <div className="mt-20 ">
      <div className="flex justify-between">
        <button
          className="inline-flex justify-center py-2 px-4 
border border-transparent shadow-sm text-sm font-medium 
rounded-md text-white bg-orange mb-5"
          onClick={() => history.goBack()}
        >
          Go To Back
        </button>

        <button
          className="inline-flex justify-center py-2 px-4 
border border-transparent shadow-sm text-sm font-medium 
rounded-md text-white bg-orange mb-5"
          onClick={() => {
            let path =
              getModulePathStringById(ModuleNameIdMap.editQuestion) +
              `/?id=${params.id}&&quizid=${params.quizid}`;
            history.push(path);
          }}
        >
          Add Questions
        </button>
      </div>

      <DataTable
        columns={columns}
        pagination
        data={question ? question : []}
        // paginationTotalRows={1000}
        //selectableRowSelected={rowSelectCritera}

        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
      {showEditQuestion.show && (
        <Modal
          size="lg"
          show={true}
          onHide={() => {
            setShowEditQuestion({
              ...showEditQuestion,
              show: false,
            });
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Question</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditQuestion
              refresh={() => {
                refetch({
                  variables: { module_mapping_id: params.quizid },
                });
              }}
              question={showEditQuestion.question}
              close={() =>
                setShowEditQuestion({
                  ...showEditQuestion,
                  show: false,
                })
              }
            />
          </Modal.Body>
        </Modal>
      )}
      {showTranslationsModal.show && (
        <Modal
          size="lg"
          show={true}
          onHide={() => {
            setShowTranslationsModal({
              ...showTranslationsModal,
              show: false,
            });
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Translations</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Translations question={showTranslationsModal.question} />
          </Modal.Body>
        </Modal>
      )}

      <Modal
        size="lg"
        show={isShow}
        onHide={() => setIsShow(!isShow)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Current Questions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currQuest && (
            <div className="p-4">
              <div className="flex justify-between">
                <p key={currQuest.id} className="font-medium text-lg">
                  Question: {currQuest.question_text}
                </p>
                {currQuest.question_type === 1 && (
                  <button
                    className="inline-flex justify-center py-2 px-4 
                  border border-transparent shadow-sm text-sm font-medium 
                  rounded-md text-white bg-orange"
                  >
                    Marks {currQuest.marks}
                  </button>
                )}
              </div>
              <div className="mt-4">
                {currQuest.quest_answer.map((e) => {
                  return (
                    <>
                      <div className="flex justify-between mb-4">
                        <div className="flex flex-row space-x-2 py-1">
                          <input
                            checked={e.isAnswer == true ? true : false}
                            id={e.id}
                            type="radio"
                            value=""
                            name="default-radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for={e.id}
                            className="ml-2 text-base
                            font-semibold
                          text-gray-900 dark:text-gray-300"
                          >
                            {currQuest.question_type === 1 && e.answer_text}
                            {currQuest.question_type === 2 && (
                              <div className="flex flex-col space-y-2">
                                <img
                                  src={`${s3QuizImgBaseUrl}/${e?.answer_img}`}
                                  width="350"
                                  height="150"
                                  alt="Example dd"
                                />
                                <span className="ml-2 py-4 mt-8 ">
                                  {e.answer_text}
                                </span>
                              </div>
                            )}
                          </label>
                        </div>
                        {currQuest.question_type === 1 && (
                          <button
                            className={`inline-flex justify-center py-2 px-4 
                            border border-transparent shadow-sm text-sm font-medium 
                            rounded-md text-white ${
                              e.isAnswer ? "bg-green-600" : "bg-red-600"
                            }`}
                          >
                            {" "}
                            {e.isAnswer == true ? "Correct" : "Incorrect"}
                          </button>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>Questions Details</Modal.Footer>
      </Modal>
    </div>
  );
}
