import { useQuery } from "@apollo/client";
import api from "api/Api";
import Select from "components/InputGroup/Select";
import { GET_BATCHES_WITH_FILTER } from "GraphQl/Queries/CourseCoupons";
import { GET_PARTNERS_WITH_FILTER } from "GraphQl/Queries/Partner";
import { useState, useMemo } from "react";
import { useRecoilState } from "recoil";
import { currentModuleAccessRecoilVar } from "State/Navigation";
import { error } from "utils/errors";
import usePromise from "utils/hooks/usePromise";
import { errorHandler, successHandler } from "utils/toast";
import { gqlUserRecoilVar } from "State/User";
import { wireEventValue } from "utils/func";

// User management pages common imports
import Container from "components/Container/Container";
import { Modal } from "skillstrainer-resource-library";
import TraineeList from "components/TraineeList/TraineeList";

export default function HandleBatchUser() {
  const [user] = useRecoilState(gqlUserRecoilVar);
  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);
  const accessiblePartners = useMemo(
    () =>
      currentModuleAccess
        ? Object.keys(currentModuleAccess.allowedPartners).map(Number)
        : [],
    [currentModuleAccess]
  );

  const [isTraineeListOpen, setIsTraineeListOpen] = useState();
  const [users, setSelectedUsers] = useState([]);

  const [selectedPartner, setSelectedPartner] = useState();
  const [selectedCourseId, setSelectedCourseId] = useState();
  const [selectedBatchId, setSelectedBatchId] = useState([]);

  /*
   *
   *
   * Fetching data
   *
   *
   */

  const { data: partnersRes } = useQuery(GET_PARTNERS_WITH_FILTER, {
    variables: { filterClause: {} },
  });
  const partners = useMemo(
    () =>
      partnersRes?.courses_partner.filter(
        (p) => user?.is_skillstrainer_admin || accessiblePartners.includes(p.id)
      ),
    [partnersRes, user, accessiblePartners]
  );

  const { data: batchesRes } = useQuery(GET_BATCHES_WITH_FILTER, {
    variables: {
      courseBatchesCriteria: selectedPartner?.id
        ? {
            partner_id: { _eq: selectedPartner?.id },
          }
        : {},
    },
  });
  const batches = batchesRes?.courses_course_batches;
  const courses = useMemo(
    () =>
      batches?.reduce((courses, batch) => {
        if (!courses.find((c) => c.id === batch.course.id))
          courses.push(batch.course);
        return courses;
      }, []),
    [batches]
  );

  /*
   *
   *
   * Submit function
   *
   *
   */

  const { run: enrolInBatch, loading: enrollingInBatch } =
    usePromise(async () => {
      try {
        if (!users.length) throw error({}, "No users are selected");
        if (!selectedBatchId) throw error({}, "No batch is selected");

        await api()
          .post("/enrol-in-batch", {
            user_id: users,
            batch_id: selectedBatchId,
          })
          .then(({ data: res }) => {
            if (res.success) successHandler("Trainees enrolled successfully");
          })
          .catch((err) => {
            err = err.response.data.message;
            if (err.type === "invalid_coupons")
              throw error(err, "Invalid coupon code");
            else if (err.type === "coupon_already_applied")
              throw error(err, "Coupon already applied");
            else
              throw error(
                err,
                typeof err === "string" ? err : "An error occurred"
              );
          });
      } catch (err) {
        console.log(err);
        errorHandler(err.message);
      }
    }, [users, selectedBatchId]);

  return (
    <Container title="Enrol User in Batch">
      <div className="mt-4 flex items-center gap-3">
        <button
          onClick={() => setIsTraineeListOpen(true)}
          className="btn-primary"
        >
          Choose trainees
        </button>
        <span>
          {users.length
            ? `${users.length} users selected`
            : "No users selected"}
        </span>
      </div>
      <Modal isOpen={isTraineeListOpen} onClose={() => setIsTraineeListOpen()}>
        <div className="px-4">
          <TraineeList
            selectable
            onComplete={(userIds) => {
              setSelectedUsers([...userIds]);
              setIsTraineeListOpen();
            }}
          />
        </div>
      </Modal>

      <div className="flex flex-col gap-y-3 mt-4">
        <Select
          label={"Partner"}
          placeholder="Choose a partner"
          options={partners?.map((p) => ({
            value: p.id,
            label: p.name,
          }))}
          onChange={wireEventValue((partnerId) =>
            setSelectedPartner({ id: partnerId })
          )}
          value={selectedPartner?.id}
        />
        <Select
          label={"Course"}
          placeholder="Choose a course"
          options={courses?.map((c) => ({ value: c.id, label: c.full_name }))}
          onChange={wireEventValue((c) => setSelectedCourseId(Number(c)))}
          value={selectedCourseId}
        />
        <Select
          label={"Batch"}
          placeholder="Choose batch"
          options={batches?.map((b) => ({ value: b.id, label: b.batch_name }))}
          onChange={wireEventValue((b) => setSelectedBatchId(Number(b)))}
          value={selectedBatchId}
        />

        <button
          onClick={enrolInBatch}
          className="btn-primary"
          disabled={enrollingInBatch}
        >
          {enrollingInBatch ? "Enrolling in batch..." : "Enrol in batch"}
        </button>
      </div>
    </Container>
  );
}
