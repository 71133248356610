import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

function TaxDataTable({ courseTax, className, updateCourseTaxes }) {
  const handleButtonClick = (e, row) => {
    updateCourseTaxes(row);
  };

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const columns = [
    {
      name: "Tax name",
      selector: (row, index) => `${row.tax_name}`,
      sortable: true,
    },
    {
      name: "Tax Percentage",
      selector: (row, index) => `${row.tax_percentage}`,
      sortable: true,
    },

    {
      name: "Publish",
      selector: (row, index) => `${row.tax_enable}`,
      sortable: true,
    },

    {
      cell: (row) => (
        <button
          className="bg-gray-700 p-2 text-base text-white"
          onClick={(e) => handleButtonClick(e, row)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  return (
    <div className={className}>
      <DataTable
        columns={columns}
        pagination
        data={courseTax.data?.courses_course_tax}
        progressPending={courseTax.loading}
        selectableRows={false}
        selectableRowsHighlight={false}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </div>
  );
}

export default TaxDataTable;
