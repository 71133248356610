import { useRecoilState } from "recoil";
import {
  currentModuleAccessRecoilVar,
  currentModuleRecoilVar,
} from "State/Navigation";
import { ModuleIdComponentMap } from "Contexts/Navigation/ModuleIdComponentMap";
import { gqlUserRecoilVar } from "State/User";

export default function CurrentPage(props) {
  const [user] = useRecoilState(gqlUserRecoilVar);
  const [currentModule] = useRecoilState(currentModuleRecoilVar);
  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);

  const Component = currentModule && ModuleIdComponentMap[currentModule.id];

  if (!currentModule)
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className="text-3xl font-semibold text-gray-300">
          Page not found
        </div>
      </div>
    );
  else if (!user?.is_skillstrainer_admin && !currentModuleAccess?.allow)
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className="text-3xl font-semibold text-red-600">
          You are not authorized to view this page
        </div>
      </div>
    );
  else if (!Component)
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className="text-3xl font-semibold text-gray-300">
          This page isn't available yet
        </div>
      </div>
    );
  return <Component />;
}
