import useRouterQuery from "../../../../utils/hooks/useRouterQuery";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_SCHOLARSHIP_BY_ID,
  GET_APPLICANTS_BY_SCHOLARSHIP_ID,
} from "../../../../GraphQl/Queries/Scholarships";
import { ADD_SCHOLARSHIP_SCHEMA } from "../schema";
import { GET_PARTNERS } from "../../../../GraphQl/Queries/Partner";
import { GET_PROJECTS_BY_PARTNER } from "../../../../GraphQl/Queries/Projects";
import Container from "../../../../components/Container/Container";
import Checkbox from "../../../../components/InputGroup/Checkbox";
import Input from "../../../../components/InputGroup/Input";
import Select from "react-select";
import TextArea from "../../../../components/InputGroup/TextArea";
import {
  ACCEPT_APPLICATION,
  REJECT_APPLICATION,
} from "../../../../GraphQl/Mutations/Scholarships";
import Loader from "assets/animated-images/loader";

const ViewApplicants = (props) => {
  const { data, loading } = useQuery(GET_APPLICANTS_BY_SCHOLARSHIP_ID, {
    variables: { _scholarshipid: props.scholarshipId },
  });
  const [acceptApplication, acceptedApplication] = useMutation(
    ACCEPT_APPLICATION,
    {
      refetchQueries: [
        GET_APPLICANTS_BY_SCHOLARSHIP_ID,
        {
          variables: { _scholarshipid: props.scholarshipId },
        },
      ],
    }
  );

  const [rejectApplication, rejectedApplication] = useMutation(
    REJECT_APPLICATION,
    {
      refetchQueries: [
        GET_APPLICANTS_BY_SCHOLARSHIP_ID,
        {
          variables: { _scholarshipid: props.scholarshipId },
        },
      ],
    }
  );

  const handleAcceptButtonClick = async (e, row) => {
    await acceptApplication({
      variables: { _applicationid: row.id },
    });
  };
  const handleRejectButtonClick = async (e, row) => {
    await rejectApplication({
      variables: { _applicationid: row.id },
    });
  };

  const columns = [
    {
      name: "Applicant Name",
      selector: (row, index) => `${row.user.name}`,
      width: "10rem",
      sortable: true,
    },
    {
      name: "Email Id",
      selector: (row, index) => `${row.user.email}`,
      width: "12rem",
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row, index) => `${row.user.mobile_number}`,
      width: "10rem",
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row, index) => `${row.user.gender}`,
      width: "6rem",
      sortable: true,
    },
    {
      name: "Social Category",
      selector: (row, index) => `${row.user.social_category}`,
      width: "9rem",
      sortable: true,
    },
    {
      name: "Family Income",
      selector: (row, index) =>
        `${row.user?.user_family_details_aggregate?.aggregate?.sum?.annual_income}`,
      sortable: true,
      width: "9rem",
    },
    {
      cell: (row) => {
        if (row.accept == true) {
          return <p className="text-green-500 my-auto">Accepted</p>;
        } else if (row.accept == false) {
          return <p className="text-red-700 my-auto">Rejected</p>;
        } else {
          return (
            <button
              className="accept-cta"
              onClick={(e) => handleAcceptButtonClick(e, row)}
            >
              Accept
            </button>
          );
        }
      },
      width: "6rem",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => {
        if (row.accept == null) {
          return (
            <button
              className="reject-cta"
              onClick={(e) => handleRejectButtonClick(e, row)}
            >
              Reject
            </button>
          );
        }
      },
      width: "6rem",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <Container>
      <div>
        <div className="">
          <div className="flex-row w-full">
            <div>
              {loading ? (
                <Loader color={"#999"} className={"h-24 mx-auto"} />
              ) : (
                <DataTable
                  columns={columns}
                  data={data?.courses_scholarship_partner_user}
                  pagination
                  persistTableHead
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ViewApplicants;
