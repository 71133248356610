import { client } from "App";
import { GET_ALL_MODULES } from "GraphQl/Queries/AdminUsers";
import _ from "lodash";
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { modulesRecoilVar } from "State/Resources";
import { gqlUserRecoilVar } from "State/User";

export default function ResourceContext() {
  const [user] = useRecoilState(gqlUserRecoilVar);
  const setModules = useSetRecoilState(modulesRecoilVar);

  useEffect(() => {
    if (user)
      client
        .query({
          query: GET_ALL_MODULES,
          variables: {},
        })
        .then(({ data }) =>
          setModules(
            _.cloneDeep(
              data.courses_admin_modules.filter((e) => e.key !== "empty")
            )
          )
        );
  }, [user]);

  return null;
}
