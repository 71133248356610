import { gql } from "@apollo/client";

export const CREATE_COURSE_SECTION = gql`
  mutation insertcoursesection(
    $course_id: Int
    $name: String
    $description: String
  ) {
    insert_courses_course_section(
      objects: { course_id: $course_id, name: $name, description: $description }
    ) {
      affected_rows
      returning {
        name
        course_id
        id
      }
    }
  }
`;

export const CREATE_COURSE_MODULE = gql`
  mutation insertCourseModule(
    $name: String
    $type: String
    $discription: String
    $visible: Boolean
  ) {
    insert_courses_course_module(
      objects: {
        name: $name
        type: $type
        discription: $discription
        visible: $visible
      }
    ) {
      returning {
        name
        visible
        type
        id
        discription
      }
    }
  }
`;

export const UPDATE_COURSE_SECTION_ORDER = gql`
  mutation updateCourseSectionOrder($id: Int, $order: Int) {
    update_courses_course_section(
      where: { id: { _eq: $id } }
      _set: { order: $order }
    ) {
      returning {
        course_id
      }
    }
  }
`;

export const INSERT_COURSE_CERTIFICATE = gql`
  mutation insertCourseCertificate(
    $cert_id: Int
    $course_id: Int
    $mapping_id: Int
    $cert_enable_criteria: Boolean
  ) {
    insert_courses_course_certificate(
      objects: {
        cert_id: $cert_id
        course_id: $course_id
        mapping_id: $mapping_id
        cert_enable_criteria: $cert_enable_criteria
      }
    ) {
      returning {
        cert_id
        id
      }
    }
  }
`;

export const INSERT_COURSE_COMPLETION_TOPICS = gql`
  mutation InsertCourseCompletionTopics($course_id: Int, $mapping_id: Int) {
    insert_courses_course_completion_criteria(
      objects: { course_id: $course_id, mapping_id: $mapping_id }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_COURSE_COMPLETION_CRITERIA = gql`
  mutation DeleteCourseCompletion($mapping_id: Int) {
    delete_courses_course_completion_criteria(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPSERT_CERTIFICATE_TEMPLATE_COURSE_PARTNER_RELATION = gql`
  mutation upsertCertificateTemplateCoursePartnerRelation(
    $objects: [courses_certificate_template_course_partner_relation_insert_input!]!
  ) {
    insert_courses_certificate_template_course_partner_relation(
      objects: $objects
      on_conflict: {
        constraint: certificate_template_course_partner_relation_pkey
        update_columns: [certificate_template_id, partner_id]
      }
    ) {
      affected_rows
      returning {
        id
        course_id
        partner_id
        certificate_template_id
      }
    }
  }
`;

export const DELETE_CERTIFICATE_TEMPLATE_COURSE_PARTNER_RELATION = gql`
  mutation deleteCertificateTemplateCoursePartnerRelation($ids: [bigint!]!) {
    delete_courses_certificate_template_course_partner_relation(
      where: { id: { _in: $ids } }
    ) {
      affected_rows
    }
  }
`;

// gql`
//   mutation upsert_certificateTemplate(
//     $certificate_template_id: bigint
//     $course_id: bigint
//   ) {
//     update_courses_course(
//       where: { id: { _eq: $course_id } }
//       _set: { certificate_template_id: $certificate_template_id }
//     ) {
//       returning {
//         id
//       }
//     }
//   }
// `;

export const UPDATE_COURSE_LESSON = gql`
  mutation MyMutation($id: Int, $visible: Boolean) {
    update_courses_course_section(
      where: { id: { _eq: $id } }
      _set: { visible: $visible }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_COURSE_TOPIC = gql`
  mutation MyMutation($visible: Boolean, $id: Int) {
    update_courses_course_module_mapping(
      where: { id: { _eq: $id } }
      _set: { visible: $visible }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_COURSE_TOPIC_ORDER = gql`
  mutation UpdateCourseTopicOrder($order: Int, $id: Int) {
    update_courses_course_module_mapping(
      where: { id: { _eq: $id } }
      _set: { order: $order }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_LESSON_TITLE = gql`
  mutation UpdateLessonTitle($name: String, $id: Int, $description: String) {
    update_courses_course_section(
      where: { id: { _eq: $id } }
      _set: { name: $name, description: $description }
    ) {
      affected_rows
    }
  }
`;
