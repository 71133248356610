import { atom } from "recoil";

// Auth data received from login / validate_token API
export const authRecoilVar = atom({
  key: "authRecoilVar",
  default: null,
});

// GQL user data received in auth
export const gqlUserRecoilVar = atom({
  key: "gqlUserRecoilVar",
  default: null,
});

// IDP user data received in auth
export const idpUserRecoilVar = atom({
  key: "idpUserRecoilVar",
  default: null,
});

// Contains information about what partners is the user allowed to access and with what role
export const partnerAccessRecoilVar = atom({
  key: "partnerAccessRecoilVar",
  default: null,
});

// Selected partner
export const selectedPartnerIdRecoilVar = atom({
  key: "selectedPartnerIdRecoilVar",
  default: null,
});

// Contains modules accessible
export const userAccessibleModulesRecoilVar = atom({
  key: "userAccessibleModulesRecoilVar",
  default: null,
});
