import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import EditScholarship from "./EditScholarship";

function EditPartnerModal(props) {
  return (
    <div>
      <Modal
        size="xl"
        show={props.show}
        onHide={() => props.handleClose()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Scholarship</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditScholarship
            scholarshipId={props.selectedScholarship.id}
            close={props.handleClose}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditPartnerModal;
