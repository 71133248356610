import Container from "components/Container/Container";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import Select from "components/InputGroup/Select";
import Input from "components/InputGroup/Input";
import moment from "moment";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";
import { errorHandler } from "utils/toast";

export default function PartnerSettlement(props) {
  const {
    partnerSettlementData,
    partnerOption,
    updateValue,
    submitSettlement,
  } = props;
  const [lgShow, setLgShow] = useState(false);

  const partnerAccess = useUserPartnerAccessDetails();

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });
  const columns = [
    {
      name: "Partner Id",
      selector: (row, index) => `${row.partner_id}`,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Partner Name",
      selector: (row, index) => `${row.partner.name}`,
      sortable: true,
      width: "20rem",
    },
    {
      name: "Settlement Amount",
      selector: (row, index) => `${row.amount}`,
      sortable: true,
      width: "20rem",
    },
    {
      name: "Paid Date",
      selector: (row, index) => moment(row.created_at).format("YYYY-MM-DD"),
      sortable: true,
      width: "20rem",
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  return (
    <Container>
      {lgShow ? (
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Enter Settlement Transaction
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="flex flex-wrap">
              <div className="w-1/2">
                <div className="mx-4 undefined">
                  <label className="block text-sm font-medium text-gray-700 mx-4 my-2">
                    Select Partner
                  </label>
                  <div className="mt-1 relative rounded-md">
                    <Select
                      label={""}
                      options={partnerOption}
                      valueField={"value"}
                      displayField={"label"}
                      data-key="partner_id"
                      onChange={(e) => updateValue(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="mx-4 undefined">
                  <label className="block text-sm font-medium text-gray-700 mx-4 my-2">
                    Enter amount
                  </label>
                  <div className="mt-1 relative rounded-md">
                    <Input
                      label={""}
                      type={"number"}
                      data-key={"amount"}
                      onChange={(e) => updateValue(e)}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                onClick={submitSettlement}
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange"
              >
                Enter Settlement Transaction
              </button>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          onClick={() =>
            partnerAccess?.isAdmin
              ? setLgShow(true)
              : errorHandler("You are not authorized to access this feature")
          }
          type="button"
          className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange ${
            partnerAccess.isAdmin ? "" : "opacity-20"
          }`}
        >
          Create Settlement Transaction
        </button>
      </div>
      <DataTable
        columns={columns}
        pagination
        data-key={columns}
        data={partnerSettlementData ? partnerSettlementData : []}
        paginationTotalRows={20}
        selectableRows={false}
        selectableRowsHighlight={false}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </Container>
  );
}
