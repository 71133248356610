import { useCallback, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { checkUserAuthentication } from "api/Auth";
import {
  authRecoilVar,
  gqlUserRecoilVar,
  idpUserRecoilVar,
  partnerAccessRecoilVar,
  userAccessibleModulesRecoilVar,
} from "State/User";
import { useLazyQuery } from "@apollo/client";
import {
  GET_ADMIN_PARTNER_ACCESS,
  GET_ADMIN_USER_BY_ID,
} from "GraphQl/Queries/AdminUsers";
import { error, hasura_error } from "utils/errors";
import _ from "lodash";
import { homeModule } from "./NavigationContext";

export const AuthContextController = {};

export default function AuthContext() {
  /*
   *
   *
   * Auth state variables
   *
   *
   */
  const [auth, setAuth] = useRecoilState(authRecoilVar);
  const [user, setGqlUser] = useRecoilState(gqlUserRecoilVar);
  const setIdpUser = useSetRecoilState(idpUserRecoilVar);
  const [partnerAccess, setPartnerAccess] = useRecoilState(
    partnerAccessRecoilVar
  );
  const setUserAccessibleModules = useSetRecoilState(
    userAccessibleModulesRecoilVar
  );

  /*
   *
   *
   * GQL Queries
   *
   *
   */
  const [runFetchAdminUserQuery] = useLazyQuery(GET_ADMIN_USER_BY_ID);
  const [runFetchAdminUserPartnerAccessQuery] = useLazyQuery(
    GET_ADMIN_PARTNER_ACCESS
  );

  /*
   *
   *
   * Auth state methods
   *
   *
   */

  // Check login

  AuthContextController.checkLogin = useCallback(async () => {
    const authData = await checkUserAuthentication();

    if (!authData || !authData.success) throw error({}, "User not logged in");

    setAuth(authData);
    setIdpUser(authData.user);
  }, []);

  /*
   *
   *
   * Auth state triggers
   *
   *
   */

  // On load
  useEffect(() => AuthContextController.checkLogin(), []);

  // On auth data being available
  const getUserData = useCallback(
    async (authData) => {
      try {
        if (!authData?.db_user) throw error({}, "User data not found");

        // Fetch user from gql
        const adminUserRes = await runFetchAdminUserQuery({
          variables: { userId: authData.db_user.id },
        });
        console.log(adminUserRes);
        const adminUser = _.cloneDeep(adminUserRes.data.courses_admin_users[0]);

        if (!adminUser) throw hasura_error({}, "An internal error occurred");
        setGqlUser(adminUser);

        // Fetch user partner access
        const adminPartnerAccessRes = await runFetchAdminUserPartnerAccessQuery(
          {
            variables: { userId: adminUser.id },
          }
        );
        const adminPartnerAccess = _.cloneDeep(
          adminPartnerAccessRes.data.courses_admin_user_partner_access
        );
        setPartnerAccess(adminPartnerAccess);
      } catch (err) {
        console.error(err);

        // Set all user data to null
        setAuth(null);
        setIdpUser(null);
        setGqlUser(null);
        setPartnerAccess(null);
        setUserAccessibleModules(null);
      }
    },
    [runFetchAdminUserQuery, runFetchAdminUserPartnerAccessQuery]
  );
  useEffect(() => auth && getUserData(auth), [auth]);

  useEffect(() => {
    if (!user?.is_skillstrainer_admin && partnerAccess) {
      const partners = {};

      const accessibleModules = partnerAccess.reduce((acc, cur) => {
        const { partner_id, role } = cur;
        partners[partner_id] = true;
        role.module_relations.forEach((mr) => {
          if (!acc[mr.module_id])
            acc[mr.module_id] = { allow: true, allowedPartners: {} };
          acc[mr.module_id].allowedPartners[partner_id] = true;
        });
        return acc;
      }, {});

      // Allowing access to home module for all partners
      accessibleModules[homeModule.id] = {
        allow: true,
        allowedPartners: partners,
      };
      setUserAccessibleModules(accessibleModules);
    }
  }, [user, partnerAccess]);

  return null;
}
