import React from "react";
import api from "api/Api";
import { HiRefresh } from "react-icons/hi";
import { errorHandler, successHandler } from "utils/toast";

const GenerateCertificateButton = ({ user_id, course_id }) => {
  const handleGenerateCertificate = async () => {
    try {
      successHandler(
        "Please wait while certificate is being generated, it might take a few seconds"
      );
      const response = await api().post("/generate_certificate", {
        user_id: user_id,
        course_id: course_id,
      });
      if (response.data?.success) {
        successHandler(
          "Certificate has been generated successfully, please refresh the page"
        );
      } else {
        errorHandler(
          "There was an error while generating certificate, please contact the tech team"
        );
      }
    } catch (error) {
      console.error("Error generating certificate:", error);
    }
  };

  return (
    <HiRefresh onClick={handleGenerateCertificate}>
      Generate Certificate
    </HiRefresh>
  );
};

export default GenerateCertificateButton;
