import { gql } from "@apollo/client";

export const GET_QUIZ_QUESTIONS = gql`
  query GetQuizQuestions(
    $question_category: Int_comparison_exp = {}
    $question_type: Int_comparison_exp = {}
  ) {
    courses_questions(
      where: {
        _or: {
          question_category: $question_category
          _or: { question_type: $question_type }
        }
      }
      order_by: { id: desc }
    ) {
      id
      question_level
      question_text
      question_type
      question_cat {
        name
      }
      quest_answer {
        id
        question_id
        answer_text
        isAnswer
      }
      qusetion_tag {
        question_tag {
          name
        }
      }
    }
  }
`;

export const GET_SKILLSTRAINER_LANGUAGES = gql`
  query getLanguages {
    courses_skillstrainer_languages {
      name
      id
    }
  }
`;

export const GET_MODULE_TAGS = gql`
  query getModuleTags($moduleId: Int) {
    courses_course_module_mapping(where: { id: { _eq: $moduleId } }) {
      id
      tags
    }
  }
`;

export const GET_QUESTION_BY_ID = gql`
  query getQuestionById($id: Int) {
    courses_questions(where: { id: { _eq: $id } }) {
      id
      marks
      question_text
      question_type
      question_level
      question_category
      visible
      mode
      question_cat {
        id
        name
      }
      qusetion_tag {
        id
        tag_id
        question_id
      }
      quest_answer {
        id
        answer_text
        category
        question_id
        answer_description
        isAnswer
        system_filename
        answer_img
      }
    }
  }
`;

export const GET_QUIZ_QUESTIONS_BY_QID = gql`
  query MyQuery {
    courses_questions {
      id
      marks
      question_text
      question_type
      question_level
      question_category
      visible
      quest_answer {
        category
        id
        answer_text
        answer_description
        isAnswer
        answer_img
      }
    }
  }
`;

export const GET_MODULE_QUESTIONS = gql`
  query getCourseQuiz($module_mapping_id: Int) {
    courses_questions(
      where: {
        quest_course: { module_mapping_id: { _eq: $module_mapping_id } }
      }
    ) {
      question_text
      question_type
      id
      marks
      quest_answer {
        category
        isAnswer
        question_id
        answer_text
        id
        answer_description
        answer_img
      }
      question_cat {
        name
      }
      question_level
    }
  }
`;
export const DELETE_QUESTION_FROM_QUIZ = gql`
  mutation removeQuestionFromQuiz($mappingId: Int, $questionId: Int) {
    delete_courses_course_question_link(
      where: {
        question_id: { _eq: $questionId }
        module_mapping_id: { _eq: $mappingId }
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_QUESTION_TAGS_AND_CATEGORY = gql`
  query getQuestionTagsAndCategories {
    courses_question_tags {
      id
      name
    }
    courses_question_category {
      id
      name
    }
  }
`;
