import { useQuery } from "@apollo/client";
import { client } from "App";
import MultiSelectDropdown from "components/InputGroup/MultiselectDropdown";
import {
  ATTACH_USER_TO_PARTNER_PROJECT,
  DETACH_USER_FROM_PARTNER_PROJECT,
} from "GraphQl/Mutations/User";
import { GET_PROJECTS_WITH_FILTER } from "GraphQl/Queries/Projects";
import { useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { currentModuleAccessRecoilVar } from "State/Navigation";
import { gqlUserRecoilVar } from "State/User";
import usePromise from "utils/hooks/usePromise";
import { errorHandler, successHandler } from "utils/toast";

// User management pages common imports
import Container from "components/Container/Container";
import { Modal } from "skillstrainer-resource-library";
import TraineeList from "components/TraineeList/TraineeList";
import Select from "components/InputGroup/Select";
import { wireEventValue } from "utils/func";
import { USER_ACTIONS } from "../components/ManageUsers/Consts";

export default function HandleProjectUser() {
  const [user] = useRecoilState(gqlUserRecoilVar);
  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);
  const accessiblePartners = useMemo(
    () =>
      currentModuleAccess
        ? Object.keys(currentModuleAccess?.allowedPartners).map(Number)
        : [],
    [currentModuleAccess]
  );

  const [action, setAction] = useState(USER_ACTIONS.attachToProject);
  const [isTraineeListOpen, setIsTraineeListOpen] = useState();
  const [users, setSelectedUsers] = useState([]);

  /*
   *
   *
   * Fetching data
   *
   *
   */

  // Projects
  const { data: projectsRes } = useQuery(GET_PROJECTS_WITH_FILTER, {
    variables: { filterClause: {} },
  });
  const projects = useMemo(
    () =>
      projectsRes?.courses_partner_projects.filter(
        (p) => user?.is_skillstrainer_admin || accessiblePartners.includes(p.id)
      ),
    [projectsRes, accessiblePartners, user]
  );

  /*
   *
   *
   * State
   *
   *
   */
  const [selectedProjects, setSelectedProjects] = useState([]);

  /*
   *
   *
   * Actions
   *
   *
   */

  // Submit
  const { run: submit, loading } = usePromise(async () => {
    let response;

    try {
      if (action === USER_ACTIONS.attachToProject) {
        const payload = users.reduce(
          (acc, userId) => {
            selectedProjects.forEach((projectId) => {
              const partner_id = projects.find(
                (p) => p.id === projectId
              ).partner_id;

              acc.partnerProjectUserObjects.push({
                partner_id,
                project_id: projectId,
                user_id: userId,
              });

              acc.partnerUserObjects.push({
                partner_id,
                user_id: userId,
              });
            });
            return acc;
          },
          { partnerProjectUserObjects: [], partnerUserObjects: [] }
        );

        response = await client
          .mutate({
            mutation: ATTACH_USER_TO_PARTNER_PROJECT,
            variables: payload,
          })
          .then(({ data }) => ({
            partnerProjectUsers:
              data.insert_courses_partner_project_users.returning,
            partnerUsers: data.insert_courses_partner_users.returning,
          }));
      } else {
        response = await client
          .mutate({
            mutation: DETACH_USER_FROM_PARTNER_PROJECT,
            variables: {
              projectIds: selectedProjects,
              userIds: users,
            },
          })
          .then(
            ({ data }) =>
              data.delete_courses_partner_project_users.affected_rows
          );
      }

      console.log(response);
      successHandler("Success!");
      return response;
    } catch (err) {
      console.log(err);
      errorHandler("Couldn't perform action");
    }
  }, [users, projects, selectedProjects]);

  return (
    <Container title="Handle Trainee Project Association">
      <div className="mt-4 flex items-center gap-3">
        <button
          onClick={() => setIsTraineeListOpen(true)}
          className="btn-primary"
        >
          Choose trainees
        </button>
        <span>
          {users.length
            ? `${users.length} users selected`
            : "No users selected"}
        </span>
      </div>
      <Modal isOpen={isTraineeListOpen} onClose={() => setIsTraineeListOpen()}>
        <div className="px-4">
          <TraineeList
            selectable
            onComplete={(userIds) => {
              setSelectedUsers([...userIds]);
              setIsTraineeListOpen();
            }}
          />
        </div>
      </Modal>
      <div className="flex gap-3 items-center mt-3">
        <Select
          options={[
            {
              value: USER_ACTIONS.attachToProject,
              label: "Attach trainees to",
            },
            {
              value: USER_ACTIONS.detachFromProject,
              label: "Detach trainees from",
            },
          ]}
          onChange={wireEventValue(setAction)}
          value={action}
        />
        <MultiSelectDropdown
          options={
            projects && projects.map((p) => ({ label: p.name, value: p.id }))
          }
          className={"w-60"}
          setSelectedValue={setSelectedProjects}
          selectedValue={selectedProjects}
        />
        <button
          onClick={submit}
          disabled={loading}
          className={`btn-primary ${loading ? "bg-gray-200" : ""}`}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </Container>
  );
}
