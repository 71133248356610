import { useCallback, useState } from "react";

export default function usePromise(callback, deps) {
  const callbackFn = useCallback(callback, deps);

  const [loading, setLoading] = useState();
  const [result, setResult] = useState();
  const [error, setError] = useState();

  const run = async (...args) => {
    setLoading(true);
    const response = await callbackFn(...args)
      .then(setResult)
      .catch(setError);
    setLoading(false);
    return response;
  };

  return { run, loading, result, error };
}
