import React from "react";
import Modal from "react-bootstrap/Modal";

function CertificateTemplatePreviewModal({ show, handleClose, templateData }) {
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Certificate Template Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray border-4 border-gray-200">
          <iframe
            height={"450"}
            title={"Certificate Preview"}
            srcDoc={templateData}
            className={"w-full"}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-secondary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
//code to fit content in an iframe of fixed size
export default CertificateTemplatePreviewModal;
