import { useMutation, useQuery } from "@apollo/client";
import MultiSelectDropdown from "components/InputGroup/MultiselectDropdown";
import {
  ATTACH_USERS_TO_PARTNER,
  DETACH_USERS_FROM_PARTNER,
} from "GraphQl/Mutations/User";
import { GET_PARTNERS_WITH_FILTER } from "GraphQl/Queries/Partner";
import { useState, useMemo } from "react";
import { useRecoilState } from "recoil";
import { currentModuleAccessRecoilVar } from "State/Navigation";
import { gqlUserRecoilVar } from "State/User";
import usePromise from "utils/hooks/usePromise";
import { errorHandler, successHandler } from "utils/toast";

// User management pages common imports
import Container from "components/Container/Container";
import { Modal } from "skillstrainer-resource-library";
import TraineeList from "components/TraineeList/TraineeList";
import Select from "components/InputGroup/Select";
import { wireEventValue } from "utils/func";
import { USER_ACTIONS } from "../components/ManageUsers/Consts";

export default function HandlePartnerUser() {
  const [user] = useRecoilState(gqlUserRecoilVar);
  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);
  const accessiblePartners = useMemo(
    () =>
      currentModuleAccess
        ? Object.keys(currentModuleAccess.allowedPartners).map(Number)
        : [],
    [currentModuleAccess]
  );

  const [action, setAction] = useState(USER_ACTIONS.attachToPartner);
  const [isTraineeListOpen, setIsTraineeListOpen] = useState();
  const [users, setSelectedUsers] = useState([]);

  const { data: partnersRes } = useQuery(GET_PARTNERS_WITH_FILTER, {
    variables: { filterClause: {} },
  });
  const partners = useMemo(
    () =>
      partnersRes?.courses_partner.filter(
        (p) => user?.is_skillstrainer_admin || accessiblePartners.includes(p.id)
      ),
    [partnersRes, user, accessiblePartners]
  );

  const [selectedPartners, setSelectedPartners] = useState([]);

  // Attach users to partner
  const [runAttachUsersToPartnerMutation] = useMutation(
    ATTACH_USERS_TO_PARTNER
  );

  // Detach users from partner
  const [runDetachUsersFromPartnerMutation] = useMutation(
    DETACH_USERS_FROM_PARTNER
  );

  // Submit function
  const { run: submit, loading: submitting } = usePromise(async () => {
    try {
      let response;

      if (action === USER_ACTIONS.attachToPartner) {
        // Attach to partner

        const objects = users.reduce((acc, userId) => {
          partners.forEach((p) => {
            acc.push({ user_id: userId, partner_id: p.id });
          });
          return acc;
        }, []);

        response = await runAttachUsersToPartnerMutation({
          variables: { objects },
        }).then(({ data }) => data.insert_courses_partner_users.returning);
      } else {
        // Detach from partner

        response = await runDetachUsersFromPartnerMutation({
          variables: {
            partnerIds: selectedPartners,
            userIds: users,
          },
        }).then(({ data }) => data.delete_courses_partner_users.affected_rows);
      }

      console.log(response);
      successHandler("Success!");
      return response;
    } catch (err) {
      console.error(err);
      errorHandler("Couldn't perform action");
    }
  }, [users, partners, selectedPartners]);

  return (
    <Container title="Handle Trainee Partner Assocation">
      <div className="mt-4 flex items-center gap-3">
        <button
          onClick={() => setIsTraineeListOpen(true)}
          className="btn-primary"
        >
          Choose trainees
        </button>
        <span>
          {users.length
            ? `${users.length} users selected`
            : "No users selected"}
        </span>
      </div>
      <Modal isOpen={isTraineeListOpen} onClose={() => setIsTraineeListOpen()}>
        <div className="px-4">
          <TraineeList
            selectable
            onComplete={(userIds) => {
              setSelectedUsers([...userIds]);
              setIsTraineeListOpen();
            }}
          />
        </div>
      </Modal>

      <div className="flex gap-3 items-center mt-3">
        <Select
          options={[
            {
              value: USER_ACTIONS.attachToPartner,
              label: "Attach trainees to",
            },
            {
              value: USER_ACTIONS.detachFromPartner,
              label: "Detach trainees from",
            },
          ]}
          onChange={wireEventValue(setAction)}
          value={action}
        />

        <MultiSelectDropdown
          options={
            partners && partners.map((p) => ({ label: p.name, value: p.id }))
          }
          className={"w-60"}
          setSelectedValue={setSelectedPartners}
          selectedValue={selectedPartners}
        />
        <button
          onClick={submit}
          disabled={submitting}
          className={`btn-primary ${submitting ? "bg-gray-200" : ""}`}
        >
          {submitting ? "Submitting..." : "Submit"}
        </button>
      </div>
    </Container>
  );
}
