import { resolvers } from "../api/pincode-resolvers";
import countries from "./resources/countries.json";

// Address
export const getCountries = () =>
  countries.map((c) => ({
    id: c["alpha-3"],
    name: c["name"],
    iso: c["alpha-3"],
  }));

export const getDataFromPincode = async ({ country, pincode }) => {
  return resolvers[country] && (await resolvers[country](pincode));
};

export const getModuleTreeFromModuleList = (moduleIdList, allModules) => {
  const roots = [];
  const moduleDependancyMap = getModuleDependancyMap(moduleIdList, allModules);

  for (const module in moduleDependancyMap) {
    if (!moduleDependancyMap[module].parent_module_id)
      roots.push(moduleDependancyMap[module]);
  }

  return roots;
};

export const getModuleDependancyMap = (moduleIdList, allModules) => {
  const modulesMap = {};

  const resolveModule = (module_id) => {
    if (modulesMap[module_id]) return modulesMap[module_id];
    else {
      const module = allModules.find((m) => m.id === module_id);
      if (!module) return;

      module.submodules = [];

      if (module.parent_module_id) {
        const parent_module = resolveModule(module.parent_module_id);
        if (!parent_module) return;

        parent_module.submodules.push(module);
        module.parent_module = parent_module;
      }
      modulesMap[module_id] = module;
      return module;
    }
  };

  for (const moduleId of moduleIdList) resolveModule(moduleId);

  return modulesMap;
};
