export class JobHelper {
  job = {};

  constructor(formJobObject) {
    this.job = formJobObject;
  }

  setJobId(jobId) {
    this.job.jobId = jobId;
  }
  /**
   * converts object from form builder to object to be used in mutation
   * @returns object for mutation on skillstrainer_jobs table
   */
  reformatJobObject() {
    const skillstrainer_jobs_object = {
      partner_id: this.job.partner_id,
      project_id: this.job.project_id,
      title: this.job.title,
      associated_job_role_id: this.job.associated_job_role_id,
      salary_ctc: this.job.salary_ctc,
      description: this.job.description,
      min_age: this.job.min_age,
      max_age: this.job.max_age,
      min_experience: this.job.min_experience,
      max_experience: this.job.max_experience,
      no_of_positions: this.job.no_of_positions,
      sector_id: this.job.sector,
      family_income: this.job.family_income,
      social_categories: this.job.social_categories,
      shift_start_timing: this.job.shift_start_timing,
      shift_end_timing: this.job.shift_end_timing,
      gender: this.job.gender,
      marital_status: this.job.marital_status,
      is_active: this.job.is_active,
      is_remote: this.job.is_remote,
      otherpartner_id: this.job.otherpartner_id,
    };

    return skillstrainer_jobs_object;
  }
  /**
   * converts object from form builder to object to be used in mutation
   * In case of repeated qualifications, it gets merged into a single qualification entry with required set to true
   * @returns array for mutation on job_qualifications table
   */
  reformatQualifications() {
    const requiredQualificationsArray = Object.values(
      this.removeUndefinedKeys(this.job.academic_qualifications || {})
    ).concat(
      Object.values(
        this.removeUndefinedKeys(this.job.technical_qualifications || {})
      )
    );

    //removing undefined keys from object
    const preferredQualificationArray = Object.values(
      this.removeUndefinedKeys(this.job.preferred_academic_qualifications || {})
    ).concat(
      Object.values(
        this.removeUndefinedKeys(
          this.job.preferred_technical_qualifications || {}
        )
      )
    );

    //handling repeated qualifications, giving priority to required qualifications -> taking difference
    const finalPreferredQualifications = preferredQualificationArray
      .filter((e) => e !== undefined)
      .filter((e) => !requiredQualificationsArray.includes(e))
      .map((q) => {
        return {
          job_id: this.job.jobId,
          required: false,
          qualification_id: q,
          qualification_type: q,
        };
      });

    const finalRequiredQualificationsArray = requiredQualificationsArray.map(
      (q) => {
        return {
          job_id: this.job.jobId,
          required: true,
          qualification_id: q,
          qualification_type: q,
        };
      }
    );
    const qualificationsArray = [
      ...finalPreferredQualifications,
      ...finalRequiredQualificationsArray,
    ]
      .filter((q) => q.qualification_id !== "Choose an option")
      .filter((q) => q.qualification_id && q.qualification_id !== null);

    return qualificationsArray;
  }
  /**
   * converts object from form builder to object to be used in mutation
   * @returns array for mutation on job_languages_required table
   */
  reformatLanguages() {
    const languagesArray = (this.job.languages || []).map((language) => {
      return {
        job_id: this.job.jobId,
        language_id: language.language,
        can_read: language.proficiency.find((item) => item === "can_read")
          ? true
          : false,
        can_write: language.proficiency.find((item) => item === "can_write")
          ? true
          : false,
        can_speak: language.proficiency.find((item) => item === "can_speak")
          ? true
          : false,
        required: language.required || false,
      };
    });

    return languagesArray;
  }
  /**
   * converts object from form builder to object to be used in mutation
   * @returns array for mutation on job_required_skills_criteria table
   */
  reformatSkills() {
    const skillsArray = (this.job.skills || []).map((skill) => {
      return {
        job_id: this.job.jobId,
        required: skill.required || false,
        st_skill_id: skill.skill,
      };
    });

    return skillsArray;
  }
  /**
   * converts object from form builder to object to be used in mutation
   * @returns array for mutation on job_locations table
   */
  reformatLocations() {
    const locationsArray = (this.job.locations || []).map((location) => {
      return {
        job_id: this.job.jobId,
        num_of_positions: location.no_of_positions,
        city: location.city.city_town || "",
        pin_code: location.city.pincode || "",
        country: location.city.country || "",
        district: location.city.district || "",
      };
    });

    return locationsArray;
  }
  /**
   * converts object from form builder to object to be used in mutation
   * @returns array for mutation on job_documents table
   */
  reformatDocuments() {
    const documentsArray = (this.job.documents || []).map((document) => {
      return {
        job_id: this.job.jobId,
        document_id: document.document,
        required: document.required || false,
      };
    });

    return documentsArray;
  }
  /**
   * removes undefined keys from an object
   * @param {any object with some undefined keys} obj
   * @returns an object without undefined keys
   */
  removeUndefinedKeys(obj) {
    for (let key in obj) {
      if (obj[key] === undefined || obj[key] === "undefined") {
        delete obj[key];
      }
    }

    return obj;
  }
  /**
   * formats data from query to job form builder format
   * @param {data/response from GET_JOB_DATA_BY_ID query} jobData
   * @returns reformatted object which acts as init values for job form builder
   */
  reformatQueryValues(jobData) {
    const skillstrainerJobData = jobData?.data.courses_skillstrainer_jobs[0];
    const qualificationsData = jobData?.data.courses_job_qualifications;
    const languagesData = jobData?.data.courses_job_languages_required;
    const skillsData = jobData?.data.courses_job_required_skills_criteria;
    const locationData = jobData?.data.courses_job_location;
    const documentsData = jobData?.data.courses_job_documents;
    const jobInitObject = {
      partner_id: skillstrainerJobData?.partner_id,
      associated_job_role_id: skillstrainerJobData?.associated_job_role_id,
      project_id: skillstrainerJobData?.project_id,
      family_income: skillstrainerJobData?.family_income,
      title: skillstrainerJobData?.title,
      no_of_positions: skillstrainerJobData?.no_of_positions,
      min_age: skillstrainerJobData?.min_age,
      max_age: skillstrainerJobData?.max_age,
      min_experience: skillstrainerJobData?.min_experience,
      max_experience: skillstrainerJobData?.max_experience,
      sector: skillstrainerJobData?.sector_id,
      social_categories: skillstrainerJobData?.social_categories.map(Number),
      shift_start_timing:
        skillstrainerJobData?.shift_start_timing.split("+")[0],
      shift_end_timing: skillstrainerJobData?.shift_end_timing.split("+")[0],
      salary_ctc: skillstrainerJobData?.salary_ctc,
      description: skillstrainerJobData?.description,
      gender: skillstrainerJobData?.gender,
      marital_status: skillstrainerJobData?.marital_status,
      otherpartner_id: skillstrainerJobData?.otherpartner_id,
      is_active: skillstrainerJobData?.is_active,
      academic_qualifications: {
        school: String(
          qualificationsData?.find((q) => {
            if (
              (q.qualification.type === "12" ||
                q.qualification.type === "pre_12") &&
              q.required
            ) {
              return q.qualification_id;
            } else return false;
          })?.qualification_id
        ),
        bachelors: String(
          qualificationsData?.find((q) => {
            if (q.qualification.type === "bachelors" && q.required) {
              return q.qualification_id;
            } else return false;
          })?.qualification_id
        ),
        masters: String(
          qualificationsData?.find((q) => {
            if (q.qualification.type === "masters" && q.required) {
              return q.qualification_id;
            } else return false;
          })?.qualification_id
        ),
      },
      preferred_academic_qualifications: {
        school: String(
          qualificationsData?.find((q) => {
            if (
              (q.qualification.type === "12" ||
                q.qualification.type === "pre_12") &&
              !q.required
            ) {
              return q.qualification_id;
            } else return false;
          })?.qualification_id
        ),
        bachelors: String(
          qualificationsData?.find((q) => {
            if (q.qualification.type === "bachelors" && !q.required) {
              return q.qualification_id;
            } else return false;
          })?.qualification_id
        ),
        masters: String(
          qualificationsData?.find((q) => {
            if (q.qualification.type === "masters" && !q.required) {
              return q.qualification_id;
            } else return false;
          })?.qualification_id
        ),
      },
      preferred_technical_qualifications: {
        iti: String(
          qualificationsData?.find((q) => {
            if (q.qualification.type === "iti" && !q.required) {
              return q.qualification_id;
            } else return false;
          })?.qualification_id
        ),
        diploma: qualificationsData?.find((q) => {
          if (q.qualification.type === "diploma" && !q.required) {
            return q.qualification_id;
          } else return false;
        })?.qualification_id,
        nsqf: String(
          qualificationsData?.find((q) => {
            if (q.qualification.type === "nsqf" && !q.required) {
              return q.qualification_id;
            } else return false;
          })?.qualification_id
        ),
      },
      technical_qualifications: {
        iti: String(
          qualificationsData?.find((q) => {
            if (q.qualification.type === "iti" && q.required) {
              return q.qualification_id;
            } else return false;
          })?.qualification_id
        ),
        diploma: String(
          qualificationsData?.find((q) => {
            if (q.qualification.type === "diploma" && q.required) {
              return q.qualification_id;
            } else return false;
          })?.qualification_id
        ),
        nsqf: String(
          qualificationsData?.find((q) => {
            if (q.qualification.type === "nsqf" && q.required) {
              return q.qualification_id;
            } else return false;
          })?.qualification_id
        ),
      },
      languages: languagesData?.map((item) => {
        let proficiency = [];
        if (item.can_speak) proficiency.push("can_speak");
        if (item.can_write) proficiency.push("can_write");
        if (item.can_read) proficiency.push("can_read");
        return {
          language: String(item.language_id),
          proficiency: proficiency,
          required: item.required,
        };
      }),
      skills: skillsData?.map((item) => {
        return {
          skill: String(item.st_skill_id),
          required: item.required,
        };
      }),
      locations: locationData?.map((item) => {
        return {
          city: {
            country: item.country,
            pincode: item.pin_code,
            city_town: item.city,
            district: item.district,
          },
          no_of_positions: item.num_of_positions,
        };
      }),
      documents: documentsData?.map((doc) => {
        return {
          //typecasting to accomdate aadhar whose document_id is 0
          document: String(doc.document_id),
          required: doc.required,
        };
      }),
    };

    return jobInitObject;
  }
  /**
   * compares arrays of objects, of, intial data state and data after updation, to return which entries are consistent, new, and which are to be deleted
   * @param {*array of objects containing intial data} oldArr
   * @param {*array of objects containing data after updation} newArr
   * @param {*key of an object, on the basis of which two objects are to be compared} comparisionKey
   * @returns an object containing three arrays of objects:
   *                                consistentArray: array with entries which remain same in both oldArr and newArr
   *                                deletedArray:  array with entries which are to be deleted, in oldArr but not in new Arr
   *                                newArray: array with new entries which are to be inserted, in newArr but not in oldArr
   */
  arrayDiff(oldArr, newArr, comparisionKey) {
    let consistentArray = [];
    (newArr || []).forEach((newItem) => {
      (oldArr || []).forEach((oldItem) => {
        if (newItem[comparisionKey] === oldItem[comparisionKey])
          consistentArray.push(newItem);
      });
    });

    let deletedArray = (oldArr || [])
      .filter(
        (q) => !newArr.find((p) => p[comparisionKey] === q[comparisionKey])
      )
      .map((q) => q);

    let newArray = (newArr || [])
      .filter(
        (q) => !oldArr.find((p) => p[comparisionKey] === q[comparisionKey])
      )
      .map((q) => q);

    return { consistentArray, deletedArray, newArray };
  }
  /**
   * Inserts ids in array of objects which are to be upserted or deleted
   * @param {*array of objects from query which contains ids } queryArray
   * @param {*array of objects in which ids are to be inserted } array
   * @param {* key of an object, on the basis of which ids are to be inserted} comparisionKey
   * @returns
   */
  fillInIdsForUpdate(queryArray, array, comparisionKey) {
    return array.map((item) => {
      queryArray.forEach((q) => {
        if (q[comparisionKey] === Number(item[comparisionKey])) item.id = q.id;
      });
      return item;
    });
  }
  /**
   * takes arrays of both old and updated data(array of object), with query data to return an upsertable and deletable array of objects
   * @param {*array of objects with old data/intial data/ data before updation} oldArr
   * @param {* array of objects with data after updation} newArr
   * @param {* key of an object, on the basis of which two objects can be compared} comparisionKey
   * @param {* data form query which gets data in oldArr} queryData
   * @returns
   */
  getUpsertableAndDeletableArray(oldArr, newArr, comparisionKey, queryData) {
    const { consistentArray, deletedArray, newArray } = this.arrayDiff(
      oldArr || [],
      newArr || [],
      comparisionKey
    );
    const deletableArray = this.fillInIdsForUpdate(
      queryData || [],
      deletedArray || [],
      comparisionKey
    ).map((item) => item.id);
    const consistentQualificationsArray = this.fillInIdsForUpdate(
      queryData || [],
      consistentArray || [],
      comparisionKey
    );
    const upsertableArray = [...consistentQualificationsArray, ...newArray];

    return { upsertableArray, deletableArray };
  }
}
