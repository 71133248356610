import { useState, useEffect } from "react";
import * as yup from "yup";

function InstituteFormBuilder(values) {
  const [schema, setSchema] = useState();

  useEffect(() => {
    const schema = {
      name: {
        label: "Institute Name",
        type: "text",
        required: true,
        schema: yup.string(),
      },
      category: {
        label: "Institute Category",
        type: "select",
        options: [
          { label: "College Private", value: "college" },
          { label: "College Govt.", value: "private_college" },
          { label: "Govt. Department", value: "govt._department" },
          { label: "Indian Large Corporate", value: "indian_large_corporate" },
          { label: "Indian MSME", value: "MSME" },
          { label: "ITI Private", value: "private_ITI" },
          { label: "ITI Govt.", value: "public_ITI" },
          {
            label: "Multi-national Corporation",
            value: "multi_national_corporation",
          },
          { label: "Polytechnic Private", value: "private_polytechnic" },
          { label: "Polytechnic Govt.", value: "public_polytechnic" },
          { label: "Sector Skill Council", value: "sector_skill_council" },
          { label: "School Private", value: "private_school" },
          { label: "School Govt.", value: "public_school" },
          {
            label: "Social Organisation/NGO",
            value: "social_organisation_ngo",
          },
          {
            label: "Training/ Coaching Center",
            value: "training_coaching_center",
          },
          { label: "University Private", value: "private_university" },
          { label: "University Govt.", value: "public_university" },

          { label: "Other", value: "other" },
        ],
      },

      address: {
        label: "Address",
        type: "address",
        keys: {
          action: "include",
          keys: ["state", "district", "city_town", "house_number", "location"],
        },
        schema: yup.object(),
      },
    };
    setSchema(schema);
  }, [values]);

  return schema;
}

export default InstituteFormBuilder;
