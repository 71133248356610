import * as yup from "yup";
import { COURSES_QUERY } from "GraphQl/Queries/Courses";
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";

function PartnerWebsiteConfigFormBuilder(values) {
  const [schema, setSchema] = useState();
  const [selectedDataType, setSelectedDataType] = useState("text");
  const { data: courseData } = useQuery(COURSES_QUERY);

  useEffect(() => {
    values.partner_info?.map((item, index) => {
      if (
        index === values.partner_info.length - 1 &&
        item.partner_data_type === "partner_display_logo"
      ) {
        setSelectedDataType("file");
      } else {
        setSelectedDataType("text");
      }
    });
  }, [values.partner_info]);

  useEffect(() => {
    const schema = {
      subdomain: {
        label: "Subdomain",
        type: "text",
        required: true,
        schema: yup
          .string()
          .matches(
            /^[a-z0-9.-]{3,63}$/,
            "Subdomain can only contain lowercase letters, numbers, periods (.) or dashes (-)"
          ),
      },
      hero_section_headline: {
        label: "Hero Section Headline",
        type: "textarea",
        required: true,
        schema: yup.string(),
      },
      hero_section_font_size: {
        label: "Hero Section Font Size",
        type: "select",
        required: false,
        options: [
          {
            label: "1",
            value: 1,
          },
          {
            label: "2",
            value: 2,
          },
          {
            label: "3",
            value: 3,
          },

          {
            label: "4",
            value: 4,
          },

          {
            label: "5 (default)",
            value: 5,
          },
        ],
      },
      partner_display_name: {
        label: "Partner Display Name",
        type: "text",
        required: true,
        schema: yup.string(),
      },
      partner_website_link: {
        label: "Partner website link",
        schema: yup.string().url(),
      },
      partner_display_logo: {
        label: "Display Logo",
        type: "file",
        required: true,
        schema: yup.array().of(
          yup.object({
            id: yup.string(),
            url: yup.string(),
            name: yup.string(),
          })
        ),
      },
      courses: {
        type: "object",
        required: true,
        label: "Courses and Revenue Split",
        repeat: true,
        insertable: true,
        fields: {
          id: {
            type: "hidden",
            schema: yup.string(),
          },
          partner_id: {
            type: "hidden",
            schema: yup.string(),
          },
          course_id: {
            label: "Course",
            type: "select",
            required: true,
            options: courseData?.courses_course
              .map((course) => ({
                value: course.id,
                label: course.full_name,
              }))
              .sort((a, b) => {
                return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
              }),
            schema: yup.string(),
          },
          commission: {
            label: "Partner Commission (%)",
            type: "number",
            schema: yup.number().min(0).max(100),
          },
        },
      },
      associated_partners: {
        label: "Associated Partners",
        type: "object",
        repeat: true,
        insertable: true,
        fields: {
          associated_partner_name: {
            label: "Associated Partner Name",
            type: "text",
            schema: yup.string(),
          },
          associated_partner_logo: {
            label: "Associated Partner Logo",
            type: "file",
            schema: yup.array().of(
              yup.object({
                id: yup.string(),
                url: yup.string(),
                name: yup.string(),
              })
            ),
          },
        },
      },
      sliders: {
        label: "Sliders",
        type: "object",
        repeat: true,
        insertable: true,
        fields: {
          header_text: {
            label: "Small Slider Header Text",
            type: "text",
            schema: yup.string(),
          },
          content_text: {
            label: "Small Slider Content Text",
            type: "text",
            schema: yup.string(),
          },
          footer_text: {
            label: "Small Slider Footer Text",
            type: "text",
            schema: yup.string(),
          },
          slider_image: {
            label: "Slider Image",
            type: "file",
            schema: yup.array().of(
              yup.object({
                id: yup.string(),
                url: yup.string(),
                name: yup.string(),
              })
            ),
          },
        },
      },
      id: {
        type: "hidden",
        schema: yup.string(),
      },
    };
    setSchema(schema);
  }, [values, selectedDataType, courseData]);

  return schema;
}

export default PartnerWebsiteConfigFormBuilder;
