import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import Container from "../../../components/Container/Container";
import { courseFields } from "./DbScchemas";
import CourseTable from "./components/CourseTable";
import { COURSE_ENABLE_DISABLE } from "GraphQl/Mutations/Courses";
import { successHandler, errorHandler } from "utils/toast";
import courseBuilderService from "services/coursebuild/course";
import courseSvc from "services/course";
import { arrayDelta } from "utils/func";
import {
  CREATE_COURSE_SECTION,
  INSERT_COURSE_COMPLETION_TOPICS,
} from "GraphQl/Mutations/coursebuildemutation/cousresection";

import {
  CREATE_COURSE_SECTION_TOPICS,
  DELETE_COURSE,
} from "GraphQl/Mutations/coursebuildemutation/CourseModule";

import {
  COURSES_QUERY,
  GET_PARTNER_COURSES,
  GET_PARTNER_TOTAL_COURSES,
  GET_TOTAL_COURSES,
} from "../../../GraphQl/Queries/Courses";
import { LINK_QUESTION_TO_COURSE } from "GraphQl/Mutations/coursebuildemutation/Questions";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";

// const columns = Object.keys(courseFields)
//   .map((key) => courseFields[key])
//   .reduce((allItems, item) => {
//     return allItems.concat(item);
//   }, [])
//   .map(({ column_name, label }) => {
//     return { column_name, label };
//   })
//   .filter(({ column_name }) => {
//     return ["id", "identifier", "updated_at"].indexOf(column_name) < 0;
//   })
//   .slice(0, 3);

// columns.push({
//   column_name: "id",
//   label: "Course Id",
// });

function ManageCourses() {
  const [catFilter, setCatFilter] = useState(0);
  const [hideFilter, setHideFilter] = useState(true);

  const partnerAccess = useUserPartnerAccessDetails();

  // Get all Courses
  const [runQuery, data] = useLazyQuery(
    partnerAccess.isAdmin ? COURSES_QUERY : GET_PARTNER_COURSES,
    {
      fetchPolicy: "network-only", // Doesn't check cache before making a network request
    }
  );

  //Hide and Show course
  const [hideCourse] = useMutation(COURSE_ENABLE_DISABLE, {
    refetchQueries: () => [
      {
        query: COURSES_QUERY,
        variables: {},
      },
    ],
    onCompleted: (data) => {
      successHandler("Course Updated successfully!");
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Delete
  const [deleteCourse] = useMutation(DELETE_COURSE, {
    refetchQueries: () => [
      {
        query: COURSES_QUERY,
        variables: {},
      },
    ],
    onCompleted: () => {
      successHandler("Course delete processing wait...");
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Add lessons
  const [addLesson] = useMutation(CREATE_COURSE_SECTION, {
    onError: () => errorHandler("An error occurred"),
  });

  //Lesson Copy Data
  const [lessonTopicCopy] = useMutation(CREATE_COURSE_SECTION_TOPICS, {
    onError: () => errorHandler("An error occurred"),
  });

  //Link quiz topic questions
  const [link_questions_to_course] = useMutation(LINK_QUESTION_TO_COURSE);

  // const totalCourses = useQuery(GET_TOTAL_COURSES);
  // const partnerTotalCourses =
  //   !partnerAccess.isAdmin &&
  //   useQuery(GET_PARTNER_TOTAL_COURSES, {
  //     variables: { partnerIds: partnerAccess.partnerIds },
  //   });

  //Insert course completion topic setting

  const [InsertCourseComletionTopics] = useMutation(
    INSERT_COURSE_COMPLETION_TOPICS,
    {
      onError: () => errorHandler("An error occurred"),
    }
  );

  useEffect(() => {
    const graphql_exp = {};
    const variables = {};

    if (partnerAccess.isAdmin) {
      graphql_exp.publish = { _eq: hideFilter };
      variables.where = graphql_exp;
      runQuery({
        variables: variables,
      });
    } else {
      runQuery({
        variables: {
          partnerIds: partnerAccess.partnerIds,
        },
      });
    }
  }, [partnerAccess]);

  const catFilterFun = (catId) => {
    setCatFilter(catId);
    const variables = {};

    const graphql_exp = {};

    if (catId > 0) graphql_exp.course_category_id = { _eq: catId };

    graphql_exp.publish = { _eq: hideFilter };

    variables.where = graphql_exp;

    runQuery({
      variables: variables,
    });
  };

  const hideCourseFun = (id, status) => {
    if (!partnerAccess.isAdmin) {
      errorHandler("You are not authorized to do this action");
      return null;
    }

    if (
      partnerAccess.isAdmin &&
      window.confirm(
        `Do you really want to ${status ? "hide" : "show"} this Course`
      )
    ) {
      hideCourse({
        variables: {
          id: id,
          publish: !status,
        },
      });
    }
  };

  // Course Copy
  const copyFun = async (courseData) => {
    if (!partnerAccess.isAdmin) {
      errorHandler("You are not authorized to do this action");
      return null;
    }
    if (
      partnerAccess.isAdmin &&
      window.confirm("Are you really want to copy this Course?")
    ) {
      const course = { ...courseData };

      course["full_name"] = course.full_name + "--copy";

      course["identifier"] = course.full_name
        .toLowerCase()
        .replaceAll(" ", "-");

      course["is_moodle_course"] = false;
      course["moodle_course_id"] = null;
      course["moodle_config_id"] = null;
      course["moodle_course_url"] = null;
      course["publish"] = false;
      course["is_subscription"] = false;

      const insertData = await courseBuilderService.createCourse(course);

      if (insertData) {
        successHandler("Course Copied Proccessing wait...");
      } else {
        errorHandler("Something will b wrong!");
      }

      // Handle certification partners

      (async () => {
        const { created: createdPartners, deleted: deletedPartners } =
          arrayDelta(
            [],
            course.partners.map((po) => po.partner.id)
          );

        await courseSvc.deleteCertificationPartners(
          insertData.id,
          deletedPartners
        );

        await courseSvc.addCertificationPartners(
          insertData.id,
          createdPartners
        );

        //Course Builder copy
        const courseSection = await courseBuilderService.getCourseSection(
          course.id
        );

        courseSection &&
          courseSection.map(async (e) => {
            //Create first copy lesson
            const lesson = await addLesson({
              variables: {
                course_id: insertData.id,
                name: e.name,
              },
            });

            const section_id =
              lesson.data.insert_courses_course_section.returning[0].id;

            //Replace copied section id with new section id

            const output =
              e.coursesec &&
              e.coursesec.map((elem) => {
                return Object.assign({}, elem, {
                  section_id: section_id,
                  course_id: insertData.id,
                });
              });

            output.map(async (topicData) => {
              const insertTopicRow = await lessonTopicCopy({
                variables: topicData,
              });

              const topicId =
                insertTopicRow.data.insert_courses_course_module_mapping
                  .returning[0].id;

              const checkTopicExist =
                await courseBuilderService.checkCourseCompletionTopic(
                  course.id,
                  topicData.id
                );

              if (checkTopicExist) {
                InsertCourseComletionTopics({
                  variables: {
                    course_id: insertData.id,
                    mapping_id: topicId,
                  },
                });
              }

              if (topicData.restriction) {
                await courseBuilderService.copyTopicRestriction(
                  topicId,
                  topicData
                );
              }

              // copy question
              if (topicData.course_question_link) {
                topicData.course_question_link.map((data) => {
                  return link_questions_to_course({
                    variables: {
                      course_id: insertData.id,
                      question_id: data.question_id,
                      module_mapping_id: topicId,
                    },
                  });
                });
              }

              //copy scorm completion criteria
              if (
                topicData.scorm_completion_criteria &&
                topicData.Module.type
              ) {
                await courseBuilderService.InsertScormCompletionCriteria(
                  topicId,
                  topicData.scorm_completion_criteria
                );
              }
            });
          });
      })().catch((err) => {
        console.log(err);
        errorHandler("An error occured while copying course");
      });

      const graphql_exp = {};
      runQuery({
        variables: graphql_exp,
      });
    }
  };

  // Delete Course
  const deleteFun = (rowid) => {
    if (!partnerAccess.isAdmin) {
      errorHandler("You are not authorized to do this action");
      return null;
    }

    if (
      partnerAccess.isAdmin &&
      window.confirm("Do you really want to delete this Course?")
    ) {
      deleteCourse({
        variables: {
          id: rowid,
          course_id: rowid,
        },
      });
    }
  };

  //Course Hide show filter
  const courseHideFilter = (filter) => {
    setHideFilter(filter);
    const variables = {};

    const graphql_exp = {};

    if (catFilter > 0) graphql_exp.course_category_id = { _eq: catFilter };

    graphql_exp.publish = { _eq: filter };

    variables.where = graphql_exp;

    runQuery({
      variables: variables,
    });
  };

  return (
    <Container title={"Manage Courses"}>
      <CourseTable
        partnerAccess={partnerAccess}
        courses={data}
        // totalCourses={totalCourses}
        // partnerTotalCourses={partnerTotalCourses}
        editRoute={getModulePathStringById(ModuleNameIdMap.updateCourse)}
        viewRoute={getModulePathStringById(ModuleNameIdMap.viewCourse)}
        catFilterFun={catFilterFun}
        hideCourseFun={hideCourseFun}
        copyFun={copyFun}
        delete={deleteFun}
        courseHideFilter={courseHideFilter}
        hideFilter={hideFilter}
      />
    </Container>
  );
}

export default ManageCourses;
