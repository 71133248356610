import React from "react";
import DataTable from "react-data-table-component";

function ScholarshipsFilterTableComponent(props) {
  const { scholarshipData } = props;
  console.log("propsprops", props);

  const generateColumns = () => {
    if (!scholarshipData || scholarshipData.length === 0) {
      return [];
    }

    const keys = Object.keys(scholarshipData[0]);

    return keys.map((key) => ({
      name: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " "),
      selector: (row) => `${row[key]}`,
      sortable: true,
    }));
  };

  const columnsScholarShipGrouppedDataa = generateColumns();

  return (
    <div>
      <div className="my-4">
        <DataTable
          columns={columnsScholarShipGrouppedDataa}
          data={scholarshipData}
          pagination
          persistTableHead
        />

        <br />
        <br />
      </div>
    </div>
  );
}

export default ScholarshipsFilterTableComponent;
