import { useQuery, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import { GET_PARTNER_WEBSITE_CONFIGS } from "GraphQl/Queries/Partner";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";
import Container from "components/Container/Container";
import { useState, useEffect } from "react";

import Input from "components/InputGroup/Input";

export function PartnerWebsiteTable() {
  const [search, setSearch] = useState({});

  const [runQuery, { data: partnerWebsiteData, loading: loading }] =
    useLazyQuery(GET_PARTNER_WEBSITE_CONFIGS, { fetchPolicy: "network-only" });

  useEffect(() => {
    SearchPartnerConfig();
  }, []);

  const SearchPartnerConfig = (e) => {
    let variables = { where: {} };
    if (search.name)
      variables.where.partner = {
        name: {
          _ilike: `%${search.name}%`,
        },
      };
    console.log(variables);
    runQuery({ variables: variables });
  };

  // const { data: partnerWebsiteData, loading } = useQuery(
  //   GET_PARTNER_WEBSITE_CONFIGS
  // );
  const history = useHistory();

  const redirectToPartnerConfigUpdate = (partnerId) => {
    history.push(
      getModulePathStringById(ModuleNameIdMap.partnerWebsiteConfiguration) +
        `?partnerId=${partnerId}`
    );
  };

  const columns = [
    {
      name: `ID`,
      selector: (row) => row.id,
      sortable: true,
      maxWidth: "40px",
    },
    {
      name: `Partner ID`,
      selector: (row) => row.partner_id,
      sortable: true,
      maxWidth: "40px",
    },
    {
      name: `Partner Name`,
      selector: (row) => row.partner.name,
      sortable: true,
    },
    {
      name: `Website URL`,
      selector: (row) => <a href={row.website_url}>{row.website_url}</a>,
      sortable: true,
    },
    {
      name: `Status`,
      selector: (row) => row.status,
      sortable: true,
      maxWidth: "115px",
      conditionalCellStyles: [
        {
          when: (row) => row.status === "unpublished",
          style: {
            backgroundColor: "rgba(245, 39, 39, 0.35)",
            borderRadius: "4px",
            justifyContent: "center",
            margin: "2px 2px 2px 2px",
          },
        },
        {
          when: (row) => row.status === "published",
          style: {
            backgroundColor: "rgba(39, 245, 74, 0.35)",
            borderRadius: "4px",
            justifyContent: "center",
            margin: "2px 2px 2px 2px",
          },
        },
      ],
    },
    {
      name: `Last Publish`,
      selector: (row) => row.last_published_at,
      sortable: true,
    },
    {
      name: `Created At`,
      selector: (row) => row.created_at,
      sortable: true,
    },
    {
      cell: (row) => (
        <button
          className="bg-gray-700 p-2 text-base text-white"
          onClick={() => redirectToPartnerConfigUpdate(row.partner_id)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <Container title={"Partner Website Table"} className="mx-8 my-8">
      <div className="d-flex mt-5 pt-3">
        <Input
          label={"Search by Partner's Name"}
          value={""}
          onChange={(e) => {
            console.log(e.target.value);
            setSearch({ ...search, name: e.target.value });
          }}
          type={"text"}
          placeholder={"Enter Partner Name"}
        />
        <button className="btn-primary my-4 ml-5" onClick={SearchPartnerConfig}>
          Search
        </button>
      </div>
      {!loading && (
        <DataTable
          columns={columns}
          className=""
          data={partnerWebsiteData?.courses_partner_website_configuration || []}
          pagination
          progressPending={loading}
        />
      )}
    </Container>
  );
}
