import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import Container from "components/Container/Container";
import { useLocation } from "react-router";
import queryString from "query-string";
import axios from "axios";
import { useHistory } from "react-router-dom";
import courseBuilderService from "services/coursebuild/course";
import { toast } from "react-toastify";
import {
  GET_MODULE_DETAILS_BY_ID,
  GET_MODULE_RESTRICTION,
} from "GraphQl/Queries/CourseBuilder/section";

import CourseActivityForm from "./CourseActivityForm";
import api from "api/Api";
import { error } from "utils/error";
import { getModulePathStringById } from "Contexts/NavigationContext";
import { ModuleNameIdMap } from "Contexts/Navigation/ModuleNameIdMap";

export default function ScormEdit() {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const history = useHistory();

  const { data: ScormFormData } = useQuery(GET_MODULE_DETAILS_BY_ID, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  });

  const { data: ModuleRestriction } = useQuery(GET_MODULE_RESTRICTION, {
    variables: { mapping_id: params.id },
    fetchPolicy: "network-only",
  });

  const [criteria, setCriteria] = useState([]);

  const [message, setMessage] = useState();

  const [topic, setTopics] = useState([]);

  const [restriction, setRestriction] = useState(false);

  const [selectedValue, setSelectedValue] = useState([]);

  const [CompleteStatus, setCompleteStatus] = useState(false);
  const [fileName, SetFileName] = useState({
    file_name: null,
    upload_url: null,
    system_filename: null,
  });

  const [defaultRestriction, setDefaultRestriction] = useState("");
  const [minScore, setMinScore] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  const [lessonName, setLessonName] = useState(null);

  useEffect(() => {
    const getScormData = async () => {
      const criteria = {
        ...(ScormFormData && ScormFormData.courses_course_module_mapping[0]),
      };
      const { scorm_completion_criteria } = criteria;

      SetFileName({
        file_name: criteria.file_name,
        upload_url: criteria.upload_url,
        system_filename: criteria.system_filename,
      });

      setLessonName(criteria.section?.name);

      // completion_criteria_previous_set
      criteria.completion_criteria_previous_set = scorm_completion_criteria
        ? true
        : false;

      if (scorm_completion_criteria) {
        criteria.enable_min_score = scorm_completion_criteria.enable_min_score;
        criteria.min_score = scorm_completion_criteria.min_score;
        criteria.passed = scorm_completion_criteria.passed ? true : false;
        criteria.completed = scorm_completion_criteria.completed ? true : false;
        criteria.scorm_version = scorm_completion_criteria.scorm_version;
        setMinScore(scorm_completion_criteria.enable_min_score);
      }

      if (parseInt(criteria.completion_criteria) === 1) {
        setCompleteStatus(true);
      }

      if (criteria.course_id) {
        const res = await courseBuilderService.GetCourseTopics(
          criteria.course_id
        );

        const Options =
          res.length > 0 &&
          res.map((topic, i) => {
            return { value: topic.id, label: topic.name };
          });

        setTopics(Options);

        const restriction_ids =
          ModuleRestriction &&
          ModuleRestriction.courses_module_completion_resrtriction[0];

        if (restriction_ids) {
          const split_string =
            restriction_ids.restriction_ids &&
            restriction_ids.restriction_ids.split(",");

          if (split_string) {
            const result =
              Options &&
              Options.filter((o1) => split_string.some((o2) => o1.value == o2));

            if (result) {
              setDefaultRestriction(result);
              setSelectedValue(
                Array.isArray(result) ? result.map((x) => x.value) : []
              );
            }
          }

          criteria.enable_restriction = true;
          criteria.restriction_previous_set = true;
          criteria.batch_id = restriction_ids.batch_id;

          setRestriction(true);
        } else {
          criteria.restriction_previous_set = false;
        }
      }

      setCriteria(criteria);
    };

    getScormData();
  }, [ScormFormData, ModuleRestriction]);

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const updateValue = (e) => {
    const key = e.target.getAttribute("data-key");
    const value = e.target.value;

    if (key == "completion_criteria" && value == 1) {
      setCompleteStatus(true);
    }
    if (key == "completion_criteria" && value != 1) {
      setCompleteStatus(false);
    }

    setCriteria({ ...criteria, [key]: value });
  };

  console.log("criteria", criteria["section_id"]);

  const updateCheckbox = (e) => {
    const key = e.target.getAttribute("data-key");

    const value = e.target.checked;

    if (key === "enable_restriction") {
      setRestriction(value);
    }
    if (key === "enable_min_score") {
      setMinScore(value);
    }

    setCriteria({ ...criteria, [key]: value });
  };

  const submitSection = async () => {
    const formData = { ...criteria };

    console.log("formData", formData);
    if (
      formData.name === "" ||
      formData.name === null ||
      formData.name === undefined
    ) {
      setMessage("Section name is required");
      return;
    }

    formData["enable_restriction"] = !formData.enable_restriction
      ? false
      : formData.enable_restriction;
    formData["restriction_ids"] = selectedValue.toString();
    formData["id"] = params.id;
    formData["file_name"] = fileName.file_name;
    formData["upload_url"] = fileName.upload_url;
    formData["attemtsgrade"] = parseInt(formData.attemtsgrade);
    formData["maxattempt"] = parseInt(formData.maxattempt);
    formData["completion_criteria"] = parseInt(formData.completion_criteria);
    formData["moodle_published"] = false;
    formData["system_filename"] = fileName.system_filename;

    const update = await courseBuilderService.UpdateScormForm(formData);

    if (update) {
      let path =
        getModulePathStringById(ModuleNameIdMap.viewCourse) +
        `?id=${formData.course_id}`;
      history.push(path, { upadtedModuleId: formData["section_id"] });

      toast.success("Scorm successfully updated!");
    } else {
      toast.error("Something will be wrong!");
    }
  };

  /**** Scorm Uplaoder function */
  const handleFileReader = async (event) => {
    setFileLoading(true);
    const file = event;

    const isPublic = false;

    const rep = async () => {
      const data = {
        file_name: event.name,
        is_public: isPublic,
        content_type: file.type,
      };

      try {
        const result = await api()
          .post("/get_file_post_url", data)
          .then(({ data }) => data);

        const s3UploadUrl = result.image_post_url;

        const axiosResponse = await axios
          .put(s3UploadUrl, file, {
            headers: {
              "content-type": file.type,
            },
          })
          .then((data) => data);

        SetFileName({
          file_name: result.file_name,
          upload_url: axiosResponse.config.url,
          system_filename: event.name,
        });

        return result.file_name;
      } catch (err) {
        throw error("Couldn't upload file", err);
      }
    };

    await rep();

    /*
     Please store this file name in the backend with activity
    */
  };

  const criteriaOption = [
    {
      value: 0,
      label: "Students can manually mark the activity as completed",
    },
    { value: 1, label: "Show activity as complete when condition are meet " },
    { value: 2, label: "Mark activity complete on view" },
  ];

  const ScormVerison = [
    { value: "1.2", label: "1.2" },
    { value: "2004", label: "2004" },
  ];

  const fileTypes = ["ZIP"];

  useEffect(() => {
    if (fileName?.file_name) {
      setFileLoading(false);
    }
  }, [fileName]);

  const removeAttachedFile = () => {
    SetFileName({
      file_name: null,
      upload_url: null,
      system_filename: null,
    });
  };

  return (
    <Container>
      <CourseActivityForm
        CompleteStatus={CompleteStatus}
        updateCheckbox={updateCheckbox}
        updateValue={updateValue}
        submitSection={submitSection}
        section={criteria}
        message={message}
        minScore={minScore}
        handleFileReader={handleFileReader}
        criteriaOption={criteriaOption}
        ScormVerison={ScormVerison}
        handleChange={handleChange}
        topic={topic}
        restriction={restriction}
        fileTypes={fileTypes}
        action="update"
        defaultRestriction={defaultRestriction}
        fileName={fileName}
        selectedValue={selectedValue}
        fileLoading={fileLoading}
        removeAttachedFile={removeAttachedFile}
        lessonName={lessonName}
      />
    </Container>
  );
}
