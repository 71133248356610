import { useQuery } from "@apollo/client";
import {
  GET_FILTER_DATA,
  GET_PARTNER_FILTER_DATA,
} from "GraphQl/Queries/CertificateTemplateMaker";
import { GET_PARTNERS } from "GraphQl/Queries/Partner";
import { useState, useEffect } from "react";
import * as yup from "yup";
import { useUserPartnerAccessDetails } from "Hooks/UserAcccess";

function InvoiceFilterFormBuilder(values) {
  const [schema, setSchema] = useState();
  const { data } = useQuery(GET_PARTNERS);
  const partnerAccess = useUserPartnerAccessDetails();
  const [selectedPartner, setSelectedPartner] = useState();
  const { data: filterData } = useQuery(
    partnerAccess.isAdmin ? GET_FILTER_DATA : GET_PARTNER_FILTER_DATA,
    {
      variables: partnerAccess.isAdmin
        ? {}
        : { partnerIds: partnerAccess.partnerIds },
    }
  );

  const filterPartner = (partners) => {
    if (partners) {
      if (partnerAccess && partnerAccess.allPartnerAccess) {
        return partners;
      } else if (partnerAccess.partnerIds) {
        const filteredPartners = partners.filter(
          ({ id }) => partnerAccess.partnerIds.indexOf(id) > -1
        );
        return filteredPartners;
      }
    }
    return [];
  };

  useEffect(() => {
    const partnerSelect = (data?.courses_partner || []).find((partner) => {
      if (partner.id == values.partner_id) return partner;
    });
    setSelectedPartner(partnerSelect);
  }, [values.partner_id]);

  useEffect(() => {
    const schema = {
      course: {
        label: "Course",
        type: "select",
        options: filterData
          ? filterData.courses_course.map((course) => {
              return {
                label: course.full_name,
                value: course.id,
              };
            })
          : [],
      },
      partner_id: {
        label: "Partner Name",
        type: "select",
        options: filterPartner(data?.courses_partner).map((partner) => ({
          label: partner.name,
          value: partner.id,
        })),
        schema: yup.string(),
      },
      // project_id: {
      //   label: "Project Name",
      //   type: "select",
      //   options: ((selectedPartner && selectedPartner.projects) || []).map(
      //     (project) => ({
      //       label: project.name,
      //       value: project.id,
      //     })
      //   ),
      //   schema: yup.string(),
      // },

      from_date: {
        label: "From Date",
        type: "date",
        schema: yup.string(),
      },
      to_date: {
        label: "To Date",
        type: "date",
        schema: yup.string(),
      },
    };
    setSchema(schema);
  }, [values, filterData, data]);

  return schema;
}

export default InvoiceFilterFormBuilder;
