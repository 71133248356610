import React, { useCallback, useEffect, useState } from "react";
import Container from "components/Container/Container";
import JobsTable from "./JobsTable";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_JOBS,
  GET_SECTOR,
  GET_JOB_ROLE,
  GET_OTHER_PARTNER,
} from "GraphQl/Queries/Jobs";
import Input from "components/InputGroup/Input";
import { useHistory } from "react-router-dom";
import Select from "components/InputGroup/Select";
import MultiSelectDropdown from "components/InputGroup/MultiselectDropdown";

const defaultSearch = {
  title: "",
  sector_id: "",
  associated_job_role_id: "",
  otherpartner_id: "",
};

function ManageJobs() {
  const navigate = useHistory();

  const { data: sectorData } = useQuery(GET_SECTOR);
  const { data: jobRoleData } = useQuery(GET_JOB_ROLE);
  const { data: partnerData } = useQuery(GET_OTHER_PARTNER);

  const otherpartnerOptions = partnerData
    ? partnerData.courses_partner.map((partner) => ({
        value: partner.id,
        label: partner.name,
      }))
    : [];

  const sectorOptions = sectorData ? sectorData.courses_course_categories : [];
  const jobRoleOptions = jobRoleData
    ? jobRoleData.courses_qualifications_lookup
    : [];

  /*

  Search functionality

  */

  const [search, setSearch] = useState(defaultSearch);
  const setSearchParameter = useCallback(
    (param, value) => {
      search[param] = value;
      setSearch({ ...search });
    },
    [search]
  );

  const [runQuery, { loading: jobLoading, data: jobData }] = useLazyQuery(
    GET_JOBS,
    { fetchPolicy: "network-only" }
  );

  const searchUsers = useCallback(() => {
    let variables = { where: {} };

    if (search.title) variables.where.title = { _ilike: `%${search.title}%` };
    if (search.sector_id && search.sector_id !== "-1")
      variables.where.sector_id = { _eq: search.sector_id };
    if (search.associated_job_role_id && search.associated_job_role_id !== "-1")
      variables.where.associated_job_role_id = {
        _eq: search.associated_job_role_id,
      };
    if (search.otherpartner_id && search.otherpartner_id.length > 0)
      variables.where.otherpartner_id = { _contains: search.otherpartner_id };

    runQuery({ variables: variables });
  }, [search, runQuery]);

  const handleResetFilters = () => {
    search.title = "";
    search.sector_id = "";
    search.associated_job_role_id = "";
    search.otherpartner_id = "";

    setSearch({ ...search });
    searchUsers();
  };

  /*


  Job record actions


  */

  const handleEdit = (e, row) => {
    e.preventDefault();
    navigate.push("update-job/?id=" + row.id);
  };

  const handleViewApplicants = (e, row) => {
    e.preventDefault();
    navigate.push("view-job-applicants/?id=" + row.id);
  };

  useEffect(() => {
    searchUsers();
  }, []);

  return (
    <Container title={"Manage Jobs"}>
      <div className="grid grid-cols-2 gap-3">
        <Input
          label={"Search By Title"}
          value={search.title}
          onChange={(e) => setSearchParameter("title", e.target.value)}
          type={"text"}
          placeholder={"Enter Job Title"}
        />

        <MultiSelectDropdown
          label={"Filter by Other Partner"}
          options={otherpartnerOptions}
          valueField={"value"}
          displayField={"label"}
          selectedValue={search.otherpartner_id}
          setSelectedValue={(value) =>
            setSearchParameter("otherpartner_id", value)
          }
        />

        <Select
          label={"Filter by Sector"}
          options={
            sectorOptions.map((sector) => ({
              value: sector.id,
              label: sector.name,
            })) || []
          }
          valueField={"value"}
          displayField={"label"}
          value={search.sector_id}
          onChange={(e) => setSearchParameter("sector_id", e.target.value)}
        />

        <Select
          label={"Filter by Job Role"}
          options={
            jobRoleOptions
              .filter((role) => !role.name.includes("Std"))
              .map((role) => ({
                value: role.id,
                label: role.name + "(" + role.qp_code + ")",
              })) || []
          }
          valueField={"value"}
          displayField={"label"}
          value={search.associated_job_role_id}
          onChange={(e) =>
            setSearchParameter("associated_job_role_id", e.target.value)
          }
        />
      </div>
      <div className="flex gap-x-4">
        <button
          className="btn-primary my-4"
          onClick={() => console.log(search) || searchUsers()}
        >
          Filter
        </button>

        <button className="btn-secondary my-4" onClick={handleResetFilters}>
          Reset Filters
        </button>
      </div>

      {!jobLoading && jobData && (
        <JobsTable
          data={jobData.courses_skillstrainer_jobs}
          handleEdit={handleEdit}
          handleViewApplicants={handleViewApplicants}
        />
      )}
    </Container>
  );
}

export default ManageJobs;
