const { errorHandler } = require("./toast");

const error = (message = "An error occurred", data = {}, status = 500) => {
  const errorObj = new Error(message);
  errorObj.status = status;
  errorObj.data = data;
  throw errorObj;
};

const translateApiError = (err) => err.response.data;

const toastError = (err) => errorHandler(err.message);
const toastApiError = (err) => toastError(translateApiError(err));

module.exports = { error, translateApiError, toastError, toastApiError };
