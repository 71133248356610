import {SET_SECTION} from 'redux/action/CourseBuilder'

const initialState={
    section:[]

}

export const coursebuilder = (state=initialState,action) => {
    switch(action.type){
     case SET_SECTION:
      return { ...state, section: action.payload }
      default:
        return state;
    }
}