import { useLazyQuery, useMutation } from "@apollo/client";
import {
  DELETE_COURSE_CRITERIA_COURSES,
  INSERT_COURSE_CRITERIA,
  INSERT_COURSE_CRITERIA_COURSES,
  UPDATE_COURSE_CRITIERA,
} from "GraphQl/Mutations/CourseCriteria";
import {
  CREATE_NEW_COUPON_CODES,
  GET_ALL_COUPONS,
  GET_BATCHES_WITH_FILTER,
  GET_COUPON_CODE_BY_ID,
  GET_TOTAL_COUPON_CODES,
  UPDATE_NEW_COUPON_COES,
} from "GraphQl/Queries/CourseCoupons";
import React, { useCallback, useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router";
import { errorHandler, successHandler } from "utils/toast";
import { Form } from "skillstrainer-resource-library";
import CouponCodeFormBuilder from "formBuilders/CouponCodeFormBuilder";
import {
  DELETE_COUPON_BATCH_RELATIONS,
  UPSERT_COUPON_BATCH_RELATIONS,
} from "GraphQl/Mutations/CouponBatch";
import { arrayDelta } from "utils/func";
import usePromise from "utils/hooks/usePromise";
export default function CreateUpdateCouponCodePage(props) {
  const [couponcode, setCouponCode] = useState({
    active: false,
    is_discount: false,
  });

  /*
   *
   *
   * Fetch coupon code
   */
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [getCouponCodeById] = useLazyQuery(GET_COUPON_CODE_BY_ID);
  const [isUpdate, setIsUpdate] = useState(false);

  const getCoupon = () => {
    getCouponCodeById({ variables: { id: params.id } }).then(
      ({ data: { courses_st_coupons_configuration_by_pk: res } }) => {
        const coursesIdArray = res.criteria.courses.map((course) => {
          return course.course_id;
        });

        const couponWithCourses = {
          ...res,
          courses: coursesIdArray,
          batches: res.coupon_batch_relations.map((cbr) => cbr.batch_id),
          is_discount: res.type === "discount" ? true : false,
          type: undefined,
        };

        setIsUpdate(true);
        setCouponCode(couponWithCourses);
      }
    );
  };

  useEffect(() => {
    if (params.id) {
      getCoupon();
    }
  }, [params.id]);

  /*
   *
   *
   * Batch services
   *
   *
   */
  const [getBatches] = useLazyQuery(GET_BATCHES_WITH_FILTER);

  /*
   *
   *
   * Coupon services
   */

  // Insert coupon
  const [insertCoupon] = useMutation(CREATE_NEW_COUPON_CODES, {
    refetchQueries: [GET_ALL_COUPONS],
  });
  // Update coupon
  const [updateCoupon] = useMutation(UPDATE_NEW_COUPON_COES, {
    refetchQueries: [GET_ALL_COUPONS, GET_TOTAL_COUPON_CODES],
  });

  /*
   *
   *
   * Coupon batch services
   */

  const [upsertCouponBatchRelations] = useMutation(
    UPSERT_COUPON_BATCH_RELATIONS
  );
  const [deleteCouponBatchRelations] = useMutation(
    DELETE_COUPON_BATCH_RELATIONS
  );

  /*
   *
   *
   * Course criteria services
   */

  // Insert course criteria of coupon
  const [insert_course_criteria] = useMutation(INSERT_COURSE_CRITERIA, {
    refetchQueries: [GET_ALL_COUPONS],
  });
  // Update course criteria of coupon
  const [update_course_criteria] = useMutation(UPDATE_COURSE_CRITIERA);

  // Delete course - course criteria relation
  const [delete_course_criteria_courses] = useMutation(
    DELETE_COURSE_CRITERIA_COURSES
  );
  // Insert course - course criteria relation
  const [insert_course_criteria_courses] = useMutation(
    INSERT_COURSE_CRITERIA_COURSES,
    {
      refetchQueries: [GET_ALL_COUPONS],
    }
  );

  /*
   *
   *
   * Coupon creation / updation logic
   */

  // Checking if coupon batches' courses are selected
  const getAreSelectedBatchesCoursesAreSelected = useCallback(
    async (selectedCourseIds, selectedBatchIds = []) => {
      const batches = await getBatches({
        variables: {
          courseBatchesCriteria: { id: { _in: selectedBatchIds || [] } },
        },
      }).then(({ data }) => {
        return data.courses_course_batches;
      });

      const requiredCourseIds = (selectedBatchIds || []).map(
        (bId) => batches.find((b) => b.id === bId)?.course_id
      );

      for (const requiredCourseId of requiredCourseIds) {
        if (selectedCourseIds.indexOf(requiredCourseId) === -1) return false;
      }

      return true;
    },
    [getBatches]
  );

  // Creating a coupon
  const { run: handleSubmit, loading: submittingForm } = usePromise(
    async (couponCode, e) => {
      try {
        couponCode.code = couponCode.code.toUpperCase();
        const { courses, batches = [] } = couponCode;

        const areSelectedBatchesCoursesAreSelected =
          await getAreSelectedBatchesCoursesAreSelected(courses, batches);
        if (!areSelectedBatchesCoursesAreSelected) {
          throw new Error(
            "Associated courses of some selected batches are not selected for coupon"
          );
        }

        const insertCouponObject = {
          ...couponCode,
          type: couponCode.is_discount ? "discount" : "enrollment",
          is_discount: undefined,
          courses: undefined,
          batches: undefined,
        };

        const insertCouponRes = await insertCoupon({
          variables: {
            object: insertCouponObject,
          },
        })
          .then((res) => {
            successHandler("New Coupon Created");
            return res.data.insert_courses_st_coupons_configuration_one;
          })
          .catch((err) => {
            console.log(err);
            errorHandler("There was an error while creating coupon");
          });

        // Creating course criteria for this coupon
        const insertCourseCriteriaPayload = {
          courses: couponCode.courses.map((course_id) => ({ course_id })),
          users: [],
          partner_id: null,
          instructor_id: null,
          coupon_id: insertCouponRes.id,
          apply_to_all_courses: false,
          apply_to_all_users: false,
          apply_to_skillstrainer: true,
          name: insertCouponRes.code,
        };

        const insertCourseCriteriaResponse = await insert_course_criteria({
          variables: insertCourseCriteriaPayload,
        }).then(({ data }) => {});

        // Creating batch relations for this coupon
        const insertBatchesPayload = batches.map((batch_id) => ({
          batch_id,
          coupon_id: insertCouponRes.id,
        }));

        const insertBatchesRes = await upsertCouponBatchRelations({
          variables: { couponBatchRelations: insertBatchesPayload },
        }).then(
          ({ data }) => data.insert_courses_coupon_batch_relations.returning
        );

        console.log({ insertCourseCriteriaResponse, insertBatchesRes });

        setCouponCode({});
      } catch (err) {
        console.error(err);
        errorHandler(err.message || "An error occurred");
      }
    }
  );
  // const insertCouponCode = async (couponCode) => {};

  // Updating a coupon
  const { run: updateCouponCode, loading: submittingFormUpdate } = usePromise(
    async (updatableCouponCode) => {
      try {
        const areSelectedBatchesCoursesAreSelected =
          await getAreSelectedBatchesCoursesAreSelected(
            updatableCouponCode.courses,
            updatableCouponCode.batches
          );

        if (!areSelectedBatchesCoursesAreSelected) {
          throw new Error(
            "Associated courses of some selected batches are not selected for coupon"
          );
        }

        const updateCouponObject = {
          id: couponcode.id,
          ...updatableCouponCode,
          type: updatableCouponCode.is_discount ? "discount" : "enrollment",
          is_discount: undefined,
          courses: undefined,
          batches: undefined,
        };

        const updateCouponRes = await updateCoupon({
          variables: {
            id: { _eq: updateCouponObject.id },
            _set: updateCouponObject,
          },
        });

        /*
         *
         *
         * Updating course criteria relations
         *
         *
         */
        const payload = {
          partner_id: null,
          instructor_id: null,
          coupon_id: updateCouponObject.id,
          apply_to_all_courses: false,
          apply_to_all_users: false,
          apply_to_skillstrainer: true,
          name: updateCouponObject.code,
        };
        payload.id = couponcode.criteria.id;
        await update_course_criteria({
          variables: payload,
        });

        /*
         *
         *
         * Updating course - course criteria relations
         *
         *
         */
        // - Delete course - course criteria relations
        await delete_course_criteria_courses({
          variables: {
            id: payload.id,
          },
        });

        // - Create new course - course criteria relations
        await Promise.all(
          updatableCouponCode.courses.map((course_id) => {
            return insert_course_criteria_courses({
              variables: {
                criteria_id: payload.id,
                course_id,
              },
            });
          })
        );

        /*
         *
         *
         * Updating coupon batch relations
         *
         *
         */
        const {
          created: createdCouponBatchRelations,
          persistant: persistantCouponBatchRelations,
          deleted: deletedCouponBatchRelations,
        } = arrayDelta(
          couponcode.coupon_batch_relations,
          updatableCouponCode.batches?.map((batch_id) => ({ batch_id })) || [],
          ["batch_id"]
        );

        console.log({
          createdCouponBatchRelations,
          persistantCouponBatchRelations,
          deletedCouponBatchRelations,
        });

        const upsertableCBRs = createdCouponBatchRelations
          .concat(persistantCouponBatchRelations)
          .map((cbr) => ({ ...cbr, coupon_id: couponcode.id }));

        // Checking if upsertable coupon batch relations' corresponding course ids are selected in coupon

        const upsertCBRRes = await upsertCouponBatchRelations({
          variables: { couponBatchRelations: upsertableCBRs },
        }).then(({ data }) => data.insert_courses_coupon_batch_relations);

        const deleteCBRRes = await deleteCouponBatchRelations({
          variables: {
            couponBatchRelationIds: deletedCouponBatchRelations.map(
              (e) => e.id
            ),
          },
        }).then(({ data }) => data.delete_courses_coupon_batch_relations);

        console.log({ upsertCBRRes, deleteCBRRes });

        successHandler("Updated successfully!");
      } catch (err) {
        console.error(err);
        errorHandler(err.message || "An error occurred");
      }
    }
  );
  // const updateCouponCode = async (updatableCouponCode) => {

  // };

  return (
    <div className="my-4 ml-1 mr-2">
      <h2 className="text-3xl font-bold px-4 ml-10">
        Create / Update Coupon Code
      </h2>
      <div className="px-4 m-3 ml-6 w-full">
        <Form
          key={couponcode?.id}
          formBuilder={CouponCodeFormBuilder}
          className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: isUpdate
              ? submittingFormUpdate
                ? "submitting..."
                : "Save"
              : submittingForm
              ? "submitting..."
              : "Save",
            className:
              "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
            disabled: submittingForm,
          }}
          onSubmit={
            isUpdate
              ? (couponCode) => updateCouponCode(couponCode)
              : (couponCode) => handleSubmit(couponCode)
          }
          initValues={couponcode}
        />
      </div>
    </div>
  );
}
