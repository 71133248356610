import { client } from "App";
import { GET_SCORM_QUIZ_ATTEMPT_WITH_FILTER } from "GraphQl/Queries/Courses";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import PieCharts from "../PieChart";

export const QUIZ_TYPES = {
  QUIZ_BUILDER: "quiz-builder",
  SCORM_QUIZ: "scorm-quiz",
};

export default function QuizReport(props) {
  const { type, activityIds, userIds, transformResponses } = props;

  const [responseList, setResponseList] = useState();

  const transformedResponseList = useMemo(
    () =>
      responseList && typeof transformResponses === "function"
        ? transformResponses(responseList)
        : responseList,
    [responseList, transformResponses]
  );

  const { questions, responseListByQuestions } = useMemo(() => {
    const quizData = { questions: [], responseListByQuestions: {} };

    if (transformedResponseList)
      transformedResponseList.forEach((response) => {
        if (!quizData.questions.find((q) => q.id === response.question_id)) {
          quizData.questions.push({
            id: response.question_id,
            text: response.question_text.split("slide: ")[1],
          });
          quizData.responseListByQuestions[response.question_id] = [];
        }

        quizData.responseListByQuestions[response.question_id].push({
          user_id: response.user_id,
          response: response.user_response,
        });
      });

    return quizData;
  }, [transformedResponseList]);

  useEffect(() => {
    getQuizAttemptRecords(type, activityIds, userIds).then(setResponseList);
  }, [type, activityIds, userIds]);

  return (
    <div className=" mx-4 ">
      <h3 className="text-xl font-semibold ">Question Wise Response Reports</h3>
      {questions.length === 0 ? (
        <span className="text-2xl font-semibold text-orange ">
          No Question Data For These Filters
        </span>
      ) : (
        questions.map((q, index) => {
          const responses = responseListByQuestions[q.id];

          return (
            <div key={q.id} className="mb-14">
              <div className="text-md font-semibold">
                Q{index + 1}. {q.text}
              </div>
              <div>
                <span className="semibold">Responses: </span>
                <PieCharts data={responses} cumulationKey={"response"} />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export const getQuizAttemptRecords = async (type, activityIds, userIds) => {
  if (type === QUIZ_TYPES.SCORM_QUIZ) {
    const scormQuizAttemptSearchExp = {
      mapping_id: { _in: activityIds },
      user_id: { _in: userIds },
    };
    const scormQuizAttemptsList = await client
      .query({
        query: GET_SCORM_QUIZ_ATTEMPT_WITH_FILTER,
        variables: {
          scormQuizAttemptSearchExp,
        },
      })
      .then(({ data }) => data.courses_course_scorm_quiz_attempt_data);

    const scormQuizLatestAttemptsList = getScormLatestAttempts(
      scormQuizAttemptsList
    );

    return scormQuizLatestAttemptsList;
  }
};

const getScormLatestAttempts = (attempts) =>
  attempts.reduce((latestAttempts, attempt) => {
    let existingUserQuestionAttemptIndex = -1;
    const existingUserQuestionAttempt = latestAttempts.find((la, index) => {
      const shouldSelect =
        la.user_id === attempt.user_id &&
        la.question_id === attempt.question_id;
      if (shouldSelect) existingUserQuestionAttemptIndex = index;
      return shouldSelect;
    });

    if (!existingUserQuestionAttempt) latestAttempts.push(attempt);
    else if (
      new Date(existingUserQuestionAttempt.created_at).getTime() <
      new Date(attempt.created_at).getTime()
    ) {
      latestAttempts.splice(existingUserQuestionAttemptIndex, 1);
      latestAttempts.push(attempt);
    }

    return latestAttempts;
  }, []);

const getResponseDistribution = (responses) => {
  const dist = {};

  responses.forEach((r) => {
    if (!dist[r.response]) dist[r.response] = 0;
    dist[r.response]++;
  });

  return dist;
};
