const { graphqlWsUri } = require("api/Consts");
const { getJwtToken } = require("./Auth");
const _ = require("lodash");
const { error } = require("./error");

const status = {
  OPENING: "opening",
  OPEN: "open",
  CLOSED: "closed",
};

class GraphQLWebSocket {
  socket;
  status = status.CLOSED;

  subscribers = {};
  requestsBuffer = [];

  // Events

  create() {
    return new Promise((res) => {
      if (this.status !== status.CLOSED)
        throw error("A socket is already running");
      this.status = status.OPENING;

      this.socket = new WebSocket(graphqlWsUri, "graphql-ws");

      this.socket.onopen = () => {
        typeof this.onopen === "function" && this.onopen();

        this.socket.send(
          JSON.stringify({
            type: "connection_init",
            payload: {
              headers: {
                Authorization: `Bearer ${getJwtToken()}`,
              },
            },
          })
        );
      };

      this.socket.onerror = (error) => {
        this.status = status.CLOSED;
        for (const sub of this.subscribers) sub.onError && sub.onError(error);

        // Reject promise
        throw error("An error occured while establishing connection");
      };

      this.socket.onmessage = (message) => {
        message = JSON.parse(message.data);
        if (message.type === "connection_ack") {
          // Connection authenticated
          this.status = status.OPEN;
          this.executeBufferRequests();

          // Resolve promise
          res(true);
        } else if (message.id) {
          // Regular communication
          const sub = this.subscribers[message.id];
          if (sub) sub.onMessage && sub.onMessage(message.payload);
        }
      };
    });
  }

  destroy() {
    return new Promise((res) => {
      if (this.status !== status.CLOSED) {
        this.sendMessage({
          type: "connection_terminate",
        });
        this.socket.onclose = () => res(true);
        this.socket.close();
        this.status = status.CLOSED;
      } else throw error("There is no socket active");
    });
  }

  subscribe(subscriber) {
    this.subscribers[subscriber.id] = _.pick(subscriber, [
      "id",
      "onMessage",
      "onError",
    ]);

    // Initial message
    this.sendMessage({
      id: subscriber.id,
      payload: {
        query: subscriber.query,
        variables: subscriber.variables,
      },
      type: "start",
    });
  }

  sendMessage(message) {
    if (this.status !== status.OPEN) {
      this.requestsBuffer.push(message);
    } else {
      this.socket.send(JSON.stringify(message));
    }
  }

  unsubscribe(subscriberId) {
    this.sendMessage({
      id: subscriberId,
      type: "stop",
    });
    delete this.subscribers[subscriberId];
  }

  executeBufferRequests() {
    for (const request of this.requestsBuffer) this.sendMessage(request);
  }
}

export const socket = new GraphQLWebSocket();
export const SocketStatus = status;

export const refreshSocket = () => {
  if (socket.status === SocketStatus.OPEN) socket.destroy();
  if (getJwtToken()) socket.create();
};
