import DataTable from "react-data-table-component";

export default function QualificationsTable(props) {
  console.log(props);
  const url = new URLSearchParams(window.location.search);
  const handleButtonClick = (e, row) => {
    url.set("id", row.id);
    window.history.pushState({}, "", `${window.location.pathname}?${url}`);
    props.updateSelectedSkills(row);
  };

  const columns = [
    {
      name: `Id`,
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: `Name`,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: `Type`,
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: `NSQF Level`,
      selector: (row) => row.nsqf_level,
      sortable: true,
    },
    {
      name: `QP Code`,
      selector: (row) => row.qp_code,
      sortable: true,
    },
    {
      name: `Sector`,
      selector: (row) => row.sector_id,
      sortable: true,
    },
    {
      cell: (row) => (
        <button
          className="bg-gray-700 p-2 text-base text-white"
          onClick={(e) => handleButtonClick(e, row)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div>
      <DataTable columns={columns} data={props.qualificationData} pagination />
    </div>
  );
}
