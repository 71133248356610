import { client } from "App";
import { GET_USERS_WITH_FILTER } from "GraphQl/Queries/User";

class UserService {
  getUsersWithFilter(filterClause) {
    console.log("Getting users");
    return client
      .query({
        query: GET_USERS_WITH_FILTER,
        variables: { filterClause },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => data.courses_users);
  }
}

const userSvc = new UserService();

export default userSvc;
