import { useEffect, useRef, useState } from "react";
import { socket } from "./websocket";

let _id = 1;

export const useSubscription = (query, opts) => {
  const { variables } = opts;

  const idRef = useRef(_id++ + "");
  const id = idRef.current;
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const onMessage = (message) => {
    if (message?.data) {
      if (typeof opts.onCompleted === "function")
        opts.onCompleted(message.data);
      setData(message.data);
      setError();
    } else if (message?.errors) {
      if (typeof opts.onError === "function") opts.onError(message.errors);
      setData();
      setError(message.errors);
    }

    if (loading) setLoading(false);
  };

  const onError = (err) =>
    setError({ message: "Trouble establishing websocket connection" });

  useEffect(() => {
    socket.subscribe({
      id,
      query,
      variables,
      onMessage,
      onError,
    });

    return () => socket.unsubscribe(id);
  }, []);

  useEffect(() => {
    socket.sendMessage({
      id,
      type: "stop",
    });
    socket.sendMessage({
      id,
      payload: {
        query,
        variables,
      },
      type: "start",
    });
  }, [query, JSON.stringify(variables)]);

  return { data, error, loading };
};
