import { gql } from "@apollo/client";

export const CREATE_COURSE_SECTION_TOPICS = gql`
  mutation addcoursesectionmodule(
    $course_id: Int
    $name: String
    $description: String
    $mod_id: Int
    $section_id: Int
    $created_date: timestamptz
    $publish_url: String
    $file_name: String
    $completion_criteria: Int
    $completion_percentage: Int
    $scorm_version: String
    $upload_url: String
    $maxattempt: Int
    $attemtsgrade: Int
    $timelimit_number: Int
    $enable_timer: Boolean
    $require_webcam: Boolean
    $system_filename: String
    $enable_user_attempt: Boolean
  ) {
    insert_courses_course_module_mapping(
      objects: {
        course_id: $course_id
        created_date: $created_date
        description: $description
        mod_id: $mod_id
        name: $name
        section_id: $section_id
        publish_url: $publish_url
        file_name: $file_name
        completion_criteria: $completion_criteria
        completion_percentage: $completion_percentage
        scorm_version: $scorm_version
        upload_url: $upload_url
        maxattempt: $maxattempt
        attemtsgrade: $attemtsgrade
        timelimit_number: $timelimit_number
        enable_timer: $enable_timer
        require_webcam: $require_webcam
        system_filename: $system_filename
        enable_user_attempt: $enable_user_attempt
      }
    ) {
      returning {
        course_id
        mod_id
        section_id
        id
      }
    }
  }
`;

export const INSERT_SCORM_DATA = gql`
  mutation InsertScormData($course_id: Int, $mapping_id: Int, $mod_id: Int) {
    insert_courses_course_scorm_track_data(
      objects: {
        mod_id: $mod_id
        mapping_id: $mapping_id
        course_id: $course_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_SCORM_COMPLETION_CRITERIA = gql`
  mutation InsertScormCompletionCriteria(
    $completed: Boolean
    $mapping_id: Int
    $passed: Boolean
    $scorm_version: String
    $min_score: Int
    $enable_min_score: Boolean
  ) {
    insert_courses_course_scorm_completion_criteria(
      objects: {
        completed: $completed
        mapping_id: $mapping_id
        passed: $passed
        scorm_version: $scorm_version
        min_score: $min_score
        enable_min_score: $enable_min_score
      }
    ) {
      affected_rows
      returning {
        id
        mapping_id
        passed
        completed
      }
    }
  }
`;

export const UPDATE_QUIZ_MODULE_FORM_DATA = gql`
  mutation UpdateQuizFormData(
    $id: Int
    $attemtsgrade: Int
    $completion_criteria: Int
    $completion_percentage: Int
    $description: String
    $enable_timer: Boolean
    $maxattempt: Int
    $name: String
    $timelimit_number: Int
    $require_webcam: Boolean
    $enable_user_attempt: Boolean
  ) {
    update_courses_course_module_mapping(
      where: { id: { _eq: $id } }
      _set: {
        attemtsgrade: $attemtsgrade
        completion_criteria: $completion_criteria
        completion_percentage: $completion_percentage
        description: $description
        enable_timer: $enable_timer
        maxattempt: $maxattempt
        name: $name
        timelimit_number: $timelimit_number
        require_webcam: $require_webcam
        enable_user_attempt: $enable_user_attempt
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_MODULE_RESTRICTION = gql`
  mutation UpdateModuleRestriction(
    $mapping_id: Int
    $restriction_ids: String
    $batch_id: bigint
  ) {
    update_courses_module_completion_resrtriction(
      where: { mapping_id: { _eq: $mapping_id } }
      _set: { restriction_ids: $restriction_ids, batch_id: $batch_id }
    ) {
      returning {
        id
        restriction_ids
      }
    }
  }
`;

export const UPDATE_CERTIFICATE_MODULE_FORM_DATA = gql`
  mutation UpadteCertificateForm(
    $id: Int
    $description: String
    $name: String
  ) {
    update_courses_course_module_mapping(
      where: { id: { _eq: $id } }
      _set: { description: $description, name: $name }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SCORM_MODULE_FORM_DATA = gql`
  mutation UpdateScormModule(
    $id: Int
    $attemtsgrade: Int
    $completion_criteria: Int
    $description: String
    $file_name: String
    $maxattempt: Int
    $name: String
    $upload_url: String
    $scorm_version: String
    $moodle_published: Boolean
    $system_filename: String
    $enable_user_attempt: Boolean
    $require_webcam: Boolean
  ) {
    update_courses_course_module_mapping(
      where: { id: { _eq: $id } }
      _set: {
        attemtsgrade: $attemtsgrade
        completion_criteria: $completion_criteria
        description: $description
        file_name: $file_name
        maxattempt: $maxattempt
        name: $name
        upload_url: $upload_url
        scorm_version: $scorm_version
        moodle_published: $moodle_published
        system_filename: $system_filename
        enable_user_attempt: $enable_user_attempt
        require_webcam: $require_webcam
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SCORM_COMPLETION_CRITERIA = gql`
  mutation UpdateScormCompletion(
    $mapping_id: Int
    $completed: Boolean
    $passed: Boolean
    $scorm_version: String
    $min_score: Int
    $enable_min_score: Boolean
  ) {
    update_courses_course_scorm_completion_criteria(
      where: { mapping_id: { _eq: $mapping_id } }
      _set: {
        completed: $completed
        passed: $passed
        scorm_version: $scorm_version
        enable_min_score: $enable_min_score
        min_score: $min_score
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_SCORM_COMPLETION_CRITERIA = gql`
  mutation DeleteScormCompletionCriteria($mapping_id: Int) {
    delete_courses_course_scorm_completion_criteria(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_MODULE_COMPLETION_RESTRICTION = gql`
  mutation DeleteMOduleRestriction($mapping_id: Int) {
    delete_courses_module_completion_resrtriction(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_COURSE_MODULE_MAPPING = gql`
  mutation MyMutation($mapping_id: Int, $bigmapping_id: bigint) {
    delete_courses_course_module_mapping(where: { id: { _eq: $mapping_id } }) {
      affected_rows
    }
    delete_courses_course_completion_criteria(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      affected_rows
    }
    delete_courses_course_scorm_completion_criteria(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      affected_rows
    }
    delete_courses_course_scorm_track_data(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      affected_rows
    }
    delete_courses_course_question_link(
      where: { module_mapping_id: { _eq: $mapping_id } }
    ) {
      affected_rows
    }
    delete_courses_course_module_files_attachements(
      where: { mapping_id: { _eq: $bigmapping_id } }
    ) {
      affected_rows
    }

    delete_courses_course_module_resources(
      where: { mapping_id: { _eq: $bigmapping_id } }
    ) {
      affected_rows
    }
    delete_courses_module_completion_resrtriction(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_COURSE_SECTION = gql`
  mutation MyMutation($section_id: Int) {
    delete_courses_course_section(where: { id: { _eq: $section_id } }) {
      affected_rows
    }
    delete_courses_course_module_mapping(
      where: { section_id: { _eq: $section_id } }
    ) {
      affected_rows
    }
  }
`;

export const CREATE_COURSE_ENROLLMENT = gql`
  mutation MyMutation($user_id: Int, $course_id: Int, $enroll_status: Boolean) {
    insert_courses_user_course_enrolment(
      objects: {
        user_id: $user_id
        course_id: $course_id
        enroll_status: $enroll_status
      }
      on_conflict: { constraint: user_course_enrolment_user_id_course_id_key }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_USER_COURSE_ENROLLMENT = gql`
  mutation MyMutation($id: Int) {
    delete_courses_user_course_enrolment(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation MyMutation($id: bigint, $course_id: Int) {
    delete_courses_course_partner_certification(
      where: { course_id: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_courses_course(where: { id: { _eq: $id } }) {
      affected_rows
    }
    delete_courses_course_module_mapping(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_course_section(where: { course_id: { _eq: $course_id } }) {
      affected_rows
    }
    delete_courses_course_batches(where: { course_id: { _eq: $course_id } }) {
      affected_rows
    }
    delete_courses_course_certificate(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_course_completion_criteria(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_course_critiera_courses(where: { course_id: { _eq: $id } }) {
      affected_rows
    }
    delete_courses_course_module_completion(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_course_module_user_attempt(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_course_question_link(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_course_scorm_track_data(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_course_subscription_plans(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_course_tags(where: { course_id: { _eq: $id } }) {
      affected_rows
    }
    delete_courses_course_tax_relation(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_issued_certificates(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_module_comp_restruction(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_module_completion_resrtriction(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_partner_project_courses(where: { course_id: { _eq: $id } }) {
      affected_rows
    }
    delete_courses_partner_revenue_split_directives(
      where: { course_id: { _eq: $id } }
    ) {
      affected_rows
    }
    delete_courses_user_batch_enrollment(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_user_course_complete(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }

    delete_courses_user_course_enrolment(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_user_course_question_attemept(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_user_course_quiz_attempt(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
    delete_courses_user_course_subscription(
      where: { course_id: { _eq: $course_id } }
    ) {
      affected_rows
    }
  }
`;

export const CREATE_RESOURCES = gql`
  mutation MyMutation($file_name: String, $mapping_id: bigint, $name: String) {
    insert_courses_course_module_resources(
      objects: { file_name: $file_name, mapping_id: $mapping_id, name: $name }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_SECTION_MODULE = gql`
  mutation UpdateSectionModule($section_id: Int, $id: Int) {
    update_courses_course_module_mapping(
      where: { id: { _eq: $id } }
      _set: { section_id: $section_id }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_COURSE_MODULE_RESOURCES_BY_ID = gql`
  mutation MyMutation($id: bigint) {
    delete_courses_course_module_resources(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_COURSE_MODULE_RESOURCES = gql`
  mutation MyMutation($file_name: String, $name: String, $id: bigint) {
    update_courses_course_module_resources(
      where: { id: { _eq: $id } }
      _set: { file_name: $file_name, name: $name }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_LESSON_TOPIC_NAME = gql`
  mutation MyMutation($name: String, $id: Int) {
    update_courses_course_module_mapping(
      where: { id: { _eq: $id } }
      _set: { name: $name }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_COURSE_RESOURCES = gql`
  mutation InsertCourseResources(
    $actual_file_name: String = ""
    $file_name: String = ""
    $mapping_id: bigint = ""
  ) {
    insert_courses_course_module_files_attachements(
      objects: {
        actual_file_name: $actual_file_name
        file_name: $file_name
        mapping_id: $mapping_id
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_RESOURCE_TOPIC_ATTACHMENTS = gql`
  mutation deleteResourceAttachement($id: [bigint!]) {
    delete_courses_course_module_files_attachements(
      where: { id: { _in: $id } }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_COURSE_COMPLETION_DESCRIPTION = gql`
  mutation UpdateCourseCompletionDesc(
    $course_completion_desc: String
    $id: bigint
  ) {
    update_courses_course(
      where: { id: { _eq: $id } }
      _set: { course_completion_desc: $course_completion_desc }
    ) {
      affected_rows
    }
  }
`;
