import { gql } from "@apollo/client";

export const GET_ADMIN_CSV_USER_IMPORTS = gql`
  query myQuery {
    courses_admin_csv_user_imports(
      order_by: { updated_at: desc }
      where: { purpose: { _eq: "user_bulk_onboard" } }
    ) {
      created
      duplicate
      failed
      file_url
      id
      partner_id
      status
      created_at
      updated
      uploader_id
    }
  }
`;

export const GET_ADMIN_CSV_USER_IMPORTS_BULK_NOTIFICATION = gql`
  query myQuery {
    courses_bulk_notification_csvs(
      order_by: { updated_at: desc }
    ) {
      id
      created_at
      notes
      admin_csv_user_imports_id
      batch_name
    }
  }
`;

export const GET_ADMIN_CSV_USER_IMPORTS_CERTIFICATE = gql`
  query userCsvImportsForCertificates {
    courses_admin_csv_user_imports(
      order_by: { updated_at: desc }
      where: { purpose: { _eq: "bulk_certificate" } }
    ) {
      created
      duplicate
      failed
      file_url
      id
      partner_id
      status
      created_at
      updated
      uploader_id
    }
  }
`;
export const GET_ADMIN_CSV_USER_IMPORTS_QUIZ = gql`
  query userCsvImportsForCertificates {
    courses_admin_csv_user_imports(
      order_by: { updated_at: desc }
      where: { purpose: { _eq: "quiz_responses" } }
    ) {
      created
      duplicate
      failed
      file_url
      id
      partner_id
      status
      created_at
      updated
      uploader_id
    }
  }
`;

export const INSERT_INSTITUTES_MUTATION = gql`
  mutation InsertInstitutes($institutes: [courses_institutes_insert_input!]!) {
    insert_courses_institutes(objects: $institutes) {
      returning {
        name
        city_town
        country
        district
        house_number
        location
        pincode
        state
      }
    }
  }
`;
