import { gql } from "@apollo/client";

export const CREATE_COURSES_ADMIN_CSV_USER_IMPORTS = gql`
  mutation createCoursesAdminCsvUserImports(
    $record: courses_admin_csv_user_imports_insert_input!
  ) {
    insert_courses_admin_csv_user_imports_one(object: $record) {
      id
    }
  }
`;
