import moment from "moment";

export default async function CourseUserProgressReport(
  usersProgressQuery,
  courseTopicQuery
) {
  const {
    courses_course_scorm_track_data,
    courses_user_course_quiz_attempt,
    courses_user_course_enrolment,
    courses_certificates,
    courses_user_course_complete,
  } = usersProgressQuery;

  return await Promise.all(
    courses_user_course_enrolment?.map((enroll) => {
      const filter = courseTopicQuery?.map((mapping) => {
        const getUserAttemptStatus =
          mapping.Module.type == "scorm-package"
            ? courses_course_scorm_track_data
                ?.filter((f) => {
                  return (
                    f.user_id === enroll.user_id && f.mapping_id === mapping.id
                  );
                })
                .reduce(function (prev, current) {
                  return prev.score_raw > current.score_raw ? prev : current;
                }, false)
            : courses_user_course_quiz_attempt
                ?.filter((f) => {
                  return (
                    f.user_id === enroll.user_id && f.mapping_id === mapping.id
                  );
                })
                .reduce(function (prev, current) {
                  return prev.total_score > current.total_score
                    ? prev
                    : current;
                }, false);

        const filterPartnerAccess = enroll.user?.partner_users.find((e) => {
          if (e.partner.id === enroll.partner_id) {
            return e.partner;
          }
        });

        const completionDate = courses_user_course_complete?.find(
          (completion) => completion.user_id === enroll.user_id
        );

        const father_name = enroll.user.user_family_details?.find(
          (e) => e.member_type == 0
        )?.name;

        const is_currently_working = enroll.user.users_tags_relation?.find(
          (e) => e.name == "is_currently_working"
        )?.value;

        const job_requirement = enroll.user.users_tags_relation
          ?.find((e) => e.name == "interest")
          ?.value?.includes("search");

        const salutation = enroll.user?.partner_users.find(
          (e) => e.partner_data
        );

        // Filter user course view or start date based on topic attempt
        const CourseViewDate =
          mapping.Module.type == "scorm-package"
            ? courses_course_scorm_track_data
                ?.filter((create) => {
                  return (
                    create.user_id === enroll.user_id &&
                    create?.scorm_attempt.attempt_number === 1
                  );
                })
                .reduce((acc, date) => {
                  return new Date(acc?.created_at) < new Date(date?.created_at)
                    ? acc?.created_at
                    : date?.created_at;
                }, "")
            : courses_user_course_quiz_attempt
                ?.filter((create) => {
                  return (
                    create.user_id === enroll.user_id &&
                    create?.attempt_number === 1
                  );
                })
                .reduce((acc, date) => {
                  return new Date(acc?.created_at) < new Date(date?.created_at)
                    ? acc?.created_at
                    : date?.created_at;
                }, "");

        return Object.assign({}, mapping, {
          skillstrainer_student_id: enroll.user_id,
          course_name: enroll.course_obj_relation.full_name,
          nsqf_level: enroll.course_obj_relation?.nsqf_level,
          salutation: salutation?.partner_data?.salutation,
          username: enroll.user.name,
          useremail: enroll.user.email,
          father_name: father_name,
          mobile_number: enroll.user.mobile_number,
          date_of_birth: enroll.user.date_of_birth,
          gender: enroll.user.gender,
          permanent_address_line: enroll.user?.address?.location,
          country: enroll.user?.address?.country,
          city_town: enroll.user?.address?.city_town,
          district: enroll.user?.address?.district,
          state: enroll.user?.address?.state,
          pincode: enroll.user?.address?.pincode,
          client_student_id: enroll?.partner_data?.partner_user_enrollment_id,
          client_batch_id: enroll?.partner_data?.partner_user_batch_id,
          partner_user_slot_number:
            enroll?.partner_data?.partner_user_slot_number,
          is_currently_working: is_currently_working,
          job_requirement: job_requirement ? "yes" : "No",
          skillstrainer_batch_id: "123",
          partner_id: filterPartnerAccess?.partner?.id,
          partner_name: filterPartnerAccess?.partner?.name,
          course_enrollment_date: moment(enroll?.created_at).format(
            "YYYY-MM-DD"
          ),
          course_start_date: CourseViewDate
            ? moment(CourseViewDate).format("YYYY-MM-DD")
            : "",
          course_completion_date: completionDate
            ? moment(completionDate?.created_at).format("YYYY-MM-DD")
            : "",

          [mapping.name + "- attempt start"]:
            mapping.Module.type == "scorm-package" && getUserAttemptStatus
              ? getUserAttemptStatus.scorm_attempt.attempt_start
              : mapping.Module.type == "quiz" && getUserAttemptStatus
              ? getUserAttemptStatus.attempt_start
              : "not attempt",
          [mapping.name + "- attempt end"]:
            mapping.Module.type == "scorm-package" && getUserAttemptStatus
              ? getUserAttemptStatus.scorm_attempt.attempt_end
              : mapping.Module.type == "quiz" && getUserAttemptStatus
              ? getUserAttemptStatus.attempt_end
              : " ",

          [mapping.name + "- attempt number"]:
            mapping.Module.type == "scorm-package" && getUserAttemptStatus
              ? getUserAttemptStatus.scorm_attempt.attempt_number
              : mapping.Module.type == "quiz" && getUserAttemptStatus
              ? getUserAttemptStatus.attempt_number
              : " ",
          [mapping.name + "- score"]:
            mapping.Module.type == "scorm-package" && getUserAttemptStatus
              ? getUserAttemptStatus.score_raw
              : mapping.Module.type == "quiz" && getUserAttemptStatus
              ? getUserAttemptStatus.total_score
              : " ",

          [mapping.name + "- result"]:
            mapping.Module.type == "scorm-package" && getUserAttemptStatus
              ? getUserAttemptStatus.lesson_status
              : mapping.Module.type == "quiz" && getUserAttemptStatus
              ? getUserAttemptStatus.result
              : " ",
        });
      });

      if (filter) {
        const mergeUserTopicAttempt = Object.assign(...filter);

        const cert =
          courses_certificates &&
          courses_certificates.find(
            (certificate) => certificate.user_id === enroll.user_id
          );

        return Object.assign({}, mergeUserTopicAttempt, {
          certificate_award_date: cert ? cert.created_at : "",
          certificate_code: cert ? cert.id : "",
        });
      }
    })
  );
}
