import axios from "axios";
import { error } from "utils/error";
import api from "./Api";
import { UPLOAD_COLLECTIONS } from "./Consts";

export const uploadFile = async (
  file_data,
  file_name,
  ops_type,
  isNotBase64
) => {
  if (isNotBase64) {
    file_data = await new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onloadend = () => res(reader.result);
      reader.onerror = () => rej(reader.error);
      reader.readAsDataURL(file_data);
    });
  }
  console.log("File data", file_data);
  const data = { file_data, file_name, ops_type };
  const response = await api().post("/upload_file", data);
  return response.data;
};

export const uploadLargeFile = async (file_data, file_name, isPublic) => {
  const data = {
    file_name,
    is_public: isPublic,
    content_type: file_data.type,
    collection: UPLOAD_COLLECTIONS.assets,
  };
  try {
    const response = await api()
      .post("/get_file_post_url", data)
      .then(({ data }) => data);

    const { image_post_url, file_name: res_file_name } = response;
    await axios
      .put(image_post_url, file_data, {
        headers: {
          "content-type": file_data.type,
          "X-Amz-ACL": isPublic && "public-read",
        },
      })
      .then(({ data }) => console.log(data));

    return { data_url: res_file_name };
  } catch (err) {
    throw error("Couldn't upload file", err);
  }
};

// Course Resources
export const uploadResourceLargeFile = async (
  file_data,
  file_name,
  isPublic,
  folder
) => {
  const data = {
    file_name,
    is_public: isPublic,
    content_type: file_data.type,
    collection: UPLOAD_COLLECTIONS.assets,
    folderpath: folder,
  };
  try {
    const response = await api()
      .post("/get_file_post_url", data)
      .then(({ data }) => data);

    const { image_post_url, file_name: res_file_name } = response;
    await axios
      .put(image_post_url, file_data, {
        headers: {
          "content-type": file_data.type,
          "X-Amz-ACL": isPublic && "public-read",
        },
      })
      .then(({ data }) => console.log(data));

    return { data_url: res_file_name };
  } catch (err) {
    throw error("Couldn't upload file", err);
  }
};
export const getFile = async (file_url, is_large_file, collection) => {
  const data = { data_url: file_url, is_large_file, collection };
  const response = await api().post("/get_file", data);
  return response.data;
};
