import "grapesjs/dist/css/grapes.min.css";
import _ from "lodash";
import Container from "components/Container/Container";
import { useLazyQuery } from "@apollo/client";
import { GET_ADMIN_CSV_USER_IMPORTS_BULK_NOTIFICATION } from "../../../GraphQl/Queries/OnboardingUsers";
import { FiRefreshCw } from "react-icons/fi";
import DataTable from "react-data-table-component";
import React, { useEffect } from "react";

function ManageNotificationBatches() {
  const [
    runQuery,
    { data: adminCsvUserImportsRes, loading: csvUserImportsLoading },
  ] = useLazyQuery(GET_ADMIN_CSV_USER_IMPORTS_BULK_NOTIFICATION, {
    fetchPolicy: "network-only", // Doesn't check cache before making a network request
  });

  const importsColumns = [
    {
      name: "Id",
      cell: (row, index) => {
        console.log("row hu ai", row);
        return `${row.id}`;
      },
    },
    {
      name: "CSV import id",
      grow: 5,
      cell: (row, index) => row.admin_csv_user_imports_id,
    },
    {
      name: "Notes",
      grow: 5,
      cell: (row, index) => row.notes,
    },
    {
      name: "Created At",
      grow: 5,
      cell: (row, index) => row.created_at,
    },
    {
      name: "Batch Name",
      grow: 5,
      cell: (row, index) => row.batch_name,
    },
  ];
  useEffect(() => {
    runQuery();
  }, []);

  return (
    <Container title={"Manage Bulk Notifications"}>
      <div className="mt-5">
        <div className="flex flex-row">
          <p className="text-2xl font-medium">Status</p>
          <button
            onClick={() => runQuery()}
            className="p-2 text-gray-500 w-5 h-5"
          >
            <FiRefreshCw />
          </button>
        </div>
        <DataTable
          className="min-w-max"
          columns={importsColumns}
          progressPending={csvUserImportsLoading}
          data={adminCsvUserImportsRes?.courses_bulk_notification_csvs}
        />
      </div>
    </Container>
  );
}

export default ManageNotificationBatches;