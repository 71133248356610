import React, { useState } from "react";
import ReactSelect from "react-select";
import ReactSelectCreatable from "react-select/creatable";

const MultiSelectDropdown = ({
  label,
  options = [],
  valueField,
  defaultValue,
  displayField,
  className,
  selectedValue,
  setSelectedValue,
  creatable,
  ...selectProps
}) => {
  // set value for default selection

  // handle onChange event of the dropdown
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const InputComponent = creatable ? ReactSelectCreatable : ReactSelect;

  return (
    <div className={className}>
      <label className="block text-sm font-medium text-japanese_indigo mb-1">
        {label}
      </label>
      <InputComponent
        {...selectProps}
        defaultValue={defaultValue}
        isMulti
        options={options}
        value={options.filter((obj) => selectedValue?.includes(obj.value))}
        onChange={handleChange}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </div>
  );
};

export default MultiSelectDropdown;
