import _ from "lodash";
import { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  currentModuleAccessRecoilVar,
  currentModulePathRecoilVar,
  currentModuleRecoilVar,
} from "State/Navigation";
import { modulesRecoilVar } from "State/Resources";
import { gqlUserRecoilVar, userAccessibleModulesRecoilVar } from "State/User";
import {
  getModuleDependancyMap,
  getModuleTreeFromModuleList,
} from "utils/resources";

export const homeModule = {
  id: "home",
  name: "Home",
  key: "home",
  slug: "",
};

const tree = {};
// Keeps track of which module is being accessed and if user has access to that
export default function NavigationContext(props) {
  const [user] = useRecoilState(gqlUserRecoilVar);
  const [modules] = useRecoilState(modulesRecoilVar);
  const [accessibleModules] = useRecoilState(userAccessibleModulesRecoilVar);

  const setCurrentModule = useSetRecoilState(currentModuleRecoilVar);
  const setCurrentModuleAccess = useSetRecoilState(
    currentModuleAccessRecoilVar
  );
  const setCurrentModulePath = useSetRecoilState(currentModulePathRecoilVar);

  const { pathname } = useLocation();
  const allModuleTree = useMemo(() => {
    if (modules) {
      const DependancyTree = getModuleDependancyMap(
        modules.map((m) => m.id),
        _.cloneDeep(modules)
      );
      Object.assign(tree, DependancyTree);

      return getModuleTreeFromModuleList(
        modules.map((m) => m.id),
        _.cloneDeep(modules)
      );
    }
  }, [modules]);

  // on path change, find intended module and it's corresponding user access
  useEffect(() => {
    if (allModuleTree && (user?.is_skillstrainer_admin || accessibleModules)) {
      // Find intended module, returns array of slugs in order
      const slugs = pathname
        .split("?")[0]
        .split("/")
        .filter((e) => !!e.trim());

      const targetModule = slugs.length
        ? getModuleBySlugList(slugs, allModuleTree)
        : homeModule;
      setCurrentModule(targetModule);

      if (targetModule) {
        // Get module path -> parent module id array
        const modulePath = getModulePath(targetModule);

        setCurrentModulePath(modulePath);

        // Get user access
        if (!user.is_skillstrainer_admin) {
          const targetModuleAccess = accessibleModules[targetModule.id] || {};
          setCurrentModuleAccess(targetModuleAccess);
        }
      }
    }
  }, [
    user?.is_skillstrainer_admin,
    accessibleModules,
    allModuleTree,
    pathname,
  ]);

  return null;
}

const getModuleBySlugList = (slugList, moduleList = [], depth = 0) => {
  const currentModule = moduleList.find((m) => m.slug === slugList[depth]);
  if (currentModule) {
    if (slugList.length - 1 === depth) return currentModule;
    return getModuleBySlugList(slugList, currentModule.submodules, depth + 1);
  }
};

const getModulePath = (module) => {
  const path = [];
  while (module.parent_module) {
    path.push(module.parent_module_id);
    module = module.parent_module;
  }
  return path.reverse();
};

export const getModulePathStringById = (moduleId) => {
  let mod = tree[moduleId];
  const path = [];
  let modulePath = "";

  while (mod) {
    path.push(mod.slug);
    mod = mod.parent_module;
  }

  for (const item of path.reverse()) {
    modulePath = modulePath + "/" + item;
  }

  return modulePath;
};
